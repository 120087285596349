import React from 'react';

export const PreparationIcon: React.FC = (props) => {
	return (
		<React.Fragment>
			<svg
				width="20"
				height="20"
				viewBox="0 0 20 20"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M16.6667 10.8335H0.833352C0.710567 10.8335 0.589298 10.8606 0.47822 10.9129C0.367141 10.9653 0.268997 11.0415 0.190808 11.1362C0.112618 11.2308 0.0563154 11.3416 0.0259263 11.4606C-0.00446277 11.5795 -0.00818731 11.7038 0.0150191 11.8243C0.26913 13.1278 0.841544 14.3482 1.68132 15.377C2.52109 16.4058 3.60217 17.211 4.82835 17.721C4.92366 17.7604 5.00096 17.8338 5.0453 17.9269C5.08963 18.0201 5.09785 18.1263 5.06835 18.2252C4.97587 18.5305 4.92897 18.8478 4.92919 19.1668C4.92919 19.3878 5.01698 19.5998 5.17326 19.7561C5.32954 19.9124 5.54151 20.0002 5.76252 20.0002H11.7375C11.9585 20.0002 12.1705 19.9124 12.3268 19.7561C12.4831 19.5998 12.5709 19.3878 12.5709 19.1668C12.571 18.8479 12.5244 18.5306 12.4325 18.2252C12.4028 18.1262 12.4109 18.0197 12.4552 17.9264C12.4996 17.8331 12.577 17.7596 12.6725 17.7202C13.8986 17.2103 14.9797 16.4052 15.8195 15.3766C16.6592 14.348 17.2317 13.1277 17.4859 11.8243C17.5091 11.7037 17.5053 11.5794 17.4749 11.4604C17.4445 11.3414 17.3881 11.2305 17.3098 11.1358C17.2315 11.0412 17.1333 10.965 17.0221 10.9127C16.9109 10.8604 16.7895 10.8334 16.6667 10.8335Z"
					fill="#D0D2DB"
				/>
				<path
					d="M19.8879 3.76844L18.5087 0.733437C18.3716 0.431874 18.1203 0.197097 17.8101 0.0806865C17.5 -0.0357235 17.1563 -0.0242438 16.8546 0.112603L12.1121 2.26594C11.9874 2.32252 11.8752 2.40311 11.7817 2.5031C11.6882 2.60309 11.6154 2.72052 11.5673 2.84869C11.5192 2.97685 11.4969 3.11323 11.5016 3.25002C11.5063 3.38682 11.5379 3.52135 11.5946 3.64594L13.1462 7.0626C13.2292 7.24486 13.363 7.39937 13.5314 7.50768C13.6999 7.616 13.8959 7.67354 14.0962 7.67344C14.244 7.67334 14.3901 7.64151 14.5246 7.5801L19.2671 5.42594C19.4168 5.35803 19.5517 5.26125 19.6639 5.14114C19.7762 5.02103 19.8637 4.87996 19.9213 4.72599C19.979 4.57202 20.0057 4.40819 20 4.24388C19.9943 4.07957 19.9562 3.918 19.8879 3.76844Z"
					fill="#D0D2DB"
				/>
				<path
					d="M8.75 6.6665C9.44036 6.6665 10 6.10686 10 5.4165C10 4.72615 9.44036 4.1665 8.75 4.1665C8.05964 4.1665 7.5 4.72615 7.5 5.4165C7.5 6.10686 8.05964 6.6665 8.75 6.6665Z"
					fill="#D0D2DB"
				/>
				<path
					d="M10.417 9.5835C11.1073 9.5835 11.667 9.02385 11.667 8.3335C11.667 7.64314 11.1073 7.0835 10.417 7.0835C9.72664 7.0835 9.16699 7.64314 9.16699 8.3335C9.16699 9.02385 9.72664 9.5835 10.417 9.5835Z"
					fill="#D0D2DB"
				/>
				<path
					d="M7.08301 9.5835C7.77336 9.5835 8.33301 9.02385 8.33301 8.3335C8.33301 7.64314 7.77336 7.0835 7.08301 7.0835C6.39265 7.0835 5.83301 7.64314 5.83301 8.3335C5.83301 9.02385 6.39265 9.5835 7.08301 9.5835Z"
					fill="#D0D2DB"
				/>
			</svg>
		</React.Fragment>
	);
};
