import './FoodServingDetails05StepForm.scss';

import { DatePicker } from '../../../atoms/datePickers/DatePicker/DatePicker';
import { Field } from 'formik';
import { IWizzardStepProps } from '../UserInterviewInterfaces';
import { RadioSelect } from '../../../atoms/RadioSelect/RadioSelect';
import { TextBox } from '../../../atoms/TextBox/TextBox';
import { WizardCard } from '../../WizardCard/WizardCard';
import { clientJourneyPutDietStartDayUtc } from '../../../../business/customer/customer-settings-actions';
import moment from 'moment';
import { useDispatch } from 'react-redux';

import React, { PropsWithChildren, useState } from 'react';

export const FoodServingDetails05StepForm: React.FC<IWizzardStepProps> = ({
	formik,
	mode,
	scrollToStep,
	...props
}: PropsWithChildren<IWizzardStepProps>) => {
	const dispatch = useDispatch();
	const [showCalendar, setShowCalendar] = useState(false);
	const minDate = moment().add(1, 'day').startOf('day');

	const handleCalendarTextBoxClick = () => {
		setShowCalendar(!showCalendar);
	};

	const handleCloseCalendar = () => {
		setShowCalendar(false);
	};

	const handleScroll = () => {
		scrollToStep(5);
	};
	const handleDateChanged = (date: Date) => {
		formik.setFieldValue('step5.dietDateStartUtc', date);
		setShowCalendar(false);
		if (mode === 'new-customer') {
			dispatch(clientJourneyPutDietStartDayUtc(date));
		}
	};
	return (
		<WizardCard id="step05" header="5. Szczegóły" onClick={handleScroll}>
			<h4 className="UserInterview__subheader">
				Czy chcesz aby jadłospis zawierał takie samo danie główne jak kolacja?
			</h4>
			<div className="UserInterview_row">
				<Field
					label="Tak"
					name="step5.lunchAndDinnerSameMeal"
					value="Yes"
					checked={formik.values.step5.lunchAndDinnerSameMeal === 'Yes'}
					as={RadioSelect}
				/>
				<Field
					label="Nie"
					name="step5.lunchAndDinnerSameMeal"
					value="No"
					checked={formik.values.step5.lunchAndDinnerSameMeal === 'No'}
					as={RadioSelect}
				/>
			</div>
			<span className="FormikError">
				{formik.touched.step5?.lunchAndDinnerSameMeal &&
					formik.errors.step5?.lunchAndDinnerSameMeal}
			</span>

			<h4 className="UserInterview__subheader">
				Czy chcesz aby jadłospis zawierał jedno danie główne na dwa dni?
			</h4>
			<div className="UserInterview_row">
				<Field
					label="Tak"
					name="step5.mainMealForTwoDays"
					value="Yes"
					checked={formik.values.step5.mainMealForTwoDays === 'Yes'}
					as={RadioSelect}
				/>
				<Field
					label="Nie"
					name="step5.mainMealForTwoDays"
					value="No"
					checked={formik.values.step5.mainMealForTwoDays === 'No'}
					as={RadioSelect}
				/>
			</div>
			<span className="FormikError">
				{formik.touched.step5?.mainMealForTwoDays &&
					formik.errors.step5?.mainMealForTwoDays}
			</span>

			<h4 className="UserInterview__subheader">
				Czy chcesz aby jadłospis zakładał te same posiłki na dwa kolejne dni? Pomoże to
				zredukować czas spędzony na gotowaniu
			</h4>
			<div className="UserInterview_row">
				<Field
					label="Tak"
					name="step5.dietDayForTwoDays"
					value="Yes"
					checked={formik.values.step5.dietDayForTwoDays === 'Yes'}
					as={RadioSelect}
				/>
				<Field
					label="Nie"
					name="step5.dietDayForTwoDays"
					value="No"
					checked={formik.values.step5.dietDayForTwoDays === 'No'}
					as={RadioSelect}
				/>
			</div>
			<span className="FormikError">
				{formik.touched.step5?.dietDayForTwoDays && formik.errors.step5?.dietDayForTwoDays}
			</span>

			{mode === 'new-customer' ? (
				<>
					<h4 className="UserInterview__subheader">Kiedy chcesz zacząć dietę?</h4>
					<div
						className="FoodServingDetails05StepForm_dietStartDate"
						onClick={handleCalendarTextBoxClick}
					>
						<Field
							name="step5.dietDateStartUtc"
							grayColor
							errorString={
								formik.touched.step5?.dietDateStartUtc &&
								formik.errors.step5?.dietDateStartUtc
							}
							disabled
							value={moment(formik.values.step5.dietDateStartUtc)
								.format('YYYY-MM-DD')
								.toLocaleString()}
							key={formik.values.step5.dietDateStartUtc}
							as={TextBox}
						/>
						<DatePicker
							minDateUtc={minDate.toDate()}
							handleChange={handleDateChanged}
							dateUtc={formik.values.step5.dietDateStartUtc}
							showCalendar={showCalendar}
							align="top"
							onShowStateChange={handleCloseCalendar}
						/>
					</div>
				</>
			) : (
				<></>
			)}
		</WizardCard>
	);
};
