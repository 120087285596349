import * as React from 'react';
import * as Redux from 'redux';

import { DietPlanPrice } from '../../staticData/staticDataSelectors';
import { LandingPageDietTypesComponent } from './LandingPageDietTypesComponent';
import { RootState } from '../../../../business/root-store';
import { connect } from 'react-redux';
import { getCheapestDietPlanPrice } from '../../staticData/staticDataSelectors';

import { AnyAction, Dispatch } from 'redux';
import {
	getStandardDietId,
	getVeggieDietId,
	getVeggieFishDietId
} from '../../../../business/diet/diet-selector';

interface ILandingPageDietTypesContainerReduxMergedProps {
	cheapestPrice: DietPlanPrice;
	standardDietId?: number;
	veggieDietId?: number;
	veggieFishDietId?: number;
}

interface ILandingPageDietTypesContainerOwnProps {}

interface ILandingPageDietTypesContainerDispatchProps {}

interface ILandingPageDietTypesContainerProps
	extends ILandingPageDietTypesContainerOwnProps,
		ILandingPageDietTypesContainerDispatchProps,
		ILandingPageDietTypesContainerReduxMergedProps {}

interface ILandingPageDietTypesContainerState {}

class LandingPageDietTypesContainer extends React.Component<
	ILandingPageDietTypesContainerProps,
	ILandingPageDietTypesContainerState
> {
	constructor(
		props: ILandingPageDietTypesContainerProps,
		state: ILandingPageDietTypesContainerState
	) {
		super(props, state);

		this.state = this.getInitialState();
	}

	public render(): JSX.Element {
		return (
			<LandingPageDietTypesComponent
				standardDietId={this.props.standardDietId}
				veggieDietId={this.props.veggieDietId}
				veggieFishDietId={this.props.veggieFishDietId}
				cheapestPrice={this.props.cheapestPrice}
			/>
		);
	}

	public componentDidMount(): void {}

	public static mapStateToProps = (
		state: RootState,
		ownProps: ILandingPageDietTypesContainerOwnProps
	): ILandingPageDietTypesContainerReduxMergedProps => {
		return {
			standardDietId: getStandardDietId(state),
			veggieDietId: getVeggieDietId(state),
			veggieFishDietId: getVeggieFishDietId(state),
			cheapestPrice: getCheapestDietPlanPrice(state)
		};
	};

	public static mapDispatchToProps = (
		dispatch: Dispatch<AnyAction>
	): ILandingPageDietTypesContainerDispatchProps => {
		return {
			...Redux.bindActionCreators({}, dispatch)
		};
	};

	private getInitialState = (): ILandingPageDietTypesContainerState => {
		return {} as ILandingPageDietTypesContainerState;
	};
}
export const LandingPageDietTypes = connect(
	LandingPageDietTypesContainer.mapStateToProps,
	LandingPageDietTypesContainer.mapDispatchToProps
)(LandingPageDietTypesContainer);
