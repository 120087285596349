import { RootState } from '../root-store';
import { createSelector } from 'reselect';

export const getDietPreferences = (state: RootState) => state.dietPreferences;
export const getSettings = (state: RootState) => state.administration.customerSettings;
export const getPreferencesId = createSelector(
	getDietPreferences,
	(preferences) => preferences.preferenceId
);
export const getExclusionRules = createSelector(
	getDietPreferences,
	(dietTypes) => dietTypes.dietExclusionRules
);

export const getNewCustomerStep03Status = createSelector(getDietPreferences, (settings) => {
	return (
		!(settings.haveExclusion === null || settings.haveExclusion === undefined) &&
		((settings.haveExclusion &&
			!(settings.dietExclusionRules === null || settings.dietExclusionRules === undefined) &&
			settings.dietExclusionRules!.length > 0) ||
			!settings.haveExclusion)
	);
});

export const getNewCustomerStep02_2Status = createSelector(
	getSettings,
	(settings) =>
		settings.customerTrainingPreferences.sportMode == false ||
		settings.customerTrainingPreferences.trainingDays! > 0
);

export const getNewCustomerStep04Status = createSelector(getDietPreferences, (settings) => {
	return !!settings.numberOfServingsPerDay;
});

export const getNewCustomerStep05Status = createSelector(getDietPreferences, (settings) => {
	return (
		settings.dietDayForTwoDays !== null &&
		settings.dietDayForTwoDays !== undefined &&
		settings.mainMealForTwoDays !== null &&
		settings.mainMealForTwoDays !== undefined
	);
});

export const getLunchAndDinnerSameMeal = createSelector(
	getDietPreferences,
	(settings) => settings.lunchAndDinnerSameMeal
);
export const getmainMealForTwoDays = createSelector(
	getDietPreferences,
	(settings) => settings.mainMealForTwoDays
);

export const getDietDayForTwoDays = createSelector(
	getDietPreferences,
	(settings) => settings.dietDayForTwoDays
);

export const getnumberOfMeals = createSelector(
	getDietPreferences,
	(settings) => settings.numberOfServingsPerDay
);

export const getExclusions = createSelector(
	getDietPreferences,
	(settings) => settings.dietExclusionRules
);

export const getNumberOfServingsPerDay = createSelector(
	getDietPreferences,
	(settings) => settings.numberOfServingsPerDay
);

export const getHaveExclusion = createSelector(
	getDietPreferences,
	(settings) => settings.haveExclusion
);
