import './ContactForm.scss';

import * as Yup from 'yup';

import { ActionButton } from '../../../atoms/ActionButton/ActionButton';
import ActionLink from '../../../atoms/action-link/action-link';
import { CheckBox } from '../../../atoms/CheckBox/CheckBox';
import React from 'react';
import { TextArea } from '../../../atoms/TextArea/TextArea';
import { TextBox } from '../../../atoms/TextBox/TextBox';
import { createUrlWithParams } from '../../../../business/router/path-definition';
import { postCustomerRequestForm } from '../LandingPageEpics';
import { routes } from '../../../../business/router/path-definition';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Field, Form, Formik, FormikHelpers } from 'formik';
import { IContactFormProps, IContactFormValues } from './ContactFormInterfaces';
import { documentsUrls, primaryOrangeColor } from '../../../../shared/shared';

const initialValues: IContactFormValues = {
	name: '',
	topic: '',
	email: '',
	content: '',
	acceptedTerms: false
};

export const ContactForm = (props: IContactFormProps) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const handleClickTerms = (): void => {
		history.push(
			createUrlWithParams(routes.static.document.path, {
				documentUrl: documentsUrls.tos
			})
		);
	};
	const onSubmit = (values: IContactFormValues, helpers: FormikHelpers<IContactFormValues>) => {
		dispatch(postCustomerRequestForm(values.name, values.email, values.topic, values.content));
		setTimeout(() => helpers.setSubmitting(false), 2000);
	};
	return (
		<Formik
			initialValues={initialValues}
			validationSchema={Yup.object<Partial<Record<keyof IContactFormValues, Yup.AnySchema>>>({
				name: Yup.string().min(3, 'Co najmniej 3 znaki').required('Wymagane'),
				topic: Yup.string()
					.min(3, 'Co najmniej 3 znaki')
					.max(30, 'Nie więcej niż 30 znaków')
					.required('Wymagane'),
				content: Yup.string().min(3, 'Co najmniej 3 znaki').required('Wymagane'),
				email: Yup.string().email('Niepoprawny adres email').required('Wymagane'),
				acceptedTerms: Yup.boolean().oneOf([true], 'Wymagane')
			})}
			onSubmit={onSubmit}
		>
			{(formik) => (
				<Form id="contact-form" className="ContactForm_content_row">
					<Field
						name="name"
						label="Imię i nazwisko"
						placeholder="Jak się nazywasz?"
						className="ContactForm_content_row_item"
						errorString={formik.touched.name && formik.errors.name}
						as={TextBox}
					/>
					<Field
						name="email"
						label="E-Mail"
						placeholder="np. jan.kowalski@kontakt.com"
						className="ContactForm_content_row_item"
						errorString={formik.touched.email && formik.errors.email}
						as={TextBox}
					/>
					<Field
						name="topic"
						label="Temat"
						placeholder="O co pytasz?"
						className="ContactForm_content_row_item"
						errorString={formik.touched.topic && formik.errors.topic}
						as={TextBox}
					/>
					<Field
						name="content"
						label="Treść"
						placeholder="Wpisz treść swojej wiadomości"
						className="ContactForm_content_row_item ContactForm_content_row_item_full-width"
						errorString={formik.touched.content && formik.errors.content}
						as={TextArea}
					/>

					<div className="ContactForm_terms">
						<Field
							name="acceptedTerms"
							type="checkbox"
							as={CheckBox}
							errorString={
								formik.touched.acceptedTerms && formik.errors.acceptedTerms
							}
							displayName={
								<span>
									Podane dane osobowe będą przetwarzane w celu kontaktu i obsługi
									Państwa zgłoszenia. Więcej informacji o przetwarzaniu danych
									osobowych:
									<ActionLink
										textColor={primaryOrangeColor}
										text="Polityka Prywatności"
										onClick={handleClickTerms}
									/>
									.
								</span>
							}
							disableDisplayNameChangeStyle
						/>
					</div>

					<ActionButton
						disabled={!formik.isValid}
						errorString={formik.isValid ? undefined : 'Sprawdź błędy w formularzu'}
						type="submit"
						text="Wyślij wiadomość"
						className="ContactForm_action-button"
					/>
				</Form>
			)}
		</Formik>
	);
};
