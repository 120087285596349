import { ISearchItem } from '../../../atoms/search-box/search-box';
import { List } from 'immutable';
import { ProductRefNamePair } from '../../../../client';
import { createSelector } from 'reselect';

const productsSelector = (products: List<ProductRefNamePair>) => products;

export const getProductsSelector = createSelector(
	productsSelector,
	(products: List<ProductRefNamePair>) => {
		const items = products
			.map((product: ProductRefNamePair) => {
				return {
					id: product.id,
					value: product,
					display: product.name
				} as ISearchItem;
			})
			.toArray();
		return items;
	}
);
