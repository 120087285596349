import { ICurrentMeals } from './meal-interfaces';
import { MealServingsProposalsForProductId } from '../../../../client';

export const CurrentMeals_Set = '@@Diet/Meal/CurrentMeals/SET';
export const MealServingsProposals_Set = '@@Diet/Meal/MealServingsProposals/SET';

export interface ICurrentDietPlanDayMealsSetAction {
	type: typeof CurrentMeals_Set;
	currentMeals: ICurrentMeals;
}

export const setCurrentMeals = (
	currentMeals: ICurrentMeals
): ICurrentDietPlanDayMealsSetAction => ({
	type: CurrentMeals_Set,
	currentMeals: currentMeals
});

export interface IMealServingsProposalsSetAction {
	type: typeof MealServingsProposals_Set;
	mealServings: MealServingsProposalsForProductId[];
}

export const setMealServingsProposals = (
	mealServings: MealServingsProposalsForProductId[]
): IMealServingsProposalsSetAction => ({
	type: MealServingsProposals_Set,
	mealServings: mealServings
});
