/* tslint:disable */
/* eslint-disable */
/**
 * TailoredApps.Diet.WebApi.Internal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AcceptOrRejectRestaurantDetails
 */
export interface AcceptOrRejectRestaurantDetails {
    /**
     * 
     * @type {number}
     * @memberof AcceptOrRejectRestaurantDetails
     */
    'restaurantId'?: number;
    /**
     * 
     * @type {string}
     * @memberof AcceptOrRejectRestaurantDetails
     */
    'rejectedDescription'?: string | null;
}
/**
 * 
 * @export
 * @interface AcceptOrRejectRestaurantDetailsResponse
 */
export interface AcceptOrRejectRestaurantDetailsResponse {
    /**
     * 
     * @type {Restaurant}
     * @memberof AcceptOrRejectRestaurantDetailsResponse
     */
    'restaurant'?: Restaurant;
}
/**
 * 
 * @export
 * @interface AcceptOrRejectRestaurantDocuments
 */
export interface AcceptOrRejectRestaurantDocuments {
    /**
     * 
     * @type {number}
     * @memberof AcceptOrRejectRestaurantDocuments
     */
    'restaurantId'?: number;
    /**
     * 
     * @type {string}
     * @memberof AcceptOrRejectRestaurantDocuments
     */
    'rejectedDescription'?: string | null;
}
/**
 * 
 * @export
 * @interface AcceptOrRejectRestaurantDocumentsResponse
 */
export interface AcceptOrRejectRestaurantDocumentsResponse {
    /**
     * 
     * @type {Restaurant}
     * @memberof AcceptOrRejectRestaurantDocumentsResponse
     */
    'restaurant'?: Restaurant;
}
/**
 * 
 * @export
 * @interface AcceptOrRejectRestaurantMeal
 */
export interface AcceptOrRejectRestaurantMeal {
    /**
     * 
     * @type {number}
     * @memberof AcceptOrRejectRestaurantMeal
     */
    'mealId'?: number;
    /**
     * 
     * @type {number}
     * @memberof AcceptOrRejectRestaurantMeal
     */
    'restaurantId'?: number;
    /**
     * 
     * @type {string}
     * @memberof AcceptOrRejectRestaurantMeal
     */
    'rejectedDescription'?: string | null;
}
/**
 * 
 * @export
 * @interface AcceptOrRejectRestaurantMealResponse
 */
export interface AcceptOrRejectRestaurantMealResponse {
    /**
     * 
     * @type {Meal}
     * @memberof AcceptOrRejectRestaurantMealResponse
     */
    'meal'?: Meal;
}
/**
 * 
 * @export
 * @interface AcceptOrRejectRestaurantRequest
 */
export interface AcceptOrRejectRestaurantRequest {
    /**
     * 
     * @type {number}
     * @memberof AcceptOrRejectRestaurantRequest
     */
    'restaurantId'?: number;
    /**
     * 
     * @type {string}
     * @memberof AcceptOrRejectRestaurantRequest
     */
    'rejectedDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AcceptOrRejectRestaurantRequest
     */
    'documentToSign'?: string | null;
}
/**
 * 
 * @export
 * @interface AcceptOrRejectRestaurantRequestResponse
 */
export interface AcceptOrRejectRestaurantRequestResponse {
    /**
     * 
     * @type {Restaurant}
     * @memberof AcceptOrRejectRestaurantRequestResponse
     */
    'restaurant'?: Restaurant;
}
/**
 * 
 * @export
 * @interface AddOrUpdateCustomProduct
 */
export interface AddOrUpdateCustomProduct {
    /**
     * 
     * @type {string}
     * @memberof AddOrUpdateCustomProduct
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddOrUpdateCustomProduct
     */
    'manufacturer'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AddOrUpdateCustomProduct
     */
    'productGroupId'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof AddOrUpdateCustomProduct
     */
    'isForPackage'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AddOrUpdateCustomProduct
     */
    'quantity'?: number;
    /**
     * 
     * @type {number}
     * @memberof AddOrUpdateCustomProduct
     */
    'proteinsQuantity'?: number;
    /**
     * 
     * @type {number}
     * @memberof AddOrUpdateCustomProduct
     */
    'carbsQuantity'?: number;
    /**
     * 
     * @type {number}
     * @memberof AddOrUpdateCustomProduct
     */
    'fatsQuantity'?: number;
    /**
     * 
     * @type {number}
     * @memberof AddOrUpdateCustomProduct
     */
    'energyQuantity'?: number;
    /**
     * 
     * @type {string}
     * @memberof AddOrUpdateCustomProduct
     */
    'ean'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddOrUpdateCustomProduct
     */
    'description'?: string | null;
    /**
     * 
     * @type {Array<ProductNutritionFact>}
     * @memberof AddOrUpdateCustomProduct
     */
    'productNutritionFacts'?: Array<ProductNutritionFact> | null;
}
/**
 * 
 * @export
 * @interface AddOrUpdateCustomProductResponse
 */
export interface AddOrUpdateCustomProductResponse {
    /**
     * 
     * @type {MealFoodProduct}
     * @memberof AddOrUpdateCustomProductResponse
     */
    'mealFoodProduct'?: MealFoodProduct;
}
/**
 * 
 * @export
 * @interface AddOrUpdateCustomerResponse
 */
export interface AddOrUpdateCustomerResponse {
    /**
     * 
     * @type {Customer}
     * @memberof AddOrUpdateCustomerResponse
     */
    'customer'?: Customer;
}
/**
 * 
 * @export
 * @interface AddOrUpdateDietPlan
 */
export interface AddOrUpdateDietPlan {
    /**
     * 
     * @type {DietPlan}
     * @memberof AddOrUpdateDietPlan
     */
    'dietPlan'?: DietPlan;
}
/**
 * 
 * @export
 * @interface AddOrUpdateDietPlanDay
 */
export interface AddOrUpdateDietPlanDay {
    /**
     * 
     * @type {DietPlanDay}
     * @memberof AddOrUpdateDietPlanDay
     */
    'dietPlanDay'?: DietPlanDay;
}
/**
 * 
 * @export
 * @interface AddOrUpdateDietPlanDayResponse
 */
export interface AddOrUpdateDietPlanDayResponse {
    /**
     * 
     * @type {DietPlanDay}
     * @memberof AddOrUpdateDietPlanDayResponse
     */
    'dietPlanDay'?: DietPlanDay;
}
/**
 * 
 * @export
 * @interface AddOrUpdateDietPlanResponse
 */
export interface AddOrUpdateDietPlanResponse {
    /**
     * 
     * @type {DietPlan}
     * @memberof AddOrUpdateDietPlanResponse
     */
    'dietPlan'?: DietPlan;
}
/**
 * 
 * @export
 * @interface AddOrUpdateHomeMeasurementRule
 */
export interface AddOrUpdateHomeMeasurementRule {
    /**
     * 
     * @type {HomeMeasurementRule}
     * @memberof AddOrUpdateHomeMeasurementRule
     */
    'homeMeasurementRule'?: HomeMeasurementRule;
}
/**
 * 
 * @export
 * @interface AddOrUpdateHomeMeasurementRuleResponse
 */
export interface AddOrUpdateHomeMeasurementRuleResponse {
    /**
     * 
     * @type {HomeMeasurementRule}
     * @memberof AddOrUpdateHomeMeasurementRuleResponse
     */
    'homeMeasurementRule'?: HomeMeasurementRule;
}
/**
 * 
 * @export
 * @interface AddOrUpdateProductExchangeGroupResponse
 */
export interface AddOrUpdateProductExchangeGroupResponse {
    /**
     * 
     * @type {ProductExchangeGroup}
     * @memberof AddOrUpdateProductExchangeGroupResponse
     */
    'productExchangeGroup'?: ProductExchangeGroup;
}
/**
 * 
 * @export
 * @interface AddOrUpdateRestaurantMeal
 */
export interface AddOrUpdateRestaurantMeal {
    /**
     * 
     * @type {Meal}
     * @memberof AddOrUpdateRestaurantMeal
     */
    'meal'?: Meal;
}
/**
 * 
 * @export
 * @interface AddOrUpdateRestaurantMealResponse
 */
export interface AddOrUpdateRestaurantMealResponse {
    /**
     * 
     * @type {Meal}
     * @memberof AddOrUpdateRestaurantMealResponse
     */
    'meal'?: Meal;
}
/**
 * 
 * @export
 * @interface AddOrUpdateUnitOfMeasure
 */
export interface AddOrUpdateUnitOfMeasure {
    /**
     * 
     * @type {UnitOfMeasure}
     * @memberof AddOrUpdateUnitOfMeasure
     */
    'unitOfMeasure'?: UnitOfMeasure;
}
/**
 * 
 * @export
 * @interface AddOrUpdateUnitOfMeasureResponse
 */
export interface AddOrUpdateUnitOfMeasureResponse {
    /**
     * 
     * @type {UnitOfMeasure}
     * @memberof AddOrUpdateUnitOfMeasureResponse
     */
    'unitOfMeasure'?: UnitOfMeasure;
}
/**
 * 
 * @export
 * @interface AddOrUpdateUoMTextRule
 */
export interface AddOrUpdateUoMTextRule {
    /**
     * 
     * @type {UoMTextRule}
     * @memberof AddOrUpdateUoMTextRule
     */
    'uoMTextRule'?: UoMTextRule;
}
/**
 * 
 * @export
 * @interface AddOrUpdateUoMTextRuleResponse
 */
export interface AddOrUpdateUoMTextRuleResponse {
    /**
     * 
     * @type {UoMTextRule}
     * @memberof AddOrUpdateUoMTextRuleResponse
     */
    'uoMTextRule'?: UoMTextRule;
}
/**
 * 
 * @export
 * @interface AuditLog
 */
export interface AuditLog {
    /**
     * 
     * @type {number}
     * @memberof AuditLog
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof AuditLog
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AuditLog
     */
    'key'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AuditLog
     */
    'before'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AuditLog
     */
    'after'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AuditLog
     */
    'action'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AuditLog
     */
    'who'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AuditLog
     */
    'when'?: string;
}
/**
 * 
 * @export
 * @interface CaloriesWithProductId
 */
export interface CaloriesWithProductId {
    /**
     * 
     * @type {number}
     * @memberof CaloriesWithProductId
     */
    'productId'?: number;
    /**
     * 
     * @type {number}
     * @memberof CaloriesWithProductId
     */
    'calories'?: number;
}
/**
 *   0 = None (None)  1 = Ballanced (Ballanced)  2 = Carbohydrates (Carbohydrates)  4 = Energy (Energy)  8 = Fat (Fat)  16 = Protein (Protein)
 * @export
 * @enum {string}
 */

export enum Classification {
    /**
    * None
    */
    None = 0,
    /**
    * Ballanced
    */
    Ballanced = 1,
    /**
    * Carbohydrates
    */
    Carbohydrates = 2,
    /**
    * Energy
    */
    Energy = 4,
    /**
    * Fat
    */
    Fat = 8,
    /**
    * Protein
    */
    Protein = 16
}

/**
 * 
 * @export
 * @interface CurrentOrder
 */
export interface CurrentOrder {
    /**
     * 
     * @type {OrderTypeEnum}
     * @memberof CurrentOrder
     */
    'orderType'?: OrderTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof CurrentOrder
     */
    'orderBoughtDays'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof CurrentOrder
     */
    'orderExpired'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CurrentOrder
     */
    'orderStartDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof CurrentOrder
     */
    'lastFinishedPaymentPaymentChannel'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CurrentOrder
     */
    'lastFinishedPaymentType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CurrentOrder
     */
    'lastFinishedPaymentCardNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CurrentOrder
     */
    'dietPlanDefinitionName'?: string | null;
}
/**
 * 
 * @export
 * @interface Customer
 */
export interface Customer {
    /**
     * 
     * @type {number}
     * @memberof Customer
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof Customer
     */
    'userId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'surname'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'mail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'city'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'street'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'homeNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'apartmentNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'postcode'?: string | null;
    /**
     * 
     * @type {Array<DietPlan>}
     * @memberof Customer
     */
    'dietPlans'?: Array<DietPlan> | null;
    /**
     * 
     * @type {Array<Meal>}
     * @memberof Customer
     */
    'customerMeals'?: Array<Meal> | null;
}
/**
 * 
 * @export
 * @interface CustomerDietPreference
 */
export interface CustomerDietPreference {
    /**
     * 
     * @type {number}
     * @memberof CustomerDietPreference
     */
    'preferenceId'?: number;
    /**
     * 
     * @type {string}
     * @memberof CustomerDietPreference
     */
    'customerSettingsId'?: string;
    /**
     * 
     * @type {number}
     * @memberof CustomerDietPreference
     */
    'dietTypeId'?: number;
    /**
     * 
     * @type {number}
     * @memberof CustomerDietPreference
     */
    'numberOfServingsPerDay'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerDietPreference
     */
    'mainMealForTwoDays'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerDietPreference
     */
    'dietDayForTwoDays'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerDietPreference
     */
    'lunchAndDinnerSameMeal'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerDietPreference
     */
    'haveExclusion'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerDietPreference
     */
    'haveTrainingDays'?: boolean | null;
    /**
     * 
     * @type {DietDefinition}
     * @memberof CustomerDietPreference
     */
    'dietType'?: DietDefinition;
    /**
     * 
     * @type {Array<DietExclusionRule>}
     * @memberof CustomerDietPreference
     */
    'dietExclusionRules'?: Array<DietExclusionRule> | null;
}
/**
 * 
 * @export
 * @interface CustomerMealServingTagPreference
 */
export interface CustomerMealServingTagPreference {
    /**
     * 
     * @type {number}
     * @memberof CustomerMealServingTagPreference
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof CustomerMealServingTagPreference
     */
    'tagId'?: number;
    /**
     * 
     * @type {string}
     * @memberof CustomerMealServingTagPreference
     */
    'tagName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerMealServingTagPreference
     */
    'settingsId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CustomerMealServingTagPreference
     */
    'mealTypeId'?: number;
    /**
     * 
     * @type {string}
     * @memberof CustomerMealServingTagPreference
     */
    'mealTypeName'?: string | null;
}
/**
 * 
 * @export
 * @interface CustomerSettings
 */
export interface CustomerSettings {
    /**
     * 
     * @type {number}
     * @memberof CustomerSettings
     */
    'customerId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerSettings
     */
    'settingsId'?: string;
    /**
     * 
     * @type {number}
     * @memberof CustomerSettings
     */
    'waist'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CustomerSettings
     */
    'weight'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CustomerSettings
     */
    'height'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CustomerSettings
     */
    'yearOfBirth'?: number | null;
    /**
     * 
     * @type {Gender}
     * @memberof CustomerSettings
     */
    'gender'?: Gender;
    /**
     * 
     * @type {DietObjective}
     * @memberof CustomerSettings
     */
    'dietObjective'?: DietObjective;
    /**
     * 
     * @type {string}
     * @memberof CustomerSettings
     */
    'dietStartUtc'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerSettings
     */
    'timezone'?: string | null;
    /**
     * 
     * @type {CustomerDietPreference}
     * @memberof CustomerSettings
     */
    'customerDietPrefence'?: CustomerDietPreference;
    /**
     * 
     * @type {Customer}
     * @memberof CustomerSettings
     */
    'customer'?: Customer;
    /**
     * 
     * @type {CustomerTrainingPreferences}
     * @memberof CustomerSettings
     */
    'customerTrainingPreferences'?: CustomerTrainingPreferences;
    /**
     * 
     * @type {Array<CustomerMealServingTagPreference>}
     * @memberof CustomerSettings
     */
    'customerMealServingTagPreferences'?: Array<CustomerMealServingTagPreference> | null;
}
/**
 * 
 * @export
 * @interface CustomerSubs
 */
export interface CustomerSubs {
    /**
     * 
     * @type {number}
     * @memberof CustomerSubs
     */
    'id'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerSubs
     */
    'trial'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CustomerSubs
     */
    'name'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerSubs
     */
    'active'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerSubs
     */
    'paid'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CustomerSubs
     */
    'startDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerSubs
     */
    'endDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerSubs
     */
    'mail'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CustomerSubs
     */
    'customerId'?: number;
    /**
     * 
     * @type {string}
     * @memberof CustomerSubs
     */
    'settingsId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerSubs
     */
    'hasDietDaysGenerated'?: boolean;
}
/**
 * 
 * @export
 * @interface CustomerTrainingPreferences
 */
export interface CustomerTrainingPreferences {
    /**
     * 
     * @type {number}
     * @memberof CustomerTrainingPreferences
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof CustomerTrainingPreferences
     */
    'customerSettingsId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerTrainingPreferences
     */
    'sportMode'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CustomerTrainingPreferences
     */
    'sunday'?: number;
    /**
     * 
     * @type {number}
     * @memberof CustomerTrainingPreferences
     */
    'monday'?: number;
    /**
     * 
     * @type {number}
     * @memberof CustomerTrainingPreferences
     */
    'tuesday'?: number;
    /**
     * 
     * @type {number}
     * @memberof CustomerTrainingPreferences
     */
    'wednesday'?: number;
    /**
     * 
     * @type {number}
     * @memberof CustomerTrainingPreferences
     */
    'thursday'?: number;
    /**
     * 
     * @type {number}
     * @memberof CustomerTrainingPreferences
     */
    'friday'?: number;
    /**
     * 
     * @type {number}
     * @memberof CustomerTrainingPreferences
     */
    'saturday'?: number;
    /**
     * 
     * @type {number}
     * @memberof CustomerTrainingPreferences
     */
    'trainingDays'?: number;
}
/**
 * 
 * @export
 * @interface DashboardSummary
 */
export interface DashboardSummary {
    /**
     * 
     * @type {number}
     * @memberof DashboardSummary
     */
    'productCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof DashboardSummary
     */
    'mealCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof DashboardSummary
     */
    'customers'?: number;
    /**
     * 
     * @type {number}
     * @memberof DashboardSummary
     */
    'registeredCustomers'?: number;
    /**
     * 
     * @type {number}
     * @memberof DashboardSummary
     */
    'activeSubscriptionsCount'?: number;
    /**
     * 
     * @type {Array<MealTotal>}
     * @memberof DashboardSummary
     */
    'mealTotals'?: Array<MealTotal> | null;
}
/**
 * 
 * @export
 * @interface DeleteDietDefinitionResponse
 */
export interface DeleteDietDefinitionResponse {
    /**
     * 
     * @type {number}
     * @memberof DeleteDietDefinitionResponse
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface DeleteExcludedProductResponse
 */
export interface DeleteExcludedProductResponse {
    /**
     * 
     * @type {number}
     * @memberof DeleteExcludedProductResponse
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface DeleteMealServigResponse
 */
export interface DeleteMealServigResponse {
    /**
     * 
     * @type {DietPlanDayMeal}
     * @memberof DeleteMealServigResponse
     */
    'dietPlanDayMeal'?: DietPlanDayMeal;
}
/**
 * 
 * @export
 * @interface DietBallanceDefinition
 */
export interface DietBallanceDefinition {
    /**
     * 
     * @type {number}
     * @memberof DietBallanceDefinition
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof DietBallanceDefinition
     */
    'nutritionFactDefinitionId'?: number;
    /**
     * 
     * @type {Gender}
     * @memberof DietBallanceDefinition
     */
    'gender'?: Gender;
    /**
     * 
     * @type {number}
     * @memberof DietBallanceDefinition
     */
    'unitOfMeasureId'?: number;
    /**
     * 
     * @type {number}
     * @memberof DietBallanceDefinition
     */
    'qty'?: number;
    /**
     * 
     * @type {number}
     * @memberof DietBallanceDefinition
     */
    'sortOrder'?: number;
    /**
     * 
     * @type {string}
     * @memberof DietBallanceDefinition
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DietBallanceDefinition
     */
    'articleUrl'?: string | null;
    /**
     * 
     * @type {UnitOfMeasure}
     * @memberof DietBallanceDefinition
     */
    'unitOfMeasure'?: UnitOfMeasure;
    /**
     * 
     * @type {NutritionFactDefinition}
     * @memberof DietBallanceDefinition
     */
    'nutritionFactDefinition'?: NutritionFactDefinition;
}
/**
 * 
 * @export
 * @interface DietBallanceItem
 */
export interface DietBallanceItem {
    /**
     * 
     * @type {string}
     * @memberof DietBallanceItem
     */
    'nutritionName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DietBallanceItem
     */
    'additionalInformation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DietBallanceItem
     */
    'group'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DietBallanceItem
     */
    'currentAmount'?: number;
    /**
     * 
     * @type {number}
     * @memberof DietBallanceItem
     */
    'minExpectedAmount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DietBallanceItem
     */
    'maxExpectedAmount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DietBallanceItem
     */
    'avgExpectedAmount'?: number;
    /**
     * 
     * @type {string}
     * @memberof DietBallanceItem
     */
    'uoMName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DietBallanceItem
     */
    'sortOrder'?: number;
    /**
     * 
     * @type {string}
     * @memberof DietBallanceItem
     */
    'articleUrl'?: string | null;
}
/**
 * 
 * @export
 * @interface DietDefinition
 */
export interface DietDefinition {
    /**
     * 
     * @type {number}
     * @memberof DietDefinition
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof DietDefinition
     */
    'name'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DietDefinition
     */
    'isDeleted'?: boolean;
}
/**
 * 
 * @export
 * @interface DietExclusionRule
 */
export interface DietExclusionRule {
    /**
     * 
     * @type {number}
     * @memberof DietExclusionRule
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof DietExclusionRule
     */
    'foodProductId'?: number;
    /**
     * 
     * @type {number}
     * @memberof DietExclusionRule
     */
    'dietDefinitionId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DietExclusionRule
     */
    'customerId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DietExclusionRule
     */
    'dietPreferenceId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DietExclusionRule
     */
    'name'?: string | null;
    /**
     * 
     * @type {Product}
     * @memberof DietExclusionRule
     */
    'foodProduct'?: Product;
    /**
     * 
     * @type {DietDefinition}
     * @memberof DietExclusionRule
     */
    'dietDefinition'?: DietDefinition;
    /**
     * 
     * @type {string}
     * @memberof DietExclusionRule
     */
    'createdDateUtc'?: string;
    /**
     * 
     * @type {string}
     * @memberof DietExclusionRule
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DietExclusionRule
     */
    'modifiedDateUtc'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DietExclusionRule
     */
    'modifiedBy'?: string | null;
}
/**
 *   0 = FAST_REDUCE_MASS_WEIGHT (FastReduceMassWeight)  1 = REDUCE_MASS_WEIGHT (ReduceMassWeight)  2 = KEEP_BALLANCED_DIET (KeepBallancedDiet)  3 = GAIN_MASS_WEIGHT (GainMassWeight)  4 = GAIN_MUSCLE_MASS_WEIGHT (GainMuscleMassWeight)
 * @export
 * @enum {string}
 */

export enum DietObjective {
    /**
    * FastReduceMassWeight
    */
    FAST_REDUCE_MASS_WEIGHT = 0,
    /**
    * ReduceMassWeight
    */
    REDUCE_MASS_WEIGHT = 1,
    /**
    * KeepBallancedDiet
    */
    KEEP_BALLANCED_DIET = 2,
    /**
    * GainMassWeight
    */
    GAIN_MASS_WEIGHT = 3,
    /**
    * GainMuscleMassWeight
    */
    GAIN_MUSCLE_MASS_WEIGHT = 4
}

/**
 * 
 * @export
 * @interface DietPlan
 */
export interface DietPlan {
    /**
     * 
     * @type {number}
     * @memberof DietPlan
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof DietPlan
     */
    'customerId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DietPlan
     */
    'dietPlanDefinitionId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DietPlan
     */
    'dietTypeId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DietPlan
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DietPlan
     */
    'startDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DietPlan
     */
    'endDate'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DietPlan
     */
    'trial'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof DietPlan
     */
    'mealExchangeCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof DietPlan
     */
    'contactCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof DietPlan
     */
    'caloriesBMR'?: number;
    /**
     * 
     * @type {number}
     * @memberof DietPlan
     */
    'caloriesTDEETrainingDay'?: number;
    /**
     * 
     * @type {number}
     * @memberof DietPlan
     */
    'caloriesTDEERestDay'?: number;
    /**
     * 
     * @type {number}
     * @memberof DietPlan
     */
    'caloriesTDEEWeeklyAverage'?: number;
    /**
     * 
     * @type {number}
     * @memberof DietPlan
     */
    'restDayMinFatInGrams'?: number;
    /**
     * 
     * @type {number}
     * @memberof DietPlan
     */
    'restDayMaxFatInGrams'?: number;
    /**
     * 
     * @type {number}
     * @memberof DietPlan
     */
    'restDayAvgFatInGrams'?: number;
    /**
     * 
     * @type {number}
     * @memberof DietPlan
     */
    'restDayMaxProteinInGramsPerMeal'?: number;
    /**
     * 
     * @type {number}
     * @memberof DietPlan
     */
    'restDayMinProteinInGramsDaily'?: number;
    /**
     * 
     * @type {number}
     * @memberof DietPlan
     */
    'restDayMaxProteinInGramsDaily'?: number;
    /**
     * 
     * @type {number}
     * @memberof DietPlan
     */
    'restDayAvgProteinInGramsDaily'?: number;
    /**
     * 
     * @type {number}
     * @memberof DietPlan
     */
    'restDayMinCarbohydratesInGramsDaily'?: number;
    /**
     * 
     * @type {number}
     * @memberof DietPlan
     */
    'restDayMaxCarbohydratesInGramsDaily'?: number;
    /**
     * 
     * @type {number}
     * @memberof DietPlan
     */
    'restDayAvgCarbohydratesInGramsDaily'?: number;
    /**
     * 
     * @type {number}
     * @memberof DietPlan
     */
    'trainingDayMinFatInGrams'?: number;
    /**
     * 
     * @type {number}
     * @memberof DietPlan
     */
    'trainingDayMaxFatInGrams'?: number;
    /**
     * 
     * @type {number}
     * @memberof DietPlan
     */
    'trainingDayAvgFatInGrams'?: number;
    /**
     * 
     * @type {number}
     * @memberof DietPlan
     */
    'trainingDayMaxProteinInGramsPerMeal'?: number;
    /**
     * 
     * @type {number}
     * @memberof DietPlan
     */
    'trainingDayMinProteinInGramsDaily'?: number;
    /**
     * 
     * @type {number}
     * @memberof DietPlan
     */
    'trainingDayMaxProteinInGramsDaily'?: number;
    /**
     * 
     * @type {number}
     * @memberof DietPlan
     */
    'trainingDayAvgProteinInGramsDaily'?: number;
    /**
     * 
     * @type {number}
     * @memberof DietPlan
     */
    'trainingDayMinCarbohydratesInGramsDaily'?: number;
    /**
     * 
     * @type {number}
     * @memberof DietPlan
     */
    'trainingDayMaxCarbohydratesInGramsDaily'?: number;
    /**
     * 
     * @type {number}
     * @memberof DietPlan
     */
    'trainingDayAvgCarbohydratesInGramsDaily'?: number;
    /**
     * 
     * @type {number}
     * @memberof DietPlan
     */
    'numberOfServingsPerDay'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof DietPlan
     */
    'mainMealForTwoDays'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof DietPlan
     */
    'dietDayForTwoDays'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof DietPlan
     */
    'lunchAndDinnerSameMeal'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof DietPlan
     */
    'haveExclusion'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof DietPlan
     */
    'haveTrainingDays'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof DietPlan
     */
    'generatedFrom'?: string | null;
    /**
     * 
     * @type {DietObjective}
     * @memberof DietPlan
     */
    'objective'?: DietObjective;
    /**
     * 
     * @type {number}
     * @memberof DietPlan
     */
    'objectiveId'?: number;
    /**
     * 
     * @type {number}
     * @memberof DietPlan
     */
    'height'?: number;
    /**
     * 
     * @type {number}
     * @memberof DietPlan
     */
    'weight'?: number;
    /**
     * 
     * @type {number}
     * @memberof DietPlan
     */
    'yearOfBirth'?: number;
    /**
     * 
     * @type {Gender}
     * @memberof DietPlan
     */
    'sex'?: Gender;
    /**
     * 
     * @type {number}
     * @memberof DietPlan
     */
    'activity'?: number;
    /**
     * 
     * @type {number}
     * @memberof DietPlan
     */
    'bodyFat'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DietPlan
     */
    'waist'?: number | null;
    /**
     * 
     * @type {Array<DietPlanDay>}
     * @memberof DietPlan
     */
    'dietPlanDays'?: Array<DietPlanDay> | null;
}
/**
 * 
 * @export
 * @interface DietPlanDay
 */
export interface DietPlanDay {
    /**
     * 
     * @type {number}
     * @memberof DietPlanDay
     */
    'dietPlanId'?: number;
    /**
     * 
     * @type {number}
     * @memberof DietPlanDay
     */
    'dietDayId'?: number;
    /**
     * 
     * @type {number}
     * @memberof DietPlanDay
     */
    'mealsCount'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof DietPlanDay
     */
    'isTrainingDay'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof DietPlanDay
     */
    'trainingsNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof DietPlanDay
     */
    'doneTrainingsCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof DietPlanDay
     */
    'dayDateUtc'?: string;
    /**
     * 
     * @type {Array<DietPlanDayMeal>}
     * @memberof DietPlanDay
     */
    'dietPlanDayMeals'?: Array<DietPlanDayMeal> | null;
    /**
     * 
     * @type {number}
     * @memberof DietPlanDay
     */
    'qtyInGrams'?: number;
    /**
     * 
     * @type {number}
     * @memberof DietPlanDay
     */
    'carbs'?: number;
    /**
     * 
     * @type {number}
     * @memberof DietPlanDay
     */
    'proteins'?: number;
    /**
     * 
     * @type {number}
     * @memberof DietPlanDay
     */
    'fats'?: number;
    /**
     * 
     * @type {number}
     * @memberof DietPlanDay
     */
    'energy'?: number;
    /**
     * 
     * @type {number}
     * @memberof DietPlanDay
     */
    'proteinsInCalories'?: number;
    /**
     * 
     * @type {number}
     * @memberof DietPlanDay
     */
    'fatsInCalories'?: number;
    /**
     * 
     * @type {number}
     * @memberof DietPlanDay
     */
    'carbsInCalories'?: number;
    /**
     * 
     * @type {number}
     * @memberof DietPlanDay
     */
    'carbsIn100Grams'?: number;
    /**
     * 
     * @type {number}
     * @memberof DietPlanDay
     */
    'proteinsIn100Grams'?: number;
    /**
     * 
     * @type {number}
     * @memberof DietPlanDay
     */
    'fatsIn100Grams'?: number;
    /**
     * 
     * @type {number}
     * @memberof DietPlanDay
     */
    'energyIn100Grams'?: number;
    /**
     * 
     * @type {number}
     * @memberof DietPlanDay
     */
    'carbsFactor'?: number;
    /**
     * 
     * @type {number}
     * @memberof DietPlanDay
     */
    'proteinsFactor'?: number;
    /**
     * 
     * @type {number}
     * @memberof DietPlanDay
     */
    'fatFactor'?: number;
    /**
     * 
     * @type {number}
     * @memberof DietPlanDay
     */
    'carbsAndProteinFactor'?: number;
    /**
     * 
     * @type {number}
     * @memberof DietPlanDay
     */
    'carbsToProteinFactorRatio'?: number;
    /**
     * 
     * @type {number}
     * @memberof DietPlanDay
     */
    'proteinToCarbsFactorRatio'?: number;
    /**
     * 
     * @type {number}
     * @memberof DietPlanDay
     */
    'carbsAndFatFactor'?: number;
    /**
     * 
     * @type {number}
     * @memberof DietPlanDay
     */
    'carbsToFatFactorRatio'?: number;
    /**
     * 
     * @type {number}
     * @memberof DietPlanDay
     */
    'fatToCarbsFactorRatio'?: number;
    /**
     * 
     * @type {number}
     * @memberof DietPlanDay
     */
    'proteinAndFatFactor'?: number;
    /**
     * 
     * @type {number}
     * @memberof DietPlanDay
     */
    'proteinToFatFactorRatio'?: number;
    /**
     * 
     * @type {number}
     * @memberof DietPlanDay
     */
    'fatToProteinFactorRatio'?: number;
    /**
     * 
     * @type {Classification}
     * @memberof DietPlanDay
     */
    'classification'?: Classification;
}
/**
 * 
 * @export
 * @interface DietPlanDayMeal
 */
export interface DietPlanDayMeal {
    /**
     * 
     * @type {number}
     * @memberof DietPlanDayMeal
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof DietPlanDayMeal
     */
    'dietPlanId'?: number;
    /**
     * 
     * @type {number}
     * @memberof DietPlanDayMeal
     */
    'dietDayId'?: number;
    /**
     * 
     * @type {number}
     * @memberof DietPlanDayMeal
     */
    'mealId'?: number;
    /**
     * 
     * @type {number}
     * @memberof DietPlanDayMeal
     */
    'mostProteinProductId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DietPlanDayMeal
     */
    'mostFatProductId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DietPlanDayMeal
     */
    'mostCarbohydrateProductId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DietPlanDayMeal
     */
    'unitOfHomeMeasureId'?: number | null;
    /**
     * 
     * @type {MealTypeEnum}
     * @memberof DietPlanDayMeal
     */
    'mealType'?: MealTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof DietPlanDayMeal
     */
    'isMealEaten'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DietPlanDayMeal
     */
    'isFavourite'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof DietPlanDayMeal
     */
    'qtyInGrams'?: number;
    /**
     * 
     * @type {number}
     * @memberof DietPlanDayMeal
     */
    'qtyInHomeMeasurement'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DietPlanDayMeal
     */
    'qtyInText'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DietPlanDayMeal
     */
    'carbs'?: number;
    /**
     * 
     * @type {number}
     * @memberof DietPlanDayMeal
     */
    'proteins'?: number;
    /**
     * 
     * @type {number}
     * @memberof DietPlanDayMeal
     */
    'fats'?: number;
    /**
     * 
     * @type {number}
     * @memberof DietPlanDayMeal
     */
    'energy'?: number;
    /**
     * 
     * @type {number}
     * @memberof DietPlanDayMeal
     */
    'proteinsInCalories'?: number;
    /**
     * 
     * @type {number}
     * @memberof DietPlanDayMeal
     */
    'fatsInCalories'?: number;
    /**
     * 
     * @type {number}
     * @memberof DietPlanDayMeal
     */
    'carbsInCalories'?: number;
    /**
     * 
     * @type {number}
     * @memberof DietPlanDayMeal
     */
    'carbsIn100Grams'?: number;
    /**
     * 
     * @type {number}
     * @memberof DietPlanDayMeal
     */
    'proteinsIn100Grams'?: number;
    /**
     * 
     * @type {number}
     * @memberof DietPlanDayMeal
     */
    'fatsIn100Grams'?: number;
    /**
     * 
     * @type {number}
     * @memberof DietPlanDayMeal
     */
    'energyIn100Grams'?: number;
    /**
     * 
     * @type {number}
     * @memberof DietPlanDayMeal
     */
    'carbsFactor'?: number;
    /**
     * 
     * @type {number}
     * @memberof DietPlanDayMeal
     */
    'proteinsFactor'?: number;
    /**
     * 
     * @type {number}
     * @memberof DietPlanDayMeal
     */
    'fatFactor'?: number;
    /**
     * 
     * @type {number}
     * @memberof DietPlanDayMeal
     */
    'carbsAndProteinFactor'?: number;
    /**
     * 
     * @type {number}
     * @memberof DietPlanDayMeal
     */
    'carbsAndFatFactor'?: number;
    /**
     * 
     * @type {number}
     * @memberof DietPlanDayMeal
     */
    'proteinAndFatFactor'?: number;
    /**
     * 
     * @type {Classification}
     * @memberof DietPlanDayMeal
     */
    'classification'?: Classification;
    /**
     * 
     * @type {Array<MealServing>}
     * @memberof DietPlanDayMeal
     */
    'mealServings'?: Array<MealServing> | null;
    /**
     * 
     * @type {Meal}
     * @memberof DietPlanDayMeal
     */
    'meal'?: Meal;
    /**
     * 
     * @type {number}
     * @memberof DietPlanDayMeal
     */
    'mostProteinFatProductId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DietPlanDayMeal
     */
    'mostCarbohydrateFatProductId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DietPlanDayMeal
     */
    'mostCarbohydrateProteinProductId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DietPlanDayMeal
     */
    'carbsToProteinFactorRatio'?: number;
    /**
     * 
     * @type {number}
     * @memberof DietPlanDayMeal
     */
    'proteinToCarbsFactorRatio'?: number;
    /**
     * 
     * @type {number}
     * @memberof DietPlanDayMeal
     */
    'carbsToFatFactorRatio'?: number;
    /**
     * 
     * @type {number}
     * @memberof DietPlanDayMeal
     */
    'fatToCarbsFactorRatio'?: number;
    /**
     * 
     * @type {number}
     * @memberof DietPlanDayMeal
     */
    'proteinToFatFactorRatio'?: number;
    /**
     * 
     * @type {number}
     * @memberof DietPlanDayMeal
     */
    'fatToProteinFactorRatio'?: number;
}
/**
 * 
 * @export
 * @interface DietPlanDefinition
 */
export interface DietPlanDefinition {
    /**
     * 
     * @type {number}
     * @memberof DietPlanDefinition
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof DietPlanDefinition
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DietPlanDefinition
     */
    'priceNet'?: number;
    /**
     * 
     * @type {number}
     * @memberof DietPlanDefinition
     */
    'dietDays'?: number;
    /**
     * 
     * @type {number}
     * @memberof DietPlanDefinition
     */
    'maximumMealExchangeCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof DietPlanDefinition
     */
    'maximumContactCount'?: number;
}
/**
 * 
 * @export
 * @interface DietWeekPlanSummary
 */
export interface DietWeekPlanSummary {
    /**
     * 
     * @type {number}
     * @memberof DietWeekPlanSummary
     */
    'dietPlanId'?: number;
    /**
     * 
     * @type {number}
     * @memberof DietWeekPlanSummary
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof DietWeekPlanSummary
     */
    'customerId'?: number;
    /**
     * 
     * @type {number}
     * @memberof DietWeekPlanSummary
     */
    'dietDay'?: number;
    /**
     * 
     * @type {string}
     * @memberof DietWeekPlanSummary
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DietWeekPlanSummary
     */
    'day'?: string;
    /**
     * 
     * @type {string}
     * @memberof DietWeekPlanSummary
     */
    'mail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DietWeekPlanSummary
     */
    'dayName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DietWeekPlanSummary
     */
    'fats'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DietWeekPlanSummary
     */
    'carbs'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DietWeekPlanSummary
     */
    'proteins'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DietWeekPlanSummary
     */
    'energy'?: number | null;
}
/**
 * 
 * @export
 * @interface DiscountCode
 */
export interface DiscountCode {
    /**
     * 
     * @type {number}
     * @memberof DiscountCode
     */
    'dietPlanDefinitionId'?: number;
    /**
     * 
     * @type {string}
     * @memberof DiscountCode
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DiscountCode
     */
    'description'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DiscountCode
     */
    'priceAfterDiscount'?: number;
    /**
     * 
     * @type {number}
     * @memberof DiscountCode
     */
    'discountInPercents'?: number;
    /**
     * 
     * @type {string}
     * @memberof DiscountCode
     */
    'fromDateUtc'?: string;
    /**
     * 
     * @type {string}
     * @memberof DiscountCode
     */
    'toDateUtc'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DiscountCode
     */
    'onlyFromUrl'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof DiscountCode
     */
    'canBeCombinedWithOtherCodes'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof DiscountCode
     */
    'isDefaultDiscount'?: boolean | null;
}
/**
 * 
 * @export
 * @interface Document
 */
export interface Document {
    /**
     * 
     * @type {number}
     * @memberof Document
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    'documentType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    'subTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    'documentData'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Document
     */
    'fileCatalog'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    'pictureOriginal'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    'pictureThumbnail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    'pictureModified'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    'url'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    'createdDateUtc'?: string;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    'modifiedDateUtc'?: string | null;
}
/**
 * 
 * @export
 * @interface EnableOrDisableRestaurantMealResponse
 */
export interface EnableOrDisableRestaurantMealResponse {
    /**
     * 
     * @type {Meal}
     * @memberof EnableOrDisableRestaurantMealResponse
     */
    'meal'?: Meal;
}
/**
 * 
 * @export
 * @interface EnableOrDisableRestaurantResponse
 */
export interface EnableOrDisableRestaurantResponse {
    /**
     * 
     * @type {Restaurant}
     * @memberof EnableOrDisableRestaurantResponse
     */
    'restaurant'?: Restaurant;
}
/**
 * 
 * @export
 * @interface ExceptionHandlingResultModel
 */
export interface ExceptionHandlingResultModel {
    /**
     * 
     * @type {string}
     * @memberof ExceptionHandlingResultModel
     */
    'message'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ExceptionHandlingResultModel
     */
    'errorCode'?: number;
    /**
     * 
     * @type {Array<ExceptionOrValidationError>}
     * @memberof ExceptionHandlingResultModel
     */
    'errors'?: Array<ExceptionOrValidationError> | null;
}
/**
 * 
 * @export
 * @interface ExceptionOrValidationError
 */
export interface ExceptionOrValidationError {
    /**
     * 
     * @type {string}
     * @memberof ExceptionOrValidationError
     */
    'field'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExceptionOrValidationError
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @interface FdcProductCategory
 */
export interface FdcProductCategory {
    /**
     * 
     * @type {number}
     * @memberof FdcProductCategory
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FdcProductCategory
     */
    'categoryCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FdcProductCategory
     */
    'categoryDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FdcProductCategory
     */
    'translatedCategoryDescription'?: string | null;
}
/**
 * 
 * @export
 * @interface FdcProductSummary
 */
export interface FdcProductSummary {
    /**
     * 
     * @type {number}
     * @memberof FdcProductSummary
     */
    'foodFdcId'?: number;
    /**
     * 
     * @type {string}
     * @memberof FdcProductSummary
     */
    'categoryCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FdcProductSummary
     */
    'categoryDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FdcProductSummary
     */
    'categoryDescriptionPL'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FdcProductSummary
     */
    'foodDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FdcProductSummary
     */
    'foodDescriptionPL'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FdcProductSummary
     */
    'dataType'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FdcProductSummary
     */
    'carbohydrate'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FdcProductSummary
     */
    'energyKcal'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FdcProductSummary
     */
    'protein'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FdcProductSummary
     */
    'totalLipidFat'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FdcProductSummary
     */
    'translatedCategoryDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FdcProductSummary
     */
    'translatedFoodDescription'?: string | null;
}
/**
 *   0 = Male  1 = Female
 * @export
 * @enum {string}
 */

export enum Gender {
    /**
    * 
    */
    Male = 0,
    /**
    * 
    */
    Female = 1
}

/**
 * 
 * @export
 * @interface GetAllHomeMeasurementRulesResponse
 */
export interface GetAllHomeMeasurementRulesResponse {
    /**
     * 
     * @type {Array<HomeMeasurementRule>}
     * @memberof GetAllHomeMeasurementRulesResponse
     */
    'homeMeasurementRules'?: Array<HomeMeasurementRule> | null;
}
/**
 * 
 * @export
 * @interface GetAllProductExchangeGroupsResponse
 */
export interface GetAllProductExchangeGroupsResponse {
    /**
     * 
     * @type {Array<ProductExchangeGroup>}
     * @memberof GetAllProductExchangeGroupsResponse
     */
    'productExchangeGroups'?: Array<ProductExchangeGroup> | null;
}
/**
 * 
 * @export
 * @interface GetAuditLogResponse
 */
export interface GetAuditLogResponse {
    /**
     * 
     * @type {Array<AuditLog>}
     * @memberof GetAuditLogResponse
     */
    'results'?: Array<AuditLog> | null;
    /**
     * 
     * @type {number}
     * @memberof GetAuditLogResponse
     */
    'count'?: number;
}
/**
 * 
 * @export
 * @interface GetAuditLogTypesResponse
 */
export interface GetAuditLogTypesResponse {
    /**
     * 
     * @type {Array<string>}
     * @memberof GetAuditLogTypesResponse
     */
    'types'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface GetAvailableDietPlansResponse
 */
export interface GetAvailableDietPlansResponse {
    /**
     * 
     * @type {Array<DietPlanDefinition>}
     * @memberof GetAvailableDietPlansResponse
     */
    'plans'?: Array<DietPlanDefinition> | null;
}
/**
 * 
 * @export
 * @interface GetAvailableDietsResponse
 */
export interface GetAvailableDietsResponse {
    /**
     * 
     * @type {Array<DietDefinition>}
     * @memberof GetAvailableDietsResponse
     */
    'definitions'?: Array<DietDefinition> | null;
}
/**
 * 
 * @export
 * @interface GetBeveragesResponse
 */
export interface GetBeveragesResponse {
    /**
     * 
     * @type {Array<Meal>}
     * @memberof GetBeveragesResponse
     */
    'beverages'?: Array<Meal> | null;
}
/**
 * 
 * @export
 * @interface GetCurrentOrderResponse
 */
export interface GetCurrentOrderResponse {
    /**
     * 
     * @type {CurrentOrder}
     * @memberof GetCurrentOrderResponse
     */
    'currentOrder'?: CurrentOrder;
}
/**
 * 
 * @export
 * @interface GetCustomerIdResponse
 */
export interface GetCustomerIdResponse {
    /**
     * 
     * @type {number}
     * @memberof GetCustomerIdResponse
     */
    'id'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetCustomerIdResponse
     */
    'dietPlanId'?: number | null;
}
/**
 * 
 * @export
 * @interface GetCustomerResponse
 */
export interface GetCustomerResponse {
    /**
     * 
     * @type {Customer}
     * @memberof GetCustomerResponse
     */
    'customer'?: Customer;
}
/**
 * 
 * @export
 * @interface GetCustomerSettingsAndPreferencesResponse
 */
export interface GetCustomerSettingsAndPreferencesResponse {
    /**
     * 
     * @type {CustomerSettings}
     * @memberof GetCustomerSettingsAndPreferencesResponse
     */
    'customerSettings'?: CustomerSettings;
}
/**
 * 
 * @export
 * @interface GetCustomerSubsResponse
 */
export interface GetCustomerSubsResponse {
    /**
     * 
     * @type {Array<CustomerSubs>}
     * @memberof GetCustomerSubsResponse
     */
    'customerSubs'?: Array<CustomerSubs> | null;
}
/**
 * 
 * @export
 * @interface GetDashboardSummaryResponse
 */
export interface GetDashboardSummaryResponse {
    /**
     * 
     * @type {DashboardSummary}
     * @memberof GetDashboardSummaryResponse
     */
    'dashboardSummary'?: DashboardSummary;
}
/**
 * 
 * @export
 * @interface GetDayPlanMealsResponse
 */
export interface GetDayPlanMealsResponse {
    /**
     * 
     * @type {Array<DietPlanDayMeal>}
     * @memberof GetDayPlanMealsResponse
     */
    'customerDayPlanMeals'?: Array<DietPlanDayMeal> | null;
}
/**
 * 
 * @export
 * @interface GetDefaultDiscountCodesResponse
 */
export interface GetDefaultDiscountCodesResponse {
    /**
     * 
     * @type {Array<DiscountCode>}
     * @memberof GetDefaultDiscountCodesResponse
     */
    'discountCodes'?: Array<DiscountCode> | null;
}
/**
 * 
 * @export
 * @interface GetDietBallanceDefinitionsResponse
 */
export interface GetDietBallanceDefinitionsResponse {
    /**
     * 
     * @type {Array<DietBallanceDefinition>}
     * @memberof GetDietBallanceDefinitionsResponse
     */
    'dietBallanceDefinitions'?: Array<DietBallanceDefinition> | null;
}
/**
 * 
 * @export
 * @interface GetDietBallanceItems
 */
export interface GetDietBallanceItems {
    /**
     * 
     * @type {number}
     * @memberof GetDietBallanceItems
     */
    'dietPlanId'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetDietBallanceItems
     */
    'startDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetDietBallanceItems
     */
    'endDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetDietBallanceItems
     */
    'dpdmId'?: number | null;
}
/**
 * 
 * @export
 * @interface GetDietBallanceItemsResponse
 */
export interface GetDietBallanceItemsResponse {
    /**
     * 
     * @type {Array<DietBallanceItem>}
     * @memberof GetDietBallanceItemsResponse
     */
    'dietBallanceItems'?: Array<DietBallanceItem> | null;
}
/**
 * 
 * @export
 * @interface GetDietCalculationForCustomerResponse
 */
export interface GetDietCalculationForCustomerResponse {
    /**
     * 
     * @type {DietPlan}
     * @memberof GetDietCalculationForCustomerResponse
     */
    'dietPlan'?: DietPlan;
    /**
     * 
     * @type {CustomerTrainingPreferences}
     * @memberof GetDietCalculationForCustomerResponse
     */
    'trainingPreferences'?: CustomerTrainingPreferences;
}
/**
 * 
 * @export
 * @interface GetDietDefinitionsResponse
 */
export interface GetDietDefinitionsResponse {
    /**
     * 
     * @type {Array<DietDefinition>}
     * @memberof GetDietDefinitionsResponse
     */
    'dietDefinition'?: Array<DietDefinition> | null;
}
/**
 * 
 * @export
 * @interface GetDietDefintionTagsResponse
 */
export interface GetDietDefintionTagsResponse {
    /**
     * 
     * @type {Array<DietDefinition>}
     * @memberof GetDietDefintionTagsResponse
     */
    'dietDefinitions'?: Array<DietDefinition> | null;
    /**
     * 
     * @type {Tag}
     * @memberof GetDietDefintionTagsResponse
     */
    'tag'?: Tag;
}
/**
 * 
 * @export
 * @interface GetDietPlanDayMealServingsResponse
 */
export interface GetDietPlanDayMealServingsResponse {
    /**
     * 
     * @type {Array<MealServingWithNutritionSummary>}
     * @memberof GetDietPlanDayMealServingsResponse
     */
    'mealServings'?: Array<MealServingWithNutritionSummary> | null;
}
/**
 * 
 * @export
 * @interface GetDietPlanDayResponse
 */
export interface GetDietPlanDayResponse {
    /**
     * 
     * @type {DietPlanDay}
     * @memberof GetDietPlanDayResponse
     */
    'dietPlan'?: DietPlanDay;
}
/**
 * 
 * @export
 * @interface GetDietPlanResponse
 */
export interface GetDietPlanResponse {
    /**
     * 
     * @type {DietPlan}
     * @memberof GetDietPlanResponse
     */
    'dietPlan'?: DietPlan;
}
/**
 * 
 * @export
 * @interface GetDocumentByIdResponse
 */
export interface GetDocumentByIdResponse {
    /**
     * 
     * @type {Document}
     * @memberof GetDocumentByIdResponse
     */
    'document'?: Document;
}
/**
 * 
 * @export
 * @interface GetDocumentByUrlResponse
 */
export interface GetDocumentByUrlResponse {
    /**
     * 
     * @type {Document}
     * @memberof GetDocumentByUrlResponse
     */
    'document'?: Document;
}
/**
 * 
 * @export
 * @interface GetDocuments
 */
export interface GetDocuments {
    /**
     * 
     * @type {string}
     * @memberof GetDocuments
     */
    'documentType'?: string | null;
}
/**
 * 
 * @export
 * @interface GetDocumentsResponse
 */
export interface GetDocumentsResponse {
    /**
     * 
     * @type {Array<Document>}
     * @memberof GetDocumentsResponse
     */
    'documents'?: Array<Document> | null;
}
/**
 * 
 * @export
 * @interface GetFindResponse
 */
export interface GetFindResponse {
    /**
     * 
     * @type {Array<SearchResult>}
     * @memberof GetFindResponse
     */
    'results'?: Array<SearchResult> | null;
}
/**
 * 
 * @export
 * @interface GetFindUsdaProductResponse
 */
export interface GetFindUsdaProductResponse {
    /**
     * 
     * @type {Array<FdcProductSummary>}
     * @memberof GetFindUsdaProductResponse
     */
    'results'?: Array<FdcProductSummary> | null;
}
/**
 * 
 * @export
 * @interface GetHomeMeasurementRulesForProductResponse
 */
export interface GetHomeMeasurementRulesForProductResponse {
    /**
     * 
     * @type {Array<HomeMeasurementRule>}
     * @memberof GetHomeMeasurementRulesForProductResponse
     */
    'homeMeasurementRules'?: Array<HomeMeasurementRule> | null;
}
/**
 * 
 * @export
 * @interface GetInvoicesResponse
 */
export interface GetInvoicesResponse {
    /**
     * 
     * @type {Array<Invoice>}
     * @memberof GetInvoicesResponse
     */
    'invoices'?: Array<Invoice> | null;
}
/**
 * 
 * @export
 * @interface GetLoggedInUserDataResponse
 */
export interface GetLoggedInUserDataResponse {
    /**
     * 
     * @type {string}
     * @memberof GetLoggedInUserDataResponse
     */
    'gravatar'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetLoggedInUserDataResponse
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetLoggedInUserDataResponse
     */
    'userId'?: number;
}
/**
 * 
 * @export
 * @interface GetMatches
 */
export interface GetMatches {
    /**
     * 
     * @type {string}
     * @memberof GetMatches
     */
    'dietSettings'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMatches
     */
    'startDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetMatches
     */
    'dietTypeId'?: number | null;
}
/**
 * 
 * @export
 * @interface GetMatches2
 */
export interface GetMatches2 {
    /**
     * 
     * @type {string}
     * @memberof GetMatches2
     */
    'dietSettings'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMatches2
     */
    'startDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetMatches2
     */
    'dietTypeId'?: number | null;
}
/**
 * 
 * @export
 * @interface GetMatches2Response
 */
export interface GetMatches2Response {
    /**
     * 
     * @type {Array<DietPlanDay>}
     * @memberof GetMatches2Response
     */
    'data'?: Array<DietPlanDay> | null;
}
/**
 * 
 * @export
 * @interface GetMatchesResponse
 */
export interface GetMatchesResponse {
    /**
     * 
     * @type {Array<DietPlanDay>}
     * @memberof GetMatchesResponse
     */
    'data'?: Array<DietPlanDay> | null;
}
/**
 * 
 * @export
 * @interface GetMealByIdResponse
 */
export interface GetMealByIdResponse {
    /**
     * 
     * @type {Meal}
     * @memberof GetMealByIdResponse
     */
    'meal'?: Meal;
    /**
     * 
     * @type {Array<MasterMealProposals>}
     * @memberof GetMealByIdResponse
     */
    'slavesProposals'?: Array<MasterMealProposals> | null;
    /**
     * 
     * @type {Array<MasterMealProposals>}
     * @memberof GetMealByIdResponse
     */
    'slaves'?: Array<MasterMealProposals> | null;
}
/**
 * 
 * @export
 * @interface GetMealDefinitionsResponse
 */
export interface GetMealDefinitionsResponse {
    /**
     * 
     * @type {Array<Meal>}
     * @memberof GetMealDefinitionsResponse
     */
    'meals'?: Array<Meal> | null;
}
/**
 * 
 * @export
 * @interface GetMealSearchResponse
 */
export interface GetMealSearchResponse {
    /**
     * 
     * @type {Array<MealSearchItem>}
     * @memberof GetMealSearchResponse
     */
    'results'?: Array<MealSearchItem> | null;
    /**
     * 
     * @type {number}
     * @memberof GetMealSearchResponse
     */
    'count'?: number;
}
/**
 * 
 * @export
 * @interface GetMealServingsExchangeProposals
 */
export interface GetMealServingsExchangeProposals {
    /**
     * 
     * @type {Array<CaloriesWithProductId>}
     * @memberof GetMealServingsExchangeProposals
     */
    'caloriesWithProductIds'?: Array<CaloriesWithProductId> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof GetMealServingsExchangeProposals
     */
    'excludedFoodProductsIds'?: Array<number> | null;
}
/**
 * 
 * @export
 * @interface GetMealServingsExchangeProposalsResponse
 */
export interface GetMealServingsExchangeProposalsResponse {
    /**
     * 
     * @type {Array<MealServingsProposalsForProductId>}
     * @memberof GetMealServingsExchangeProposalsResponse
     */
    'mealServingsForProductIds'?: Array<MealServingsProposalsForProductId> | null;
}
/**
 * 
 * @export
 * @interface GetMealTypesByNumberOfServingsResponse
 */
export interface GetMealTypesByNumberOfServingsResponse {
    /**
     * 
     * @type {Array<MealTypeEnum>}
     * @memberof GetMealTypesByNumberOfServingsResponse
     */
    'mealTypes'?: Array<MealTypeEnum> | null;
}
/**
 * 
 * @export
 * @interface GetMealsExchangeProposalsResponse
 */
export interface GetMealsExchangeProposalsResponse {
    /**
     * 
     * @type {Array<MealExchange>}
     * @memberof GetMealsExchangeProposalsResponse
     */
    'mealProposals'?: Array<MealExchange> | null;
}
/**
 * 
 * @export
 * @interface GetMealsForWeek
 */
export interface GetMealsForWeek {
    /**
     * 
     * @type {number}
     * @memberof GetMealsForWeek
     */
    'dietPlanId'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetMealsForWeek
     */
    'dietDay'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetMealsForWeek
     */
    'customerId'?: number;
}
/**
 * 
 * @export
 * @interface GetMealsForWeekResponse
 */
export interface GetMealsForWeekResponse {
    /**
     * 
     * @type {Array<WeekViewDay>}
     * @memberof GetMealsForWeekResponse
     */
    'dietPlans'?: Array<WeekViewDay> | null;
}
/**
 * 
 * @export
 * @interface GetMealsResponse
 */
export interface GetMealsResponse {
    /**
     * 
     * @type {Array<Meal>}
     * @memberof GetMealsResponse
     */
    'meals'?: Array<Meal> | null;
}
/**
 * 
 * @export
 * @interface GetNearbyRestaurantsResponse
 */
export interface GetNearbyRestaurantsResponse {
    /**
     * 
     * @type {Array<Restaurant>}
     * @memberof GetNearbyRestaurantsResponse
     */
    'restaurants'?: Array<Restaurant> | null;
}
/**
 * 
 * @export
 * @interface GetNutritionFactDefinitionsResponse
 */
export interface GetNutritionFactDefinitionsResponse {
    /**
     * 
     * @type {Array<NutritionFactDefinition>}
     * @memberof GetNutritionFactDefinitionsResponse
     */
    'nutritionFactDefinitions'?: Array<NutritionFactDefinition> | null;
}
/**
 * 
 * @export
 * @interface GetOrdersResponse
 */
export interface GetOrdersResponse {
    /**
     * 
     * @type {Array<Order>}
     * @memberof GetOrdersResponse
     */
    'orders'?: Array<Order> | null;
}
/**
 * 
 * @export
 * @interface GetPaymentChannelsResponse
 */
export interface GetPaymentChannelsResponse {
    /**
     * 
     * @type {Array<PaymentChannel>}
     * @memberof GetPaymentChannelsResponse
     */
    'paymentChannels'?: Array<PaymentChannel> | null;
}
/**
 * 
 * @export
 * @interface GetPaymentsResponse
 */
export interface GetPaymentsResponse {
    /**
     * 
     * @type {Array<Payment>}
     * @memberof GetPaymentsResponse
     */
    'payments'?: Array<Payment> | null;
}
/**
 * 
 * @export
 * @interface GetProductByIdResponse
 */
export interface GetProductByIdResponse {
    /**
     * 
     * @type {Product}
     * @memberof GetProductByIdResponse
     */
    'product'?: Product;
}
/**
 * 
 * @export
 * @interface GetProductByProductGroupResponse
 */
export interface GetProductByProductGroupResponse {
    /**
     * 
     * @type {Array<Product>}
     * @memberof GetProductByProductGroupResponse
     */
    'products'?: Array<Product> | null;
}
/**
 * 
 * @export
 * @interface GetProductGroupsResponse
 */
export interface GetProductGroupsResponse {
    /**
     * 
     * @type {Array<ProductGroup>}
     * @memberof GetProductGroupsResponse
     */
    'productGroups'?: Array<ProductGroup> | null;
}
/**
 * 
 * @export
 * @interface GetProductHomeMeasurementResponse
 */
export interface GetProductHomeMeasurementResponse {
    /**
     * 
     * @type {string}
     * @memberof GetProductHomeMeasurementResponse
     */
    'measurement'?: string | null;
}
/**
 * 
 * @export
 * @interface GetProductNutrionSummaryResponse
 */
export interface GetProductNutrionSummaryResponse {
    /**
     * 
     * @type {ProductWithNutritionSummary}
     * @memberof GetProductNutrionSummaryResponse
     */
    'productWithNutritionSummary'?: ProductWithNutritionSummary;
}
/**
 * 
 * @export
 * @interface GetProductRefAndNamesByDietNameResponse
 */
export interface GetProductRefAndNamesByDietNameResponse {
    /**
     * 
     * @type {Array<ProductRefNamePair>}
     * @memberof GetProductRefAndNamesByDietNameResponse
     */
    'refNamePair'?: Array<ProductRefNamePair> | null;
}
/**
 * 
 * @export
 * @interface GetProductRefAndNamesResponse
 */
export interface GetProductRefAndNamesResponse {
    /**
     * 
     * @type {Array<ProductRefNamePair>}
     * @memberof GetProductRefAndNamesResponse
     */
    'refNamePair'?: Array<ProductRefNamePair> | null;
}
/**
 * 
 * @export
 * @interface GetProductServingRulesResponse
 */
export interface GetProductServingRulesResponse {
    /**
     * 
     * @type {Array<ProductServingRule>}
     * @memberof GetProductServingRulesResponse
     */
    'productServingRules'?: Array<ProductServingRule> | null;
}
/**
 * 
 * @export
 * @interface GetProductTagsResponse
 */
export interface GetProductTagsResponse {
    /**
     * 
     * @type {Array<ProductTag>}
     * @memberof GetProductTagsResponse
     */
    'tags'?: Array<ProductTag> | null;
    /**
     * 
     * @type {Tag}
     * @memberof GetProductTagsResponse
     */
    'tag'?: Tag;
}
/**
 * 
 * @export
 * @interface GetProductsResponse
 */
export interface GetProductsResponse {
    /**
     * 
     * @type {Array<Product>}
     * @memberof GetProductsResponse
     */
    'products'?: Array<Product> | null;
}
/**
 * 
 * @export
 * @interface GetRegisteredCustomersResponse
 */
export interface GetRegisteredCustomersResponse {
    /**
     * 
     * @type {Array<RegisteredCustomer>}
     * @memberof GetRegisteredCustomersResponse
     */
    'registeredCustomer'?: Array<RegisteredCustomer> | null;
}
/**
 * 
 * @export
 * @interface GetReplacementProductResponse
 */
export interface GetReplacementProductResponse {
    /**
     * 
     * @type {Array<Product>}
     * @memberof GetReplacementProductResponse
     */
    'replacements'?: Array<Product> | null;
}
/**
 * 
 * @export
 * @interface GetRequestConversationHistoryResponse
 */
export interface GetRequestConversationHistoryResponse {
    /**
     * 
     * @type {Array<Request>}
     * @memberof GetRequestConversationHistoryResponse
     */
    'customerRequests'?: Array<Request> | null;
}
/**
 * 
 * @export
 * @interface GetRequests
 */
export interface GetRequests {
    /**
     * 
     * @type {RequestType}
     * @memberof GetRequests
     */
    'requestType'?: RequestType;
}
/**
 * 
 * @export
 * @interface GetRequestsResponse
 */
export interface GetRequestsResponse {
    /**
     * 
     * @type {Array<Request>}
     * @memberof GetRequestsResponse
     */
    'requests'?: Array<Request> | null;
}
/**
 * 
 * @export
 * @interface GetRestaurantByContextResponse
 */
export interface GetRestaurantByContextResponse {
    /**
     * 
     * @type {Restaurant}
     * @memberof GetRestaurantByContextResponse
     */
    'restaurant'?: Restaurant;
}
/**
 * 
 * @export
 * @interface GetRestaurantByUrlResponse
 */
export interface GetRestaurantByUrlResponse {
    /**
     * 
     * @type {Restaurant}
     * @memberof GetRestaurantByUrlResponse
     */
    'restaurant'?: Restaurant;
}
/**
 * 
 * @export
 * @interface GetRestaurantByUrlWithPublicMealsResponse
 */
export interface GetRestaurantByUrlWithPublicMealsResponse {
    /**
     * 
     * @type {Restaurant}
     * @memberof GetRestaurantByUrlWithPublicMealsResponse
     */
    'restaurant'?: Restaurant;
}
/**
 * 
 * @export
 * @interface GetRestaurantsResponse
 */
export interface GetRestaurantsResponse {
    /**
     * 
     * @type {Array<Restaurant>}
     * @memberof GetRestaurantsResponse
     */
    'results'?: Array<Restaurant> | null;
    /**
     * 
     * @type {number}
     * @memberof GetRestaurantsResponse
     */
    'count'?: number;
}
/**
 * 
 * @export
 * @interface GetShoppingListForDietPlanResponse
 */
export interface GetShoppingListForDietPlanResponse {
    /**
     * 
     * @type {ShoppingList}
     * @memberof GetShoppingListForDietPlanResponse
     */
    'shoppingList'?: ShoppingList;
}
/**
 * 
 * @export
 * @interface GetStaticDataResponse
 */
export interface GetStaticDataResponse {
    /**
     * 
     * @type {string}
     * @memberof GetStaticDataResponse
     */
    'environmentName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetStaticDataResponse
     */
    'environmentCode'?: string | null;
}
/**
 * 
 * @export
 * @interface GetTagMealsForDietServingsResponse
 */
export interface GetTagMealsForDietServingsResponse {
    /**
     * 
     * @type {Array<Tag>}
     * @memberof GetTagMealsForDietServingsResponse
     */
    'tags'?: Array<Tag> | null;
}
/**
 * 
 * @export
 * @interface GetTagMealsResponse
 */
export interface GetTagMealsResponse {
    /**
     * 
     * @type {Tag}
     * @memberof GetTagMealsResponse
     */
    'tag'?: Tag;
    /**
     * 
     * @type {Array<Meal>}
     * @memberof GetTagMealsResponse
     */
    'meals'?: Array<Meal> | null;
}
/**
 * 
 * @export
 * @interface GetTagsMealsForCustomerResponse
 */
export interface GetTagsMealsForCustomerResponse {
    /**
     * 
     * @type {Array<TagsMealsForDietServings>}
     * @memberof GetTagsMealsForCustomerResponse
     */
    'tagsMealsForDietServings'?: Array<TagsMealsForDietServings> | null;
}
/**
 * 
 * @export
 * @interface GetTagsResponse
 */
export interface GetTagsResponse {
    /**
     * 
     * @type {Array<Tag>}
     * @memberof GetTagsResponse
     */
    'tags'?: Array<Tag> | null;
}
/**
 * 
 * @export
 * @interface GetUnitOfMeasures
 */
export interface GetUnitOfMeasures {
    /**
     * 
     * @type {Array<UoMGroupEnum>}
     * @memberof GetUnitOfMeasures
     */
    'group'?: Array<UoMGroupEnum> | null;
}
/**
 * 
 * @export
 * @interface GetUnitOfMeasuresResponse
 */
export interface GetUnitOfMeasuresResponse {
    /**
     * 
     * @type {Array<UnitOfMeasure>}
     * @memberof GetUnitOfMeasuresResponse
     */
    'unitOfMeasures'?: Array<UnitOfMeasure> | null;
}
/**
 * 
 * @export
 * @interface GetUsdaFoodCategoriesResponse
 */
export interface GetUsdaFoodCategoriesResponse {
    /**
     * 
     * @type {Array<FdcProductCategory>}
     * @memberof GetUsdaFoodCategoriesResponse
     */
    'results'?: Array<FdcProductCategory> | null;
}
/**
 * 
 * @export
 * @interface GetWeekPlanResponse
 */
export interface GetWeekPlanResponse {
    /**
     * 
     * @type {Array<DietWeekPlanSummary>}
     * @memberof GetWeekPlanResponse
     */
    'customerWeekPlan'?: Array<DietWeekPlanSummary> | null;
}
/**
 * 
 * @export
 * @interface HomeMeasurementRule
 */
export interface HomeMeasurementRule {
    /**
     * 
     * @type {number}
     * @memberof HomeMeasurementRule
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof HomeMeasurementRule
     */
    'productId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof HomeMeasurementRule
     */
    'productGroupId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof HomeMeasurementRule
     */
    'unitOfMeasureId'?: number;
    /**
     * 
     * @type {number}
     * @memberof HomeMeasurementRule
     */
    'qtyInGrams'?: number;
    /**
     * 
     * @type {Product}
     * @memberof HomeMeasurementRule
     */
    'product'?: Product;
    /**
     * 
     * @type {ProductGroup}
     * @memberof HomeMeasurementRule
     */
    'productGroup'?: ProductGroup;
    /**
     * 
     * @type {UnitOfMeasure}
     * @memberof HomeMeasurementRule
     */
    'unitOfMeasure'?: UnitOfMeasure;
}
/**
 * 
 * @export
 * @interface Invoice
 */
export interface Invoice {
    /**
     * 
     * @type {number}
     * @memberof Invoice
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof Invoice
     */
    'customerId'?: number;
    /**
     * 
     * @type {number}
     * @memberof Invoice
     */
    'orderId'?: number;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    'docUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    'date'?: string;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    'name'?: string | null;
}
/**
 * 
 * @export
 * @interface MasterMealProposals
 */
export interface MasterMealProposals {
    /**
     * 
     * @type {number}
     * @memberof MasterMealProposals
     */
    'mealId'?: number;
    /**
     * 
     * @type {string}
     * @memberof MasterMealProposals
     */
    'mealName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MasterMealProposals
     */
    'dietDefinitionId'?: number;
    /**
     * 
     * @type {string}
     * @memberof MasterMealProposals
     */
    'dietDefinitionName'?: string | null;
}
/**
 * 
 * @export
 * @interface Meal
 */
export interface Meal {
    /**
     * 
     * @type {number}
     * @memberof Meal
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof Meal
     */
    'dietDefinitionId'?: number;
    /**
     * 
     * @type {number}
     * @memberof Meal
     */
    'restaurantId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Meal
     */
    'unitOfHomeMeasureId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Meal
     */
    'customerId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Meal
     */
    'mostProteinProductId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Meal
     */
    'mostFatProductId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Meal
     */
    'mostCarbohydrateProductId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Meal
     */
    'mostProteinFatProductId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Meal
     */
    'mostCarbohydrateFatProductId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Meal
     */
    'mostCarbohydrateProteinProductId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Meal
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Meal
     */
    'instructions'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Meal
     */
    'isMainCourse'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Meal
     */
    'isBeverage'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Meal
     */
    'isPreferedAsSnack'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Meal
     */
    'isPreferedAsBreakfast'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Meal
     */
    'isPreferedAsDinner'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Meal
     */
    'isCustomMeal'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Meal
     */
    'imagesCatalog'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Meal
     */
    'madeFrom'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof Meal
     */
    'isSweet'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Meal
     */
    'isDry'?: boolean;
    /**
     * 
     * @type {MealStatus}
     * @memberof Meal
     */
    'status'?: MealStatus;
    /**
     * 
     * @type {boolean}
     * @memberof Meal
     */
    'isMasterMeal'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Meal
     */
    'isDeleted'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Meal
     */
    'masterMealId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Meal
     */
    'qtyInGrams'?: number;
    /**
     * 
     * @type {number}
     * @memberof Meal
     */
    'qtyInHomeMeasurement'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Meal
     */
    'qtyInText'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Meal
     */
    'carbs'?: number;
    /**
     * 
     * @type {number}
     * @memberof Meal
     */
    'proteins'?: number;
    /**
     * 
     * @type {number}
     * @memberof Meal
     */
    'fats'?: number;
    /**
     * 
     * @type {number}
     * @memberof Meal
     */
    'energy'?: number;
    /**
     * 
     * @type {number}
     * @memberof Meal
     */
    'proteinsInCalories'?: number;
    /**
     * 
     * @type {number}
     * @memberof Meal
     */
    'fatsInCalories'?: number;
    /**
     * 
     * @type {number}
     * @memberof Meal
     */
    'carbsInCalories'?: number;
    /**
     * 
     * @type {number}
     * @memberof Meal
     */
    'carbsIn100Grams'?: number;
    /**
     * 
     * @type {number}
     * @memberof Meal
     */
    'proteinsIn100Grams'?: number;
    /**
     * 
     * @type {number}
     * @memberof Meal
     */
    'fatsIn100Grams'?: number;
    /**
     * 
     * @type {number}
     * @memberof Meal
     */
    'energyIn100Grams'?: number;
    /**
     * 
     * @type {number}
     * @memberof Meal
     */
    'carbsFactor'?: number;
    /**
     * 
     * @type {number}
     * @memberof Meal
     */
    'proteinsFactor'?: number;
    /**
     * 
     * @type {number}
     * @memberof Meal
     */
    'fatFactor'?: number;
    /**
     * 
     * @type {number}
     * @memberof Meal
     */
    'carbsAndProteinFactor'?: number;
    /**
     * 
     * @type {number}
     * @memberof Meal
     */
    'carbsAndFatFactor'?: number;
    /**
     * 
     * @type {number}
     * @memberof Meal
     */
    'proteinAndFatFactor'?: number;
    /**
     * 
     * @type {Classification}
     * @memberof Meal
     */
    'classification'?: Classification;
    /**
     * 
     * @type {number}
     * @memberof Meal
     */
    'carbsToProteinFactorRatio'?: number;
    /**
     * 
     * @type {number}
     * @memberof Meal
     */
    'proteinToCarbsFactorRatio'?: number;
    /**
     * 
     * @type {number}
     * @memberof Meal
     */
    'carbsToFatFactorRatio'?: number;
    /**
     * 
     * @type {number}
     * @memberof Meal
     */
    'fatToCarbsFactorRatio'?: number;
    /**
     * 
     * @type {number}
     * @memberof Meal
     */
    'proteinToFatFactorRatio'?: number;
    /**
     * 
     * @type {number}
     * @memberof Meal
     */
    'fatToProteinFactorRatio'?: number;
    /**
     * 
     * @type {Array<MealComment>}
     * @memberof Meal
     */
    'mealComments'?: Array<MealComment> | null;
    /**
     * 
     * @type {Customer}
     * @memberof Meal
     */
    'customer'?: Customer;
    /**
     * 
     * @type {DietDefinition}
     * @memberof Meal
     */
    'dietDefinition'?: DietDefinition;
    /**
     * 
     * @type {Array<MealFoodProduct>}
     * @memberof Meal
     */
    'mealFoodProducts'?: Array<MealFoodProduct> | null;
    /**
     * 
     * @type {Array<MealServing>}
     * @memberof Meal
     */
    'mealServings'?: Array<MealServing> | null;
    /**
     * 
     * @type {Array<DietPlanDayMeal>}
     * @memberof Meal
     */
    'dietPlanDayMeals'?: Array<DietPlanDayMeal> | null;
    /**
     * 
     * @type {Array<Tag>}
     * @memberof Meal
     */
    'mealTags'?: Array<Tag> | null;
    /**
     * 
     * @type {boolean}
     * @memberof Meal
     */
    'isPreferedFromTag'?: boolean;
}
/**
 * 
 * @export
 * @interface MealComment
 */
export interface MealComment {
    /**
     * 
     * @type {number}
     * @memberof MealComment
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof MealComment
     */
    'mealId'?: number;
    /**
     * 
     * @type {number}
     * @memberof MealComment
     */
    'customerId'?: number;
    /**
     * 
     * @type {string}
     * @memberof MealComment
     */
    'customerName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MealComment
     */
    'fileCatalogId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MealComment
     */
    'imageOrg'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MealComment
     */
    'imageResized'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MealComment
     */
    'imageThumbnail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MealComment
     */
    'comments'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MealComment
     */
    'createdDateUtc'?: string;
    /**
     * 
     * @type {string}
     * @memberof MealComment
     */
    'modifiedDateUtc'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MealComment
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MealComment
     */
    'modifiedBy'?: string | null;
}
/**
 * 
 * @export
 * @interface MealExchange
 */
export interface MealExchange {
    /**
     * 
     * @type {number}
     * @memberof MealExchange
     */
    'mealId'?: number;
    /**
     * 
     * @type {string}
     * @memberof MealExchange
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MealExchange
     */
    'dietDefinitionId'?: number;
    /**
     * 
     * @type {string}
     * @memberof MealExchange
     */
    'dietDefinition'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MealExchange
     */
    'isMainCourse'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MealExchange
     */
    'isBeverage'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MealExchange
     */
    'isPreferedAsSnack'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MealExchange
     */
    'isPreferedAsBreakfast'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MealExchange
     */
    'isPreferedAsDinner'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MealExchange
     */
    'isCustomMeal'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof MealExchange
     */
    'imagesCatalog'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MealExchange
     */
    'customerId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MealExchange
     */
    'madeFrom'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof MealExchange
     */
    'isLiked'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MealExchange
     */
    'currentylySelected'?: boolean;
}
/**
 * 
 * @export
 * @interface MealFoodProduct
 */
export interface MealFoodProduct {
    /**
     * 
     * @type {number}
     * @memberof MealFoodProduct
     */
    'mealId'?: number;
    /**
     * 
     * @type {number}
     * @memberof MealFoodProduct
     */
    'foodProductId'?: number;
    /**
     * 
     * @type {number}
     * @memberof MealFoodProduct
     */
    'unitOfHomeMeasureId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MealFoodProduct
     */
    'qtyInGrams'?: number;
    /**
     * 
     * @type {number}
     * @memberof MealFoodProduct
     */
    'qtyInHomeMeasurement'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MealFoodProduct
     */
    'qtyInText'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MealFoodProduct
     */
    'carbs'?: number;
    /**
     * 
     * @type {number}
     * @memberof MealFoodProduct
     */
    'proteins'?: number;
    /**
     * 
     * @type {number}
     * @memberof MealFoodProduct
     */
    'fats'?: number;
    /**
     * 
     * @type {number}
     * @memberof MealFoodProduct
     */
    'energy'?: number;
    /**
     * 
     * @type {number}
     * @memberof MealFoodProduct
     */
    'proteinsInCalories'?: number;
    /**
     * 
     * @type {number}
     * @memberof MealFoodProduct
     */
    'fatsInCalories'?: number;
    /**
     * 
     * @type {number}
     * @memberof MealFoodProduct
     */
    'carbsInCalories'?: number;
    /**
     * 
     * @type {number}
     * @memberof MealFoodProduct
     */
    'carbsIn100Grams'?: number;
    /**
     * 
     * @type {number}
     * @memberof MealFoodProduct
     */
    'proteinsIn100Grams'?: number;
    /**
     * 
     * @type {number}
     * @memberof MealFoodProduct
     */
    'fatsIn100Grams'?: number;
    /**
     * 
     * @type {number}
     * @memberof MealFoodProduct
     */
    'energyIn100Grams'?: number;
    /**
     * 
     * @type {number}
     * @memberof MealFoodProduct
     */
    'carbsFactor'?: number;
    /**
     * 
     * @type {number}
     * @memberof MealFoodProduct
     */
    'proteinsFactor'?: number;
    /**
     * 
     * @type {number}
     * @memberof MealFoodProduct
     */
    'fatFactor'?: number;
    /**
     * 
     * @type {number}
     * @memberof MealFoodProduct
     */
    'carbsAndProteinFactor'?: number;
    /**
     * 
     * @type {number}
     * @memberof MealFoodProduct
     */
    'carbsAndFatFactor'?: number;
    /**
     * 
     * @type {number}
     * @memberof MealFoodProduct
     */
    'proteinAndFatFactor'?: number;
    /**
     * 
     * @type {Classification}
     * @memberof MealFoodProduct
     */
    'classification'?: Classification;
    /**
     * 
     * @type {number}
     * @memberof MealFoodProduct
     */
    'carbsToProteinFactorRatio'?: number;
    /**
     * 
     * @type {number}
     * @memberof MealFoodProduct
     */
    'proteinToCarbsFactorRatio'?: number;
    /**
     * 
     * @type {number}
     * @memberof MealFoodProduct
     */
    'carbsToFatFactorRatio'?: number;
    /**
     * 
     * @type {number}
     * @memberof MealFoodProduct
     */
    'fatToCarbsFactorRatio'?: number;
    /**
     * 
     * @type {number}
     * @memberof MealFoodProduct
     */
    'proteinToFatFactorRatio'?: number;
    /**
     * 
     * @type {number}
     * @memberof MealFoodProduct
     */
    'fatToProteinFactorRatio'?: number;
    /**
     * 
     * @type {Product}
     * @memberof MealFoodProduct
     */
    'foodProduct'?: Product;
    /**
     * 
     * @type {number}
     * @memberof MealFoodProduct
     */
    'lockedQtyInGrams'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MealFoodProduct
     */
    'lockedHomeMeasurementId'?: number | null;
}
/**
 * 
 * @export
 * @interface MealFoodProductMealPair
 */
export interface MealFoodProductMealPair {
    /**
     * 
     * @type {number}
     * @memberof MealFoodProductMealPair
     */
    'key'?: number;
    /**
     * 
     * @type {Array<MealFoodProduct>}
     * @memberof MealFoodProductMealPair
     */
    'value'?: Array<MealFoodProduct> | null;
}
/**
 * 
 * @export
 * @interface MealSearchItem
 */
export interface MealSearchItem {
    /**
     * 
     * @type {number}
     * @memberof MealSearchItem
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof MealSearchItem
     */
    'dietDefinitionId'?: number;
    /**
     * 
     * @type {number}
     * @memberof MealSearchItem
     */
    'restaurantId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MealSearchItem
     */
    'restaurantName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MealSearchItem
     */
    'customerId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MealSearchItem
     */
    'mostProteinProductId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MealSearchItem
     */
    'mostFatProductId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MealSearchItem
     */
    'mostCarbohydrateProductId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MealSearchItem
     */
    'mostProteinFatProductId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MealSearchItem
     */
    'mostCarbohydrateFatProductId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MealSearchItem
     */
    'mostCarbohydrateProteinProductId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MealSearchItem
     */
    'name'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MealSearchItem
     */
    'isMainCourse'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MealSearchItem
     */
    'isBeverage'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MealSearchItem
     */
    'isPreferedAsSnack'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MealSearchItem
     */
    'isPreferedAsBreakfast'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MealSearchItem
     */
    'isPreferedAsDinner'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MealSearchItem
     */
    'isDeleted'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MealSearchItem
     */
    'isCustomMeal'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof MealSearchItem
     */
    'imagesCatalog'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MealSearchItem
     */
    'madeFrom'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof MealSearchItem
     */
    'isSweet'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MealSearchItem
     */
    'isDry'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MealSearchItem
     */
    'isMasterMeal'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof MealSearchItem
     */
    'masterMealId'?: number | null;
    /**
     * 
     * @type {MealStatus}
     * @memberof MealSearchItem
     */
    'status'?: MealStatus;
    /**
     * 
     * @type {Classification}
     * @memberof MealSearchItem
     */
    'classification'?: Classification;
    /**
     * 
     * @type {DietDefinition}
     * @memberof MealSearchItem
     */
    'dietDefinition'?: DietDefinition;
    /**
     * 
     * @type {Array<Tag>}
     * @memberof MealSearchItem
     */
    'mealTags'?: Array<Tag> | null;
    /**
     * 
     * @type {number}
     * @memberof MealSearchItem
     */
    'count'?: number;
}
/**
 * 
 * @export
 * @interface MealServing
 */
export interface MealServing {
    /**
     * 
     * @type {number}
     * @memberof MealServing
     */
    'dietPlanDayMealId'?: number;
    /**
     * 
     * @type {number}
     * @memberof MealServing
     */
    'mealId'?: number;
    /**
     * 
     * @type {number}
     * @memberof MealServing
     */
    'foodProductId'?: number;
    /**
     * 
     * @type {number}
     * @memberof MealServing
     */
    'unitOfHomeMeasureId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MealServing
     */
    'qtyInGrams'?: number;
    /**
     * 
     * @type {number}
     * @memberof MealServing
     */
    'qtyInHomeMeasurement'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MealServing
     */
    'qtyInText'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MealServing
     */
    'carbs'?: number;
    /**
     * 
     * @type {number}
     * @memberof MealServing
     */
    'proteins'?: number;
    /**
     * 
     * @type {number}
     * @memberof MealServing
     */
    'fats'?: number;
    /**
     * 
     * @type {number}
     * @memberof MealServing
     */
    'energy'?: number;
    /**
     * 
     * @type {number}
     * @memberof MealServing
     */
    'proteinsInCalories'?: number;
    /**
     * 
     * @type {number}
     * @memberof MealServing
     */
    'fatsInCalories'?: number;
    /**
     * 
     * @type {number}
     * @memberof MealServing
     */
    'carbsInCalories'?: number;
    /**
     * 
     * @type {number}
     * @memberof MealServing
     */
    'carbsIn100Grams'?: number;
    /**
     * 
     * @type {number}
     * @memberof MealServing
     */
    'proteinsIn100Grams'?: number;
    /**
     * 
     * @type {number}
     * @memberof MealServing
     */
    'fatsIn100Grams'?: number;
    /**
     * 
     * @type {number}
     * @memberof MealServing
     */
    'energyIn100Grams'?: number;
    /**
     * 
     * @type {number}
     * @memberof MealServing
     */
    'carbsFactor'?: number;
    /**
     * 
     * @type {number}
     * @memberof MealServing
     */
    'proteinsFactor'?: number;
    /**
     * 
     * @type {number}
     * @memberof MealServing
     */
    'fatFactor'?: number;
    /**
     * 
     * @type {number}
     * @memberof MealServing
     */
    'carbsAndProteinFactor'?: number;
    /**
     * 
     * @type {number}
     * @memberof MealServing
     */
    'carbsAndFatFactor'?: number;
    /**
     * 
     * @type {number}
     * @memberof MealServing
     */
    'proteinAndFatFactor'?: number;
    /**
     * 
     * @type {Classification}
     * @memberof MealServing
     */
    'classification'?: Classification;
    /**
     * 
     * @type {number}
     * @memberof MealServing
     */
    'carbsToProteinFactorRatio'?: number;
    /**
     * 
     * @type {number}
     * @memberof MealServing
     */
    'proteinToCarbsFactorRatio'?: number;
    /**
     * 
     * @type {number}
     * @memberof MealServing
     */
    'carbsToFatFactorRatio'?: number;
    /**
     * 
     * @type {number}
     * @memberof MealServing
     */
    'fatToCarbsFactorRatio'?: number;
    /**
     * 
     * @type {number}
     * @memberof MealServing
     */
    'proteinToFatFactorRatio'?: number;
    /**
     * 
     * @type {number}
     * @memberof MealServing
     */
    'fatToProteinFactorRatio'?: number;
    /**
     * 
     * @type {Product}
     * @memberof MealServing
     */
    'foodProduct'?: Product;
    /**
     * 
     * @type {number}
     * @memberof MealServing
     */
    'lockedQtyInGrams'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MealServing
     */
    'lockedHomeMeasurementId'?: number | null;
}
/**
 * 
 * @export
 * @interface MealServingWithNutritionSummary
 */
export interface MealServingWithNutritionSummary {
    /**
     * 
     * @type {number}
     * @memberof MealServingWithNutritionSummary
     */
    'dietPlanDayMealId'?: number;
    /**
     * 
     * @type {number}
     * @memberof MealServingWithNutritionSummary
     */
    'foodProductId'?: number;
    /**
     * 
     * @type {string}
     * @memberof MealServingWithNutritionSummary
     */
    'foodProductName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MealServingWithNutritionSummary
     */
    'recipeQtyInGrams'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MealServingWithNutritionSummary
     */
    'recipeQtyInText'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MealServingWithNutritionSummary
     */
    'qtyInGrams'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MealServingWithNutritionSummary
     */
    'qtyInText'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MealServingWithNutritionSummary
     */
    'fats'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MealServingWithNutritionSummary
     */
    'carbs'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MealServingWithNutritionSummary
     */
    'proteins'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MealServingWithNutritionSummary
     */
    'energy'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MealServingWithNutritionSummary
     */
    'mealName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MealServingWithNutritionSummary
     */
    'mealId'?: number;
}
/**
 * 
 * @export
 * @interface MealServingsProposalsForProductId
 */
export interface MealServingsProposalsForProductId {
    /**
     * 
     * @type {number}
     * @memberof MealServingsProposalsForProductId
     */
    'productId'?: number;
    /**
     * 
     * @type {Array<MealServing>}
     * @memberof MealServingsProposalsForProductId
     */
    'mealServings'?: Array<MealServing> | null;
}
/**
 *   0 = None  1 = New  2 = ToVerify  4 = Verified  8 = Rejected  64 = Public
 * @export
 * @enum {string}
 */

export enum MealStatus {
    /**
    * 
    */
    None = 0,
    /**
    * 
    */
    New = 1,
    /**
    * 
    */
    ToVerify = 2,
    /**
    * 
    */
    Verified = 4,
    /**
    * 
    */
    Rejected = 8,
    /**
    * 
    */
    Public = 64
}

/**
 * 
 * @export
 * @interface MealTotal
 */
export interface MealTotal {
    /**
     * 
     * @type {number}
     * @memberof MealTotal
     */
    'dietDefinitionId'?: number;
    /**
     * 
     * @type {string}
     * @memberof MealTotal
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MealTotal
     */
    'count'?: number;
}
/**
 *   0 = Breakfast  1 = Brunch  2 = Elevenses  3 = Lunch  4 = Tea  5 = Supper  6 = Dinner  7 = Beverages
 * @export
 * @enum {string}
 */

export enum MealTypeEnum {
    /**
    * 
    */
    Breakfast = 0,
    /**
    * 
    */
    Brunch = 1,
    /**
    * 
    */
    Elevenses = 2,
    /**
    * 
    */
    Lunch = 3,
    /**
    * 
    */
    Tea = 4,
    /**
    * 
    */
    Supper = 5,
    /**
    * 
    */
    Dinner = 6,
    /**
    * 
    */
    Beverages = 7
}

/**
 * 
 * @export
 * @interface MealTypeTag
 */
export interface MealTypeTag {
    /**
     * 
     * @type {number}
     * @memberof MealTypeTag
     */
    'tagsMealsForDietServingsId'?: number;
    /**
     * 
     * @type {number}
     * @memberof MealTypeTag
     */
    'mealTypeId'?: number;
    /**
     * 
     * @type {string}
     * @memberof MealTypeTag
     */
    'mealTypeName'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MealTypeTag
     */
    'selected'?: boolean;
}
/**
 * 
 * @export
 * @interface NutritionFactDefinition
 */
export interface NutritionFactDefinition {
    /**
     * 
     * @type {number}
     * @memberof NutritionFactDefinition
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof NutritionFactDefinition
     */
    'defaultUnitOfMeasureId'?: number;
    /**
     * 
     * @type {string}
     * @memberof NutritionFactDefinition
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NutritionFactDefinition
     */
    'group'?: string | null;
    /**
     * 
     * @type {UnitOfMeasure}
     * @memberof NutritionFactDefinition
     */
    'defaultUnitOfMeasure'?: UnitOfMeasure;
}
/**
 * 
 * @export
 * @interface Order
 */
export interface Order {
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    'customerId'?: number;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    'productId'?: number;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    'priceRegular'?: number;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    'priceToPay'?: number;
    /**
     * 
     * @type {OrderTypeEnum}
     * @memberof Order
     */
    'orderType'?: OrderTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof Order
     */
    'expired'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    'boughtDays'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    'startDate'?: string;
    /**
     * 
     * @type {DietPlanDefinition}
     * @memberof Order
     */
    'dietPlanDefinition'?: DietPlanDefinition;
    /**
     * 
     * @type {Array<Payment>}
     * @memberof Order
     */
    'payments'?: Array<Payment> | null;
    /**
     * 
     * @type {Array<Invoice>}
     * @memberof Order
     */
    'invoices'?: Array<Invoice> | null;
}
/**
 *   0 = Reccuring  1 = OneTime
 * @export
 * @enum {string}
 */

export enum OrderTypeEnum {
    /**
    * 
    */
    Reccuring = 0,
    /**
    * 
    */
    OneTime = 1
}

/**
 * 
 * @export
 * @interface Payment
 */
export interface Payment {
    /**
     * 
     * @type {number}
     * @memberof Payment
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof Payment
     */
    'orderId'?: number;
    /**
     * 
     * @type {number}
     * @memberof Payment
     */
    'invoiceId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    'uniqueId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    'paymentChannel'?: string | null;
    /**
     * 
     * @type {PaymentStatus}
     * @memberof Payment
     */
    'paymentStatus'?: PaymentStatus;
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    'date'?: string;
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    'cardNumber'?: string | null;
    /**
     * 
     * @type {Invoice}
     * @memberof Payment
     */
    'invoice'?: Invoice;
    /**
     * 
     * @type {Order}
     * @memberof Payment
     */
    'order'?: Order;
}
/**
 * 
 * @export
 * @interface PaymentChannel
 */
export interface PaymentChannel {
    /**
     * 
     * @type {string}
     * @memberof PaymentChannel
     */
    'id'?: string | null;
    /**
     * 
     * @type {PaymentModel}
     * @memberof PaymentChannel
     */
    'paymentModel'?: PaymentModel;
    /**
     * 
     * @type {Array<string>}
     * @memberof PaymentChannel
     */
    'availableCurrencies'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof PaymentChannel
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaymentChannel
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaymentChannel
     */
    'logoUrl'?: string | null;
}
/**
 *   0 = OneTime  1 = Subscription
 * @export
 * @enum {string}
 */

export enum PaymentModel {
    /**
    * 
    */
    OneTime = 0,
    /**
    * 
    */
    Subscription = 1
}

/**
 * 
 * @export
 * @interface PaymentRequest
 */
export interface PaymentRequest {
    /**
     * 
     * @type {string}
     * @memberof PaymentRequest
     */
    'paymentChannel'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaymentRequest
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaymentRequest
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaymentRequest
     */
    'currency'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PaymentRequest
     */
    'amount'?: number;
    /**
     * 
     * @type {string}
     * @memberof PaymentRequest
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaymentRequest
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaymentRequest
     */
    'surname'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaymentRequest
     */
    'street'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaymentRequest
     */
    'house'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaymentRequest
     */
    'flat'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaymentRequest
     */
    'postCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaymentRequest
     */
    'city'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaymentRequest
     */
    'country'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaymentRequest
     */
    'additionalData'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaymentRequest
     */
    'referer'?: string | null;
}
/**
 *   0 = Created  1 = Processing  2 = Finished  3 = Rejected
 * @export
 * @enum {string}
 */

export enum PaymentStatus {
    /**
    * 
    */
    Created = 0,
    /**
    * 
    */
    Processing = 1,
    /**
    * 
    */
    Finished = 2,
    /**
    * 
    */
    Rejected = 3
}

/**
 * 
 * @export
 * @interface PostAddOrUpdateDietBallanceDefinitionResponse
 */
export interface PostAddOrUpdateDietBallanceDefinitionResponse {
    /**
     * 
     * @type {DietBallanceDefinition}
     * @memberof PostAddOrUpdateDietBallanceDefinitionResponse
     */
    'dietBallanceDefinition'?: DietBallanceDefinition;
}
/**
 * 
 * @export
 * @interface PostAddOrUpdateDietPlanDayMeals
 */
export interface PostAddOrUpdateDietPlanDayMeals {
    /**
     * 
     * @type {number}
     * @memberof PostAddOrUpdateDietPlanDayMeals
     */
    'customerId'?: number;
    /**
     * 
     * @type {number}
     * @memberof PostAddOrUpdateDietPlanDayMeals
     */
    'dietDefinitionId'?: number;
    /**
     * 
     * @type {number}
     * @memberof PostAddOrUpdateDietPlanDayMeals
     */
    'dietPlanId'?: number;
    /**
     * 
     * @type {number}
     * @memberof PostAddOrUpdateDietPlanDayMeals
     */
    'dayId'?: number;
    /**
     * 
     * @type {MealTypeEnum}
     * @memberof PostAddOrUpdateDietPlanDayMeals
     */
    'mealTypeEnum'?: MealTypeEnum;
    /**
     * 
     * @type {Array<Meal>}
     * @memberof PostAddOrUpdateDietPlanDayMeals
     */
    'meals'?: Array<Meal> | null;
}
/**
 * 
 * @export
 * @interface PostAddOrUpdateMealServig
 */
export interface PostAddOrUpdateMealServig {
    /**
     * 
     * @type {MealServing}
     * @memberof PostAddOrUpdateMealServig
     */
    'mealServing'?: MealServing;
}
/**
 * 
 * @export
 * @interface PostAddOrUpdateMealServigResponse
 */
export interface PostAddOrUpdateMealServigResponse {
    /**
     * 
     * @type {MealServing}
     * @memberof PostAddOrUpdateMealServigResponse
     */
    'mealServing'?: MealServing;
}
/**
 * 
 * @export
 * @interface PostAddOrUpdateRestaurant
 */
export interface PostAddOrUpdateRestaurant {
    /**
     * 
     * @type {Restaurant}
     * @memberof PostAddOrUpdateRestaurant
     */
    'restaurant'?: Restaurant;
}
/**
 * 
 * @export
 * @interface PostAddOrUpdateRestaurantResponse
 */
export interface PostAddOrUpdateRestaurantResponse {
    /**
     * 
     * @type {Restaurant}
     * @memberof PostAddOrUpdateRestaurantResponse
     */
    'restaurant'?: Restaurant;
}
/**
 * 
 * @export
 * @interface PostAssignUserToRestaurant
 */
export interface PostAssignUserToRestaurant {
    /**
     * 
     * @type {string}
     * @memberof PostAssignUserToRestaurant
     */
    'email'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PostAssignUserToRestaurant
     */
    'userId'?: number;
}
/**
 * 
 * @export
 * @interface PostAssignUserToRestaurantResponse
 */
export interface PostAssignUserToRestaurantResponse {
    /**
     * 
     * @type {Restaurant}
     * @memberof PostAssignUserToRestaurantResponse
     */
    'restaurant'?: Restaurant;
}
/**
 * 
 * @export
 * @interface PostConvertToMasterMeal
 */
export interface PostConvertToMasterMeal {
    /**
     * 
     * @type {number}
     * @memberof PostConvertToMasterMeal
     */
    'mealId'?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof PostConvertToMasterMeal
     */
    'slaveMeals'?: Array<number> | null;
}
/**
 * 
 * @export
 * @interface PostCopyDietPlanDayMeals
 */
export interface PostCopyDietPlanDayMeals {
    /**
     * 
     * @type {string}
     * @memberof PostCopyDietPlanDayMeals
     */
    'fromDay'?: string;
    /**
     * 
     * @type {string}
     * @memberof PostCopyDietPlanDayMeals
     */
    'toDay'?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof PostCopyDietPlanDayMeals
     */
    'mealIds'?: Array<number> | null;
    /**
     * 
     * @type {MealTypeEnum}
     * @memberof PostCopyDietPlanDayMeals
     */
    'fromMealType'?: MealTypeEnum;
    /**
     * 
     * @type {MealTypeEnum}
     * @memberof PostCopyDietPlanDayMeals
     */
    'toMealType'?: MealTypeEnum;
}
/**
 * 
 * @export
 * @interface PostCopyMealDefinition
 */
export interface PostCopyMealDefinition {
    /**
     * 
     * @type {number}
     * @memberof PostCopyMealDefinition
     */
    'mealId'?: number;
    /**
     * 
     * @type {number}
     * @memberof PostCopyMealDefinition
     */
    'dietDefinitionId'?: number;
}
/**
 * 
 * @export
 * @interface PostCopyMealDefinitionResponse
 */
export interface PostCopyMealDefinitionResponse {
    /**
     * 
     * @type {Meal}
     * @memberof PostCopyMealDefinitionResponse
     */
    'meal'?: Meal;
}
/**
 * 
 * @export
 * @interface PostCorelateUserWithCustomer
 */
export interface PostCorelateUserWithCustomer {
    /**
     * 
     * @type {string}
     * @memberof PostCorelateUserWithCustomer
     */
    'settingsId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PostCorelateUserWithCustomer
     */
    'customerId'?: number;
    /**
     * 
     * @type {number}
     * @memberof PostCorelateUserWithCustomer
     */
    'userId'?: number;
    /**
     * 
     * @type {string}
     * @memberof PostCorelateUserWithCustomer
     */
    'email'?: string | null;
}
/**
 * 
 * @export
 * @interface PostCreateCustomerSettings
 */
export interface PostCreateCustomerSettings {
    /**
     * 
     * @type {number}
     * @memberof PostCreateCustomerSettings
     */
    'dietTypeId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof PostCreateCustomerSettings
     */
    'dietStartUtc'?: string;
    /**
     * 
     * @type {string}
     * @memberof PostCreateCustomerSettings
     */
    'timezone'?: string | null;
}
/**
 * 
 * @export
 * @interface PostCreateCustomerSettingsResponse
 */
export interface PostCreateCustomerSettingsResponse {
    /**
     * 
     * @type {CustomerSettings}
     * @memberof PostCreateCustomerSettingsResponse
     */
    'customerSettings'?: CustomerSettings;
}
/**
 * 
 * @export
 * @interface PostCreateCustomerTrainingSettings
 */
export interface PostCreateCustomerTrainingSettings {
    /**
     * 
     * @type {string}
     * @memberof PostCreateCustomerTrainingSettings
     */
    'settingsId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PostCreateCustomerTrainingSettings
     */
    'wantUseSportMode'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PostCreateCustomerTrainingSettings
     */
    'sunday'?: number;
    /**
     * 
     * @type {number}
     * @memberof PostCreateCustomerTrainingSettings
     */
    'monday'?: number;
    /**
     * 
     * @type {number}
     * @memberof PostCreateCustomerTrainingSettings
     */
    'tuesday'?: number;
    /**
     * 
     * @type {number}
     * @memberof PostCreateCustomerTrainingSettings
     */
    'wednesday'?: number;
    /**
     * 
     * @type {number}
     * @memberof PostCreateCustomerTrainingSettings
     */
    'thursday'?: number;
    /**
     * 
     * @type {number}
     * @memberof PostCreateCustomerTrainingSettings
     */
    'friday'?: number;
    /**
     * 
     * @type {number}
     * @memberof PostCreateCustomerTrainingSettings
     */
    'saturday'?: number;
}
/**
 * 
 * @export
 * @interface PostCreateCustomerTrainingSettingsResponse
 */
export interface PostCreateCustomerTrainingSettingsResponse {
    /**
     * 
     * @type {CustomerTrainingPreferences}
     * @memberof PostCreateCustomerTrainingSettingsResponse
     */
    'customerTrainingPreferences'?: CustomerTrainingPreferences;
}
/**
 * 
 * @export
 * @interface PostCreateDietPlan
 */
export interface PostCreateDietPlan {
    /**
     * 
     * @type {string}
     * @memberof PostCreateDietPlan
     */
    'settingsId'?: string | null;
}
/**
 * 
 * @export
 * @interface PostCreateDietPlanResponse
 */
export interface PostCreateDietPlanResponse {
    /**
     * 
     * @type {number}
     * @memberof PostCreateDietPlanResponse
     */
    'dietPlanId'?: number;
    /**
     * 
     * @type {number}
     * @memberof PostCreateDietPlanResponse
     */
    'customerId'?: number | null;
}
/**
 * 
 * @export
 * @interface PostCreateProductFromUsdaRecord
 */
export interface PostCreateProductFromUsdaRecord {
    /**
     * 
     * @type {number}
     * @memberof PostCreateProductFromUsdaRecord
     */
    'fdcId'?: number;
    /**
     * 
     * @type {number}
     * @memberof PostCreateProductFromUsdaRecord
     */
    'productGroupId'?: number;
    /**
     * 
     * @type {string}
     * @memberof PostCreateProductFromUsdaRecord
     */
    'productName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PostCreateProductFromUsdaRecord
     */
    'productId'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof PostCreateProductFromUsdaRecord
     */
    'overridePnfs'?: boolean | null;
}
/**
 * 
 * @export
 * @interface PostCreateProductFromUsdaRecordResponse
 */
export interface PostCreateProductFromUsdaRecordResponse {
    /**
     * 
     * @type {number}
     * @memberof PostCreateProductFromUsdaRecordResponse
     */
    'productId'?: number;
    /**
     * 
     * @type {string}
     * @memberof PostCreateProductFromUsdaRecordResponse
     */
    'productRef'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PostCreateProductFromUsdaRecordResponse
     */
    'productName'?: string | null;
}
/**
 * 
 * @export
 * @interface PostCustomerMealFoodProducts
 */
export interface PostCustomerMealFoodProducts {
    /**
     * 
     * @type {number}
     * @memberof PostCustomerMealFoodProducts
     */
    'customerId'?: number;
    /**
     * 
     * @type {number}
     * @memberof PostCustomerMealFoodProducts
     */
    'dayId'?: number;
    /**
     * 
     * @type {number}
     * @memberof PostCustomerMealFoodProducts
     */
    'dietPlanId'?: number;
    /**
     * 
     * @type {Array<MealFoodProductMealPair>}
     * @memberof PostCustomerMealFoodProducts
     */
    'mealFoodProducts'?: Array<MealFoodProductMealPair> | null;
    /**
     * 
     * @type {MealTypeEnum}
     * @memberof PostCustomerMealFoodProducts
     */
    'mealType'?: MealTypeEnum;
}
/**
 * 
 * @export
 * @interface PostCustomerRequest
 */
export interface PostCustomerRequest {
    /**
     * 
     * @type {Request}
     * @memberof PostCustomerRequest
     */
    'request'?: Request;
}
/**
 * 
 * @export
 * @interface PostDietDefinition
 */
export interface PostDietDefinition {
    /**
     * 
     * @type {DietDefinition}
     * @memberof PostDietDefinition
     */
    'dietDefinition'?: DietDefinition;
}
/**
 * 
 * @export
 * @interface PostDietDefinitionResponse
 */
export interface PostDietDefinitionResponse {
    /**
     * 
     * @type {DietDefinition}
     * @memberof PostDietDefinitionResponse
     */
    'dietDefinition'?: DietDefinition;
}
/**
 * 
 * @export
 * @interface PostDietDefintionTags
 */
export interface PostDietDefintionTags {
    /**
     * 
     * @type {number}
     * @memberof PostDietDefintionTags
     */
    'tagId'?: number;
    /**
     * 
     * @type {number}
     * @memberof PostDietDefintionTags
     */
    'dietDefinitionId'?: number;
}
/**
 * 
 * @export
 * @interface PostDietDefintionTagsResponse
 */
export interface PostDietDefintionTagsResponse {
    /**
     * 
     * @type {Array<DietDefinition>}
     * @memberof PostDietDefintionTagsResponse
     */
    'dietDefinitions'?: Array<DietDefinition> | null;
}
/**
 * 
 * @export
 * @interface PostDietRecalcCorrectedResponse
 */
export interface PostDietRecalcCorrectedResponse {
    /**
     * 
     * @type {DietPlanDayMeal}
     * @memberof PostDietRecalcCorrectedResponse
     */
    'mealCalculated'?: DietPlanDayMeal;
}
/**
 * 
 * @export
 * @interface PostDietRecalcResponse
 */
export interface PostDietRecalcResponse {
    /**
     * 
     * @type {DietPlanDayMeal}
     * @memberof PostDietRecalcResponse
     */
    'mealCalculated'?: DietPlanDayMeal;
}
/**
 * 
 * @export
 * @interface PostDocument
 */
export interface PostDocument {
    /**
     * 
     * @type {Document}
     * @memberof PostDocument
     */
    'document'?: Document;
}
/**
 * 
 * @export
 * @interface PostDocumentResponse
 */
export interface PostDocumentResponse {
    /**
     * 
     * @type {Document}
     * @memberof PostDocumentResponse
     */
    'document'?: Document;
}
/**
 * 
 * @export
 * @interface PostFavouriteMealDefinition
 */
export interface PostFavouriteMealDefinition {
    /**
     * 
     * @type {number}
     * @memberof PostFavouriteMealDefinition
     */
    'mealId'?: number;
}
/**
 * 
 * @export
 * @interface PostMealComment
 */
export interface PostMealComment {
    /**
     * 
     * @type {MealComment}
     * @memberof PostMealComment
     */
    'mealComment'?: MealComment;
}
/**
 * 
 * @export
 * @interface PostMealCommentResponse
 */
export interface PostMealCommentResponse {
    /**
     * 
     * @type {MealComment}
     * @memberof PostMealCommentResponse
     */
    'mealComment'?: MealComment;
}
/**
 * 
 * @export
 * @interface PostMealDefinition
 */
export interface PostMealDefinition {
    /**
     * 
     * @type {string}
     * @memberof PostMealDefinition
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PostMealDefinition
     */
    'dietDefinitionId'?: number;
    /**
     * 
     * @type {string}
     * @memberof PostMealDefinition
     */
    'instructions'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PostMealDefinition
     */
    'isMainCourse'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PostMealDefinition
     */
    'isPreferedAsSnack'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PostMealDefinition
     */
    'isPreferedAsBreakfast'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PostMealDefinition
     */
    'isPreferedAsDinner'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PostMealDefinition
     */
    'isBeverage'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PostMealDefinition
     */
    'isCustomMeal'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PostMealDefinition
     */
    'imagesCatalog'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PostMealDefinition
     */
    'customerId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PostMealDefinition
     */
    'madeFrom'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof PostMealDefinition
     */
    'isSweet'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PostMealDefinition
     */
    'isDry'?: boolean;
    /**
     * 
     * @type {Array<MealFoodProduct>}
     * @memberof PostMealDefinition
     */
    'mealFoodProducts'?: Array<MealFoodProduct> | null;
    /**
     * 
     * @type {Array<Tag>}
     * @memberof PostMealDefinition
     */
    'mealTags'?: Array<Tag> | null;
}
/**
 * 
 * @export
 * @interface PostMealDefinitionResponse
 */
export interface PostMealDefinitionResponse {
    /**
     * 
     * @type {Meal}
     * @memberof PostMealDefinitionResponse
     */
    'meal'?: Meal;
}
/**
 * 
 * @export
 * @interface PostMealInCustomerDayPlan
 */
export interface PostMealInCustomerDayPlan {
    /**
     * 
     * @type {number}
     * @memberof PostMealInCustomerDayPlan
     */
    'subscriptionId'?: number;
    /**
     * 
     * @type {number}
     * @memberof PostMealInCustomerDayPlan
     */
    'customerId'?: number;
    /**
     * 
     * @type {number}
     * @memberof PostMealInCustomerDayPlan
     */
    'dayId'?: number;
    /**
     * 
     * @type {number}
     * @memberof PostMealInCustomerDayPlan
     */
    'mealId'?: number;
    /**
     * 
     * @type {MealTypeEnum}
     * @memberof PostMealInCustomerDayPlan
     */
    'mealType'?: MealTypeEnum;
}
/**
 * 
 * @export
 * @interface PostMealInCustomerDayPlanResponse
 */
export interface PostMealInCustomerDayPlanResponse {
    /**
     * 
     * @type {DietPlanDayMeal}
     * @memberof PostMealInCustomerDayPlanResponse
     */
    'dietPlanDayMeal'?: DietPlanDayMeal;
}
/**
 * 
 * @export
 * @interface PostPaymentRequest
 */
export interface PostPaymentRequest {
    /**
     * 
     * @type {number}
     * @memberof PostPaymentRequest
     */
    'userId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof PostPaymentRequest
     */
    'customerMail'?: string | null;
    /**
     * 
     * @type {PaymentRequest}
     * @memberof PostPaymentRequest
     */
    'paymentRequest'?: PaymentRequest;
}
/**
 * 
 * @export
 * @interface PostPaymentResponse
 */
export interface PostPaymentResponse {
    /**
     * 
     * @type {string}
     * @memberof PostPaymentResponse
     */
    'redirectUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PostPaymentResponse
     */
    'paymentUniqueId'?: string | null;
    /**
     * 
     * @type {PaymentStatus}
     * @memberof PostPaymentResponse
     */
    'paymentStatus'?: PaymentStatus;
}
/**
 * 
 * @export
 * @interface PostProcessEatenMeal
 */
export interface PostProcessEatenMeal {
    /**
     * 
     * @type {number}
     * @memberof PostProcessEatenMeal
     */
    'dietPlanId'?: number;
    /**
     * 
     * @type {number}
     * @memberof PostProcessEatenMeal
     */
    'dayId'?: number;
    /**
     * 
     * @type {MealTypeEnum}
     * @memberof PostProcessEatenMeal
     */
    'mealType'?: MealTypeEnum;
}
/**
 * 
 * @export
 * @interface PostProcessEatenMealResponse
 */
export interface PostProcessEatenMealResponse {
    /**
     * 
     * @type {boolean}
     * @memberof PostProcessEatenMealResponse
     */
    'isMealEaten'?: boolean;
}
/**
 * 
 * @export
 * @interface PostProduct
 */
export interface PostProduct {
    /**
     * 
     * @type {number}
     * @memberof PostProduct
     */
    'productGroupId'?: number;
    /**
     * 
     * @type {number}
     * @memberof PostProduct
     */
    'parentProductId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof PostProduct
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PostProduct
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PostProduct
     */
    'ean'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PostProduct
     */
    'nutritionFactToImport'?: string | null;
}
/**
 * 
 * @export
 * @interface PostProductGroup
 */
export interface PostProductGroup {
    /**
     * 
     * @type {ProductGroup}
     * @memberof PostProductGroup
     */
    'productGroup'?: ProductGroup;
}
/**
 * 
 * @export
 * @interface PostProductGroupResponse
 */
export interface PostProductGroupResponse {
    /**
     * 
     * @type {ProductGroup}
     * @memberof PostProductGroupResponse
     */
    'productGroup'?: ProductGroup;
}
/**
 * 
 * @export
 * @interface PostProductNutritionFact
 */
export interface PostProductNutritionFact {
    /**
     * 
     * @type {number}
     * @memberof PostProductNutritionFact
     */
    'productId'?: number;
    /**
     * 
     * @type {number}
     * @memberof PostProductNutritionFact
     */
    'quantity'?: number;
    /**
     * 
     * @type {number}
     * @memberof PostProductNutritionFact
     */
    'uoMId'?: number;
    /**
     * 
     * @type {number}
     * @memberof PostProductNutritionFact
     */
    'nutritionFactDefinitionId'?: number;
}
/**
 * 
 * @export
 * @interface PostProductNutritionFactResponse
 */
export interface PostProductNutritionFactResponse {
    /**
     * 
     * @type {ProductNutritionFact}
     * @memberof PostProductNutritionFactResponse
     */
    'productNutritionFact'?: ProductNutritionFact;
}
/**
 * 
 * @export
 * @interface PostProductResponse
 */
export interface PostProductResponse {
    /**
     * 
     * @type {Product}
     * @memberof PostProductResponse
     */
    'product'?: Product;
}
/**
 * 
 * @export
 * @interface PostProductServingRule
 */
export interface PostProductServingRule {
    /**
     * 
     * @type {ProductServingRule}
     * @memberof PostProductServingRule
     */
    'productServingRule'?: ProductServingRule;
}
/**
 * 
 * @export
 * @interface PostProductServingRuleResponse
 */
export interface PostProductServingRuleResponse {
    /**
     * 
     * @type {ProductServingRule}
     * @memberof PostProductServingRuleResponse
     */
    'productServingRule'?: ProductServingRule;
}
/**
 * 
 * @export
 * @interface PostProductTag
 */
export interface PostProductTag {
    /**
     * 
     * @type {number}
     * @memberof PostProductTag
     */
    'tagId'?: number;
    /**
     * 
     * @type {ProductTag}
     * @memberof PostProductTag
     */
    'productTag'?: ProductTag;
}
/**
 * 
 * @export
 * @interface PostProductTagResponse
 */
export interface PostProductTagResponse {
    /**
     * 
     * @type {ProductTag}
     * @memberof PostProductTagResponse
     */
    'productTag'?: ProductTag;
}
/**
 * 
 * @export
 * @interface PostReCalcMealResponse
 */
export interface PostReCalcMealResponse {
    /**
     * 
     * @type {Array<Meal>}
     * @memberof PostReCalcMealResponse
     */
    'meal'?: Array<Meal> | null;
}
/**
 * 
 * @export
 * @interface PostReplyOnRequest
 */
export interface PostReplyOnRequest {
    /**
     * 
     * @type {number}
     * @memberof PostReplyOnRequest
     */
    'requestId'?: number;
    /**
     * 
     * @type {string}
     * @memberof PostReplyOnRequest
     */
    'responseMessage'?: string | null;
}
/**
 * 
 * @export
 * @interface PostRestaurantDetails
 */
export interface PostRestaurantDetails {
    /**
     * 
     * @type {Restaurant}
     * @memberof PostRestaurantDetails
     */
    'restaurant'?: Restaurant;
}
/**
 * 
 * @export
 * @interface PostRestaurantDetailsResponse
 */
export interface PostRestaurantDetailsResponse {
    /**
     * 
     * @type {Restaurant}
     * @memberof PostRestaurantDetailsResponse
     */
    'restaurant'?: Restaurant;
}
/**
 * 
 * @export
 * @interface PostRestaurantDocuments
 */
export interface PostRestaurantDocuments {
    /**
     * 
     * @type {number}
     * @memberof PostRestaurantDocuments
     */
    'restaurantId'?: number;
    /**
     * 
     * @type {string}
     * @memberof PostRestaurantDocuments
     */
    'signedDocument'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PostRestaurantDocuments
     */
    'fileName'?: string | null;
}
/**
 * 
 * @export
 * @interface PostRestaurantDocumentsResponse
 */
export interface PostRestaurantDocumentsResponse {
    /**
     * 
     * @type {Restaurant}
     * @memberof PostRestaurantDocumentsResponse
     */
    'restaurant'?: Restaurant;
}
/**
 * 
 * @export
 * @interface PostRestaurantLocationResponse
 */
export interface PostRestaurantLocationResponse {
    /**
     * 
     * @type {Restaurant}
     * @memberof PostRestaurantLocationResponse
     */
    'restaurant'?: Restaurant;
}
/**
 * 
 * @export
 * @interface PostRestaurantRequest
 */
export interface PostRestaurantRequest {
    /**
     * 
     * @type {Request}
     * @memberof PostRestaurantRequest
     */
    'request'?: Request;
    /**
     * 
     * @type {Restaurant}
     * @memberof PostRestaurantRequest
     */
    'restaurant'?: Restaurant;
}
/**
 * 
 * @export
 * @interface PostRestaurantRequestResponse
 */
export interface PostRestaurantRequestResponse {
    /**
     * 
     * @type {Restaurant}
     * @memberof PostRestaurantRequestResponse
     */
    'restaurant'?: Restaurant;
}
/**
 * 
 * @export
 * @interface PostShoppingItemForDietPlan
 */
export interface PostShoppingItemForDietPlan {
    /**
     * 
     * @type {number}
     * @memberof PostShoppingItemForDietPlan
     */
    'shoppingItemId'?: number;
}
/**
 * 
 * @export
 * @interface PostShoppingListForDietPlan
 */
export interface PostShoppingListForDietPlan {
    /**
     * 
     * @type {string}
     * @memberof PostShoppingListForDietPlan
     */
    'startDateUtc'?: string;
    /**
     * 
     * @type {string}
     * @memberof PostShoppingListForDietPlan
     */
    'endDateUtc'?: string;
}
/**
 * 
 * @export
 * @interface PostShoppingListForDietPlanResponse
 */
export interface PostShoppingListForDietPlanResponse {
    /**
     * 
     * @type {ShoppingList}
     * @memberof PostShoppingListForDietPlanResponse
     */
    'shoppingList'?: ShoppingList;
}
/**
 * 
 * @export
 * @interface PostSwapDietPlanDayMeals
 */
export interface PostSwapDietPlanDayMeals {
    /**
     * 
     * @type {number}
     * @memberof PostSwapDietPlanDayMeals
     */
    'dietPlanId'?: number;
    /**
     * 
     * @type {number}
     * @memberof PostSwapDietPlanDayMeals
     */
    'dayId'?: number;
    /**
     * 
     * @type {number}
     * @memberof PostSwapDietPlanDayMeals
     */
    'from'?: number;
    /**
     * 
     * @type {number}
     * @memberof PostSwapDietPlanDayMeals
     */
    'to'?: number;
}
/**
 * 
 * @export
 * @interface PostSwapDietPlanDayMealsResponse
 */
export interface PostSwapDietPlanDayMealsResponse {
    /**
     * 
     * @type {Array<WeekViewDay>}
     * @memberof PostSwapDietPlanDayMealsResponse
     */
    'swappedMeals'?: Array<WeekViewDay> | null;
}
/**
 * 
 * @export
 * @interface PostTag
 */
export interface PostTag {
    /**
     * 
     * @type {string}
     * @memberof PostTag
     */
    'tag'?: string | null;
}
/**
 * 
 * @export
 * @interface PostTagResponse
 */
export interface PostTagResponse {
    /**
     * 
     * @type {Tag}
     * @memberof PostTagResponse
     */
    'tag'?: Tag;
}
/**
 * 
 * @export
 * @interface PostTagsMealsForDietServings
 */
export interface PostTagsMealsForDietServings {
    /**
     * 
     * @type {string}
     * @memberof PostTagsMealsForDietServings
     */
    'settingsId'?: string | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof PostTagsMealsForDietServings
     */
    'tagsIds'?: Array<number> | null;
    /**
     * 
     * @type {number}
     * @memberof PostTagsMealsForDietServings
     */
    'mealTypeId'?: number | null;
}
/**
 * 
 * @export
 * @interface PostTagsMealsForDietServingsResponse
 */
export interface PostTagsMealsForDietServingsResponse {
    /**
     * 
     * @type {Array<TagsMealsForDietServings>}
     * @memberof PostTagsMealsForDietServingsResponse
     */
    'tagsMealsForDietServings'?: Array<TagsMealsForDietServings> | null;
}
/**
 * 
 * @export
 * @interface ProblemDetails
 */
export interface ProblemDetails {
    [key: string]: any | any;

    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'title'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProblemDetails
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'detail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'instance'?: string | null;
}
/**
 * 
 * @export
 * @interface Product
 */
export interface Product {
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'productGroupId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'parentProductId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'customerId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'restaurantId'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof Product
     */
    'isVerifed'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'manufacturer'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'ref'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'ean'?: string | null;
    /**
     * 
     * @type {Product}
     * @memberof Product
     */
    'parentProduct'?: Product;
    /**
     * 
     * @type {Array<ProductNutritionFact>}
     * @memberof Product
     */
    'productNutritionFacts'?: Array<ProductNutritionFact> | null;
    /**
     * 
     * @type {ProductGroup}
     * @memberof Product
     */
    'productGroup'?: ProductGroup;
    /**
     * 
     * @type {ProductExchangeGroup}
     * @memberof Product
     */
    'productExchangeGroup'?: ProductExchangeGroup;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'createdDateUtc'?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'modifiedDateUtc'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'modifiedBy'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'lockedQtyInGrams'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'lockedHomeMeasurementId'?: number | null;
}
/**
 * 
 * @export
 * @interface ProductExchangeGroup
 */
export interface ProductExchangeGroup {
    /**
     * 
     * @type {number}
     * @memberof ProductExchangeGroup
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductExchangeGroup
     */
    'productId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductExchangeGroup
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductExchangeGroup
     */
    'description'?: string | null;
    /**
     * 
     * @type {Product}
     * @memberof ProductExchangeGroup
     */
    'product'?: Product;
}
/**
 * 
 * @export
 * @interface ProductGroup
 */
export interface ProductGroup {
    /**
     * 
     * @type {number}
     * @memberof ProductGroup
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductGroup
     */
    'parentId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProductGroup
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductGroup
     */
    'description'?: string | null;
    /**
     * 
     * @type {ProductGroup}
     * @memberof ProductGroup
     */
    'parent'?: ProductGroup;
    /**
     * 
     * @type {Array<Product>}
     * @memberof ProductGroup
     */
    'products'?: Array<Product> | null;
    /**
     * 
     * @type {Array<ProductGroup>}
     * @memberof ProductGroup
     */
    'children'?: Array<ProductGroup> | null;
}
/**
 * 
 * @export
 * @interface ProductNutritionFact
 */
export interface ProductNutritionFact {
    /**
     * 
     * @type {number}
     * @memberof ProductNutritionFact
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductNutritionFact
     */
    'uoMId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductNutritionFact
     */
    'productId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductNutritionFact
     */
    'nutritionFactDefinitionId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductNutritionFact
     */
    'quantity'?: number;
    /**
     * 
     * @type {NutritionFactDefinition}
     * @memberof ProductNutritionFact
     */
    'nutritionFactDefinition'?: NutritionFactDefinition;
    /**
     * 
     * @type {UnitOfMeasure}
     * @memberof ProductNutritionFact
     */
    'uoM'?: UnitOfMeasure;
}
/**
 * 
 * @export
 * @interface ProductRefNamePair
 */
export interface ProductRefNamePair {
    /**
     * 
     * @type {number}
     * @memberof ProductRefNamePair
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductRefNamePair
     */
    'ref'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductRefNamePair
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProductRefNamePair
     */
    'carbsIn100Grams'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductRefNamePair
     */
    'proteinsIn100Grams'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductRefNamePair
     */
    'fatsIn100Grams'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductRefNamePair
     */
    'energyIn100Grams'?: number;
}
/**
 * 
 * @export
 * @interface ProductServingRule
 */
export interface ProductServingRule {
    /**
     * 
     * @type {number}
     * @memberof ProductServingRule
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductServingRule
     */
    'productId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProductServingRule
     */
    'productGroupId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProductServingRule
     */
    'productName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductServingRule
     */
    'productGroupName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProductServingRule
     */
    'priority'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductServingRule
     */
    'minQtyInGrams'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductServingRule
     */
    'maxQtyInGrams'?: number;
}
/**
 * 
 * @export
 * @interface ProductTag
 */
export interface ProductTag {
    /**
     * 
     * @type {number}
     * @memberof ProductTag
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductTag
     */
    'productGroupId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductTag
     */
    'productId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProductTag
     */
    'tagId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductTag
     */
    'tagName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductTag
     */
    'productName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductTag
     */
    'productGroupName'?: string | null;
}
/**
 * 
 * @export
 * @interface ProductWithNutritionSummary
 */
export interface ProductWithNutritionSummary {
    /**
     * 
     * @type {number}
     * @memberof ProductWithNutritionSummary
     */
    'foodProductId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductWithNutritionSummary
     */
    'foodProductName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProductWithNutritionSummary
     */
    'qtyInGrams'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductWithNutritionSummary
     */
    'qtyInText'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProductWithNutritionSummary
     */
    'fats'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProductWithNutritionSummary
     */
    'carbs'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProductWithNutritionSummary
     */
    'proteins'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProductWithNutritionSummary
     */
    'energy'?: number | null;
}
/**
 * 
 * @export
 * @interface PutDietDefinition
 */
export interface PutDietDefinition {
    /**
     * 
     * @type {number}
     * @memberof PutDietDefinition
     */
    'id'?: number;
    /**
     * 
     * @type {DietDefinition}
     * @memberof PutDietDefinition
     */
    'dietDefinition'?: DietDefinition;
}
/**
 * 
 * @export
 * @interface PutDietDefinitionResponse
 */
export interface PutDietDefinitionResponse {
    /**
     * 
     * @type {DietDefinition}
     * @memberof PutDietDefinitionResponse
     */
    'dietDefinition'?: DietDefinition;
}
/**
 * 
 * @export
 * @interface PutDietObjective
 */
export interface PutDietObjective {
    /**
     * 
     * @type {string}
     * @memberof PutDietObjective
     */
    'settingsId'?: string | null;
    /**
     * 
     * @type {DietObjective}
     * @memberof PutDietObjective
     */
    'objective'?: DietObjective;
}
/**
 * 
 * @export
 * @interface PutDietObjectiveResponse
 */
export interface PutDietObjectiveResponse {
    /**
     * 
     * @type {CustomerSettings}
     * @memberof PutDietObjectiveResponse
     */
    'customerSettings'?: CustomerSettings;
}
/**
 * 
 * @export
 * @interface PutDietStartDayUtc
 */
export interface PutDietStartDayUtc {
    /**
     * 
     * @type {string}
     * @memberof PutDietStartDayUtc
     */
    'settingsId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PutDietStartDayUtc
     */
    'dietStartUtc'?: string;
}
/**
 * 
 * @export
 * @interface PutDocument
 */
export interface PutDocument {
    /**
     * 
     * @type {number}
     * @memberof PutDocument
     */
    'id'?: number;
    /**
     * 
     * @type {Document}
     * @memberof PutDocument
     */
    'document'?: Document;
}
/**
 * 
 * @export
 * @interface PutDocumentResponse
 */
export interface PutDocumentResponse {
    /**
     * 
     * @type {Document}
     * @memberof PutDocumentResponse
     */
    'document'?: Document;
}
/**
 * 
 * @export
 * @interface PutExcludedProduct
 */
export interface PutExcludedProduct {
    /**
     * 
     * @type {string}
     * @memberof PutExcludedProduct
     */
    'settingsId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PutExcludedProduct
     */
    'productId'?: number;
}
/**
 * 
 * @export
 * @interface PutExcludedProductResponse
 */
export interface PutExcludedProductResponse {
    /**
     * 
     * @type {string}
     * @memberof PutExcludedProductResponse
     */
    'name'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PutExcludedProductResponse
     */
    'exclude'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PutExcludedProductResponse
     */
    'foodProductId'?: number;
    /**
     * 
     * @type {string}
     * @memberof PutExcludedProductResponse
     */
    'foodProductName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PutExcludedProductResponse
     */
    'dietDefinitionId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PutExcludedProductResponse
     */
    'customerId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PutExcludedProductResponse
     */
    'dietPreferenceId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof PutExcludedProductResponse
     */
    'foodProductRef'?: string | null;
}
/**
 * 
 * @export
 * @interface PutExclusionsForDietPlan
 */
export interface PutExclusionsForDietPlan {
    /**
     * 
     * @type {string}
     * @memberof PutExclusionsForDietPlan
     */
    'settingsId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PutExclusionsForDietPlan
     */
    'wantExcludeProducts'?: boolean;
}
/**
 * 
 * @export
 * @interface PutExclusionsForDietPlanResponse
 */
export interface PutExclusionsForDietPlanResponse {
    /**
     * 
     * @type {CustomerDietPreference}
     * @memberof PutExclusionsForDietPlanResponse
     */
    'customerDietPreference'?: CustomerDietPreference;
}
/**
 * 
 * @export
 * @interface PutInfoAboutYourself
 */
export interface PutInfoAboutYourself {
    /**
     * 
     * @type {string}
     * @memberof PutInfoAboutYourself
     */
    'settingsId'?: string | null;
    /**
     * 
     * @type {Gender}
     * @memberof PutInfoAboutYourself
     */
    'gender'?: Gender;
    /**
     * 
     * @type {number}
     * @memberof PutInfoAboutYourself
     */
    'weight'?: number;
    /**
     * 
     * @type {number}
     * @memberof PutInfoAboutYourself
     */
    'height'?: number;
    /**
     * 
     * @type {number}
     * @memberof PutInfoAboutYourself
     */
    'yearOfBirth'?: number;
}
/**
 * 
 * @export
 * @interface PutInfoAboutYourselfResponse
 */
export interface PutInfoAboutYourselfResponse {
    /**
     * 
     * @type {CustomerSettings}
     * @memberof PutInfoAboutYourselfResponse
     */
    'customerSettings'?: CustomerSettings;
}
/**
 * 
 * @export
 * @interface PutMeal
 */
export interface PutMeal {
    /**
     * 
     * @type {number}
     * @memberof PutMeal
     */
    'mealId'?: number;
    /**
     * 
     * @type {Meal}
     * @memberof PutMeal
     */
    'meal'?: Meal;
}
/**
 * 
 * @export
 * @interface PutMealResponse
 */
export interface PutMealResponse {
    /**
     * 
     * @type {Meal}
     * @memberof PutMealResponse
     */
    'meal'?: Meal;
}
/**
 * 
 * @export
 * @interface PutMealServingPrerefences
 */
export interface PutMealServingPrerefences {
    /**
     * 
     * @type {number}
     * @memberof PutMealServingPrerefences
     */
    'mealId'?: number;
    /**
     * 
     * @type {string}
     * @memberof PutMealServingPrerefences
     */
    'serving'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PutMealServingPrerefences
     */
    'value'?: boolean;
}
/**
 * 
 * @export
 * @interface PutProduct
 */
export interface PutProduct {
    /**
     * 
     * @type {number}
     * @memberof PutProduct
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PutProduct
     */
    'name'?: string | null;
}
/**
 * 
 * @export
 * @interface PutProductGroup
 */
export interface PutProductGroup {
    /**
     * 
     * @type {number}
     * @memberof PutProductGroup
     */
    'id'?: number;
    /**
     * 
     * @type {ProductGroup}
     * @memberof PutProductGroup
     */
    'productGroup'?: ProductGroup;
}
/**
 * 
 * @export
 * @interface PutProductGroupResponse
 */
export interface PutProductGroupResponse {
    /**
     * 
     * @type {ProductGroup}
     * @memberof PutProductGroupResponse
     */
    'productGroup'?: ProductGroup;
}
/**
 * 
 * @export
 * @interface PutProductNutritionFact
 */
export interface PutProductNutritionFact {
    /**
     * 
     * @type {number}
     * @memberof PutProductNutritionFact
     */
    'productId'?: number;
    /**
     * 
     * @type {number}
     * @memberof PutProductNutritionFact
     */
    'nutritionFactId'?: number;
    /**
     * 
     * @type {number}
     * @memberof PutProductNutritionFact
     */
    'nutritionFactDefinitionId'?: number;
    /**
     * 
     * @type {number}
     * @memberof PutProductNutritionFact
     */
    'quantity'?: number;
    /**
     * 
     * @type {number}
     * @memberof PutProductNutritionFact
     */
    'uoMId'?: number;
}
/**
 * 
 * @export
 * @interface PutProductNutritionFactResponse
 */
export interface PutProductNutritionFactResponse {
    /**
     * 
     * @type {ProductNutritionFact}
     * @memberof PutProductNutritionFactResponse
     */
    'productNutritionFact'?: ProductNutritionFact;
}
/**
 * 
 * @export
 * @interface PutProductResponse
 */
export interface PutProductResponse {
    /**
     * 
     * @type {Product}
     * @memberof PutProductResponse
     */
    'product'?: Product;
}
/**
 * 
 * @export
 * @interface PutSelectDietType
 */
export interface PutSelectDietType {
    /**
     * 
     * @type {string}
     * @memberof PutSelectDietType
     */
    'settingsId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PutSelectDietType
     */
    'dietTypeId'?: number;
}
/**
 * 
 * @export
 * @interface PutSelectDietTypeResponse
 */
export interface PutSelectDietTypeResponse {
    /**
     * 
     * @type {CustomerDietPreference}
     * @memberof PutSelectDietTypeResponse
     */
    'customerDietPreference'?: CustomerDietPreference;
}
/**
 * 
 * @export
 * @interface PutServingPreferences
 */
export interface PutServingPreferences {
    /**
     * 
     * @type {string}
     * @memberof PutServingPreferences
     */
    'settingsId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PutServingPreferences
     */
    'numberOfServingsPerDay'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof PutServingPreferences
     */
    'mainDishForTwoDays'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof PutServingPreferences
     */
    'dietDayForTwoDays'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof PutServingPreferences
     */
    'lunchAndDinnerSameMeal'?: boolean | null;
}
/**
 * 
 * @export
 * @interface PutServingPreferencesResponse
 */
export interface PutServingPreferencesResponse {
    /**
     * 
     * @type {CustomerDietPreference}
     * @memberof PutServingPreferencesResponse
     */
    'customerDietPreference'?: CustomerDietPreference;
    /**
     * 
     * @type {Array<TagsMealsForDietServings>}
     * @memberof PutServingPreferencesResponse
     */
    'tagsMealsForDietServings'?: Array<TagsMealsForDietServings> | null;
}
/**
 * 
 * @export
 * @interface PutUserInterview
 */
export interface PutUserInterview {
    /**
     * 
     * @type {string}
     * @memberof PutUserInterview
     */
    'settingsId'?: string | null;
    /**
     * 
     * @type {Gender}
     * @memberof PutUserInterview
     */
    'gender'?: Gender;
    /**
     * 
     * @type {number}
     * @memberof PutUserInterview
     */
    'weight'?: number;
    /**
     * 
     * @type {number}
     * @memberof PutUserInterview
     */
    'height'?: number;
    /**
     * 
     * @type {number}
     * @memberof PutUserInterview
     */
    'yearOfBirth'?: number;
    /**
     * 
     * @type {DietObjective}
     * @memberof PutUserInterview
     */
    'objective'?: DietObjective;
    /**
     * 
     * @type {boolean}
     * @memberof PutUserInterview
     */
    'wantUseSportMode'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PutUserInterview
     */
    'sunday'?: number;
    /**
     * 
     * @type {number}
     * @memberof PutUserInterview
     */
    'monday'?: number;
    /**
     * 
     * @type {number}
     * @memberof PutUserInterview
     */
    'tuesday'?: number;
    /**
     * 
     * @type {number}
     * @memberof PutUserInterview
     */
    'wednesday'?: number;
    /**
     * 
     * @type {number}
     * @memberof PutUserInterview
     */
    'thursday'?: number;
    /**
     * 
     * @type {number}
     * @memberof PutUserInterview
     */
    'friday'?: number;
    /**
     * 
     * @type {number}
     * @memberof PutUserInterview
     */
    'saturday'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PutUserInterview
     */
    'wantExcludeProducts'?: boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof PutUserInterview
     */
    'excludedProductsIds'?: Array<number> | null;
    /**
     * 
     * @type {number}
     * @memberof PutUserInterview
     */
    'numberOfServingsPerDay'?: number;
    /**
     * 
     * @type {Array<TagsMealsForDietServings>}
     * @memberof PutUserInterview
     */
    'tags'?: Array<TagsMealsForDietServings> | null;
    /**
     * 
     * @type {boolean}
     * @memberof PutUserInterview
     */
    'mainDishForTwoDays'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PutUserInterview
     */
    'dietDayForTwoDays'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PutUserInterview
     */
    'lunchAndDinnerSameMeal'?: boolean;
}
/**
 * 
 * @export
 * @interface RegisteredCustomer
 */
export interface RegisteredCustomer {
    /**
     * 
     * @type {number}
     * @memberof RegisteredCustomer
     */
    'customerId'?: number;
    /**
     * 
     * @type {number}
     * @memberof RegisteredCustomer
     */
    'userId'?: number;
    /**
     * 
     * @type {string}
     * @memberof RegisteredCustomer
     */
    'mail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RegisteredCustomer
     */
    'unlockDateTimeUtc'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof RegisteredCustomer
     */
    'isLocked'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RegisteredCustomer
     */
    'lastInvalidLoginDateUtc'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof RegisteredCustomer
     */
    'reset'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RegisteredCustomer
     */
    'confirmedMail'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof RegisteredCustomer
     */
    'invalidLoginCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof RegisteredCustomer
     */
    'createdDateUtc'?: string;
    /**
     * 
     * @type {string}
     * @memberof RegisteredCustomer
     */
    'registerDate'?: string | null;
    /**
     * 
     * @type {Gender}
     * @memberof RegisteredCustomer
     */
    'sex'?: Gender;
    /**
     * 
     * @type {string}
     * @memberof RegisteredCustomer
     */
    'dateOfBirth'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RegisteredCustomer
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RegisteredCustomer
     */
    'userName'?: string | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof RegisteredCustomer
     */
    'planIds'?: Array<number> | null;
}
/**
 * 
 * @export
 * @interface Request
 */
export interface Request {
    /**
     * 
     * @type {number}
     * @memberof Request
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof Request
     */
    'customerId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Request
     */
    'restaurantId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Request
     */
    'lastRequestId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Request
     */
    'conversationId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Request
     */
    'mail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Request
     */
    'content'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Request
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Request
     */
    'name'?: string | null;
    /**
     * 
     * @type {RequestType}
     * @memberof Request
     */
    'requestType'?: RequestType;
    /**
     * 
     * @type {Customer}
     * @memberof Request
     */
    'customer'?: Customer;
    /**
     * 
     * @type {string}
     * @memberof Request
     */
    'createdDateUtc'?: string;
    /**
     * 
     * @type {string}
     * @memberof Request
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Request
     */
    'modifiedDateUtc'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Request
     */
    'modifiedBy'?: string | null;
}
/**
 *   0 = Customer  1 = Restaurant
 * @export
 * @enum {string}
 */

export enum RequestType {
    /**
    * 
    */
    Customer = 0,
    /**
    * 
    */
    Restaurant = 1
}

/**
 * 
 * @export
 * @interface Restaurant
 */
export interface Restaurant {
    /**
     * 
     * @type {number}
     * @memberof Restaurant
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof Restaurant
     */
    'userId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Restaurant
     */
    'placeId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Restaurant
     */
    'documentsFileCatalogId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Restaurant
     */
    'imagesFileCatalogId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Restaurant
     */
    'discountCodeId'?: number | null;
    /**
     * 
     * @type {RestaurantStatus}
     * @memberof Restaurant
     */
    'status'?: RestaurantStatus;
    /**
     * 
     * @type {string}
     * @memberof Restaurant
     */
    'documentData'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Restaurant
     */
    'documentHasChanged'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof Restaurant
     */
    'rejectedDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Restaurant
     */
    'mail'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Restaurant
     */
    'lat'?: number;
    /**
     * 
     * @type {number}
     * @memberof Restaurant
     */
    'lng'?: number;
    /**
     * 
     * @type {string}
     * @memberof Restaurant
     */
    'additionalInfo'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Restaurant
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Restaurant
     */
    'shortDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Restaurant
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Restaurant
     */
    'descriptionTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Restaurant
     */
    'openHours'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Restaurant
     */
    'logoUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Restaurant
     */
    'url'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Restaurant
     */
    'city'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Restaurant
     */
    'street'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Restaurant
     */
    'homeNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Restaurant
     */
    'apartmentNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Restaurant
     */
    'postcode'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Restaurant
     */
    'newImagesFileCatalogId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Restaurant
     */
    'newAdditionalInfo'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Restaurant
     */
    'newLat'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Restaurant
     */
    'newLng'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Restaurant
     */
    'newName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Restaurant
     */
    'newShortDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Restaurant
     */
    'newDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Restaurant
     */
    'newDescriptionTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Restaurant
     */
    'newOpenHours'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Restaurant
     */
    'newLogoUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Restaurant
     */
    'newUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Restaurant
     */
    'newCity'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Restaurant
     */
    'newStreet'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Restaurant
     */
    'newHomeNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Restaurant
     */
    'newApartmentNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Restaurant
     */
    'newPostcode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Restaurant
     */
    'representativeName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Restaurant
     */
    'representativeTelephone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Restaurant
     */
    'representativeCity'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Restaurant
     */
    'representativeStreet'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Restaurant
     */
    'representativeHomeNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Restaurant
     */
    'representativeApartmentNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Restaurant
     */
    'representativePostcode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Restaurant
     */
    'newRepresentativeName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Restaurant
     */
    'newRepresentativeTelephone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Restaurant
     */
    'newRepresentativeCity'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Restaurant
     */
    'newRepresentativeStreet'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Restaurant
     */
    'newRepresentativeHomeNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Restaurant
     */
    'newRepresentativeApartmentNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Restaurant
     */
    'newRepresentativePostcode'?: string | null;
    /**
     * 
     * @type {Array<Meal>}
     * @memberof Restaurant
     */
    'meals'?: Array<Meal> | null;
    /**
     * 
     * @type {string}
     * @memberof Restaurant
     */
    'createdDateUtc'?: string;
    /**
     * 
     * @type {string}
     * @memberof Restaurant
     */
    'modifiedDateUtc'?: string | null;
}
/**
 *   0 = None  1 = New  2 = ToSign  4 = ToVerify  8 = Verified  16 = Rejected  64 = Public
 * @export
 * @enum {string}
 */

export enum RestaurantStatus {
    /**
    * 
    */
    None = 0,
    /**
    * 
    */
    New = 1,
    /**
    * 
    */
    ToSign = 2,
    /**
    * 
    */
    ToVerify = 4,
    /**
    * 
    */
    Verified = 8,
    /**
    * 
    */
    Rejected = 16,
    /**
    * 
    */
    Public = 64
}

/**
 * 
 * @export
 * @interface SearchResult
 */
export interface SearchResult {
    /**
     * 
     * @type {string}
     * @memberof SearchResult
     */
    'resultType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchResult
     */
    'key'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchResult
     */
    'text'?: string | null;
}
/**
 * 
 * @export
 * @interface ShoppingItem
 */
export interface ShoppingItem {
    /**
     * 
     * @type {number}
     * @memberof ShoppingItem
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof ShoppingItem
     */
    'productId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ShoppingItem
     */
    'shoppingListId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ShoppingItem
     */
    'productName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ShoppingItem
     */
    'productGroupName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ShoppingItem
     */
    'unitOfHomeMeasureId'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof ShoppingItem
     */
    'isBought'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof ShoppingItem
     */
    'qtyInGrams'?: number;
    /**
     * 
     * @type {string}
     * @memberof ShoppingItem
     */
    'qtyInText'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ShoppingItem
     */
    'qtyInHomeMeasurement'?: number | null;
}
/**
 * 
 * @export
 * @interface ShoppingList
 */
export interface ShoppingList {
    /**
     * 
     * @type {number}
     * @memberof ShoppingList
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof ShoppingList
     */
    'customerId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ShoppingList
     */
    'startDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof ShoppingList
     */
    'endDate'?: string;
    /**
     * 
     * @type {Array<ShoppingItem>}
     * @memberof ShoppingList
     */
    'shoppingItems'?: Array<ShoppingItem> | null;
}
/**
 *   0 = Undefined  1 = Asc  2 = Desc
 * @export
 * @enum {string}
 */

export enum SortDirection {
    /**
    * 
    */
    Undefined = 0,
    /**
    * 
    */
    Asc = 1,
    /**
    * 
    */
    Desc = 2
}

/**
 * 
 * @export
 * @interface Tag
 */
export interface Tag {
    /**
     * 
     * @type {number}
     * @memberof Tag
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Tag
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Tag
     */
    'mealCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof Tag
     */
    'productCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof Tag
     */
    'dietDefinitonsCount'?: number;
}
/**
 * 
 * @export
 * @interface TagsMealsForDietServings
 */
export interface TagsMealsForDietServings {
    /**
     * 
     * @type {number}
     * @memberof TagsMealsForDietServings
     */
    'tagId'?: number;
    /**
     * 
     * @type {string}
     * @memberof TagsMealsForDietServings
     */
    'tagName'?: string | null;
    /**
     * 
     * @type {Array<MealTypeTag>}
     * @memberof TagsMealsForDietServings
     */
    'mealTypeTags'?: Array<MealTypeTag> | null;
}
/**
 * 
 * @export
 * @interface UnitOfMeasure
 */
export interface UnitOfMeasure {
    /**
     * 
     * @type {number}
     * @memberof UnitOfMeasure
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof UnitOfMeasure
     */
    'uoMName'?: string | null;
    /**
     * 
     * @type {UoMGroupEnum}
     * @memberof UnitOfMeasure
     */
    'group'?: UoMGroupEnum;
    /**
     * 
     * @type {boolean}
     * @memberof UnitOfMeasure
     */
    'allowFractions'?: boolean;
    /**
     * 
     * @type {Array<UoMTextRule>}
     * @memberof UnitOfMeasure
     */
    'uoMTextRules'?: Array<UoMTextRule> | null;
}
/**
 *   1 = Weight  2 = Energy  4 = HomeMeasurement
 * @export
 * @enum {string}
 */

export enum UoMGroupEnum {
    /**
    * 
    */
    Weight = 1,
    /**
    * 
    */
    Energy = 2,
    /**
    * 
    */
    HomeMeasurement = 4
}

/**
 * 
 * @export
 * @interface UoMTextRule
 */
export interface UoMTextRule {
    /**
     * 
     * @type {number}
     * @memberof UoMTextRule
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof UoMTextRule
     */
    'languageId'?: number;
    /**
     * 
     * @type {number}
     * @memberof UoMTextRule
     */
    'unitOfMeasureId'?: number;
    /**
     * 
     * @type {number}
     * @memberof UoMTextRule
     */
    'minQty'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UoMTextRule
     */
    'maxQty'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UoMTextRule
     */
    'minFractions'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UoMTextRule
     */
    'maxFractions'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UoMTextRule
     */
    'minUnities'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UoMTextRule
     */
    'maxUnities'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UoMTextRule
     */
    'qtyInText'?: string | null;
}
/**
 * 
 * @export
 * @interface WeekViewDay
 */
export interface WeekViewDay {
    /**
     * 
     * @type {number}
     * @memberof WeekViewDay
     */
    'dietDayId'?: number;
    /**
     * 
     * @type {string}
     * @memberof WeekViewDay
     */
    'dayDateUtc'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof WeekViewDay
     */
    'isTranningDay'?: boolean;
    /**
     * 
     * @type {Array<WeekViewDayMeal>}
     * @memberof WeekViewDay
     */
    'meals'?: Array<WeekViewDayMeal> | null;
}
/**
 * 
 * @export
 * @interface WeekViewDayMeal
 */
export interface WeekViewDayMeal {
    /**
     * 
     * @type {MealTypeEnum}
     * @memberof WeekViewDayMeal
     */
    'mealType'?: MealTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof WeekViewDayMeal
     */
    'mealName'?: string | null;
}

/**
 * AuxiliaryApi - axios parameter creator
 * @export
 */
export const AuxiliaryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AcceptOrRejectRestaurantDetails} [acceptOrRejectRestaurantDetails] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptOrRejectRestaurantDetails: async (acceptOrRejectRestaurantDetails?: AcceptOrRejectRestaurantDetails, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Auxiliary/Restaurant/Details/Verify`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(acceptOrRejectRestaurantDetails, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AcceptOrRejectRestaurantDocuments} [acceptOrRejectRestaurantDocuments] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptOrRejectRestaurantDocuments: async (acceptOrRejectRestaurantDocuments?: AcceptOrRejectRestaurantDocuments, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Auxiliary/Restaurant/Documents/Verify`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(acceptOrRejectRestaurantDocuments, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AcceptOrRejectRestaurantMeal} [acceptOrRejectRestaurantMeal] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptOrRejectRestaurantMeal: async (acceptOrRejectRestaurantMeal?: AcceptOrRejectRestaurantMeal, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Auxiliary/Restaurant/Meal/Verify`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(acceptOrRejectRestaurantMeal, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AcceptOrRejectRestaurantRequest} [acceptOrRejectRestaurantRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptOrRejectRestaurantRequest: async (acceptOrRejectRestaurantRequest?: AcceptOrRejectRestaurantRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Auxiliary/Restaurant/Request/Verify`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(acceptOrRejectRestaurantRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AddOrUpdateRestaurantMeal} [addOrUpdateRestaurantMeal] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOrUpdateRestaurantMeal: async (addOrUpdateRestaurantMeal?: AddOrUpdateRestaurantMeal, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Auxiliary/Restaurant/Meal`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addOrUpdateRestaurantMeal, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDocument: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteDocument', 'id', id)
            const localVarPath = `/api/Auxiliary/StaticData/Documents/{Id}`
                .replace(`{${"Id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRestaurantMeal: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteRestaurantMeal', 'id', id)
            const localVarPath = `/api/Auxiliary/Restaurant/Meal/{Id}`
                .replace(`{${"Id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} restaurantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enableOrDisableRestaurant: async (restaurantId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'restaurantId' is not null or undefined
            assertParamExists('enableOrDisableRestaurant', 'restaurantId', restaurantId)
            const localVarPath = `/api/Auxiliary/Restaurant/Public/{RestaurantId}`
                .replace(`{${"RestaurantId"}}`, encodeURIComponent(String(restaurantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} mealId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enableOrDisableRestaurantMeal: async (mealId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mealId' is not null or undefined
            assertParamExists('enableOrDisableRestaurantMeal', 'mealId', mealId)
            const localVarPath = `/api/Auxiliary/Restaurant/Meal/Public/{MealId}`
                .replace(`{${"MealId"}}`, encodeURIComponent(String(mealId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [count] 
         * @param {boolean} [isPagingSpecified] 
         * @param {string} [sortField] 
         * @param {SortDirection} [sortDir]   0 &#x3D; Undefined  1 &#x3D; Asc  2 &#x3D; Desc
         * @param {boolean} [isSortingSpecified] 
         * @param {number} [filterId] 
         * @param {string} [filterType] 
         * @param {string} [filterKey] 
         * @param {string} [filterAction] 
         * @param {string} [filterWho] 
         * @param {string} [filterWhenFrom] 
         * @param {string} [filterWhenTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDataLogs: async (page?: number, count?: number, isPagingSpecified?: boolean, sortField?: string, sortDir?: SortDirection, isSortingSpecified?: boolean, filterId?: number, filterType?: string, filterKey?: string, filterAction?: string, filterWho?: string, filterWhenFrom?: string, filterWhenTo?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Auxiliary/DataLogs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['Page'] = page;
            }

            if (count !== undefined) {
                localVarQueryParameter['Count'] = count;
            }

            if (isPagingSpecified !== undefined) {
                localVarQueryParameter['IsPagingSpecified'] = isPagingSpecified;
            }

            if (sortField !== undefined) {
                localVarQueryParameter['SortField'] = sortField;
            }

            if (sortDir !== undefined) {
                localVarQueryParameter['SortDir'] = sortDir;
            }

            if (isSortingSpecified !== undefined) {
                localVarQueryParameter['IsSortingSpecified'] = isSortingSpecified;
            }

            if (filterId !== undefined) {
                localVarQueryParameter['Filter.Id'] = filterId;
            }

            if (filterType !== undefined) {
                localVarQueryParameter['Filter.Type'] = filterType;
            }

            if (filterKey !== undefined) {
                localVarQueryParameter['Filter.Key'] = filterKey;
            }

            if (filterAction !== undefined) {
                localVarQueryParameter['Filter.Action'] = filterAction;
            }

            if (filterWho !== undefined) {
                localVarQueryParameter['Filter.Who'] = filterWho;
            }

            if (filterWhenFrom !== undefined) {
                localVarQueryParameter['Filter.WhenFrom'] = (filterWhenFrom as any instanceof Date) ?
                    (filterWhenFrom as any).toISOString() :
                    filterWhenFrom;
            }

            if (filterWhenTo !== undefined) {
                localVarQueryParameter['Filter.WhenTo'] = (filterWhenTo as any instanceof Date) ?
                    (filterWhenTo as any).toISOString() :
                    filterWhenTo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDataLogsTypes: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Auxiliary/DataLogs/Types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDocumentById', 'id', id)
            const localVarPath = `/api/Auxiliary/StaticData/Documents/{Id}`
                .replace(`{${"Id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} url 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentByUrl: async (url: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'url' is not null or undefined
            assertParamExists('getDocumentByUrl', 'url', url)
            const localVarPath = `/api/Auxiliary/Document/{Url}`
                .replace(`{${"Url"}}`, encodeURIComponent(String(url)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GetDocuments} [getDocuments] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocuments: async (getDocuments?: GetDocuments, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Auxiliary/StaticData/Documents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getDocuments, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} lat 
         * @param {number} lng 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNearbyRestaurants: async (lat: number, lng: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'lat' is not null or undefined
            assertParamExists('getNearbyRestaurants', 'lat', lat)
            // verify required parameter 'lng' is not null or undefined
            assertParamExists('getNearbyRestaurants', 'lng', lng)
            const localVarPath = `/api/Auxiliary/NearbyRestaurants/{Lat}/{Lng}`
                .replace(`{${"Lat"}}`, encodeURIComponent(String(lat)))
                .replace(`{${"Lng"}}`, encodeURIComponent(String(lng)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} requestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRequestConversationHistory: async (requestId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestId' is not null or undefined
            assertParamExists('getRequestConversationHistory', 'requestId', requestId)
            const localVarPath = `/api/Auxiliary/Request/History/{RequestId}`
                .replace(`{${"RequestId"}}`, encodeURIComponent(String(requestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GetRequests} [getRequests] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRequests: async (getRequests?: GetRequests, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Auxiliary/Requests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getRequests, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRestaurantByContext: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Auxiliary/Restaurant`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} url 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRestaurantByUrl: async (url: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'url' is not null or undefined
            assertParamExists('getRestaurantByUrl', 'url', url)
            const localVarPath = `/api/Auxiliary/Restaurant/{Url}`
                .replace(`{${"Url"}}`, encodeURIComponent(String(url)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} url 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRestaurantByUrlWithPublicMeals: async (url: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'url' is not null or undefined
            assertParamExists('getRestaurantByUrlWithPublicMeals', 'url', url)
            const localVarPath = `/api/Auxiliary/RestaurantWithPublicMeals/{Url}`
                .replace(`{${"Url"}}`, encodeURIComponent(String(url)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [count] 
         * @param {boolean} [isPagingSpecified] 
         * @param {string} [sortField] 
         * @param {SortDirection} [sortDir]   0 &#x3D; Undefined  1 &#x3D; Asc  2 &#x3D; Desc
         * @param {boolean} [isSortingSpecified] 
         * @param {RestaurantStatus} [filterStatus]   0 &#x3D; None  1 &#x3D; New  2 &#x3D; ToSign  4 &#x3D; ToVerify  8 &#x3D; Verified  16 &#x3D; Rejected  64 &#x3D; Public
         * @param {string} [filterModifedDateFrom] 
         * @param {string} [filterModifiedDateTo] 
         * @param {string} [filterCreatedDateFrom] 
         * @param {string} [filterCreatedDateTo] 
         * @param {string} [filterMail] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRestaurants: async (page?: number, count?: number, isPagingSpecified?: boolean, sortField?: string, sortDir?: SortDirection, isSortingSpecified?: boolean, filterStatus?: RestaurantStatus, filterModifedDateFrom?: string, filterModifiedDateTo?: string, filterCreatedDateFrom?: string, filterCreatedDateTo?: string, filterMail?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Auxiliary/Restaurants`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['Page'] = page;
            }

            if (count !== undefined) {
                localVarQueryParameter['Count'] = count;
            }

            if (isPagingSpecified !== undefined) {
                localVarQueryParameter['IsPagingSpecified'] = isPagingSpecified;
            }

            if (sortField !== undefined) {
                localVarQueryParameter['SortField'] = sortField;
            }

            if (sortDir !== undefined) {
                localVarQueryParameter['SortDir'] = sortDir;
            }

            if (isSortingSpecified !== undefined) {
                localVarQueryParameter['IsSortingSpecified'] = isSortingSpecified;
            }

            if (filterStatus !== undefined) {
                localVarQueryParameter['Filter.Status'] = filterStatus;
            }

            if (filterModifedDateFrom !== undefined) {
                localVarQueryParameter['Filter.ModifedDateFrom'] = (filterModifedDateFrom as any instanceof Date) ?
                    (filterModifedDateFrom as any).toISOString() :
                    filterModifedDateFrom;
            }

            if (filterModifiedDateTo !== undefined) {
                localVarQueryParameter['Filter.ModifiedDateTo'] = (filterModifiedDateTo as any instanceof Date) ?
                    (filterModifiedDateTo as any).toISOString() :
                    filterModifiedDateTo;
            }

            if (filterCreatedDateFrom !== undefined) {
                localVarQueryParameter['Filter.CreatedDateFrom'] = (filterCreatedDateFrom as any instanceof Date) ?
                    (filterCreatedDateFrom as any).toISOString() :
                    filterCreatedDateFrom;
            }

            if (filterCreatedDateTo !== undefined) {
                localVarQueryParameter['Filter.CreatedDateTo'] = (filterCreatedDateTo as any instanceof Date) ?
                    (filterCreatedDateTo as any).toISOString() :
                    filterCreatedDateTo;
            }

            if (filterMail !== undefined) {
                localVarQueryParameter['Filter.Mail'] = filterMail;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStaticData: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Auxiliary/StaticData`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PostAddOrUpdateRestaurant} [postAddOrUpdateRestaurant] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAddOrUpdateRestaurant: async (postAddOrUpdateRestaurant?: PostAddOrUpdateRestaurant, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Auxiliary/Restaurant`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postAddOrUpdateRestaurant, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PostAssignUserToRestaurant} [postAssignUserToRestaurant] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAssignUserToRestaurant: async (postAssignUserToRestaurant?: PostAssignUserToRestaurant, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Auxiliary/Restaurant/AssignUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postAssignUserToRestaurant, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PostDocument} [postDocument] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDocument: async (postDocument?: PostDocument, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Auxiliary/StaticData/Document`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postDocument, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} requestId 
         * @param {PostReplyOnRequest} [postReplyOnRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postReplyOnRequest: async (requestId: number, postReplyOnRequest?: PostReplyOnRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestId' is not null or undefined
            assertParamExists('postReplyOnRequest', 'requestId', requestId)
            const localVarPath = `/api/Auxiliary/Request/ReplyTo/{RequestId}`
                .replace(`{${"RequestId"}}`, encodeURIComponent(String(requestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postReplyOnRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PostRestaurantDetails} [postRestaurantDetails] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postRestaurantDetails: async (postRestaurantDetails?: PostRestaurantDetails, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Auxiliary/Restaurant/Details`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postRestaurantDetails, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PostRestaurantDocuments} [postRestaurantDocuments] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postRestaurantDocuments: async (postRestaurantDocuments?: PostRestaurantDocuments, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Auxiliary/Restaurant/Documents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postRestaurantDocuments, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} lat 
         * @param {number} lng 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postRestaurantLocation: async (lat: number, lng: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'lat' is not null or undefined
            assertParamExists('postRestaurantLocation', 'lat', lat)
            // verify required parameter 'lng' is not null or undefined
            assertParamExists('postRestaurantLocation', 'lng', lng)
            const localVarPath = `/api/Auxiliary/Restaurant/Location/{Lat}/{Lng}`
                .replace(`{${"Lat"}}`, encodeURIComponent(String(lat)))
                .replace(`{${"Lng"}}`, encodeURIComponent(String(lng)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PostRestaurantRequest} [postRestaurantRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postRestaurantRequest: async (postRestaurantRequest?: PostRestaurantRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Auxiliary/Request/New`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postRestaurantRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {PutDocument} [putDocument] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putDocument: async (id: number, putDocument?: PutDocument, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putDocument', 'id', id)
            const localVarPath = `/api/Auxiliary/StaticData/Document/{Id}`
                .replace(`{${"Id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(putDocument, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuxiliaryApi - functional programming interface
 * @export
 */
export const AuxiliaryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuxiliaryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {AcceptOrRejectRestaurantDetails} [acceptOrRejectRestaurantDetails] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async acceptOrRejectRestaurantDetails(acceptOrRejectRestaurantDetails?: AcceptOrRejectRestaurantDetails, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AcceptOrRejectRestaurantDetailsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.acceptOrRejectRestaurantDetails(acceptOrRejectRestaurantDetails, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AcceptOrRejectRestaurantDocuments} [acceptOrRejectRestaurantDocuments] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async acceptOrRejectRestaurantDocuments(acceptOrRejectRestaurantDocuments?: AcceptOrRejectRestaurantDocuments, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AcceptOrRejectRestaurantDocumentsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.acceptOrRejectRestaurantDocuments(acceptOrRejectRestaurantDocuments, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AcceptOrRejectRestaurantMeal} [acceptOrRejectRestaurantMeal] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async acceptOrRejectRestaurantMeal(acceptOrRejectRestaurantMeal?: AcceptOrRejectRestaurantMeal, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AcceptOrRejectRestaurantMealResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.acceptOrRejectRestaurantMeal(acceptOrRejectRestaurantMeal, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AcceptOrRejectRestaurantRequest} [acceptOrRejectRestaurantRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async acceptOrRejectRestaurantRequest(acceptOrRejectRestaurantRequest?: AcceptOrRejectRestaurantRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AcceptOrRejectRestaurantRequestResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.acceptOrRejectRestaurantRequest(acceptOrRejectRestaurantRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AddOrUpdateRestaurantMeal} [addOrUpdateRestaurantMeal] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addOrUpdateRestaurantMeal(addOrUpdateRestaurantMeal?: AddOrUpdateRestaurantMeal, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddOrUpdateRestaurantMealResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addOrUpdateRestaurantMeal(addOrUpdateRestaurantMeal, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDocument(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDocument(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteRestaurantMeal(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteRestaurantMeal(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} restaurantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async enableOrDisableRestaurant(restaurantId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EnableOrDisableRestaurantResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.enableOrDisableRestaurant(restaurantId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} mealId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async enableOrDisableRestaurantMeal(mealId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EnableOrDisableRestaurantMealResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.enableOrDisableRestaurantMeal(mealId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [count] 
         * @param {boolean} [isPagingSpecified] 
         * @param {string} [sortField] 
         * @param {SortDirection} [sortDir]   0 &#x3D; Undefined  1 &#x3D; Asc  2 &#x3D; Desc
         * @param {boolean} [isSortingSpecified] 
         * @param {number} [filterId] 
         * @param {string} [filterType] 
         * @param {string} [filterKey] 
         * @param {string} [filterAction] 
         * @param {string} [filterWho] 
         * @param {string} [filterWhenFrom] 
         * @param {string} [filterWhenTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDataLogs(page?: number, count?: number, isPagingSpecified?: boolean, sortField?: string, sortDir?: SortDirection, isSortingSpecified?: boolean, filterId?: number, filterType?: string, filterKey?: string, filterAction?: string, filterWho?: string, filterWhenFrom?: string, filterWhenTo?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAuditLogResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDataLogs(page, count, isPagingSpecified, sortField, sortDir, isSortingSpecified, filterId, filterType, filterKey, filterAction, filterWho, filterWhenFrom, filterWhenTo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDataLogsTypes(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAuditLogTypesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDataLogsTypes(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDocumentById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDocumentByIdResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDocumentById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} url 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDocumentByUrl(url: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDocumentByUrlResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDocumentByUrl(url, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {GetDocuments} [getDocuments] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDocuments(getDocuments?: GetDocuments, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDocumentsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDocuments(getDocuments, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} lat 
         * @param {number} lng 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNearbyRestaurants(lat: number, lng: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetNearbyRestaurantsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNearbyRestaurants(lat, lng, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} requestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRequestConversationHistory(requestId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetRequestConversationHistoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRequestConversationHistory(requestId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {GetRequests} [getRequests] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRequests(getRequests?: GetRequests, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetRequestsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRequests(getRequests, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRestaurantByContext(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetRestaurantByContextResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRestaurantByContext(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} url 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRestaurantByUrl(url: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetRestaurantByUrlResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRestaurantByUrl(url, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} url 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRestaurantByUrlWithPublicMeals(url: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetRestaurantByUrlWithPublicMealsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRestaurantByUrlWithPublicMeals(url, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [count] 
         * @param {boolean} [isPagingSpecified] 
         * @param {string} [sortField] 
         * @param {SortDirection} [sortDir]   0 &#x3D; Undefined  1 &#x3D; Asc  2 &#x3D; Desc
         * @param {boolean} [isSortingSpecified] 
         * @param {RestaurantStatus} [filterStatus]   0 &#x3D; None  1 &#x3D; New  2 &#x3D; ToSign  4 &#x3D; ToVerify  8 &#x3D; Verified  16 &#x3D; Rejected  64 &#x3D; Public
         * @param {string} [filterModifedDateFrom] 
         * @param {string} [filterModifiedDateTo] 
         * @param {string} [filterCreatedDateFrom] 
         * @param {string} [filterCreatedDateTo] 
         * @param {string} [filterMail] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRestaurants(page?: number, count?: number, isPagingSpecified?: boolean, sortField?: string, sortDir?: SortDirection, isSortingSpecified?: boolean, filterStatus?: RestaurantStatus, filterModifedDateFrom?: string, filterModifiedDateTo?: string, filterCreatedDateFrom?: string, filterCreatedDateTo?: string, filterMail?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetRestaurantsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRestaurants(page, count, isPagingSpecified, sortField, sortDir, isSortingSpecified, filterStatus, filterModifedDateFrom, filterModifiedDateTo, filterCreatedDateFrom, filterCreatedDateTo, filterMail, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStaticData(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetStaticDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStaticData(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PostAddOrUpdateRestaurant} [postAddOrUpdateRestaurant] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAddOrUpdateRestaurant(postAddOrUpdateRestaurant?: PostAddOrUpdateRestaurant, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostAddOrUpdateRestaurantResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAddOrUpdateRestaurant(postAddOrUpdateRestaurant, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PostAssignUserToRestaurant} [postAssignUserToRestaurant] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAssignUserToRestaurant(postAssignUserToRestaurant?: PostAssignUserToRestaurant, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostAssignUserToRestaurantResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAssignUserToRestaurant(postAssignUserToRestaurant, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PostDocument} [postDocument] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postDocument(postDocument?: PostDocument, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostDocumentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postDocument(postDocument, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} requestId 
         * @param {PostReplyOnRequest} [postReplyOnRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postReplyOnRequest(requestId: number, postReplyOnRequest?: PostReplyOnRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postReplyOnRequest(requestId, postReplyOnRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PostRestaurantDetails} [postRestaurantDetails] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postRestaurantDetails(postRestaurantDetails?: PostRestaurantDetails, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostRestaurantDetailsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postRestaurantDetails(postRestaurantDetails, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PostRestaurantDocuments} [postRestaurantDocuments] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postRestaurantDocuments(postRestaurantDocuments?: PostRestaurantDocuments, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostRestaurantDocumentsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postRestaurantDocuments(postRestaurantDocuments, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} lat 
         * @param {number} lng 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postRestaurantLocation(lat: number, lng: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostRestaurantLocationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postRestaurantLocation(lat, lng, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PostRestaurantRequest} [postRestaurantRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postRestaurantRequest(postRestaurantRequest?: PostRestaurantRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostRestaurantRequestResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postRestaurantRequest(postRestaurantRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {PutDocument} [putDocument] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putDocument(id: number, putDocument?: PutDocument, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PutDocumentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putDocument(id, putDocument, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuxiliaryApi - factory interface
 * @export
 */
export const AuxiliaryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuxiliaryApiFp(configuration)
    return {
        /**
         * 
         * @param {AcceptOrRejectRestaurantDetails} [acceptOrRejectRestaurantDetails] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptOrRejectRestaurantDetails(acceptOrRejectRestaurantDetails?: AcceptOrRejectRestaurantDetails, options?: any): AxiosPromise<AcceptOrRejectRestaurantDetailsResponse> {
            return localVarFp.acceptOrRejectRestaurantDetails(acceptOrRejectRestaurantDetails, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AcceptOrRejectRestaurantDocuments} [acceptOrRejectRestaurantDocuments] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptOrRejectRestaurantDocuments(acceptOrRejectRestaurantDocuments?: AcceptOrRejectRestaurantDocuments, options?: any): AxiosPromise<AcceptOrRejectRestaurantDocumentsResponse> {
            return localVarFp.acceptOrRejectRestaurantDocuments(acceptOrRejectRestaurantDocuments, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AcceptOrRejectRestaurantMeal} [acceptOrRejectRestaurantMeal] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptOrRejectRestaurantMeal(acceptOrRejectRestaurantMeal?: AcceptOrRejectRestaurantMeal, options?: any): AxiosPromise<AcceptOrRejectRestaurantMealResponse> {
            return localVarFp.acceptOrRejectRestaurantMeal(acceptOrRejectRestaurantMeal, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AcceptOrRejectRestaurantRequest} [acceptOrRejectRestaurantRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptOrRejectRestaurantRequest(acceptOrRejectRestaurantRequest?: AcceptOrRejectRestaurantRequest, options?: any): AxiosPromise<AcceptOrRejectRestaurantRequestResponse> {
            return localVarFp.acceptOrRejectRestaurantRequest(acceptOrRejectRestaurantRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AddOrUpdateRestaurantMeal} [addOrUpdateRestaurantMeal] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOrUpdateRestaurantMeal(addOrUpdateRestaurantMeal?: AddOrUpdateRestaurantMeal, options?: any): AxiosPromise<AddOrUpdateRestaurantMealResponse> {
            return localVarFp.addOrUpdateRestaurantMeal(addOrUpdateRestaurantMeal, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDocument(id: number, options?: any): AxiosPromise<object> {
            return localVarFp.deleteDocument(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRestaurantMeal(id: number, options?: any): AxiosPromise<object> {
            return localVarFp.deleteRestaurantMeal(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} restaurantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enableOrDisableRestaurant(restaurantId: number, options?: any): AxiosPromise<EnableOrDisableRestaurantResponse> {
            return localVarFp.enableOrDisableRestaurant(restaurantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} mealId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enableOrDisableRestaurantMeal(mealId: number, options?: any): AxiosPromise<EnableOrDisableRestaurantMealResponse> {
            return localVarFp.enableOrDisableRestaurantMeal(mealId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [count] 
         * @param {boolean} [isPagingSpecified] 
         * @param {string} [sortField] 
         * @param {SortDirection} [sortDir]   0 &#x3D; Undefined  1 &#x3D; Asc  2 &#x3D; Desc
         * @param {boolean} [isSortingSpecified] 
         * @param {number} [filterId] 
         * @param {string} [filterType] 
         * @param {string} [filterKey] 
         * @param {string} [filterAction] 
         * @param {string} [filterWho] 
         * @param {string} [filterWhenFrom] 
         * @param {string} [filterWhenTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDataLogs(page?: number, count?: number, isPagingSpecified?: boolean, sortField?: string, sortDir?: SortDirection, isSortingSpecified?: boolean, filterId?: number, filterType?: string, filterKey?: string, filterAction?: string, filterWho?: string, filterWhenFrom?: string, filterWhenTo?: string, options?: any): AxiosPromise<GetAuditLogResponse> {
            return localVarFp.getDataLogs(page, count, isPagingSpecified, sortField, sortDir, isSortingSpecified, filterId, filterType, filterKey, filterAction, filterWho, filterWhenFrom, filterWhenTo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDataLogsTypes(options?: any): AxiosPromise<GetAuditLogTypesResponse> {
            return localVarFp.getDataLogsTypes(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentById(id: number, options?: any): AxiosPromise<GetDocumentByIdResponse> {
            return localVarFp.getDocumentById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} url 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentByUrl(url: string, options?: any): AxiosPromise<GetDocumentByUrlResponse> {
            return localVarFp.getDocumentByUrl(url, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GetDocuments} [getDocuments] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocuments(getDocuments?: GetDocuments, options?: any): AxiosPromise<GetDocumentsResponse> {
            return localVarFp.getDocuments(getDocuments, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} lat 
         * @param {number} lng 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNearbyRestaurants(lat: number, lng: number, options?: any): AxiosPromise<GetNearbyRestaurantsResponse> {
            return localVarFp.getNearbyRestaurants(lat, lng, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} requestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRequestConversationHistory(requestId: number, options?: any): AxiosPromise<GetRequestConversationHistoryResponse> {
            return localVarFp.getRequestConversationHistory(requestId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GetRequests} [getRequests] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRequests(getRequests?: GetRequests, options?: any): AxiosPromise<GetRequestsResponse> {
            return localVarFp.getRequests(getRequests, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRestaurantByContext(options?: any): AxiosPromise<GetRestaurantByContextResponse> {
            return localVarFp.getRestaurantByContext(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} url 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRestaurantByUrl(url: string, options?: any): AxiosPromise<GetRestaurantByUrlResponse> {
            return localVarFp.getRestaurantByUrl(url, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} url 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRestaurantByUrlWithPublicMeals(url: string, options?: any): AxiosPromise<GetRestaurantByUrlWithPublicMealsResponse> {
            return localVarFp.getRestaurantByUrlWithPublicMeals(url, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [count] 
         * @param {boolean} [isPagingSpecified] 
         * @param {string} [sortField] 
         * @param {SortDirection} [sortDir]   0 &#x3D; Undefined  1 &#x3D; Asc  2 &#x3D; Desc
         * @param {boolean} [isSortingSpecified] 
         * @param {RestaurantStatus} [filterStatus]   0 &#x3D; None  1 &#x3D; New  2 &#x3D; ToSign  4 &#x3D; ToVerify  8 &#x3D; Verified  16 &#x3D; Rejected  64 &#x3D; Public
         * @param {string} [filterModifedDateFrom] 
         * @param {string} [filterModifiedDateTo] 
         * @param {string} [filterCreatedDateFrom] 
         * @param {string} [filterCreatedDateTo] 
         * @param {string} [filterMail] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRestaurants(page?: number, count?: number, isPagingSpecified?: boolean, sortField?: string, sortDir?: SortDirection, isSortingSpecified?: boolean, filterStatus?: RestaurantStatus, filterModifedDateFrom?: string, filterModifiedDateTo?: string, filterCreatedDateFrom?: string, filterCreatedDateTo?: string, filterMail?: string, options?: any): AxiosPromise<GetRestaurantsResponse> {
            return localVarFp.getRestaurants(page, count, isPagingSpecified, sortField, sortDir, isSortingSpecified, filterStatus, filterModifedDateFrom, filterModifiedDateTo, filterCreatedDateFrom, filterCreatedDateTo, filterMail, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStaticData(options?: any): AxiosPromise<GetStaticDataResponse> {
            return localVarFp.getStaticData(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PostAddOrUpdateRestaurant} [postAddOrUpdateRestaurant] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAddOrUpdateRestaurant(postAddOrUpdateRestaurant?: PostAddOrUpdateRestaurant, options?: any): AxiosPromise<PostAddOrUpdateRestaurantResponse> {
            return localVarFp.postAddOrUpdateRestaurant(postAddOrUpdateRestaurant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PostAssignUserToRestaurant} [postAssignUserToRestaurant] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAssignUserToRestaurant(postAssignUserToRestaurant?: PostAssignUserToRestaurant, options?: any): AxiosPromise<PostAssignUserToRestaurantResponse> {
            return localVarFp.postAssignUserToRestaurant(postAssignUserToRestaurant, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PostDocument} [postDocument] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDocument(postDocument?: PostDocument, options?: any): AxiosPromise<PostDocumentResponse> {
            return localVarFp.postDocument(postDocument, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} requestId 
         * @param {PostReplyOnRequest} [postReplyOnRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postReplyOnRequest(requestId: number, postReplyOnRequest?: PostReplyOnRequest, options?: any): AxiosPromise<object> {
            return localVarFp.postReplyOnRequest(requestId, postReplyOnRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PostRestaurantDetails} [postRestaurantDetails] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postRestaurantDetails(postRestaurantDetails?: PostRestaurantDetails, options?: any): AxiosPromise<PostRestaurantDetailsResponse> {
            return localVarFp.postRestaurantDetails(postRestaurantDetails, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PostRestaurantDocuments} [postRestaurantDocuments] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postRestaurantDocuments(postRestaurantDocuments?: PostRestaurantDocuments, options?: any): AxiosPromise<PostRestaurantDocumentsResponse> {
            return localVarFp.postRestaurantDocuments(postRestaurantDocuments, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} lat 
         * @param {number} lng 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postRestaurantLocation(lat: number, lng: number, options?: any): AxiosPromise<PostRestaurantLocationResponse> {
            return localVarFp.postRestaurantLocation(lat, lng, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PostRestaurantRequest} [postRestaurantRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postRestaurantRequest(postRestaurantRequest?: PostRestaurantRequest, options?: any): AxiosPromise<PostRestaurantRequestResponse> {
            return localVarFp.postRestaurantRequest(postRestaurantRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {PutDocument} [putDocument] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putDocument(id: number, putDocument?: PutDocument, options?: any): AxiosPromise<PutDocumentResponse> {
            return localVarFp.putDocument(id, putDocument, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuxiliaryApi - object-oriented interface
 * @export
 * @class AuxiliaryApi
 * @extends {BaseAPI}
 */
export class AuxiliaryApi extends BaseAPI {
    /**
     * 
     * @param {AcceptOrRejectRestaurantDetails} [acceptOrRejectRestaurantDetails] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuxiliaryApi
     */
    public acceptOrRejectRestaurantDetails(acceptOrRejectRestaurantDetails?: AcceptOrRejectRestaurantDetails, options?: AxiosRequestConfig) {
        return AuxiliaryApiFp(this.configuration).acceptOrRejectRestaurantDetails(acceptOrRejectRestaurantDetails, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AcceptOrRejectRestaurantDocuments} [acceptOrRejectRestaurantDocuments] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuxiliaryApi
     */
    public acceptOrRejectRestaurantDocuments(acceptOrRejectRestaurantDocuments?: AcceptOrRejectRestaurantDocuments, options?: AxiosRequestConfig) {
        return AuxiliaryApiFp(this.configuration).acceptOrRejectRestaurantDocuments(acceptOrRejectRestaurantDocuments, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AcceptOrRejectRestaurantMeal} [acceptOrRejectRestaurantMeal] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuxiliaryApi
     */
    public acceptOrRejectRestaurantMeal(acceptOrRejectRestaurantMeal?: AcceptOrRejectRestaurantMeal, options?: AxiosRequestConfig) {
        return AuxiliaryApiFp(this.configuration).acceptOrRejectRestaurantMeal(acceptOrRejectRestaurantMeal, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AcceptOrRejectRestaurantRequest} [acceptOrRejectRestaurantRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuxiliaryApi
     */
    public acceptOrRejectRestaurantRequest(acceptOrRejectRestaurantRequest?: AcceptOrRejectRestaurantRequest, options?: AxiosRequestConfig) {
        return AuxiliaryApiFp(this.configuration).acceptOrRejectRestaurantRequest(acceptOrRejectRestaurantRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AddOrUpdateRestaurantMeal} [addOrUpdateRestaurantMeal] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuxiliaryApi
     */
    public addOrUpdateRestaurantMeal(addOrUpdateRestaurantMeal?: AddOrUpdateRestaurantMeal, options?: AxiosRequestConfig) {
        return AuxiliaryApiFp(this.configuration).addOrUpdateRestaurantMeal(addOrUpdateRestaurantMeal, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuxiliaryApi
     */
    public deleteDocument(id: number, options?: AxiosRequestConfig) {
        return AuxiliaryApiFp(this.configuration).deleteDocument(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuxiliaryApi
     */
    public deleteRestaurantMeal(id: number, options?: AxiosRequestConfig) {
        return AuxiliaryApiFp(this.configuration).deleteRestaurantMeal(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} restaurantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuxiliaryApi
     */
    public enableOrDisableRestaurant(restaurantId: number, options?: AxiosRequestConfig) {
        return AuxiliaryApiFp(this.configuration).enableOrDisableRestaurant(restaurantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} mealId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuxiliaryApi
     */
    public enableOrDisableRestaurantMeal(mealId: number, options?: AxiosRequestConfig) {
        return AuxiliaryApiFp(this.configuration).enableOrDisableRestaurantMeal(mealId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] 
     * @param {number} [count] 
     * @param {boolean} [isPagingSpecified] 
     * @param {string} [sortField] 
     * @param {SortDirection} [sortDir]   0 &#x3D; Undefined  1 &#x3D; Asc  2 &#x3D; Desc
     * @param {boolean} [isSortingSpecified] 
     * @param {number} [filterId] 
     * @param {string} [filterType] 
     * @param {string} [filterKey] 
     * @param {string} [filterAction] 
     * @param {string} [filterWho] 
     * @param {string} [filterWhenFrom] 
     * @param {string} [filterWhenTo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuxiliaryApi
     */
    public getDataLogs(page?: number, count?: number, isPagingSpecified?: boolean, sortField?: string, sortDir?: SortDirection, isSortingSpecified?: boolean, filterId?: number, filterType?: string, filterKey?: string, filterAction?: string, filterWho?: string, filterWhenFrom?: string, filterWhenTo?: string, options?: AxiosRequestConfig) {
        return AuxiliaryApiFp(this.configuration).getDataLogs(page, count, isPagingSpecified, sortField, sortDir, isSortingSpecified, filterId, filterType, filterKey, filterAction, filterWho, filterWhenFrom, filterWhenTo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuxiliaryApi
     */
    public getDataLogsTypes(options?: AxiosRequestConfig) {
        return AuxiliaryApiFp(this.configuration).getDataLogsTypes(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuxiliaryApi
     */
    public getDocumentById(id: number, options?: AxiosRequestConfig) {
        return AuxiliaryApiFp(this.configuration).getDocumentById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} url 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuxiliaryApi
     */
    public getDocumentByUrl(url: string, options?: AxiosRequestConfig) {
        return AuxiliaryApiFp(this.configuration).getDocumentByUrl(url, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GetDocuments} [getDocuments] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuxiliaryApi
     */
    public getDocuments(getDocuments?: GetDocuments, options?: AxiosRequestConfig) {
        return AuxiliaryApiFp(this.configuration).getDocuments(getDocuments, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} lat 
     * @param {number} lng 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuxiliaryApi
     */
    public getNearbyRestaurants(lat: number, lng: number, options?: AxiosRequestConfig) {
        return AuxiliaryApiFp(this.configuration).getNearbyRestaurants(lat, lng, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} requestId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuxiliaryApi
     */
    public getRequestConversationHistory(requestId: number, options?: AxiosRequestConfig) {
        return AuxiliaryApiFp(this.configuration).getRequestConversationHistory(requestId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GetRequests} [getRequests] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuxiliaryApi
     */
    public getRequests(getRequests?: GetRequests, options?: AxiosRequestConfig) {
        return AuxiliaryApiFp(this.configuration).getRequests(getRequests, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuxiliaryApi
     */
    public getRestaurantByContext(options?: AxiosRequestConfig) {
        return AuxiliaryApiFp(this.configuration).getRestaurantByContext(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} url 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuxiliaryApi
     */
    public getRestaurantByUrl(url: string, options?: AxiosRequestConfig) {
        return AuxiliaryApiFp(this.configuration).getRestaurantByUrl(url, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} url 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuxiliaryApi
     */
    public getRestaurantByUrlWithPublicMeals(url: string, options?: AxiosRequestConfig) {
        return AuxiliaryApiFp(this.configuration).getRestaurantByUrlWithPublicMeals(url, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] 
     * @param {number} [count] 
     * @param {boolean} [isPagingSpecified] 
     * @param {string} [sortField] 
     * @param {SortDirection} [sortDir]   0 &#x3D; Undefined  1 &#x3D; Asc  2 &#x3D; Desc
     * @param {boolean} [isSortingSpecified] 
     * @param {RestaurantStatus} [filterStatus]   0 &#x3D; None  1 &#x3D; New  2 &#x3D; ToSign  4 &#x3D; ToVerify  8 &#x3D; Verified  16 &#x3D; Rejected  64 &#x3D; Public
     * @param {string} [filterModifedDateFrom] 
     * @param {string} [filterModifiedDateTo] 
     * @param {string} [filterCreatedDateFrom] 
     * @param {string} [filterCreatedDateTo] 
     * @param {string} [filterMail] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuxiliaryApi
     */
    public getRestaurants(page?: number, count?: number, isPagingSpecified?: boolean, sortField?: string, sortDir?: SortDirection, isSortingSpecified?: boolean, filterStatus?: RestaurantStatus, filterModifedDateFrom?: string, filterModifiedDateTo?: string, filterCreatedDateFrom?: string, filterCreatedDateTo?: string, filterMail?: string, options?: AxiosRequestConfig) {
        return AuxiliaryApiFp(this.configuration).getRestaurants(page, count, isPagingSpecified, sortField, sortDir, isSortingSpecified, filterStatus, filterModifedDateFrom, filterModifiedDateTo, filterCreatedDateFrom, filterCreatedDateTo, filterMail, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuxiliaryApi
     */
    public getStaticData(options?: AxiosRequestConfig) {
        return AuxiliaryApiFp(this.configuration).getStaticData(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PostAddOrUpdateRestaurant} [postAddOrUpdateRestaurant] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuxiliaryApi
     */
    public postAddOrUpdateRestaurant(postAddOrUpdateRestaurant?: PostAddOrUpdateRestaurant, options?: AxiosRequestConfig) {
        return AuxiliaryApiFp(this.configuration).postAddOrUpdateRestaurant(postAddOrUpdateRestaurant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PostAssignUserToRestaurant} [postAssignUserToRestaurant] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuxiliaryApi
     */
    public postAssignUserToRestaurant(postAssignUserToRestaurant?: PostAssignUserToRestaurant, options?: AxiosRequestConfig) {
        return AuxiliaryApiFp(this.configuration).postAssignUserToRestaurant(postAssignUserToRestaurant, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PostDocument} [postDocument] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuxiliaryApi
     */
    public postDocument(postDocument?: PostDocument, options?: AxiosRequestConfig) {
        return AuxiliaryApiFp(this.configuration).postDocument(postDocument, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} requestId 
     * @param {PostReplyOnRequest} [postReplyOnRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuxiliaryApi
     */
    public postReplyOnRequest(requestId: number, postReplyOnRequest?: PostReplyOnRequest, options?: AxiosRequestConfig) {
        return AuxiliaryApiFp(this.configuration).postReplyOnRequest(requestId, postReplyOnRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PostRestaurantDetails} [postRestaurantDetails] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuxiliaryApi
     */
    public postRestaurantDetails(postRestaurantDetails?: PostRestaurantDetails, options?: AxiosRequestConfig) {
        return AuxiliaryApiFp(this.configuration).postRestaurantDetails(postRestaurantDetails, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PostRestaurantDocuments} [postRestaurantDocuments] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuxiliaryApi
     */
    public postRestaurantDocuments(postRestaurantDocuments?: PostRestaurantDocuments, options?: AxiosRequestConfig) {
        return AuxiliaryApiFp(this.configuration).postRestaurantDocuments(postRestaurantDocuments, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} lat 
     * @param {number} lng 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuxiliaryApi
     */
    public postRestaurantLocation(lat: number, lng: number, options?: AxiosRequestConfig) {
        return AuxiliaryApiFp(this.configuration).postRestaurantLocation(lat, lng, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PostRestaurantRequest} [postRestaurantRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuxiliaryApi
     */
    public postRestaurantRequest(postRestaurantRequest?: PostRestaurantRequest, options?: AxiosRequestConfig) {
        return AuxiliaryApiFp(this.configuration).postRestaurantRequest(postRestaurantRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {PutDocument} [putDocument] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuxiliaryApi
     */
    public putDocument(id: number, putDocument?: PutDocument, options?: AxiosRequestConfig) {
        return AuxiliaryApiFp(this.configuration).putDocument(id, putDocument, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CustomerApi - axios parameter creator
 * @export
 */
export const CustomerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Customer} [customer] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOrUpdateCustomer: async (customer?: Customer, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Customer/Customer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customer, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authServiceCallbacks: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Customer/AuthServiceCallbacks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} settingsId 
         * @param {number} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteExcludedProduct: async (settingsId: string, productId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'settingsId' is not null or undefined
            assertParamExists('deleteExcludedProduct', 'settingsId', settingsId)
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('deleteExcludedProduct', 'productId', productId)
            const localVarPath = `/api/Customer/{SettingsId}/Exclusions/Product/{ProductId}`
                .replace(`{${"SettingsId"}}`, encodeURIComponent(String(settingsId)))
                .replace(`{${"ProductId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} mealId 
         * @param {MealTypeEnum} mealType   0 &#x3D; Breakfast  1 &#x3D; Brunch  2 &#x3D; Elevenses  3 &#x3D; Lunch  4 &#x3D; Tea  5 &#x3D; Supper  6 &#x3D; Dinner  7 &#x3D; Beverages
         * @param {number} dayId 
         * @param {number} customerId 
         * @param {number} subscriptionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMealInDayPlan: async (mealId: number, mealType: MealTypeEnum, dayId: number, customerId: number, subscriptionId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mealId' is not null or undefined
            assertParamExists('deleteMealInDayPlan', 'mealId', mealId)
            // verify required parameter 'mealType' is not null or undefined
            assertParamExists('deleteMealInDayPlan', 'mealType', mealType)
            // verify required parameter 'dayId' is not null or undefined
            assertParamExists('deleteMealInDayPlan', 'dayId', dayId)
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('deleteMealInDayPlan', 'customerId', customerId)
            // verify required parameter 'subscriptionId' is not null or undefined
            assertParamExists('deleteMealInDayPlan', 'subscriptionId', subscriptionId)
            const localVarPath = `/api/Customer/Diet/DietPlanDayMeal/Meal/{MealId}/MealType/{MealType}/Day/{DayId}/Customer/{CustomerId}/Subscription/{SubscriptionId}`
                .replace(`{${"MealId"}}`, encodeURIComponent(String(mealId)))
                .replace(`{${"MealType"}}`, encodeURIComponent(String(mealType)))
                .replace(`{${"DayId"}}`, encodeURIComponent(String(dayId)))
                .replace(`{${"CustomerId"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"SubscriptionId"}}`, encodeURIComponent(String(subscriptionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} settingsId 
         * @param {number} tagId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRemoveTagsFromMealForCustomer: async (settingsId: string, tagId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'settingsId' is not null or undefined
            assertParamExists('deleteRemoveTagsFromMealForCustomer', 'settingsId', settingsId)
            // verify required parameter 'tagId' is not null or undefined
            assertParamExists('deleteRemoveTagsFromMealForCustomer', 'tagId', tagId)
            const localVarPath = `/api/Customer/{SettingsId}/TagsMealsForDietServings/{TagId}`
                .replace(`{${"SettingsId"}}`, encodeURIComponent(String(settingsId)))
                .replace(`{${"TagId"}}`, encodeURIComponent(String(tagId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomer: async (customerId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('getCustomer', 'customerId', customerId)
            const localVarPath = `/api/Customer/GetCustomer/{customerId}`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} mail 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerId: async (mail: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mail' is not null or undefined
            assertParamExists('getCustomerId', 'mail', mail)
            const localVarPath = `/api/Customer/{Mail}/Id`
                .replace(`{${"Mail"}}`, encodeURIComponent(String(mail)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} settingsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerSettingsAndPreferences: async (settingsId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'settingsId' is not null or undefined
            assertParamExists('getCustomerSettingsAndPreferences', 'settingsId', settingsId)
            const localVarPath = `/api/Customer/{SettingsId}/Settings`
                .replace(`{${"SettingsId"}}`, encodeURIComponent(String(settingsId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerSubs: async (customerId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('getCustomerSubs', 'customerId', customerId)
            const localVarPath = `/api/Customer/{CustomerId}/Subscription`
                .replace(`{${"CustomerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLoggedInUserData: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Customer/GetLoggedInUserData`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegisteredCustomers: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Customer/Registered`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} settingsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTagsMealsForCustomer: async (settingsId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'settingsId' is not null or undefined
            assertParamExists('getTagsMealsForCustomer', 'settingsId', settingsId)
            const localVarPath = `/api/Customer/{SettingsId}/TagsMealsForDietServings`
                .replace(`{${"SettingsId"}}`, encodeURIComponent(String(settingsId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} settingsId 
         * @param {number} customerId 
         * @param {PostCorelateUserWithCustomer} [postCorelateUserWithCustomer] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCorelateUserWithCustomer: async (settingsId: string, customerId: number, postCorelateUserWithCustomer?: PostCorelateUserWithCustomer, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'settingsId' is not null or undefined
            assertParamExists('postCorelateUserWithCustomer', 'settingsId', settingsId)
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('postCorelateUserWithCustomer', 'customerId', customerId)
            const localVarPath = `/api/Customer/{SettingsId}/Corelate/{CustomerId}`
                .replace(`{${"SettingsId"}}`, encodeURIComponent(String(settingsId)))
                .replace(`{${"CustomerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postCorelateUserWithCustomer, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PostCreateCustomerSettings} [postCreateCustomerSettings] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCreateCustomerSettings: async (postCreateCustomerSettings?: PostCreateCustomerSettings, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Customer/Create/Settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postCreateCustomerSettings, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} settingsId 
         * @param {PostCreateCustomerTrainingSettings} [postCreateCustomerTrainingSettings] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCreateCustomerTrainingSettings: async (settingsId: string, postCreateCustomerTrainingSettings?: PostCreateCustomerTrainingSettings, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'settingsId' is not null or undefined
            assertParamExists('postCreateCustomerTrainingSettings', 'settingsId', settingsId)
            const localVarPath = `/api/Customer/{SettingsId}/TrainingSettings`
                .replace(`{${"SettingsId"}}`, encodeURIComponent(String(settingsId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postCreateCustomerTrainingSettings, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} settingsId 
         * @param {PostCreateDietPlan} [postCreateDietPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCreateDietPlan: async (settingsId: string, postCreateDietPlan?: PostCreateDietPlan, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'settingsId' is not null or undefined
            assertParamExists('postCreateDietPlan', 'settingsId', settingsId)
            const localVarPath = `/api/Customer/{SettingsId}/DietPlan/New`
                .replace(`{${"SettingsId"}}`, encodeURIComponent(String(settingsId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postCreateDietPlan, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PostCustomerRequest} [postCustomerRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCustomerRequest: async (postCustomerRequest?: PostCustomerRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Customer/Request/New`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postCustomerRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} customerId 
         * @param {number} subscriptionId 
         * @param {number} dayId 
         * @param {PostMealInCustomerDayPlan} [postMealInCustomerDayPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postMealInCustomerDayPlan: async (customerId: number, subscriptionId: number, dayId: number, postMealInCustomerDayPlan?: PostMealInCustomerDayPlan, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('postMealInCustomerDayPlan', 'customerId', customerId)
            // verify required parameter 'subscriptionId' is not null or undefined
            assertParamExists('postMealInCustomerDayPlan', 'subscriptionId', subscriptionId)
            // verify required parameter 'dayId' is not null or undefined
            assertParamExists('postMealInCustomerDayPlan', 'dayId', dayId)
            const localVarPath = `/api/Customer/{CustomerId}/Subscription/{SubscriptionId}/Day/{DayId}`
                .replace(`{${"CustomerId"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"SubscriptionId"}}`, encodeURIComponent(String(subscriptionId)))
                .replace(`{${"DayId"}}`, encodeURIComponent(String(dayId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postMealInCustomerDayPlan, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} settingsId 
         * @param {PostTagsMealsForDietServings} [postTagsMealsForDietServings] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postTagsMealsForDietServings: async (settingsId: string, postTagsMealsForDietServings?: PostTagsMealsForDietServings, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'settingsId' is not null or undefined
            assertParamExists('postTagsMealsForDietServings', 'settingsId', settingsId)
            const localVarPath = `/api/Customer/{SettingsId}/TagsMealsForDietServings`
                .replace(`{${"SettingsId"}}`, encodeURIComponent(String(settingsId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postTagsMealsForDietServings, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} settingsId 
         * @param {PutDietObjective} [putDietObjective] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putDietObjective: async (settingsId: string, putDietObjective?: PutDietObjective, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'settingsId' is not null or undefined
            assertParamExists('putDietObjective', 'settingsId', settingsId)
            const localVarPath = `/api/Customer/{SettingsId}/DietObjective`
                .replace(`{${"SettingsId"}}`, encodeURIComponent(String(settingsId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(putDietObjective, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} settingsId 
         * @param {PutDietStartDayUtc} [putDietStartDayUtc] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putDietStartDayUtc: async (settingsId: string, putDietStartDayUtc?: PutDietStartDayUtc, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'settingsId' is not null or undefined
            assertParamExists('putDietStartDayUtc', 'settingsId', settingsId)
            const localVarPath = `/api/Customer/{SettingsId}/DietStartDayUtc`
                .replace(`{${"SettingsId"}}`, encodeURIComponent(String(settingsId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(putDietStartDayUtc, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} settingsId 
         * @param {PutExcludedProduct} [putExcludedProduct] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putExcludedProduct: async (settingsId: string, putExcludedProduct?: PutExcludedProduct, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'settingsId' is not null or undefined
            assertParamExists('putExcludedProduct', 'settingsId', settingsId)
            const localVarPath = `/api/Customer/{SettingsId}/Exclusions/Product`
                .replace(`{${"SettingsId"}}`, encodeURIComponent(String(settingsId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(putExcludedProduct, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} settingsId 
         * @param {PutExclusionsForDietPlan} [putExclusionsForDietPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putExclusionsForDietPlan: async (settingsId: string, putExclusionsForDietPlan?: PutExclusionsForDietPlan, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'settingsId' is not null or undefined
            assertParamExists('putExclusionsForDietPlan', 'settingsId', settingsId)
            const localVarPath = `/api/Customer/{SettingsId}/ExclusionsForDietPlan`
                .replace(`{${"SettingsId"}}`, encodeURIComponent(String(settingsId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(putExclusionsForDietPlan, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} settingsId 
         * @param {PutInfoAboutYourself} [putInfoAboutYourself] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putInfoAboutYourself: async (settingsId: string, putInfoAboutYourself?: PutInfoAboutYourself, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'settingsId' is not null or undefined
            assertParamExists('putInfoAboutYourself', 'settingsId', settingsId)
            const localVarPath = `/api/Customer/{SettingsId}/InfoAboutYourself`
                .replace(`{${"SettingsId"}}`, encodeURIComponent(String(settingsId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(putInfoAboutYourself, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} settingsId 
         * @param {PutSelectDietType} [putSelectDietType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putSelectDietType: async (settingsId: string, putSelectDietType?: PutSelectDietType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'settingsId' is not null or undefined
            assertParamExists('putSelectDietType', 'settingsId', settingsId)
            const localVarPath = `/api/Customer/{SettingsId}/DietType`
                .replace(`{${"SettingsId"}}`, encodeURIComponent(String(settingsId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(putSelectDietType, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} settingsId 
         * @param {PutServingPreferences} [putServingPreferences] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putServingPreferences: async (settingsId: string, putServingPreferences?: PutServingPreferences, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'settingsId' is not null or undefined
            assertParamExists('putServingPreferences', 'settingsId', settingsId)
            const localVarPath = `/api/Customer/{SettingsId}/ServingPreferences`
                .replace(`{${"SettingsId"}}`, encodeURIComponent(String(settingsId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(putServingPreferences, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} settingsId 
         * @param {PutUserInterview} [putUserInterview] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putUserInterview: async (settingsId: string, putUserInterview?: PutUserInterview, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'settingsId' is not null or undefined
            assertParamExists('putUserInterview', 'settingsId', settingsId)
            const localVarPath = `/api/Customer/{SettingsId}/UserInterview`
                .replace(`{${"SettingsId"}}`, encodeURIComponent(String(settingsId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(putUserInterview, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomerApi - functional programming interface
 * @export
 */
export const CustomerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CustomerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {Customer} [customer] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addOrUpdateCustomer(customer?: Customer, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddOrUpdateCustomerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addOrUpdateCustomer(customer, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authServiceCallbacks(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authServiceCallbacks(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} settingsId 
         * @param {number} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteExcludedProduct(settingsId: string, productId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteExcludedProductResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteExcludedProduct(settingsId, productId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} mealId 
         * @param {MealTypeEnum} mealType   0 &#x3D; Breakfast  1 &#x3D; Brunch  2 &#x3D; Elevenses  3 &#x3D; Lunch  4 &#x3D; Tea  5 &#x3D; Supper  6 &#x3D; Dinner  7 &#x3D; Beverages
         * @param {number} dayId 
         * @param {number} customerId 
         * @param {number} subscriptionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteMealInDayPlan(mealId: number, mealType: MealTypeEnum, dayId: number, customerId: number, subscriptionId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteMealInDayPlan(mealId, mealType, dayId, customerId, subscriptionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} settingsId 
         * @param {number} tagId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteRemoveTagsFromMealForCustomer(settingsId: string, tagId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteRemoveTagsFromMealForCustomer(settingsId, tagId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomer(customerId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCustomerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomer(customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} mail 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomerId(mail: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCustomerIdResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomerId(mail, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} settingsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomerSettingsAndPreferences(settingsId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCustomerSettingsAndPreferencesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomerSettingsAndPreferences(settingsId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomerSubs(customerId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCustomerSubsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomerSubs(customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLoggedInUserData(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetLoggedInUserDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLoggedInUserData(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRegisteredCustomers(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetRegisteredCustomersResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRegisteredCustomers(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} settingsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTagsMealsForCustomer(settingsId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetTagsMealsForCustomerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTagsMealsForCustomer(settingsId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} settingsId 
         * @param {number} customerId 
         * @param {PostCorelateUserWithCustomer} [postCorelateUserWithCustomer] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postCorelateUserWithCustomer(settingsId: string, customerId: number, postCorelateUserWithCustomer?: PostCorelateUserWithCustomer, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postCorelateUserWithCustomer(settingsId, customerId, postCorelateUserWithCustomer, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PostCreateCustomerSettings} [postCreateCustomerSettings] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postCreateCustomerSettings(postCreateCustomerSettings?: PostCreateCustomerSettings, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostCreateCustomerSettingsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postCreateCustomerSettings(postCreateCustomerSettings, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} settingsId 
         * @param {PostCreateCustomerTrainingSettings} [postCreateCustomerTrainingSettings] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postCreateCustomerTrainingSettings(settingsId: string, postCreateCustomerTrainingSettings?: PostCreateCustomerTrainingSettings, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostCreateCustomerTrainingSettingsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postCreateCustomerTrainingSettings(settingsId, postCreateCustomerTrainingSettings, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} settingsId 
         * @param {PostCreateDietPlan} [postCreateDietPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postCreateDietPlan(settingsId: string, postCreateDietPlan?: PostCreateDietPlan, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostCreateDietPlanResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postCreateDietPlan(settingsId, postCreateDietPlan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PostCustomerRequest} [postCustomerRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postCustomerRequest(postCustomerRequest?: PostCustomerRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postCustomerRequest(postCustomerRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} customerId 
         * @param {number} subscriptionId 
         * @param {number} dayId 
         * @param {PostMealInCustomerDayPlan} [postMealInCustomerDayPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postMealInCustomerDayPlan(customerId: number, subscriptionId: number, dayId: number, postMealInCustomerDayPlan?: PostMealInCustomerDayPlan, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostMealInCustomerDayPlanResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postMealInCustomerDayPlan(customerId, subscriptionId, dayId, postMealInCustomerDayPlan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} settingsId 
         * @param {PostTagsMealsForDietServings} [postTagsMealsForDietServings] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postTagsMealsForDietServings(settingsId: string, postTagsMealsForDietServings?: PostTagsMealsForDietServings, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostTagsMealsForDietServingsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postTagsMealsForDietServings(settingsId, postTagsMealsForDietServings, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} settingsId 
         * @param {PutDietObjective} [putDietObjective] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putDietObjective(settingsId: string, putDietObjective?: PutDietObjective, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PutDietObjectiveResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putDietObjective(settingsId, putDietObjective, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} settingsId 
         * @param {PutDietStartDayUtc} [putDietStartDayUtc] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putDietStartDayUtc(settingsId: string, putDietStartDayUtc?: PutDietStartDayUtc, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PutDietObjectiveResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putDietStartDayUtc(settingsId, putDietStartDayUtc, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} settingsId 
         * @param {PutExcludedProduct} [putExcludedProduct] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putExcludedProduct(settingsId: string, putExcludedProduct?: PutExcludedProduct, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PutExcludedProductResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putExcludedProduct(settingsId, putExcludedProduct, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} settingsId 
         * @param {PutExclusionsForDietPlan} [putExclusionsForDietPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putExclusionsForDietPlan(settingsId: string, putExclusionsForDietPlan?: PutExclusionsForDietPlan, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PutExclusionsForDietPlanResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putExclusionsForDietPlan(settingsId, putExclusionsForDietPlan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} settingsId 
         * @param {PutInfoAboutYourself} [putInfoAboutYourself] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putInfoAboutYourself(settingsId: string, putInfoAboutYourself?: PutInfoAboutYourself, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PutInfoAboutYourselfResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putInfoAboutYourself(settingsId, putInfoAboutYourself, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} settingsId 
         * @param {PutSelectDietType} [putSelectDietType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putSelectDietType(settingsId: string, putSelectDietType?: PutSelectDietType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PutSelectDietTypeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putSelectDietType(settingsId, putSelectDietType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} settingsId 
         * @param {PutServingPreferences} [putServingPreferences] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putServingPreferences(settingsId: string, putServingPreferences?: PutServingPreferences, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PutServingPreferencesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putServingPreferences(settingsId, putServingPreferences, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} settingsId 
         * @param {PutUserInterview} [putUserInterview] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putUserInterview(settingsId: string, putUserInterview?: PutUserInterview, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putUserInterview(settingsId, putUserInterview, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CustomerApi - factory interface
 * @export
 */
export const CustomerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CustomerApiFp(configuration)
    return {
        /**
         * 
         * @param {Customer} [customer] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOrUpdateCustomer(customer?: Customer, options?: any): AxiosPromise<AddOrUpdateCustomerResponse> {
            return localVarFp.addOrUpdateCustomer(customer, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authServiceCallbacks(options?: any): AxiosPromise<void> {
            return localVarFp.authServiceCallbacks(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} settingsId 
         * @param {number} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteExcludedProduct(settingsId: string, productId: number, options?: any): AxiosPromise<DeleteExcludedProductResponse> {
            return localVarFp.deleteExcludedProduct(settingsId, productId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} mealId 
         * @param {MealTypeEnum} mealType   0 &#x3D; Breakfast  1 &#x3D; Brunch  2 &#x3D; Elevenses  3 &#x3D; Lunch  4 &#x3D; Tea  5 &#x3D; Supper  6 &#x3D; Dinner  7 &#x3D; Beverages
         * @param {number} dayId 
         * @param {number} customerId 
         * @param {number} subscriptionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMealInDayPlan(mealId: number, mealType: MealTypeEnum, dayId: number, customerId: number, subscriptionId: number, options?: any): AxiosPromise<object> {
            return localVarFp.deleteMealInDayPlan(mealId, mealType, dayId, customerId, subscriptionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} settingsId 
         * @param {number} tagId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRemoveTagsFromMealForCustomer(settingsId: string, tagId: number, options?: any): AxiosPromise<object> {
            return localVarFp.deleteRemoveTagsFromMealForCustomer(settingsId, tagId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomer(customerId: number, options?: any): AxiosPromise<GetCustomerResponse> {
            return localVarFp.getCustomer(customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} mail 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerId(mail: string, options?: any): AxiosPromise<GetCustomerIdResponse> {
            return localVarFp.getCustomerId(mail, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} settingsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerSettingsAndPreferences(settingsId: string, options?: any): AxiosPromise<GetCustomerSettingsAndPreferencesResponse> {
            return localVarFp.getCustomerSettingsAndPreferences(settingsId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerSubs(customerId: number, options?: any): AxiosPromise<GetCustomerSubsResponse> {
            return localVarFp.getCustomerSubs(customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLoggedInUserData(options?: any): AxiosPromise<GetLoggedInUserDataResponse> {
            return localVarFp.getLoggedInUserData(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegisteredCustomers(options?: any): AxiosPromise<GetRegisteredCustomersResponse> {
            return localVarFp.getRegisteredCustomers(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} settingsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTagsMealsForCustomer(settingsId: string, options?: any): AxiosPromise<GetTagsMealsForCustomerResponse> {
            return localVarFp.getTagsMealsForCustomer(settingsId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} settingsId 
         * @param {number} customerId 
         * @param {PostCorelateUserWithCustomer} [postCorelateUserWithCustomer] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCorelateUserWithCustomer(settingsId: string, customerId: number, postCorelateUserWithCustomer?: PostCorelateUserWithCustomer, options?: any): AxiosPromise<object> {
            return localVarFp.postCorelateUserWithCustomer(settingsId, customerId, postCorelateUserWithCustomer, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PostCreateCustomerSettings} [postCreateCustomerSettings] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCreateCustomerSettings(postCreateCustomerSettings?: PostCreateCustomerSettings, options?: any): AxiosPromise<PostCreateCustomerSettingsResponse> {
            return localVarFp.postCreateCustomerSettings(postCreateCustomerSettings, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} settingsId 
         * @param {PostCreateCustomerTrainingSettings} [postCreateCustomerTrainingSettings] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCreateCustomerTrainingSettings(settingsId: string, postCreateCustomerTrainingSettings?: PostCreateCustomerTrainingSettings, options?: any): AxiosPromise<PostCreateCustomerTrainingSettingsResponse> {
            return localVarFp.postCreateCustomerTrainingSettings(settingsId, postCreateCustomerTrainingSettings, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} settingsId 
         * @param {PostCreateDietPlan} [postCreateDietPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCreateDietPlan(settingsId: string, postCreateDietPlan?: PostCreateDietPlan, options?: any): AxiosPromise<PostCreateDietPlanResponse> {
            return localVarFp.postCreateDietPlan(settingsId, postCreateDietPlan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PostCustomerRequest} [postCustomerRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCustomerRequest(postCustomerRequest?: PostCustomerRequest, options?: any): AxiosPromise<object> {
            return localVarFp.postCustomerRequest(postCustomerRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} customerId 
         * @param {number} subscriptionId 
         * @param {number} dayId 
         * @param {PostMealInCustomerDayPlan} [postMealInCustomerDayPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postMealInCustomerDayPlan(customerId: number, subscriptionId: number, dayId: number, postMealInCustomerDayPlan?: PostMealInCustomerDayPlan, options?: any): AxiosPromise<PostMealInCustomerDayPlanResponse> {
            return localVarFp.postMealInCustomerDayPlan(customerId, subscriptionId, dayId, postMealInCustomerDayPlan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} settingsId 
         * @param {PostTagsMealsForDietServings} [postTagsMealsForDietServings] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postTagsMealsForDietServings(settingsId: string, postTagsMealsForDietServings?: PostTagsMealsForDietServings, options?: any): AxiosPromise<PostTagsMealsForDietServingsResponse> {
            return localVarFp.postTagsMealsForDietServings(settingsId, postTagsMealsForDietServings, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} settingsId 
         * @param {PutDietObjective} [putDietObjective] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putDietObjective(settingsId: string, putDietObjective?: PutDietObjective, options?: any): AxiosPromise<PutDietObjectiveResponse> {
            return localVarFp.putDietObjective(settingsId, putDietObjective, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} settingsId 
         * @param {PutDietStartDayUtc} [putDietStartDayUtc] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putDietStartDayUtc(settingsId: string, putDietStartDayUtc?: PutDietStartDayUtc, options?: any): AxiosPromise<PutDietObjectiveResponse> {
            return localVarFp.putDietStartDayUtc(settingsId, putDietStartDayUtc, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} settingsId 
         * @param {PutExcludedProduct} [putExcludedProduct] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putExcludedProduct(settingsId: string, putExcludedProduct?: PutExcludedProduct, options?: any): AxiosPromise<PutExcludedProductResponse> {
            return localVarFp.putExcludedProduct(settingsId, putExcludedProduct, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} settingsId 
         * @param {PutExclusionsForDietPlan} [putExclusionsForDietPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putExclusionsForDietPlan(settingsId: string, putExclusionsForDietPlan?: PutExclusionsForDietPlan, options?: any): AxiosPromise<PutExclusionsForDietPlanResponse> {
            return localVarFp.putExclusionsForDietPlan(settingsId, putExclusionsForDietPlan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} settingsId 
         * @param {PutInfoAboutYourself} [putInfoAboutYourself] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putInfoAboutYourself(settingsId: string, putInfoAboutYourself?: PutInfoAboutYourself, options?: any): AxiosPromise<PutInfoAboutYourselfResponse> {
            return localVarFp.putInfoAboutYourself(settingsId, putInfoAboutYourself, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} settingsId 
         * @param {PutSelectDietType} [putSelectDietType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putSelectDietType(settingsId: string, putSelectDietType?: PutSelectDietType, options?: any): AxiosPromise<PutSelectDietTypeResponse> {
            return localVarFp.putSelectDietType(settingsId, putSelectDietType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} settingsId 
         * @param {PutServingPreferences} [putServingPreferences] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putServingPreferences(settingsId: string, putServingPreferences?: PutServingPreferences, options?: any): AxiosPromise<PutServingPreferencesResponse> {
            return localVarFp.putServingPreferences(settingsId, putServingPreferences, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} settingsId 
         * @param {PutUserInterview} [putUserInterview] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putUserInterview(settingsId: string, putUserInterview?: PutUserInterview, options?: any): AxiosPromise<object> {
            return localVarFp.putUserInterview(settingsId, putUserInterview, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CustomerApi - object-oriented interface
 * @export
 * @class CustomerApi
 * @extends {BaseAPI}
 */
export class CustomerApi extends BaseAPI {
    /**
     * 
     * @param {Customer} [customer] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public addOrUpdateCustomer(customer?: Customer, options?: AxiosRequestConfig) {
        return CustomerApiFp(this.configuration).addOrUpdateCustomer(customer, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public authServiceCallbacks(options?: AxiosRequestConfig) {
        return CustomerApiFp(this.configuration).authServiceCallbacks(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} settingsId 
     * @param {number} productId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public deleteExcludedProduct(settingsId: string, productId: number, options?: AxiosRequestConfig) {
        return CustomerApiFp(this.configuration).deleteExcludedProduct(settingsId, productId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} mealId 
     * @param {MealTypeEnum} mealType   0 &#x3D; Breakfast  1 &#x3D; Brunch  2 &#x3D; Elevenses  3 &#x3D; Lunch  4 &#x3D; Tea  5 &#x3D; Supper  6 &#x3D; Dinner  7 &#x3D; Beverages
     * @param {number} dayId 
     * @param {number} customerId 
     * @param {number} subscriptionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public deleteMealInDayPlan(mealId: number, mealType: MealTypeEnum, dayId: number, customerId: number, subscriptionId: number, options?: AxiosRequestConfig) {
        return CustomerApiFp(this.configuration).deleteMealInDayPlan(mealId, mealType, dayId, customerId, subscriptionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} settingsId 
     * @param {number} tagId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public deleteRemoveTagsFromMealForCustomer(settingsId: string, tagId: number, options?: AxiosRequestConfig) {
        return CustomerApiFp(this.configuration).deleteRemoveTagsFromMealForCustomer(settingsId, tagId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} customerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public getCustomer(customerId: number, options?: AxiosRequestConfig) {
        return CustomerApiFp(this.configuration).getCustomer(customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} mail 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public getCustomerId(mail: string, options?: AxiosRequestConfig) {
        return CustomerApiFp(this.configuration).getCustomerId(mail, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} settingsId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public getCustomerSettingsAndPreferences(settingsId: string, options?: AxiosRequestConfig) {
        return CustomerApiFp(this.configuration).getCustomerSettingsAndPreferences(settingsId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} customerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public getCustomerSubs(customerId: number, options?: AxiosRequestConfig) {
        return CustomerApiFp(this.configuration).getCustomerSubs(customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public getLoggedInUserData(options?: AxiosRequestConfig) {
        return CustomerApiFp(this.configuration).getLoggedInUserData(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public getRegisteredCustomers(options?: AxiosRequestConfig) {
        return CustomerApiFp(this.configuration).getRegisteredCustomers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} settingsId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public getTagsMealsForCustomer(settingsId: string, options?: AxiosRequestConfig) {
        return CustomerApiFp(this.configuration).getTagsMealsForCustomer(settingsId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} settingsId 
     * @param {number} customerId 
     * @param {PostCorelateUserWithCustomer} [postCorelateUserWithCustomer] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public postCorelateUserWithCustomer(settingsId: string, customerId: number, postCorelateUserWithCustomer?: PostCorelateUserWithCustomer, options?: AxiosRequestConfig) {
        return CustomerApiFp(this.configuration).postCorelateUserWithCustomer(settingsId, customerId, postCorelateUserWithCustomer, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PostCreateCustomerSettings} [postCreateCustomerSettings] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public postCreateCustomerSettings(postCreateCustomerSettings?: PostCreateCustomerSettings, options?: AxiosRequestConfig) {
        return CustomerApiFp(this.configuration).postCreateCustomerSettings(postCreateCustomerSettings, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} settingsId 
     * @param {PostCreateCustomerTrainingSettings} [postCreateCustomerTrainingSettings] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public postCreateCustomerTrainingSettings(settingsId: string, postCreateCustomerTrainingSettings?: PostCreateCustomerTrainingSettings, options?: AxiosRequestConfig) {
        return CustomerApiFp(this.configuration).postCreateCustomerTrainingSettings(settingsId, postCreateCustomerTrainingSettings, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} settingsId 
     * @param {PostCreateDietPlan} [postCreateDietPlan] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public postCreateDietPlan(settingsId: string, postCreateDietPlan?: PostCreateDietPlan, options?: AxiosRequestConfig) {
        return CustomerApiFp(this.configuration).postCreateDietPlan(settingsId, postCreateDietPlan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PostCustomerRequest} [postCustomerRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public postCustomerRequest(postCustomerRequest?: PostCustomerRequest, options?: AxiosRequestConfig) {
        return CustomerApiFp(this.configuration).postCustomerRequest(postCustomerRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} customerId 
     * @param {number} subscriptionId 
     * @param {number} dayId 
     * @param {PostMealInCustomerDayPlan} [postMealInCustomerDayPlan] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public postMealInCustomerDayPlan(customerId: number, subscriptionId: number, dayId: number, postMealInCustomerDayPlan?: PostMealInCustomerDayPlan, options?: AxiosRequestConfig) {
        return CustomerApiFp(this.configuration).postMealInCustomerDayPlan(customerId, subscriptionId, dayId, postMealInCustomerDayPlan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} settingsId 
     * @param {PostTagsMealsForDietServings} [postTagsMealsForDietServings] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public postTagsMealsForDietServings(settingsId: string, postTagsMealsForDietServings?: PostTagsMealsForDietServings, options?: AxiosRequestConfig) {
        return CustomerApiFp(this.configuration).postTagsMealsForDietServings(settingsId, postTagsMealsForDietServings, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} settingsId 
     * @param {PutDietObjective} [putDietObjective] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public putDietObjective(settingsId: string, putDietObjective?: PutDietObjective, options?: AxiosRequestConfig) {
        return CustomerApiFp(this.configuration).putDietObjective(settingsId, putDietObjective, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} settingsId 
     * @param {PutDietStartDayUtc} [putDietStartDayUtc] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public putDietStartDayUtc(settingsId: string, putDietStartDayUtc?: PutDietStartDayUtc, options?: AxiosRequestConfig) {
        return CustomerApiFp(this.configuration).putDietStartDayUtc(settingsId, putDietStartDayUtc, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} settingsId 
     * @param {PutExcludedProduct} [putExcludedProduct] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public putExcludedProduct(settingsId: string, putExcludedProduct?: PutExcludedProduct, options?: AxiosRequestConfig) {
        return CustomerApiFp(this.configuration).putExcludedProduct(settingsId, putExcludedProduct, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} settingsId 
     * @param {PutExclusionsForDietPlan} [putExclusionsForDietPlan] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public putExclusionsForDietPlan(settingsId: string, putExclusionsForDietPlan?: PutExclusionsForDietPlan, options?: AxiosRequestConfig) {
        return CustomerApiFp(this.configuration).putExclusionsForDietPlan(settingsId, putExclusionsForDietPlan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} settingsId 
     * @param {PutInfoAboutYourself} [putInfoAboutYourself] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public putInfoAboutYourself(settingsId: string, putInfoAboutYourself?: PutInfoAboutYourself, options?: AxiosRequestConfig) {
        return CustomerApiFp(this.configuration).putInfoAboutYourself(settingsId, putInfoAboutYourself, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} settingsId 
     * @param {PutSelectDietType} [putSelectDietType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public putSelectDietType(settingsId: string, putSelectDietType?: PutSelectDietType, options?: AxiosRequestConfig) {
        return CustomerApiFp(this.configuration).putSelectDietType(settingsId, putSelectDietType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} settingsId 
     * @param {PutServingPreferences} [putServingPreferences] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public putServingPreferences(settingsId: string, putServingPreferences?: PutServingPreferences, options?: AxiosRequestConfig) {
        return CustomerApiFp(this.configuration).putServingPreferences(settingsId, putServingPreferences, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} settingsId 
     * @param {PutUserInterview} [putUserInterview] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public putUserInterview(settingsId: string, putUserInterview?: PutUserInterview, options?: AxiosRequestConfig) {
        return CustomerApiFp(this.configuration).putUserInterview(settingsId, putUserInterview, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DashboardApi - axios parameter creator
 * @export
 */
export const DashboardApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDashboardSummary: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Dashboard/Summary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DashboardApi - functional programming interface
 * @export
 */
export const DashboardApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DashboardApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDashboardSummary(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDashboardSummaryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDashboardSummary(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DashboardApi - factory interface
 * @export
 */
export const DashboardApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DashboardApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDashboardSummary(options?: any): AxiosPromise<GetDashboardSummaryResponse> {
            return localVarFp.getDashboardSummary(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DashboardApi - object-oriented interface
 * @export
 * @class DashboardApi
 * @extends {BaseAPI}
 */
export class DashboardApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public getDashboardSummary(options?: AxiosRequestConfig) {
        return DashboardApiFp(this.configuration).getDashboardSummary(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DietApi - axios parameter creator
 * @export
 */
export const DietApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AddOrUpdateDietPlan} [addOrUpdateDietPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOrUpdateDietPlan: async (addOrUpdateDietPlan?: AddOrUpdateDietPlan, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Diet/Customer/DietPlan`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addOrUpdateDietPlan, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AddOrUpdateDietPlanDay} [addOrUpdateDietPlanDay] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOrUpdateDietPlanDay: async (addOrUpdateDietPlanDay?: AddOrUpdateDietPlanDay, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Diet/DietPlanDay`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addOrUpdateDietPlanDay, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDietBallanceDefinition: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteDietBallanceDefinition', 'id', id)
            const localVarPath = `/api/Diet/BallanceDefinition/{Id}`
                .replace(`{${"Id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDietDefinition: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteDietDefinition', 'id', id)
            const localVarPath = `/api/Diet/Type/{Id}`
                .replace(`{${"Id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} tagId 
         * @param {number} dietDefinitionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDietDefintionTags: async (tagId: number, dietDefinitionId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tagId' is not null or undefined
            assertParamExists('deleteDietDefintionTags', 'tagId', tagId)
            // verify required parameter 'dietDefinitionId' is not null or undefined
            assertParamExists('deleteDietDefintionTags', 'dietDefinitionId', dietDefinitionId)
            const localVarPath = `/api/Diet/Tag/{TagId}/DietDefinitons/{DietDefinitionId}`
                .replace(`{${"TagId"}}`, encodeURIComponent(String(tagId)))
                .replace(`{${"DietDefinitionId"}}`, encodeURIComponent(String(dietDefinitionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} subscriptionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDietPlanDays: async (subscriptionId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'subscriptionId' is not null or undefined
            assertParamExists('deleteDietPlanDays', 'subscriptionId', subscriptionId)
            const localVarPath = `/api/Diet/PlanDays/{SubscriptionId}`
                .replace(`{${"SubscriptionId"}}`, encodeURIComponent(String(subscriptionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} mealId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMeal: async (mealId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mealId' is not null or undefined
            assertParamExists('deleteMeal', 'mealId', mealId)
            const localVarPath = `/api/Diet/Meal/{MealId}`
                .replace(`{${"MealId"}}`, encodeURIComponent(String(mealId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} mealId 
         * @param {number} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMealFoodProductFromMeal: async (mealId: number, productId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mealId' is not null or undefined
            assertParamExists('deleteMealFoodProductFromMeal', 'mealId', mealId)
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('deleteMealFoodProductFromMeal', 'productId', productId)
            const localVarPath = `/api/Diet/Meal/{MealId}/FoodProduct/{ProductId}`
                .replace(`{${"MealId"}}`, encodeURIComponent(String(mealId)))
                .replace(`{${"ProductId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} dietPlanDayMealId 
         * @param {number} mealId 
         * @param {number} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMealServig: async (dietPlanDayMealId: number, mealId: number, productId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dietPlanDayMealId' is not null or undefined
            assertParamExists('deleteMealServig', 'dietPlanDayMealId', dietPlanDayMealId)
            // verify required parameter 'mealId' is not null or undefined
            assertParamExists('deleteMealServig', 'mealId', mealId)
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('deleteMealServig', 'productId', productId)
            const localVarPath = `/api/Diet/MealServing/{DietPlanDayMealId}/Meal/{MealId}/Product/{ProductId}`
                .replace(`{${"DietPlanDayMealId"}}`, encodeURIComponent(String(dietPlanDayMealId)))
                .replace(`{${"MealId"}}`, encodeURIComponent(String(mealId)))
                .replace(`{${"ProductId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} tagId 
         * @param {number} productTagId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProductTag: async (tagId: number, productTagId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tagId' is not null or undefined
            assertParamExists('deleteProductTag', 'tagId', tagId)
            // verify required parameter 'productTagId' is not null or undefined
            assertParamExists('deleteProductTag', 'productTagId', productTagId)
            const localVarPath = `/api/Diet/Tag/{TagId}/Products/{ProductTagId}`
                .replace(`{${"TagId"}}`, encodeURIComponent(String(tagId)))
                .replace(`{${"ProductTagId"}}`, encodeURIComponent(String(productTagId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} mealId 
         * @param {number} slaveMealId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUnlinkFromMasterMeal: async (mealId: number, slaveMealId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mealId' is not null or undefined
            assertParamExists('deleteUnlinkFromMasterMeal', 'mealId', mealId)
            // verify required parameter 'slaveMealId' is not null or undefined
            assertParamExists('deleteUnlinkFromMasterMeal', 'slaveMealId', slaveMealId)
            const localVarPath = `/api/Diet/Meal/{MealId}/UnlinkSlave/{SlaveMealId}`
                .replace(`{${"MealId"}}`, encodeURIComponent(String(mealId)))
                .replace(`{${"SlaveMealId"}}`, encodeURIComponent(String(slaveMealId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableDietPlans: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Diet/Plans`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableDiets: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Diet/Diets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} dietDefinitionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBeverages: async (dietDefinitionId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dietDefinitionId' is not null or undefined
            assertParamExists('getBeverages', 'dietDefinitionId', dietDefinitionId)
            const localVarPath = `/api/Diet/Beverages/{DietDefinitionId}`
                .replace(`{${"DietDefinitionId"}}`, encodeURIComponent(String(dietDefinitionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} customerId 
         * @param {number} subscriptionId 
         * @param {number} dayId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDayPlan: async (customerId: number, subscriptionId: number, dayId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('getDayPlan', 'customerId', customerId)
            // verify required parameter 'subscriptionId' is not null or undefined
            assertParamExists('getDayPlan', 'subscriptionId', subscriptionId)
            // verify required parameter 'dayId' is not null or undefined
            assertParamExists('getDayPlan', 'dayId', dayId)
            const localVarPath = `/api/Diet/Customer/{CustomerId}/Subscription/{SubscriptionId}/Day/{DayId}`
                .replace(`{${"CustomerId"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"SubscriptionId"}}`, encodeURIComponent(String(subscriptionId)))
                .replace(`{${"DayId"}}`, encodeURIComponent(String(dayId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDietBallanceDefinitions: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Diet/BallanceDefinition`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} dietPlanId 
         * @param {GetDietBallanceItems} [getDietBallanceItems] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDietBallanceItems: async (dietPlanId: number, getDietBallanceItems?: GetDietBallanceItems, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dietPlanId' is not null or undefined
            assertParamExists('getDietBallanceItems', 'dietPlanId', dietPlanId)
            const localVarPath = `/api/Diet/Plans/{DietPlanId}/Ballance`
                .replace(`{${"DietPlanId"}}`, encodeURIComponent(String(dietPlanId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getDietBallanceItems, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} customerId 
         * @param {number} dietPlanId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDietCalculationForCustomer: async (customerId: number, dietPlanId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('getDietCalculationForCustomer', 'customerId', customerId)
            // verify required parameter 'dietPlanId' is not null or undefined
            assertParamExists('getDietCalculationForCustomer', 'dietPlanId', dietPlanId)
            const localVarPath = `/api/Diet/Calculations/Customer/{CustomerId}/DietPlan/{DietPlanId}`
                .replace(`{${"CustomerId"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"DietPlanId"}}`, encodeURIComponent(String(dietPlanId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDietDefinitions: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Diet/Type`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} tagId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDietDefintionTags: async (tagId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tagId' is not null or undefined
            assertParamExists('getDietDefintionTags', 'tagId', tagId)
            const localVarPath = `/api/Diet/Tag/{TagId}/DietDefinitons`
                .replace(`{${"TagId"}}`, encodeURIComponent(String(tagId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} customerId 
         * @param {number} planId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDietPlan: async (customerId: number, planId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('getDietPlan', 'customerId', customerId)
            // verify required parameter 'planId' is not null or undefined
            assertParamExists('getDietPlan', 'planId', planId)
            const localVarPath = `/api/Diet/Customer/{CustomerId}/DietPlan/{PlanId}`
                .replace(`{${"CustomerId"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"PlanId"}}`, encodeURIComponent(String(planId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} customerId 
         * @param {number} dietPlanId 
         * @param {number} dietDay 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDietPlanDay: async (customerId: number, dietPlanId: number, dietDay: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('getDietPlanDay', 'customerId', customerId)
            // verify required parameter 'dietPlanId' is not null or undefined
            assertParamExists('getDietPlanDay', 'dietPlanId', dietPlanId)
            // verify required parameter 'dietDay' is not null or undefined
            assertParamExists('getDietPlanDay', 'dietDay', dietDay)
            const localVarPath = `/api/Diet/{CustomerId}/Plans/{DietPlanId}/Day/{DietDay}`
                .replace(`{${"CustomerId"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"DietPlanId"}}`, encodeURIComponent(String(dietPlanId)))
                .replace(`{${"DietDay"}}`, encodeURIComponent(String(dietDay)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} dietPlanDayMealId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDietPlanDayMealServings: async (dietPlanDayMealId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dietPlanDayMealId' is not null or undefined
            assertParamExists('getDietPlanDayMealServings', 'dietPlanDayMealId', dietPlanDayMealId)
            const localVarPath = `/api/Diet/MealServing/{DietPlanDayMealId}`
                .replace(`{${"DietPlanDayMealId"}}`, encodeURIComponent(String(dietPlanDayMealId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} dietSettings 
         * @param {GetMatches} [getMatches] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMatches: async (dietSettings: string, getMatches?: GetMatches, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dietSettings' is not null or undefined
            assertParamExists('getMatches', 'dietSettings', dietSettings)
            const localVarPath = `/api/Diet/Match/{DietSettings}`
                .replace(`{${"DietSettings"}}`, encodeURIComponent(String(dietSettings)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getMatches, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} dietSettings 
         * @param {GetMatches2} [getMatches2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMatches2: async (dietSettings: string, getMatches2?: GetMatches2, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dietSettings' is not null or undefined
            assertParamExists('getMatches2', 'dietSettings', dietSettings)
            const localVarPath = `/api/Diet/Match2/{DietSettings}`
                .replace(`{${"DietSettings"}}`, encodeURIComponent(String(dietSettings)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getMatches2, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} mealId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMealById: async (mealId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mealId' is not null or undefined
            assertParamExists('getMealById', 'mealId', mealId)
            const localVarPath = `/api/Diet/Meal/{MealId}`
                .replace(`{${"MealId"}}`, encodeURIComponent(String(mealId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} dietTypeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMealDefinitions: async (dietTypeId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dietTypeId' is not null or undefined
            assertParamExists('getMealDefinitions', 'dietTypeId', dietTypeId)
            const localVarPath = `/api/Diet/Meals/{DietTypeId}`
                .replace(`{${"DietTypeId"}}`, encodeURIComponent(String(dietTypeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [count] 
         * @param {boolean} [isPagingSpecified] 
         * @param {string} [sortField] 
         * @param {SortDirection} [sortDir]   0 &#x3D; Undefined  1 &#x3D; Asc  2 &#x3D; Desc
         * @param {boolean} [isSortingSpecified] 
         * @param {string} [filterDietType] 
         * @param {number} [filterTagId] 
         * @param {MealStatus} [filterStatus]   0 &#x3D; None  1 &#x3D; New  2 &#x3D; ToVerify  4 &#x3D; Verified  8 &#x3D; Rejected  64 &#x3D; Public
         * @param {MealTypeEnum} [filterMealType]   0 &#x3D; Breakfast  1 &#x3D; Brunch  2 &#x3D; Elevenses  3 &#x3D; Lunch  4 &#x3D; Tea  5 &#x3D; Supper  6 &#x3D; Dinner  7 &#x3D; Beverages
         * @param {boolean} [filterMasterMeal] 
         * @param {boolean} [filterHasImage] 
         * @param {boolean} [filterSweet] 
         * @param {boolean} [filterDry] 
         * @param {boolean} [filterIsCustom] 
         * @param {Classification} [filterClassification]   0 &#x3D; None (None)  1 &#x3D; Ballanced (Ballanced)  2 &#x3D; Carbohydrates (Carbohydrates)  4 &#x3D; Energy (Energy)  8 &#x3D; Fat (Fat)  16 &#x3D; Protein (Protein)
         * @param {boolean} [filterIsDeleted] 
         * @param {boolean} [filterIsForRestaurant] 
         * @param {string} [filterRestaurantName] 
         * @param {number} [filterRestaurantId] 
         * @param {number} [filterMealId] 
         * @param {number} [filterCustomerId] 
         * @param {Array<number>} [filterProductIds] 
         * @param {string} [filterName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMealSearch: async (page?: number, count?: number, isPagingSpecified?: boolean, sortField?: string, sortDir?: SortDirection, isSortingSpecified?: boolean, filterDietType?: string, filterTagId?: number, filterStatus?: MealStatus, filterMealType?: MealTypeEnum, filterMasterMeal?: boolean, filterHasImage?: boolean, filterSweet?: boolean, filterDry?: boolean, filterIsCustom?: boolean, filterClassification?: Classification, filterIsDeleted?: boolean, filterIsForRestaurant?: boolean, filterRestaurantName?: string, filterRestaurantId?: number, filterMealId?: number, filterCustomerId?: number, filterProductIds?: Array<number>, filterName?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Diet/Meals/Search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['Page'] = page;
            }

            if (count !== undefined) {
                localVarQueryParameter['Count'] = count;
            }

            if (isPagingSpecified !== undefined) {
                localVarQueryParameter['IsPagingSpecified'] = isPagingSpecified;
            }

            if (sortField !== undefined) {
                localVarQueryParameter['SortField'] = sortField;
            }

            if (sortDir !== undefined) {
                localVarQueryParameter['SortDir'] = sortDir;
            }

            if (isSortingSpecified !== undefined) {
                localVarQueryParameter['IsSortingSpecified'] = isSortingSpecified;
            }

            if (filterDietType !== undefined) {
                localVarQueryParameter['Filter.DietType'] = filterDietType;
            }

            if (filterTagId !== undefined) {
                localVarQueryParameter['Filter.TagId'] = filterTagId;
            }

            if (filterStatus !== undefined) {
                localVarQueryParameter['Filter.Status'] = filterStatus;
            }

            if (filterMealType !== undefined) {
                localVarQueryParameter['Filter.MealType'] = filterMealType;
            }

            if (filterMasterMeal !== undefined) {
                localVarQueryParameter['Filter.MasterMeal'] = filterMasterMeal;
            }

            if (filterHasImage !== undefined) {
                localVarQueryParameter['Filter.HasImage'] = filterHasImage;
            }

            if (filterSweet !== undefined) {
                localVarQueryParameter['Filter.Sweet'] = filterSweet;
            }

            if (filterDry !== undefined) {
                localVarQueryParameter['Filter.Dry'] = filterDry;
            }

            if (filterIsCustom !== undefined) {
                localVarQueryParameter['Filter.IsCustom'] = filterIsCustom;
            }

            if (filterClassification !== undefined) {
                localVarQueryParameter['Filter.Classification'] = filterClassification;
            }

            if (filterIsDeleted !== undefined) {
                localVarQueryParameter['Filter.IsDeleted'] = filterIsDeleted;
            }

            if (filterIsForRestaurant !== undefined) {
                localVarQueryParameter['Filter.IsForRestaurant'] = filterIsForRestaurant;
            }

            if (filterRestaurantName !== undefined) {
                localVarQueryParameter['Filter.RestaurantName'] = filterRestaurantName;
            }

            if (filterRestaurantId !== undefined) {
                localVarQueryParameter['Filter.RestaurantId'] = filterRestaurantId;
            }

            if (filterMealId !== undefined) {
                localVarQueryParameter['Filter.MealId'] = filterMealId;
            }

            if (filterCustomerId !== undefined) {
                localVarQueryParameter['Filter.CustomerId'] = filterCustomerId;
            }

            if (filterProductIds) {
                localVarQueryParameter['Filter.ProductIds'] = filterProductIds;
            }

            if (filterName !== undefined) {
                localVarQueryParameter['Filter.Name'] = filterName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GetMealServingsExchangeProposals} [getMealServingsExchangeProposals] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMealServingsExchangeProposals: async (getMealServingsExchangeProposals?: GetMealServingsExchangeProposals, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Diet/Product/Serving/Proposals`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getMealServingsExchangeProposals, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} numberOfServings 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMealTypesByNumberOfServings: async (numberOfServings: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'numberOfServings' is not null or undefined
            assertParamExists('getMealTypesByNumberOfServings', 'numberOfServings', numberOfServings)
            const localVarPath = `/api/Diet/Servings/{NumberOfServings}`
                .replace(`{${"NumberOfServings"}}`, encodeURIComponent(String(numberOfServings)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<number>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeals: async (requestBody?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Diet/GetMeals`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} dietPlanId 
         * @param {number} dietDay 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMealsExchangeProposals: async (dietPlanId: number, dietDay: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dietPlanId' is not null or undefined
            assertParamExists('getMealsExchangeProposals', 'dietPlanId', dietPlanId)
            // verify required parameter 'dietDay' is not null or undefined
            assertParamExists('getMealsExchangeProposals', 'dietDay', dietDay)
            const localVarPath = `/api/Diet/Plans/{DietPlanId}/Day/{DietDay}/Serving/Proposals`
                .replace(`{${"DietPlanId"}}`, encodeURIComponent(String(dietPlanId)))
                .replace(`{${"DietDay"}}`, encodeURIComponent(String(dietDay)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GetMealsForWeek} [getMealsForWeek] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMealsForWeek: async (getMealsForWeek?: GetMealsForWeek, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Diet/Diet/GetMealsForWeek`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getMealsForWeek, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} productId 
         * @param {number} newQtyInGrams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductNutrionSummary: async (productId: number, newQtyInGrams: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('getProductNutrionSummary', 'productId', productId)
            // verify required parameter 'newQtyInGrams' is not null or undefined
            assertParamExists('getProductNutrionSummary', 'newQtyInGrams', newQtyInGrams)
            const localVarPath = `/api/Diet/Porduct/{ProductId}/NutritionSummary/{NewQtyInGrams}`
                .replace(`{${"ProductId"}}`, encodeURIComponent(String(productId)))
                .replace(`{${"NewQtyInGrams"}}`, encodeURIComponent(String(newQtyInGrams)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} tagId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductTags: async (tagId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tagId' is not null or undefined
            assertParamExists('getProductTags', 'tagId', tagId)
            const localVarPath = `/api/Diet/Tag/{TagId}/Products`
                .replace(`{${"TagId"}}`, encodeURIComponent(String(tagId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShoppingItemForDietPlan: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Diet/Diet/GetShoppingItemForDietPlan`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tag 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTagMeals: async (tag: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tag' is not null or undefined
            assertParamExists('getTagMeals', 'tag', tag)
            const localVarPath = `/api/Diet/Tag/{Tag}`
                .replace(`{${"Tag"}}`, encodeURIComponent(String(tag)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} dietTypeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTagMealsForDietServings: async (dietTypeId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dietTypeId' is not null or undefined
            assertParamExists('getTagMealsForDietServings', 'dietTypeId', dietTypeId)
            const localVarPath = `/api/Diet/DietServingsTags/{DietTypeId}`
                .replace(`{${"DietTypeId"}}`, encodeURIComponent(String(dietTypeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTags: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Diet/Tag`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} customerId 
         * @param {number} subscriptionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWeekPlan: async (customerId: number, subscriptionId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('getWeekPlan', 'customerId', customerId)
            // verify required parameter 'subscriptionId' is not null or undefined
            assertParamExists('getWeekPlan', 'subscriptionId', subscriptionId)
            const localVarPath = `/api/Diet/Customer/{CustomerId}/Subscription/{SubscriptionId}`
                .replace(`{${"CustomerId"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"SubscriptionId"}}`, encodeURIComponent(String(subscriptionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DietBallanceDefinition} [dietBallanceDefinition] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAddOrUpdateDietBallanceDefinition: async (dietBallanceDefinition?: DietBallanceDefinition, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Diet/BallanceDefinition`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dietBallanceDefinition, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} customerId 
         * @param {PostAddOrUpdateDietPlanDayMeals} [postAddOrUpdateDietPlanDayMeals] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAddOrUpdateDietPlanDayMeals: async (customerId: number, postAddOrUpdateDietPlanDayMeals?: PostAddOrUpdateDietPlanDayMeals, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('postAddOrUpdateDietPlanDayMeals', 'customerId', customerId)
            const localVarPath = `/api/Diet/Diet/Meal/Customer/{CustomerId}`
                .replace(`{${"CustomerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postAddOrUpdateDietPlanDayMeals, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} dietPlanDayMealId 
         * @param {number} mealId 
         * @param {number} productId 
         * @param {PostAddOrUpdateMealServig} [postAddOrUpdateMealServig] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAddOrUpdateMealServig: async (dietPlanDayMealId: number, mealId: number, productId: number, postAddOrUpdateMealServig?: PostAddOrUpdateMealServig, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dietPlanDayMealId' is not null or undefined
            assertParamExists('postAddOrUpdateMealServig', 'dietPlanDayMealId', dietPlanDayMealId)
            // verify required parameter 'mealId' is not null or undefined
            assertParamExists('postAddOrUpdateMealServig', 'mealId', mealId)
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('postAddOrUpdateMealServig', 'productId', productId)
            const localVarPath = `/api/Diet/MealServing/{DietPlanDayMealId}/Meal/{MealId}/Product/{ProductId}`
                .replace(`{${"DietPlanDayMealId"}}`, encodeURIComponent(String(dietPlanDayMealId)))
                .replace(`{${"MealId"}}`, encodeURIComponent(String(mealId)))
                .replace(`{${"ProductId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postAddOrUpdateMealServig, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} mealId 
         * @param {PostConvertToMasterMeal} [postConvertToMasterMeal] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postConvertToMasterMeal: async (mealId: number, postConvertToMasterMeal?: PostConvertToMasterMeal, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mealId' is not null or undefined
            assertParamExists('postConvertToMasterMeal', 'mealId', mealId)
            const localVarPath = `/api/Diet/Meal/{MealId}/ConvertToMaster`
                .replace(`{${"MealId"}}`, encodeURIComponent(String(mealId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postConvertToMasterMeal, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PostCopyDietPlanDayMeals} [postCopyDietPlanDayMeals] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCopyDietPlanDayMeals: async (postCopyDietPlanDayMeals?: PostCopyDietPlanDayMeals, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Diet/Diet/Meal/Copy`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postCopyDietPlanDayMeals, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PostCopyMealDefinition} [postCopyMealDefinition] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCopyMealDefinition: async (postCopyMealDefinition?: PostCopyMealDefinition, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Diet/Diet/Meal/Definition/Copy`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postCopyMealDefinition, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MealTypeEnum} mealType   0 &#x3D; Breakfast  1 &#x3D; Brunch  2 &#x3D; Elevenses  3 &#x3D; Lunch  4 &#x3D; Tea  5 &#x3D; Supper  6 &#x3D; Dinner  7 &#x3D; Beverages
         * @param {number} customerId 
         * @param {PostCustomerMealFoodProducts} [postCustomerMealFoodProducts] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCustomerMealFoodProducts: async (mealType: MealTypeEnum, customerId: number, postCustomerMealFoodProducts?: PostCustomerMealFoodProducts, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mealType' is not null or undefined
            assertParamExists('postCustomerMealFoodProducts', 'mealType', mealType)
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('postCustomerMealFoodProducts', 'customerId', customerId)
            const localVarPath = `/api/Diet/Diet/Meal/{MealType}/CustomServings/{CustomerId}`
                .replace(`{${"MealType"}}`, encodeURIComponent(String(mealType)))
                .replace(`{${"CustomerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postCustomerMealFoodProducts, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PostDietDefinition} [postDietDefinition] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDietDefinition: async (postDietDefinition?: PostDietDefinition, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Diet/Type`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postDietDefinition, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} tagId 
         * @param {PostDietDefintionTags} [postDietDefintionTags] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDietDefintionTags: async (tagId: number, postDietDefintionTags?: PostDietDefintionTags, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tagId' is not null or undefined
            assertParamExists('postDietDefintionTags', 'tagId', tagId)
            const localVarPath = `/api/Diet/Tag/{TagId}/DietDefinitons`
                .replace(`{${"TagId"}}`, encodeURIComponent(String(tagId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postDietDefintionTags, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [mealId] 
         * @param {number} [carbs] 
         * @param {number} [proteins] 
         * @param {number} [fats] 
         * @param {number} [energy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDietRecalc: async (mealId?: number, carbs?: number, proteins?: number, fats?: number, energy?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Diet/Meals/DietRecalc`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (mealId !== undefined) {
                localVarQueryParameter['mealId'] = mealId;
            }

            if (carbs !== undefined) {
                localVarQueryParameter['carbs'] = carbs;
            }

            if (proteins !== undefined) {
                localVarQueryParameter['proteins'] = proteins;
            }

            if (fats !== undefined) {
                localVarQueryParameter['fats'] = fats;
            }

            if (energy !== undefined) {
                localVarQueryParameter['energy'] = energy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [mealId] 
         * @param {number} [carbs] 
         * @param {number} [proteins] 
         * @param {number} [fats] 
         * @param {number} [energy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDietRecalcCorrected: async (mealId?: number, carbs?: number, proteins?: number, fats?: number, energy?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Diet/Meals/DietRecalcCorrected`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (mealId !== undefined) {
                localVarQueryParameter['mealId'] = mealId;
            }

            if (carbs !== undefined) {
                localVarQueryParameter['carbs'] = carbs;
            }

            if (proteins !== undefined) {
                localVarQueryParameter['proteins'] = proteins;
            }

            if (fats !== undefined) {
                localVarQueryParameter['fats'] = fats;
            }

            if (energy !== undefined) {
                localVarQueryParameter['energy'] = energy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PostFavouriteMealDefinition} [postFavouriteMealDefinition] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postFavouriteMealDefinition: async (postFavouriteMealDefinition?: PostFavouriteMealDefinition, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Diet/Diet/Meal/Favourite/Set`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postFavouriteMealDefinition, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PostMealComment} [postMealComment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postMealComment: async (postMealComment?: PostMealComment, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Diet/Diet/DietPlanDayMeals/Comment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postMealComment, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PostMealDefinition} [postMealDefinition] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postMealDefinition: async (postMealDefinition?: PostMealDefinition, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Diet/Meal`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postMealDefinition, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PostProcessEatenMeal} [postProcessEatenMeal] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postProcessEatenMeal: async (postProcessEatenMeal?: PostProcessEatenMeal, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Diet/Diet/DietPlanDayMeal/ProcessMealEaten`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postProcessEatenMeal, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} tagId 
         * @param {PostProductTag} [postProductTag] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postProductTag: async (tagId: number, postProductTag?: PostProductTag, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tagId' is not null or undefined
            assertParamExists('postProductTag', 'tagId', tagId)
            const localVarPath = `/api/Diet/Tag/{TagId}/Products`
                .replace(`{${"TagId"}}`, encodeURIComponent(String(tagId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postProductTag, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [mealId] 
         * @param {boolean} [update] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postReCalcMeal: async (mealId?: number, update?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Diet/Meals/Recalc`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (mealId !== undefined) {
                localVarQueryParameter['mealId'] = mealId;
            }

            if (update !== undefined) {
                localVarQueryParameter['update'] = update;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PostShoppingItemForDietPlan} [postShoppingItemForDietPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postShoppingItemForDietPlan: async (postShoppingItemForDietPlan?: PostShoppingItemForDietPlan, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Diet/Diet/PostShoppingItemForDietPlan`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postShoppingItemForDietPlan, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PostShoppingListForDietPlan} [postShoppingListForDietPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postShoppingListForDietPlan: async (postShoppingListForDietPlan?: PostShoppingListForDietPlan, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Diet/Diet/PostShoppingListForDietPlan`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postShoppingListForDietPlan, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PostSwapDietPlanDayMeals} [postSwapDietPlanDayMeals] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postSwapDietPlanDayMeals: async (postSwapDietPlanDayMeals?: PostSwapDietPlanDayMeals, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Diet/Diet/DietPlanDayMeals/Swap`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postSwapDietPlanDayMeals, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PostTag} [postTag] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postTag: async (postTag?: PostTag, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Diet/Tag`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postTag, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {PutDietDefinition} [putDietDefinition] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putDietDefinition: async (id: number, putDietDefinition?: PutDietDefinition, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putDietDefinition', 'id', id)
            const localVarPath = `/api/Diet/Type/{Id}`
                .replace(`{${"Id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(putDietDefinition, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} mealId 
         * @param {PutMeal} [putMeal] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putMeal: async (mealId: number, putMeal?: PutMeal, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mealId' is not null or undefined
            assertParamExists('putMeal', 'mealId', mealId)
            const localVarPath = `/api/Diet/Meal/{MealId}`
                .replace(`{${"MealId"}}`, encodeURIComponent(String(mealId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(putMeal, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} mealId 
         * @param {PutMealServingPrerefences} [putMealServingPrerefences] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putMealServingPrerefences: async (mealId: number, putMealServingPrerefences?: PutMealServingPrerefences, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mealId' is not null or undefined
            assertParamExists('putMealServingPrerefences', 'mealId', mealId)
            const localVarPath = `/api/Diet/Meal/{MealId}/ServingPreferences`
                .replace(`{${"MealId"}}`, encodeURIComponent(String(mealId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(putMealServingPrerefences, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DietApi - functional programming interface
 * @export
 */
export const DietApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DietApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {AddOrUpdateDietPlan} [addOrUpdateDietPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addOrUpdateDietPlan(addOrUpdateDietPlan?: AddOrUpdateDietPlan, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddOrUpdateDietPlanResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addOrUpdateDietPlan(addOrUpdateDietPlan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AddOrUpdateDietPlanDay} [addOrUpdateDietPlanDay] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addOrUpdateDietPlanDay(addOrUpdateDietPlanDay?: AddOrUpdateDietPlanDay, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddOrUpdateDietPlanDayResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addOrUpdateDietPlanDay(addOrUpdateDietPlanDay, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDietBallanceDefinition(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDietBallanceDefinition(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDietDefinition(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteDietDefinitionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDietDefinition(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} tagId 
         * @param {number} dietDefinitionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDietDefintionTags(tagId: number, dietDefinitionId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDietDefintionTags(tagId, dietDefinitionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} subscriptionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDietPlanDays(subscriptionId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDietPlanDays(subscriptionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} mealId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteMeal(mealId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteMeal(mealId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} mealId 
         * @param {number} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteMealFoodProductFromMeal(mealId: number, productId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteMealFoodProductFromMeal(mealId, productId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} dietPlanDayMealId 
         * @param {number} mealId 
         * @param {number} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteMealServig(dietPlanDayMealId: number, mealId: number, productId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteMealServigResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteMealServig(dietPlanDayMealId, mealId, productId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} tagId 
         * @param {number} productTagId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProductTag(tagId: number, productTagId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProductTag(tagId, productTagId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} mealId 
         * @param {number} slaveMealId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUnlinkFromMasterMeal(mealId: number, slaveMealId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUnlinkFromMasterMeal(mealId, slaveMealId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAvailableDietPlans(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAvailableDietPlansResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAvailableDietPlans(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAvailableDiets(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAvailableDietsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAvailableDiets(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} dietDefinitionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBeverages(dietDefinitionId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetBeveragesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBeverages(dietDefinitionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} customerId 
         * @param {number} subscriptionId 
         * @param {number} dayId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDayPlan(customerId: number, subscriptionId: number, dayId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDayPlanMealsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDayPlan(customerId, subscriptionId, dayId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDietBallanceDefinitions(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDietBallanceDefinitionsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDietBallanceDefinitions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} dietPlanId 
         * @param {GetDietBallanceItems} [getDietBallanceItems] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDietBallanceItems(dietPlanId: number, getDietBallanceItems?: GetDietBallanceItems, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDietBallanceItemsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDietBallanceItems(dietPlanId, getDietBallanceItems, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} customerId 
         * @param {number} dietPlanId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDietCalculationForCustomer(customerId: number, dietPlanId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDietCalculationForCustomerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDietCalculationForCustomer(customerId, dietPlanId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDietDefinitions(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDietDefinitionsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDietDefinitions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} tagId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDietDefintionTags(tagId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDietDefintionTagsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDietDefintionTags(tagId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} customerId 
         * @param {number} planId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDietPlan(customerId: number, planId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDietPlanResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDietPlan(customerId, planId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} customerId 
         * @param {number} dietPlanId 
         * @param {number} dietDay 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDietPlanDay(customerId: number, dietPlanId: number, dietDay: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDietPlanDayResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDietPlanDay(customerId, dietPlanId, dietDay, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} dietPlanDayMealId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDietPlanDayMealServings(dietPlanDayMealId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDietPlanDayMealServingsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDietPlanDayMealServings(dietPlanDayMealId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} dietSettings 
         * @param {GetMatches} [getMatches] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMatches(dietSettings: string, getMatches?: GetMatches, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetMatchesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMatches(dietSettings, getMatches, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} dietSettings 
         * @param {GetMatches2} [getMatches2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMatches2(dietSettings: string, getMatches2?: GetMatches2, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetMatches2Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMatches2(dietSettings, getMatches2, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} mealId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMealById(mealId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetMealByIdResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMealById(mealId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} dietTypeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMealDefinitions(dietTypeId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetMealDefinitionsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMealDefinitions(dietTypeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [count] 
         * @param {boolean} [isPagingSpecified] 
         * @param {string} [sortField] 
         * @param {SortDirection} [sortDir]   0 &#x3D; Undefined  1 &#x3D; Asc  2 &#x3D; Desc
         * @param {boolean} [isSortingSpecified] 
         * @param {string} [filterDietType] 
         * @param {number} [filterTagId] 
         * @param {MealStatus} [filterStatus]   0 &#x3D; None  1 &#x3D; New  2 &#x3D; ToVerify  4 &#x3D; Verified  8 &#x3D; Rejected  64 &#x3D; Public
         * @param {MealTypeEnum} [filterMealType]   0 &#x3D; Breakfast  1 &#x3D; Brunch  2 &#x3D; Elevenses  3 &#x3D; Lunch  4 &#x3D; Tea  5 &#x3D; Supper  6 &#x3D; Dinner  7 &#x3D; Beverages
         * @param {boolean} [filterMasterMeal] 
         * @param {boolean} [filterHasImage] 
         * @param {boolean} [filterSweet] 
         * @param {boolean} [filterDry] 
         * @param {boolean} [filterIsCustom] 
         * @param {Classification} [filterClassification]   0 &#x3D; None (None)  1 &#x3D; Ballanced (Ballanced)  2 &#x3D; Carbohydrates (Carbohydrates)  4 &#x3D; Energy (Energy)  8 &#x3D; Fat (Fat)  16 &#x3D; Protein (Protein)
         * @param {boolean} [filterIsDeleted] 
         * @param {boolean} [filterIsForRestaurant] 
         * @param {string} [filterRestaurantName] 
         * @param {number} [filterRestaurantId] 
         * @param {number} [filterMealId] 
         * @param {number} [filterCustomerId] 
         * @param {Array<number>} [filterProductIds] 
         * @param {string} [filterName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMealSearch(page?: number, count?: number, isPagingSpecified?: boolean, sortField?: string, sortDir?: SortDirection, isSortingSpecified?: boolean, filterDietType?: string, filterTagId?: number, filterStatus?: MealStatus, filterMealType?: MealTypeEnum, filterMasterMeal?: boolean, filterHasImage?: boolean, filterSweet?: boolean, filterDry?: boolean, filterIsCustom?: boolean, filterClassification?: Classification, filterIsDeleted?: boolean, filterIsForRestaurant?: boolean, filterRestaurantName?: string, filterRestaurantId?: number, filterMealId?: number, filterCustomerId?: number, filterProductIds?: Array<number>, filterName?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetMealSearchResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMealSearch(page, count, isPagingSpecified, sortField, sortDir, isSortingSpecified, filterDietType, filterTagId, filterStatus, filterMealType, filterMasterMeal, filterHasImage, filterSweet, filterDry, filterIsCustom, filterClassification, filterIsDeleted, filterIsForRestaurant, filterRestaurantName, filterRestaurantId, filterMealId, filterCustomerId, filterProductIds, filterName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {GetMealServingsExchangeProposals} [getMealServingsExchangeProposals] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMealServingsExchangeProposals(getMealServingsExchangeProposals?: GetMealServingsExchangeProposals, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetMealServingsExchangeProposalsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMealServingsExchangeProposals(getMealServingsExchangeProposals, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} numberOfServings 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMealTypesByNumberOfServings(numberOfServings: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetMealTypesByNumberOfServingsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMealTypesByNumberOfServings(numberOfServings, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<number>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMeals(requestBody?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetMealsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMeals(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} dietPlanId 
         * @param {number} dietDay 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMealsExchangeProposals(dietPlanId: number, dietDay: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetMealsExchangeProposalsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMealsExchangeProposals(dietPlanId, dietDay, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {GetMealsForWeek} [getMealsForWeek] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMealsForWeek(getMealsForWeek?: GetMealsForWeek, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetMealsForWeekResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMealsForWeek(getMealsForWeek, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} productId 
         * @param {number} newQtyInGrams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductNutrionSummary(productId: number, newQtyInGrams: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetProductNutrionSummaryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProductNutrionSummary(productId, newQtyInGrams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} tagId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductTags(tagId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetProductTagsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProductTags(tagId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getShoppingItemForDietPlan(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetShoppingListForDietPlanResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getShoppingItemForDietPlan(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tag 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTagMeals(tag: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetTagMealsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTagMeals(tag, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} dietTypeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTagMealsForDietServings(dietTypeId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetTagMealsForDietServingsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTagMealsForDietServings(dietTypeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTags(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetTagsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTags(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} customerId 
         * @param {number} subscriptionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWeekPlan(customerId: number, subscriptionId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetWeekPlanResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWeekPlan(customerId, subscriptionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DietBallanceDefinition} [dietBallanceDefinition] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAddOrUpdateDietBallanceDefinition(dietBallanceDefinition?: DietBallanceDefinition, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostAddOrUpdateDietBallanceDefinitionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAddOrUpdateDietBallanceDefinition(dietBallanceDefinition, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} customerId 
         * @param {PostAddOrUpdateDietPlanDayMeals} [postAddOrUpdateDietPlanDayMeals] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAddOrUpdateDietPlanDayMeals(customerId: number, postAddOrUpdateDietPlanDayMeals?: PostAddOrUpdateDietPlanDayMeals, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAddOrUpdateDietPlanDayMeals(customerId, postAddOrUpdateDietPlanDayMeals, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} dietPlanDayMealId 
         * @param {number} mealId 
         * @param {number} productId 
         * @param {PostAddOrUpdateMealServig} [postAddOrUpdateMealServig] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAddOrUpdateMealServig(dietPlanDayMealId: number, mealId: number, productId: number, postAddOrUpdateMealServig?: PostAddOrUpdateMealServig, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostAddOrUpdateMealServigResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAddOrUpdateMealServig(dietPlanDayMealId, mealId, productId, postAddOrUpdateMealServig, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} mealId 
         * @param {PostConvertToMasterMeal} [postConvertToMasterMeal] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postConvertToMasterMeal(mealId: number, postConvertToMasterMeal?: PostConvertToMasterMeal, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postConvertToMasterMeal(mealId, postConvertToMasterMeal, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PostCopyDietPlanDayMeals} [postCopyDietPlanDayMeals] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postCopyDietPlanDayMeals(postCopyDietPlanDayMeals?: PostCopyDietPlanDayMeals, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postCopyDietPlanDayMeals(postCopyDietPlanDayMeals, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PostCopyMealDefinition} [postCopyMealDefinition] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postCopyMealDefinition(postCopyMealDefinition?: PostCopyMealDefinition, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostCopyMealDefinitionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postCopyMealDefinition(postCopyMealDefinition, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MealTypeEnum} mealType   0 &#x3D; Breakfast  1 &#x3D; Brunch  2 &#x3D; Elevenses  3 &#x3D; Lunch  4 &#x3D; Tea  5 &#x3D; Supper  6 &#x3D; Dinner  7 &#x3D; Beverages
         * @param {number} customerId 
         * @param {PostCustomerMealFoodProducts} [postCustomerMealFoodProducts] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postCustomerMealFoodProducts(mealType: MealTypeEnum, customerId: number, postCustomerMealFoodProducts?: PostCustomerMealFoodProducts, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postCustomerMealFoodProducts(mealType, customerId, postCustomerMealFoodProducts, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PostDietDefinition} [postDietDefinition] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postDietDefinition(postDietDefinition?: PostDietDefinition, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostDietDefinitionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postDietDefinition(postDietDefinition, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} tagId 
         * @param {PostDietDefintionTags} [postDietDefintionTags] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postDietDefintionTags(tagId: number, postDietDefintionTags?: PostDietDefintionTags, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostDietDefintionTagsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postDietDefintionTags(tagId, postDietDefintionTags, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [mealId] 
         * @param {number} [carbs] 
         * @param {number} [proteins] 
         * @param {number} [fats] 
         * @param {number} [energy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postDietRecalc(mealId?: number, carbs?: number, proteins?: number, fats?: number, energy?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostDietRecalcResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postDietRecalc(mealId, carbs, proteins, fats, energy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [mealId] 
         * @param {number} [carbs] 
         * @param {number} [proteins] 
         * @param {number} [fats] 
         * @param {number} [energy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postDietRecalcCorrected(mealId?: number, carbs?: number, proteins?: number, fats?: number, energy?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostDietRecalcCorrectedResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postDietRecalcCorrected(mealId, carbs, proteins, fats, energy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PostFavouriteMealDefinition} [postFavouriteMealDefinition] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postFavouriteMealDefinition(postFavouriteMealDefinition?: PostFavouriteMealDefinition, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postFavouriteMealDefinition(postFavouriteMealDefinition, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PostMealComment} [postMealComment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postMealComment(postMealComment?: PostMealComment, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostMealCommentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postMealComment(postMealComment, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PostMealDefinition} [postMealDefinition] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postMealDefinition(postMealDefinition?: PostMealDefinition, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostMealDefinitionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postMealDefinition(postMealDefinition, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PostProcessEatenMeal} [postProcessEatenMeal] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postProcessEatenMeal(postProcessEatenMeal?: PostProcessEatenMeal, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostProcessEatenMealResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postProcessEatenMeal(postProcessEatenMeal, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} tagId 
         * @param {PostProductTag} [postProductTag] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postProductTag(tagId: number, postProductTag?: PostProductTag, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostProductTagResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postProductTag(tagId, postProductTag, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [mealId] 
         * @param {boolean} [update] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postReCalcMeal(mealId?: number, update?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostReCalcMealResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postReCalcMeal(mealId, update, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PostShoppingItemForDietPlan} [postShoppingItemForDietPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postShoppingItemForDietPlan(postShoppingItemForDietPlan?: PostShoppingItemForDietPlan, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postShoppingItemForDietPlan(postShoppingItemForDietPlan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PostShoppingListForDietPlan} [postShoppingListForDietPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postShoppingListForDietPlan(postShoppingListForDietPlan?: PostShoppingListForDietPlan, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostShoppingListForDietPlanResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postShoppingListForDietPlan(postShoppingListForDietPlan, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PostSwapDietPlanDayMeals} [postSwapDietPlanDayMeals] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postSwapDietPlanDayMeals(postSwapDietPlanDayMeals?: PostSwapDietPlanDayMeals, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostSwapDietPlanDayMealsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postSwapDietPlanDayMeals(postSwapDietPlanDayMeals, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PostTag} [postTag] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postTag(postTag?: PostTag, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostTagResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postTag(postTag, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {PutDietDefinition} [putDietDefinition] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putDietDefinition(id: number, putDietDefinition?: PutDietDefinition, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PutDietDefinitionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putDietDefinition(id, putDietDefinition, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} mealId 
         * @param {PutMeal} [putMeal] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putMeal(mealId: number, putMeal?: PutMeal, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PutMealResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putMeal(mealId, putMeal, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} mealId 
         * @param {PutMealServingPrerefences} [putMealServingPrerefences] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putMealServingPrerefences(mealId: number, putMealServingPrerefences?: PutMealServingPrerefences, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putMealServingPrerefences(mealId, putMealServingPrerefences, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DietApi - factory interface
 * @export
 */
export const DietApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DietApiFp(configuration)
    return {
        /**
         * 
         * @param {AddOrUpdateDietPlan} [addOrUpdateDietPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOrUpdateDietPlan(addOrUpdateDietPlan?: AddOrUpdateDietPlan, options?: any): AxiosPromise<AddOrUpdateDietPlanResponse> {
            return localVarFp.addOrUpdateDietPlan(addOrUpdateDietPlan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AddOrUpdateDietPlanDay} [addOrUpdateDietPlanDay] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOrUpdateDietPlanDay(addOrUpdateDietPlanDay?: AddOrUpdateDietPlanDay, options?: any): AxiosPromise<AddOrUpdateDietPlanDayResponse> {
            return localVarFp.addOrUpdateDietPlanDay(addOrUpdateDietPlanDay, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDietBallanceDefinition(id: number, options?: any): AxiosPromise<object> {
            return localVarFp.deleteDietBallanceDefinition(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDietDefinition(id: number, options?: any): AxiosPromise<DeleteDietDefinitionResponse> {
            return localVarFp.deleteDietDefinition(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} tagId 
         * @param {number} dietDefinitionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDietDefintionTags(tagId: number, dietDefinitionId: number, options?: any): AxiosPromise<object> {
            return localVarFp.deleteDietDefintionTags(tagId, dietDefinitionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} subscriptionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDietPlanDays(subscriptionId: string, options?: any): AxiosPromise<object> {
            return localVarFp.deleteDietPlanDays(subscriptionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} mealId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMeal(mealId: number, options?: any): AxiosPromise<object> {
            return localVarFp.deleteMeal(mealId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} mealId 
         * @param {number} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMealFoodProductFromMeal(mealId: number, productId: number, options?: any): AxiosPromise<object> {
            return localVarFp.deleteMealFoodProductFromMeal(mealId, productId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} dietPlanDayMealId 
         * @param {number} mealId 
         * @param {number} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMealServig(dietPlanDayMealId: number, mealId: number, productId: number, options?: any): AxiosPromise<DeleteMealServigResponse> {
            return localVarFp.deleteMealServig(dietPlanDayMealId, mealId, productId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} tagId 
         * @param {number} productTagId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProductTag(tagId: number, productTagId: number, options?: any): AxiosPromise<object> {
            return localVarFp.deleteProductTag(tagId, productTagId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} mealId 
         * @param {number} slaveMealId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUnlinkFromMasterMeal(mealId: number, slaveMealId: number, options?: any): AxiosPromise<object> {
            return localVarFp.deleteUnlinkFromMasterMeal(mealId, slaveMealId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableDietPlans(options?: any): AxiosPromise<GetAvailableDietPlansResponse> {
            return localVarFp.getAvailableDietPlans(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableDiets(options?: any): AxiosPromise<GetAvailableDietsResponse> {
            return localVarFp.getAvailableDiets(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} dietDefinitionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBeverages(dietDefinitionId: number, options?: any): AxiosPromise<GetBeveragesResponse> {
            return localVarFp.getBeverages(dietDefinitionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} customerId 
         * @param {number} subscriptionId 
         * @param {number} dayId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDayPlan(customerId: number, subscriptionId: number, dayId: number, options?: any): AxiosPromise<GetDayPlanMealsResponse> {
            return localVarFp.getDayPlan(customerId, subscriptionId, dayId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDietBallanceDefinitions(options?: any): AxiosPromise<GetDietBallanceDefinitionsResponse> {
            return localVarFp.getDietBallanceDefinitions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} dietPlanId 
         * @param {GetDietBallanceItems} [getDietBallanceItems] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDietBallanceItems(dietPlanId: number, getDietBallanceItems?: GetDietBallanceItems, options?: any): AxiosPromise<GetDietBallanceItemsResponse> {
            return localVarFp.getDietBallanceItems(dietPlanId, getDietBallanceItems, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} customerId 
         * @param {number} dietPlanId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDietCalculationForCustomer(customerId: number, dietPlanId: number, options?: any): AxiosPromise<GetDietCalculationForCustomerResponse> {
            return localVarFp.getDietCalculationForCustomer(customerId, dietPlanId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDietDefinitions(options?: any): AxiosPromise<GetDietDefinitionsResponse> {
            return localVarFp.getDietDefinitions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} tagId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDietDefintionTags(tagId: number, options?: any): AxiosPromise<GetDietDefintionTagsResponse> {
            return localVarFp.getDietDefintionTags(tagId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} customerId 
         * @param {number} planId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDietPlan(customerId: number, planId: number, options?: any): AxiosPromise<GetDietPlanResponse> {
            return localVarFp.getDietPlan(customerId, planId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} customerId 
         * @param {number} dietPlanId 
         * @param {number} dietDay 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDietPlanDay(customerId: number, dietPlanId: number, dietDay: number, options?: any): AxiosPromise<GetDietPlanDayResponse> {
            return localVarFp.getDietPlanDay(customerId, dietPlanId, dietDay, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} dietPlanDayMealId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDietPlanDayMealServings(dietPlanDayMealId: number, options?: any): AxiosPromise<GetDietPlanDayMealServingsResponse> {
            return localVarFp.getDietPlanDayMealServings(dietPlanDayMealId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} dietSettings 
         * @param {GetMatches} [getMatches] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMatches(dietSettings: string, getMatches?: GetMatches, options?: any): AxiosPromise<GetMatchesResponse> {
            return localVarFp.getMatches(dietSettings, getMatches, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} dietSettings 
         * @param {GetMatches2} [getMatches2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMatches2(dietSettings: string, getMatches2?: GetMatches2, options?: any): AxiosPromise<GetMatches2Response> {
            return localVarFp.getMatches2(dietSettings, getMatches2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} mealId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMealById(mealId: number, options?: any): AxiosPromise<GetMealByIdResponse> {
            return localVarFp.getMealById(mealId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} dietTypeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMealDefinitions(dietTypeId: number, options?: any): AxiosPromise<GetMealDefinitionsResponse> {
            return localVarFp.getMealDefinitions(dietTypeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [count] 
         * @param {boolean} [isPagingSpecified] 
         * @param {string} [sortField] 
         * @param {SortDirection} [sortDir]   0 &#x3D; Undefined  1 &#x3D; Asc  2 &#x3D; Desc
         * @param {boolean} [isSortingSpecified] 
         * @param {string} [filterDietType] 
         * @param {number} [filterTagId] 
         * @param {MealStatus} [filterStatus]   0 &#x3D; None  1 &#x3D; New  2 &#x3D; ToVerify  4 &#x3D; Verified  8 &#x3D; Rejected  64 &#x3D; Public
         * @param {MealTypeEnum} [filterMealType]   0 &#x3D; Breakfast  1 &#x3D; Brunch  2 &#x3D; Elevenses  3 &#x3D; Lunch  4 &#x3D; Tea  5 &#x3D; Supper  6 &#x3D; Dinner  7 &#x3D; Beverages
         * @param {boolean} [filterMasterMeal] 
         * @param {boolean} [filterHasImage] 
         * @param {boolean} [filterSweet] 
         * @param {boolean} [filterDry] 
         * @param {boolean} [filterIsCustom] 
         * @param {Classification} [filterClassification]   0 &#x3D; None (None)  1 &#x3D; Ballanced (Ballanced)  2 &#x3D; Carbohydrates (Carbohydrates)  4 &#x3D; Energy (Energy)  8 &#x3D; Fat (Fat)  16 &#x3D; Protein (Protein)
         * @param {boolean} [filterIsDeleted] 
         * @param {boolean} [filterIsForRestaurant] 
         * @param {string} [filterRestaurantName] 
         * @param {number} [filterRestaurantId] 
         * @param {number} [filterMealId] 
         * @param {number} [filterCustomerId] 
         * @param {Array<number>} [filterProductIds] 
         * @param {string} [filterName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMealSearch(page?: number, count?: number, isPagingSpecified?: boolean, sortField?: string, sortDir?: SortDirection, isSortingSpecified?: boolean, filterDietType?: string, filterTagId?: number, filterStatus?: MealStatus, filterMealType?: MealTypeEnum, filterMasterMeal?: boolean, filterHasImage?: boolean, filterSweet?: boolean, filterDry?: boolean, filterIsCustom?: boolean, filterClassification?: Classification, filterIsDeleted?: boolean, filterIsForRestaurant?: boolean, filterRestaurantName?: string, filterRestaurantId?: number, filterMealId?: number, filterCustomerId?: number, filterProductIds?: Array<number>, filterName?: string, options?: any): AxiosPromise<GetMealSearchResponse> {
            return localVarFp.getMealSearch(page, count, isPagingSpecified, sortField, sortDir, isSortingSpecified, filterDietType, filterTagId, filterStatus, filterMealType, filterMasterMeal, filterHasImage, filterSweet, filterDry, filterIsCustom, filterClassification, filterIsDeleted, filterIsForRestaurant, filterRestaurantName, filterRestaurantId, filterMealId, filterCustomerId, filterProductIds, filterName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GetMealServingsExchangeProposals} [getMealServingsExchangeProposals] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMealServingsExchangeProposals(getMealServingsExchangeProposals?: GetMealServingsExchangeProposals, options?: any): AxiosPromise<GetMealServingsExchangeProposalsResponse> {
            return localVarFp.getMealServingsExchangeProposals(getMealServingsExchangeProposals, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} numberOfServings 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMealTypesByNumberOfServings(numberOfServings: number, options?: any): AxiosPromise<GetMealTypesByNumberOfServingsResponse> {
            return localVarFp.getMealTypesByNumberOfServings(numberOfServings, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<number>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeals(requestBody?: Array<number>, options?: any): AxiosPromise<GetMealsResponse> {
            return localVarFp.getMeals(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} dietPlanId 
         * @param {number} dietDay 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMealsExchangeProposals(dietPlanId: number, dietDay: number, options?: any): AxiosPromise<GetMealsExchangeProposalsResponse> {
            return localVarFp.getMealsExchangeProposals(dietPlanId, dietDay, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GetMealsForWeek} [getMealsForWeek] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMealsForWeek(getMealsForWeek?: GetMealsForWeek, options?: any): AxiosPromise<GetMealsForWeekResponse> {
            return localVarFp.getMealsForWeek(getMealsForWeek, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} productId 
         * @param {number} newQtyInGrams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductNutrionSummary(productId: number, newQtyInGrams: number, options?: any): AxiosPromise<GetProductNutrionSummaryResponse> {
            return localVarFp.getProductNutrionSummary(productId, newQtyInGrams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} tagId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductTags(tagId: number, options?: any): AxiosPromise<GetProductTagsResponse> {
            return localVarFp.getProductTags(tagId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShoppingItemForDietPlan(options?: any): AxiosPromise<GetShoppingListForDietPlanResponse> {
            return localVarFp.getShoppingItemForDietPlan(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tag 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTagMeals(tag: string, options?: any): AxiosPromise<GetTagMealsResponse> {
            return localVarFp.getTagMeals(tag, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} dietTypeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTagMealsForDietServings(dietTypeId: number, options?: any): AxiosPromise<GetTagMealsForDietServingsResponse> {
            return localVarFp.getTagMealsForDietServings(dietTypeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTags(options?: any): AxiosPromise<GetTagsResponse> {
            return localVarFp.getTags(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} customerId 
         * @param {number} subscriptionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWeekPlan(customerId: number, subscriptionId: number, options?: any): AxiosPromise<GetWeekPlanResponse> {
            return localVarFp.getWeekPlan(customerId, subscriptionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DietBallanceDefinition} [dietBallanceDefinition] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAddOrUpdateDietBallanceDefinition(dietBallanceDefinition?: DietBallanceDefinition, options?: any): AxiosPromise<PostAddOrUpdateDietBallanceDefinitionResponse> {
            return localVarFp.postAddOrUpdateDietBallanceDefinition(dietBallanceDefinition, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} customerId 
         * @param {PostAddOrUpdateDietPlanDayMeals} [postAddOrUpdateDietPlanDayMeals] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAddOrUpdateDietPlanDayMeals(customerId: number, postAddOrUpdateDietPlanDayMeals?: PostAddOrUpdateDietPlanDayMeals, options?: any): AxiosPromise<object> {
            return localVarFp.postAddOrUpdateDietPlanDayMeals(customerId, postAddOrUpdateDietPlanDayMeals, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} dietPlanDayMealId 
         * @param {number} mealId 
         * @param {number} productId 
         * @param {PostAddOrUpdateMealServig} [postAddOrUpdateMealServig] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAddOrUpdateMealServig(dietPlanDayMealId: number, mealId: number, productId: number, postAddOrUpdateMealServig?: PostAddOrUpdateMealServig, options?: any): AxiosPromise<PostAddOrUpdateMealServigResponse> {
            return localVarFp.postAddOrUpdateMealServig(dietPlanDayMealId, mealId, productId, postAddOrUpdateMealServig, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} mealId 
         * @param {PostConvertToMasterMeal} [postConvertToMasterMeal] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postConvertToMasterMeal(mealId: number, postConvertToMasterMeal?: PostConvertToMasterMeal, options?: any): AxiosPromise<object> {
            return localVarFp.postConvertToMasterMeal(mealId, postConvertToMasterMeal, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PostCopyDietPlanDayMeals} [postCopyDietPlanDayMeals] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCopyDietPlanDayMeals(postCopyDietPlanDayMeals?: PostCopyDietPlanDayMeals, options?: any): AxiosPromise<object> {
            return localVarFp.postCopyDietPlanDayMeals(postCopyDietPlanDayMeals, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PostCopyMealDefinition} [postCopyMealDefinition] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCopyMealDefinition(postCopyMealDefinition?: PostCopyMealDefinition, options?: any): AxiosPromise<PostCopyMealDefinitionResponse> {
            return localVarFp.postCopyMealDefinition(postCopyMealDefinition, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MealTypeEnum} mealType   0 &#x3D; Breakfast  1 &#x3D; Brunch  2 &#x3D; Elevenses  3 &#x3D; Lunch  4 &#x3D; Tea  5 &#x3D; Supper  6 &#x3D; Dinner  7 &#x3D; Beverages
         * @param {number} customerId 
         * @param {PostCustomerMealFoodProducts} [postCustomerMealFoodProducts] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCustomerMealFoodProducts(mealType: MealTypeEnum, customerId: number, postCustomerMealFoodProducts?: PostCustomerMealFoodProducts, options?: any): AxiosPromise<object> {
            return localVarFp.postCustomerMealFoodProducts(mealType, customerId, postCustomerMealFoodProducts, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PostDietDefinition} [postDietDefinition] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDietDefinition(postDietDefinition?: PostDietDefinition, options?: any): AxiosPromise<PostDietDefinitionResponse> {
            return localVarFp.postDietDefinition(postDietDefinition, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} tagId 
         * @param {PostDietDefintionTags} [postDietDefintionTags] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDietDefintionTags(tagId: number, postDietDefintionTags?: PostDietDefintionTags, options?: any): AxiosPromise<PostDietDefintionTagsResponse> {
            return localVarFp.postDietDefintionTags(tagId, postDietDefintionTags, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [mealId] 
         * @param {number} [carbs] 
         * @param {number} [proteins] 
         * @param {number} [fats] 
         * @param {number} [energy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDietRecalc(mealId?: number, carbs?: number, proteins?: number, fats?: number, energy?: number, options?: any): AxiosPromise<PostDietRecalcResponse> {
            return localVarFp.postDietRecalc(mealId, carbs, proteins, fats, energy, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [mealId] 
         * @param {number} [carbs] 
         * @param {number} [proteins] 
         * @param {number} [fats] 
         * @param {number} [energy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDietRecalcCorrected(mealId?: number, carbs?: number, proteins?: number, fats?: number, energy?: number, options?: any): AxiosPromise<PostDietRecalcCorrectedResponse> {
            return localVarFp.postDietRecalcCorrected(mealId, carbs, proteins, fats, energy, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PostFavouriteMealDefinition} [postFavouriteMealDefinition] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postFavouriteMealDefinition(postFavouriteMealDefinition?: PostFavouriteMealDefinition, options?: any): AxiosPromise<object> {
            return localVarFp.postFavouriteMealDefinition(postFavouriteMealDefinition, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PostMealComment} [postMealComment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postMealComment(postMealComment?: PostMealComment, options?: any): AxiosPromise<PostMealCommentResponse> {
            return localVarFp.postMealComment(postMealComment, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PostMealDefinition} [postMealDefinition] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postMealDefinition(postMealDefinition?: PostMealDefinition, options?: any): AxiosPromise<PostMealDefinitionResponse> {
            return localVarFp.postMealDefinition(postMealDefinition, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PostProcessEatenMeal} [postProcessEatenMeal] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postProcessEatenMeal(postProcessEatenMeal?: PostProcessEatenMeal, options?: any): AxiosPromise<PostProcessEatenMealResponse> {
            return localVarFp.postProcessEatenMeal(postProcessEatenMeal, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} tagId 
         * @param {PostProductTag} [postProductTag] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postProductTag(tagId: number, postProductTag?: PostProductTag, options?: any): AxiosPromise<PostProductTagResponse> {
            return localVarFp.postProductTag(tagId, postProductTag, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [mealId] 
         * @param {boolean} [update] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postReCalcMeal(mealId?: number, update?: boolean, options?: any): AxiosPromise<PostReCalcMealResponse> {
            return localVarFp.postReCalcMeal(mealId, update, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PostShoppingItemForDietPlan} [postShoppingItemForDietPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postShoppingItemForDietPlan(postShoppingItemForDietPlan?: PostShoppingItemForDietPlan, options?: any): AxiosPromise<object> {
            return localVarFp.postShoppingItemForDietPlan(postShoppingItemForDietPlan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PostShoppingListForDietPlan} [postShoppingListForDietPlan] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postShoppingListForDietPlan(postShoppingListForDietPlan?: PostShoppingListForDietPlan, options?: any): AxiosPromise<PostShoppingListForDietPlanResponse> {
            return localVarFp.postShoppingListForDietPlan(postShoppingListForDietPlan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PostSwapDietPlanDayMeals} [postSwapDietPlanDayMeals] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postSwapDietPlanDayMeals(postSwapDietPlanDayMeals?: PostSwapDietPlanDayMeals, options?: any): AxiosPromise<PostSwapDietPlanDayMealsResponse> {
            return localVarFp.postSwapDietPlanDayMeals(postSwapDietPlanDayMeals, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PostTag} [postTag] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postTag(postTag?: PostTag, options?: any): AxiosPromise<PostTagResponse> {
            return localVarFp.postTag(postTag, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {PutDietDefinition} [putDietDefinition] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putDietDefinition(id: number, putDietDefinition?: PutDietDefinition, options?: any): AxiosPromise<PutDietDefinitionResponse> {
            return localVarFp.putDietDefinition(id, putDietDefinition, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} mealId 
         * @param {PutMeal} [putMeal] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putMeal(mealId: number, putMeal?: PutMeal, options?: any): AxiosPromise<PutMealResponse> {
            return localVarFp.putMeal(mealId, putMeal, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} mealId 
         * @param {PutMealServingPrerefences} [putMealServingPrerefences] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putMealServingPrerefences(mealId: number, putMealServingPrerefences?: PutMealServingPrerefences, options?: any): AxiosPromise<object> {
            return localVarFp.putMealServingPrerefences(mealId, putMealServingPrerefences, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DietApi - object-oriented interface
 * @export
 * @class DietApi
 * @extends {BaseAPI}
 */
export class DietApi extends BaseAPI {
    /**
     * 
     * @param {AddOrUpdateDietPlan} [addOrUpdateDietPlan] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DietApi
     */
    public addOrUpdateDietPlan(addOrUpdateDietPlan?: AddOrUpdateDietPlan, options?: AxiosRequestConfig) {
        return DietApiFp(this.configuration).addOrUpdateDietPlan(addOrUpdateDietPlan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AddOrUpdateDietPlanDay} [addOrUpdateDietPlanDay] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DietApi
     */
    public addOrUpdateDietPlanDay(addOrUpdateDietPlanDay?: AddOrUpdateDietPlanDay, options?: AxiosRequestConfig) {
        return DietApiFp(this.configuration).addOrUpdateDietPlanDay(addOrUpdateDietPlanDay, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DietApi
     */
    public deleteDietBallanceDefinition(id: number, options?: AxiosRequestConfig) {
        return DietApiFp(this.configuration).deleteDietBallanceDefinition(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DietApi
     */
    public deleteDietDefinition(id: number, options?: AxiosRequestConfig) {
        return DietApiFp(this.configuration).deleteDietDefinition(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} tagId 
     * @param {number} dietDefinitionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DietApi
     */
    public deleteDietDefintionTags(tagId: number, dietDefinitionId: number, options?: AxiosRequestConfig) {
        return DietApiFp(this.configuration).deleteDietDefintionTags(tagId, dietDefinitionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} subscriptionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DietApi
     */
    public deleteDietPlanDays(subscriptionId: string, options?: AxiosRequestConfig) {
        return DietApiFp(this.configuration).deleteDietPlanDays(subscriptionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} mealId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DietApi
     */
    public deleteMeal(mealId: number, options?: AxiosRequestConfig) {
        return DietApiFp(this.configuration).deleteMeal(mealId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} mealId 
     * @param {number} productId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DietApi
     */
    public deleteMealFoodProductFromMeal(mealId: number, productId: number, options?: AxiosRequestConfig) {
        return DietApiFp(this.configuration).deleteMealFoodProductFromMeal(mealId, productId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} dietPlanDayMealId 
     * @param {number} mealId 
     * @param {number} productId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DietApi
     */
    public deleteMealServig(dietPlanDayMealId: number, mealId: number, productId: number, options?: AxiosRequestConfig) {
        return DietApiFp(this.configuration).deleteMealServig(dietPlanDayMealId, mealId, productId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} tagId 
     * @param {number} productTagId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DietApi
     */
    public deleteProductTag(tagId: number, productTagId: number, options?: AxiosRequestConfig) {
        return DietApiFp(this.configuration).deleteProductTag(tagId, productTagId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} mealId 
     * @param {number} slaveMealId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DietApi
     */
    public deleteUnlinkFromMasterMeal(mealId: number, slaveMealId: number, options?: AxiosRequestConfig) {
        return DietApiFp(this.configuration).deleteUnlinkFromMasterMeal(mealId, slaveMealId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DietApi
     */
    public getAvailableDietPlans(options?: AxiosRequestConfig) {
        return DietApiFp(this.configuration).getAvailableDietPlans(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DietApi
     */
    public getAvailableDiets(options?: AxiosRequestConfig) {
        return DietApiFp(this.configuration).getAvailableDiets(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} dietDefinitionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DietApi
     */
    public getBeverages(dietDefinitionId: number, options?: AxiosRequestConfig) {
        return DietApiFp(this.configuration).getBeverages(dietDefinitionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} customerId 
     * @param {number} subscriptionId 
     * @param {number} dayId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DietApi
     */
    public getDayPlan(customerId: number, subscriptionId: number, dayId: number, options?: AxiosRequestConfig) {
        return DietApiFp(this.configuration).getDayPlan(customerId, subscriptionId, dayId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DietApi
     */
    public getDietBallanceDefinitions(options?: AxiosRequestConfig) {
        return DietApiFp(this.configuration).getDietBallanceDefinitions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} dietPlanId 
     * @param {GetDietBallanceItems} [getDietBallanceItems] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DietApi
     */
    public getDietBallanceItems(dietPlanId: number, getDietBallanceItems?: GetDietBallanceItems, options?: AxiosRequestConfig) {
        return DietApiFp(this.configuration).getDietBallanceItems(dietPlanId, getDietBallanceItems, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} customerId 
     * @param {number} dietPlanId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DietApi
     */
    public getDietCalculationForCustomer(customerId: number, dietPlanId: number, options?: AxiosRequestConfig) {
        return DietApiFp(this.configuration).getDietCalculationForCustomer(customerId, dietPlanId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DietApi
     */
    public getDietDefinitions(options?: AxiosRequestConfig) {
        return DietApiFp(this.configuration).getDietDefinitions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} tagId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DietApi
     */
    public getDietDefintionTags(tagId: number, options?: AxiosRequestConfig) {
        return DietApiFp(this.configuration).getDietDefintionTags(tagId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} customerId 
     * @param {number} planId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DietApi
     */
    public getDietPlan(customerId: number, planId: number, options?: AxiosRequestConfig) {
        return DietApiFp(this.configuration).getDietPlan(customerId, planId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} customerId 
     * @param {number} dietPlanId 
     * @param {number} dietDay 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DietApi
     */
    public getDietPlanDay(customerId: number, dietPlanId: number, dietDay: number, options?: AxiosRequestConfig) {
        return DietApiFp(this.configuration).getDietPlanDay(customerId, dietPlanId, dietDay, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} dietPlanDayMealId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DietApi
     */
    public getDietPlanDayMealServings(dietPlanDayMealId: number, options?: AxiosRequestConfig) {
        return DietApiFp(this.configuration).getDietPlanDayMealServings(dietPlanDayMealId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} dietSettings 
     * @param {GetMatches} [getMatches] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DietApi
     */
    public getMatches(dietSettings: string, getMatches?: GetMatches, options?: AxiosRequestConfig) {
        return DietApiFp(this.configuration).getMatches(dietSettings, getMatches, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} dietSettings 
     * @param {GetMatches2} [getMatches2] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DietApi
     */
    public getMatches2(dietSettings: string, getMatches2?: GetMatches2, options?: AxiosRequestConfig) {
        return DietApiFp(this.configuration).getMatches2(dietSettings, getMatches2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} mealId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DietApi
     */
    public getMealById(mealId: number, options?: AxiosRequestConfig) {
        return DietApiFp(this.configuration).getMealById(mealId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} dietTypeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DietApi
     */
    public getMealDefinitions(dietTypeId: number, options?: AxiosRequestConfig) {
        return DietApiFp(this.configuration).getMealDefinitions(dietTypeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] 
     * @param {number} [count] 
     * @param {boolean} [isPagingSpecified] 
     * @param {string} [sortField] 
     * @param {SortDirection} [sortDir]   0 &#x3D; Undefined  1 &#x3D; Asc  2 &#x3D; Desc
     * @param {boolean} [isSortingSpecified] 
     * @param {string} [filterDietType] 
     * @param {number} [filterTagId] 
     * @param {MealStatus} [filterStatus]   0 &#x3D; None  1 &#x3D; New  2 &#x3D; ToVerify  4 &#x3D; Verified  8 &#x3D; Rejected  64 &#x3D; Public
     * @param {MealTypeEnum} [filterMealType]   0 &#x3D; Breakfast  1 &#x3D; Brunch  2 &#x3D; Elevenses  3 &#x3D; Lunch  4 &#x3D; Tea  5 &#x3D; Supper  6 &#x3D; Dinner  7 &#x3D; Beverages
     * @param {boolean} [filterMasterMeal] 
     * @param {boolean} [filterHasImage] 
     * @param {boolean} [filterSweet] 
     * @param {boolean} [filterDry] 
     * @param {boolean} [filterIsCustom] 
     * @param {Classification} [filterClassification]   0 &#x3D; None (None)  1 &#x3D; Ballanced (Ballanced)  2 &#x3D; Carbohydrates (Carbohydrates)  4 &#x3D; Energy (Energy)  8 &#x3D; Fat (Fat)  16 &#x3D; Protein (Protein)
     * @param {boolean} [filterIsDeleted] 
     * @param {boolean} [filterIsForRestaurant] 
     * @param {string} [filterRestaurantName] 
     * @param {number} [filterRestaurantId] 
     * @param {number} [filterMealId] 
     * @param {number} [filterCustomerId] 
     * @param {Array<number>} [filterProductIds] 
     * @param {string} [filterName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DietApi
     */
    public getMealSearch(page?: number, count?: number, isPagingSpecified?: boolean, sortField?: string, sortDir?: SortDirection, isSortingSpecified?: boolean, filterDietType?: string, filterTagId?: number, filterStatus?: MealStatus, filterMealType?: MealTypeEnum, filterMasterMeal?: boolean, filterHasImage?: boolean, filterSweet?: boolean, filterDry?: boolean, filterIsCustom?: boolean, filterClassification?: Classification, filterIsDeleted?: boolean, filterIsForRestaurant?: boolean, filterRestaurantName?: string, filterRestaurantId?: number, filterMealId?: number, filterCustomerId?: number, filterProductIds?: Array<number>, filterName?: string, options?: AxiosRequestConfig) {
        return DietApiFp(this.configuration).getMealSearch(page, count, isPagingSpecified, sortField, sortDir, isSortingSpecified, filterDietType, filterTagId, filterStatus, filterMealType, filterMasterMeal, filterHasImage, filterSweet, filterDry, filterIsCustom, filterClassification, filterIsDeleted, filterIsForRestaurant, filterRestaurantName, filterRestaurantId, filterMealId, filterCustomerId, filterProductIds, filterName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GetMealServingsExchangeProposals} [getMealServingsExchangeProposals] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DietApi
     */
    public getMealServingsExchangeProposals(getMealServingsExchangeProposals?: GetMealServingsExchangeProposals, options?: AxiosRequestConfig) {
        return DietApiFp(this.configuration).getMealServingsExchangeProposals(getMealServingsExchangeProposals, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} numberOfServings 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DietApi
     */
    public getMealTypesByNumberOfServings(numberOfServings: number, options?: AxiosRequestConfig) {
        return DietApiFp(this.configuration).getMealTypesByNumberOfServings(numberOfServings, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<number>} [requestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DietApi
     */
    public getMeals(requestBody?: Array<number>, options?: AxiosRequestConfig) {
        return DietApiFp(this.configuration).getMeals(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} dietPlanId 
     * @param {number} dietDay 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DietApi
     */
    public getMealsExchangeProposals(dietPlanId: number, dietDay: number, options?: AxiosRequestConfig) {
        return DietApiFp(this.configuration).getMealsExchangeProposals(dietPlanId, dietDay, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GetMealsForWeek} [getMealsForWeek] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DietApi
     */
    public getMealsForWeek(getMealsForWeek?: GetMealsForWeek, options?: AxiosRequestConfig) {
        return DietApiFp(this.configuration).getMealsForWeek(getMealsForWeek, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} productId 
     * @param {number} newQtyInGrams 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DietApi
     */
    public getProductNutrionSummary(productId: number, newQtyInGrams: number, options?: AxiosRequestConfig) {
        return DietApiFp(this.configuration).getProductNutrionSummary(productId, newQtyInGrams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} tagId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DietApi
     */
    public getProductTags(tagId: number, options?: AxiosRequestConfig) {
        return DietApiFp(this.configuration).getProductTags(tagId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DietApi
     */
    public getShoppingItemForDietPlan(options?: AxiosRequestConfig) {
        return DietApiFp(this.configuration).getShoppingItemForDietPlan(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tag 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DietApi
     */
    public getTagMeals(tag: string, options?: AxiosRequestConfig) {
        return DietApiFp(this.configuration).getTagMeals(tag, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} dietTypeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DietApi
     */
    public getTagMealsForDietServings(dietTypeId: number, options?: AxiosRequestConfig) {
        return DietApiFp(this.configuration).getTagMealsForDietServings(dietTypeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DietApi
     */
    public getTags(options?: AxiosRequestConfig) {
        return DietApiFp(this.configuration).getTags(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} customerId 
     * @param {number} subscriptionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DietApi
     */
    public getWeekPlan(customerId: number, subscriptionId: number, options?: AxiosRequestConfig) {
        return DietApiFp(this.configuration).getWeekPlan(customerId, subscriptionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DietBallanceDefinition} [dietBallanceDefinition] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DietApi
     */
    public postAddOrUpdateDietBallanceDefinition(dietBallanceDefinition?: DietBallanceDefinition, options?: AxiosRequestConfig) {
        return DietApiFp(this.configuration).postAddOrUpdateDietBallanceDefinition(dietBallanceDefinition, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} customerId 
     * @param {PostAddOrUpdateDietPlanDayMeals} [postAddOrUpdateDietPlanDayMeals] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DietApi
     */
    public postAddOrUpdateDietPlanDayMeals(customerId: number, postAddOrUpdateDietPlanDayMeals?: PostAddOrUpdateDietPlanDayMeals, options?: AxiosRequestConfig) {
        return DietApiFp(this.configuration).postAddOrUpdateDietPlanDayMeals(customerId, postAddOrUpdateDietPlanDayMeals, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} dietPlanDayMealId 
     * @param {number} mealId 
     * @param {number} productId 
     * @param {PostAddOrUpdateMealServig} [postAddOrUpdateMealServig] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DietApi
     */
    public postAddOrUpdateMealServig(dietPlanDayMealId: number, mealId: number, productId: number, postAddOrUpdateMealServig?: PostAddOrUpdateMealServig, options?: AxiosRequestConfig) {
        return DietApiFp(this.configuration).postAddOrUpdateMealServig(dietPlanDayMealId, mealId, productId, postAddOrUpdateMealServig, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} mealId 
     * @param {PostConvertToMasterMeal} [postConvertToMasterMeal] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DietApi
     */
    public postConvertToMasterMeal(mealId: number, postConvertToMasterMeal?: PostConvertToMasterMeal, options?: AxiosRequestConfig) {
        return DietApiFp(this.configuration).postConvertToMasterMeal(mealId, postConvertToMasterMeal, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PostCopyDietPlanDayMeals} [postCopyDietPlanDayMeals] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DietApi
     */
    public postCopyDietPlanDayMeals(postCopyDietPlanDayMeals?: PostCopyDietPlanDayMeals, options?: AxiosRequestConfig) {
        return DietApiFp(this.configuration).postCopyDietPlanDayMeals(postCopyDietPlanDayMeals, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PostCopyMealDefinition} [postCopyMealDefinition] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DietApi
     */
    public postCopyMealDefinition(postCopyMealDefinition?: PostCopyMealDefinition, options?: AxiosRequestConfig) {
        return DietApiFp(this.configuration).postCopyMealDefinition(postCopyMealDefinition, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MealTypeEnum} mealType   0 &#x3D; Breakfast  1 &#x3D; Brunch  2 &#x3D; Elevenses  3 &#x3D; Lunch  4 &#x3D; Tea  5 &#x3D; Supper  6 &#x3D; Dinner  7 &#x3D; Beverages
     * @param {number} customerId 
     * @param {PostCustomerMealFoodProducts} [postCustomerMealFoodProducts] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DietApi
     */
    public postCustomerMealFoodProducts(mealType: MealTypeEnum, customerId: number, postCustomerMealFoodProducts?: PostCustomerMealFoodProducts, options?: AxiosRequestConfig) {
        return DietApiFp(this.configuration).postCustomerMealFoodProducts(mealType, customerId, postCustomerMealFoodProducts, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PostDietDefinition} [postDietDefinition] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DietApi
     */
    public postDietDefinition(postDietDefinition?: PostDietDefinition, options?: AxiosRequestConfig) {
        return DietApiFp(this.configuration).postDietDefinition(postDietDefinition, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} tagId 
     * @param {PostDietDefintionTags} [postDietDefintionTags] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DietApi
     */
    public postDietDefintionTags(tagId: number, postDietDefintionTags?: PostDietDefintionTags, options?: AxiosRequestConfig) {
        return DietApiFp(this.configuration).postDietDefintionTags(tagId, postDietDefintionTags, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [mealId] 
     * @param {number} [carbs] 
     * @param {number} [proteins] 
     * @param {number} [fats] 
     * @param {number} [energy] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DietApi
     */
    public postDietRecalc(mealId?: number, carbs?: number, proteins?: number, fats?: number, energy?: number, options?: AxiosRequestConfig) {
        return DietApiFp(this.configuration).postDietRecalc(mealId, carbs, proteins, fats, energy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [mealId] 
     * @param {number} [carbs] 
     * @param {number} [proteins] 
     * @param {number} [fats] 
     * @param {number} [energy] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DietApi
     */
    public postDietRecalcCorrected(mealId?: number, carbs?: number, proteins?: number, fats?: number, energy?: number, options?: AxiosRequestConfig) {
        return DietApiFp(this.configuration).postDietRecalcCorrected(mealId, carbs, proteins, fats, energy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PostFavouriteMealDefinition} [postFavouriteMealDefinition] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DietApi
     */
    public postFavouriteMealDefinition(postFavouriteMealDefinition?: PostFavouriteMealDefinition, options?: AxiosRequestConfig) {
        return DietApiFp(this.configuration).postFavouriteMealDefinition(postFavouriteMealDefinition, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PostMealComment} [postMealComment] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DietApi
     */
    public postMealComment(postMealComment?: PostMealComment, options?: AxiosRequestConfig) {
        return DietApiFp(this.configuration).postMealComment(postMealComment, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PostMealDefinition} [postMealDefinition] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DietApi
     */
    public postMealDefinition(postMealDefinition?: PostMealDefinition, options?: AxiosRequestConfig) {
        return DietApiFp(this.configuration).postMealDefinition(postMealDefinition, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PostProcessEatenMeal} [postProcessEatenMeal] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DietApi
     */
    public postProcessEatenMeal(postProcessEatenMeal?: PostProcessEatenMeal, options?: AxiosRequestConfig) {
        return DietApiFp(this.configuration).postProcessEatenMeal(postProcessEatenMeal, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} tagId 
     * @param {PostProductTag} [postProductTag] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DietApi
     */
    public postProductTag(tagId: number, postProductTag?: PostProductTag, options?: AxiosRequestConfig) {
        return DietApiFp(this.configuration).postProductTag(tagId, postProductTag, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [mealId] 
     * @param {boolean} [update] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DietApi
     */
    public postReCalcMeal(mealId?: number, update?: boolean, options?: AxiosRequestConfig) {
        return DietApiFp(this.configuration).postReCalcMeal(mealId, update, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PostShoppingItemForDietPlan} [postShoppingItemForDietPlan] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DietApi
     */
    public postShoppingItemForDietPlan(postShoppingItemForDietPlan?: PostShoppingItemForDietPlan, options?: AxiosRequestConfig) {
        return DietApiFp(this.configuration).postShoppingItemForDietPlan(postShoppingItemForDietPlan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PostShoppingListForDietPlan} [postShoppingListForDietPlan] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DietApi
     */
    public postShoppingListForDietPlan(postShoppingListForDietPlan?: PostShoppingListForDietPlan, options?: AxiosRequestConfig) {
        return DietApiFp(this.configuration).postShoppingListForDietPlan(postShoppingListForDietPlan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PostSwapDietPlanDayMeals} [postSwapDietPlanDayMeals] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DietApi
     */
    public postSwapDietPlanDayMeals(postSwapDietPlanDayMeals?: PostSwapDietPlanDayMeals, options?: AxiosRequestConfig) {
        return DietApiFp(this.configuration).postSwapDietPlanDayMeals(postSwapDietPlanDayMeals, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PostTag} [postTag] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DietApi
     */
    public postTag(postTag?: PostTag, options?: AxiosRequestConfig) {
        return DietApiFp(this.configuration).postTag(postTag, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {PutDietDefinition} [putDietDefinition] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DietApi
     */
    public putDietDefinition(id: number, putDietDefinition?: PutDietDefinition, options?: AxiosRequestConfig) {
        return DietApiFp(this.configuration).putDietDefinition(id, putDietDefinition, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} mealId 
     * @param {PutMeal} [putMeal] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DietApi
     */
    public putMeal(mealId: number, putMeal?: PutMeal, options?: AxiosRequestConfig) {
        return DietApiFp(this.configuration).putMeal(mealId, putMeal, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} mealId 
     * @param {PutMealServingPrerefences} [putMealServingPrerefences] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DietApi
     */
    public putMealServingPrerefences(mealId: number, putMealServingPrerefences?: PutMealServingPrerefences, options?: AxiosRequestConfig) {
        return DietApiFp(this.configuration).putMealServingPrerefences(mealId, putMealServingPrerefences, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FoodApi - axios parameter creator
 * @export
 */
export const FoodApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AddOrUpdateCustomProduct} [addOrUpdateCustomProduct] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOrUpdateCustomProduct: async (addOrUpdateCustomProduct?: AddOrUpdateCustomProduct, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Food/CustomProduct`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addOrUpdateCustomProduct, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AddOrUpdateHomeMeasurementRule} [addOrUpdateHomeMeasurementRule] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOrUpdateHomeMeasurementRule: async (addOrUpdateHomeMeasurementRule?: AddOrUpdateHomeMeasurementRule, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Food/HomeMeasurement/Rules`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addOrUpdateHomeMeasurementRule, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ProductExchangeGroup} [productExchangeGroup] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOrUpdateProductExchangeGroup: async (productExchangeGroup?: ProductExchangeGroup, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Food/ProductExchangeGroup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(productExchangeGroup, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AddOrUpdateUnitOfMeasure} [addOrUpdateUnitOfMeasure] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOrUpdateUnitOfMeasure: async (addOrUpdateUnitOfMeasure?: AddOrUpdateUnitOfMeasure, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Food/UnitOfMeasure`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addOrUpdateUnitOfMeasure, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AddOrUpdateUoMTextRule} [addOrUpdateUoMTextRule] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOrUpdateUoMTextRule: async (addOrUpdateUoMTextRule?: AddOrUpdateUoMTextRule, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Food/UoMTextRule`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addOrUpdateUoMTextRule, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteHomeMeasurementRule: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteHomeMeasurementRule', 'id', id)
            const localVarPath = `/api/Food/HomeMeasurement/Rules/{Id}`
                .replace(`{${"Id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} productId 
         * @param {number} nutritionFactId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNutritionFact: async (productId: number, nutritionFactId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('deleteNutritionFact', 'productId', productId)
            // verify required parameter 'nutritionFactId' is not null or undefined
            assertParamExists('deleteNutritionFact', 'nutritionFactId', nutritionFactId)
            const localVarPath = `/api/Food/Product/{ProductId}/NutritionFact/{NutritionFactId}`
                .replace(`{${"ProductId"}}`, encodeURIComponent(String(productId)))
                .replace(`{${"NutritionFactId"}}`, encodeURIComponent(String(nutritionFactId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProduct: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteProduct', 'id', id)
            const localVarPath = `/api/Food/Product/{Id}`
                .replace(`{${"Id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProductExchangeGroup: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteProductExchangeGroup', 'id', id)
            const localVarPath = `/api/Food/ProductExchangeGroup/{Id}`
                .replace(`{${"Id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProductGroup: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteProductGroup', 'id', id)
            const localVarPath = `/api/Food/ProductGroup/{Id}`
                .replace(`{${"Id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} ruleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProductServingRule: async (ruleId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ruleId' is not null or undefined
            assertParamExists('deleteProductServingRule', 'ruleId', ruleId)
            const localVarPath = `/api/Food/ProductServingRule/{RuleId}`
                .replace(`{${"RuleId"}}`, encodeURIComponent(String(ruleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllHomeMeasurementRules: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Food/HomeMeasurement/Rules`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllProductExchangeGroups: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Food/ProductExchangeGroup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHomeMeasurementRulesForProduct: async (productId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('getHomeMeasurementRulesForProduct', 'productId', productId)
            const localVarPath = `/api/Food/HomeMeasurement/Rules/{ProductId}`
                .replace(`{${"ProductId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNutritionFactDefinitions: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Food/NutritionFact/Definitions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getProductById', 'id', id)
            const localVarPath = `/api/Food/Product/{Id}`
                .replace(`{${"Id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} productGroupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductByProductGroup: async (productGroupId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productGroupId' is not null or undefined
            assertParamExists('getProductByProductGroup', 'productGroupId', productGroupId)
            const localVarPath = `/api/Food/Products/Group/{ProductGroupId}`
                .replace(`{${"ProductGroupId"}}`, encodeURIComponent(String(productGroupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductGroups: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Food/ProductGroups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} qtyInGrams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductHomeMeasurement: async (id: number, qtyInGrams: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getProductHomeMeasurement', 'id', id)
            // verify required parameter 'qtyInGrams' is not null or undefined
            assertParamExists('getProductHomeMeasurement', 'qtyInGrams', qtyInGrams)
            const localVarPath = `/api/Food/Product/{Id}/HomeMeasurement/{QtyInGrams}`
                .replace(`{${"Id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"QtyInGrams"}}`, encodeURIComponent(String(qtyInGrams)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductRefAndNames: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Food/ProductRefs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} dietName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductRefAndNamesByDietName: async (dietName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dietName' is not null or undefined
            assertParamExists('getProductRefAndNamesByDietName', 'dietName', dietName)
            const localVarPath = `/api/Food/ProductRefs/{DietName}`
                .replace(`{${"DietName"}}`, encodeURIComponent(String(dietName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductServingRules: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Food/ProductServingRules`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProducts: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Food/Products`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} productId 
         * @param {number} dietPlanId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReplacementProduct: async (productId: number, dietPlanId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('getReplacementProduct', 'productId', productId)
            // verify required parameter 'dietPlanId' is not null or undefined
            assertParamExists('getReplacementProduct', 'dietPlanId', dietPlanId)
            const localVarPath = `/api/Food/{ProductId}/Replacement/{DietPlanId}`
                .replace(`{${"ProductId"}}`, encodeURIComponent(String(productId)))
                .replace(`{${"DietPlanId"}}`, encodeURIComponent(String(dietPlanId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GetUnitOfMeasures} [getUnitOfMeasures] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUnitOfMeasures: async (getUnitOfMeasures?: GetUnitOfMeasures, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Food/UnitOfMeasure/List`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getUnitOfMeasures, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PostProduct} [postProduct] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postProduct: async (postProduct?: PostProduct, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Food/Product`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postProduct, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PostProductGroup} [postProductGroup] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postProductGroup: async (postProductGroup?: PostProductGroup, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Food/ProductGroup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postProductGroup, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} productId 
         * @param {PostProductNutritionFact} [postProductNutritionFact] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postProductNutritionFact: async (productId: number, postProductNutritionFact?: PostProductNutritionFact, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('postProductNutritionFact', 'productId', productId)
            const localVarPath = `/api/Food/Product/{ProductId}/NutritionFact`
                .replace(`{${"ProductId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postProductNutritionFact, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PostProductServingRule} [postProductServingRule] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postProductServingRule: async (postProductServingRule?: PostProductServingRule, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Food/ProductServingRule`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postProductServingRule, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {PutProduct} [putProduct] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putProduct: async (id: number, putProduct?: PutProduct, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putProduct', 'id', id)
            const localVarPath = `/api/Food/Product/{Id}`
                .replace(`{${"Id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(putProduct, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {PutProductGroup} [putProductGroup] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putProductGroup: async (id: number, putProductGroup?: PutProductGroup, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putProductGroup', 'id', id)
            const localVarPath = `/api/Food/ProductGroup/{Id}`
                .replace(`{${"Id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(putProductGroup, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} productId 
         * @param {number} nutritionFactId 
         * @param {PutProductNutritionFact} [putProductNutritionFact] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putProductNutritionFact: async (productId: number, nutritionFactId: number, putProductNutritionFact?: PutProductNutritionFact, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('putProductNutritionFact', 'productId', productId)
            // verify required parameter 'nutritionFactId' is not null or undefined
            assertParamExists('putProductNutritionFact', 'nutritionFactId', nutritionFactId)
            const localVarPath = `/api/Food/Product/{ProductId}/NutritionFact/{NutritionFactId}`
                .replace(`{${"ProductId"}}`, encodeURIComponent(String(productId)))
                .replace(`{${"NutritionFactId"}}`, encodeURIComponent(String(nutritionFactId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(putProductNutritionFact, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FoodApi - functional programming interface
 * @export
 */
export const FoodApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FoodApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {AddOrUpdateCustomProduct} [addOrUpdateCustomProduct] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addOrUpdateCustomProduct(addOrUpdateCustomProduct?: AddOrUpdateCustomProduct, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddOrUpdateCustomProductResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addOrUpdateCustomProduct(addOrUpdateCustomProduct, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AddOrUpdateHomeMeasurementRule} [addOrUpdateHomeMeasurementRule] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addOrUpdateHomeMeasurementRule(addOrUpdateHomeMeasurementRule?: AddOrUpdateHomeMeasurementRule, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddOrUpdateHomeMeasurementRuleResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addOrUpdateHomeMeasurementRule(addOrUpdateHomeMeasurementRule, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ProductExchangeGroup} [productExchangeGroup] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addOrUpdateProductExchangeGroup(productExchangeGroup?: ProductExchangeGroup, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddOrUpdateProductExchangeGroupResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addOrUpdateProductExchangeGroup(productExchangeGroup, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AddOrUpdateUnitOfMeasure} [addOrUpdateUnitOfMeasure] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addOrUpdateUnitOfMeasure(addOrUpdateUnitOfMeasure?: AddOrUpdateUnitOfMeasure, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddOrUpdateUnitOfMeasureResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addOrUpdateUnitOfMeasure(addOrUpdateUnitOfMeasure, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AddOrUpdateUoMTextRule} [addOrUpdateUoMTextRule] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addOrUpdateUoMTextRule(addOrUpdateUoMTextRule?: AddOrUpdateUoMTextRule, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddOrUpdateUoMTextRuleResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addOrUpdateUoMTextRule(addOrUpdateUoMTextRule, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteHomeMeasurementRule(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteHomeMeasurementRule(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} productId 
         * @param {number} nutritionFactId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteNutritionFact(productId: number, nutritionFactId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteNutritionFact(productId, nutritionFactId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProduct(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProduct(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProductExchangeGroup(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProductExchangeGroup(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProductGroup(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProductGroup(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} ruleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProductServingRule(ruleId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProductServingRule(ruleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllHomeMeasurementRules(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAllHomeMeasurementRulesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllHomeMeasurementRules(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllProductExchangeGroups(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAllProductExchangeGroupsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllProductExchangeGroups(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHomeMeasurementRulesForProduct(productId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetHomeMeasurementRulesForProductResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHomeMeasurementRulesForProduct(productId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNutritionFactDefinitions(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetNutritionFactDefinitionsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNutritionFactDefinitions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetProductByIdResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProductById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} productGroupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductByProductGroup(productGroupId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetProductByProductGroupResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProductByProductGroup(productGroupId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductGroups(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetProductGroupsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProductGroups(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} qtyInGrams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductHomeMeasurement(id: number, qtyInGrams: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetProductHomeMeasurementResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProductHomeMeasurement(id, qtyInGrams, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductRefAndNames(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetProductRefAndNamesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProductRefAndNames(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} dietName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductRefAndNamesByDietName(dietName: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetProductRefAndNamesByDietNameResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProductRefAndNamesByDietName(dietName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductServingRules(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetProductServingRulesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProductServingRules(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProducts(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetProductsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProducts(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} productId 
         * @param {number} dietPlanId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReplacementProduct(productId: number, dietPlanId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetReplacementProductResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReplacementProduct(productId, dietPlanId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {GetUnitOfMeasures} [getUnitOfMeasures] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUnitOfMeasures(getUnitOfMeasures?: GetUnitOfMeasures, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUnitOfMeasuresResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUnitOfMeasures(getUnitOfMeasures, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PostProduct} [postProduct] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postProduct(postProduct?: PostProduct, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostProductResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postProduct(postProduct, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PostProductGroup} [postProductGroup] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postProductGroup(postProductGroup?: PostProductGroup, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostProductGroupResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postProductGroup(postProductGroup, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} productId 
         * @param {PostProductNutritionFact} [postProductNutritionFact] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postProductNutritionFact(productId: number, postProductNutritionFact?: PostProductNutritionFact, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostProductNutritionFactResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postProductNutritionFact(productId, postProductNutritionFact, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PostProductServingRule} [postProductServingRule] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postProductServingRule(postProductServingRule?: PostProductServingRule, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostProductServingRuleResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postProductServingRule(postProductServingRule, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {PutProduct} [putProduct] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putProduct(id: number, putProduct?: PutProduct, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PutProductResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putProduct(id, putProduct, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {PutProductGroup} [putProductGroup] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putProductGroup(id: number, putProductGroup?: PutProductGroup, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PutProductGroupResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putProductGroup(id, putProductGroup, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} productId 
         * @param {number} nutritionFactId 
         * @param {PutProductNutritionFact} [putProductNutritionFact] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putProductNutritionFact(productId: number, nutritionFactId: number, putProductNutritionFact?: PutProductNutritionFact, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PutProductNutritionFactResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putProductNutritionFact(productId, nutritionFactId, putProductNutritionFact, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FoodApi - factory interface
 * @export
 */
export const FoodApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FoodApiFp(configuration)
    return {
        /**
         * 
         * @param {AddOrUpdateCustomProduct} [addOrUpdateCustomProduct] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOrUpdateCustomProduct(addOrUpdateCustomProduct?: AddOrUpdateCustomProduct, options?: any): AxiosPromise<AddOrUpdateCustomProductResponse> {
            return localVarFp.addOrUpdateCustomProduct(addOrUpdateCustomProduct, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AddOrUpdateHomeMeasurementRule} [addOrUpdateHomeMeasurementRule] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOrUpdateHomeMeasurementRule(addOrUpdateHomeMeasurementRule?: AddOrUpdateHomeMeasurementRule, options?: any): AxiosPromise<AddOrUpdateHomeMeasurementRuleResponse> {
            return localVarFp.addOrUpdateHomeMeasurementRule(addOrUpdateHomeMeasurementRule, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProductExchangeGroup} [productExchangeGroup] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOrUpdateProductExchangeGroup(productExchangeGroup?: ProductExchangeGroup, options?: any): AxiosPromise<AddOrUpdateProductExchangeGroupResponse> {
            return localVarFp.addOrUpdateProductExchangeGroup(productExchangeGroup, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AddOrUpdateUnitOfMeasure} [addOrUpdateUnitOfMeasure] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOrUpdateUnitOfMeasure(addOrUpdateUnitOfMeasure?: AddOrUpdateUnitOfMeasure, options?: any): AxiosPromise<AddOrUpdateUnitOfMeasureResponse> {
            return localVarFp.addOrUpdateUnitOfMeasure(addOrUpdateUnitOfMeasure, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AddOrUpdateUoMTextRule} [addOrUpdateUoMTextRule] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOrUpdateUoMTextRule(addOrUpdateUoMTextRule?: AddOrUpdateUoMTextRule, options?: any): AxiosPromise<AddOrUpdateUoMTextRuleResponse> {
            return localVarFp.addOrUpdateUoMTextRule(addOrUpdateUoMTextRule, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteHomeMeasurementRule(id: number, options?: any): AxiosPromise<object> {
            return localVarFp.deleteHomeMeasurementRule(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} productId 
         * @param {number} nutritionFactId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNutritionFact(productId: number, nutritionFactId: number, options?: any): AxiosPromise<object> {
            return localVarFp.deleteNutritionFact(productId, nutritionFactId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProduct(id: number, options?: any): AxiosPromise<object> {
            return localVarFp.deleteProduct(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProductExchangeGroup(id: number, options?: any): AxiosPromise<object> {
            return localVarFp.deleteProductExchangeGroup(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProductGroup(id: number, options?: any): AxiosPromise<object> {
            return localVarFp.deleteProductGroup(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} ruleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProductServingRule(ruleId: number, options?: any): AxiosPromise<object> {
            return localVarFp.deleteProductServingRule(ruleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllHomeMeasurementRules(options?: any): AxiosPromise<GetAllHomeMeasurementRulesResponse> {
            return localVarFp.getAllHomeMeasurementRules(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllProductExchangeGroups(options?: any): AxiosPromise<GetAllProductExchangeGroupsResponse> {
            return localVarFp.getAllProductExchangeGroups(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHomeMeasurementRulesForProduct(productId: number, options?: any): AxiosPromise<GetHomeMeasurementRulesForProductResponse> {
            return localVarFp.getHomeMeasurementRulesForProduct(productId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNutritionFactDefinitions(options?: any): AxiosPromise<GetNutritionFactDefinitionsResponse> {
            return localVarFp.getNutritionFactDefinitions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductById(id: number, options?: any): AxiosPromise<GetProductByIdResponse> {
            return localVarFp.getProductById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} productGroupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductByProductGroup(productGroupId: number, options?: any): AxiosPromise<GetProductByProductGroupResponse> {
            return localVarFp.getProductByProductGroup(productGroupId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductGroups(options?: any): AxiosPromise<GetProductGroupsResponse> {
            return localVarFp.getProductGroups(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {number} qtyInGrams 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductHomeMeasurement(id: number, qtyInGrams: number, options?: any): AxiosPromise<GetProductHomeMeasurementResponse> {
            return localVarFp.getProductHomeMeasurement(id, qtyInGrams, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductRefAndNames(options?: any): AxiosPromise<GetProductRefAndNamesResponse> {
            return localVarFp.getProductRefAndNames(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} dietName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductRefAndNamesByDietName(dietName: string, options?: any): AxiosPromise<GetProductRefAndNamesByDietNameResponse> {
            return localVarFp.getProductRefAndNamesByDietName(dietName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductServingRules(options?: any): AxiosPromise<GetProductServingRulesResponse> {
            return localVarFp.getProductServingRules(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProducts(options?: any): AxiosPromise<GetProductsResponse> {
            return localVarFp.getProducts(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} productId 
         * @param {number} dietPlanId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReplacementProduct(productId: number, dietPlanId: number, options?: any): AxiosPromise<GetReplacementProductResponse> {
            return localVarFp.getReplacementProduct(productId, dietPlanId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GetUnitOfMeasures} [getUnitOfMeasures] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUnitOfMeasures(getUnitOfMeasures?: GetUnitOfMeasures, options?: any): AxiosPromise<GetUnitOfMeasuresResponse> {
            return localVarFp.getUnitOfMeasures(getUnitOfMeasures, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PostProduct} [postProduct] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postProduct(postProduct?: PostProduct, options?: any): AxiosPromise<PostProductResponse> {
            return localVarFp.postProduct(postProduct, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PostProductGroup} [postProductGroup] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postProductGroup(postProductGroup?: PostProductGroup, options?: any): AxiosPromise<PostProductGroupResponse> {
            return localVarFp.postProductGroup(postProductGroup, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} productId 
         * @param {PostProductNutritionFact} [postProductNutritionFact] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postProductNutritionFact(productId: number, postProductNutritionFact?: PostProductNutritionFact, options?: any): AxiosPromise<PostProductNutritionFactResponse> {
            return localVarFp.postProductNutritionFact(productId, postProductNutritionFact, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PostProductServingRule} [postProductServingRule] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postProductServingRule(postProductServingRule?: PostProductServingRule, options?: any): AxiosPromise<PostProductServingRuleResponse> {
            return localVarFp.postProductServingRule(postProductServingRule, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {PutProduct} [putProduct] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putProduct(id: number, putProduct?: PutProduct, options?: any): AxiosPromise<PutProductResponse> {
            return localVarFp.putProduct(id, putProduct, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {PutProductGroup} [putProductGroup] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putProductGroup(id: number, putProductGroup?: PutProductGroup, options?: any): AxiosPromise<PutProductGroupResponse> {
            return localVarFp.putProductGroup(id, putProductGroup, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} productId 
         * @param {number} nutritionFactId 
         * @param {PutProductNutritionFact} [putProductNutritionFact] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putProductNutritionFact(productId: number, nutritionFactId: number, putProductNutritionFact?: PutProductNutritionFact, options?: any): AxiosPromise<PutProductNutritionFactResponse> {
            return localVarFp.putProductNutritionFact(productId, nutritionFactId, putProductNutritionFact, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FoodApi - object-oriented interface
 * @export
 * @class FoodApi
 * @extends {BaseAPI}
 */
export class FoodApi extends BaseAPI {
    /**
     * 
     * @param {AddOrUpdateCustomProduct} [addOrUpdateCustomProduct] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodApi
     */
    public addOrUpdateCustomProduct(addOrUpdateCustomProduct?: AddOrUpdateCustomProduct, options?: AxiosRequestConfig) {
        return FoodApiFp(this.configuration).addOrUpdateCustomProduct(addOrUpdateCustomProduct, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AddOrUpdateHomeMeasurementRule} [addOrUpdateHomeMeasurementRule] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodApi
     */
    public addOrUpdateHomeMeasurementRule(addOrUpdateHomeMeasurementRule?: AddOrUpdateHomeMeasurementRule, options?: AxiosRequestConfig) {
        return FoodApiFp(this.configuration).addOrUpdateHomeMeasurementRule(addOrUpdateHomeMeasurementRule, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProductExchangeGroup} [productExchangeGroup] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodApi
     */
    public addOrUpdateProductExchangeGroup(productExchangeGroup?: ProductExchangeGroup, options?: AxiosRequestConfig) {
        return FoodApiFp(this.configuration).addOrUpdateProductExchangeGroup(productExchangeGroup, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AddOrUpdateUnitOfMeasure} [addOrUpdateUnitOfMeasure] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodApi
     */
    public addOrUpdateUnitOfMeasure(addOrUpdateUnitOfMeasure?: AddOrUpdateUnitOfMeasure, options?: AxiosRequestConfig) {
        return FoodApiFp(this.configuration).addOrUpdateUnitOfMeasure(addOrUpdateUnitOfMeasure, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AddOrUpdateUoMTextRule} [addOrUpdateUoMTextRule] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodApi
     */
    public addOrUpdateUoMTextRule(addOrUpdateUoMTextRule?: AddOrUpdateUoMTextRule, options?: AxiosRequestConfig) {
        return FoodApiFp(this.configuration).addOrUpdateUoMTextRule(addOrUpdateUoMTextRule, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodApi
     */
    public deleteHomeMeasurementRule(id: number, options?: AxiosRequestConfig) {
        return FoodApiFp(this.configuration).deleteHomeMeasurementRule(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} productId 
     * @param {number} nutritionFactId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodApi
     */
    public deleteNutritionFact(productId: number, nutritionFactId: number, options?: AxiosRequestConfig) {
        return FoodApiFp(this.configuration).deleteNutritionFact(productId, nutritionFactId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodApi
     */
    public deleteProduct(id: number, options?: AxiosRequestConfig) {
        return FoodApiFp(this.configuration).deleteProduct(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodApi
     */
    public deleteProductExchangeGroup(id: number, options?: AxiosRequestConfig) {
        return FoodApiFp(this.configuration).deleteProductExchangeGroup(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodApi
     */
    public deleteProductGroup(id: number, options?: AxiosRequestConfig) {
        return FoodApiFp(this.configuration).deleteProductGroup(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} ruleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodApi
     */
    public deleteProductServingRule(ruleId: number, options?: AxiosRequestConfig) {
        return FoodApiFp(this.configuration).deleteProductServingRule(ruleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodApi
     */
    public getAllHomeMeasurementRules(options?: AxiosRequestConfig) {
        return FoodApiFp(this.configuration).getAllHomeMeasurementRules(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodApi
     */
    public getAllProductExchangeGroups(options?: AxiosRequestConfig) {
        return FoodApiFp(this.configuration).getAllProductExchangeGroups(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} productId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodApi
     */
    public getHomeMeasurementRulesForProduct(productId: number, options?: AxiosRequestConfig) {
        return FoodApiFp(this.configuration).getHomeMeasurementRulesForProduct(productId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodApi
     */
    public getNutritionFactDefinitions(options?: AxiosRequestConfig) {
        return FoodApiFp(this.configuration).getNutritionFactDefinitions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodApi
     */
    public getProductById(id: number, options?: AxiosRequestConfig) {
        return FoodApiFp(this.configuration).getProductById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} productGroupId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodApi
     */
    public getProductByProductGroup(productGroupId: number, options?: AxiosRequestConfig) {
        return FoodApiFp(this.configuration).getProductByProductGroup(productGroupId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodApi
     */
    public getProductGroups(options?: AxiosRequestConfig) {
        return FoodApiFp(this.configuration).getProductGroups(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {number} qtyInGrams 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodApi
     */
    public getProductHomeMeasurement(id: number, qtyInGrams: number, options?: AxiosRequestConfig) {
        return FoodApiFp(this.configuration).getProductHomeMeasurement(id, qtyInGrams, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodApi
     */
    public getProductRefAndNames(options?: AxiosRequestConfig) {
        return FoodApiFp(this.configuration).getProductRefAndNames(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} dietName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodApi
     */
    public getProductRefAndNamesByDietName(dietName: string, options?: AxiosRequestConfig) {
        return FoodApiFp(this.configuration).getProductRefAndNamesByDietName(dietName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodApi
     */
    public getProductServingRules(options?: AxiosRequestConfig) {
        return FoodApiFp(this.configuration).getProductServingRules(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodApi
     */
    public getProducts(options?: AxiosRequestConfig) {
        return FoodApiFp(this.configuration).getProducts(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} productId 
     * @param {number} dietPlanId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodApi
     */
    public getReplacementProduct(productId: number, dietPlanId: number, options?: AxiosRequestConfig) {
        return FoodApiFp(this.configuration).getReplacementProduct(productId, dietPlanId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GetUnitOfMeasures} [getUnitOfMeasures] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodApi
     */
    public getUnitOfMeasures(getUnitOfMeasures?: GetUnitOfMeasures, options?: AxiosRequestConfig) {
        return FoodApiFp(this.configuration).getUnitOfMeasures(getUnitOfMeasures, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PostProduct} [postProduct] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodApi
     */
    public postProduct(postProduct?: PostProduct, options?: AxiosRequestConfig) {
        return FoodApiFp(this.configuration).postProduct(postProduct, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PostProductGroup} [postProductGroup] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodApi
     */
    public postProductGroup(postProductGroup?: PostProductGroup, options?: AxiosRequestConfig) {
        return FoodApiFp(this.configuration).postProductGroup(postProductGroup, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} productId 
     * @param {PostProductNutritionFact} [postProductNutritionFact] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodApi
     */
    public postProductNutritionFact(productId: number, postProductNutritionFact?: PostProductNutritionFact, options?: AxiosRequestConfig) {
        return FoodApiFp(this.configuration).postProductNutritionFact(productId, postProductNutritionFact, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PostProductServingRule} [postProductServingRule] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodApi
     */
    public postProductServingRule(postProductServingRule?: PostProductServingRule, options?: AxiosRequestConfig) {
        return FoodApiFp(this.configuration).postProductServingRule(postProductServingRule, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {PutProduct} [putProduct] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodApi
     */
    public putProduct(id: number, putProduct?: PutProduct, options?: AxiosRequestConfig) {
        return FoodApiFp(this.configuration).putProduct(id, putProduct, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {PutProductGroup} [putProductGroup] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodApi
     */
    public putProductGroup(id: number, putProductGroup?: PutProductGroup, options?: AxiosRequestConfig) {
        return FoodApiFp(this.configuration).putProductGroup(id, putProductGroup, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} productId 
     * @param {number} nutritionFactId 
     * @param {PutProductNutritionFact} [putProductNutritionFact] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoodApi
     */
    public putProductNutritionFact(productId: number, nutritionFactId: number, putProductNutritionFact?: PutProductNutritionFact, options?: AxiosRequestConfig) {
        return FoodApiFp(this.configuration).putProductNutritionFact(productId, nutritionFactId, putProductNutritionFact, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ImportApi - axios parameter creator
 * @export
 */
export const ImportApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [categoryCode] 
         * @param {string} [keyword] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFindUsdaProduct: async (categoryCode?: string, keyword?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Import/Import/USDA/Products`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (categoryCode !== undefined) {
                localVarQueryParameter['CategoryCode'] = categoryCode;
            }

            if (keyword !== undefined) {
                localVarQueryParameter['Keyword'] = keyword;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsdaFoodCategories: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Import/Import/USDA/Food/Categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} fdcId 
         * @param {PostCreateProductFromUsdaRecord} [postCreateProductFromUsdaRecord] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCreateProductFromUsdaRecord: async (fdcId: number, postCreateProductFromUsdaRecord?: PostCreateProductFromUsdaRecord, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fdcId' is not null or undefined
            assertParamExists('postCreateProductFromUsdaRecord', 'fdcId', fdcId)
            const localVarPath = `/api/Import/Import/USDA/Products/{FdcId}`
                .replace(`{${"FdcId"}}`, encodeURIComponent(String(fdcId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postCreateProductFromUsdaRecord, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ImportApi - functional programming interface
 * @export
 */
export const ImportApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ImportApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [categoryCode] 
         * @param {string} [keyword] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFindUsdaProduct(categoryCode?: string, keyword?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetFindUsdaProductResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFindUsdaProduct(categoryCode, keyword, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsdaFoodCategories(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUsdaFoodCategoriesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUsdaFoodCategories(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} fdcId 
         * @param {PostCreateProductFromUsdaRecord} [postCreateProductFromUsdaRecord] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postCreateProductFromUsdaRecord(fdcId: number, postCreateProductFromUsdaRecord?: PostCreateProductFromUsdaRecord, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostCreateProductFromUsdaRecordResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postCreateProductFromUsdaRecord(fdcId, postCreateProductFromUsdaRecord, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ImportApi - factory interface
 * @export
 */
export const ImportApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ImportApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [categoryCode] 
         * @param {string} [keyword] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFindUsdaProduct(categoryCode?: string, keyword?: string, options?: any): AxiosPromise<GetFindUsdaProductResponse> {
            return localVarFp.getFindUsdaProduct(categoryCode, keyword, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsdaFoodCategories(options?: any): AxiosPromise<GetUsdaFoodCategoriesResponse> {
            return localVarFp.getUsdaFoodCategories(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} fdcId 
         * @param {PostCreateProductFromUsdaRecord} [postCreateProductFromUsdaRecord] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCreateProductFromUsdaRecord(fdcId: number, postCreateProductFromUsdaRecord?: PostCreateProductFromUsdaRecord, options?: any): AxiosPromise<PostCreateProductFromUsdaRecordResponse> {
            return localVarFp.postCreateProductFromUsdaRecord(fdcId, postCreateProductFromUsdaRecord, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ImportApi - object-oriented interface
 * @export
 * @class ImportApi
 * @extends {BaseAPI}
 */
export class ImportApi extends BaseAPI {
    /**
     * 
     * @param {string} [categoryCode] 
     * @param {string} [keyword] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImportApi
     */
    public getFindUsdaProduct(categoryCode?: string, keyword?: string, options?: AxiosRequestConfig) {
        return ImportApiFp(this.configuration).getFindUsdaProduct(categoryCode, keyword, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImportApi
     */
    public getUsdaFoodCategories(options?: AxiosRequestConfig) {
        return ImportApiFp(this.configuration).getUsdaFoodCategories(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} fdcId 
     * @param {PostCreateProductFromUsdaRecord} [postCreateProductFromUsdaRecord] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImportApi
     */
    public postCreateProductFromUsdaRecord(fdcId: number, postCreateProductFromUsdaRecord?: PostCreateProductFromUsdaRecord, options?: AxiosRequestConfig) {
        return ImportApiFp(this.configuration).postCreateProductFromUsdaRecord(fdcId, postCreateProductFromUsdaRecord, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PaymentApi - axios parameter creator
 * @export
 */
export const PaymentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChannels: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Payment/Channels`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentOrder: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Payment/Order`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerInvoices: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Payment/Invoices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerPayments: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Payment/Payments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDefaultDiscountCodes: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Payment/DefaultDiscountCodes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} invoiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvoiceFile: async (invoiceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invoiceId' is not null or undefined
            assertParamExists('getInvoiceFile', 'invoiceId', invoiceId)
            const localVarPath = `/api/Payment/Invoices/{invoiceId}`
                .replace(`{${"invoiceId"}}`, encodeURIComponent(String(invoiceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrders: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Payment/Orders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} provider 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransactionStatusChanged: async (provider: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'provider' is not null or undefined
            assertParamExists('getTransactionStatusChanged', 'provider', provider)
            const localVarPath = `/api/Payment/{provider}/TransactionStatusChanged`
                .replace(`{${"provider"}}`, encodeURIComponent(String(provider)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PostPaymentRequest} [postPaymentRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        makePayments: async (postPaymentRequest?: PostPaymentRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Payment/MakePayments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postPaymentRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} provider 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postTransactionStatusChanged: async (provider: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'provider' is not null or undefined
            assertParamExists('postTransactionStatusChanged', 'provider', provider)
            const localVarPath = `/api/Payment/{provider}/TransactionStatusChanged`
                .replace(`{${"provider"}}`, encodeURIComponent(String(provider)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PaymentApi - functional programming interface
 * @export
 */
export const PaymentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PaymentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChannels(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPaymentChannelsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getChannels(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCurrentOrder(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCurrentOrderResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCurrentOrder(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomerInvoices(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetInvoicesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomerInvoices(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomerPayments(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPaymentsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomerPayments(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDefaultDiscountCodes(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDefaultDiscountCodesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDefaultDiscountCodes(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} invoiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInvoiceFile(invoiceId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInvoiceFile(invoiceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrders(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetOrdersResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrders(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} provider 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTransactionStatusChanged(provider: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTransactionStatusChanged(provider, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PostPaymentRequest} [postPaymentRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async makePayments(postPaymentRequest?: PostPaymentRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostPaymentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.makePayments(postPaymentRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} provider 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postTransactionStatusChanged(provider: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postTransactionStatusChanged(provider, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PaymentApi - factory interface
 * @export
 */
export const PaymentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PaymentApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChannels(options?: any): AxiosPromise<GetPaymentChannelsResponse> {
            return localVarFp.getChannels(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentOrder(options?: any): AxiosPromise<GetCurrentOrderResponse> {
            return localVarFp.getCurrentOrder(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerInvoices(options?: any): AxiosPromise<GetInvoicesResponse> {
            return localVarFp.getCustomerInvoices(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerPayments(options?: any): AxiosPromise<GetPaymentsResponse> {
            return localVarFp.getCustomerPayments(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDefaultDiscountCodes(options?: any): AxiosPromise<GetDefaultDiscountCodesResponse> {
            return localVarFp.getDefaultDiscountCodes(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} invoiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvoiceFile(invoiceId: number, options?: any): AxiosPromise<any> {
            return localVarFp.getInvoiceFile(invoiceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrders(options?: any): AxiosPromise<GetOrdersResponse> {
            return localVarFp.getOrders(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} provider 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransactionStatusChanged(provider: string, options?: any): AxiosPromise<void> {
            return localVarFp.getTransactionStatusChanged(provider, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PostPaymentRequest} [postPaymentRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        makePayments(postPaymentRequest?: PostPaymentRequest, options?: any): AxiosPromise<PostPaymentResponse> {
            return localVarFp.makePayments(postPaymentRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} provider 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postTransactionStatusChanged(provider: string, options?: any): AxiosPromise<void> {
            return localVarFp.postTransactionStatusChanged(provider, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PaymentApi - object-oriented interface
 * @export
 * @class PaymentApi
 * @extends {BaseAPI}
 */
export class PaymentApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public getChannels(options?: AxiosRequestConfig) {
        return PaymentApiFp(this.configuration).getChannels(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public getCurrentOrder(options?: AxiosRequestConfig) {
        return PaymentApiFp(this.configuration).getCurrentOrder(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public getCustomerInvoices(options?: AxiosRequestConfig) {
        return PaymentApiFp(this.configuration).getCustomerInvoices(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public getCustomerPayments(options?: AxiosRequestConfig) {
        return PaymentApiFp(this.configuration).getCustomerPayments(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public getDefaultDiscountCodes(options?: AxiosRequestConfig) {
        return PaymentApiFp(this.configuration).getDefaultDiscountCodes(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} invoiceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public getInvoiceFile(invoiceId: number, options?: AxiosRequestConfig) {
        return PaymentApiFp(this.configuration).getInvoiceFile(invoiceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public getOrders(options?: AxiosRequestConfig) {
        return PaymentApiFp(this.configuration).getOrders(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} provider 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public getTransactionStatusChanged(provider: string, options?: AxiosRequestConfig) {
        return PaymentApiFp(this.configuration).getTransactionStatusChanged(provider, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PostPaymentRequest} [postPaymentRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public makePayments(postPaymentRequest?: PostPaymentRequest, options?: AxiosRequestConfig) {
        return PaymentApiFp(this.configuration).makePayments(postPaymentRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} provider 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public postTransactionStatusChanged(provider: string, options?: AxiosRequestConfig) {
        return PaymentApiFp(this.configuration).postTransactionStatusChanged(provider, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SearchApi - axios parameter creator
 * @export
 */
export const SearchApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} keyword 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFind: async (keyword: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'keyword' is not null or undefined
            assertParamExists('getFind', 'keyword', keyword)
            const localVarPath = `/api/Search/Find/{Keyword}`
                .replace(`{${"Keyword"}}`, encodeURIComponent(String(keyword)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SearchApi - functional programming interface
 * @export
 */
export const SearchApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SearchApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} keyword 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFind(keyword: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetFindResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFind(keyword, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SearchApi - factory interface
 * @export
 */
export const SearchApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SearchApiFp(configuration)
    return {
        /**
         * 
         * @param {string} keyword 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFind(keyword: string, options?: any): AxiosPromise<GetFindResponse> {
            return localVarFp.getFind(keyword, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SearchApi - object-oriented interface
 * @export
 * @class SearchApi
 * @extends {BaseAPI}
 */
export class SearchApi extends BaseAPI {
    /**
     * 
     * @param {string} keyword 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchApi
     */
    public getFind(keyword: string, options?: AxiosRequestConfig) {
        return SearchApiFp(this.configuration).getFind(keyword, options).then((request) => request(this.axios, this.basePath));
    }
}


