import * as React from 'react';
import * as Redux from 'redux';

import { FileMetadata } from '../../../../files-client';
import { ICurrentMeals } from '../../../molecules/MealCard/Meal/meal-interfaces';
import { IImageFile } from '../../../../shared/interfaces/IImageFile';
import { PartnershipPanelComponent } from './PartnershipPanelComponent';
import { RootState } from '../../../../business/root-store';
import { connect } from 'react-redux';
import { getCurrentMealsSelector } from '../../../molecules/MealCard/Meal/meal-selectors';
import { getRestaurantImagesSelector } from '../../../../business/Restaurants/RestaurantsSelectors';
import { push } from 'connected-react-router';
import { setCurrentMeals } from '../../../molecules/MealCard/Meal/meal';

import { AddToastPayload, actions } from 'react-redux-toastr';
import { AnyAction, Dispatch } from 'redux';
import { Document, Meal, Restaurant } from '../../../../client';
import {
	addOrUpdateRestaurantMeal,
	deleteRestaurantMeal,
	downloadDocuments,
	enableOrDisableRestaurant,
	enableOrDisableRestaurantMeal,
	getPartnershipDocumentByUrl,
	updateRestaurantLocation
} from './PartnershipPanelEpics';
import {
	getPartnershipDocumentSelector,
	getPartnershipEditRestaurantMealSelector,
	getPartnershipRestaurantDocumentsSelector,
	getPartnershipRestaurantSelector
} from '../../../../business/Partnership/PartnershipSelectors';

interface IPartnershipPanelContainerReduxMergedProps {
	meal: Meal;
	document: Document;
	restaurant: Restaurant;
	restaurantDocuments: FileMetadata[];
	restaurantImages: IImageFile[];
	currentMeals: ICurrentMeals;
}

interface IPartnershipPanelContainerOwnProps {}

interface IPartnershipPanelContainerDispatchProps {
	deleteRestaurantMeal: (id: number) => void;
	getPartnershipDocumentByUrl: (documentUrl: string) => void;
	enableOrDisableRestaurant: () => void;
	downloadDocuments: (documentGuid: string, fileName: string) => void;
	navigateTo: (path: string, state?: any) => void;
	toast: (message: AddToastPayload) => void;
	enableOrDisableRestaurantMeal: (mealId: number) => void;
	updateRestaurantLocation: (lat: number, lng: number) => void;
	addOrUpdateRestaurantMeal(meal: Meal): void;
	setCurrentMeals: (currentMeals: ICurrentMeals) => void;
}

interface IPartnershipPanelContainerProps
	extends IPartnershipPanelContainerOwnProps,
		IPartnershipPanelContainerDispatchProps,
		IPartnershipPanelContainerReduxMergedProps {}

interface IPartnershipPanelContainerState {}

class PartnershipInitialContainer extends React.Component<
	IPartnershipPanelContainerProps,
	IPartnershipPanelContainerState
> {
	constructor(props: IPartnershipPanelContainerProps, state: IPartnershipPanelContainerState) {
		super(props, state);

		this.state = this.getInitialState();
	}

	public render(): JSX.Element {
		return (
			<PartnershipPanelComponent
				meal={this.props.meal}
				document={this.props.document}
				restaurant={this.props.restaurant}
				restaurantDocuments={this.props.restaurantDocuments}
				restaurantImages={this.props.restaurantImages}
				downloadDocuments={this.props.downloadDocuments}
				navigateTo={this.props.navigateTo}
				enableOrDisableRestaurant={this.props.enableOrDisableRestaurant}
				toast={this.props.toast}
				setCurrentMeals={this.props.setCurrentMeals}
				currentMeals={this.props.currentMeals}
				enableOrDisableRestaurantMeal={this.props.enableOrDisableRestaurantMeal}
				addOrUpdateRestaurantMeal={this.props.addOrUpdateRestaurantMeal}
				updateRestaurantLocation={this.props.updateRestaurantLocation}
				deleteRestaurantMeal={this.props.deleteRestaurantMeal}
				key={(this.props.restaurant.lat ?? 0) + (this.props.restaurant.lng ?? 0)}
			/>
		);
	}

	public componentDidMount(): void {}

	public static mapStateToProps = (
		state: RootState,
		ownProps: IPartnershipPanelContainerOwnProps
	): IPartnershipPanelContainerReduxMergedProps => {
		return {
			document: getPartnershipDocumentSelector(state),
			restaurant: getPartnershipRestaurantSelector(state),
			restaurantDocuments: getPartnershipRestaurantDocumentsSelector(state),
			meal: getPartnershipEditRestaurantMealSelector(state),
			restaurantImages: getRestaurantImagesSelector(state),
			currentMeals: getCurrentMealsSelector(state)
		};
	};

	public static mapDispatchToProps = (
		dispatch: Dispatch<AnyAction>
	): IPartnershipPanelContainerDispatchProps => {
		return {
			...Redux.bindActionCreators(
				{
					downloadDocuments: downloadDocuments,
					navigateTo: push,
					getPartnershipDocumentByUrl: getPartnershipDocumentByUrl,
					enableOrDisableRestaurant: enableOrDisableRestaurant,
					toast: actions.add,
					enableOrDisableRestaurantMeal: enableOrDisableRestaurantMeal,
					updateRestaurantLocation: updateRestaurantLocation,
					deleteRestaurantMeal: deleteRestaurantMeal,
					addOrUpdateRestaurantMeal: addOrUpdateRestaurantMeal,
					setCurrentMeals: setCurrentMeals
				},
				dispatch
			)
		};
	};

	private getInitialState = (): IPartnershipPanelContainerState => {
		return {} as IPartnershipPanelContainerState;
	};
}
export const PartnershipPanel = connect(
	PartnershipInitialContainer.mapStateToProps,
	PartnershipInitialContainer.mapDispatchToProps
)(PartnershipInitialContainer);
