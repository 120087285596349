import { OrderReducerNamespace } from './interfaces';

export const checkRequiredCashTranferFormAgreements = (
	cashTransferFormAgreements: OrderReducerNamespace.CashTransferFormAgreements
) => Object.values(cashTransferFormAgreements).filter((item) => !item).length === 0;

export const checkAreCashTransferFormValuesAreValid = (
	cashTransferFormValues: OrderReducerNamespace.CashTransferFormValues
) => {
	const requiredCashTransferFormFields = Object.keys(cashTransferFormValues).filter(
		(item: string) => item !== 'flat'
	);

	const requiredCashTransferFormValues = requiredCashTransferFormFields.filter(
		(field: string) => cashTransferFormValues[field].trim() === ''
	);

	return requiredCashTransferFormValues.length === 0;
};
