import { GetApiConfig } from '../../../../business/api-client-config';
import { RootState } from '../../../../business/root-store';
import { ThunkAction } from 'redux-thunk';
import { loadDietPlanDay } from '../../../../business/diet/diet-action';

import { CaloriesWithProductId, DietApi, Meal } from '../../../../client';
import { setCurrentMeals, setMealServingsProposals } from './meal';

export const mealActionTypeName = {
	ON_SET_MEAL_AS_FAVOURITE_REQUEST: 'ON_SET_MEAL_AS_FAVOURITE_REQUEST',
	ON_SET_MEAL_AS_FAVOURITE_RESPONSE: 'ON_SET_MEAL_AS_FAVOURITE_RESPONSE',
	ON_SET_MEAL_AS_FAVOURITE_ERROR: 'ON_SET_MEAL_AS_FAVOURITE_ERROR'
};

//this whole part should be refactored
//whole changes should happens in reducers or components as result of triggered actions
//action should have no side effects

export const setFavouriteMeal =
	(mealId: number): ThunkAction<any, RootState, any, any> =>
	(dispatch, getState) => {
		dispatch({ type: mealActionTypeName.ON_SET_MEAL_AS_FAVOURITE_REQUEST });
		const state = getState();
		const api = new DietApi(GetApiConfig(state));
		api.postFavouriteMealDefinition({ mealId })
			.then(() => {
				const dietDay = state.diet.dietPlanDay!.dietDayId;
				dispatch(loadDietPlanDay(dietDay!));
				dispatch({
					type: mealActionTypeName.ON_SET_MEAL_AS_FAVOURITE_RESPONSE,
					payload: { mealId }
				});
			})
			.catch((c) => {
				dispatch({ type: mealActionTypeName.ON_SET_MEAL_AS_FAVOURITE_ERROR });
			});
	};

export const getMealServingsExchangeProposals =
	(
		caloriesWithProductIds: CaloriesWithProductId[],
		excludedFoodProductsIds: number[]
	): ThunkAction<any, RootState, any, any> =>
	(dispatch, getState) => {
		const state = getState();
		const api = new DietApi(GetApiConfig(state));
		api.getMealServingsExchangeProposals({
			caloriesWithProductIds: caloriesWithProductIds,
			excludedFoodProductsIds: excludedFoodProductsIds
		}).then((x) => {
			if (x.data.mealServingsForProductIds) {
				dispatch(setMealServingsProposals(x.data.mealServingsForProductIds));
			}
		});
	};

export const updateServingInDietPlanDayMeal =
	(meal: Meal): ThunkAction<any, RootState, any, any> =>
	(dispatch, getState) => {
		const state = getState();
		const api = new DietApi(GetApiConfig(state));
		const customerId = state.administration.customerSettings.customerId!;
		const dayId = state.diet.dietPlanDay?.dietDayId!;
		const dietPlandId = state.diet.dietPlanDay?.dietPlanId!;
		const dietDefinitionId = state.diet.dietDefinition?.id!;
		const currentMeals = state.dayView.meal.currentMeals;

		api.postAddOrUpdateDietPlanDayMeals(customerId, {
			dayId: dayId,
			dietPlanId: dietPlandId,
			dietDefinitionId: dietDefinitionId,
			meals: [meal],
			mealTypeEnum: currentMeals.mealType
		}).then((x) => {
			dispatch(
				setCurrentMeals({
					...currentMeals,
					dpdmWithImage: {
						dietPlanDayMeal: {
							...currentMeals.dpdmWithImage!.dietPlanDayMeal,
							mealServings: meal.mealFoodProducts
						},
						imageUrl: currentMeals.dpdmWithImage!.imageUrl
					}
				})
			);
			let caloriesWithProductIds: CaloriesWithProductId[] = [];
			meal.mealFoodProducts?.forEach((x) => {
				caloriesWithProductIds.push({ calories: x.energy, productId: x.foodProductId });
			});
			const excludedFoodProductsIds = meal.mealFoodProducts!.map((x) => x.foodProductId!);
			dispatch(
				getMealServingsExchangeProposals(caloriesWithProductIds, excludedFoodProductsIds)
			);
			dispatch(loadDietPlanDay(dayId));
		});
	};
