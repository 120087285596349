import React from 'react';

class ChevronUpIconComponent extends React.Component {
	public render(): JSX.Element {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="20"
				height="20"
				viewBox="0 0 1024 1024"
				fill="#FD6851"
			>
				<path
					xmlns="http://www.w3.org/2000/svg"
					d="M316.16 657.493333L512 462.08l195.84 195.413333L768 597.333333l-256-256-256 256 60.16 60.16z"
				/>
			</svg>
		);
	}
}

export const ChevronUpIcon = ChevronUpIconComponent;
