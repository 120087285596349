import React from 'react';

export const SupperIcon: React.FC = (props) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 22 22"
			width="20px"
			height="20px"
			style={{ fill: 'rgb(187, 107, 217)' }}
		>
			<path d="M0 0h24v24H0z" fill="none" />
			<path d="M12 2C8.43 2 5.23 3.54 3.01 6L12 22l8.99-16C18.78 3.55 15.57 2 12 2zM7 7c0-1.1.9-2 2-2s2 .9 2 2-.9 2-2 2-2-.9-2-2zm5 8c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z" />
		</svg>
	);
};
