import '../auth.scss';

import * as Yup from 'yup';

import { ActionButton } from '../../../atoms/ActionButton/ActionButton';
import { Headline } from '../../../atoms/headline/headline';
import React from 'react';
import { TextBox } from '../../../atoms/TextBox/TextBox';

import { Field, Form, Formik, FormikHelpers } from 'formik';
import { IResetPasswordFormProps, IResetPasswordFormValues } from './ResetPasswordInterfaces';

const initialValues: IResetPasswordFormValues = {
	mail: ''
};
export const ResetPasswordForm = ({ backToLogin, resetPassword }: IResetPasswordFormProps) => {
	const onSubmit = (
		values: IResetPasswordFormValues,
		helpers: FormikHelpers<IResetPasswordFormValues>
	) => {
		resetPassword && resetPassword(values.mail);
		setTimeout(() => helpers.setSubmitting(false), 2000);
	};
	return (
		<Formik
			initialValues={initialValues}
			validationSchema={Yup.object<
				Partial<Record<keyof IResetPasswordFormValues, Yup.AnySchema>>
			>({
				mail: Yup.string().email('Niepoprawny adres email').required('Wymagane')
			})}
			onSubmit={onSubmit}
		>
			{(formik) => (
				<Form>
					<div className="AuthResetPassword">
						<Headline text="Zresetuj hasło" align="left" />
						<Field
							name="mail"
							label="E-Mail"
							placeholder="np. jan.kowalski@kontakt.com"
							className="AuthRegister__form-row"
							errorString={formik.touched.mail && formik.errors.mail}
							as={TextBox}
						/>
					</div>
					<div className="AuthResetPassword__button-wrapper">
						<ActionButton
							disabled={!formik.isValid}
							errorString={formik.isValid ? undefined : 'Sprawdź błędy w formularzu'}
							type="submit"
							className="AuthResetPassword__button"
							text="Zmień hasło"
						/>
						<ActionButton
							text="Logowanie"
							onClick={backToLogin}
							className="AuthResetPassword__button"
						/>
					</div>
				</Form>
			)}
		</Formik>
	);
};
