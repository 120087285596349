import './checkbox-select.scss';

import { CheckBoxGroup } from '../checkbox-group/checkbox-group';
import { ChevronDownIcon } from '../../../shared/assets/chevron-down-icon';
import { useOutsideClick } from '../../../hooks/use-outside-click';

import React, { useRef, useState } from 'react';

interface ICheckboxSelectProps {
	items: ICheckboxSelectItem[];
	placeholder?: string;
	placement: string;
	onChange: (lastUpdatedItem: string) => void;
}

export interface ICheckboxSelectItem {
	value: string;
	displayName: string;
	icon?: JSX.Element;
	selected?: boolean;
}

export const CheckboxSelect: React.FC<ICheckboxSelectProps> = (props) => {
	const wrapperRef = useRef(null);
	useOutsideClick(wrapperRef, () => {
		setShowDropdown(false);
	});

	const selectedItems = props.items.filter((x) => x.selected);
	const [showDropdown, setShowDropdown] = useState<boolean>(false);
	const handleClick = () => {
		setShowDropdown(!showDropdown);
	};
	return (
		<div className="checkbox-select" ref={wrapperRef}>
			<div className="checkbox-select__trigger" onClick={handleClick}>
				<div>
					{selectedItems.length > 0 ? (
						selectedItems.sort().map((selectedItem) => (
							<span key={selectedItem.displayName} style={{ marginRight: '5px' }}>
								{selectedItem.icon}
							</span>
						))
					) : (
						<span>Wybierz</span>
					)}
				</div>
				<ChevronDownIcon />
			</div>
			{showDropdown && (
				<div
					className={
						'checkbox-select__dropdown ' +
						(props.placement === 'top' ? 'checkbox-select__dropdown--top ' : '') +
						(props.placement === 'bottom' ? 'checkbox-select__dropdown--bottom' : '')
					}
				>
					<CheckBoxGroup
						items={props.items}
						selectedItems={selectedItems.map((x) => {
							return x.value;
						})}
						onChange={props.onChange}
					/>
				</div>
			)}
		</div>
	);
};

CheckboxSelect.defaultProps = {
	placeholder: 'Wybierz'
};
