import { GetApiConfig } from '../../../../business/api-client-config';
import { RootState } from '../../../../business/root-store';
import { ThunkAction } from 'redux-thunk';
import { getCurrentMealsSelector } from '../Meal/meal-selectors';
import { loadDietPlanDay } from '../../../../business/diet/diet-action';
import moment from 'moment';
import { push } from 'connected-react-router';
import { routes } from '../../../../business/router/path-definition';

import { DietApi, MealTypeEnum } from '../../../../client';

export const CopyMeals =
	(mealType: MealTypeEnum, toDayUtc: Date): ThunkAction<any, RootState, any, any> =>
	(dispatch, getState) => {
		const state = getState();
		const api = new DietApi(GetApiConfig(state));
		const dpd = state.diet.dietPlanDay;
		const currentMeals = getCurrentMealsSelector(state);
		api.postCopyDietPlanDayMeals({
			mealIds: currentMeals.meals.map((x) => x.id!),
			fromMealType: currentMeals.mealType,
			toMealType: mealType,
			fromDay: moment(dpd?.dayDateUtc).toISOString(true),
			toDay: toDayUtc.toISOString()
		}).then(() => {
			dispatch(loadDietPlanDay(dpd?.dietDayId!));
			dispatch(push(routes.home.path));
		});
	};
