import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'animate.css';
import './index.css';

import * as serviceWorker from './serviceWorker';

import { App } from './AppContainer';
import { Provider } from 'react-redux';
import React from 'react';
import ReactDOM from 'react-dom';
import ReduxToastr from 'react-redux-toastr';
import { Spinner } from './components/atoms/spinner/SpinnerContainer';
import { store } from './business/root-store';

const startApp = () => {
	ReactDOM.render(
		<Provider store={store}>
			<Spinner />
			<App />
			<ReduxToastr
				timeOut={4000}
				newestOnTop={false}
				preventDuplicates
				position="top-right"
				transitionIn="fadeIn"
				transitionOut="fadeOut"
				closeOnToastrClick
			/>
		</Provider>,
		document.getElementById('root')
	);
};
let wnd = window as any;
if (!wnd.cordova) {
	startApp();
} else {
	document.addEventListener('deviceready', startApp, false);
	serviceWorker.register();
}
