import * as React from 'react';
import * as Redux from 'redux';

import { IImageFile } from '../../../../shared/interfaces/IImageFile';
import { Restaurant } from '../../../../client';
import { RestaurantPageComponent } from './RestaurantPageComponent';
import { RootState } from '../../../../business/root-store';
import { connect } from 'react-redux';
import { getRestaurantByUrl } from '../../../../business/Restaurants/RestaurantsEpics';
import { isSignedIn } from '../../../../business/auth/auth-selector';
import { match } from 'react-router-dom';
import { routes } from '../../../../business/router/path-definition';

import { AnyAction, Dispatch } from 'redux';
import { createMatchSelector, push } from 'connected-react-router';
import {
	getRestaurantImagesSelector,
	getRestaurantSelector
} from '../../../../business/Restaurants/RestaurantsSelectors';

interface IRestaurantPageContainerReduxMergedProps {
	match: match<{ restaurantUrl?: string }>;
	restaurant: Restaurant;
	restaurantImages: IImageFile[];
	isSignedIn: boolean;
}

interface IRestaurantPageContainerOwnProps {}

interface IRestaurantPageContainerDispatchProps {
	getRestaurantByUrl: (restaurantUrl: string) => void;
	navigateTo: (path: string, state?: any) => void;
}

interface IRestaurantPageContainerProps
	extends IRestaurantPageContainerOwnProps,
		IRestaurantPageContainerDispatchProps,
		IRestaurantPageContainerReduxMergedProps {}

interface IRestaurantPageContainerState {}

class RestaurantPageContainer extends React.Component<
	IRestaurantPageContainerProps,
	IRestaurantPageContainerState
> {
	constructor(props: IRestaurantPageContainerProps, state: IRestaurantPageContainerState) {
		super(props, state);
		this.state = this.getInitialState();
		this.props.getRestaurantByUrl(this.props.match.params.restaurantUrl!);
	}

	public render(): JSX.Element {
		return (
			<RestaurantPageComponent
				key={this.props.restaurant.meals?.length}
				restaurant={this.props.restaurant}
				navigateTo={this.props.navigateTo}
				restaurantImages={this.props.restaurantImages}
				isSignedIn={this.props.isSignedIn}
			/>
		);
	}

	public componentDidUpdate(): void {}

	public static mapStateToProps = (
		state: RootState,
		ownProps: IRestaurantPageContainerOwnProps
	): IRestaurantPageContainerReduxMergedProps => {
		const matchSelector = createMatchSelector(routes.restaurants.restaurantPage);

		return {
			match: matchSelector(state)!,
			restaurant: getRestaurantSelector(state),
			restaurantImages: getRestaurantImagesSelector(state),
			isSignedIn: isSignedIn(state)
		};
	};

	public static mapDispatchToProps = (
		dispatch: Dispatch<AnyAction>
	): IRestaurantPageContainerDispatchProps => {
		return {
			...Redux.bindActionCreators(
				{
					getRestaurantByUrl: getRestaurantByUrl,
					navigateTo: push
				},
				dispatch
			)
		};
	};

	private getInitialState = (): IRestaurantPageContainerState => {
		return {} as IRestaurantPageContainerState;
	};
}
export const RestaurantPage = connect(
	RestaurantPageContainer.mapStateToProps,
	RestaurantPageContainer.mapDispatchToProps
)(RestaurantPageContainer);
