import './checkbox-group.scss';

import { CheckBox, ICheckBoxProps } from '../CheckBox/CheckBox';
import React, { InputHTMLAttributes } from 'react';

interface ICheckBoxGroupProps {
	items: ICheckBoxProps[];
	selectedItems: string[];
	onChange: (lastUpdatedItem: string) => void;
}

export const CheckBoxGroup: React.FC<ICheckBoxGroupProps> = (props) => {
	return (
		<>
			{props.items.map((item: ICheckBoxProps & InputHTMLAttributes<HTMLInputElement>) => {
				const value = item.value?.toString() ?? '';
				const status = props.selectedItems.includes(value ?? '');
				return (
					<CheckBox
						displayName={item.displayName}
						classNameContainer="CheckBoxGroup__checkbox"
						key={value}
						checked={status}
						icon={item.icon}
						onChange={() => props.onChange(value)}
					/>
				);
			})}
		</>
	);
};
