import { Reducer } from 'redux';

import { CustomerDietPreference, DietExclusionRule } from '../../client';
import { DietPreferencesAction, customerPreferencesActionNames } from './diet-preferences-action';

export const INITIAL_STATE: CustomerDietPreference = {
	preferenceId: 0,
	customerSettingsId: '',
	dietTypeId: 0,
	dietType: undefined,
	numberOfServingsPerDay: undefined,
	mainMealForTwoDays: undefined,
	dietDayForTwoDays: undefined,
	lunchAndDinnerSameMeal: undefined,
	haveExclusion: undefined,
	dietExclusionRules: [],
	haveTrainingDays: undefined
};
export interface ICustomerDietPreferenceReduxState extends CustomerDietPreference {}

export const dietPreferencesReducer: Reducer<
	ICustomerDietPreferenceReduxState,
	DietPreferencesAction
> = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case customerPreferencesActionNames.Customer_Preferences_Load:
			return preferencesLoad(action);
		case customerPreferencesActionNames.Customer_Exclusions_Confirm:
			return exclusionsConfirm(state, action);
		case customerPreferencesActionNames.Customer_Exclusions_Deny:
			return exclusionsDeny(state, action);
		case customerPreferencesActionNames.Customer_Exclusions_Add:
			return exclusionsAdd(state, action);
		case customerPreferencesActionNames.Customer_Exclusions_Remove:
			return exclusionsRemove(state, action);
		case customerPreferencesActionNames.Customer_Preferences_LunchAndDinnerSameMeal:
			return preferencesSetLunchAndDinnerSameMeal(state, action);
		case customerPreferencesActionNames.Customer_MealsNumber_Set:
			return mealsNumberSet(state, action);
		default:
			return state;
	}
};

const preferencesLoad = (action: any): ICustomerDietPreferenceReduxState => {
	return {
		...action.payload.customerDietPreference
	};
};

const exclusionsDeny = (
	state: CustomerDietPreference,
	action: any
): ICustomerDietPreferenceReduxState => {
	return {
		...state,
		haveExclusion: action.payload.newValue
	};
};

const exclusionsConfirm = (
	state: CustomerDietPreference,
	action: any
): ICustomerDietPreferenceReduxState => {
	return {
		...state,
		haveExclusion: action.payload.newValue
	};
};

const exclusionsAdd = (
	state: CustomerDietPreference,
	action: any
): ICustomerDietPreferenceReduxState => {
	return {
		...state,
		haveExclusion: true,
		dietExclusionRules: [...(state.dietExclusionRules || []), action.payload.exclusionRule]
	};
};

const exclusionsRemove = (
	state: CustomerDietPreference,
	action: any
): ICustomerDietPreferenceReduxState => {
	const exclusions =
		state.dietExclusionRules &&
		state.dietExclusionRules.filter(
			(exc: DietExclusionRule) => exc.foodProductId !== action.payload.productId
		);

	return {
		...state,
		haveExclusion: true,
		dietExclusionRules: exclusions
	};
};

const preferencesSetLunchAndDinnerSameMeal = (
	state: CustomerDietPreference,
	action: any
): ICustomerDietPreferenceReduxState => {
	return {
		...state,
		lunchAndDinnerSameMeal: !action.payload.newValue
	};
};

const mealsNumberSet = (
	state: CustomerDietPreference,
	action: any
): ICustomerDietPreferenceReduxState => {
	return {
		...state,
		numberOfServingsPerDay: action.payload.newValue
	};
};
