import './search-box.scss';

import { CloseIcon } from '../../../shared/assets/close-icon';
import { SearchIcon } from '../../../shared/assets/search-icon';
import { useOutsideClick } from '../../../hooks/use-outside-click';

import React, { useRef } from 'react';

export interface ISearchBoxProps {
	value: string;
	data?: ISearchItem[];
	subData?: ISearchItem[];
	disableFilteringData?: ISearchItem[];
	label?: string;
	disabled?: boolean;
	placeholder?: string;
	activateOnFocus?: boolean;
	className?: string;
	disableSuggestions?: boolean;

	onSelect?: (item: ISearchItem) => void;
	onSearchChanged: (value: string) => void;
	onKeyUp?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
	onBlur?(event: React.FocusEvent<HTMLInputElement, Element>): void;
}

export interface ISearchItem {
	display?: string;
	disabled?: boolean;
	value?: any;
	id: number;
}

export const SearchBox: React.FC<ISearchBoxProps> = (props) => {
	const closeIconSize = 16;
	const [suggestionsVisible, setSuggestionsVisible] = React.useState(false);
	const [suggestions, setSuggestions] = React.useState([] as ISearchItem[]);
	const wrapperRef: any = useRef();
	useOutsideClick(wrapperRef, () => {
		setSuggestionsVisible(false);
	});
	const onSearchChanged = (e: any) => {
		let value = e.target.value as string;
		if (value.length > 0 && props.data) {
			const regex = new RegExp(value.toLocaleLowerCase());
			const filteredSuggestions = props.data.filter((x) =>
				regex.test(x.display?.toLocaleLowerCase() ?? '') ? true : false
			);
			setSuggestions(filteredSuggestions);
		}
		props.onSearchChanged(value);
	};
	const handleOnKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (props.onKeyUp) props.onKeyUp(event);
	};
	const onFocus = (e: any) => {
		if (props.activateOnFocus) {
			setSuggestionsVisible(true);
			if (props.data) {
				let filteredSuggestions: ISearchItem[] = [];
				filteredSuggestions = props.data.sort();
				setSuggestions(filteredSuggestions);
			}
		}
	};

	const suggestionSelected = (item: ISearchItem) => {
		onCloseClicked();
		props.onSelect && props.onSelect(item);
	};

	const onCloseClicked = (): void => {
		setSuggestionsVisible(false);
		setSuggestions([]);
		props.onSearchChanged('');
	};

	const renderSuggestionsList = (items: ISearchItem[], subdata?: boolean): JSX.Element => {
		return (
			<ul className={subdata ? 'searchBox_favourite_list' : ''}>
				{items.map((item: ISearchItem) => {
					let newText = item.display?.replaceAll('_', ' ') || '';
					const regex = new RegExp(`${props.value}`, 'i');
					const results = regex.exec(newText);
					results &&
						results.forEach((element) => {
							newText = newText.replace(element, '<strong>' + element + '</strong>');
						});
					const handleClick = () => {
						suggestionSelected(item);
					};
					return (
						<li
							key={'ItemInListId_' + item.display + item.id}
							onClick={handleClick}
							dangerouslySetInnerHTML={{
								__html: newText
							}}
						/>
					);
				})}
			</ul>
		);
	};

	return (
		<div
			className={props.className ? props.className + ' searchBox' : 'searchBox'}
			ref={wrapperRef}
		>
			<label className="searchBox_label"> {props.label}</label>
			<div className="searchBox_view">
				<SearchIcon className="searchBox_icon" />
				<input
					className="searchBox_input"
					placeholder={props.placeholder}
					disabled={props.disabled}
					value={props.value}
					onKeyUp={handleOnKeyUp}
					onChange={onSearchChanged}
					onFocus={onFocus}
					onBlur={props.onBlur}
				/>
				{props.value.length > 0 && (
					<CloseIcon
						className="searchBox_closeIcon"
						size={closeIconSize}
						onClick={onCloseClicked}
						id="exchangeMeal_closeIcon"
					/>
				)}
			</div>

			{!props.disableSuggestions && suggestionsVisible && (
				<div className="searchBox_result_main">
					<div className="searchBox_result_view">
						<div className="searchBox_result_lists">
							{props.subData && renderSuggestionsList(props.subData, true)}
							{props.data && renderSuggestionsList(suggestions)}
							{props.disableFilteringData &&
								renderSuggestionsList(props.disableFilteringData)}
						</div>
					</div>
				</div>
			)}
		</div>
	);
};
