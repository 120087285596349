import './headline.scss';
import React from 'react';
import Badge, { IBadgeProps } from '../Badge/Badge';

export interface IHeadlineProps {
	align?: string;
	text?: string;
	id?: string;
	subHeadline?: string;
	badge?: IBadgeProps;
}

export const Headline: React.FC<IHeadlineProps> = (props) => {
	return (
		<div className={`headline headline--${props.align}`} id={props.id}>
			{props.badge && (
				<div className="headline__badge">
					<Badge text={props.badge.text} type={props.badge.type} />
				</div>
			)}
			<h2 className="headline__text">{props.text}</h2>
			<h3 className="headline__sub-headline">{props.subHeadline}</h3>
		</div>
	);
};
