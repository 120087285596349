import './preparation-renderer-component.scss';

import { IDpdmWithImage } from '../../../meal-card-interfaces';
import { PreparationIcon } from '../../../../../../shared/assets/preparation-icon';
import { mealEtiquetteExpression } from '../../../../../../shared/helpers';

interface IIngredientsRendererComponentProps {
	mealWithImage: IDpdmWithImage;
}
export const PreparationRendererComponent = (
	props: IIngredientsRendererComponentProps
): JSX.Element => {
	const instructions = props.mealWithImage.dietPlanDayMeal.meal?.instructions!.replace(
		mealEtiquetteExpression,
		''
	);

	return (
		<div id="preparation-renderer-component">
			<div className="preparation-renderer__header">
				<PreparationIcon />
				<h3>Przygotowanie</h3>
			</div>
			<div className="preparation-renderer__content">
				{instructions!.split('\n').map((i: string) => {
					return (
						<div key={i}>
							{i}
							<br />
						</div>
					);
				})}
			</div>
		</div>
	);
};
