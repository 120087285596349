import React from 'react';

export interface ICloseIconProps {
	id?: string;
	size?: number;
	className?: string;
	onClick(): void;
}

export const CloseIcon: React.FC<ICloseIconProps> = (props) => {
	const defaultSize = 20;
	return (
		<div className="cursor-pointer">
			<svg
				id={props.id}
				width={props.size || defaultSize}
				height={props.size || defaultSize}
				viewBox="0 0 20 20"
				fill="none"
				onClick={props.onClick}
				className={props.className}
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M18.2637 2L2.00022 18.2635"
					stroke="#A6A6A6"
					strokeWidth="2"
					strokeLinecap="round"
				/>
				<path
					d="M2 2L18.2635 18.2635"
					stroke="#A6A6A6"
					strokeWidth="2"
					strokeLinecap="round"
				/>
			</svg>
		</div>
	);
};
