import { CustomerApi } from '../../../client';
import { GetApiConfig } from '../../../business/api-client-config';
import { RootState } from '../../../business/root-store';
import { ThunkAction } from 'redux-thunk';
import { documentsUrls } from '../../../shared/shared';
import { push } from 'connected-react-router';

export const postCustomerRequestForm =
	(
		name: string,
		email: string,
		topic: string,
		content: string
	): ThunkAction<any, RootState, any, any> =>
	(dispatch, getState) => {
		const state = getState();
		const api = new CustomerApi(GetApiConfig(state));
		api.postCustomerRequest({
			request: { name: name, mail: email, title: topic, content: content }
		}).then(() => {
			dispatch(push('document/' + documentsUrls.thanks.contact));
		});
	};
