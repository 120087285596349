import './FileUploader.scss';
import ActionLink from '../action-link/action-link';

import React, { InputHTMLAttributes } from 'react';

export interface IFileUploaderProps {
	errorString?: string;
	text?: string;
	className?: string;
}
export const FileUploader = ({
	errorString,
	text,
	className,
	onChange,
	...props
}: IFileUploaderProps & InputHTMLAttributes<HTMLInputElement>) => {
	return (
		<div className={className ? 'FileUploader ' + className : 'FileUploader'}>
			<div className="FileUploader__input">
				<input name="" id="file-input" type="file" onChange={onChange} {...props} />
				<ActionLink text={text} type="button">
					<img src="/img/attachment.svg" alt="attachment.svg" />
				</ActionLink>
			</div>
			{errorString && <span className="FormikError">{errorString}</span>}
		</div>
	);
};
