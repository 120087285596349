import { RestaurantStatus } from '../../client';

export interface RestaurantStatusFilter {
	name: string;
	status: RestaurantStatus;
}

export const RestaurantStatusFilter = {
	None: { name: 'Brak', status: RestaurantStatus.None } as RestaurantStatusFilter,
	FormToVerify: {
		name: 'Oczekuje na zatwierdzenie formularza',
		status: RestaurantStatus.New | RestaurantStatus.ToVerify
	} as RestaurantStatusFilter,
	FormRejected: {
		name: 'Odrzucony formularz',
		status: RestaurantStatus.New | RestaurantStatus.Rejected
	} as RestaurantStatusFilter,
	DocumentsToSign: {
		name: 'Dokumenty czekają na podpisanie',
		status: RestaurantStatus.ToSign
	} as RestaurantStatusFilter,
	DocumentsToVerify: {
		name: 'Oczekuje na zatwierdzenie dokumentów',
		status: RestaurantStatus.ToSign | RestaurantStatus.ToVerify
	} as RestaurantStatusFilter,
	DocumentsRejected: {
		name: 'Odrzucone dokumenty',
		status: RestaurantStatus.ToSign | RestaurantStatus.Rejected
	} as RestaurantStatusFilter,
	ChangesToVerifyPublic: {
		name: 'Oczekuje na zatwierdzenie zmian, publiczna',
		status: RestaurantStatus.ToVerify | RestaurantStatus.Public
	} as RestaurantStatusFilter,
	ChangesToVerifyUnpublic: {
		name: 'Oczekuje na zatwierdzenie zmian, niepubliczna',
		status: RestaurantStatus.ToVerify
	} as RestaurantStatusFilter,
	ChangesRejectedPublic: {
		name: 'Odrzucone zmiany, publiczna',
		status: RestaurantStatus.Verified | RestaurantStatus.Rejected | RestaurantStatus.Public
	} as RestaurantStatusFilter,
	ChangesRejectedUnpublic: {
		name: 'Odrzucone zmiany, niepubliczna',
		status: RestaurantStatus.Verified | RestaurantStatus.Rejected
	} as RestaurantStatusFilter,
	FullWorkingPublic: {
		name: 'W pełni działająca, publiczna',
		status: RestaurantStatus.Verified | RestaurantStatus.Public
	} as RestaurantStatusFilter,
	FullWorkingUnpublic: {
		name: 'W pełni działająca, niepubliczna',
		status: RestaurantStatus.Verified
	} as RestaurantStatusFilter
};

export const getRestaurantStatusName = (status: RestaurantStatus) => {
	return Object.values(RestaurantStatusFilter).find((x) => x.status === status)?.name;
};

export const restaurantCanFullWorking = (status: RestaurantStatus) => {
	return (
		status === RestaurantStatusFilter.FullWorkingPublic.status ||
		status === RestaurantStatusFilter.ChangesRejectedPublic.status ||
		status === RestaurantStatusFilter.ChangesToVerifyPublic.status ||
		status === RestaurantStatusFilter.FullWorkingUnpublic.status ||
		status === RestaurantStatusFilter.ChangesRejectedUnpublic.status ||
		status === RestaurantStatusFilter.ChangesToVerifyUnpublic.status
	);
};
