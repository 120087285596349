import React from 'react';

export const IngredientsIcon: React.FC = (props) => {
	return (
		<React.Fragment>
			<svg
				width="20"
				height="20"
				viewBox="0 0 20 20"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<g clipPath="url(#clip0)">
					<path
						d="M17.5 0H2.5C1.83696 0 1.20107 0.263392 0.732233 0.732233C0.263392 1.20107 0 1.83696 0 2.5L0 17.5C0 18.163 0.263392 18.7989 0.732233 19.2678C1.20107 19.7366 1.83696 20 2.5 20H17.5C18.163 20 18.7989 19.7366 19.2678 19.2678C19.7366 18.7989 20 18.163 20 17.5V2.5C20 1.83696 19.7366 1.20107 19.2678 0.732233C18.7989 0.263392 18.163 0 17.5 0V0ZM18.3333 17.5C18.3333 17.721 18.2455 17.933 18.0893 18.0893C17.933 18.2455 17.721 18.3333 17.5 18.3333H2.5C2.27899 18.3333 2.06702 18.2455 1.91074 18.0893C1.75446 17.933 1.66667 17.721 1.66667 17.5V2.5C1.66667 2.27899 1.75446 2.06702 1.91074 1.91074C2.06702 1.75446 2.27899 1.66667 2.5 1.66667H17.5C17.721 1.66667 17.933 1.75446 18.0893 1.91074C18.2455 2.06702 18.3333 2.27899 18.3333 2.5V17.5Z"
						fill="#D0D2DB"
					/>
					<path
						d="M9.37411 3.74998C9.15302 3.58444 8.87527 3.5134 8.60185 3.55246C8.32843 3.59152 8.08168 3.73749 7.91578 3.95831L6.13745 6.33331L5.73578 5.93248C5.53772 5.75322 5.27835 5.65691 5.0113 5.66344C4.74424 5.66998 4.4899 5.77887 4.30084 5.9676C4.11178 6.15634 4.00246 6.41049 3.99547 6.67754C3.98848 6.94459 4.08436 7.20411 4.26328 7.40248L5.51328 8.65248C5.61961 8.75652 5.74679 8.83681 5.88644 8.88807C6.02609 8.93932 6.17502 8.96037 6.3234 8.94981C6.47178 8.93926 6.61623 8.89735 6.74722 8.82684C6.87821 8.75634 6.99275 8.65885 7.08328 8.54081L9.58328 5.20748C9.74869 4.98642 9.81957 4.70873 9.78035 4.43544C9.74113 4.16215 9.59502 3.9156 9.37411 3.74998Z"
						fill="#D0D2DB"
					/>
					<path
						d="M9.37436 11.25C9.15327 11.0844 8.87552 11.0134 8.6021 11.0525C8.32868 11.0915 8.08193 11.2375 7.91603 11.4583L6.1377 13.8333L5.73603 13.4325C5.63991 13.333 5.52495 13.2537 5.39784 13.1991C5.27074 13.1446 5.13405 13.1159 4.99573 13.1147C4.85742 13.1136 4.72026 13.14 4.59226 13.1924C4.46426 13.2448 4.34798 13.3222 4.2502 13.42C4.15242 13.5178 4.07511 13.6342 4.02277 13.7622C3.97043 13.8902 3.94411 14.0274 3.94535 14.1657C3.9466 14.304 3.97537 14.4407 4.03 14.5678C4.08463 14.6948 4.16401 14.8098 4.26353 14.9058L5.51353 16.1558C5.62027 16.2593 5.74769 16.339 5.88742 16.3898C6.02716 16.4405 6.17605 16.4612 6.32431 16.4503C6.47258 16.4394 6.61688 16.3973 6.74772 16.3268C6.87857 16.2562 6.99301 16.1587 7.08353 16.0408L9.58353 12.7075C9.74894 12.4864 9.81982 12.2087 9.7806 11.9354C9.74138 11.6621 9.59527 11.4156 9.37436 11.25Z"
						fill="#D0D2DB"
					/>
					<path
						d="M15.4167 6.45749H11.6667C11.3904 6.45749 11.1254 6.56724 10.9301 6.76259C10.7347 6.95794 10.625 7.22289 10.625 7.49916C10.625 7.77542 10.7347 8.04037 10.9301 8.23572C11.1254 8.43107 11.3904 8.54082 11.6667 8.54082H15.4167C15.6929 8.54082 15.9579 8.43107 16.1532 8.23572C16.3486 8.04037 16.4583 7.77542 16.4583 7.49916C16.4583 7.22289 16.3486 6.95794 16.1532 6.76259C15.9579 6.56724 15.6929 6.45749 15.4167 6.45749Z"
						fill="#D0D2DB"
					/>
					<path
						d="M15.4167 13.1242H11.6667C11.3904 13.1242 11.1254 13.2339 10.9301 13.4293C10.7347 13.6246 10.625 13.8896 10.625 14.1658C10.625 14.4421 10.7347 14.707 10.9301 14.9024C11.1254 15.0977 11.3904 15.2075 11.6667 15.2075H15.4167C15.6929 15.2075 15.9579 15.0977 16.1532 14.9024C16.3486 14.707 16.4583 14.4421 16.4583 14.1658C16.4583 13.8896 16.3486 13.6246 16.1532 13.4293C15.9579 13.2339 15.6929 13.1242 15.4167 13.1242Z"
						fill="#D0D2DB"
					/>
				</g>
				<defs>
					<clipPath id="clip0">
						<rect width="20" height="20" fill="white" />
					</clipPath>
				</defs>
			</svg>
		</React.Fragment>
	);
};
