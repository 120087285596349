import React from 'react';

interface IDumbbellIconComponentProps {
	onClick?(event: React.SyntheticEvent<any> | undefined): any;
	className?: string;
}

class DumbbellIconComponent extends React.Component<IDumbbellIconComponentProps> {
	constructor(props: IDumbbellIconComponentProps) {
		super(props);
	}

	public render(): JSX.Element {
		return (
			<React.Fragment>
				<div onClick={this.props.onClick}>
					<svg
						width="14"
						height="14"
						viewBox="0 0 14 14"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<g clipPath="url(#clip0_289_5823)">
							<path
								d="M7.3502 1.28684C7.18509 1.12753 6.96402 1.03944 6.7346 1.04154C6.50518 1.04364 6.28576 1.13577 6.12361 1.29808C5.96145 1.46039 5.86953 1.67989 5.86765 1.90932C5.86576 2.13874 5.95406 2.35973 6.11353 2.52468L7.76319 4.17434C7.8177 4.22902 7.8483 4.30306 7.8483 4.38026C7.8483 4.45746 7.8177 4.53151 7.76319 4.58618L4.58811 7.76534C4.56102 7.79251 4.52883 7.81406 4.4934 7.82876C4.45796 7.84346 4.41998 7.85103 4.38161 7.85103C4.34325 7.85103 4.30526 7.84346 4.26983 7.82876C4.23439 7.81406 4.20221 7.79251 4.17511 7.76534L2.52545 6.11568C2.44417 6.0344 2.34768 5.96993 2.24149 5.92594C2.13529 5.88195 2.02147 5.85931 1.90653 5.85931C1.79159 5.85931 1.67777 5.88195 1.57157 5.92594C1.46538 5.96993 1.36889 6.0344 1.28761 6.11568C1.12347 6.27982 1.03125 6.50246 1.03125 6.73459C1.03125 6.96673 1.12347 7.18936 1.28761 7.35351L6.6502 12.7167C6.81434 12.8808 7.03697 12.973 7.26911 12.973C7.50125 12.973 7.72388 12.8808 7.88803 12.7167C8.05218 12.5525 8.14439 12.3299 8.14439 12.0978C8.14439 11.8656 8.05218 11.643 7.88803 11.4788L6.23836 9.82918C6.18368 9.77448 6.15297 9.70031 6.15297 9.62297C6.15297 9.54563 6.18368 9.47146 6.23836 9.41676L9.41344 6.23818C9.46814 6.1835 9.54231 6.15278 9.61965 6.15278C9.69699 6.15278 9.77117 6.1835 9.82586 6.23818L11.4761 7.88784C11.5569 7.97138 11.6534 8.03799 11.7602 8.0838C11.867 8.1296 11.9818 8.15369 12.098 8.15464C12.2142 8.1556 12.3294 8.1334 12.4369 8.08936C12.5444 8.04531 12.6421 7.98029 12.7242 7.8981C12.8063 7.8159 12.8712 7.71818 12.9152 7.61062C12.9591 7.50307 12.9812 7.38784 12.9802 7.27166C12.9791 7.15548 12.9549 7.04067 12.909 6.93394C12.8631 6.82721 12.7964 6.73069 12.7128 6.65001L7.3502 1.28684Z"
								fill="#FD6851"
							/>
							<path
								d="M13.7441 3.96666L12.9193 3.14416C12.8921 3.11706 12.8706 3.08488 12.8559 3.04944C12.8412 3.01401 12.8336 2.97602 12.8336 2.93766C12.8336 2.89929 12.8412 2.86131 12.8559 2.82587C12.8706 2.79044 12.8921 2.75825 12.9193 2.73116C13.1381 2.51232 13.2611 2.21552 13.2611 1.90603C13.2611 1.59655 13.1381 1.29975 12.9193 1.08091C12.7005 0.862072 12.4037 0.739131 12.0942 0.739131C11.7847 0.739131 11.4879 0.862072 11.2691 1.08091C11.2144 1.13559 11.1402 1.1663 11.0628 1.1663C10.9855 1.1663 10.9113 1.13559 10.8566 1.08091L10.0336 0.256076C9.95231 0.17476 9.85585 0.110242 9.74968 0.0662049C9.64351 0.0221679 9.5297 -0.000525568 9.41476 -0.000579737C9.29981 -0.000633905 9.18598 0.0219522 9.07977 0.0658891C8.97355 0.109826 8.87703 0.174253 8.79572 0.255492C8.7144 0.336731 8.64988 0.433191 8.60585 0.539364C8.56181 0.645537 8.53912 0.759344 8.53906 0.874288C8.53901 0.989231 8.56159 1.10306 8.60553 1.20927C8.64947 1.31549 8.7139 1.41201 8.79513 1.49333L12.5063 5.20624C12.6707 5.37039 12.8935 5.46251 13.1258 5.46235C13.3581 5.46218 13.5809 5.36974 13.745 5.20537C13.9092 5.04099 14.0013 4.81813 14.0011 4.58583C14.001 4.35353 13.9085 4.13081 13.7441 3.96666Z"
								fill="#FD6851"
							/>
							<path
								d="M1.49314 8.79432C1.41178 8.71308 1.31522 8.64866 1.20897 8.60474C1.10272 8.56081 0.988862 8.53825 0.873891 8.53833C0.758921 8.53841 0.645092 8.56114 0.538904 8.60521C0.432717 8.64928 0.336249 8.71384 0.25501 8.79519C0.173771 8.87655 0.109352 8.9731 0.0654293 9.07935C0.0215071 9.1856 -0.0010576 9.29947 -0.000976344 9.41444C-0.000895093 9.52941 0.0218305 9.64323 0.0659029 9.74942C0.109975 9.85561 0.174531 9.95208 0.255885 10.0333L1.0813 10.8582C1.13598 10.9128 1.1667 10.987 1.1667 11.0644C1.1667 11.1417 1.13598 11.2159 1.0813 11.2706C0.862465 11.4894 0.739524 11.7862 0.739524 12.0957C0.739524 12.4052 0.862465 12.702 1.0813 12.9208C1.30014 13.1397 1.59694 13.2626 1.90643 13.2626C2.21591 13.2626 2.51272 13.1397 2.73155 12.9208C2.75865 12.8937 2.79083 12.8721 2.82627 12.8574C2.8617 12.8427 2.89969 12.8351 2.93805 12.8351C2.97642 12.8351 3.0144 12.8427 3.04984 12.8574C3.08527 12.8721 3.11746 12.8937 3.14455 12.9208L3.96647 13.7433C4.04686 13.8283 4.14346 13.8962 4.25056 13.9432C4.35767 13.9902 4.47311 14.0152 4.59005 14.0168C4.70699 14.0184 4.82307 13.9965 4.93142 13.9525C5.03977 13.9085 5.13819 13.8432 5.22087 13.7605C5.30355 13.6777 5.36881 13.5793 5.41279 13.4709C5.45677 13.3625 5.47858 13.2465 5.47692 13.1295C5.47527 13.0126 5.45019 12.8972 5.40316 12.7901C5.35614 12.683 5.28812 12.5864 5.20313 12.5061L1.49314 8.79432Z"
								fill="#FD6851"
							/>
						</g>
						<defs>
							<clipPath id="clip0_289_5823">
								<rect width="14" height="14" fill="white" />
							</clipPath>
						</defs>
					</svg>
				</div>
			</React.Fragment>
		);
	}
}
export const DumbbellIcon = DumbbellIconComponent;
