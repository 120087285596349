import { ActionType } from 'typesafe-actions';
import { GetApiConfig } from '../api-client-config';
import { RootState } from '../root-store';
import { ThunkAction } from 'redux-thunk';
import moment from 'moment';

import { DietApi, DietBallanceItem } from '../../client';

export const durinalBalanceActionName = {
	ON_GET_DURINAL_BALANCE_RESPONSE: 'ON_GET_DURINAL_BALANCE_RESPONSE'
} as const;

export const durinalBalanceActions = {
	onGetDietBallanceResponse: (ballance: DietBallanceItem[] | null | undefined) =>
		({
			type: durinalBalanceActionName.ON_GET_DURINAL_BALANCE_RESPONSE,
			payload: { ballance }
		} as const)
};

export type DurinalBalanceAction = ActionType<typeof durinalBalanceActions>;

export const getDietBallance =
	(startDateLocal: Date, endDateLocal: Date): ThunkAction<any, RootState, any, any> =>
	(dispatch, getState) => {
		const state: RootState = getState();
		const api = new DietApi(GetApiConfig(state));
		const dietPlan = state.diet.customerDietPlan!;
		api.getDietBallanceItems(dietPlan.id!, {
			startDate: moment(startDateLocal).toISOString(),
			endDate: moment(endDateLocal).toISOString()
		}).then((response) => {
			dispatch(
				durinalBalanceActions.onGetDietBallanceResponse(response.data.dietBallanceItems)
			);
		});
	};
