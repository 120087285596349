import { List } from 'immutable';
import { Reducer } from 'redux';

import { Beverages_Fetched, IBeveragesFetchedAction } from '../Beverages/beverages-actions';
import {
	BlogDocuments_Fetched,
	DefaultDiscountCodes_Fetched,
	Document_Fetched,
	HmrsForProduct_Fetched,
	IDefaultDiscountCodesFetchedAction,
	IDocumentFetchedAction,
	IHmrsForProductFetchedAction,
	IProductGroupsFetchedAction,
	IUserMealTypesFetchedAction,
	ProductGroups_Fetched
} from './staticDataActions';
import {
	DiscountCode,
	Document,
	HomeMeasurementRule,
	Meal,
	MealExchange,
	MealTypeEnum,
	NutritionFactDefinition,
	ProductGroup,
	ProductRefNamePair
} from '../../../client';
import { IBlogDocumentsFetchedAction, UserMealTypes_Fetched } from './staticDataActions';
import {
	IMealProposalsFetchedAction,
	MealProposals_Fetched
} from '../MealCard/ExchangeMeal/ExchangeMealActions';
import {
	INutritionFactDefinitionsFetchedAction,
	NutritionFactDefinition_Fetched
} from './staticDataActions';
import { IProductsFetchedAction, Products_Fetched } from '../MealCard/CustomMeal/CustomMealActions';

type ReducerActions =
	| IMealProposalsFetchedAction
	| IProductsFetchedAction
	| IBeveragesFetchedAction
	| IUserMealTypesFetchedAction
	| IHmrsForProductFetchedAction
	| IDocumentFetchedAction
	| IDefaultDiscountCodesFetchedAction
	| INutritionFactDefinitionsFetchedAction
	| IProductGroupsFetchedAction
	| IBlogDocumentsFetchedAction;

interface IStaticDataReduxState {
	mealProposals: List<MealExchange>;
	products: List<ProductRefNamePair>;
	beverages: List<Meal>;
	userMealTypes: List<MealTypeEnum>; //todo remove from static.
	hmrsPerProduct: IHmrsPerProduct[];
	document: Document;
	defaultDiscountCodes: DiscountCode[];
	nutritionFactDefinitions: NutritionFactDefinition[];
	productGroups: ProductGroup[];
	blogDocuments: Document[];
}

const INITIAL_STATE: IStaticDataReduxState = {
	mealProposals: List<MealExchange>(),
	products: List<ProductRefNamePair>(),
	beverages: List<Meal>(),
	userMealTypes: List<MealTypeEnum>(),
	hmrsPerProduct: [],
	document: {},
	defaultDiscountCodes: [],
	blogDocuments: [],
	nutritionFactDefinitions: [],
	productGroups: []
};

export interface IHmrsPerProduct {
	productId: number;
	hmrs: List<HomeMeasurementRule>;
}

export const staticDataReducer: Reducer<IStaticDataReduxState, ReducerActions> = (
	state = INITIAL_STATE,
	action
) => {
	switch (action.type) {
		case MealProposals_Fetched:
			return handleMealsFetched(state, action);
		case Products_Fetched:
			return handleProductsFetched(state, action);
		case Beverages_Fetched:
			return handleBeveragesFetched(state, action);
		case UserMealTypes_Fetched:
			return handleUserMealTypesFetched(state, action);
		case NutritionFactDefinition_Fetched:
			return handleNutritionFactDefinitionsFetched(state, action);
		case ProductGroups_Fetched:
			return handleProductGroupsFetched(state, action);
		case DefaultDiscountCodes_Fetched:
			return handleDefaultDiscountCodesFetched(state, action);
		case HmrsForProduct_Fetched:
			return handleHmrsForProductFetched(state, action);
		case Document_Fetched:
			return handleDocumentFetched(state, action);
		case BlogDocuments_Fetched:
			return handleBlogDocumentsFetched(state, action);
		default:
			return state;
	}
};

const handleUserMealTypesFetched = (
	state: IStaticDataReduxState,
	action: IUserMealTypesFetchedAction
): IStaticDataReduxState => {
	return {
		...state,
		userMealTypes: List<MealTypeEnum>(action.userMealTypes)
	};
};

const handleNutritionFactDefinitionsFetched = (
	state: IStaticDataReduxState,
	action: INutritionFactDefinitionsFetchedAction
): IStaticDataReduxState => {
	return {
		...state,
		nutritionFactDefinitions: action.nutritionFactDefinitions
	};
};

const handleProductGroupsFetched = (
	state: IStaticDataReduxState,
	action: IProductGroupsFetchedAction
): IStaticDataReduxState => {
	return {
		...state,
		productGroups: action.productGroups
	};
};

const handleDefaultDiscountCodesFetched = (
	state: IStaticDataReduxState,
	action: IDefaultDiscountCodesFetchedAction
): IStaticDataReduxState => {
	return {
		...state,
		defaultDiscountCodes: action.defaultDiscountCodes
	};
};

const handleDocumentFetched = (
	state: IStaticDataReduxState,
	action: IDocumentFetchedAction
): IStaticDataReduxState => {
	return {
		...state,
		document: action.document
	};
};

const handleBlogDocumentsFetched = (
	state: IStaticDataReduxState,
	action: IBlogDocumentsFetchedAction
): IStaticDataReduxState => {
	return {
		...state,
		blogDocuments: action.documents
	};
};

const handleHmrsForProductFetched = (
	state: IStaticDataReduxState,
	action: IHmrsForProductFetchedAction
): IStaticDataReduxState => {
	return {
		...state,
		hmrsPerProduct: state.hmrsPerProduct.concat({
			hmrs: action.hmrs,
			productId: action.productId
		})
	};
};

const handleMealsFetched = (
	state: IStaticDataReduxState,
	action: IMealProposalsFetchedAction
): IStaticDataReduxState => {
	return {
		...state,
		mealProposals: List<MealExchange>(action.payload.mealProposals)
	};
};

const handleBeveragesFetched = (
	state: IStaticDataReduxState,
	action: IBeveragesFetchedAction
): IStaticDataReduxState => {
	return {
		...state,
		beverages: List<Meal>(action.beverages)
	};
};

const handleProductsFetched = (
	state: IStaticDataReduxState,
	action: IProductsFetchedAction
): IStaticDataReduxState => {
	return {
		...state,
		products: List<ProductRefNamePair>(action.payload.products)
	};
};
