import './TopNavUserMenu.scss';

import { ChevronDownIcon } from '../../../../shared/assets/chevron-down-icon';
import { LogOutIcon } from '../../../../shared/assets/log-out-icon';
import { useOutsideClick } from '../../../../hooks/use-outside-click';
import userCircleIcon from '../../../../shared/assets/svg/user-circle.svg';

import { RefObject, useRef, useState } from 'react';

interface ITopNavMenuProps {
	userName: string;
	onOptionSelect(value: string): void;
}

export const TopNavUserMenu = ({ userName, onOptionSelect }: ITopNavMenuProps) => {
	const [showOptions, setShowOptions] = useState(false);
	const wrapper: RefObject<any> = useRef();

	useOutsideClick(wrapper, () => {
		setShowOptions(false);
	});

	const selectOption = (value: string) => (): void => {
		onOptionSelect(value);
		setShowOptions(false);
	};

	const handleClick = () => {
		setShowOptions(!showOptions);
	};

	return (
		<div className="TopNavUserMenu" ref={wrapper}>
			<div className="TopNavUserMenu__trigger" onClick={handleClick}>
				<div className="TopNavUserMenu__trigger-user">
					<img src={userCircleIcon} alt="User icon" />
					<div className="TopNavUserMenu__user-name">{userName}</div>
				</div>
				<ChevronDownIcon />
			</div>
			{showOptions && (
				<ul className="TopNavUserMenu__options">
					<li onClick={selectOption('account_settings')}>Ustawienia konta</li>
					<li onClick={selectOption('diet_settings')}>Ustawienia diety</li>
					<li onClick={selectOption('logout')}>
						<LogOutIcon /> Wyloguj
					</li>
				</ul>
			)}
		</div>
	);
};
