import { FileApi } from '../../files-client';
import { IImageFile } from '../../shared/interfaces/IImageFile';
import { RootState } from '../root-store';
import { ThunkAction } from 'redux-thunk';

import { AuxiliaryApi, Restaurant } from '../../client';
import { GetApiConfig, GetFileConfig } from '../api-client-config';
import {
	restaurantFetched,
	restaurantImagesFetched,
	restaurantsFetched
} from './RestaurantsActions';

export const getNearbyRestaurants =
	(lat: number, lng: number): ThunkAction<any, RootState, any, any> =>
	(dispatch, getState) => {
		const state = getState();
		const api = new AuxiliaryApi(GetApiConfig(state));
		api.getNearbyRestaurants(lat, lng).then((x) =>
			dispatch(restaurantsFetched(x.data.restaurants ?? []))
		);
	};

export const getRestaurantByUrl =
	(url: string): ThunkAction<any, RootState, any, any> =>
	(dispatch, getState) => {
		const state = getState();
		const api = new AuxiliaryApi(GetApiConfig(state));
		api.getRestaurantByUrlWithPublicMeals(url).then((x) => {
			dispatch(restaurantFetched(x.data.restaurant!));
			dispatch(getRestaurantImages(x.data.restaurant!));
		});
	};

export const getRestaurantImages =
	(restaurant: Restaurant): ThunkAction<any, RootState, any, any> =>
	(dispatch, getState) => {
		const state = getState();
		const fileApi = new FileApi(GetFileConfig(state));
		fileApi.getFilesFromCatalog(restaurant.imagesFileCatalogId!).then((x) => {
			dispatch(
				restaurantImagesFetched({
					metadatas: x.data.fileMetadatas ?? [],
					catalogId: restaurant.imagesFileCatalogId!
				} as IImageFile)
			);
		});
		restaurant.meals?.forEach((meal) =>
			fileApi.getFilesFromCatalog(meal.imagesCatalog!).then((x) => {
				dispatch(
					restaurantImagesFetched({
						metadatas: x.data.fileMetadatas ?? [],
						catalogId: meal.imagesCatalog!
					} as IImageFile)
				);
			})
		);
	};
