import { ActionType } from 'typesafe-actions';
import { INewSubscriptionCreatedNavigationState } from '../router/navigation-states/new-subscription-created';
import { IUserInterviewValues } from '../../components/molecules/UserInterview/UserInterviewInterfaces';
import { RootState } from '../root-store';
import { ThunkAction } from 'redux-thunk';
import { actions } from 'react-redux-toastr';
import { dietObjectiveStringToEnum } from '../../shared/helpers';
import { loadCustomerDietPlan } from './diet-action';
import moment from 'moment';

import {
	Configuration,
	CustomerApi,
	CustomerDietPreference,
	DietExclusionRule,
	DietPlan,
	Gender,
	Product,
	PutServingPreferences
} from '../../client';
import {
	customerSettingsActions,
	retrieveCustomerSettings
} from '../customer/customer-settings-actions';
import { navigationAction, routes } from '../router/path-definition';

export const CannotSavePreferencesError: string =
	'Nie mozna zapisać preferefencji diety, proszę spróbować później';
export const CannotAddExclutionsError: string =
	'Nie mozna dodać wykluczeń do diety, proszę spróbować później';
export const CannotRemoveExclutionsError: string =
	'Nie mozna usunąć wykluczenia z diety, proszę spróbować później';

export const customerPreferencesActionNames = {
	Customer_Preferences_LunchAndDinnerSameMeal:
		'@@Diet/Customer/Preferences/LunchAndDinnerSameMeal/SET',
	Customer_Preferences_Load: '@@Diet/Customer/Preferences/LOAD',
	Customer_Exclusions_Confirm: '@@Diet/Customer/Exclusions/CONFIRM',
	Customer_Exclusions_Deny: '@@Diet/Customer/Exclusions/DENY',
	Customer_Exclusions_Remove: '@@Diet/Customer/Exclusions/REMOVE',
	Customer_Exclusions_Add: '@@Diet/Customer/Exclusions/ADD',
	Customer_MealsNumber_Set: '@@Diet/Customer/MealsNumber/SET',
	Customer_Plan_Set: '@@Diet/Customer/Plan/SET'
} as const;
export const customerPreferencesActions = {
	loadPreferences: (customerDietPreference: CustomerDietPreference) =>
		({
			type: customerPreferencesActionNames.Customer_Preferences_Load,
			payload: { customerDietPreference }
		} as const),
	confirmExclusion: (newValue: boolean) =>
		({
			type: customerPreferencesActionNames.Customer_Exclusions_Confirm,
			payload: { newValue }
		} as const),
	denyExclusion: (newValue: boolean) =>
		({
			type: customerPreferencesActionNames.Customer_Exclusions_Deny,
			payload: { newValue }
		} as const),
	addExclusion: (exclusionRule: DietExclusionRule) =>
		({
			type: customerPreferencesActionNames.Customer_Exclusions_Add,
			payload: { exclusionRule }
		} as const),
	removeExclusion: (productId: number) =>
		({
			type: customerPreferencesActionNames.Customer_Exclusions_Remove,
			payload: { productId }
		} as const),
	setSweet: (lunchAndDinnerSameMeal: boolean) =>
		({
			type: customerPreferencesActionNames.Customer_Preferences_LunchAndDinnerSameMeal,
			payload: { newValue: lunchAndDinnerSameMeal }
		} as const),
	setMeals: (numberOfMeals: number) =>
		({
			type: customerPreferencesActionNames.Customer_MealsNumber_Set,
			payload: { newValue: numberOfMeals }
		} as const),
	setCustomerDietPlan: (customerDietPlan: DietPlan) =>
		({
			type: customerPreferencesActionNames.Customer_Plan_Set,
			payload: { newValue: customerDietPlan }
		} as const)
};

export type DietPreferencesAction = ActionType<typeof customerPreferencesActions>;

export const clientJourneyPutExclusionsForDietPlan =
	(wantExcludeProducts: boolean): ThunkAction<any, RootState, any, any> =>
	(dispatch, getState) => {
		const state = getState();
		const config = new Configuration({ basePath: process.env.REACT_APP_API_URL });
		const api = new CustomerApi(config);
		api.putExclusionsForDietPlan(
			state.administration.customerSettings.customerBasicInformation.settingsId || '',
			{
				wantExcludeProducts
			}
		)
			.then((x) => {
				if (wantExcludeProducts) {
					dispatch(customerPreferencesActions.confirmExclusion(wantExcludeProducts));
				} else {
					dispatch(customerPreferencesActions.denyExclusion(wantExcludeProducts));
				}
			})
			.catch((c) => {});
	};

export const updateUserInterview =
	(values: IUserInterviewValues): ThunkAction<any, RootState, any, any> =>
	(dispatch, getState) => {
		const state = getState();
		const config = new Configuration({ basePath: process.env.REACT_APP_API_URL });
		const api = new CustomerApi(config);
		const settingsId =
			state.administration.customerSettings.customerBasicInformation.settingsId || '';
		api.putUserInterview(settingsId, {
			gender: values.step1.gender === 'Male' ? Gender.Male : Gender.Female,
			height: values.step1.height,
			yearOfBirth: moment().year() - values.step1.age!,
			weight: values.step1.weight,
			friday: values.step2.friday,
			monday: values.step2.monday,
			saturday: values.step2.saturday,
			sunday: values.step2.sunday,
			thursday: values.step2.thursday,
			tuesday: values.step2.tuesday,
			wednesday: values.step2.wednesday,
			objective: dietObjectiveStringToEnum(values.step2.dietObjective),
			wantUseSportMode: values.step2.haveTrainings === 'Yes',
			excludedProductsIds: values.step3.exclusions?.map((x) => x.foodProductId!),
			wantExcludeProducts: values.step3.haveExclusions === 'Yes',
			numberOfServingsPerDay: +values.step4.mealsCount!,
			tags: values.step4.tags,
			dietDayForTwoDays: values.step5.dietDayForTwoDays === 'Yes',
			lunchAndDinnerSameMeal: values.step5.lunchAndDinnerSameMeal === 'Yes',
			mainDishForTwoDays: values.step5.mainMealForTwoDays === 'Yes'
		})
			.then((x) => {
				dispatch(
					actions.add({
						message: 'Poprawnie zapisano ustawienia diety',
						type: 'success'
					})
				);
				dispatch(retrieveCustomerSettings(settingsId));
			})
			.catch((c) => {});
	};

export const clientJourneyDeleteExclusionsForDietPlan =
	(productId: number): ThunkAction<any, RootState, any, any> =>
	(dispatch, getState) => {
		const state = getState();
		const config = new Configuration({ basePath: process.env.REACT_APP_API_URL });
		const api = new CustomerApi(config);
		api.deleteExcludedProduct(
			state.administration.customerSettings.customerBasicInformation.settingsId || '',
			productId
		)
			.then((x) => {
				dispatch(customerPreferencesActions.removeExclusion(productId));
			})
			.catch((c) => {});
	};

export const clientJourneyAddExclusionsForDietPlan =
	(productId: number): ThunkAction<any, RootState, any, any> =>
	(dispatch, getState) => {
		const state = getState();
		const config = new Configuration({ basePath: process.env.REACT_APP_API_URL });
		const api = new CustomerApi(config);
		api.putExcludedProduct(
			state.administration.customerSettings.customerBasicInformation.settingsId || '',
			{
				settingsId:
					state.administration.customerSettings.customerBasicInformation.settingsId,
				productId: productId
			}
		)
			.then((x) => {
				dispatch(
					customerPreferencesActions.addExclusion({
						foodProductId: x.data.foodProductId,
						customerId: x.data.customerId,
						exclude: x.data.exclude,
						dietDefinitionId: x.data.dietDefinitionId,
						name: x.data.name,
						foodProduct: {
							id: x.data.foodProductId,
							name: x.data.foodProductName,
							ref: x.data.foodProductRef
						} as Product
					} as DietExclusionRule)
				);
			})
			.catch((c) => {});
	};

export const clientJourneyPutServingPreferences =
	(
		servingPreferences: PutServingPreferences,
		setFieldValue?: (field: string, value: any, shouldValidate?: boolean | undefined) => void
	): ThunkAction<any, RootState, any, any> =>
	(dispatch, getState) => {
		const state = getState();

		const config = new Configuration({ basePath: process.env.REACT_APP_API_URL });
		const api = new CustomerApi(config);
		api.putServingPreferences(
			state.administration.customerSettings.customerBasicInformation.settingsId || '',
			servingPreferences
		)
			.then((x) => {
				if (
					!(
						x?.data?.customerDietPreference == null ||
						x?.data?.customerDietPreference == undefined
					)
				) {
					dispatch(
						customerPreferencesActions.loadPreferences(x.data.customerDietPreference)
					);
				}
				dispatch(
					customerSettingsActions.loadTagsMealsForDietServings(
						x.data.tagsMealsForDietServings || []
					)
				);
				if (setFieldValue)
					setFieldValue('step4.tags', x.data.tagsMealsForDietServings ?? []);
			})
			.catch((c) => {});
	};

export const clientJourneyPutTrainingPreferences =
	(wantUseTrainingMode: boolean): ThunkAction<any, RootState, any, any> =>
	(dispatch, getState) => {
		const state = getState();

		const config = new Configuration({ basePath: process.env.REACT_APP_API_URL });
		const api = new CustomerApi(config);
		api.postCreateCustomerTrainingSettings(
			state.administration.customerSettings.customerBasicInformation.settingsId || '',
			{
				wantUseSportMode: wantUseTrainingMode,
				sunday: 0,
				monday: 0,
				tuesday: 0,
				wednesday: 0,
				thursday: 0,
				friday: 0,
				saturday: 0
			}
		)
			.then((x) => {
				if (
					!(
						x.data.customerTrainingPreferences == null ||
						x.data.customerTrainingPreferences == undefined
					)
				)
					dispatch(
						customerSettingsActions.loadCustomerTrainingPreferences(
							x.data.customerTrainingPreferences
						)
					);
			})
			.catch((c) => {});
	};

export const clientJourneyPutTrainingDayPreferences =
	(
		wantUseTrainingMode: boolean,
		monday?: number,
		tuesday?: number,
		wednesday?: number,
		thursday?: number,
		friday?: number,
		saturday?: number,
		sunday?: number
	): ThunkAction<any, RootState, any, any> =>
	(dispatch, getState) => {
		const state = getState();

		const config = new Configuration({ basePath: process.env.REACT_APP_API_URL });
		const api = new CustomerApi(config);
		api.postCreateCustomerTrainingSettings(
			state.administration.customerSettings.customerBasicInformation.settingsId || '',
			{
				wantUseSportMode: wantUseTrainingMode,
				sunday: wantUseTrainingMode ? sunday ?? 0 : 0,
				monday: wantUseTrainingMode ? monday ?? 0 : 0,
				tuesday: wantUseTrainingMode ? tuesday ?? 0 : 0,
				wednesday: wantUseTrainingMode ? wednesday ?? 0 : 0,
				thursday: wantUseTrainingMode ? thursday ?? 0 : 0,
				friday: wantUseTrainingMode ? friday ?? 0 : 0,
				saturday: wantUseTrainingMode ? saturday ?? 0 : 0
			}
		)
			.then((x) => {
				if (
					!(
						x.data.customerTrainingPreferences == null ||
						x.data.customerTrainingPreferences == undefined
					)
				)
					dispatch(
						customerSettingsActions.loadCustomerTrainingPreferences(
							x.data.customerTrainingPreferences
						)
					);
			})
			.catch((c) => {});
	};

export const clientJourneyPostCreateDietPlan =
	(): ThunkAction<any, RootState, any, any> => (dispatch, getState) => {
		const state = getState();

		const config = new Configuration({ basePath: process.env.REACT_APP_API_URL });
		const api = new CustomerApi(config);
		api.postCreateDietPlan(
			state.administration.customerSettings.customerBasicInformation.settingsId || '',
			{
				settingsId:
					state.administration.customerSettings.customerBasicInformation.settingsId || ''
			}
		)
			.then((x) => {
				dispatch(loadCustomerDietPlan(x.data.customerId || 0, x.data.dietPlanId || 0));

				api.getCustomerSubs(x.data.customerId || 0)
					.then((data) => {
						dispatch(
							customerSettingsActions.loadSubscriptionData(
								data.data.customerSubs || []
							)
						);
						dispatch(
							loadCustomerDietPlan(
								x.data.customerId || 0,
								data.data.customerSubs![0].id || 0
							)
						);
					})
					.catch((c) => {});

				dispatch(navigationAction.open(routes.home.path));
			})
			.catch((c) => {});
	};

export const clientJourneyPostCreateDietPlanAndRegisterUser =
	(): ThunkAction<any, RootState, any, any> => (dispatch, getState) => {
		const state = getState();

		const config = new Configuration({ basePath: process.env.REACT_APP_API_URL });
		const api = new CustomerApi(config);
		api.postCreateDietPlan(
			state.administration.customerSettings.customerBasicInformation.settingsId || '',
			{
				settingsId:
					state.administration.customerSettings.customerBasicInformation.settingsId || ''
			}
		)
			.then((x) => {
				dispatch(customerSettingsActions.setCustomerId(x.data.customerId!));
				dispatch(
					navigationAction.open(routes.auth.register.path, {
						next: routes.home.path,
						settingsId:
							state.administration.customerSettings.customerBasicInformation
								.settingsId,
						newClient: true,
						customerId: x.data.customerId,
						customerDietPlanId: x.data.dietPlanId
					} as INewSubscriptionCreatedNavigationState)
				);
			})
			.catch((c) => {});
	};
