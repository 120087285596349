import { RootState } from '../../../../business/root-store';
import { createSelector } from '@reduxjs/toolkit';

const rootStateSelector = (state: RootState) => state;

export const getCurrentMealsSelector = createSelector(rootStateSelector, (state: RootState) => {
	return state.dayView.meal.currentMeals;
});

export const getCurrentMealDetailsSelector = createSelector(
	rootStateSelector,
	(state: RootState) => {
		return state.dayView.meal.currentMeals.dpdmWithImage;
	}
);

export const getMealServingsProposalsSelector = createSelector(
	rootStateSelector,
	(state: RootState) => {
		return state.dayView.meal.mealServingsProposals;
	}
);

export const getCustomMealImageSelector = createSelector(rootStateSelector, (state: RootState) => {
	return state.dayView.meal.customMealImage;
});
