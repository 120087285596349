import { List } from 'immutable';

import {
	DiscountCode,
	Document,
	HomeMeasurementRule,
	MealTypeEnum,
	NutritionFactDefinition,
	ProductGroup
} from '../../../client';

export const DefaultDiscountCodes_Fetched = '@@Diet/Static/DefaultDiscountCodes/FETCHED';
export const UserMealTypes_Fetched = '@@Diet/Static/UserMealTypes/FETCHED';
export const NutritionFactDefinition_Fetched = '@@Diet/Static/NutritionFactDefinition/FETCHED';
export const ProductGroups_Fetched = '@@Diet/Static/ProductGroups/FETCHED';
export const Document_Fetched = '@@Diet/Static/Document/FETCHED';
export const BlogDocuments_Fetched = '@@Diet/Static/BlogDocuments/FETCHED';
export const HmrsForProduct_Fetched = '@@Diet/Static/HmrsForProduct/FETCHED';

export interface IUserMealTypesFetchedAction {
	type: typeof UserMealTypes_Fetched;
	userMealTypes: MealTypeEnum[];
}

export const userMealTypesFetched = (
	userMealTypes: MealTypeEnum[]
): IUserMealTypesFetchedAction => ({
	type: UserMealTypes_Fetched,
	userMealTypes: userMealTypes
});

export interface INutritionFactDefinitionsFetchedAction {
	type: typeof NutritionFactDefinition_Fetched;
	nutritionFactDefinitions: NutritionFactDefinition[];
}

export const nutritionFactDefinitionsFetched = (
	nutritionFactDefinitions: NutritionFactDefinition[]
): INutritionFactDefinitionsFetchedAction => ({
	type: NutritionFactDefinition_Fetched,
	nutritionFactDefinitions: nutritionFactDefinitions
});

export interface IProductGroupsFetchedAction {
	type: typeof ProductGroups_Fetched;
	productGroups: ProductGroup[];
}

export const productGroupsFetched = (
	productGroups: NutritionFactDefinition[]
): IProductGroupsFetchedAction => ({
	type: ProductGroups_Fetched,
	productGroups: productGroups
});

export interface IDefaultDiscountCodesFetchedAction {
	type: typeof DefaultDiscountCodes_Fetched;
	defaultDiscountCodes: DiscountCode[];
}

export const defaultDiscountCodesFetched = (
	defaultDiscountCodes: DiscountCode[]
): IDefaultDiscountCodesFetchedAction => ({
	type: DefaultDiscountCodes_Fetched,
	defaultDiscountCodes: defaultDiscountCodes
});

export interface IDocumentFetchedAction {
	type: typeof Document_Fetched;
	document: Document;
}

export const documentFetched = (document: Document): IDocumentFetchedAction => ({
	type: Document_Fetched,
	document: document
});

export interface IBlogDocumentsFetchedAction {
	type: typeof BlogDocuments_Fetched;
	documents: Document[];
}

export const blogDocumentsFetched = (documents: Document[]): IBlogDocumentsFetchedAction => ({
	type: BlogDocuments_Fetched,
	documents: documents
});

export interface IHmrsForProductFetchedAction {
	type: typeof HmrsForProduct_Fetched;
	hmrs: List<HomeMeasurementRule>;
	productId: number;
}

export const hmrsForProductFetched = (
	productId: number,
	hmrs: List<HomeMeasurementRule>
): IHmrsForProductFetchedAction => ({
	type: HmrsForProduct_Fetched,
	productId: productId,
	hmrs: hmrs
});
