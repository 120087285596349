import { AuxiliaryApi } from '../../../client';
import { GetApiConfig } from '../../../business/api-client-config';
import { RootState } from '../../../business/root-store';
import { ThunkAction } from 'redux-thunk';
import { documentFetched } from '../../molecules/staticData/staticDataActions';

export const getDocumentByUrl =
	(documentName: string): ThunkAction<any, RootState, any, any> =>
	(dispatch, getState) => {
		const state = getState();
		const api = new AuxiliaryApi(GetApiConfig(state));
		api.getDocumentByUrl(documentName).then((x) => {
			dispatch(documentFetched(x.data.document!));
		});
	};
