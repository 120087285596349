import './diet-summary-component.scss';

import { ActionButton } from '../../atoms/ActionButton/ActionButton';
import { DietPlanPriceAtom } from '../../atoms/DietPlanPrice/DietPlanPriceComponent';
import { DietSummaryComponentProps } from './interfaces';
import { getMonthsConjugation } from '../../organisms/OrderMenuView/DietSummaryContainer/DietSummaryContainer.auxiliaries';

const scrollToTop = () => {
	window.scrollTo({ behavior: 'smooth', top: 0 });
};

export const DietSummaryComponent = ({
	activeDietPlanPrice,
	dietType,
	isConfirmationDietButtonDisplayed,
	isAnyButtonDisplayed,
	onDietConfirmation,
	displayDietAndPackageTypeView,
	adBanner
}: DietSummaryComponentProps) => {
	return (
		<div
			id="diet-summary-component"
			className="summary-wrapper animate__animated animate__fadeIn"
		>
			<div className="title">{adBanner ? 'Polecamy' : 'Podsumowanie'}</div>
			<div className="content">
				<img src={dietType.imgSrc} alt={`Obrazek diety ${dietType.name}`} />
				<div>Dieta {dietType.name}</div>
				<div>{dietType.description}</div>
			</div>
			<div className="payment-and-plan-summary">
				<DietPlanPriceAtom dietPlanPrice={activeDietPlanPrice} />
				<div>
					abonament na {activeDietPlanPrice.dietPlanDefinition.dietDays! / 30}{' '}
					{getMonthsConjugation(activeDietPlanPrice.dietPlanDefinition.dietDays! / 30)}{' '}
					{activeDietPlanPrice.discountCodePricePerMonth !== undefined
						? activeDietPlanPrice.discountCodePricePerMonth <
						  activeDietPlanPrice.regularPricePerMonth
							? activeDietPlanPrice.discountCode?.priceAfterDiscount
							: activeDietPlanPrice.dietPlanDefinition.priceNet
						: activeDietPlanPrice.dietPlanDefinition.priceNet}{' '}
					zł
				</div>
				{(isAnyButtonDisplayed === undefined || isAnyButtonDisplayed) && !adBanner ? (
					isConfirmationDietButtonDisplayed ? (
						<ActionButton text="Wybieram" onClick={onDietConfirmation} />
					) : (
						<button className="light-button" onClick={displayDietAndPackageTypeView}>
							Zmień rodzaj diety
						</button>
					)
				) : null}
			</div>
			<div className="rounded-button" onClick={scrollToTop}></div>
		</div>
	);
};
