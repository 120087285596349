import { FileInfo } from '../../../../files-client';
import { ICurrentMeals } from './meal-interfaces';
import { Reducer } from 'redux';
import { mealActionTypeName } from './meal-epics';

import {
	CurrentMeals_Set,
	ICurrentDietPlanDayMealsSetAction,
	IMealServingsProposalsSetAction,
	MealServingsProposals_Set
} from './meal';
import {
	CustomMealImage_Removed,
	CustomMealImage_Saved,
	ICustomMealImageRemovedAction,
	ICustomMealImageSavedAction
} from '../CustomMeal/CustomMealActions';
import { MealServingsProposalsForProductId, MealTypeEnum } from '../../../../client';

type ReducerActions =
	| ICurrentDietPlanDayMealsSetAction
	| IMealServingsProposalsSetAction
	| ICustomMealImageSavedAction
	| ICustomMealImageRemovedAction;

export interface IMealReduxState {
	currentMeals: ICurrentMeals;
	mealServingsProposals: MealServingsProposalsForProductId[];
	customMealImage?: { catalogId: number; fileInfos: FileInfo[] };
}

const INITIAL_STATE: IMealReduxState = {
	currentMeals: {
		meals: [],
		mealType: MealTypeEnum.Lunch
	},
	mealServingsProposals: []
};

export const mealReducer: Reducer<IMealReduxState, ReducerActions> = (
	state = INITIAL_STATE,
	action
) => {
	switch (action.type) {
		case CustomMealImage_Saved:
			return handleCustoMealImageSaved(state, action);
		case CustomMealImage_Removed:
			return handleCustoMealImageRemoved(state, action);
		case CurrentMeals_Set:
			return handleSetCurrentDietPlanDayMeals(state, action);
		case MealServingsProposals_Set:
			return handleSetMealServingsProposals(state, action);
		case mealActionTypeName.ON_SET_MEAL_AS_FAVOURITE_RESPONSE: {
			if (state.currentMeals.dpdmWithImage) {
				const isFavourite = !state.currentMeals.dpdmWithImage.dietPlanDayMeal.isFavourite;

				return {
					...state,
					currentMeals: {
						...state.currentMeals,
						dpdmWithImage: {
							...state.currentMeals.dpdmWithImage,
							dietPlanDayMeal: {
								...state.currentMeals.dpdmWithImage.dietPlanDayMeal,
								isFavourite
							}
						}
					}
				};
			}
			return state;
		}
		default:
			return state;
	}
};

const handleSetCurrentDietPlanDayMeals = (
	state: IMealReduxState,
	action: ICurrentDietPlanDayMealsSetAction
): IMealReduxState => {
	return {
		...state,
		currentMeals: action.currentMeals
	};
};

const handleCustoMealImageSaved = (
	state: IMealReduxState,
	action: ICustomMealImageSavedAction
): IMealReduxState => {
	return {
		...state,
		customMealImage: {
			catalogId: action.payload.catalogId,
			fileInfos: action.payload.fileInfos
		}
	};
};

const handleCustoMealImageRemoved = (
	state: IMealReduxState,
	action: ICustomMealImageRemovedAction
): IMealReduxState => {
	return {
		...state,
		customMealImage: undefined
	};
};

const handleSetMealServingsProposals = (
	state: IMealReduxState,
	action: IMealServingsProposalsSetAction
): IMealReduxState => {
	return {
		...state,
		mealServingsProposals: action.mealServings
	};
};
