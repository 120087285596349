import { DietPlanPrice } from '../../../molecules/staticData/staticDataSelectors';
import { IOrderMenuView } from '../interfaces';

const patterns: IOrderMenuView.Patterns = {
	dietDays: '%dietDays%',
	priceNet: '%priceNet%'
};

export const planDescriptions: Record<number, string> = {
	1: `przy jednorazowej płatności za ${patterns.dietDays} miesiąc`,
	2: `przy abonamencie na ${patterns.dietDays} miesiące za ${patterns.priceNet} zł`,
	3: `przy abonamencie na ${patterns.dietDays} miesięcy za ${patterns.priceNet} zł`
};

export const getDietPlanDescription = (dietPlanPrice: DietPlanPrice): string => {
	return planDescriptions[dietPlanPrice.dietPlanDefinition.id!]
		.replace(patterns.dietDays, (dietPlanPrice.dietPlanDefinition.dietDays! / 30).toFixed(0))
		.replace(
			patterns.priceNet,
			(dietPlanPrice.discountCodePricePerMonth !== undefined
				? dietPlanPrice.discountCodePricePerMonth < dietPlanPrice.regularPricePerMonth
					? dietPlanPrice.discountCode?.priceAfterDiscount!
					: dietPlanPrice.dietPlanDefinition.priceNet!
				: dietPlanPrice.dietPlanDefinition.priceNet!
			).toFixed(2)
		);
};
