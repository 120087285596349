import * as React from 'react';
import * as Redux from 'redux';

import { Restaurant } from '../../../client';
import { RestaurantsComponent } from './RestaurantsComponent';
import { RootState } from '../../../business/root-store';
import { connect } from 'react-redux';
import { getNearbyRestaurants } from '../../../business/Restaurants/RestaurantsEpics';
import { getRestaurantsSelector } from '../../../business/Restaurants/RestaurantsSelectors';
import { isSignedIn } from '../../../business/auth/auth-selector';
import { push } from 'connected-react-router';

import { AnyAction, Dispatch } from 'redux';

interface IRestaurantsContainerReduxMergedProps {
	restaurants: Restaurant[];
	isSignedIn: boolean;
}

interface IRestaurantsContainerOwnProps {}

interface IRestaurantsContainerDispatchProps {
	getNearbyRestaurants: (lat: number, lng: number) => void;
	navigateTo: (path: string, state?: any) => void;
}

interface IRestaurantsContainerProps
	extends IRestaurantsContainerOwnProps,
		IRestaurantsContainerDispatchProps,
		IRestaurantsContainerReduxMergedProps {}

interface IRestaurantsContainerState {}

class RestaurantsContainer extends React.Component<
	IRestaurantsContainerProps,
	IRestaurantsContainerState
> {
	constructor(props: IRestaurantsContainerProps, state: IRestaurantsContainerState) {
		super(props, state);

		this.state = this.getInitialState();
	}

	public render(): JSX.Element {
		return (
			<RestaurantsComponent
				restaurants={this.props.restaurants}
				isSignedIn={this.props.isSignedIn}
				navigateTo={this.props.navigateTo}
				getNearbyRestaurants={this.props.getNearbyRestaurants}
			/>
		);
	}

	public componentDidMount(): void {}

	public static mapStateToProps = (
		state: RootState,
		ownProps: IRestaurantsContainerOwnProps
	): IRestaurantsContainerReduxMergedProps => {
		return { restaurants: getRestaurantsSelector(state), isSignedIn: isSignedIn(state) };
	};

	public static mapDispatchToProps = (
		dispatch: Dispatch<AnyAction>
	): IRestaurantsContainerDispatchProps => {
		return {
			...Redux.bindActionCreators(
				{ getNearbyRestaurants: getNearbyRestaurants, navigateTo: push },
				dispatch
			)
		};
	};

	private getInitialState = (): IRestaurantsContainerState => {
		return {} as IRestaurantsContainerState;
	};
}
export const Restaurants = connect(
	RestaurantsContainer.mapStateToProps,
	RestaurantsContainer.mapDispatchToProps
)(RestaurantsContainer);
