import './login-card.scss';

import { AuthCardContainer } from '../../../molecules/AuthCardComponent';
import { AuthTypeKey } from '../../../../business/router/interfaces';
import { ILogin } from '../../../molecules/AuthCardComponent/interfaces';
import { getIsUserRegisteredWhileOrdering } from '../../../../business/order/order.selectors';
import { getUsername } from '../../../../business/auth/auth-selector';
import { routes } from '../../../../business/router/path-definition';
import { actions as toastrActions } from 'react-redux-toastr';

import {
	activateCreatedAccount,
	changePassword,
	handleResetPassword,
	resetPassword
} from '../../../../business/auth/auth-actions';
import {
	onUserLoginWhileOrderingMenu,
	onUserRegisterWhileOrderingMenu
} from '../../../../business/order/order.actions';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

export const LoginCardContainer = () => {
	const isUserLogged = useSelector(getUsername).length > 0;
	const isUserRegistered = useSelector(getIsUserRegisteredWhileOrdering);
	const history = useHistory();
	const dispatch = useDispatch();

	const [isRegistered, setIsRegistered] = useState<boolean | undefined>(false);
	const [formDisplayed, setFormDisplayed] = useState<AuthTypeKey>('register');
	const { authType, guid } = useParams<{ authType?: AuthTypeKey; guid?: string }>();

	const onLogErrorHandler = (message: string) => {
		dispatch(toastrActions.add({ message, type: 'error' }));
	};

	const onUserLoginHandler = (data: ILogin) => {
		const { login, password } = data;
		dispatch(onUserLoginWhileOrderingMenu(login, password));
	};

	const onUserRegistrationHandler = (data: ILogin) => {
		const { login, password } = data;
		dispatch(onUserRegisterWhileOrderingMenu(login, password));
	};

	const resetPasswordHandler = () => {
		history.push(routes.auth.resetPassword.path);
	};
	const backToLogin = () => {
		history.push(routes.auth.login.path);
	};
	const onChangePasswordHandler = (oldPassword: string, newPassword: string) => {
		dispatch(changePassword(oldPassword, newPassword));
	};
	const onResetPasswordHandler = (email: string) => {
		dispatch(resetPassword(email));
	};
	const handleActivateCreatedAccount = (
		newPassword: string,
		retypeNewPassword: string,
		guid: string
	) => {
		dispatch(activateCreatedAccount(newPassword, retypeNewPassword, guid));
	};
	const onHandleResetPassword = (
		newPassword: string,
		retypeNewPassword: string,
		guid: string
	) => {
		dispatch(handleResetPassword(newPassword, retypeNewPassword, guid));
	};

	useEffect(() => {
		if (isUserLogged || isUserRegistered) {
			history.push(routes.customer.order.payment.main.path);
		}
	}, [isUserLogged, isUserRegistered]);

	useEffect(() => {
		if (isRegistered) {
			setFormDisplayed('login');
		} else {
			setFormDisplayed('register');
		}
	}, [isRegistered]);

	return (
		<div id="order-login-card">
			<AuthCardContainer
				formDisplayed={formDisplayed}
				isUserRegistered={isRegistered}
				logError={onLogErrorHandler}
				loginUser={onUserLoginHandler}
				registerUser={onUserRegistrationHandler}
				setIsUserRegistered={setIsRegistered}
				resetPasswordHandler={resetPasswordHandler}
				backToLogin={backToLogin}
				changePassword={onChangePasswordHandler}
				resetPassword={onResetPasswordHandler}
				handleActivateCreatedAccount={handleActivateCreatedAccount}
				handleResetPassword={onHandleResetPassword}
				guid={guid ?? ''}
			/>
		</div>
	);
};
