import './outlined-tale.scss';

interface OutlinedTaleProps {
	isActive?: boolean;
	children?: JSX.Element | string | number;
	onClick?(): void;
}

export const OutlinedTale = ({ isActive, children, onClick }: OutlinedTaleProps) => {
	return (
		<div onClick={onClick} className={`outlined-tale${isActive ? ' active' : ''}`}>
			{children}
		</div>
	);
};
