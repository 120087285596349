import { ActionType } from 'typesafe-actions';
import { AuthenticationApi } from '../../auth-client';
import { DietPlanPrice } from '../../components/molecules/staticData/staticDataSelectors';
import { OrderReducerNamespace } from './interfaces';
import { RootState } from '../root-store';
import { ThunkAction } from 'redux-thunk';
import { authenticationActions } from '../auth/auth-actions';
import { getUsername } from '../auth/auth-selector';
import { actions as toastrActions } from 'react-redux-toastr';

import {
	CustomerApi,
	DietDefinition,
	GetPaymentChannelsResponse,
	PaymentApi,
	PaymentChannel,
	PostPaymentRequest,
	PostPaymentResponse
} from '../../client';
import { GetApiConfig, GetAuthConfig } from '../api-client-config';

export const orderActionName = {
	ON_GET_PAYMENT_CHANNELS_RESPONSE: 'ON_GET_PAYMENT_CHANNELS_RESPONSE',
	ON_GET_MAKE_PAYMENTS_RESPONSE: 'ON_GET_MAKE_PAYMENTS_RESPONSE',
	ON_SET_ACTIVE_DIET_PLAN: 'ON_SET_ACTIVE_DIET_PLAN',
	ON_SET_ACTIVE_DIET_TYPE: 'ON_SET_ACTIVE_DIET_TYPE',
	ON_SET_ACTIVE_CHANNEL: 'ON_SET_ACTIVE_CHANNEL',
	ON_CASH_TRANSFER_FIELD_VALUE_CHANGE: 'ON_CASH_TRANSFER_FIELD_VALUE_CHANGE',
	ON_CASH_TRANSFER_FORM_AGREEMENT_CHANGE: 'ON_CASH_TRANSFER_FORM_AGREEMENT_CHANGE',
	ON_SET_INITIAL_STATE: 'ON_SET_INITIAL_STATE',
	ON_USER_LOGIN_WHILE_ORDERING: 'ON_USER_LOGIN_WHILE_ORDERING',
	ON_SET_PAYMENT_FORM: 'ON_SET_PAYMENT_FORM',
	ON_USER_REGISTRATION_WHILE_ORDERING: 'ON_USER_REGISTRATION_WHILE_ORDERING'
} as const;

export const orderActions = {
	onSetPaymentForm: (formValues: OrderReducerNamespace.CashTransferFormValues) => ({
		type: orderActionName.ON_SET_PAYMENT_FORM,
		payload: { formValues }
	}),
	onLoginWhileOrdering: (username: string) => ({
		type: orderActionName.ON_USER_LOGIN_WHILE_ORDERING,
		payload: { username }
	}),
	onRegistrationWhileOrdering: (username: string, userId: number) => ({
		type: orderActionName.ON_USER_REGISTRATION_WHILE_ORDERING,
		payload: { username, userId }
	}),
	onSetInitialState: () => ({ type: orderActionName.ON_SET_INITIAL_STATE }),
	onCashTransferFormAgreementChange: (agreementKey: string, value: boolean) => ({
		type: orderActionName.ON_CASH_TRANSFER_FORM_AGREEMENT_CHANGE,
		payload: { agreementKey, value }
	}),
	onCashTransferFormValueChange: (fieldKey: string, value: string) => ({
		type: orderActionName.ON_CASH_TRANSFER_FIELD_VALUE_CHANGE,
		payload: { fieldKey, value }
	}),
	onSetActiveChannel: (activeChannel: PaymentChannel) =>
		({ type: orderActionName.ON_SET_ACTIVE_CHANNEL, payload: { activeChannel } } as const),
	onPaymentChannelsResponse: (paymentChannelResponse: GetPaymentChannelsResponse) =>
		({
			type: orderActionName.ON_GET_PAYMENT_CHANNELS_RESPONSE,
			payload: paymentChannelResponse
		} as const),
	onMakePaymentResponse: (postPaymentResponse: PostPaymentResponse) =>
		({
			type: orderActionName.ON_GET_MAKE_PAYMENTS_RESPONSE,
			payload: postPaymentResponse
		} as const),
	onSetActiveDietPlan: (dietPlan: DietPlanPrice) =>
		({ type: orderActionName.ON_SET_ACTIVE_DIET_PLAN, payload: { dietPlan } } as const),
	onSetActiveDietType: (dietType: DietDefinition) =>
		({ type: orderActionName.ON_SET_ACTIVE_DIET_TYPE, payload: { dietType } } as const)
};

export type OrderAction = ActionType<typeof orderActions>;

export const getPymentChanels =
	(): ThunkAction<any, RootState, any, any> => (dispatch, getState) => {
		const store: RootState = getState();
		const api = new PaymentApi(GetApiConfig(store));

		api.getChannels().then((x) => {
			dispatch(orderActions.onPaymentChannelsResponse(x.data));
		});
	};

export const makePayment =
	(paymentData: PostPaymentRequest): ThunkAction<any, RootState, any, any> =>
	(dispatch, getState) => {
		const store: RootState = getState();
		const api = new PaymentApi(GetApiConfig(store));
		paymentData.customerMail = getUsername(store);
		if (!paymentData.customerMail) {
			paymentData.customerMail = store.order.cashTransferFormValues.email;
		}
		paymentData.userId = store.order.userId;
		api.makePayments(paymentData).then((x) => {
			dispatch(orderActions.onMakePaymentResponse(x.data));
		});
	};

export const onUserLoginWhileOrderingMenu =
	(username: string, password: string): ThunkAction<any, RootState, any, any> =>
	(dispatch, getState) => {
		const api = new AuthenticationApi(GetAuthConfig(getState()));
		api.login({ username, password })
			.then((data) => {
				if (data.data.authData && data.data.authData.token) {
					const { token } = data.data.authData;
					dispatch(authenticationActions.logInAction({ login: username, token }));
					dispatch(toastrActions.add({ message: 'Zalogowano', type: 'success' }));
					dispatch(orderActions.onLoginWhileOrdering(username));
				}
				return false;
			})
			.catch((c) => {
				dispatch(authenticationActions.logInAction({ login: '', token: '' }));
				dispatch(toastrActions.add({ message: 'Logowanie nieudane', type: 'error' }));
			});
	};

export const onUserRegisterWhileOrderingMenu =
	(username: string, password: string): ThunkAction<any, RootState, any, any> =>
	(dispatch, getState) => {
		const api = new AuthenticationApi(GetAuthConfig(getState()));
		api.register({ email: username, password, username })
			.then((data) => {
				dispatch(toastrActions.add({ message: 'Utworzono konto', type: 'success' }));
				dispatch(
					orderActions.onRegistrationWhileOrdering(username, +data.data.authData?.userId!)
				);
			})
			.catch((c) => {
				dispatch(toastrActions.add({ message: 'Nie stworzono konta', type: 'error' }));
			});
	};

export const getPaymentInfoFromLoggedCustomer =
	(): ThunkAction<any, RootState, any, any> => (dispatch, getState) => {
		const state = getState();
		const api = new CustomerApi(GetApiConfig(state));
		if (state.administration.customerSettings.customerId) {
			api.getCustomer(state.administration.customerSettings.customerId).then((data) => {
				const customer = data.data.customer!;
				dispatch(
					orderActions.onSetPaymentForm({
						email: customer.mail!,
						city: customer.city ?? '',
						firstName: customer.name ?? '',
						flat: customer.apartmentNumber ?? '',
						house: customer.homeNumber ?? '',
						postCode: customer.postcode ?? '',
						street: customer.street ?? '',
						surname: customer.surname ?? ''
					})
				);
			});
		}
	};
