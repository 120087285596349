import * as React from 'react';
import * as Redux from 'redux';

import { ICurrentMeals } from './Meal/meal-interfaces';
import { IDpdmWithImage } from './meal-card-interfaces';
import { MealCardComponent } from './meal-card-component';
import { RootState } from '../../../business/root-store';
import { connect } from 'react-redux';
import { getImagesForDietPlanDayMeals } from './meal-card-selectors';
import { processEatenMeal } from './meal-card-epics';
import { push } from 'connected-react-router';
import { setCurrentMeals } from './Meal/meal';

import { AnyAction, Dispatch } from 'redux';
import { DietPlanDayMeal, MealTypeEnum } from '../../../client';
import {
	INutritionFactsSummary,
	getNutritionFactsFromDietPlanDayMeals
} from '../../../shared/helpers';

interface IMealCardContainerReduxMergedState {
	mealsWithImages: IDpdmWithImage[];
	summary: INutritionFactsSummary;
}

export interface IMealCardContainerOwnProps {
	meals: DietPlanDayMeal[];
	title: string;
	mealType: MealTypeEnum;
	currentMeals: ICurrentMeals;
	onCopyClicked: (value: boolean) => void;
	onExistingMealModalClicked: (value: boolean) => void;
	onCustomMealModalClicked: (value: boolean) => void;
}

interface IDayCardContainerProps
	extends IMealCardContainerOwnProps,
		IMealCardContainerDispatch,
		IMealCardContainerReduxMergedState {}

interface IMealCardContainerDispatch {
	navigateTo: (path: string, state?: any) => void;
	setCurrentMeals: (currentMeals: ICurrentMeals) => void;
	onProcessEatenMeal: (mealType: MealTypeEnum) => void;
}

class MealCardContainer extends React.PureComponent<IDayCardContainerProps> {
	constructor(props: IDayCardContainerProps) {
		super(props);
	}

	public render(): JSX.Element {
		return (
			<MealCardComponent
				title={this.props.title}
				mealType={this.props.mealType}
				meals={this.props.mealsWithImages}
				summary={this.props.summary}
				currentMeals={this.props.currentMeals}
				navigateTo={this.props.navigateTo}
				setCurrentMeals={this.props.setCurrentMeals}
				onProcessEatenMeal={this.props.onProcessEatenMeal}
				onCopyClicked={this.props.onCopyClicked}
				onExistingMealModalClicked={this.props.onExistingMealModalClicked}
				onCustomMealModalClicked={this.props.onCustomMealModalClicked}
			/>
		);
	}
	public static mapStateToProps = (
		state: RootState,
		ownProps: IMealCardContainerOwnProps
	): IMealCardContainerReduxMergedState => {
		return {
			mealsWithImages: getImagesForDietPlanDayMeals(state, ownProps),
			summary: getNutritionFactsFromDietPlanDayMeals(ownProps.meals)
		};
	};

	public static mapDispatchToProps = (
		dispatch: Dispatch<AnyAction>
	): IMealCardContainerDispatch => {
		return {
			...Redux.bindActionCreators(
				{
					navigateTo: push,
					setCurrentMeals: setCurrentMeals,
					onProcessEatenMeal: processEatenMeal
				},
				dispatch
			)
		};
	};
}

export const MealCard = connect(
	MealCardContainer.mapStateToProps,
	MealCardContainer.mapDispatchToProps
)(MealCardContainer);
