import * as React from 'react';
import * as Redux from 'redux';

import { INutritionFactsSummary } from '../../../../shared/helpers';
import { MealCardHeaderComponent } from './meal-card-header-component';
import { RootState } from '../../../../business/root-store';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { setFavouriteMeal } from '../Meal/meal-epics';

import { AnyAction, Dispatch } from 'redux';

export interface IMealHeaderContainerOwnProps {
	header: string;
	mealId: number;
	isFavouriteMeal: boolean;
	showFavourite: boolean;
	summary: INutritionFactsSummary;
	onCopy(): void;
}

interface IMealHeaderContainerDispatchProps {
	navigateTo: (path: string, state?: any) => void;
	onFavouriteClicked: (dietPlanDayMealId: number) => void;
}

interface IMealHeaderContainerReduxMergedState {}

interface IMealHeaderContainerProps
	extends IMealHeaderContainerOwnProps,
		IMealHeaderContainerDispatchProps,
		IMealHeaderContainerReduxMergedState {}

class MealCardHeaderContainer extends React.Component<IMealHeaderContainerProps> {
	constructor(props: IMealHeaderContainerProps) {
		super(props);
	}

	public componentDidMount(): void {}

	public render(): JSX.Element {
		return (
			<MealCardHeaderComponent
				header={this.props.header}
				isFavouriteMeal={this.props.isFavouriteMeal}
				showFavourite={this.props.showFavourite}
				onCopyClicked={this.props.onCopy}
				onRefreshClicked={this.handleRefreshClicked}
				onFavouriteClicked={this.handleFavouriteClicked}
				summary={this.props.summary}
			/>
		);
	}

	private handleFavouriteClicked = (): void => {
		this.props.onFavouriteClicked(this.props.mealId);
	};

	private handleRefreshClicked = (): void => {};

	public static mapStateToProps = (
		state: RootState,
		ownProps: IMealHeaderContainerOwnProps
	): IMealHeaderContainerReduxMergedState => {
		return {};
	};

	public static mapDispatchToProps = (
		dispatch: Dispatch<AnyAction>
	): IMealHeaderContainerDispatchProps => {
		return {
			...Redux.bindActionCreators(
				{
					navigateTo: push,
					onFavouriteClicked: setFavouriteMeal
				},
				dispatch
			)
		};
	};
}
export const MealCardHeader = connect(
	MealCardHeaderContainer.mapStateToProps,
	MealCardHeaderContainer.mapDispatchToProps
)(MealCardHeaderContainer);
