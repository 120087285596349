import './TopNav.scss';

import { ActionButton } from '../../atoms/ActionButton/ActionButton';
import { Link } from 'react-router-dom';
import React from 'react';
import { TopNavUserMenu } from './TopNavUserMenu/TopNavUserMenu';
import { authenticationActions } from '../../../business/auth/auth-actions';
import { documentsUrls } from '../../../shared/shared';

import {
	createUrlWithParams,
	navigationAction,
	routes
} from '../../../business/router/path-definition';
import { getUsername, isSignedIn } from '../../../business/auth/auth-selector';
import { useDispatch, useSelector } from 'react-redux';

export interface ITopNavBarProps {
	onOpenMenu(): void;
}
export const TopNav: React.FC<ITopNavBarProps> = (props) => {
	const dispatch = useDispatch();

	const isLoggedIn = useSelector(isSignedIn);
	const userName = useSelector(getUsername);

	const login = () => {
		dispatch(navigationAction.open(routes.auth.login.path));
	};

	const register = () => {
		dispatch(navigationAction.open(routes.auth.register.path));
	};

	const logout = () => {
		dispatch(authenticationActions.logOutAction());
		dispatch(navigationAction.open(routes.home.path));
	};

	const handleClickDietSettings = () => {
		dispatch(navigationAction.open(routes.customer.settings.diet.path));
	};

	const handleClickAccountSettings = () => {
		dispatch(navigationAction.open(routes.customer.settings.account.path));
	};

	const homeClick = () => {
		dispatch(navigationAction.open(routes.home.path));
	};

	const onUserMenuOptionSelect = (value: string): void => {
		switch (value) {
			case 'account_settings':
				handleClickAccountSettings();
				break;
			case 'diet_settings':
				handleClickDietSettings();
				break;
			case 'logout':
				logout();
				break;
		}
	};

	return (
		<>
			<div className="top-nav-mobile style-mobile">
				<img className="top-nav__home" src="\img\logo.svg" alt="logo" onClick={homeClick} />
				<svg
					className="top-nav__menu-icon"
					width="30"
					height="23"
					viewBox="0 0 30 23"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
					onClick={props.onOpenMenu}
				>
					<line
						x1="1.5"
						y1="1.5"
						x2="28.5"
						y2="1.5"
						stroke="#B0B4C4"
						strokeWidth="3"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<line
						x1="1.5"
						y1="11.5"
						x2="28.5"
						y2="11.5"
						stroke="#B0B4C4"
						strokeWidth="3"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<line
						x1="1.5"
						y1="21.5"
						x2="28.5"
						y2="21.5"
						stroke="#B0B4C4"
						strokeWidth="3"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</svg>
			</div>

			<div className="top-nav style-desktop">
				<div className="top-nav__left">
					<img
						className="top-nav__logo"
						src="\img\logo.svg"
						alt="logo"
						onClick={homeClick}
					/>
					<a href="#" className="top-nav__href" onClick={homeClick}>
						Home
					</a>
					<Link to={routes.static.blog.path} className="top-nav__href">
						Blog
					</Link>
					{/* <Link to={routes.customer.meal.analyze} className="top-nav__href">
						Analiza posiłku
					</Link> */}
					<Link className="top-nav__href" to={'/restaurants'}>
						Zjadłem na mieście
					</Link>
					<Link
						to={createUrlWithParams(routes.static.document.path, {
							documentUrl: documentsUrls.getstarted
						})}
						className="top-nav__href"
					>
						Jak to działa
					</Link>
				</div>
				<div className="top-nav__right">
					{!isLoggedIn && (
						<>
							<a className="top-nav__href" onClick={register}>
								Załóż konto
							</a>
							<ActionButton
								className="top-nav__href"
								text="Zaloguj"
								style={{ width: '200px' }}
								styleType="secondary"
								onClick={login}
							/>
						</>
					)}
					{isLoggedIn && (
						<TopNavUserMenu
							userName={userName}
							onOptionSelect={onUserMenuOptionSelect}
						/>
					)}
				</div>
			</div>
		</>
	);
};
