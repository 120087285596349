import './ScrollProgressBar.scss';

import React from 'react';

interface IScrollProgressBarProps {}

interface IScrollProgressBarState {
	scrolled: string;
}

export class ScrollProgressBar extends React.Component<
	IScrollProgressBarProps,
	IScrollProgressBarState
> {
	constructor(props: IScrollProgressBarProps, state: IScrollProgressBarState) {
		super(props, state);

		this.state = this.getInitialState();
	}
	componentDidMount() {
		window.addEventListener('scroll', this.scrollProgress);
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this.scrollProgress);
	}
	public render(): JSX.Element {
		const progressBarStyle = {
			width: this.state.scrolled
		};
		return (
			<div className="progressBar-container">
				<div className="progressBar" style={progressBarStyle}></div>
			</div>
		);
	}

	scrollProgress = () => {
		const scrollPx = document.documentElement.scrollTop;
		const winHeightPx =
			document.documentElement.scrollHeight - document.documentElement.clientHeight;
		const scrolled = `${(scrollPx / winHeightPx) * 100}%`;
		this.setState({
			...this.state,
			scrolled: scrolled
		});
	};

	private getInitialState = (): IScrollProgressBarState => {
		return { scrolled: 'fit-content' } as IScrollProgressBarState;
	};
}
