import { AuthView } from './components/organisms/AuthView';
import { Beverages } from './components/molecules/Beverages/beverages-container';
import { BeveragesEditor } from './components/molecules/Beverages/BeverageEditor/BeveragesEditorContainer';
import { Blog } from './components/organisms/Blog/BlogContainer';
import { ConnectedRouter } from 'connected-react-router';
import { CopyDay } from './components/molecules/MealCard/CopyDay/CopyDayContainer';
import { CopyMeal } from './components/molecules/MealCard/CopyMeal/CopyMealContainer';
import { CustomMeal } from './components/molecules/MealCard/CustomMeal/CustomMealContainer';
import { DayView } from './components/organisms/DayView';
import { DietSettings } from './components/organisms/Settings/DietSettings/DietSettings';
import { DocumentPage } from './components/organisms/Document/DocumentContainer';
import { ExchangeMeal } from './components/molecules/MealCard/ExchangeMeal/ExchangeMealContainer';
import { Footer } from './components/molecules/footer/footer';
import { LandingPage } from './components/organisms/LandingPage/LandingPage';
import { MealDetailCard } from './components/molecules/MealCard/Meal/MealDetailContainer/MealDetailCard';
import { NewCustomer } from './components/organisms/NewCustomer/NewCustomer';
import { OrderMenuView } from './components/organisms/OrderMenuView';
import { PartnershipInitial } from './components/organisms/Partnership/PartnershipInitial/PartnershipInitialContainer';
import { PartnershipPanel } from './components/organisms/Partnership/PartnershipPanel/PartnershipPanelContainer';
import { PaymentStatusView } from './components/organisms/PaymentStatusVIew';
import React from 'react';
import { RestaurantPage } from './components/molecules/Restaurants/RestaurantPage/RestaurantPageContainer';
import { Restaurants } from './components/molecules/Restaurants/RestaurantsContainer';
import { SideBar } from './components/organisms/SideBar/SideBar';
import { TopNav } from './components/molecules/TopNav/TopNav';
import { UserSettings } from './components/organisms/Settings/UserSettings/UserSettingsContainer';
import { WeekView } from './components/organisms/WeekView/WeekViewContainer';
import appHistory from './app-history';
import { routes } from './business/router/path-definition';

import { Route, Switch } from 'react-router-dom';

interface IAppComponenetProps {
	isLoggedUser: boolean;
	sideBarShow: boolean;
	toggleSlideBar(): void;
}

interface IAppComponentState {}

export class AppComponent extends React.Component<IAppComponenetProps, IAppComponentState> {
	private renderSideMenu = () => {
		if (this.props.sideBarShow) {
			return <SideBar onCloseMenu={this.props.toggleSlideBar} />;
		} else {
			return null;
		}
	};

	private getComponent = () => {
		if (this.props.isLoggedUser) {
			return DayView;
		} else {
			return LandingPage;
		}
	};

	public render(): JSX.Element {
		return (
			<ConnectedRouter history={appHistory}>
				<div className="App">
					{this.renderSideMenu()}
					<TopNav onOpenMenu={this.props.toggleSlideBar} />
					<>
						<Switch>
							<Route exact path="/" component={this.getComponent()} />
							<Route path={routes.customer.new.path} component={NewCustomer} />
							<Route path={routes.auth.main.path} component={AuthView} />
							<Route path={routes.customer.meal.add.path} component={CustomMeal} />
							<Route
								path={routes.customer.order.main.path}
								component={OrderMenuView}
							/>
							<Route
								path={routes.customer.order.paymentStatus.main.path}
								component={PaymentStatusView}
							/>
							{/* <Route
								path={routes.customer.meal.analyze.path}
								component={AnalyzeMeal}
							/> */}
							<Route
								path={routes.customer.meal.exchange.path}
								component={ExchangeMeal}
							/>
							<Route path={routes.customer.meal.copy.path} component={CopyMeal} />
							<Route path={routes.customer.meal.copyDay.path} component={CopyDay} />
							<Route
								path={routes.customer.meal.details.path}
								component={MealDetailCard}
							/>
							<Route
								path={routes.customer.meal.preference.path}
								component={WeekView}
							/>
							<Route
								path={routes.customer.beverages.main.path}
								component={Beverages}
							/>
							<Route
								path={routes.customer.settings.account.path}
								component={UserSettings}
							/>
							{/*todo: remove "any"*/}
							<Route
								path={routes.customer.settings.diet.path}
								component={DietSettings as any}
							/>
							<Route
								path={routes.customer.beverages.add.path}
								component={BeveragesEditor}
							/>
							<Route path={routes.static.document.path} component={DocumentPage} />
							<Route path={routes.static.blog.path} component={Blog} />
							<Route
								path={routes.restaurants.restaurantsList.path}
								component={Restaurants}
							/>
							<Route
								path={routes.restaurants.restaurantPage.path}
								component={RestaurantPage}
							/>
							<Route
								exact
								path={routes.partnership.initialForm.path}
								component={PartnershipInitial}
							/>
							<Route
								path={routes.partnership.panel.path}
								component={PartnershipPanel}
							/>
						</Switch>
					</>
					<Footer />
				</div>
			</ConnectedRouter>
		);
	}
}
