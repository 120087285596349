import './TextArea.scss';

import React, { TextareaHTMLAttributes } from 'react';

export interface ITextBoxProps {
	errorString?: string;
	label?: string;
	className?: string;
	expressionOnValue?: RegExp;
}
export const TextArea = ({
	errorString,
	label,
	className,
	expressionOnValue,
	...props
}: ITextBoxProps & TextareaHTMLAttributes<HTMLTextAreaElement>) => {
	return (
		<div className={`${className} TextArea`}>
			{label && (
				<label className="TextArea_label" htmlFor={props.id || props.name}>
					{label}
				</label>
			)}
			<textarea
				className="TextArea_textarea"
				value={
					expressionOnValue
						? props.value?.toString().replace(expressionOnValue, '')
						: props.value
				}
				{...props}
			/>
			{errorString && <span className="FormikError">{errorString}</span>}
		</div>
	);
};
