import * as Yup from 'yup';

import { ActionButton } from '../../../../atoms/ActionButton/ActionButton';
import React from 'react';
import { TextBox } from '../../../../atoms/TextBox/TextBox';

import { Field, Form, Formik, FormikHelpers } from 'formik';
import {
	IUserInvoiceDataFormProps,
	IUserInvoiceDataFormValues
} from './UserInvoiceDataFormInterfaces';

export const UserInvoiceDataForm = (props: IUserInvoiceDataFormProps) => {
	const onSubmit = (
		values: IUserInvoiceDataFormValues,
		helpers: FormikHelpers<IUserInvoiceDataFormValues>
	) => {
		props.saveUserSettings(values);
		helpers.resetForm({ values: values });
		setTimeout(() => helpers.setSubmitting(false), 2000);
	};
	return (
		<Formik
			initialValues={{
				apartmentNumber: props.initialValues.apartmentNumber ?? undefined,
				city: props.initialValues.city ?? undefined,
				homeNumber: props.initialValues.homeNumber ?? undefined,
				mail: props.initialValues.mail ?? undefined,
				name: props.initialValues.name ?? undefined,
				postcode: props.initialValues.postcode ?? undefined,
				street: props.initialValues.street ?? undefined,
				surname: props.initialValues.surname ?? undefined
			}}
			validationSchema={Yup.object<
				Partial<Record<keyof IUserInvoiceDataFormValues, Yup.AnySchema>>
			>({
				name: Yup.string()
					.min(3, 'Co najmniej 3 znaki')
					.max(30, 'Nie więcej niż 30 znaków')
					.required('Wymagane'),
				surname: Yup.string()
					.min(3, 'Co najmniej 3 znaki')
					.max(30, 'Nie więcej niż 30 znaków')
					.required('Wymagane'),
				street: Yup.string()
					.min(3, 'Co najmniej 3 znaki')
					.max(30, 'Nie więcej niż 30 znaków')
					.required('Wymagane'),
				apartmentNumber: Yup.string()
					.min(3, 'Co najmniej 3 znaki')
					.max(30, 'Nie więcej niż 30 znaków'),
				homeNumber: Yup.string()
					.min(3, 'Co najmniej 3 znaki')
					.max(30, 'Nie więcej niż 30 znaków'),
				postcode: Yup.string()
					.matches(new RegExp('[0-9]{2}-[0-9]{3}'), 'Niepoprawny kod pocztowy (00-000)')
					.min(5, 'Co najmniej 6 znaków')
					.required('Wymagane'),
				city: Yup.string()
					.min(3, 'Co najmniej 3 znaki')
					.max(30, 'Nie więcej niż 30 znaków')
					.required('Wymagane')
			})}
			onSubmit={onSubmit}
		>
			{(formik) => (
				<Form>
					<Field
						name="name"
						label="Imię"
						className="UserSettings__row"
						errorString={formik.touched.name && formik.errors.name}
						as={TextBox}
					/>
					<Field
						name="surname"
						label="Nazwisko"
						className="UserSettings__row"
						errorString={formik.touched.surname && formik.errors.surname}
						as={TextBox}
					/>
					<Field
						disabled
						name="mail"
						label="E-mail"
						className="UserSettings__row"
						errorString={formik.touched.mail && formik.errors.mail}
						as={TextBox}
					/>
					<div className="UserSettings__row">
						<Field
							name="street"
							label="Ulica"
							className="UserSettings__row-item UserSettings__row-item--m"
							errorString={formik.touched.street && formik.errors.street}
							as={TextBox}
						/>
						<Field
							name="homeNumber"
							label="Nr domu"
							className="UserSettings__row-item UserSettings__row-item--s"
							errorString={formik.touched.homeNumber && formik.errors.homeNumber}
							as={TextBox}
						/>
						<Field
							name="apartmentNumber"
							label="Nr mieszkania"
							className="UserSettings__row-item UserSettings__row-item--s"
							errorString={
								formik.touched.apartmentNumber && formik.errors.apartmentNumber
							}
							as={TextBox}
						/>
					</div>
					<div className="UserSettings__row">
						<Field
							name="postcode"
							label="Kod pocztowy"
							className="UserSettings__row-item UserSettings__row-item--s"
							errorString={formik.touched.postcode && formik.errors.postcode}
							as={TextBox}
						/>
						<Field
							name="city"
							label="Miasto"
							className="UserSettings__row-item UserSettings__row-item--l"
							errorString={formik.touched.city && formik.errors.city}
							as={TextBox}
						/>
					</div>
					<ActionButton
						type="submit"
						text="Zapisz"
						disabled={props.initialValues.name ? !formik.dirty : false}
						errorString={formik.isValid ? undefined : 'Sprawdź błędy w formularzu'}
						className="UserSettings__action-button"
					/>
				</Form>
			)}
		</Formik>
	);
};
