import './DietPlanPreferences04StepForm.scss';

import { BreakfastIcon } from '../../../../shared/assets/breakfast-icon';
import { BrunchIcon } from '../../../../shared/assets/brunch-icon';
import { CheckboxSelect } from '../../../atoms/checkbox-select/checkbox-select';
import { CloseIcon } from '../../../../shared/assets/close-icon';
import { DinnerIcon } from '../../../../shared/assets/dinner-icon';
import { ICheckboxSelectItem } from '../../../atoms/checkbox-select/checkbox-select';
import { IWizzardStepProps } from '../UserInterviewInterfaces';
import { LunchIcon } from '../../../../shared/assets/lunch-icon';
import { RadioSelect } from '../../../atoms/RadioSelect/RadioSelect';
import { SupperIcon } from '../../../../shared/assets/supper-icon';
import { WizardCard } from '../../WizardCard/WizardCard';
import { loadTagMealsForDietServings } from '../../../../business/diet/diet-action';
import { useSortMealTags } from '../../../../hooks/useSortMealTags';

import { Field, FieldArray, FieldArrayRenderProps } from 'formik';
import { ISearchItem, SearchBox } from '../../../atoms/search-box/search-box';
import { MealTypeEnum, MealTypeTag, TagsMealsForDietServings } from '../../../../client';
import {
	PossibleNumbersOfMeals,
	getFriendlyMealName,
	getIdForMealType,
	getMealTypesForNumberOfMeals
} from '../../../../shared/helpers';
import React, { ReactElement, useEffect } from 'react';
import {
	allTagMealsForDietServings,
	getCustomerDietDefinition,
	hasTagMealsForDietServings
} from '../../../../business/diet/diet-selector';
import {
	clientJourneyDeleteTagsMealsFrDietServings,
	clientJourneyPostTagsMealsForDietServings,
	clientJourneyPostUpdateTagMealTypesForDietServings
} from '../../../../business/customer/customer-settings-actions';
import { useDispatch, useSelector } from 'react-redux';

export const DietPlanPreferences04StepForm: React.FC<IWizzardStepProps> = ({
	formik,
	mode,
	scrollToStep,
	...props
}) => {
	const dispatch = useDispatch();
	const sortTags = useSortMealTags;

	const allTags = useSelector(allTagMealsForDietServings).map((x) => {
		return { display: x.name, value: x.name, id: x.id } as ISearchItem;
	});
	const allTagsFormatted = sortTags(allTags, 'value');

	const [searchText, setSearchText] = React.useState('');
	const onSearchTextChanged = (text: string) => {
		setSearchText(text);
	};

	const customerDietDefinition = useSelector(getCustomerDietDefinition);
	const hasTagMealsForDietServingsLoaded = useSelector(hasTagMealsForDietServings);
	useEffect(() => {
		if (!hasTagMealsForDietServingsLoaded && customerDietDefinition?.id) {
			dispatch(loadTagMealsForDietServings(customerDietDefinition.id));
		}
	}, [customerDietDefinition]);

	const getCheckboxSelectItems = (mealTypeTags: MealTypeTag[]) => {
		var toReturn: ICheckboxSelectItem[] = [];
		mealTypeTags.map((x) => {
			if (x.mealTypeName !== 'Beverages')
				toReturn.push({
					value: x.mealTypeName!,
					displayName: getFriendlyMealName(x.mealTypeName!),
					icon: getIconForMealType(x.mealTypeId!),
					selected: x.selected
				});
		});
		return toReturn;
	};

	const getIconForMealType = (mealTypeId: number): ReactElement => {
		switch (mealTypeId) {
			case 0:
				return <BreakfastIcon />;
			case 1:
				return <BrunchIcon />;
			case 3:
				return <LunchIcon />;
			case 5:
				return <SupperIcon />;
			case 6:
				return <DinnerIcon />;
			default:
				return <></>;
		}
	};

	const handleScroll = () => {
		scrollToStep(4);
	};

	const handleRemove = (
		activeTag: TagsMealsForDietServings,
		index: number,
		arrayHelpers: FieldArrayRenderProps
	) => {
		if (mode === 'new-customer') {
			dispatch(clientJourneyDeleteTagsMealsFrDietServings(activeTag.tagId!));
		}
		const indexToRemove = formik.values.step4.tags?.findIndex(
			(tag: TagsMealsForDietServings) => tag.tagId === activeTag.tagId
		);
		if (indexToRemove !== undefined && indexToRemove > -1) {
			arrayHelpers.remove(indexToRemove);
		}
	};

	const handleUpdateTag = (
		lastUpdatedItem: string,
		tag: TagsMealsForDietServings,
		index: number,
		arrayHelpers: FieldArrayRenderProps
	) => {
		const mealTypeId = getIdForMealType(lastUpdatedItem)!;
		if (mode === 'new-customer') {
			dispatch(
				clientJourneyPostUpdateTagMealTypesForDietServings(
					mealTypeId,
					tag.tagId!,
					formik.setFieldValue
				)
			);
		} else {
			if (
				tag.mealTypeTags?.filter((x) => x.selected).length === 1 &&
				tag.mealTypeTags.find((x) => x.mealTypeId === mealTypeId) &&
				tag.mealTypeTags[0].selected
			) {
				arrayHelpers.remove(index);
			} else {
				arrayHelpers.replace(index, {
					...tag,
					mealTypeTags: tag.mealTypeTags?.map((x) => {
						if (x.mealTypeId === mealTypeId) {
							return {
								...x,
								selected: !x.selected
							};
						}
						return x;
					})
				} as TagsMealsForDietServings);
			}
		}
	};

	const handleSelectTag = (item: ISearchItem, arrayHelpers: FieldArrayRenderProps) => {
		if (mode === 'new-customer') {
			dispatch(
				clientJourneyPostTagsMealsForDietServings(
					[
						{
							tagName: item.display,
							tagId: item.id
						} as TagsMealsForDietServings
					],
					formik.setFieldValue
				)
			);
		} else {
			arrayHelpers.push({
				tagId: item.id,
				tagName: item.display,
				mealTypeTags: getMealTypesForNumberOfMeals(
					+(formik.values.step4.mealsCount ?? 5) as PossibleNumbersOfMeals
				).map((x) => {
					return {
						mealTypeId: x,
						mealTypeName: MealTypeEnum[x],
						selected: true,
						tagsMealsForDietServingsId: item.id
					} as MealTypeTag;
				})
			} as TagsMealsForDietServings);
		}
	};

	return (
		<WizardCard id="step04" header="4. Schemat Diety" onClick={handleScroll}>
			<h4 className="UserInterview__subheader UserInterview__subheader--first-in-row">
				Ile posiłków dziennie chcesz jeść?
			</h4>
			<Field
				label="3 posiłki"
				name="step4.mealsCount"
				value="3"
				checked={formik.values.step4.mealsCount === '3'}
				as={RadioSelect}
			/>
			{/* <Field
				label="4 posiłki"
				name="step4.mealsCount"
				value="4"
				checked={formik.values.step4.mealsCount === '4'}
				as={RadioSelect}
			/>
			<Field
				label="5 posiłków"
				name="step4.mealsCount"
				value="5"
				checked={formik.values.step4.mealsCount === '5'}
				as={RadioSelect}
			/> */}
			<span className="FormikError">
				{formik.touched.step4?.mealsCount && formik.errors.step4?.mealsCount}
			</span>

			<h4 className="UserInterview__subheader">Chcę jeść ... na </h4>

			<FieldArray
				name="step4.tags"
				render={(arrayHelpers) => {
					return (
						<>
							<SearchBox
								placeholder="Zacznij wyszukiwanie (np. koktajle)"
								data={allTagsFormatted.filter(
									(x: any) =>
										!formik.values.step4.tags?.some((y) => y.tagId === x.id)
								)}
								onSelect={(item) => handleSelectTag(item, arrayHelpers)}
								onSearchChanged={onSearchTextChanged}
								value={searchText}
								activateOnFocus
							/>
							<span className="FormikError">
								{(formik.touched.step4?.mealsCount || mode === 'diet-settings') &&
									formik.errors.step4?.tags}
							</span>
							<div className="DietPlanPreferences04StepForm_chips">
								{sortTags(formik.values.step4.tags, 'tagName').map(
									(tag: TagsMealsForDietServings, index: number) => {
										return (
											<div
												key={'Tag_' + tag.tagId}
												className="DietPlanPreferences04StepForm_chips_container"
											>
												<div className="DietPlanPreferences04StepForm_chips_container_label">
													<span>
														{tag.tagName?.replaceAll('_', ' ') || ''}
													</span>
													<CloseIcon
														size={15}
														onClick={() =>
															handleRemove(tag, index, arrayHelpers)
														}
													/>
												</div>
												<CheckboxSelect
													key={tag.tagName}
													items={getCheckboxSelectItems(
														tag.mealTypeTags ?? []
													)}
													placeholder="Wybierz"
													placement="top"
													onChange={(lastUpdatedItem) =>
														handleUpdateTag(
															lastUpdatedItem,
															tag,
															index,
															arrayHelpers
														)
													}
												/>
											</div>
										);
									}
								)}
							</div>
						</>
					);
				}}
			/>
		</WizardCard>
	);
};
