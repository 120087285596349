import './PaymentChannelForm.scss';

import { ActionButton } from '../../../../atoms/ActionButton/ActionButton';
import { CheckBox } from '../../../../atoms/CheckBox/CheckBox';
import { RadioSelect } from '../../../../atoms/RadioSelect/RadioSelect';
import { TextBox } from '../../../../atoms/TextBox/TextBox';
import { usePaymentsChannelFormData } from './usePaymentsChannelFormData';

import { contactDetailsFields, personalDataFields } from './usePaymentsChannelFormData.auxiliaries';

export const PaymentChannelForm = () => {
	const {
		cashTransferFormAgreementHandler,
		cashTransferFormValueHandler: cashTransferFormValuehandler,
		setPaymentChannel,
		paymentHandler,
		activePaymentChannel,
		paymentChannels,
		isPaymentAvailable,
		isPaymentStatuteAccspted,
		isPersonalDataProcessingAgrementAccepted,
		cashTransferFormValues
	} = usePaymentsChannelFormData();
	return (
		//todo animate css
		<div className="PaymentChannelForm animate__animated animate__fadeIn">
			<div className="PaymentChannelForm__list">
				{paymentChannels.map((el) => {
					return (
						<div className="PaymentChannelForm__list__tale" key={el.id}>
							<img src={el.logoUrl!} alt={el.name!} />
							<RadioSelect
								label={el.name!}
								checked={activePaymentChannel?.id === el.id}
								id={el.id!}
								onChange={setPaymentChannel(el)}
							/>
						</div>
					);
				})}
			</div>
			<div className="PaymentChannelForm__consents">
				<a href="/https://dietdesigner.app/#/document/regulamin">
					Regulamin usługi Diet Designer
				</a>
				<CheckBox
					checked={isPaymentStatuteAccspted}
					displayName="Akceptuję regulamin usługi Diet Designer"
					onChange={() =>
						cashTransferFormAgreementHandler(
							'cashTransferStatuteAgreement',
							!isPaymentStatuteAccspted
						)
					}
				/>
				<a href="https://www.cashbill.pl/download/dokumenty/przetwarzanie-danych.pdf">
					Pełen regulamin przetwarzania danych przez operatora płatności
				</a>
				<CheckBox
					checked={isPersonalDataProcessingAgrementAccepted}
					onChange={() =>
						cashTransferFormAgreementHandler(
							'dataProcessingAgrement',
							!isPersonalDataProcessingAgrementAccepted
						)
					}
					displayName="Wyrażam zgodę na przetwarzanie moich danych osobowych przez  CashBill S.A., ul. Jana III Sobieskiego 2, 40-082 Katowice, wpisana do rejestru przedsiębiorców prowadzonego przez Sąd Rejonowy Katowice-Wschód w Katowicach VIII Wydział Gospodarczy KRS pod numerem 0000323297, NIP: 6292410801
                     dla potrzeb realizacji procesu płatności zgodnie z obowiązującymi przepisami (Ustawa z dnia 29.08.1997r. O ochronie danych osobowych,
                dz. U. Nr 133, poz. 883 z późn. Zmianami). 
                Mam prawo wglądu i poprawiania swoich danych."
				/>
			</div>
			<form className="PaymentChannelForm__form-wrapper">
				<div>
					{Object.values(personalDataFields).map((field) => (
						<div className="PaymentChannelForm__form-item" key={field.key}>
							<TextBox
								onChange={(x) =>
									cashTransferFormValuehandler(field.key, x.target.value)
								}
								name={field.key}
								label={field.label}
								value={cashTransferFormValues[field.key]}
								disabled={field.disabled}
							/>
						</div>
					))}
				</div>
				<div>
					{Object.values(contactDetailsFields).map((field: any) => (
						<div className="PaymentChannelForm__form-item" key={field.key}>
							<TextBox
								onChange={(x) =>
									cashTransferFormValuehandler(field.key, x.target.value)
								}
								name={field.key}
								label={field.label}
								value={cashTransferFormValues[field.key]}
							/>
						</div>
					))}
				</div>
			</form>
			<ActionButton
				text="Zamów i zapłać"
				onClick={paymentHandler}
				disabled={!isPaymentAvailable}
			/>
		</div>
	);
};
