import * as React from 'react';
import * as Redux from 'redux';

import { CopyMealComponent } from './CopyMealComponent';
import { CopyMeals } from './CopyMealEpics';
import { GetUserMealTypes } from '../../staticData/staticDataEpics';
import { ICurrentMeals } from '../Meal/meal-interfaces';
import { List } from 'immutable';
import { MealTypeEnum } from '../../../../client';
import { RootState } from '../../../../business/root-store';
import { connect } from 'react-redux';
import { getCurrentMealsSelector } from '../Meal/meal-selectors';
import { getUserMealTypesWithoutBeverages } from '../../staticData/staticDataSelectors';
import moment from 'moment';
import { push } from 'connected-react-router';

import { AddToastPayload, actions } from 'react-redux-toastr';
import { AnyAction, Dispatch } from 'redux';
import {
	getCustomerDietPlan,
	getCustomerDietPlanDayDateLocal
} from '../../../../business/diet/diet-selector';

interface ICopyMealContainerReduxMergedProps {
	maxDateUtc: Date;
	minDateUtc: Date;
	currentDietPlanDayUtc: Date;
	currentMeals: ICurrentMeals;
	userMealTypes: List<MealTypeEnum>;
}

interface ICopyMealContainerOwnProps {}

interface ICopyMealContainerDispatchProps {
	navigateTo: (path: string, state?: any) => void;
	CopyMeals(mealType: MealTypeEnum, toDayUtc: Date): void;
	toast: (message: AddToastPayload) => void;
	GetUserMealTypes: () => void;
}

interface ICopyMealContainerProps
	extends ICopyMealContainerOwnProps,
		ICopyMealContainerDispatchProps,
		ICopyMealContainerReduxMergedProps {}

interface ICopyMealComponentState {}

class CopyMealContainer extends React.Component<ICopyMealContainerProps, ICopyMealComponentState> {
	constructor(props: ICopyMealContainerProps, state: ICopyMealComponentState) {
		super(props, state);
		this.state = this.getInitialState();
		this.props.GetUserMealTypes();
	}

	public render(): JSX.Element {
		return (
			<CopyMealComponent
				maxDateUtc={this.props.maxDateUtc}
				minDateUtc={this.props.minDateUtc}
				CopyMeals={this.props.CopyMeals}
				onCloseClicked={this.props.navigateTo}
				toast={this.props.toast}
				currentDietPlanDayUtc={this.props.currentDietPlanDayUtc}
				userMealTypes={this.props.userMealTypes}
				currentMeals={this.props.currentMeals}
			/>
		);
	}

	public static mapStateToProps = (
		state: RootState,
		ownProps: ICopyMealContainerOwnProps
	): ICopyMealContainerReduxMergedProps => {
		const customerDietPlan = getCustomerDietPlan(state);
		return {
			maxDateUtc:
				customerDietPlan && customerDietPlan.endDate
					? moment.utc(customerDietPlan.endDate).toDate()
					: moment.utc().toDate(),
			minDateUtc:
				customerDietPlan && customerDietPlan.startDate
					? moment.utc(customerDietPlan.startDate).toDate()
					: moment.utc().toDate(),
			currentDietPlanDayUtc: getCustomerDietPlanDayDateLocal(state),
			userMealTypes: getUserMealTypesWithoutBeverages(state),
			currentMeals: getCurrentMealsSelector(state)
		};
	};

	public static mapDispatchToProps = (
		dispatch: Dispatch<AnyAction>
	): ICopyMealContainerDispatchProps => {
		return {
			...Redux.bindActionCreators(
				{
					navigateTo: push,
					CopyMeals: CopyMeals,
					toast: actions.add,
					GetUserMealTypes: GetUserMealTypes
				},
				dispatch
			)
		};
	};

	private getInitialState = (): ICopyMealComponentState => {
		return {} as ICopyMealComponentState;
	};
}
export const CopyMeal = connect(
	CopyMealContainer.mapStateToProps,
	CopyMealContainer.mapDispatchToProps
)(CopyMealContainer);
