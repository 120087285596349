export const personalDataFields = {
	firstName: { key: 'firstName', label: 'Imię', value: '', type: 'text', disabled: false },
	surname: { key: 'surname', label: 'Nazwisko', value: '', type: 'text', disabled: false },
	email: { key: 'email', label: 'E-mail', value: '', type: 'email', disabled: true }
};

export const contactDetailsFields = {
	city: { key: 'city', label: 'Miasto', value: 'Miasto', type: 'text' },
	street: { key: 'street', label: 'Ulica', value: '', type: 'text' },
	house: { key: 'house', label: 'Nr domu', value: '', type: 'text' },
	flat: { key: 'flat', label: 'Nr mieszkania', value: '', type: 'text' },
	postCode: { key: 'postCode', label: 'Kod pocztowy', value: '', type: 'text' }
};
