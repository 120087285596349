import './LandingPageDietTypesComponent.css';

import { DietPlanPrice } from '../../staticData/staticDataSelectors';
import { Headline } from '../../../atoms/headline/headline';
import { MenuSubscription } from './menu-subscription/menu-subscription';
import React from 'react';

interface ILandingPageDietTypesComponentProps {
	cheapestPrice: DietPlanPrice;
	standardDietId?: number;
	veggieDietId?: number;
	veggieFishDietId?: number;
}

interface ILandingPageDietTypesComponentState {}

export class LandingPageDietTypesComponent extends React.Component<
	ILandingPageDietTypesComponentProps,
	ILandingPageDietTypesComponentState
> {
	constructor(
		props: ILandingPageDietTypesComponentProps,
		state: ILandingPageDietTypesComponentState
	) {
		super(props, state);

		this.state = this.getInitialState();
	}

	public render(): JSX.Element {
		return (
			<div className="landing-page-diet-types">
				<div className="line-decor" />
				<Headline
					text="DOŁĄCZ JUŻ DZIŚ i wybierz jeden z trzech typów diety"
					id="join-us"
				/>
				<div className="landing-page-diet-types__menu-wrapper">
					{this.props.standardDietId && (
						<MenuSubscription
							id={this.props.standardDietId}
							img="/img/diet-types/Standard I.png"
							imgOnHover="/img/diet-types/Standard II.png"
							url="standard"
							DietName="Dieta Standard"
							About="Dieta idealna dla osób, które nie lubią nudy na talerzu. Bazuje
                na dużej gamie produktów, co gwarantuje różnorodność posiłków.
                Dzięki niej zadbasz o zdrowie i sylwetkę bez większych
                wyrzeczeń."
							cheapestPrice={this.props.cheapestPrice}
						/>
					)}
					{this.props.veggieDietId && (
						<MenuSubscription
							id={this.props.veggieDietId}
							url="veggie"
							DietName="Dieta Veggie"
							img="/img/diet-types/Veggie I.png"
							imgOnHover="/img/diet-types/Veggie II.png"
							About="Dieta bezmięsna może być urozmaicona i smaczna, a także da
                pewność, że dania są odpowiednio zbilansowane i dostarczają
                wszystkich potrzebnych składników odżywczych."
							cheapestPrice={this.props.cheapestPrice}
						/>
					)}
					{this.props.veggieFishDietId && (
						<MenuSubscription
							id={this.props.veggieFishDietId}
							url="veggieplusfishandsea"
							DietName="Veggie + Fish & Seafood"
							img="/img/diet-types/Pesco I.png"
							imgOnHover="/img/diet-types/Pesco II.png"
							About="Dieta wegetariańska z dodatkiem ryb i owoców morza. Jest
                doskonałym wyborem dla osób, które chcą wzbogacić swoje posiłki
                w dary mórz i jezior."
							cheapestPrice={this.props.cheapestPrice}
						/>
					)}
				</div>
			</div>
		);
	}

	private getInitialState = (): ILandingPageDietTypesComponentState => {
		return {} as ILandingPageDietTypesComponentState;
	};
}
