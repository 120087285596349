import './comment-section-renderer-component.scss';

import { IDpdmWithImage } from '../../../meal-card-interfaces';
import { MealComment } from '../../../../../../client';

import SlickSlider, { Settings } from 'react-slick';

const sliderSettings: Settings = {
	pauseOnHover: true,
	autoplay: true,
	infinite: true,
	speed: 500,
	slidesToShow: 1,
	slidesToScroll: 1
};

const renderCommentList = (mealWithImage: IDpdmWithImage) => {
	return mealWithImage?.dietPlanDayMeal.meal?.mealComments?.map((item: MealComment) => (
		<div key={item.id!} className="comments-item">
			<div className="comments-caption">
				<div className="comments-caption__user">{item.createdBy!}&nbsp;&nbsp;</div>
				<div className="comments-caption__date">
					{new Date(item.createdDateUtc!).getDate() +
						' ' +
						new Date(item.createdDateUtc!).toLocaleDateString('pl-PL', {
							month: 'long'
						}) +
						' ' +
						new Date(item.createdDateUtc!).getFullYear()}{' '}
				</div>
			</div>
			<div className="comments-content">
				<div className="comments-content__comment">{item.comments!}</div>
				{item.imageOrg && (
					<img
						className="comments-content__image"
						src={`${process.env.REACT_APP_FILES_URL}/api/${item.imageOrg}`}
					></img>
				)}
			</div>
		</div>
	));
};

export interface ICommentSectionRendererComponent {
	mealWithImage: IDpdmWithImage;
}

export const CommentSectionRendererComponent = (
	props: ICommentSectionRendererComponent
): JSX.Element | null => {
	//isListDisplayed value doesnt react with resize event. Changes are available while component renders
	//we may think about further refactor...

	const isListDisplayed = window.innerWidth > 750;

	return isListDisplayed ? (
		<div id="comments-list">{renderCommentList(props.mealWithImage)}</div>
	) : (
		<div id="comments-slider">
			<SlickSlider {...sliderSettings}>{renderCommentList(props.mealWithImage)}</SlickSlider>
		</div>
	);
};
