import './DayView.scss';

import * as React from 'react';

import { ChooseDietPlan } from '../../molecules/ChooseDietPLan';
import { DayViewTabsSelector } from '../../molecules/DayViewTabsSelector';
import { DietBalance } from './DietBalance';
import { HeaderComponent } from '../../molecules/HeaderComponent';
import { MenuComponent } from './Menu';
import { ShoppingListView } from './ShoppingList/shopping-list-container';
import { fetchMealsForWeek } from '../WeekView/MealTable/MealTableEpics';
import { getBeverages } from '../../molecules/Beverages/beverages-epics';
import { getCheapestDietPlanPrice } from '../../molecules/staticData/staticDataSelectors';
import { getUsernameLowerCase } from '../../../business/auth/auth-selector';
import { routes } from '../../../business/router/path-definition';
import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';

// eslint-disable-next-line sort-imports
import {
	getCustomerDietDefinition,
	getCustomerDietPlanName,
	getDietDay,
	getGroupedCustomerDietPlanDayMeals,
	getIsDayPlanAvailable,
	getIsTrial,
	getavailableDietPlans
} from '../../../business/diet/diet-selector';
import { useDispatch, useSelector } from 'react-redux';

export type DayViewTabs = 'Menu' | 'DietBalance' | 'ShoppingList';

export const DayView = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const userName = useSelector(getUsernameLowerCase);
	const dietDescritpion = useSelector(getCustomerDietPlanName);
	const dietDefinition = useSelector(getCustomerDietDefinition);
	const isTrial = useSelector(getIsTrial);
	const meals = useSelector(getGroupedCustomerDietPlanDayMeals);
	const availableDietPlans = useSelector(getavailableDietPlans);
	const isDayPlanAvailable = useSelector(getIsDayPlanAvailable);
	const cheapestPrice = useSelector(getCheapestDietPlanPrice);
	const dietPlanDay = useSelector(getDietDay);
	const dietPlanId = dietPlanDay?.dietPlanId ?? 0;
	const dietDayId = dietPlanDay?.dietDayId ?? 0;
	const [currentActiveTab, setCurrentActiveTab] = useState<DayViewTabs>('Menu');
	const handleOnPreferenceClicked = (path: string): void => {
		history.push(path + '/?dietPlanId=' + dietPlanId + '&dayId=' + dietDayId);
		dispatch(fetchMealsForWeek(dietPlanId!, dietDayId!));
	};

	const onPreferencesClicked = (): void => {
		handleOnPreferenceClicked(routes.customer.meal.preference.path);
	};

	const onDietPlanChoice = (): void => {
		history.push(routes.customer.order.plan.path);
	};

	useEffect(() => {
		dispatch(getBeverages());
	}, [dietDefinition]);

	return (
		<div className="DayView">
			<HeaderComponent
				dietDescritpion={dietDescritpion}
				isTrial={isTrial}
				onPreferencesClicked={onPreferencesClicked}
				userName={userName}
			/>
			<DayViewTabsSelector
				currentActiveTab={currentActiveTab}
				setCurrentActiveTab={setCurrentActiveTab}
			/>
			{isDayPlanAvailable ? (
				(currentActiveTab === 'Menu' && <MenuComponent />) ||
				(currentActiveTab === 'DietBalance' && <DietBalance />) ||
				(currentActiveTab === 'ShoppingList' && <ShoppingListView />)
			) : (
				<div className="DayView__diet-in-progress-info">
					<div className="DayView__diet-in-progress-info-loader"></div>
					<div>
						Daj nam jeszcze chwilkę. Generujemy dla Ciebie możliwie najpyszniejszy
						jadłospis. Gdy będzie gotowy damy znać mailem.
					</div>
				</div>
			)}
			<ChooseDietPlan
				isTrial={isTrial}
				isDayPlanAvailable={isDayPlanAvailable}
				availableDietPlans={availableDietPlans}
				onDietPlanChoice={onDietPlanChoice}
				dietPlanPrice={cheapestPrice}
			/>
		</div>
	);
};
