import { GetApiConfig } from '../../../../business/api-client-config';
import { List } from 'immutable';
import { RootState } from '../../../../business/root-store';
import { ThunkAction } from 'redux-thunk';
import { actions } from 'react-redux-toastr';
import { customerSettingsActions } from '../../../../business/customer/customer-settings-actions';
import { push } from 'connected-react-router';
import { routes } from '../../../../business/router/path-definition';

import { Customer, CustomerApi, PaymentApi } from '../../../../client';
import { currentOrderFetched, invoicesFetched, ordersFetched } from './UserSettingsActions';

export const updateUserSettings =
	(customer: Customer): ThunkAction<any, RootState, any, any> =>
	(dispatch, getState) => {
		const store: RootState = getState();
		const customerApi = new CustomerApi(GetApiConfig(store));
		customerApi
			.addOrUpdateCustomer(customer)
			.then((x) => {
				dispatch(push(routes.customer.settings.account.path));
				if (x.data.customer)
					dispatch(customerSettingsActions.loadCustomer(x.data.customer));
			})
			.catch(() => {
				console.log(
					actions.add({
						message: 'Cannot update user settings.',
						type: 'error'
					})
				);
			});
	};

export const fetchUserInvoices =
	(): ThunkAction<any, RootState, any, any> => (dispatch, getState) => {
		const store: RootState = getState();
		const api = new PaymentApi(GetApiConfig(store));
		api.getCustomerInvoices().then((x) => {
			dispatch(invoicesFetched(List(x.data.invoices!)));
		});
	};

export const fetchUserOrders =
	(): ThunkAction<any, RootState, any, any> => (dispatch, getState) => {
		const store: RootState = getState();
		const api = new PaymentApi(GetApiConfig(store));
		api.getOrders().then((x) => {
			dispatch(ordersFetched(List(x.data.orders!)));
		});
	};

export const downloadInvoice =
	(invoiceId: number, fileName: string): ThunkAction<any, RootState, any, any> =>
	(dispatch, getState) => {
		const store: RootState = getState();
		const api = new PaymentApi(GetApiConfig(store));
		api.getInvoiceFile(invoiceId, {
			responseType: 'blob'
		})
			.then((x) => {
				const url = window.URL.createObjectURL(new Blob([x.data]));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', fileName); //or any other extension
				document.body.appendChild(link);
				link.click();
			})
			.catch((z) => {
				console.log(
					actions.add({
						message: 'Plik jeszcze nie gotowy, spróbuj później',
						type: 'error'
					})
				);
			});
	};

export const fetchCurrentOrder =
	(): ThunkAction<any, RootState, any, any> => (dispatch, getState) => {
		const store: RootState = getState();
		const api = new PaymentApi(GetApiConfig(store));
		api.getCurrentOrder().then((x) => {
			dispatch(currentOrderFetched(x.data.currentOrder!));
		});
	};
