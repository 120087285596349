import { useDefaultId } from '../../../hooks/use-default-id';
import React, { MouseEventHandler } from 'react';

export interface IActionLinkProps {
	className?: string;
	text?: string;
	textColor?: string;
	onClick?: MouseEventHandler<HTMLButtonElement>;
	id?: string;
	disableLeftMargin?: boolean;
	disabled?: boolean;
	type?: 'button' | 'submit' | 'reset';
}

export const ActionLink: React.FC<IActionLinkProps> = (props) => {
	const id = useDefaultId(props.id);

	return (
		<button
			id={id}
			disabled={props.disabled}
			type={props.type}
			onClick={props.onClick}
			className={`action-link ${props.className} ${
				props.disabled ? 'action-link__disabled' : ''
			}`}
		>
			{props.children}
			<span
				style={{ color: props.textColor }}
				className={
					'action-link__label' +
					(props.disableLeftMargin ? ' disableMarginLeft' : '') +
					(props.disabled ? ' action-link__label__disabled' : '')
				}
			>
				{` ${props.text} `}
			</span>
		</button>
	);
};
export default ActionLink;
