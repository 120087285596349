export const primaryOrangeColor = '#FD6851';

export const documentsUrls = {
	thanks: {
		partnership: 'partnershipthanks',
		contact: 'contactthanks'
	},
	partnership: {
		wait: 'partnershipwait',
		rejected: 'partnershiprejected',
		documents: 'partnershipdocuments',
		documentsRejected: 'partnershipdocumentsrejected',
		documentsVerify: 'partnershipdocumentsverify',
		documentsWait: 'partnershipdocumentswait',
		detailsRejected: 'partnershipdetailsrejected',
		detailsWait: 'partnershipdetailswait'
	},
	promo: {
		partnershippromo: 'partnershippromo'
	},
	tos: 'regulamin',
	getstarted: 'getstarted'
};
