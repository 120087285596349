import { FileApi } from '../../../../files-client';
import { IImageFile } from '../../../../shared/interfaces/IImageFile';
import { List } from 'immutable';
import { RootState } from '../../../../business/root-store';
import { ThunkAction } from 'redux-thunk';
import { getBase64 } from '../../../../shared/helpers';
import { loadDietPlanDay } from '../../../../business/diet/diet-action';
import { productsFetched } from './CustomMealActions';
import { push } from 'connected-react-router';
import { restaurantImagesFetched } from '../../../../business/Restaurants/RestaurantsActions';
import { routes } from '../../../../business/router/path-definition';
import { setCurrentMeals } from '../Meal/meal';

import { DietApi, FoodApi, Meal, MealTypeEnum, ProductRefNamePair } from '../../../../client';
import { GetApiConfig, GetFileConfig } from '../../../../business/api-client-config';

export const getProductRefAndNames =
	(): ThunkAction<any, RootState, any, any> => (dispatch, getState) => {
		const state = getState();
		const api = new FoodApi(GetApiConfig(state));
		api.getProductRefAndNames()
			.then((response) => {
				if (response.data.refNamePair) {
					dispatch(
						productsFetched({
							products: List<ProductRefNamePair>(response.data.refNamePair)
						})
					);
				}
			})
			.catch((c) => {});
	};

export const addOrUpdateCustomerMeals =
	(meals: Meal[], mealType?: MealTypeEnum): ThunkAction<any, RootState, any, any> =>
	(dispatch, getState) => {
		const state = getState();
		const api = new DietApi(GetApiConfig(state));
		const currentMeals = state.dayView.meal.currentMeals;
		const customerId = state.administration.customerSettings.customerId!;
		const dayId = state.diet.dietPlanDay?.dietDayId!;
		const dietPlandId = state.diet.dietPlanDay?.dietPlanId!;
		const dietDefinitionId = state.diet.dietDefinition?.id!;

		api.postAddOrUpdateDietPlanDayMeals(customerId, {
			dayId: dayId,
			dietPlanId: dietPlandId,
			dietDefinitionId: dietDefinitionId,
			meals: meals,
			mealTypeEnum: mealType ?? currentMeals.mealType
		}).then(() => {
			dispatch(loadDietPlanDay(dayId));
			dispatch(push(routes.home.path));
		});
	};

export const addCustomMealImage =
	(file: File, mealIndex: number): ThunkAction<any, RootState, any, any> =>
	(dispatch, getState) => {
		const state = getState();
		const api = new FileApi(GetFileConfig(state));
		const currentMeals = state.dayView.meal.currentMeals;
		getBase64(file, (base64: string) => {
			var convert = base64.substr(base64.indexOf(',') + 1);
			{
				api.postFileCatalog({ name: 'CustomMeal' }).then((catalogResponse) => {
					api.postImageFile({
						imageData: convert,
						name: file.name,
						catalogId: catalogResponse.data.catalogId,
						resize: true,
						generateThumbminal: true,
						width: 1280,
						height: 720,
						keepResizeRatio: true,
						thumbminalHeight: 225,
						thumbminalWidth: 400
					}).then((z) => {
						api.getFilesFromCatalog(catalogResponse.data.catalogId!).then((x) => {
							dispatch(
								setCurrentMeals({
									...currentMeals,
									meals: currentMeals.meals.map((stateMeal, stateMealIndex) => {
										if (stateMealIndex !== mealIndex) {
											return stateMeal;
										}
										return {
											...stateMeal,
											imagesCatalog: catalogResponse.data.catalogId
										};
									})
								})
							);
							dispatch(
								restaurantImagesFetched({
									metadatas: x.data.fileMetadatas ?? [],
									catalogId: catalogResponse.data.catalogId
								} as IImageFile)
							);
						});
					});
				});
			}
		});
	};
