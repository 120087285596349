import { RootState } from '../root-store';
import { createSelector } from 'reselect';
import { routes } from './path-definition';

export const getRouterLocationState = (state: RootState) => state.router.location;

export const isNewUserCreatorActive = createSelector(
	getRouterLocationState,
	(loc) => loc.pathname === '/new'
);

export const isRegisterActive = createSelector(
	getRouterLocationState,
	(loc) => loc.pathname === routes.auth.register.path
);

export const getLocationState = createSelector(getRouterLocationState, (loc) => loc.state);
export const getStaticDataState = createSelector(getRouterLocationState, (state) => state.state);

//To be deleted if not used anymore, otherwise its not a correct place

// export const geCurrentMealFromLocationState = createSelector(
// 	getLocationState,
// 	state => state.state.DietPlanDayMeal
// );
// export const geCurrentMealDayOfServingFromLocationState = createSelector(
// 	getLocationState,
// 	state => state.state.Day
// );
// export const geCurrentMealSummaryFromLocationState = createSelector(
// 	getLocationState,
// 	state => state.state.MealSummary
// );
