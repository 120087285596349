import { DumbbellIcon } from '../../../shared/assets/dumbbell-icon';
import { MealTable } from './MealTable/MealTableContainer';
import { PreviousIcon } from '../../../shared/assets/previous-icon';
import React from 'react';

interface IWeekViewComponentProps {
	onPreviousClicked(): void;
}

interface IWeekViewComponentState {}

export class WeekViewComponent extends React.Component<
	IWeekViewComponentProps,
	IWeekViewComponentState
> {
	constructor(props: IWeekViewComponentProps, state: IWeekViewComponentState) {
		super(props, state);

		this.state = this.getInitialState();
	}

	public render(): JSX.Element {
		return (
			<>
				<div
					className="weekViewHeaderTop shared_orange"
					onClick={this.props.onPreviousClicked}
				>
					<PreviousIcon />
					<div className="weekViewHeaderTopTitle">Powrót do jadłospisu</div>
				</div>

				<div className="shared_header_title weekViewHeaderTitle">Dostosuj plan diety</div>
				<div className="weekViewHeaderBottom">
					<DumbbellIcon />
					<div className="shared_orange weekViewHeaderBottomTitle">
						Edytuj dni treningowe
					</div>
				</div>
				<MealTable />
			</>
		);
	}

	private getInitialState = (): IWeekViewComponentState => {
		return {} as IWeekViewComponentState;
	};
}
