import * as React from 'react';
import * as Redux from 'redux';

import { BeveragesComponent } from './beverages-component';
import { DietPlanDayMeal } from '../../../client';
import { ICurrentMeals } from '../MealCard/Meal/meal-interfaces';
import { RootState } from '../../../business/root-store';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { setCurrentMeals } from '../MealCard/Meal/meal';

import { AnyAction, Dispatch } from 'redux';

interface IBeveragesContainerReduxMergedProps {}

export interface IBeveragesContainerOwnProps {
	currentMeals: ICurrentMeals;
	beverages: DietPlanDayMeal[];
	handleCustomMealModalClicked: (value: boolean) => void;
	handleBeveragesEditorModalClicked: (value: boolean) => void;
}

interface IBeveragesContainerDispatchProps {
	navigateTo: (path: string, state?: any) => void;
	setCurrentMeals(currentMeals: ICurrentMeals): void;
}

interface IBeveragesContainerProps
	extends IBeveragesContainerOwnProps,
		IBeveragesContainerDispatchProps,
		IBeveragesContainerReduxMergedProps {}

interface IBeveragesComponentState {}

class BeveragesContainer extends React.PureComponent<
	IBeveragesContainerProps,
	IBeveragesComponentState
> {
	constructor(props: IBeveragesContainerProps, state: IBeveragesComponentState) {
		super(props, state);

		this.state = this.getInitialState();
	}

	public render(): JSX.Element {
		return (
			<BeveragesComponent
				currentMeals={this.props.currentMeals}
				beverages={this.props.beverages}
				navigateTo={this.props.navigateTo}
				setCurrentMeals={this.props.setCurrentMeals}
				handleBeveragesEditorModalClicked={this.props.handleBeveragesEditorModalClicked}
				handleCustomMealModalClicked={this.props.handleCustomMealModalClicked}
			/>
		);
	}

	public componentDidMount(): void {}

	public static mapStateToProps = (
		state: RootState,
		ownProps: IBeveragesContainerOwnProps
	): IBeveragesContainerReduxMergedProps => {
		return {};
	};

	public static mapDispatchToProps = (
		dispatch: Dispatch<AnyAction>
	): IBeveragesContainerDispatchProps => {
		return {
			...Redux.bindActionCreators(
				{
					navigateTo: push,
					setCurrentMeals: setCurrentMeals
				},
				dispatch
			)
		};
	};

	private getInitialState = (): IBeveragesComponentState => {
		return {} as IBeveragesComponentState;
	};
}
export const Beverages = connect(
	BeveragesContainer.mapStateToProps,
	BeveragesContainer.mapDispatchToProps
)(BeveragesContainer);
