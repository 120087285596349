import './nutrition-fact-real-summary.scss';
import { INutritionFactsSummary } from '../../../../shared/helpers';
import IndicatorBar from '../../../atoms/IndicatorBar/IndicatorBar';
import React from 'react';

interface INutritionFactRealSummary {
	daySummary: INutritionFactsSummary;
	realSummary: INutritionFactsSummary;
}

export const NutritionFactRealSummary = (props: INutritionFactRealSummary) => {
	const fixValue = (value: number | undefined): number => {
		if (!value) return 0;
		else return Math.floor(value);
	};

	return (
		<div className="nutrition-fact-real-summary">
			<div className="nutrition-fact-real-summary__item">
				<div className="nutrition-fact-real-summary__item-value">
					{fixValue(props.realSummary.energy)} kcal
				</div>
				<div className="nutrition-fact-real-summary__item-left-value">
					<IndicatorBar
						value={props.realSummary.energy!}
						valueMax={props.daySummary.energy!}
						isCaloricType={true}
					/>
				</div>
			</div>
			<div className="nutrition-fact-real-summary__item">
				<div className="nutrition-fact-real-summary__item-value">
					{fixValue(props.realSummary.proteins)} g
				</div>
				<div className="nutrition-fact-real-summary__item-left-value">
					<IndicatorBar
						value={props.realSummary.proteins!}
						valueMax={props.daySummary.proteins!}
					/>
				</div>
			</div>
			<div className="nutrition-fact-real-summary__item">
				<div className="nutrition-fact-real-summary__item-value">
					{fixValue(props.realSummary.fats)} g
				</div>
				<div className="nutrition-fact-real-summary__item-left-value">
					<IndicatorBar
						value={props.realSummary.fats!}
						valueMax={props.daySummary.fats!}
					/>
				</div>
			</div>
			<div className="nutrition-fact-real-summary__item">
				<div className="nutrition-fact-real-summary__item-value">
					{fixValue(props.realSummary.carbs)} g
				</div>
				<div className="nutrition-fact-real-summary__item-left-value">
					<IndicatorBar
						value={props.realSummary.carbs!}
						valueMax={props.daySummary.carbs!}
					/>
				</div>
			</div>
		</div>
	);
};
