import * as React from 'react';
import * as Redux from 'redux';

import { DayMealsComponent } from './day-meals-component';
import { ICurrentMeals } from '../MealCard/Meal/meal-interfaces';
import { RootState } from '../../../business/root-store';
import { addOrUpdateCustomerMeals } from '../MealCard/CustomMeal/CustomMealEpics';
import { connect } from 'react-redux';
import { getCurrentMealsSelector } from '../MealCard/Meal/meal-selectors';
import { setCurrentMeals } from '../MealCard/Meal/meal';

import { AnyAction, Dispatch } from 'redux';
import { DietPlanDayMeal, Meal } from '../../../client';
import {
	IGroupedMeals,
	getDayPlanBeveragesFromCustomerDietPlanDayMeals,
	getGroupedCustomerDietPlanDayMeals
} from '../../../business/diet/diet-selector';

interface IDayMealsContainerReduxMergedProps {
	meals: IGroupedMeals[];
	beverages: DietPlanDayMeal[];
	currentMeals: ICurrentMeals;
}

interface IDayMealsContainerOwnProps {}

interface IDayMealsContainerDispatchProps {
	setCurrentMeals: (currentMeals: ICurrentMeals) => void;
	addOrUpdateCustomerMeals: (meals: Meal[]) => void;
}

interface IDayMealsContainerProps
	extends IDayMealsContainerOwnProps,
		IDayMealsContainerDispatchProps,
		IDayMealsContainerReduxMergedProps {}

interface IDayMealsComponentState {}

class DayMealsContainer extends React.Component<IDayMealsContainerProps, IDayMealsComponentState> {
	constructor(props: IDayMealsContainerProps, state: IDayMealsComponentState) {
		super(props, state);

		this.state = this.getInitialState();
	}

	public render(): JSX.Element {
		return (
			<DayMealsComponent
				meals={this.props.meals}
				beverages={this.props.beverages}
				currentMeals={this.props.currentMeals}
				setCurrentMeals={this.props.setCurrentMeals}
				addOrUpdateCustomerMeals={this.props.addOrUpdateCustomerMeals}
			/>
		);
	}

	public componentDidMount(): void {}

	public static mapStateToProps = (
		state: RootState,
		ownProps: IDayMealsContainerOwnProps
	): IDayMealsContainerReduxMergedProps => {
		return {
			beverages: getDayPlanBeveragesFromCustomerDietPlanDayMeals(state),
			meals: getGroupedCustomerDietPlanDayMeals(state),
			currentMeals: getCurrentMealsSelector(state)
		};
	};

	public static mapDispatchToProps = (
		dispatch: Dispatch<AnyAction>
	): IDayMealsContainerDispatchProps => {
		return {
			...Redux.bindActionCreators(
				{
					setCurrentMeals: setCurrentMeals,
					addOrUpdateCustomerMeals: addOrUpdateCustomerMeals
				},
				dispatch
			)
		};
	};

	private getInitialState = (): IDayMealsComponentState => {
		return {} as IDayMealsComponentState;
	};
}
export const DayMeals = connect(
	DayMealsContainer.mapStateToProps,
	DayMealsContainer.mapDispatchToProps
)(DayMealsContainer);
