import '../CustomMeal.scss';

import * as Yup from 'yup';

import { ActionButton } from '../../../../atoms/ActionButton/ActionButton';
import { CloseIcon } from '../../../../../shared/assets/close-icon';
import { FileUploader } from '../../../../atoms/FileUploader/FileUploader';
import { Ingredient } from '../Ingredient/IngredientContainer';
import { IngredientsIcon } from '../../../../../shared/assets/ingredients-icon';
import { PreparationIcon } from '../../../../../shared/assets/preparation-icon';
import { TextArea } from '../../../../atoms/TextArea/TextArea';
import { TextBox } from '../../../../atoms/TextBox/TextBox';
import { mealEtiquetteExpression } from '../../../../../shared/helpers';

import { Field, FieldArray, Form, Formik, FormikHelpers, FormikProps, getIn } from 'formik';
import { ICustomMealForm, ICustomMealFormValues } from './CustomMealFormInterfaces';
import { ISearchItem, SearchBox } from '../../../../atoms/search-box/search-box';
import { Meal, MealFoodProduct, MealTypeEnum } from '../../../../../client';
import { useEffect, useState } from 'react';

export const CustomMealForm = (props: ICustomMealForm) => {
	const [searchText, setSearchText] = useState('');

	const renderPreparationSection = (
		meal: Meal,
		mealIndex: number,
		mealNameWithIndex: string,
		formik: FormikProps<ICustomMealFormValues>
	): JSX.Element => {
		const file = props.mealsImages
			.find((x) => x.catalogId === meal.imagesCatalog)
			?.metadatas.find(
				(x) =>
					(x.extension === '.jpg' || x.extension === '.png') &&
					x.fileName?.startsWith('org')
			)?.lastVersion.url;
		const handleFileAdded = (e: React.ChangeEvent<HTMLInputElement>): void => {
			props.setCurrentMeals({
				...props.currentMeals,
				meals: formik.values.meals,
				mealIndex: mealIndex
			});
			props.addMealImage(e.target.files![0], mealIndex);
		};
		const removeImage = (): void => {
			formik.setFieldValue(mealNameWithIndex + '.imagesCatalog', undefined);
		};
		return (
			<div className="CustomMeal_Section">
				<div className="CustomMeal_Section_Header">
					<PreparationIcon />
					<p className="CustomMeal_Section_Name">Przygotowanie i informacje</p>
				</div>
				<Field
					disabled={!meal.isCustomMeal}
					name={mealNameWithIndex + '.name'}
					label={meal.isBeverage ? 'Nazwa napoju' : 'Nazwa posiłku'}
					placeholder={
						meal.isBeverage ? 'np. Gorąca czekolada' : 'np. Naleśniki z borówkami'
					}
					className="CustomMeal_Section_TextBox"
					errorString={
						getIn(formik.touched, mealNameWithIndex + '.name') &&
						getIn(formik.errors, mealNameWithIndex + '.name')
					}
					as={TextBox}
				/>
				<Field
					disabled={!meal.isCustomMeal}
					name={mealNameWithIndex + '.instructions'}
					rows={7}
					label={meal.isBeverage ? 'Opis napoju' : 'Opis posiłku'}
					placeholder={
						meal.isBeverage
							? 'np. pyszny niskokaloryczny shake'
							: 'np. pyszny niskokaloryczny makaron'
					}
					expressionOnValue={mealEtiquetteExpression}
					className="CustomMeal_Section_TextArea"
					errorString={
						getIn(formik.touched, mealNameWithIndex + '.instructions') &&
						getIn(formik.errors, mealNameWithIndex + '.instructions')
					}
					as={TextArea}
				/>
				{meal.isCustomMeal && (
					<FileUploader
						className="CustomMeal_Section_FileUploader"
						onChange={handleFileAdded}
						text="Dodaj zdjęcie"
						accept="image/*"
					/>
				)}
				{meal.isCustomMeal && file && (
					<>
						<CloseIcon onClick={removeImage} className="CustomMeal_Section_CloseIcon" />
						<img
							className="CustomMeal_Section_Attachment"
							src={`${process.env.REACT_APP_FILES_URL}/api/${file}`}
						/>
					</>
				)}
			</div>
		);
	};
	const renderIngredientsSection = (
		meal: Meal,
		mealIndex: number,
		mealNameWithIndex: string,
		formik: FormikProps<ICustomMealFormValues>
	): JSX.Element => {
		const handleOpenAddCustomProduct = () => {
			props.setCurrentMeals({
				...props.currentMeals,
				meals: formik.values.meals,
				mealIndex: mealIndex
			});
			props.handleCustomProductModalClicked(true);
		};

		return (
			<div className="CustomMeal_Section">
				<div className="CustomMeal_Section_Header">
					<IngredientsIcon />
					<p className="CustomMeal_Section_Name">Składniki</p>
				</div>
				<a className="CustomMeal_Section_Button" onClick={handleOpenAddCustomProduct}>
					Dodaj własny produkt
				</a>
				<FieldArray name={mealNameWithIndex + '.mealFoodProducts'}>
					{(ingredientsArrayHelpers) => {
						const handleIngredientSelected = (item: ISearchItem) => {
							formik.setFieldTouched(mealNameWithIndex + '.mealFoodProducts', true);
							let mfpToAdd: MealFoodProduct = {
								foodProductId: item.id,
								foodProduct: {
									id: item.id,
									name: item.display,
									ref: item.value.ref
								},
								carbsIn100Grams: item.value.carbsIn100Grams,
								fatsIn100Grams: item.value.fatsIn100Grams,
								energyIn100Grams: item.value.energyIn100Grams,
								proteinsIn100Grams: item.value.proteinsIn100Grams
							};
							//gdy  produktu jeszcze nie ma
							if (
								meal.mealFoodProducts?.find(
									(x) => x.foodProductId === mfpToAdd.foodProduct?.id
								) === undefined
							) {
								// name:
								// 	meal.isCustomMeal &&
								// 	meal.mealFoodProducts?.length === 0 &&
								// 	isEmptyNullOrUndefinedOrDefaultNumber(meal.name)
								// 		? mfpToAdd.foodProduct?.name
								// 		: meal.name,  //tylko custom product?
								ingredientsArrayHelpers.push(mfpToAdd);
							}
							//gdy produkt już jest
							else {
								props.toast({
									message: 'Składnik jest już wybrany',
									type: 'warning',
									title: mfpToAdd.foodProduct?.name!
								});
							}
						};
						return (
							<>
								<SearchBox
									data={props.allIngredients}
									className="CustomMeal_Section_SearchBox"
									activateOnFocus
									placeholder="Dodaj produkt z bazy"
									onSelect={handleIngredientSelected}
									onSearchChanged={setSearchText}
									value={searchText}
								/>
								{meal.mealFoodProducts?.map(
									(stateMfp: MealFoodProduct, mfpIndex: number) => {
										const mfpNameWithIndex = `meals.${mealIndex}.mealFoodProducts.${mfpIndex}.qtyInGrams`;
										const handleIngredientUpdated = (mfp: MealFoodProduct) => {
											ingredientsArrayHelpers.replace(mfpIndex, mfp);
										};
										const handleIngredientDeleted = () => {
											ingredientsArrayHelpers.remove(mfpIndex);
										};
										return (
											<>
												<Ingredient
													mfp={stateMfp}
													key={'Ingredient_' + mfpIndex}
													handleIngredientUpdated={
														handleIngredientUpdated
													}
													handleIngredientDeleted={
														handleIngredientDeleted
													}
												/>
												<span className="FormikError">
													{getIn(formik.touched, mfpNameWithIndex) &&
														getIn(formik.errors, mfpNameWithIndex)}
												</span>
											</>
										);
									}
								)}
								<span className="FormikError">
									{!Array.isArray(
										getIn(
											formik.errors,
											mealNameWithIndex + '.mealFoodProducts'
										)
									) &&
									getIn(formik.errors, mealNameWithIndex + '.mealFoodProducts')
										? getIn(
												formik.errors,
												mealNameWithIndex + '.mealFoodProducts'
										  )
										: ''}
								</span>
							</>
						);
					}}
				</FieldArray>
			</div>
		);
	};
	const renderMacrosSection = (
		meal: Meal,
		mealIndex: number,
		mealNameWithIndex: string,
		formik: FormikProps<ICustomMealFormValues>
	): JSX.Element => {
		const disabled =
			(meal.mealFoodProducts?.length ?? 0) > 0 ||
			!meal.isCustomMeal ||
			props.disableCustomMacros;
		return (
			<div className="CustomMeal_Section">
				<div className="CustomMeal_Section_Header">
					<p className="CustomMeal_Section_Name">Wartości odżywcze</p>
				</div>
				<div className="CustomMeal_Section_Ingredients">
					<Field
						type="number"
						toFixed={2}
						name={mealNameWithIndex + '.proteins'}
						suffix="g"
						rightLabel="Białko"
						placeholder="Ilość"
						className="CustomMeal_Section_Ingredients_TextBox"
						disabled={disabled}
						errorString={
							getIn(formik.touched, mealNameWithIndex + '.proteins') &&
							getIn(formik.errors, mealNameWithIndex + '.proteins')
						}
						as={TextBox}
					/>
					<Field
						type="number"
						toFixed={2}
						name={mealNameWithIndex + '.carbs'}
						rightLabel="Węglowodany"
						suffix="g"
						placeholder="Ilość"
						className="CustomMeal_Section_Ingredients_TextBox"
						disabled={disabled}
						errorString={
							getIn(formik.touched, mealNameWithIndex + '.carbs') &&
							getIn(formik.errors, mealNameWithIndex + '.carbs')
						}
						as={TextBox}
					/>
					<Field
						type="number"
						toFixed={2}
						name={mealNameWithIndex + '.fats'}
						suffix="g"
						rightLabel="Tłuszcze"
						placeholder="Ilość"
						className="CustomMeal_Section_Ingredients_TextBox"
						disabled={disabled}
						errorString={
							getIn(formik.touched, mealNameWithIndex + '.fats') &&
							getIn(formik.errors, mealNameWithIndex + '.fats')
						}
						as={TextBox}
					/>
					<Field
						type="number"
						toFixed={2}
						name={mealNameWithIndex + '.energy'}
						rightLabel="Kilokalorie (kcal)"
						placeholder="Ilość"
						className="CustomMeal_Section_Ingredients_TextBox"
						disabled={disabled}
						errorString={
							getIn(formik.touched, mealNameWithIndex + '.energy') &&
							getIn(formik.errors, mealNameWithIndex + '.energy')
						}
						as={TextBox}
					/>
				</div>
			</div>
		);
	};
	const onSubmit = (
		values: ICustomMealFormValues,
		helpers: FormikHelpers<ICustomMealFormValues>
	) => {
		document.dispatchEvent(new CustomEvent('closeModal', {}));
		props.submitCustomMeals(values.meals);
		setTimeout(() => helpers.setSubmitting(false), 2000);
	};
	return (
		<Formik
			enableReinitialize
			initialValues={{
				meals:
					props.currentMeals.meals.length > 0
						? props.currentMeals.meals
						: ([
								{
									id: 0,
									carbs: undefined,
									energy: undefined,
									fats: undefined,
									proteins: undefined,
									isCustomMeal: true,
									isBeverage:
										props.currentMeals.mealType === MealTypeEnum.Beverages
											? true
											: undefined,
									mealFoodProducts: [] as MealFoodProduct[],
									name: ''
								} as Meal
						  ] as Meal[])
			}}
			validationSchema={Yup.object<
				Partial<Record<keyof ICustomMealFormValues, Yup.AnySchema>>
			>({
				meals: Yup.array().of(
					Yup.object<Partial<Record<keyof Meal, Yup.AnySchema>>>().shape({
						name: Yup.string().min(3, 'Co najmniej 3 znaki').required('Wymagane'),
						instructions: Yup.string().nullable().min(3, 'Co najmniej 3 znaki'),
						mealFoodProducts: Yup.array()
							.min(1, 'Dodaj chociaż jeden składnik')
							.of(
								Yup.object<
									Partial<Record<keyof MealFoodProduct, Yup.AnySchema>>
								>().shape({
									qtyInGrams: Yup.number().required('Wymagane')
								})
							)
					})
				)
			})}
			onSubmit={onSubmit}
		>
			{(formik) => {
				formik.values.meals.map((meal, mealIndex) => {
					useEffect(() => {
						if (formik.dirty) {
							formik.setFieldValue(`meals[${mealIndex}]`, {
								...meal,
								energy: meal.mealFoodProducts?.reduce(
									(partialSum, mfp) =>
										partialSum +
										((mfp.energyIn100Grams ?? 0) * (mfp.qtyInGrams ?? 0)) / 100,
									0
								),
								fats: meal.mealFoodProducts?.reduce(
									(partialSum, mfp) =>
										partialSum +
										((mfp.fatsIn100Grams ?? 0) * (mfp.qtyInGrams ?? 0)) / 100,
									0
								),
								proteins: meal.mealFoodProducts?.reduce(
									(partialSum, mfp) =>
										partialSum +
										((mfp.proteinsIn100Grams ?? 0) * (mfp.qtyInGrams ?? 0)) /
											100,
									0
								),
								carbs: meal.mealFoodProducts?.reduce(
									(partialSum, mfp) =>
										partialSum +
										((mfp.carbsIn100Grams ?? 0) * (mfp.qtyInGrams ?? 0)) / 100,
									0
								)
							} as Meal);
						}
						meal.mealFoodProducts?.map((mfp, mfpIndex) => {
							formik.setFieldTouched(
								`meals.${mealIndex}.mealFoodProducts.${mfpIndex}.qtyInGrams`,
								true
							);
						});
					}, [meal.mealFoodProducts]);
				});
				return (
					<Form>
						<div className="CustomMeal_MealsContainer">
							<FieldArray name="meals">
								{(mealsArrayHelpers) => {
									return formik.values.meals.map(
										(meal: Meal, mealIndex: number) => {
											const mealNameWithIndex = `meals[${mealIndex}]`;
											return (
												<div
													className="CustomMeal_MealsContainer_Meal"
													key={mealNameWithIndex}
												>
													{renderPreparationSection(
														meal,
														mealIndex,
														mealNameWithIndex,
														formik
													)}
													{renderIngredientsSection(
														meal,
														mealIndex,
														mealNameWithIndex,
														formik
													)}
													{renderMacrosSection(
														meal,
														mealIndex,
														mealNameWithIndex,
														formik
													)}

													{/* {meal.isCustomMeal && (
                <div className="customMeal_checkbox checkbox_area">
                    <CheckBox
                        value="true"
                        checked={meal?.status === MealStatus.ToVerify}
                        // group="public-meal"
                        id="public-meal_id"
                        displayName="Dodaj do bazy jako przepis publiczny"
                        onChange={() => this.handleIsPublicClicked(meal.meal?.id)}
                    />
                </div>
            )} todo */}
												</div>
											);
										}
									);
								}}
							</FieldArray>
						</div>
						<ActionButton
							type="submit"
							className="CustomMeal_ActionButton"
							text={
								formik.values.meals.length === 1
									? props.currentMeals.mealType === MealTypeEnum.Beverages
										? 'Zapisz napój'
										: 'Zapisz posiłek'
									: 'Zapisz posiłki'
							}
							disabled={!formik.isValid}
							errorString={formik.isValid ? undefined : 'Sprawdź błędy w formularzu'}
						/>
					</Form>
				);
			}}
		</Formik>
	);
};
