export const setCookie = (key: string, value: string, expires?: Date): void => {
	let cookieValue = `${key}=${value}`;
	if (expires) {
		cookieValue += `;expires='${expires.toUTCString()}'`;
	}
	document.cookie = cookieValue;
};

export const setWithExpiryInYears = (key: string, value: string, expires: number) => {
	setWithExpiryInDays(key, value, expires * 365);
};

export const setWithExpiryInDays = (key: string, value: string, expires: number) => {
	setWithExpiryInHours(key, value, expires * 24);
};

export const setWithExpiryInHours = (key: string, value: string, expires: number) => {
	setWithExpiryInMinutes(key, value, expires * 60);
};

export const setWithExpiryInMinutes = (key: string, value: string, expires: number) => {
	setWithExpiryInSeconds(key, value, expires * 60);
};

export const setWithExpiryInSeconds = (key: string, value: string, expires: number) => {
	setWithExpiryInMiliseconds(key, value, expires * 1000);
};

export const setWithExpiryInMiliseconds = (key: string, value: string, expires: number) => {
	const expireDate: Date = new Date();
	const time = expireDate.getTime() + expires;
	expireDate.setTime(time);

	setCookie(key, value, expireDate);
};

export const getCookie = (key: string): string => {
	const decodedCookie: string = decodeURIComponent(document.cookie);
	const pairs: string[] = decodedCookie.split(/;\s*/);

	const prefix = `${key}=`;
	for (const pair of pairs) {
		if (pair.indexOf(prefix) === 0) {
			return pair.substring(prefix.length);
		}
	}
	return '';
};
