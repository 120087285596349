import './ExchangeMeal.scss';

import { ActionButton } from '../../../atoms/ActionButton/ActionButton';
import { List } from 'immutable';
import React from 'react';
import { RemoveIcon } from '../../../../shared/assets/remove-icon';
import { confirm } from 'react-confirm-box';
import { useConfirmationBoxOptions } from '../../../../hooks/useConfirmationBoxOptions';

import { ISearchItem, SearchBox } from '../../../atoms/search-box/search-box';

interface IExchangeMealComponentProps {
	onSaveClicked(items: List<number>): void;
	searchDataSource: List<ISearchItem>;
	favouritemsDataSource: List<ISearchItem>;
	onMealAdd?: (value: boolean) => void;
}

interface IExchangeMealComponentState {
	selectedItems: List<ISearchItem>;
	searchText: string;
}

export class ExchangeMealComponent extends React.Component<
	IExchangeMealComponentProps,
	IExchangeMealComponentState
> {
	constructor(props: IExchangeMealComponentProps) {
		super(props);
		this.state = this.getInitialState();
	}

	onSearchChanged = (text: string) => {
		this.setState({ ...this.state, searchText: text });
	};

	public render(): JSX.Element {
		const isModelValid = this.state.selectedItems.count() > 0;
		const dataSource = this.props.searchDataSource.toArray();
		const favourites = this.props.favouritemsDataSource.toArray();
		return (
			<div className="page exchangeMeal_main">
				<div className="exchangeMeal_header">
					<p className="header">Dodaj posiłek</p>
				</div>
				<SearchBox
					data={dataSource}
					subData={favourites}
					activateOnFocus
					className="exchangeMeal_search"
					placeholder="Wyszukaj posiłek"
					value={this.state.searchText}
					onSelect={this.handleItemSelected}
					onSearchChanged={this.onSearchChanged}
				/>
				{this.state.selectedItems.count() > 0 && (
					<div className="exchangeMeal_group">
						{this.state.selectedItems.map((meal: ISearchItem) => {
							const handleMealDeleted = async (): Promise<void> => {
								const options: any = useConfirmationBoxOptions();
								const result = await confirm(
									'Czy na pewno chcesz usunąć ten posiłek?',
									options
								);
								if (result) {
									this.setState({
										selectedItems: this.state.selectedItems.filter(
											(x) => x.id != meal.id
										)
									});
								}
							};
							return (
								<div key={meal.id} className="exchangeMeal_row">
									<div className="exchangeMeal_meal exchangeMeal_meal_selected">
										<p className="exchangeMeal_text">{meal.display}</p>
										<div className="exchangeMeal_selectedIcon">
											<RemoveIcon
												id={meal.id!}
												onClick={handleMealDeleted}
												className="exchangeMeal_remove_icon"
											/>
										</div>
									</div>
								</div>
							);
						})}
					</div>
				)}
				<ActionButton
					text={
						this.state.selectedItems.count() === 1 ? 'Dodaj posiłek' : 'Dodaj posiłki'
					}
					disabled={!isModelValid}
					className="exchangeMeal_action-button"
					onClick={this.handleOnSaveClicked}
				/>
			</div>
		);
	}

	private handleItemSelected = (newItem: ISearchItem) => {
		const isAdded =
			this.state.selectedItems
				.filter((meal: ISearchItem) => meal.id === newItem.id)
				.count() !== 0;

		if (!isAdded) {
			this.setState({ selectedItems: this.state.selectedItems.push(newItem) });
		}
	};

	private handleOnSaveClicked = async (): Promise<void> => {
		await this.props.onSaveClicked(
			this.state.selectedItems.map((item: ISearchItem) => item.id)
		);
		document.dispatchEvent(new CustomEvent('closeModal', {}));
		if (this.props.onMealAdd) {
			this.props.onMealAdd(true);
		}
	};

	private getInitialState = (): IExchangeMealComponentState => {
		return {
			searchText: '',
			selectedItems: List<ISearchItem>()
		} as IExchangeMealComponentState;
	};
}
