import { FileMetadata } from '../../files-client';

import { Document, Meal, Restaurant } from '../../client';

export const PartnershipRestaurant_Fetched = '@@Diet/Partnership/Restaurant/FETCHED';
export const PartnershipRestaurantDocuments_Fetched =
	'@@Diet/Partnership/RestaurantDocuments/FETCHED';
export const PartnershipRestaurantImages_Fetched = '@@Diet/Partnership/RestaurantImages/FETCHED';
export const PartnershipDocument_Fetched = '@@Diet/Partnership/Document/FETCHED';
export const PartnershipRestaurantMeal_Fetched = '@@Diet/Partnership/Restaurant/Meal/FETCHED';
export const PartnershipRestaurantMeal_Deleted = '@@Diet/Partnership/Restaurant/Meal/DELETED';
export const PartnershipEditRestaurantMeal_Fetched =
	'@@Diet/Partnership/Restaurant/EditMeal/FETCHED';
export const PartnershipEditRestaurantMealMfp_Fetched =
	'@@Diet/Partnership/Restaurant/EditMeal/Mfp/FETCHED';

export interface IPartnershipRestaurantFetchedAction {
	type: typeof PartnershipRestaurant_Fetched;
	restaurant: Restaurant;
}

export const partnershipRestaurantFetched = (
	restaurant: Restaurant
): IPartnershipRestaurantFetchedAction => ({
	type: PartnershipRestaurant_Fetched,
	restaurant: restaurant
});

export interface IPartnershipRestaurantDocumentsFetchedAction {
	type: typeof PartnershipRestaurantDocuments_Fetched;
	files: FileMetadata[];
}

export const partnershipRestaurantDocumentsFetched = (
	files: FileMetadata[]
): IPartnershipRestaurantDocumentsFetchedAction => ({
	type: PartnershipRestaurantDocuments_Fetched,
	files: files
});

export interface IPartnershipRestaurantImagesFetchedAction {
	type: typeof PartnershipRestaurantImages_Fetched;
	images: FileMetadata[];
}

export const partnershipRestaurantImagesFetched = (
	images: FileMetadata[]
): IPartnershipRestaurantImagesFetchedAction => ({
	type: PartnershipRestaurantImages_Fetched,
	images: images
});

export interface IPartnershipDocumentFetchedAction {
	type: typeof PartnershipDocument_Fetched;
	document: Document;
}

export const partnershipDocumentFetched = (
	document: Document
): IPartnershipDocumentFetchedAction => ({
	type: PartnershipDocument_Fetched,
	document: document
});

export interface IPartnershipRestaurantMealFetchedAction {
	type: typeof PartnershipRestaurantMeal_Fetched;
	meal: Meal;
}

export const partnershipRestaurantMealFetched = (
	meal: Meal
): IPartnershipRestaurantMealFetchedAction => ({
	type: PartnershipRestaurantMeal_Fetched,
	meal: meal
});

export interface IPartnershipRestaurantMealDeletedAction {
	type: typeof PartnershipRestaurantMeal_Deleted;
	id: number;
}

export const partnershipRestaurantMealDeleted = (
	id: number
): IPartnershipRestaurantMealDeletedAction => ({
	type: PartnershipRestaurantMeal_Deleted,
	id: id
});

export interface IPartnershipEditRestaurantMealFetchedAction {
	type: typeof PartnershipEditRestaurantMeal_Fetched;
	meal: Meal;
}

export const partnershipEditRestaurantMealFetched = (
	meal: Meal
): IPartnershipEditRestaurantMealFetchedAction => ({
	type: PartnershipEditRestaurantMeal_Fetched,
	meal: meal
});

// export interface IPartnershipEditRestaurantMealMfpFetchedAction {
// 	type: typeof PartnershipEditRestaurantMealMfp_Fetched;
// 	mfp: MealFoodProduct;
// }

// export const partnershipEditRestaurantMealMfpFetched = (
// 	mfp: MealFoodProduct
// ): IPartnershipEditRestaurantMealMfpFetchedAction => ({
// 	type: PartnershipEditRestaurantMealMfp_Fetched,
// 	mfp: mfp
// });
