import './HandleResetPassword.scss';

import * as Yup from 'yup';

import { ActionButton } from '../../../atoms/ActionButton/ActionButton';
import React from 'react';
import { TextBox } from '../../../atoms/TextBox/TextBox';

import { Field, Form, Formik, FormikHelpers } from 'formik';
import {
	IHandleResetPasswordFormProps,
	IHandleResetPasswordFormValues
} from './HandleResetPasswordFormInterfaces';

const initialValues: IHandleResetPasswordFormValues = {
	password: '',
	repeatPassword: ''
};
export const HandleResetPasswordForm = ({
	handleError,
	handleResetPassword,
	guid
}: IHandleResetPasswordFormProps) => {
	const onSubmit = (
		values: IHandleResetPasswordFormValues,
		helpers: FormikHelpers<IHandleResetPasswordFormValues>
	) => {
		handleResetPassword(values.password, values.repeatPassword, guid);
		setTimeout(() => helpers.setSubmitting(false), 2000);
	};
	return (
		<Formik
			initialValues={initialValues}
			validationSchema={Yup.object<
				Partial<Record<keyof IHandleResetPasswordFormValues, Yup.AnySchema>>
			>({
				password: Yup.string()
					.min(3, 'Co najmniej 3 znaki')
					.max(30, 'Nie więcej niż 30 znaków')
					.required('Wymagane'),
				repeatPassword: Yup.string()
					.oneOf([Yup.ref('password'), null], 'Hasła muszą być takie same')
					.required('Wymagane')
			})}
			onSubmit={onSubmit}
		>
			{(formik) => (
				<Form>
					<Field
						name="password"
						label="Hasło"
						type="password"
						placeholder="Ustal nowe silne hasło"
						className="AuthHandleResetPassword__form-row"
						errorString={formik.touched.password && formik.errors.password}
						as={TextBox}
					/>
					<Field
						name="repeatPassword"
						label="Powtórz hasło"
						type="password"
						placeholder="Hasła muszą być takie same"
						className="AuthHandleResetPassword__form-row"
						errorString={formik.touched.repeatPassword && formik.errors.repeatPassword}
						as={TextBox}
					/>
					<ActionButton
						disabled={!formik.isValid}
						errorString={formik.isValid ? undefined : 'Sprawdź błędy w formularzu'}
						type="submit"
						className="AuthHandleResetPassword__button"
						text="Zmień hasło"
					/>
				</Form>
			)}
		</Formik>
	);
};
