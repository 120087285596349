import * as React from 'react';
import * as Redux from 'redux';

import { CopyDayComponent } from './CopyDayComponent';
import { CopyDayEpic } from './CopyDayEpics';
import { RootState } from '../../../../business/root-store';
import { connect } from 'react-redux';
import moment from 'moment';
import { push } from 'connected-react-router';

import { AddToastPayload, actions } from 'react-redux-toastr';
import { AnyAction, Dispatch } from 'redux';
import {
	getCustomerDietPlan,
	getCustomerDietPlanDayDateLocal
} from '../../../../business/diet/diet-selector';

interface ICopyDayContainerReduxMergedProps {
	maxDateUtc: Date;
	minDateUtc: Date;
	currentDietPlanDayUtc: Date;
}

interface ICopyDayContainerOwnProps {}

interface ICopyDayContainerDispatchProps {
	navigateTo: (path: string, state?: any) => void;
	copyDay(fromDay: Date, toDay: Date): void;
	toast: (message: AddToastPayload) => void;
}

interface ICopyDayContainerProps
	extends ICopyDayContainerOwnProps,
		ICopyDayContainerDispatchProps,
		ICopyDayContainerReduxMergedProps {}

interface ICopyDayComponentState {}

class CopyDayContainer extends React.Component<ICopyDayContainerProps, ICopyDayComponentState> {
	constructor(props: ICopyDayContainerProps, state: ICopyDayComponentState) {
		super(props, state);
		this.state = this.getInitialState();
	}

	public componentDidMount(): void {}

	public render(): JSX.Element {
		return (
			<CopyDayComponent
				maxDateUtc={this.props.maxDateUtc}
				minDateUtc={this.props.minDateUtc}
				currentDietPlanDayUtc={this.props.currentDietPlanDayUtc}
				copyDay={this.props.copyDay}
				onCloseClicked={this.props.navigateTo}
				toast={this.props.toast}
			/>
		);
	}

	public static mapStateToProps = (
		state: RootState,
		ownProps: ICopyDayContainerOwnProps
	): ICopyDayContainerReduxMergedProps => {
		const customerDietPlan = getCustomerDietPlan(state);
		return {
			maxDateUtc:
				customerDietPlan && customerDietPlan.endDate
					? moment.utc(customerDietPlan.endDate).toDate()
					: moment.utc().toDate(),
			minDateUtc:
				customerDietPlan && customerDietPlan.startDate
					? moment.utc(customerDietPlan.startDate).toDate()
					: moment.utc().toDate(),
			currentDietPlanDayUtc: getCustomerDietPlanDayDateLocal(state)
		};
	};

	public static mapDispatchToProps = (
		dispatch: Dispatch<AnyAction>
	): ICopyDayContainerDispatchProps => {
		return {
			...Redux.bindActionCreators(
				{
					navigateTo: push,
					copyDay: CopyDayEpic,
					toast: actions.add
				},
				dispatch
			)
		};
	};

	private getInitialState = (): ICopyDayComponentState => {
		return {} as ICopyDayComponentState;
	};
}
export const CopyDay = connect(
	CopyDayContainer.mapStateToProps,
	CopyDayContainer.mapDispatchToProps
)(CopyDayContainer);
