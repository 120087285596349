import './StepIcon.scss';

import { CheckIcon } from '../../../../shared/assets/check-icon';
import React from 'react';
import { useDefaultId } from '../../../../hooks/use-default-id';

export interface IStepIconProps {
	isFinished: boolean;
	label: string;
	navigationElementId: string;
	stepNumber: number;
	onStepClick(stepNumber: number): void;
	isActive?: boolean;
	id?: string;
}

export const StepIcon: React.FC<IStepIconProps> = ({
	isActive,
	isFinished,
	label,
	stepNumber,
	navigationElementId,
	onStepClick,
	id
}: IStepIconProps) => {
	const handleClick = () => {
		onStepClick && onStepClick(stepNumber);
	};
	const isChecked = isActive || isFinished;
	return (
		<div
			id={useDefaultId(id)}
			className="step-icon"
			data-navigation-element-id={navigationElementId}
		>
			<div
				className={`step-icon__circle ${isChecked ? 'step-icon__circle--complete' : ''}`}
				onClick={handleClick}
			>
				{isChecked && <CheckIcon />}
			</div>
			<span className="step-icon__label">{label}</span>
		</div>
	);
};
