import { IPaymentChannelFormData } from './PaymentChannelFormInterfaces';

import { PaymentChannel, PostPaymentRequest } from '../../../../../client';
import {
	getActiveChannel,
	getActiveDietPlanPrice,
	getActiveDietType,
	getCashTransferFormAgreements,
	getCashTransferFormValues,
	getIsCashTransferFromValid,
	getPaymentChannels,
	getPaymentResponseRedirectUrl
} from '../../../../../business/order/order.selectors';
import {
	getPaymentInfoFromLoggedCustomer,
	getPymentChanels,
	makePayment,
	orderActions
} from '../../../../../business/order/order.actions';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

export const usePaymentsChannelFormData = () => {
	const dispatch = useDispatch();
	const dietPlan = useSelector(getActiveDietPlanPrice);
	const dietType = useSelector(getActiveDietType);
	const activePaymentChannel = useSelector(getActiveChannel);
	const paymentChannels = useSelector(getPaymentChannels);
	const cashTransferFormValues = useSelector(getCashTransferFormValues);
	const cashTansferFormAgreements = useSelector(getCashTransferFormAgreements);
	const isCashTransferFormValid = useSelector(getIsCashTransferFromValid);
	const redirectUrl = useSelector(getPaymentResponseRedirectUrl);

	useEffect(() => {
		dispatch(getPaymentInfoFromLoggedCustomer());
	}, []);
	const [pendingPaymentsChannel, setPendingPaymentsChannel] = useState(false);

	const paymentHandler = () => {
		const paymentPayload: PostPaymentRequest = {
			paymentRequest: {
				amount:
					dietPlan.discountCodePricePerMonth !== undefined
						? dietPlan.discountCodePricePerMonth < dietPlan.regularPricePerMonth
							? dietPlan.discountCode?.priceAfterDiscount
							: dietPlan.dietPlanDefinition.priceNet
						: dietPlan.dietPlanDefinition.priceNet,
				currency: 'PLN',
				referer: 'dietdesigner',
				paymentChannel: activePaymentChannel?.id,
				title: `Diet Designer Zakup Subskrypcji z dnia ${new Date().toLocaleString()}`,
				description: `dietType: ${dietType.name}, dietPlan: ${dietType.name}`,
				// todo: should be get from logged user profile, not state
				email: cashTransferFormValues.email,
				firstName: cashTransferFormValues.firstName,
				surname: cashTransferFormValues.surname,
				street: cashTransferFormValues.street,
				house: cashTransferFormValues.house,
				flat: cashTransferFormValues.flat,
				postCode: cashTransferFormValues.postCode,
				city: cashTransferFormValues.city,
				country: 'Poland',
				additionalData: JSON.stringify({
					name: dietPlan.dietPlanDefinition.name,
					dietDays: dietPlan.dietPlanDefinition.dietDays,
					priceNet: dietPlan.dietPlanDefinition.priceNet,
					productId: dietPlan.dietPlanDefinition.id,
					priceAfterDiscount:
						dietPlan.discountCodePricePerMonth !== undefined
							? dietPlan.discountCodePricePerMonth < dietPlan.regularPricePerMonth
								? dietPlan.discountCode?.priceAfterDiscount
								: dietPlan.dietPlanDefinition.priceNet
							: dietPlan.dietPlanDefinition.priceNet
				})
			}
		};

		dispatch(makePayment(paymentPayload));
	};

	const setPaymentChannel = (channel: PaymentChannel) => () => {
		dispatch(orderActions.onSetActiveChannel(channel));
	};

	const cashTransferFormAgreementHandler = (agreementFieldKey: string, isChecked: boolean) => {
		dispatch(orderActions.onCashTransferFormAgreementChange(agreementFieldKey, isChecked));
	};

	const cashTransferFormValuehandler = (fieldKey: string, value: string) => {
		dispatch(orderActions.onCashTransferFormValueChange(fieldKey, value));
	};

	const isPaymentAvailable =
		activePaymentChannel.id &&
		isCashTransferFormValid &&
		cashTansferFormAgreements.dataProcessingAgrement &&
		cashTansferFormAgreements.cashTransferStatuteAgreement;

	useEffect(() => {
		if (!paymentChannels.length && !pendingPaymentsChannel) {
			setPendingPaymentsChannel(true);
			dispatch(getPymentChanels());
		} else if (pendingPaymentsChannel && paymentChannels?.length) {
			setPendingPaymentsChannel(false);
		}
	}, [paymentChannels, pendingPaymentsChannel]);

	useEffect(() => {
		if (redirectUrl) {
			window.open(redirectUrl, '_self');
		}
	}, [redirectUrl]);

	return {
		cashTransferFormAgreementHandler,
		cashTransferFormValueHandler: cashTransferFormValuehandler,
		setPaymentChannel,
		paymentHandler,
		activePaymentChannel,
		paymentChannels,
		isPaymentAvailable,
		isPaymentStatuteAccspted: cashTansferFormAgreements.cashTransferStatuteAgreement,
		isPersonalDataProcessingAgrementAccepted: cashTansferFormAgreements.dataProcessingAgrement,
		cashTransferFormValues
	} as IPaymentChannelFormData;
};
