import * as React from 'react';
import * as Redux from 'redux';

import { BeverageItemComponent } from './beverage-item-component';
import { RootState } from '../../../../business/root-store';
import { connect } from 'react-redux';
import { deleteDietPlanDayMeal } from '../beverages-epics';

import { AnyAction, Dispatch } from 'redux';
import { DietPlanDayMeal, MealTypeEnum } from '../../../../client';

interface IBeverageItemContainerReduxMergedProps {}

interface IBeverageItemContainerOwnProps {
	dietPlanDayMeal: DietPlanDayMeal;
}

interface IBeverageItemContainerDispatchProps {
	deleteBeverage: (mealId: number, mealType: MealTypeEnum) => void;
}

interface IBeverageItemContainerProps
	extends IBeverageItemContainerOwnProps,
		IBeverageItemContainerDispatchProps,
		IBeverageItemContainerReduxMergedProps {}

interface IBeverageItemContainerState {}

class BeverageItemContainer extends React.Component<
	IBeverageItemContainerProps,
	IBeverageItemContainerState
> {
	constructor(props: IBeverageItemContainerProps, state: IBeverageItemContainerState) {
		super(props, state);

		this.state = this.getInitialState();
	}

	public render(): JSX.Element {
		return (
			<BeverageItemComponent
				deleteBeverage={this.props.deleteBeverage}
				dietPlanDayMeal={this.props.dietPlanDayMeal}
			/>
		);
	}

	public componentDidMount(): void {}

	public static mapStateToProps = (
		state: RootState,
		ownProps: IBeverageItemContainerOwnProps
	): IBeverageItemContainerReduxMergedProps => {
		return {};
	};

	public static mapDispatchToProps = (
		dispatch: Dispatch<AnyAction>
	): IBeverageItemContainerDispatchProps => {
		return {
			...Redux.bindActionCreators(
				{
					deleteBeverage: deleteDietPlanDayMeal
				},
				dispatch
			)
		};
	};

	private getInitialState = (): IBeverageItemContainerState => {
		return {} as IBeverageItemContainerState;
	};
}
export const BeverageItem = connect(
	BeverageItemContainer.mapStateToProps,
	BeverageItemContainer.mapDispatchToProps
)(BeverageItemContainer);
