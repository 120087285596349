import React from 'react';

class ChevronRightIconComponent extends React.Component {
	public render(): JSX.Element {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="13"
				height="13"
				viewBox="-1 0 7 10"
				fill="none"
			>
				<path
					d="M0.892379 9.18872L5.09668 4.98442L0.89238 0.780119"
					stroke="white"
					strokeWidth="1.68172"
				/>
			</svg>
		);
	}
}

export const ChevronRightIcon = ChevronRightIconComponent;
