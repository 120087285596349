import './RestaurantsMap.scss';

import { ActionButton } from '../../../atoms/ActionButton/ActionButton';
import React from 'react';
import ReactDOM from 'react-dom';
import { Restaurant } from '../../../../client';
import { primaryOrangeColor } from '../../../../shared/shared';

import { Cluster, ClusterStats, MarkerClusterer } from '@googlemaps/markerclusterer';

interface IRestaurantsMapComponentProps {
	restaurants: Restaurant[];
	geolocationButtonText?: string;
	currentPosition?: { lat: number; lng: number };
	handleSetCurrentPosition?: (lat: number, lng: number) => void;
	handleOpenMealsModal?: (
		isMealsModalVisible: boolean,
		restaurantClicked?: Restaurant,
		placeIdClicked?: number
	) => void;
	onIdle?: (lat: number, lng: number) => void;
	onClickMap?: (lat: number, lng: number) => void;
}

interface IRestaurantsMapComponentState {
	restaurants: Restaurant[];
	currentPosition?: { lat: number; lng: number };
	map?: google.maps.Map;
	clusterer?: MarkerClusterer;
}

export class RestaurantsMapComponent extends React.Component<
	IRestaurantsMapComponentProps,
	IRestaurantsMapComponentState
> {
	private readonly zoom: number = 16;
	public mapRef: React.MutableRefObject<HTMLDivElement> =
		React.createRef() as React.MutableRefObject<HTMLDivElement>;
	private infoWindow: google.maps.InfoWindow = new google.maps.InfoWindow();
	private readonly locationButton: HTMLDivElement = document.createElement('div');
	private readonly center = { lat: 0, lng: 0 };
	private currentPositionMarker?: google.maps.Marker;
	private markersPlaceIds: number[] = [];

	constructor(props: IRestaurantsMapComponentProps, state: IRestaurantsMapComponentState) {
		super(props, state);
		this.state = this.getInitialState();
	}
	shouldComponentUpdate(
		nextProps: Readonly<IRestaurantsMapComponentProps>,
		nextState: Readonly<IRestaurantsMapComponentState>,
		nextContext: any
	): boolean {
		let difference = nextProps.restaurants.filter(
			({ id: id1 }) => !nextState.restaurants.some(({ id: id2 }) => id2 === id1)
		);
		if (
			difference.length > 0 ||
			(nextProps.currentPosition?.lat &&
				nextProps.currentPosition.lng &&
				(nextProps.currentPosition.lat !== nextState.currentPosition?.lat ||
					nextProps.currentPosition.lng !== nextState.currentPosition?.lng))
		) {
			this.state.map?.setCenter(nextProps.currentPosition!);
			return true;
		}
		return false;
	}

	componentWillUnmount() {
		if (this.props.handleSetCurrentPosition) {
			const mapcenter = this.state.map?.getCenter();
			this.props.handleSetCurrentPosition(mapcenter?.lat() ?? 0, mapcenter?.lng() ?? 0);
		}
	}
	componentDidUpdate(
		prevProps: Readonly<IRestaurantsMapComponentProps>,
		prevState: Readonly<IRestaurantsMapComponentState>,
		snapshot?: any
	): void {
		if (
			prevProps.currentPosition?.lat &&
			prevProps.currentPosition.lng &&
			(prevProps.currentPosition.lat !== prevState.currentPosition?.lat ||
				prevProps.currentPosition.lng !== prevState.currentPosition?.lng)
		) {
			this.state.map?.setCenter(prevProps.currentPosition);
			this.setState({ ...this.state, currentPosition: prevProps.currentPosition });
		}

		let difference = prevProps.restaurants.filter(
			({ id: id1 }) => !prevState.restaurants.some(({ id: id2 }) => id2 === id1)
		);
		if (difference.length > 0) {
			difference.map((restaurant: Restaurant) => {
				this.addRestaurantMarker(
					{
						lat: restaurant.lat!,
						lng: restaurant.lng!
					},
					restaurant.name!,
					restaurant
				);
			});
			this.setState({ ...this.state, restaurants: difference.concat(prevState.restaurants) });
		}
	}

	async componentDidMount() {
		const map = new window.google.maps.Map(this.mapRef.current, {
			center: this.props.currentPosition ?? this.center,
			zoom: this.zoom,
			styles: [
				{
					featureType: 'administrative',
					elementType: 'geometry',
					stylers: [
						{
							visibility: 'off'
						}
					]
				},
				{
					featureType: 'poi',
					stylers: [
						{
							visibility: 'off'
						}
					]
				},
				{
					featureType: 'road',
					elementType: 'labels.icon',
					stylers: [
						{
							visibility: 'off'
						}
					]
				},
				{
					featureType: 'transit',
					stylers: [
						{
							visibility: 'off'
						}
					]
				}
			]
		});
		const clusterer = new MarkerClusterer({
			map: map,
			renderer: {
				render(cluster: Cluster, stats: ClusterStats) {
					return new google.maps.Marker({
						label: {
							text: String(cluster.count),
							color: 'white',
							fontSize: '12px'
						},
						position: cluster.position,
						icon: '/img/circle.png',
						zIndex: Number(google.maps.Marker.MAX_ZINDEX) + cluster.count
					});
				}
			}
		});
		await this.setState({
			...this.state,
			map: map,
			clusterer: clusterer
		});

		map.addListener('idle', () => {
			const mapcenter = map.getCenter();
			const pos = { lat: mapcenter?.lat() ?? 0, lng: mapcenter?.lng() ?? 0 };
			if (this.props.handleSetCurrentPosition) {
				this.props.handleSetCurrentPosition(pos.lat, pos.lng);
			}
			if (this.props.onIdle) {
				this.props.onIdle!(pos.lat, pos.lng);
			}
		});
		if (this.props.onClickMap) {
			map.addListener('click', (mapsMouseEvent: any) => {
				this.props.onClickMap!(mapsMouseEvent.latLng.lat(), mapsMouseEvent.latLng.lng());
			});
		}

		if (this.props.geolocationButtonText) {
			ReactDOM.render(
				<ActionButton
					onClick={this.handleClickGeolocation}
					className="RestaurantsMap_actionButton"
					text={this.props.geolocationButtonText}
				/>,
				this.locationButton
			);
			map.controls[google.maps.ControlPosition.BOTTOM_CENTER].push(this.locationButton);
		}

		if (this.props.currentPosition?.lat && this.props.currentPosition.lng) {
			this.state.map?.setCenter(this.props.currentPosition);
			this.state.map?.setZoom(this.zoom);
		} else {
			this.handleClickGeolocation();
		}
		this.props.restaurants.map((restaurant: Restaurant) => {
			this.addRestaurantMarker(
				{
					lat: restaurant.lat!,
					lng: restaurant.lng!
				},
				restaurant.name!,
				restaurant
			);
		});
	}

	public render(): JSX.Element {
		return <div ref={this.mapRef} id="map" />;
	}
	private addRestaurantMarker = (
		position: { lat: number; lng: number },
		name?: string,
		restaurant?: Restaurant,
		placeId?: number
	) => {
		const marker = new google.maps.Marker({
			position: position,
			icon: restaurant ? '/img/logoMaps.svg' : '/img/logoMapsGray.svg',
			label: name
				? {
						text: name,
						color: restaurant ? primaryOrangeColor : '#708489',
						className: 'RestaurantsMap_icon'
				  }
				: null,
			map: this.state.map,
			animation: restaurant ? google.maps.Animation.BOUNCE : null
		});
		this.state.clusterer?.addMarker(marker);
		google.maps.event.addListener(this.state.map!, 'maptypeid_changed', () => {
			let label = marker.getLabel();
			label!.color =
				this.state.map?.getMapTypeId() === 'roadmap'
					? restaurant
						? primaryOrangeColor
						: '#708489'
					: 'white';
			marker.setLabel(label);
		});
		if (this.props.handleOpenMealsModal) {
			google.maps.event.addListener(marker, 'click', () => {
				this.props.handleOpenMealsModal!(true, restaurant, placeId);
			});
		}
		if (restaurant) {
			google.maps.event.addListener(this.state.map!, 'idle', () => {
				marker.setAnimation(google.maps.Animation.BOUNCE);
			});
		}
		if (placeId) {
			this.markersPlaceIds.push(placeId);
		}
	};

	private handleClickGeolocation = () => {
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(
				(position: GeolocationPosition) => {
					const pos = {
						lat: position.coords.latitude,
						lng: position.coords.longitude
					};
					this.renderCurrentPositionMarker(pos);
				},
				() => {
					this.handleLocationError(true, this.infoWindow, this.state.map?.getCenter()!);
				}
			);
		} else {
			this.handleLocationError(false, this.infoWindow, this.state.map?.getCenter()!);
		}
	};

	private renderCurrentPositionMarker = (pos: { lat: number; lng: number }) => {
		this.currentPositionMarker?.setMap(null);
		this.currentPositionMarker = new google.maps.Marker({
			position: pos,
			icon: {
				path: google.maps.SymbolPath.CIRCLE,
				scale: 10,
				fillOpacity: 1,
				strokeWeight: 2,
				fillColor: '#5384ED',
				strokeColor: '#ffffff'
			},
			map: this.state.map,
			clickable: false
		});
		this.state.map?.setCenter(pos);
		this.state.map?.setZoom(this.zoom);
	};

	private handleLocationError = (
		browserHasGeolocation: boolean,
		infoWindow: google.maps.InfoWindow,
		pos: google.maps.LatLng
	) => {
		infoWindow.setPosition(pos);
		infoWindow.setContent(
			browserHasGeolocation
				? 'Nie udzieliłeś zgody na pobranie lokalizacji.'
				: 'Twoja przeglądarka nie obsługuje lokalizacji.'
		);
		infoWindow.open(this.state.map);
	};

	private getInitialState = (): IRestaurantsMapComponentState => {
		return {
			restaurants: this.props.restaurants,
			currentPosition: this.props.currentPosition
		} as IRestaurantsMapComponentState;
	};
}

// checkIfPlaceIdExistInArray = (placeId: number) => {
//     let placeIds = this.props.restaurants.map((x) => x.placeId!).concat(this.markersPlaceIds);
//     return placeIds.includes(placeId);
// };
// determineIfIsRestaurant(toBeDetermined: any): toBeDetermined is Restaurant {
//     if ((toBeDetermined as Restaurant).name) {
//         return true;
//     }
//     return false;
// }

// handleOnSelect = (item: ISearchItem) => {
//     //todo
//     if (this.determineIfIsRestaurant(item.value)) {
//         this.state.map?.setCenter({ lat: item.value.lat!, lng: item.value.lng! });
//         this.state.map?.setZoom(this.zoomOnSearch);
//     } else {
//         const position = {
//             lat: +item.value.y,
//             lng: +item.value.x
//         } as google.maps.LatLngLiteral;
//         this.state.map?.setCenter(position);
//         this.state.map?.setZoom(this.zoom);
//     }
// };

// handleOnKeyUp = async (event: React.KeyboardEvent<HTMLInputElement>, text: string) => {
//     if (event.key === 'Enter') {
//         const _this = this;
//         axios
//             .get('https://api.mapy.cz/geocode', {
//                 params: {
//                     query: text,
//                     lang: 'pl'
//                 }
//             })
//             .then(function (response) {
//                 xml2js.parseStringPromise(response.data).then((x) => {
//                     const responseItems = x.result.point[0].item as any[];
//                     const arrayUniqueByKey = [
//                         ...new Map(
//                             responseItems.map((item: any) => [item.$['title'], item.$])
//                         ).values()
//                     ];
//                     const items = arrayUniqueByKey.map((x: any) => {
//                         return {
//                             display: x.title,
//                             value: x
//                         } as ISearchItem;
//                     });
//                     _this.setState({ ..._this.state, searchItems: items });
//                 });
//             });
//     }
// };

// nearbySearchCallback = async (
// 	results: google.maps.places.PlaceResult[] | null,
// 	status: google.maps.places.PlacesServiceStatus
// ) => {
// 	if (status === google.maps.places.PlacesServiceStatus.OK && results) {
// 		results.map((x) => {
// 			if (
// 				x.place_id &&
// 				!this.props.checkIfPlaceIdExistInArray(x.place_id) &&
// 				!this.bannedTypes.some((type) => x.types?.includes(type))
// 			) {
// 				this.props.addRestaurantMarker(
// 					{
// 						lat: x.geometry?.location?.lat(),
// 						lng: x.geometry?.location?.lng()
// 					} as google.maps.LatLngLiteral,
// 					normalizeWords(x.name ?? ''),
// 					undefined,
// 					x.place_id
// 				);
// 			}
// 		});
// 	}
// };
