import { AuthenticationApi } from '../../../../auth-client';
import { FileApi } from '../../../../files-client';
import { IImageFile } from '../../../../shared/interfaces/IImageFile';
import { RootState } from '../../../../business/root-store';
import { ThunkAction } from 'redux-thunk';
import { actions } from 'react-redux-toastr';
import { documentsUrls } from '../../../../shared/shared';
import { getPartnershipDocumentByUrl } from '../PartnershipPanel/PartnershipPanelEpics';
import { push } from 'connected-react-router';
import { restaurantImagesFetched } from '../../../../business/Restaurants/RestaurantsActions';
import { toBase64 } from '../../../../shared/helpers';

import { AuxiliaryApi, Request, Restaurant } from '../../../../client';
import { GetApiConfig, GetAuthConfig, GetFileConfig } from '../../../../business/api-client-config';
import { createUrlWithParams, routes } from '../../../../business/router/path-definition';
import {
	partnershipRestaurantDocumentsFetched,
	partnershipRestaurantFetched,
	partnershipRestaurantImagesFetched
} from '../../../../business/Partnership/PartnershipActions';

export const postRestaurantRequestForm =
	(
		request: Request,
		restaurant: Restaurant,
		dontCreateAccount?: boolean
	): ThunkAction<any, RootState, any, any> =>
	(dispatch, getState) => {
		const state = getState();
		const api = new AuxiliaryApi(GetApiConfig(state));
		const authapi = new AuthenticationApi(GetAuthConfig(getState()));

		if (!dontCreateAccount) {
			authapi
				.create({
					email: request.mail,
					username: request.mail,
					initialRole: 'Restaurant',
					systemUniqueId: 'DIET'
				})
				.then((authData) => {
					api.postRestaurantRequest({
						request: request,
						restaurant: restaurant
					}).then((x) => {
						api.postAssignUserToRestaurant({
							email: request.mail,
							userId: +authData.data.authData?.userId!
						});
						dispatch(
							push(
								createUrlWithParams(routes.static.document.path, {
									documentUrl: documentsUrls.thanks.partnership
								})
							)
						);
					});
				});
		} else {
			api.postRestaurantRequest({
				request: request,
				restaurant: restaurant
			}).then((x) => {
				dispatch(getPartnershipDocumentByUrl(documentsUrls.partnership.wait));
				dispatch(partnershipRestaurantFetched(x.data.restaurant!));
				dispatch(
					actions.add({ type: 'success', message: 'Pomyślnie zaktualizowano formularz' })
				);
			});
		}
	};

export const uploadPartnershipRestaurantImages =
	(images: File[], fileCatalogId: number): ThunkAction<any, RootState, any, any> =>
	(dispatch, getState) => {
		const state = getState();
		const api = new FileApi(GetFileConfig(state));
		const promises = images.map(async (file) => {
			let base64 = await toBase64(file);
			base64 = base64.substr(base64.indexOf(',') + 1);
			return api.postImageFile({
				imageData: base64,
				name: file.name,
				catalogId: fileCatalogId,
				resize: true,
				generateThumbminal: true,
				width: 1280,
				height: 720,
				keepResizeRatio: true,
				thumbminalHeight: 225,
				thumbminalWidth: 400
			});
		});
		Promise.all(promises).then(() => {
			dispatch(getPartnershipRestaurantImages(fileCatalogId));
		});
	};

export const removePartnershipRestaurantImage =
	(imageGuid: string): ThunkAction<any, RootState, any, any> =>
	(dispatch, getState) => {
		const state = getState();
		const api = new FileApi(GetFileConfig(state));
		api.deleteFile(imageGuid).then((x) => {
			dispatch(getPartnershipRestaurantImages());
		});
	};

export const getPartnershipRestaurantDocuments =
	(): ThunkAction<any, RootState, any, any> => (dispatch, getState) => {
		const state = getState();
		const api = new FileApi(GetFileConfig(state));
		if (state.administration.partnership.restaurant.documentsFileCatalogId) {
			api.getFilesFromCatalog(
				state.administration.partnership.restaurant.documentsFileCatalogId
			).then((x) => {
				if (x.data.fileMetadatas && x.data.fileMetadatas.length > 0) {
					dispatch(partnershipRestaurantDocumentsFetched(x.data.fileMetadatas));
				}
			});
		}
	};

export const getPartnershipRestaurantImages =
	(fileCatalogId?: number | null): ThunkAction<any, RootState, any, any> =>
	(dispatch, getState) => {
		const state = getState();
		const api = new FileApi(GetFileConfig(state));
		if (!fileCatalogId) {
			fileCatalogId =
				state.administration.partnership.restaurant.newImagesFileCatalogId ??
				state.administration.partnership.restaurant.imagesFileCatalogId;
		}

		if (fileCatalogId) {
			api.getFilesFromCatalog(fileCatalogId).then((x) => {
				if (x.data.fileMetadatas && x.data.fileMetadatas.length > 0) {
					dispatch(partnershipRestaurantImagesFetched(x.data.fileMetadatas));
				}
			});
		}
	};

export const getPartnershipRestaurantMealsImages =
	(): ThunkAction<any, RootState, any, any> => (dispatch, getState) => {
		const state = getState();
		const api = new FileApi(GetFileConfig(state));
		state.administration.partnership.restaurant.meals?.forEach((meal) =>
			api.getFilesFromCatalog(meal.imagesCatalog!).then((x) => {
				dispatch(
					restaurantImagesFetched({
						metadatas: x.data.fileMetadatas ?? [],
						catalogId: meal.imagesCatalog!
					} as IImageFile)
				);
			})
		);
	};

export const addPartnershipRestaurantImages =
	(restaurant: Restaurant, imagesToAdd: File[]): ThunkAction<any, RootState, any, any> =>
	(dispatch, getState) => {
		const state = getState();
		const api = new FileApi(GetFileConfig(state));
		if (!restaurant.imagesFileCatalogId) {
			api.postFileCatalog({ name: 'Restaurant' }).then((x) => {
				restaurant.imagesFileCatalogId = x.data.catalogId!;
				dispatch(partnershipRestaurantFetched(restaurant));
				dispatch(
					uploadPartnershipRestaurantImages(imagesToAdd, restaurant.imagesFileCatalogId)
				);
			});
		} else
			dispatch(
				uploadPartnershipRestaurantImages(imagesToAdd, restaurant.imagesFileCatalogId)
			);
	};
