import { FileInfo } from '../../../../files-client';
import { List } from 'immutable';
import { ProductRefNamePair } from '../../../../client';

export const Products_Fetched = '@@Diet/Products/FETCHED';
export const CustomMealImage_Saved = '@@Diet/CustomMealImage/SAVED';
export const CustomMealImage_Removed = '@@Diet/CustomMealImage/REMOVED';
export interface IProductsFetchedAction {
	type: typeof Products_Fetched;
	payload: { products: List<ProductRefNamePair> };
}

export const productsFetched = (
	payload: IProductsFetchedAction['payload']
): IProductsFetchedAction => ({
	type: Products_Fetched,
	payload
});

export interface ICustomMealImageSavedAction {
	type: typeof CustomMealImage_Saved;
	payload: { catalogId: number; fileInfos: FileInfo[] };
}

export const customMealImageSaved = (
	payload: ICustomMealImageSavedAction['payload']
): ICustomMealImageSavedAction => ({
	type: CustomMealImage_Saved,
	payload
});

export interface ICustomMealImageRemovedAction {
	type: typeof CustomMealImage_Removed;
}

export const customMealImageRemoved = (): ICustomMealImageRemovedAction => ({
	type: CustomMealImage_Removed
});
