import { DietSummaryComponent } from '../../../molecules/DietSummaryComponent';
import { getCheapestDietPlanPrice } from '../../../molecules/staticData/staticDataSelectors';
import { getUsername } from '../../../../business/auth/auth-selector';
import { matchDietTypeDescription } from './DietSummaryContainer.auxiliaries';
import { orderActions } from '../../../../business/order/order.actions';
import { routes } from '../../../../business/router/path-definition';
import { useEffect } from 'react';

import {
	getActiveDietPlanPrice,
	getActiveDietType,
	getIsUserRegisteredWhileOrdering
} from '../../../../business/order/order.selectors';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

interface IDietSummaryContainer {
	adBanner?: boolean;
}

export const DietSummaryContainer: React.FC<IDietSummaryContainer> = (props) => {
	const history = useHistory();
	const location = useLocation();
	const dispatch = useDispatch();

	const isUserLogged = useSelector(getUsername).length > 0;
	const isUserRegistered = useSelector(getIsUserRegisteredWhileOrdering);
	const activeDietPlanPrice = useSelector(getActiveDietPlanPrice);
	const activeDietType = useSelector(getActiveDietType);
	const cheapest = useSelector(getCheapestDietPlanPrice);
	useEffect(() => {
		if (cheapest.regularPricePerMonth > 0) {
			dispatch(orderActions.onSetActiveDietPlan(cheapest));
		}
	}, [cheapest]);
	const currentDietType = matchDietTypeDescription(activeDietType);

	const isConfirmationDietButtonDisplayed = location.pathname.includes(
		routes.customer.order.plan.path
	);

	const onDietConfirmation = () => {
		if (isUserLogged || isUserRegistered) {
			history.push(routes.customer.order.payment.main.path);
		} else {
			history.push(routes.customer.order.auth.path);
		}
	};

	const displayDietAndPackageTypeView = () => {
		history.push(routes.customer.order.plan.path);
	};

	return (
		<DietSummaryComponent
			activeDietPlanPrice={activeDietPlanPrice}
			dietType={currentDietType}
			isConfirmationDietButtonDisplayed={isConfirmationDietButtonDisplayed}
			onDietConfirmation={onDietConfirmation}
			displayDietAndPackageTypeView={displayDietAndPackageTypeView}
			adBanner={props.adBanner}
		/>
	);
};
