import { RootState } from '../root-store';

export const getPaymentChannels = (state: RootState) => state.order.paymentChannels;
export const getActiveDietPlanPrice = (state: RootState) => state.order.activeDietPlanPrice;
export const getActiveDietType = (state: RootState) => state.order.activeDietType;
export const getActiveChannel = (state: RootState) => state.order.activeChannel;
export const getCashTransferFormValues = (state: RootState) => state.order.cashTransferFormValues;
export const getIsCashTransferFromValid = (state: RootState) => state.order.isCashTransferFormValid;
export const getCashTransferFormAgreements = (state: RootState) =>
	state.order.cashTransferFormAgreements;
export const getPaymentResponseRedirectUrl = (state: RootState) =>
	state.order.paymentResponse.redirectUrl;
export const getIsUserRegisteredWhileOrdering = (state: RootState) =>
	state.order.isUserRegisteredWhileOrdering;
