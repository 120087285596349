import { AnalyzeFoodAction } from './Interfaces';
import { ImageObject } from '../../files-client';
import { Reducer } from 'redux';
import {
	ANALYZE_FOOD_IMAGE_ERROR,
	ANALYZE_FOOD_IMAGE_RESPONSE,
	ANALYZE_FOOD_REOMVE_INGREDIENT_RESPONSE,
	REOMVE_ANALYZED_FOOD
} from './mealAnalysis.actions';

interface IAnalyzeMealReduxState {
	caloriesEstimated: number;
	fileId: string;
	fileUrl: string;
	imageObject: ImageObject[];
}

const initialState: IAnalyzeMealReduxState = {
	caloriesEstimated: 0,
	fileId: '',
	fileUrl: '',
	imageObject: []
};

export const mealAnalysisReducer: Reducer<IAnalyzeMealReduxState, AnalyzeFoodAction> = (
	state = initialState,
	action
): IAnalyzeMealReduxState => {
	switch (action.type) {
		case ANALYZE_FOOD_IMAGE_RESPONSE: {
			const { caloriesEstimated, fileId, fileUrl, imageObject } = action.payload;

			return {
				...state,
				caloriesEstimated: caloriesEstimated || 0,
				fileId: fileId || '',
				fileUrl: fileUrl || '',
				imageObject: imageObject || []
			};
		}
		case ANALYZE_FOOD_REOMVE_INGREDIENT_RESPONSE: {
			const { estimatedCalories, markerId } = action.payload;

			const imageObject = state.imageObject.filter((item) => item.markerId !== markerId);

			return {
				...state,
				caloriesEstimated: estimatedCalories,
				imageObject
			};
		}
		case REOMVE_ANALYZED_FOOD:
		case ANALYZE_FOOD_IMAGE_ERROR: {
			return initialState;
		}
		default:
			return state;
	}
};
