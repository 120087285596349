import './Restaurants.scss';

import { BackLink } from '../../atoms/back-link/back-link';
import { Modal } from '../../atoms/Modal/Modal';
import { QrBarCodeScanner } from '../QrBarCodeScanner';
import React from 'react';
import { Restaurant } from '../../../client';
import { RestaurantMeals } from './RestaurantMeals/RestaurantMealsContainer';
import { RestaurantsListComponent } from './RestaurantsList/RestaurantsListComponent';
import { RestaurantsMapWrapperComponent } from './RestaurantsMap/RestaurantsMapWrapperComponent';
import { routes } from '../../../business/router/path-definition';
import { HereAPI, HereApiItem } from '../../../shared/HereApi';
import { ISearchItem, SearchBox } from '../../atoms/search-box/search-box';

interface IRestaurantsComponentProps {
	restaurants: Restaurant[];
	navigateTo: (path: string, state?: any) => void;
	getNearbyRestaurants: (lat: number, lng: number) => void;
	isSignedIn: boolean;
}

interface IRestaurantsComponentState {
	restaurantsFiltered: Restaurant[];
	skipFlag: boolean;
	restaurantsItems: ISearchItem[];
	searchAddressesItems: ISearchItem[];
	isMealsModalVisible: boolean;
	isQrScannerModalVisible: boolean;
	isMapComponentVisible: boolean;
	restaurantClicked?: Restaurant;
	placeIdClicked?: number;
	currentPosition?: { lat: number; lng: number };
	currentPositionName: string;
	restaurantSearchString: string;
}
export class RestaurantsComponent extends React.Component<
	IRestaurantsComponentProps,
	IRestaurantsComponentState
> {
	private geocodeTimer?: NodeJS.Timeout;
	private rgeocodeTimer?: NodeJS.Timeout;
	constructor(props: IRestaurantsComponentProps, state: IRestaurantsComponentState) {
		super(props, state);
		this.state = this.getInitialState();
	}
	static getDerivedStateFromProps(
		nextProps: IRestaurantsComponentProps,
		prevState: IRestaurantsComponentState
	) {
		if (nextProps.restaurants !== prevState.restaurantsFiltered && !prevState.skipFlag) {
			const restaurantsItems = nextProps.restaurants?.map((x) => {
				const wordsArray = [
					x.name,
					x.postcode,
					x.city,
					x.street,
					x.homeNumber,
					x.apartmentNumber
				];
				const fullName = wordsArray.join(' ');
				return { id: x.id, display: fullName, value: x } as ISearchItem;
			});
			return {
				restaurantsFiltered: nextProps.restaurants,
				restaurantsItems: restaurantsItems
			};
		}
		return { skipFlag: false };
	}

	componentDidMount() {
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition((position: GeolocationPosition) => {
				const pos = {
					lat: position.coords.latitude,
					lng: position.coords.longitude
				};
				this.handleOnIdleMap(pos.lat, pos.lng);
				this.setState({ currentPosition: pos });
			});
		}
	}

	handleOnBackClicked = (): void => {
		this.props.navigateTo(routes.home.path);
	};

	handleOnSelectPosition = (item: ISearchItem) => {
		const position = {
			lat: +item.value.y,
			lng: +item.value.x
		};
		this.props.getNearbyRestaurants(position.lat, position.lng);
		this.handleSetCurrentPosition(position.lat, position.lng);
	};

	handleOnSelectRestaurant = (item: ISearchItem) => {
		const restaurant = item.value as Restaurant;
		const position = { lat: restaurant.lat!, lng: restaurant.lng! };
		if (this.state.isMapComponentVisible) {
			this.setState({
				restaurantSearchString: '',
				currentPosition: position
			});
			this.props.getNearbyRestaurants(position.lat, position.lng);
		} else {
			this.handleSearchChangedRestaurant(item.display ?? '');
		}
	};

	handleOnIdleMap = (lat: number, lng: number) => {
		if (this.rgeocodeTimer) clearTimeout(this.rgeocodeTimer);
		this.rgeocodeTimer = setTimeout(() => {
			HereAPI.rgeocode(lat, lng).then((x: string) =>
				this.setState({
					currentPositionName: x
				})
			);
		}, 3000);

		this.props.getNearbyRestaurants(lat, lng);
	};

	handleSetCurrentPosition = (lat: number, lng: number) => {
		this.setState({
			currentPosition: { lat: lat, lng: lng }
		});
	};

	handleOpenMealsModal = (
		isMealsModalVisible: boolean,
		restaurantClicked?: Restaurant,
		placeIdClicked?: number
	) => {
		this.setState({
			isMealsModalVisible: isMealsModalVisible,
			restaurantClicked: restaurantClicked,
			placeIdClicked: placeIdClicked
		});
	};

	handleSearchChangedPosition = (text: string) => {
		this.setState({ currentPositionName: text });
		if (this.geocodeTimer) clearTimeout(this.geocodeTimer);
		this.geocodeTimer = setTimeout(() => {
			HereAPI.geocode(text).then((results: HereApiItem[]) => {
				const items = results.map((item: HereApiItem) => {
					return {
						display: item.title,
						value: {
							x: item.x,
							y: item.y
						}
					} as ISearchItem;
				});
				this.setState({ searchAddressesItems: items });
			});
		}, 1000);
	};

	handleSearchChangedRestaurant = (text: string) => {
		const regex = new RegExp(text.toLocaleLowerCase());
		const restaurants = this.state.restaurantsItems
			.filter((x) => regex.test(x.display?.toLocaleLowerCase() ?? ''))
			.map((x) => x.value as Restaurant);
		this.setState({
			restaurantsFiltered: restaurants,
			skipFlag: true,
			restaurantSearchString: text
		});
	};

	handleMapIconClick = () => {
		this.setState({
			isMapComponentVisible: !this.state.isMapComponentVisible,
			restaurantSearchString: ''
		});
	};

	handleQrIconClick = () => {
		this.setState({
			isQrScannerModalVisible: !this.state.isQrScannerModalVisible,
			restaurantSearchString: ''
		});
	};

	disableMealsModal = () => {
		this.setState({ isMealsModalVisible: false });
	};

	disablerScannerModal = () => {
		this.setState({ isQrScannerModalVisible: false });
	};

	public render(): JSX.Element {
		return (
			<>
				<div className="Restaurants">
					<div className="Restaurants_header">
						<BackLink
							text={
								this.props.isSignedIn
									? 'Powrót do jadłospisu'
									: 'Powrót do strony głównej'
							}
							onClick={this.handleOnBackClicked}
						/>
						<h3>Zjadłem na mieście</h3>
						<div className="Restaurants_searchSection">
							<div className="Restaurants_searchSection_searchBox_wrapper">
								<SearchBox
									data={this.state.restaurantsItems}
									disableSuggestions={!this.state.isMapComponentVisible}
									label="Restauracje"
									activateOnFocus
									placeholder="Nazwa restauracji"
									value={this.state.restaurantSearchString}
									onSelect={this.handleOnSelectRestaurant}
									onSearchChanged={this.handleSearchChangedRestaurant}
									className="Restaurants_searchSection_searchBox"
								/>
								<i
									className="fas fa-qrcode Restaurants_searchSection_mapIcon"
									onClick={this.handleQrIconClick}
								/>
							</div>

							<div className="Restaurants_searchSection_searchBox_wrapper">
								<SearchBox
									disableFilteringData={this.state.searchAddressesItems}
									label="Lokalizacja"
									activateOnFocus
									placeholder="Adres"
									value={this.state.currentPositionName}
									onSelect={this.handleOnSelectPosition}
									onSearchChanged={this.handleSearchChangedPosition}
									className="Restaurants_searchSection_searchBox"
								/>
								{this.state.isMapComponentVisible ? (
									<i
										className="fas fa-list Restaurants_searchSection_mapIcon"
										onClick={this.handleMapIconClick}
									/>
								) : (
									<i
										className="fas fa-map-marked-alt Restaurants_searchSection_mapIcon"
										onClick={this.handleMapIconClick}
									/>
								)}
							</div>
						</div>
					</div>
					<div
						className={
							this.state.isMapComponentVisible
								? 'Restaurants_container Restaurants_offPadding'
								: 'Restaurants_container'
						}
					>
						{this.state.isMapComponentVisible ? (
							<RestaurantsMapWrapperComponent
								restaurants={this.props.restaurants}
								currentPosition={this.state.currentPosition}
								handleOpenMealsModal={this.handleOpenMealsModal}
								onIdle={this.handleOnIdleMap}
								handleSetCurrentPosition={this.handleSetCurrentPosition}
								geolocationButtonText="Znajdź restauracje blisko mnie"
							/>
						) : (
							<RestaurantsListComponent
								allRestaurantsLength={this.props.restaurants.length}
								restaurants={this.state.restaurantsFiltered}
								currentPosition={this.state.currentPosition}
								handleOpenMealsModal={this.handleOpenMealsModal}
							/>
						)}
					</div>
				</div>
				{this.state.isMealsModalVisible && (
					<Modal
						show={this.state.isMealsModalVisible}
						handleStateChange={this.disableMealsModal}
					>
						<RestaurantMeals
							restaurant={this.state.restaurantClicked}
							placeId={this.state.placeIdClicked}
						/>
					</Modal>
				)}
				{this.state.isQrScannerModalVisible && (
					<Modal
						show={this.state.isQrScannerModalVisible}
						handleStateChange={this.disablerScannerModal}
					>
						<QrBarCodeScanner />
					</Modal>
				)}
			</>
		);
	}

	private getInitialState = (): IRestaurantsComponentState => {
		return {
			restaurantsItems: [],
			restaurantsFiltered: this.props.restaurants,
			searchAddressesItems: [],
			isMealsModalVisible: false,
			isMapComponentVisible: false,
			isQrScannerModalVisible: false,
			skipFlag: false,
			currentPositionName: '',
			restaurantSearchString: ''
		} as IRestaurantsComponentState;
	};
}
