import * as React from 'react';
import * as Redux from 'redux';

import { DayPickerComponent } from './DayPickerComponent';
import { DietPlanDay } from '../../../client';
import { ICurrentMeals } from '../../molecules/MealCard/Meal/meal-interfaces';
import { RootState } from '../../../business/root-store';
import { connect } from 'react-redux';
import { loadDietPlanDay } from '../../../business/diet/diet-action';
import { push } from 'connected-react-router';
import { setCurrentMeals } from '../../molecules/MealCard/Meal/meal';

import { AnyAction, Dispatch } from 'redux';
import { getDietDay, hasNextDay, hasPreviousDay } from '../../../business/diet/diet-selector';

interface IDayPickerContainerReduxMergedProps {
	currentDay?: DietPlanDay;
	hasNextDayActive: boolean;
	hasPreviousDayActive: boolean;
}

interface IDayPickerContainerOwnProps {}

interface IDayPickerContainerDispatchProps {
	loadDietPlanDay: (dietDay: number) => void;
	navigateTo(path: string, state?: any): void;
	setCurrentMeals: (currentMeals: ICurrentMeals) => void;
}

interface IDayPickerContainerProps
	extends IDayPickerContainerOwnProps,
		IDayPickerContainerDispatchProps,
		IDayPickerContainerReduxMergedProps {}

interface IDayPickerComponentState {}

class DayPickerContainer extends React.Component<
	IDayPickerContainerProps,
	IDayPickerComponentState
> {
	constructor(props: IDayPickerContainerProps, state: IDayPickerComponentState) {
		super(props, state);

		this.state = this.getInitialState();
	}

	public render(): JSX.Element {
		return (
			<DayPickerComponent
				loadDietPlanDay={this.props.loadDietPlanDay}
				currentDay={this.props.currentDay}
				hasNextDayActive={this.props.hasNextDayActive}
				hasPreviousDayActive={this.props.hasPreviousDayActive}
				navigateTo={this.props.navigateTo}
				setCurrentMeals={this.props.setCurrentMeals}
			/>
		);
	}

	public componentDidMount(): void {}

	public static mapStateToProps = (
		state: RootState,
		ownProps: IDayPickerContainerOwnProps
	): IDayPickerContainerReduxMergedProps => {
		return {
			currentDay: getDietDay(state),
			hasNextDayActive: hasNextDay(state),
			hasPreviousDayActive: hasPreviousDay(state)
		};
	};

	public static mapDispatchToProps = (
		dispatch: Dispatch<AnyAction>
	): IDayPickerContainerDispatchProps => {
		return {
			...Redux.bindActionCreators(
				{
					loadDietPlanDay: loadDietPlanDay,
					navigateTo: push,
					setCurrentMeals: setCurrentMeals
				},
				dispatch
			)
		};
	};

	private getInitialState = (): IDayPickerComponentState => {
		return {} as IDayPickerComponentState;
	};
}
export const DayPicker = connect(
	DayPickerContainer.mapStateToProps,
	DayPickerContainer.mapDispatchToProps
)(DayPickerContainer);
