import * as React from 'react';
import * as Redux from 'redux';

import { ICurrentMeals } from './meal-interfaces';
import { IDpdmWithImage } from '../meal-card-interfaces';
import { MealComponent } from './meal-component';
import { MealTypeEnum } from '../../../../client';
import { RootState } from '../../../../business/root-store';
import { connect } from 'react-redux';
import { deleteDietPlanDayMeal } from '../../Beverages/beverages-epics';
import { getCurrentMealsSelector } from './meal-selectors';
import { getCustomerId } from '../../../../business/customer/customer-settings-selector';
import { push } from 'connected-react-router';
import { setCurrentMeals } from './meal';
import { setFavouriteMeal } from './meal-epics';

import { AddToastPayload, actions } from 'react-redux-toastr';
import { AnyAction, Dispatch } from 'redux';

interface IMealContainerReduxMergedProps {
	customerId?: number;
	currentMeals: ICurrentMeals;
}

interface IMealContainerOwnProps {
	isFavouriteVisible: boolean;
	mealWithImage: IDpdmWithImage;
	onEditMealModalClicked: (value: boolean) => void;
	onCopyClicked: (value: boolean) => void;
}

interface IMealContainerDispatchProps {
	onFavouriteClicked(mealId: number): void;
	navigateTo(path: string, state?: any): void;
	deleteMealInDietPlanDayMeal(mealId: number, mealType: MealTypeEnum): void;
	setCurrentMeals: (currentMeals: ICurrentMeals) => void;
	toast: (message: AddToastPayload) => void;
}

interface IMealContainerProps
	extends IMealContainerOwnProps,
		IMealContainerDispatchProps,
		IMealContainerReduxMergedProps {}

interface IMealContainerState {}

class MealContainer extends React.Component<IMealContainerProps, IMealContainerState> {
	constructor(props: IMealContainerProps, state: IMealContainerState) {
		super(props, state);

		this.state = this.getInitialState();
	}

	public render(): JSX.Element {
		return (
			<MealComponent
				onEditMealModalClicked={this.props.onEditMealModalClicked}
				onCopyClicked={this.props.onCopyClicked}
				currentMeals={this.props.currentMeals}
				mealWithImage={this.props.mealWithImage}
				isFavouriteVisible={this.props.isFavouriteVisible}
				customerId={this.props.customerId}
				onFavouriteClicked={this.props.onFavouriteClicked}
				deleteMealInDietPlanDayMeal={this.props.deleteMealInDietPlanDayMeal}
				setCurrentMeals={this.props.setCurrentMeals}
				navigateTo={this.props.navigateTo}
				toast={this.props.toast}
			/>
		);
	}

	public static mapStateToProps = (
		state: RootState,
		ownProps: IMealContainerOwnProps
	): IMealContainerReduxMergedProps => {
		return { customerId: getCustomerId(state), currentMeals: getCurrentMealsSelector(state) };
	};

	public static mapDispatchToProps = (
		dispatch: Dispatch<AnyAction>
	): IMealContainerDispatchProps => {
		return {
			...Redux.bindActionCreators(
				{
					navigateTo: push,
					onFavouriteClicked: setFavouriteMeal,
					deleteMealInDietPlanDayMeal: deleteDietPlanDayMeal,
					setCurrentMeals: setCurrentMeals,
					toast: actions.add
				},
				dispatch
			)
		};
	};

	private getInitialState = (): IMealContainerState => {
		return {} as IMealContainerState;
	};
}

export const Meal = connect(
	MealContainer.mapStateToProps,
	MealContainer.mapDispatchToProps
)(MealContainer);
