import { FileMetadata } from '../../files-client';
import { Reducer } from 'redux';

import { Document, Meal, MealFoodProduct, Restaurant } from '../../client';
import {
	IPartnershipDocumentFetchedAction,
	IPartnershipEditRestaurantMealFetchedAction,
	IPartnershipRestaurantDocumentsFetchedAction,
	IPartnershipRestaurantFetchedAction,
	IPartnershipRestaurantImagesFetchedAction,
	IPartnershipRestaurantMealDeletedAction,
	IPartnershipRestaurantMealFetchedAction,
	PartnershipDocument_Fetched,
	PartnershipEditRestaurantMeal_Fetched,
	PartnershipRestaurantDocuments_Fetched,
	PartnershipRestaurantImages_Fetched,
	PartnershipRestaurantMeal_Deleted,
	PartnershipRestaurantMeal_Fetched,
	PartnershipRestaurant_Fetched
} from './PartnershipActions';

type ReducerActions =
	| IPartnershipRestaurantFetchedAction
	| IPartnershipRestaurantMealDeletedAction
	| IPartnershipRestaurantMealFetchedAction
	| IPartnershipEditRestaurantMealFetchedAction
	| IPartnershipRestaurantDocumentsFetchedAction
	| IPartnershipDocumentFetchedAction
	| IPartnershipRestaurantImagesFetchedAction;

interface IPartnershipDataReduxState {
	document: Document;
	restaurant: Restaurant;
	restaurantDocuments: FileMetadata[];
	restaurantImages: FileMetadata[];
	editRestaurantMeal: Meal;
}

const INITIAL_STATE: IPartnershipDataReduxState = {
	document: {},
	restaurant: {
		city: '',
		street: '',
		homeNumber: '',
		apartmentNumber: '',
		postcode: '',
		openHours: '',
		descriptionTitle: '',
		name: '',
		shortDescription: '',
		description: '',
		representativeApartmentNumber: '',
		representativeHomeNumber: '',
		representativeCity: '',
		representativeName: '',
		representativePostcode: '',
		representativeStreet: '',
		representativeTelephone: '',
		additionalInfo: '',
		mail: ''
	} as Restaurant,
	restaurantDocuments: [],
	restaurantImages: [],
	editRestaurantMeal: { mealFoodProducts: [] as MealFoodProduct[] } as Meal
};
export const partnershipReducer: Reducer<IPartnershipDataReduxState, ReducerActions> = (
	state = INITIAL_STATE,
	action
) => {
	switch (action.type) {
		case PartnershipRestaurantMeal_Fetched:
			return handlePartnershipRestaurantMealFetched(state, action);
		case PartnershipRestaurantMeal_Deleted:
			return handlePartnershipRestaurantMealDeleted(state, action);
		case PartnershipEditRestaurantMeal_Fetched:
			return handlePartnershipEditRestaurantMealFetched(state, action);
		case PartnershipRestaurant_Fetched:
			return handlePartnershipRestaurantFetched(state, action);
		case PartnershipRestaurantDocuments_Fetched:
			return handlePartnershipRestaurantDocumentsFetched(state, action);
		case PartnershipRestaurantImages_Fetched:
			return handlePartnershipRestaurantImagesFetched(state, action);
		case PartnershipDocument_Fetched:
			return handlePartnershipDocumentFetched(state, action);
		default:
			return state;
	}
};

const handlePartnershipRestaurantFetched = (
	state: IPartnershipDataReduxState,
	action: IPartnershipRestaurantFetchedAction
): IPartnershipDataReduxState => {
	return {
		...state,
		restaurant: action.restaurant
	};
};

const handlePartnershipRestaurantMealFetched = (
	state: IPartnershipDataReduxState,
	action: IPartnershipRestaurantMealFetchedAction
): IPartnershipDataReduxState => {
	return {
		...state,
		restaurant: {
			...state.restaurant,
			meals: [
				...(state.restaurant.meals?.filter((x) => x.id !== action.meal.id) ?? []),
				action.meal
			]
		}
	};
};

const handlePartnershipRestaurantMealDeleted = (
	state: IPartnershipDataReduxState,
	action: IPartnershipRestaurantMealDeletedAction
): IPartnershipDataReduxState => {
	return {
		...state,
		restaurant: {
			...state.restaurant,
			meals: state.restaurant.meals?.filter((x) => x.id !== action.id)
		}
	};
};

const handlePartnershipEditRestaurantMealFetched = (
	state: IPartnershipDataReduxState,
	action: IPartnershipEditRestaurantMealFetchedAction
): IPartnershipDataReduxState => {
	return {
		...state,
		editRestaurantMeal: action.meal
	};
};

const handlePartnershipRestaurantDocumentsFetched = (
	state: IPartnershipDataReduxState,
	action: IPartnershipRestaurantDocumentsFetchedAction
): IPartnershipDataReduxState => {
	return {
		...state,
		restaurantDocuments: action.files
	};
};

const handlePartnershipRestaurantImagesFetched = (
	state: IPartnershipDataReduxState,
	action: IPartnershipRestaurantImagesFetchedAction
): IPartnershipDataReduxState => {
	return {
		...state,
		restaurantImages: action.images
	};
};

const handlePartnershipDocumentFetched = (
	state: IPartnershipDataReduxState,
	action: IPartnershipDocumentFetchedAction
): IPartnershipDataReduxState => {
	return {
		...state,
		document: action.document
	};
};
