import * as React from 'react';

import { RootState } from '../../../business/root-store';
import { SpinnerComponent } from './SpinnerComponent';
import { connect } from 'react-redux';

import { AnyAction, Dispatch } from 'redux';

interface ISpinnerContainerReduxMergedProps {
	activeRequestsCount: boolean;
}

interface ISpinnerContainerOwnProps {}

interface ISpinnerContainerDispatchProps {}

interface ISpinnerContainerProps
	extends ISpinnerContainerOwnProps,
		ISpinnerContainerDispatchProps,
		ISpinnerContainerReduxMergedProps {}

interface ISpinnerComponentState {}

class SpinnerContainer extends React.Component<ISpinnerContainerProps, ISpinnerComponentState> {
	constructor(props: ISpinnerContainerProps, state: ISpinnerComponentState) {
		super(props, state);

		this.state = this.getInitialState();
	}

	public render(): JSX.Element {
		return <SpinnerComponent visible={this.spinnerVisible()} />;
	}

	public componentDidMount(): void {}

	//public componentWillUnmount

	public static mapStateToProps = (
		state: RootState,
		ownProps: ISpinnerContainerOwnProps
	): ISpinnerContainerReduxMergedProps => {
		return { activeRequestsCount: state.spinner.activeRequestsCount };
	};

	public static mapDispatchToProps = (
		dispatch: Dispatch<AnyAction>
	): ISpinnerContainerDispatchProps => {
		return {
			//...Redux.bindActionCreators({ onImageOpen: sendFile }, dispatch)
		};
	};

	private getInitialState = (): ISpinnerComponentState => {
		return {} as ISpinnerComponentState;
	};

	private spinnerVisible = (): boolean => {
		return this.props.activeRequestsCount;
	};
}
export const Spinner = connect(
	SpinnerContainer.mapStateToProps,
	SpinnerContainer.mapDispatchToProps
)(SpinnerContainer);
