import { ISearchItem } from '../../../atoms/search-box/search-box';
import { List } from 'immutable';
import { MealExchange } from '../../../../client';
import { createSelector } from 'reselect';

const mealsSelector = (products: List<MealExchange>) => products;

export const getMealsSelector = createSelector(mealsSelector, (products: List<MealExchange>) => {
	return products
		.filter((meal: MealExchange) => !meal.isLiked)
		.map((meal: MealExchange) => {
			return { id: meal.mealId, value: meal.name, display: meal.name } as ISearchItem;
		});
});

export const getFavouritesSelector = createSelector(
	mealsSelector,
	(products: List<MealExchange>) => {
		return products
			.filter((meal: MealExchange) => meal.isLiked || meal.currentylySelected)
			.map((meal: MealExchange) => {
				return { id: meal.mealId, value: meal.name, display: meal.name } as ISearchItem;
			});
	}
);
