import React from 'react';

export const IncrementTrainingsIcon: React.FC = (props) => {
	return (
		<React.Fragment>
			<svg
				width="50"
				height="50"
				viewBox="0 0 45 45"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<circle opacity="0.2" cx="22.5" cy="22.5" r="22.5" fill="#FD6851" />
				<path
					d="M18.8077 17.3751C19.7636 17.3751 20.5385 16.6196 20.5385 15.6876C20.5385 14.7555 19.7636 14 18.8077 14C17.8518 14 17.0769 14.7555 17.0769 15.6876C17.0769 16.6196 17.8518 17.3751 18.8077 17.3751ZM13.0994 25.1615L12.5657 26.3751H10.1538C9.51671 26.3751 9 26.8789 9 27.5002C9 28.1214 9.51671 28.6252 10.1538 28.6252H12.9465C13.6406 28.6252 14.2655 28.223 14.537 27.6025L14.854 26.881L14.4692 26.6596C13.8447 26.2999 13.3853 25.7676 13.0994 25.1615ZM22.8462 21.875H21.2585L20.3189 20.0028C19.8681 19.1045 19.0406 18.4478 18.0912 18.2119L15.5282 17.4687C14.5078 17.2296 13.4452 17.4493 12.6133 18.0713L11.1829 19.1404C10.677 19.5183 10.5811 20.2243 10.9695 20.7176C11.3578 21.2108 12.0815 21.3033 12.587 20.9257L14.0181 19.8566C14.2947 19.6495 14.647 19.5753 14.9293 19.6407L15.4594 19.7943L14.1087 22.8668C13.6536 23.9032 14.0614 25.1172 15.057 25.6903L18.1212 27.4541L17.1306 30.5385C16.9403 31.1313 17.2788 31.7623 17.8868 31.948C18.0018 31.9831 18.1179 32 18.2322 32C18.723 32 19.178 31.6917 19.3327 31.2107L20.4736 27.6577C20.6867 26.9275 20.3694 26.1431 19.6933 25.7455L17.4851 24.4749L18.6141 21.7227L19.345 23.1793C19.6334 23.7538 20.2435 24.1247 20.8994 24.1247H22.8462C23.4833 24.1247 24 23.6209 24 22.9996C24 22.3784 23.4833 21.875 22.8462 21.875Z"
					fill="#FD6851"
				/>
				<path
					d="M34.5731 23.385H31.7681V26.235H29.7281V23.385H26.9231V21.45H29.7281V18.585H31.7681V21.45H34.5731V23.385Z"
					fill="#FD6851"
				/>
			</svg>
		</React.Fragment>
	);
};
