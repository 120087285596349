import '../auth.scss';

import * as Yup from 'yup';

import { ActionButton } from '../../../atoms/ActionButton/ActionButton';
import ActionLink from '../../../atoms/action-link/action-link';
import { TextBox } from '../../../atoms/TextBox/TextBox';

import { Field, Form, Formik, FormikHelpers } from 'formik';
import { ILoginFormProps, ILoginFormValues } from './LoginFormInterfaces';

const initialValues: ILoginFormValues = {
	mail: '',
	password: ''
};

export const LoginForm = ({ resetPasswordHandler, userLoginHandler }: ILoginFormProps) => {
	const onSubmit = (values: ILoginFormValues, helpers: FormikHelpers<ILoginFormValues>) => {
		userLoginHandler({ login: values.mail, password: values.password });
		setTimeout(() => helpers.setSubmitting(false), 2000);
	};
	return (
		<Formik
			className="AuthLogin__form"
			initialValues={initialValues}
			validationSchema={Yup.object<Partial<Record<keyof ILoginFormValues, Yup.AnySchema>>>({
				mail: Yup.string().email('Niepoprawny adres email').required('Wymagane'),
				password: Yup.string()
					.min(3, 'Co najmniej 3 znaki')
					.max(30, 'Nie więcej niż 30 znaków')
					.required('Wymagane')
			})}
			onSubmit={onSubmit}
		>
			{(formik) => (
				<Form>
					<Field
						name="mail"
						label="E-Mail"
						placeholder="np. jan.kowalski@kontakt.com"
						className="AuthLogin__form-row"
						errorString={formik.touched.mail && formik.errors.mail}
						as={TextBox}
					/>
					<Field
						name="password"
						label="Hasło"
						type="password"
						placeholder="Hasło do Twojego konta"
						className="AuthRegister__form-row"
						errorString={formik.touched.password && formik.errors.password}
						as={TextBox}
					/>
					<ActionLink
						className="AuthLogin__link"
						text="Nie pamiętam hasła"
						textColor="#BB6BD9"
						onClick={resetPasswordHandler}
					/>
					<ActionButton
						disabled={!formik.isValid}
						errorString={formik.isValid ? undefined : 'Sprawdź błędy w formularzu'}
						type="submit"
						className="AuthLogin__button"
						text="Zaloguj"
					/>
				</Form>
			)}
		</Formik>
	);
};
