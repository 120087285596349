import React from 'react';
import { primaryOrangeColor } from '../../../shared/shared';
import { useDefaultId } from '../../../hooks/use-default-id';

export interface IBackLinkProps {
	text?: string;
	href?: string;
	id?: string;
	onClick?: () => void;
}
const actionLinkStyle = {
	display: 'flex',
	alignItems: 'center',
	cursor: 'pointer'
	// marginBottom: '20px',
	// marginLeft: '28px'
} as React.CSSProperties;

const spanStyle = {
	fontWeight: '600' as 'bold',
	fontSize: '14px',
	lineHeight: '21px',
	color: primaryOrangeColor,
	marginLeft: '9px'
} as React.CSSProperties;

export const BackLink: React.FC<IBackLinkProps> = (props) => {
	const { children, ...restProps } = props;

	let id = useDefaultId(props.id);
	return (
		<a id={id} style={actionLinkStyle} href={props.href} onClick={props.onClick}>
			<svg
				width="17"
				height="17"
				viewBox="0 0 17 17"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<circle cx="8.5" cy="8.5" r="8.5" fill="url(#paint0_linear)" />
				<path
					d="M9.68822 5.91302L6.58069 9.02055L9.68822 12.1281"
					stroke="white"
					strokeWidth="1.24301"
				/>
				<defs>
					<linearGradient
						id="paint0_linear"
						x1="18.6301"
						y1="-8.35068e-06"
						x2="-3.11424"
						y2="1.07232"
						gradientUnits="userSpaceOnUse"
					>
						<stop stopColor="#FF7E36" />
						<stop offset="1" stopColor="#FA3A68" stopOpacity="0.91" />
					</linearGradient>
				</defs>
			</svg>

			<span style={spanStyle}>{props.text}</span>
		</a>
	);
};
