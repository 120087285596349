import * as React from 'react';
import * as Redux from 'redux';

import { AddCustomProduct } from './CustomProductEpics';
import { CustomProductForm } from './CustomProductForm/CustomProductForm';
import { ISearchItem } from '../../../../atoms/search-box/search-box';
import { RootState } from '../../../../../business/root-store';
import { connect } from 'react-redux';

import { AddOrUpdateCustomProduct, NutritionFactDefinition } from '../../../../../client';
import { AnyAction, Dispatch } from 'redux';
import { GetNutritionFactDefinitions, GetProductGroups } from '../../../staticData/staticDataEpics';
import {
	getNutritionFactDefinitionsSelector,
	getProductGroupsSelector
} from '../../../staticData/staticDataSelectors';

interface ICustomProductContainerReduxMergedProps {
	nutritionFactDefinitions: NutritionFactDefinition[];
	productGroups: ISearchItem[];
}

interface ICustomProductContainerOwnProps {}

interface ICustomProductContainerDispatchProps {
	GetNutritionFactDefinitions: () => void;
	GetProductGroups: () => void;
	AddCustomProduct: (customProduct: AddOrUpdateCustomProduct) => void;
}

interface ICustomProductContainerProps
	extends ICustomProductContainerOwnProps,
		ICustomProductContainerDispatchProps,
		ICustomProductContainerReduxMergedProps {}

interface ICustomProductComponentState {}

class CustomProductContainer extends React.Component<
	ICustomProductContainerProps,
	ICustomProductComponentState
> {
	constructor(props: ICustomProductContainerProps, state: ICustomProductComponentState) {
		super(props, state);
		this.state = this.getInitialState();
		if (props.nutritionFactDefinitions.length === 0) {
			props.GetNutritionFactDefinitions();
		}
		if (props.productGroups.length === 0) {
			props.GetProductGroups();
		}
	}

	public componentDidMount(): void {}

	public render(): JSX.Element {
		return (
			<div className="page">
				<div className="customProduct_header">
					<p className="header">Dodaj własny produkt</p>
				</div>
				<CustomProductForm
					nutritionFactDefinitions={this.props.nutritionFactDefinitions}
					productGroups={this.props.productGroups}
					AddCustomProduct={this.props.AddCustomProduct}
					key={
						this.props.nutritionFactDefinitions.length + this.props.productGroups.length
					}
				/>
			</div>
		);
	}

	public static mapStateToProps = (
		state: RootState,
		ownProps: ICustomProductContainerOwnProps
	): ICustomProductContainerReduxMergedProps => {
		return {
			nutritionFactDefinitions: getNutritionFactDefinitionsSelector(state),
			productGroups: getProductGroupsSelector(state)
		};
	};

	public static mapDispatchToProps = (
		dispatch: Dispatch<AnyAction>
	): ICustomProductContainerDispatchProps => {
		return {
			...Redux.bindActionCreators(
				{
					GetNutritionFactDefinitions: GetNutritionFactDefinitions,
					GetProductGroups: GetProductGroups,
					AddCustomProduct: AddCustomProduct
				},
				dispatch
			)
		};
	};

	private getInitialState = (): ICustomProductComponentState => {
		return {} as ICustomProductComponentState;
	};
}
export const CustomProduct = connect(
	CustomProductContainer.mapStateToProps,
	CustomProductContainer.mapDispatchToProps
)(CustomProductContainer);
