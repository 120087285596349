import './customer-opinions-slider-item.css';
import { Headline } from '../../../../atoms/headline/headline';
import React from 'react';

interface ICustomerOpinionsSliderItemProps {
	title: string;
	quote: string;
	author: string;
}

export const CustomerOpinionsSliderItem: React.FC<ICustomerOpinionsSliderItemProps> = (props) => {
	return (
		<div className="customer-opinions-slider-item">
			<Headline text={props.title} />
			<p className="customer-opinions-slider-item__quote">{props.quote}</p>
			<div className="customer-opinions-slider-item__author">{props.author}</div>
		</div>
	);
};
