import './menu-subscription.css';

import { ActionButton } from '../../../../atoms/ActionButton/ActionButton';
import { DietPlanPrice } from '../../../staticData/staticDataSelectors';
import { DietPlanPriceAtom } from '../../../../atoms/DietPlanPrice/DietPlanPriceComponent';
import { useDispatch } from 'react-redux';

import { Link, useHistory } from 'react-router-dom';
import React, { useState } from 'react';
import { createUrlWithParams, routes } from '../../../../../business/router/path-definition';

export interface IMenuSubscriptionProps {
	id: number;
	img: string;
	imgOnHover: string;
	url: string;
	DietName: string;
	About: string;
	cheapestPrice: DietPlanPrice;
}

export const MenuSubscription: React.FC<IMenuSubscriptionProps> = (props) => {
	const [imageUrl, setImageUrl] = useState(props.img);
	const dispatch = useDispatch();
	const history = useHistory();

	const onClickAction = () => {
		history.push('/customer/new/' + props.id);
	};

	// todo: redesign image source
	const menuImg = { background: `url(${props.img})` } as React.CSSProperties;

	return (
		<div
			className="menu-subscription"
			onMouseEnter={() => setImageUrl(props.imgOnHover)}
			onMouseLeave={() => setImageUrl(props.img)}
		>
			<img
				className="menu-subscription__img"
				src={imageUrl}
				alt={props.DietName}
				onClick={onClickAction}
			/>
			<h3 className="menu-subscription__headline">{props.DietName}</h3>
			<DietPlanPriceAtom dietPlanPrice={props.cheapestPrice} />
			<div className="menu-subscription__action-button">
				<ActionButton text="Zobacz okres próbny" onClick={onClickAction} />
			</div>
			<div className="menu-subscription__about">
				<span>{props.About}</span>{' '}
				<Link
					to={createUrlWithParams(routes.static.document.path, {
						documentUrl: props.url
					})}
				>
					Dowiedz się więcej
				</Link>
			</div>
		</div>
	);
};
