import './RadioSelect.scss';

import { useDefaultId } from '../../../hooks/use-default-id';

import React, { InputHTMLAttributes } from 'react';

export interface IRadioSelectProps {
	errorString?: string;
	label?: string;
	className?: string;
	disabled?: boolean;
}

export const RadioSelect = ({
	label,
	className,
	errorString,
	...props
}: IRadioSelectProps & InputHTMLAttributes<HTMLInputElement>) => {
	const id = useDefaultId(props.id ?? (props.name ?? '') + (props.value ?? ''));
	return (
		<div className={`${className ?? ''} CustomRadio`}>
			<label
				htmlFor={id}
				className={
					props.checked
						? 'CustomRadio_label CustomRadio_label_checked'
						: 'CustomRadio_label'
				}
			>
				<input type="radio" id={id} {...props} className="CustomRadio_label_input" />
				{label}
			</label>
			{errorString && <span className="FormikError">{errorString}</span>}
		</div>
	);
};
