import './DayPicker.scss';

import { ActionButton } from '../ActionButton/ActionButton';
import ActionLink from '../../atoms/action-link/action-link';
import { ChevronLeftIcon } from '../../../shared/assets/chevron-left-icon';
import { ChevronRightIcon } from '../../../shared/assets/chevron-right-icon';
import { CopyDay } from '../../molecules/MealCard/CopyDay/CopyDayContainer';
import { DietPlanDay } from '../../../client';
import { ICurrentMeals } from '../../molecules/MealCard/Meal/meal-interfaces';
import { Modal } from '../Modal/Modal';
import React from 'react';
import moment from 'moment';
import { primaryOrangeColor } from '../../../shared/shared';

import { getDayName, getFriendlyMonthName } from '../../../shared/helpers';

interface IDayPickerComponentProps {
	loadDietPlanDay: (dietDay: number) => void;
	navigateTo(path: string, state?: any): void;
	setCurrentMeals: (currentMeals: ICurrentMeals) => void;
	currentDay?: DietPlanDay;
	hasNextDayActive: boolean;
	hasPreviousDayActive: boolean;
}

interface IDayPickerComponentState {
	showCopyDayModal: boolean;
}

export class DayPickerComponent extends React.Component<
	IDayPickerComponentProps,
	IDayPickerComponentState
> {
	constructor(props: IDayPickerComponentProps, state: IDayPickerComponentState) {
		super(props, state);

		this.state = this.getInitialState();
		this.setShowCopyDayModal = this.setShowCopyDayModal.bind(this);
	}

	setShowCopyDayModal(value: boolean) {
		this.setState({ showCopyDayModal: value });
	}

	public render(): JSX.Element {
		const currentDayLocal = moment.utc(this.props.currentDay?.dayDateUtc).toDate();
		return !(currentDayLocal === null || currentDayLocal === undefined) ? (
			<div className="day-picker">
				<div className="day-picker__header">
					<ActionButton
						icon={<ChevronLeftIcon />}
						onClick={this.loadPreviousDay}
						disabled={!this.props.hasPreviousDayActive}
					/>
					<div className="day-picker__headline-wrapper">
						<div className="day-picker__headline-content">
							<span className="day-picker__headline day-picker__headline--bold">
								{getDayName(currentDayLocal.getDay())}
							</span>
							<span className="day-picker__headline">
								{currentDayLocal.getDate()} {getFriendlyMonthName(currentDayLocal)}
							</span>
						</div>
						<div className="day-picker__action-links">
							<ActionLink
								text="Zaproponuj dzień"
								textColor="#BB6BD9"
								onClick={this.handleChange}
								className="day-picker__action-link"
							>
								<img src="\img\change.svg" alt="Zmień dzień" />
							</ActionLink>

							<ActionLink
								text="Kopiuj dzień"
								textColor={primaryOrangeColor}
								onClick={this.handleCopy}
								className="day-picker__action-link"
							>
								<img src="\img\copy.svg" alt="Kopiuj dzień" />
							</ActionLink>
						</div>
					</div>
					<ActionButton
						icon={<ChevronRightIcon />}
						onClick={this.loadNextDay}
						disabled={!this.props.hasNextDayActive}
					/>
				</div>
				{this.state.showCopyDayModal && (
					<Modal
						show={this.state.showCopyDayModal}
						handleStateChange={this.setShowCopyDayModal}
					>
						<CopyDay />
					</Modal>
				)}
			</div>
		) : (
			<></>
		);
	}
	private handleChange = () => {};

	private handleCopy = () => {
		this.setState({ showCopyDayModal: true });
	};

	private loadPreviousDay = () => {
		this.props.loadDietPlanDay(this.props.currentDay?.dietDayId! - 1);
	};
	private loadNextDay = () => {
		this.props.loadDietPlanDay(this.props.currentDay?.dietDayId! + 1);
	};

	private getInitialState = (): IDayPickerComponentState => {
		return {
			showCopyDayModal: false
		} as IDayPickerComponentState;
	};
}
