import './HeaderComponent.scss';

import { ActionButton } from '../ActionButton/ActionButton';
import { PreferencesIcon } from '../../../shared/assets/preferences-icon';
import React from 'react';
import { primaryOrangeColor } from '../../../shared/shared';

export interface IHeaderProps {
	text?: string;
	subtitle?: string;
	showDietPreferences?: boolean;
	onDietPreferencesClick?(): void;
}

const HeaderComponent: React.FC<IHeaderProps> = (props) => {
	return (
		<div className="HeaderComponent">
			{props.subtitle ? (
				<div className="HeaderComponent__marginRight">
					<h3 className="HeaderComponent__label">{props.children}</h3>
					<h3 className="mainStyleDivider">I</h3>
					<br />
					<h3 className="subLabeStyle">{props.subtitle}</h3>
				</div>
			) : (
				<h3 className="HeaderComponent__label">{props.children}</h3>
			)}
			{props.showDietPreferences && (
				<>
					<ActionButton
						className="style-mobile"
						onClick={props.onDietPreferencesClick}
						icon={<PreferencesIcon />}
					/>
					<ActionButton
						className="style-desktop"
						onClick={props.onDietPreferencesClick}
						text="Dostosuj plan diety"
						icon={<PreferencesIcon color={primaryOrangeColor} />}
						styleType="secondary"
					/>
				</>
			)}
		</div>
	);
};

export const Header = HeaderComponent;
