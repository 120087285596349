import './chips-item.scss';
import React from 'react';
import { useDefaultId } from '../../../hooks/use-default-id';

export interface IChipsItemProps {
	id: number;
	text?: string;
	onRemove: (id: number) => void;
}

export const ChipsItem: React.FC<IChipsItemProps> = (props) => {
	const { children, ...restProps } = props;
	let id = useDefaultId(props.id.toString());

	const handleOnClicked = () => {
		props.onRemove(+props.id);
	};

	return (
		<div id={id} className="chips-item">
			<span>{props.text}</span>
			<svg
				className="chips-item__button"
				width="17"
				height="17"
				viewBox="0 0 17 17"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
				onClick={handleOnClicked}
			>
				<line x1="4.13847" y1="3.90239" x2="12.1388" y2="11.9027" stroke="white" />
				<line x1="12.1392" y1="4.13889" x2="4.13889" y2="12.1392" stroke="white" />
			</svg>
		</div>
	);
};
