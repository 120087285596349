import './landing-page-header.css';

import React from 'react';

export const LandingPageHeader: React.FC = (props) => {
	// todo: replace img with divs
	return (
		<div className="landing-page-header">
			<div className="landing-page-header__content">
				<div className="line-decor line-decor--left" />
				<h1 className="landing-page-header__headline">Żywienie szyte pod Ciebie</h1>
				<p className="landing-page-header__brief">
					Diet Designer to aplikacja dietetyczna, dzięki której jesz zdrowo i tak jak
					lubisz bez zbędnych ograniczeń!
				</p>
				{/* TEMPORARY HIDE DIET-259 */}
				{/* <div className="landing-page-header__store-buttons">
					<img src="\googlePlay.svg" alt="Download from Google Play button" />
					<img
						src="\appStore.svg"
						alt="Download from App Store button"
						onClick={onClickStatute}
					/>
				</div> */}
			</div>
			<img className="landing-page-header__cover-image" src="\img\mobile.png" alt="mobile" />
		</div>
	);
};
