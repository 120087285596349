import React from 'react';

interface IChevronDownIconComponentProps {
	fillColor?: string;
}

class ChevronDownIconComponent extends React.Component<IChevronDownIconComponentProps> {
	constructor(props: IChevronDownIconComponentProps) {
		super(props);
	}

	public render(): JSX.Element {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="20"
				height="20"
				viewBox="0 0 1024 1024"
				fill={this.props.fillColor || '#b0b4c4'}
			>
				<path d="M316.16 366.08L512 561.92l195.84-195.84L768 426.666667l-256 256-256-256 60.16-60.586667z" />
			</svg>
		);
	}
}

export const ChevronDownIcon = ChevronDownIconComponent;
