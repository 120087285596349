import './Ingredient.scss';

import { List } from 'immutable';
import { RemoveIcon } from '../../../../../shared/assets/remove-icon';
import Switch from 'react-switch';
import { confirm } from 'react-confirm-box';
import { useConfirmationBoxOptions } from '../../../../../hooks/useConfirmationBoxOptions';

import { DropdownMenu, IDropdownMenuItem } from '../../../../atoms/DropdownMenu/DropdownMenu';
import { HomeMeasurementRule, MealFoodProduct } from '../../../../../client';
import React, { ChangeEvent } from 'react';

interface IIngredientComponentProps {
	mfp: MealFoodProduct;
	disableRemoveIcon?: boolean;
	disableBorder?: boolean;
	className?: string;
	handleIngredientUpdated(mfp: MealFoodProduct): void;
	handleIngredientDeleted?: () => void;
	hmrs?: List<HomeMeasurementRule>;
}

interface IIngredientComponentState {
	uomSwitchChecked: boolean;
	hmr?: HomeMeasurementRule;
}

export class IngredientComponent extends React.Component<
	IIngredientComponentProps,
	IIngredientComponentState
> {
	constructor(props: IIngredientComponentProps, state: IIngredientComponentState) {
		super(props, state);

		this.state = this.getInitialState();
	}

	public render(): JSX.Element {
		const menuItems = this.props.hmrs?.map((hmr) => {
			return {
				label: hmr.unitOfMeasure?.uoMName,
				onClick: () => this.handleUomClick(hmr)
			} as IDropdownMenuItem;
		});
		return (
			<div
				className={this.props.disableBorder ? 'Ingredient' : 'Ingredient Ingredient_border'}
			>
				<div className={'Ingredient_row ' + this.props.className}>
					<p className="Ingredient_name">{this.props.mfp.foodProduct?.name}</p>
					<div className="Ingredient_group">
						<input
							type="number"
							placeholder="Ilość"
							value={
								this.state.uomSwitchChecked
									? this.props.mfp.qtyInHomeMeasurement || ''
									: this.props.mfp.qtyInGrams || ''
							}
							className="Ingredient_input"
							onChange={this.handleQuantityChanged}
						/>
						<div className="Ingredient_switch">
							<span className="Ingredient_switch_left">gramy</span>
							<Switch
								disabled={this.props.hmrs?.count() === 0}
								onChange={this.handleClickUomSwitch}
								checked={this.state.uomSwitchChecked}
								height={24}
								width={48}
								onColor="#BB6BD9"
								offColor="#BB6BD9"
								checkedIcon={false}
								uncheckedIcon={false}
							/>
							<DropdownMenu
								triggerItem={
									<span className="Ingredient_switch_right">
										{this.state.hmr?.unitOfMeasure?.uoMName}
									</span>
								}
								menuItems={menuItems?.toArray() ?? []}
								position="bottomRight"
							/>
						</div>
					</div>
				</div>
				<div className="Ingredient_remove">
					{!this.props.disableRemoveIcon && (
						<RemoveIcon
							id={this.props.mfp.foodProductId!}
							className="Ingredient_remove_icon"
							onClick={this.handleDelete}
						/>
					)}
				</div>
			</div>
		);
	}

	private handleUomClick = (hmr: HomeMeasurementRule): void => {
		let newIngredient = { ...this.props.mfp };
		newIngredient.qtyInHomeMeasurement = this.state.uomSwitchChecked
			? this.props.mfp.qtyInHomeMeasurement || 0
			: this.props.mfp.qtyInGrams || 0;
		newIngredient.qtyInGrams = hmr.qtyInGrams! * (newIngredient.qtyInHomeMeasurement ?? 0);
		newIngredient.unitOfHomeMeasureId = hmr.unitOfMeasureId;
		this.setState({
			...this.state,
			hmr: hmr,
			uomSwitchChecked: true
		});
		this.props.handleIngredientUpdated(newIngredient);
	};

	private handleClickUomSwitch = (checked: boolean): void => {
		if (checked) {
			this.handleUomClick(this.state.hmr!);
		} else {
			let newIngredient = this.props.mfp;
			newIngredient.qtyInGrams = newIngredient.qtyInHomeMeasurement ?? undefined;
			newIngredient.qtyInHomeMeasurement = undefined;
			this.setState({ ...this.state, uomSwitchChecked: checked });
			this.props.handleIngredientUpdated(newIngredient);
		}
	};

	private handleQuantityChanged = (event: ChangeEvent<HTMLInputElement>) => {
		const newQty = event.target.value === '' ? undefined : +event.target.value;
		let newIngredient = { ...this.props.mfp };
		if (this.state.uomSwitchChecked) {
			newIngredient.qtyInHomeMeasurement = newQty;
			newIngredient.qtyInGrams =
				this.state.hmr?.qtyInGrams! * (newIngredient.qtyInHomeMeasurement ?? 0);
		} else {
			newIngredient.qtyInGrams = newQty;
		}
		this.props.handleIngredientUpdated(newIngredient);
	};

	private handleDelete = async (): Promise<void> => {
		const options: any = useConfirmationBoxOptions();
		const result = await confirm('Czy na pewno chcesz usunąć ten składnik?', options);
		if (result) {
			this.props.handleIngredientDeleted && this.props.handleIngredientDeleted();
		}
	};

	private getInitialState = (): IIngredientComponentState => {
		return {
			uomSwitchChecked: false,
			hmr: this.props.mfp.unitOfHomeMeasureId
				? this.props.hmrs?.find(
						(x) => x.unitOfMeasureId == this.props.mfp.unitOfHomeMeasureId
				  )
				: this.props.hmrs && this.props.hmrs.count() > 0
				? this.props.hmrs.first()
				: undefined
		} as IIngredientComponentState;
	};
}
