import { List } from 'immutable';
import { WeekViewDay } from '../../../../client';

export const mealsForWeek_Fetch = '@@Diet/Customer/WeekView/Meals/FETCH';
export const mealsForWeek_Fetched = '@@Diet/Customer/WeekView/Meals/FETCHED';

// export interface IMealsForWeekFetchAction {
//     type: typeof mealsForWeek_Fetch;
// }

// export const mealsForWeekFetch = (
// ): IMealsForWeekFetchAction => ({
//     type: mealsForWeek_Fetch
// });

export interface IMealsForWeekFetchedAction {
	type: typeof mealsForWeek_Fetched;
	payload: { mealsForWeek: List<WeekViewDay> };
}

export const mealsForWeekFetched = (
	payload: IMealsForWeekFetchedAction['payload']
): IMealsForWeekFetchedAction => ({
	type: mealsForWeek_Fetched,
	payload
});
