import './PartnershipPanel.scss';

import { ActionButton } from '../../../atoms/ActionButton/ActionButton';
import ActionLink from '../../../atoms/action-link/action-link';
import { AddToastPayload } from 'react-redux-toastr';
import { BackLink } from '../../../atoms/back-link/back-link';
import { CustomMeal } from '../../../molecules/MealCard/CustomMeal/CustomMealContainer';
import { CustomProduct } from '../../../molecules/MealCard/CustomMeal/CustomProduct/CustomProductContainer';
import { FileMetadata } from '../../../../files-client';
import FlipMove from 'react-flip-move';
import { Headline } from '../../../atoms/headline/headline';
import { ICurrentMeals } from '../../../molecules/MealCard/Meal/meal-interfaces';
import { IImageFile } from '../../../../shared/interfaces/IImageFile';
import { Modal } from '../../../atoms/Modal/Modal';
import { PartnershipInitial } from '../PartnershipInitial/PartnershipInitialContainer';
import React from 'react';
import { RemoveIcon } from '../../../../shared/assets/remove-icon';
import { RestaurantDetailsComponent } from '../../../molecules/Partnership/RestaurantDetaills/RestaurantDetails';
import { RestaurantDocuments } from '../../../molecules/Partnership/RestaurantDocuments/RestaurantDocuments';
import { RestaurantsMapWrapperComponent } from '../../../molecules/Restaurants/RestaurantsMap/RestaurantsMapWrapperComponent';
import Switch from 'react-switch';
import { confirm } from 'react-confirm-box';
import { getMealImage } from '../../../molecules/MealCard/meal-card-helper';
import moment from 'moment';
import { primaryOrangeColor } from '../../../../shared/shared';
import { routes } from '../../../../business/router/path-definition';
import { useConfirmationBoxOptions } from '../../../../hooks/useConfirmationBoxOptions';
import { Document, Meal, MealTypeEnum, Restaurant } from '../../../../client';
import { HereAPI, HereApiItem } from '../../../../shared/HereApi';
import { ISearchItem, SearchBox } from '../../../atoms/search-box/search-box';
import {
	MealStatusFilter,
	getMealStatusName
} from '../../../../shared/interfaces/MealStatusFilter';
import {
	RestaurantStatusFilter,
	getRestaurantStatusName,
	restaurantCanFullWorking
} from '../../../../shared/interfaces/RestaurantStatusFilter';

interface IPartnershipPanelComponentProps {
	meal: Meal;
	document: Document;
	restaurant: Restaurant;
	restaurantDocuments: FileMetadata[];
	restaurantImages: IImageFile[];
	currentMeals: ICurrentMeals;
	addOrUpdateRestaurantMeal(meal: Meal): void;
	deleteRestaurantMeal: (id: number) => void;
	enableOrDisableRestaurant: () => void;
	downloadDocuments: (documentGuid: string, fileName: string) => void;
	navigateTo: (path: string, state?: any) => void;
	toast: (message: AddToastPayload) => void;
	enableOrDisableRestaurantMeal: (mealId: number) => void;
	updateRestaurantLocation: (lat: number, lng: number) => void;
	setCurrentMeals: (currentMeals: ICurrentMeals) => void;
}

interface IPartnershipPanelComponentState {
	showCustomMealModal: boolean;
	showCustomProductModal: boolean;
	currentPosition?: { lat: number; lng: number };
	currentPositionName: string;
	searchAddressesItems: ISearchItem[];
	newLocationSwitch: boolean;
}

export class PartnershipPanelComponent extends React.Component<
	IPartnershipPanelComponentProps,
	IPartnershipPanelComponentState
> {
	private geocodeTimer?: NodeJS.Timeout;
	private rgeocodeTimer?: NodeJS.Timeout;
	constructor(props: IPartnershipPanelComponentProps, state: IPartnershipPanelComponentState) {
		super(props, state);

		this.state = this.getInitialState();
	}
	public render(): JSX.Element {
		return (
			<>
				<div className="PartnershipPanel">
					<BackLink text="Powrót do strony głównej" onClick={this.onPreviousClicked} />
					<Headline
						align="left"
						text={
							'Panel restauratora, restauracja "' + this.props.restaurant.name + '"'
						}
					/>
					<div className="PartnershipPanel__main">
						<div className="PartnershipPanel__panel-left">
							<div className="PartnershipPanel__section">
								<div className="PartnershipPanel__section-title">
									{this.props.restaurant.status ===
										RestaurantStatusFilter.FormToVerify.status ||
									this.props.restaurant.status ===
										RestaurantStatusFilter.FormRejected.status
										? 'Przesłany formularz'
										: 'Dane restauracji'}
								</div>
								{this.props.restaurant.status ===
									RestaurantStatusFilter.FormRejected.status && (
									<PartnershipInitial />
								)}
								{this.props.restaurant.status ===
									RestaurantStatusFilter.FormToVerify.status && (
									<PartnershipInitial disabled />
								)}
								{(this.props.restaurant.status ===
									RestaurantStatusFilter.FullWorkingPublic.status ||
									this.props.restaurant.status ===
										RestaurantStatusFilter.FullWorkingUnpublic.status) && (
									<RestaurantDetailsComponent />
								)}
								{(this.props.restaurant.status ===
									RestaurantStatusFilter.DocumentsToSign.status ||
									this.props.restaurant.status ===
										RestaurantStatusFilter.DocumentsRejected.status ||
									this.props.restaurant.status ===
										RestaurantStatusFilter.DocumentsToVerify.status) && (
									<RestaurantDetailsComponent disabled />
								)}
								{(this.props.restaurant.status ===
									RestaurantStatusFilter.ChangesToVerifyPublic.status ||
									this.props.restaurant.status ===
										RestaurantStatusFilter.ChangesToVerifyUnpublic.status) && (
									<RestaurantDetailsComponent
										disabled
										dontRewriteToNewProperties
									/>
								)}
								{(this.props.restaurant.status ===
									RestaurantStatusFilter.ChangesRejectedPublic.status ||
									this.props.restaurant.status ===
										RestaurantStatusFilter.ChangesRejectedUnpublic.status) && (
									<RestaurantDetailsComponent dontRewriteToNewProperties />
								)}
							</div>
						</div>
						<div className="PartnershipPanel__panel-right">
							<div className="PartnershipPanel__section">
								<div className="PartnershipPanel__section-title">
									Status restauracji
								</div>
								<h3>{getRestaurantStatusName(this.props.restaurant.status!)}</h3>
								{restaurantCanFullWorking(this.props.restaurant.status!) && (
									<ActionLink
										text="Przełącz status publiczności"
										onClick={this.props.enableOrDisableRestaurant}
									/>
								)}
								{this.props.document.createdDateUtc && (
									<>
										<h3 className="PartnershipPanel__status-topic">
											{this.props.document.title}
										</h3>
										<div
											dangerouslySetInnerHTML={{
												__html: this.props.document.documentData ?? ''
											}}
										></div>
										{this.props.restaurant.rejectedDescription && (
											<p>
												Powód: "{this.props.restaurant.rejectedDescription}"
											</p>
										)}
									</>
								)}
							</div>
							{this.props.restaurantDocuments.length > 0 && (
								<div className="PartnershipPanel__section">
									<div className="PartnershipPanel__section-title">Dokumenty</div>

									{this.props.restaurantDocuments.map((x) => (
										<div
											className="PartnershipPanel__section-item"
											key={'document_' + x.id}
										>
											<span style={{ fontSize: 'small' }}>
												{x.fileName!}
												{' - '}
												{moment
													.utc(x.createdDateUtc!)
													.local()
													.calendar()
													.toLocaleLowerCase()}
											</span>
											<ActionLink
												text="Pobierz"
												textColor={primaryOrangeColor}
												onClick={() =>
													this.props.downloadDocuments(
														x.guid!,
														x.fileName!
													)
												}
											/>
										</div>
									))}
									{(this.props.restaurant.status ===
										RestaurantStatusFilter.DocumentsToSign.status ||
										this.props.restaurant.status ===
											RestaurantStatusFilter.DocumentsRejected.status) && (
										<div className="PartnershipPanel__section-item">
											<RestaurantDocuments
												restaurant={this.props.restaurant}
											/>
										</div>
									)}
								</div>
							)}
							{restaurantCanFullWorking(this.props.restaurant.status!) && (
								<div className="PartnershipPanel__section">
									<div className="PartnershipPanel__section-title">Posiłki</div>
									<div className="PartnershipPanel_MealsContainer">
										<FlipMove>
											{this.props.restaurant.meals?.map((meal: Meal) => {
												const image = getMealImage(
													this.props.restaurantImages,
													meal.imagesCatalog!
												);
												const changeMealPublicStatus = () => {
													this.props.enableOrDisableRestaurantMeal(
														meal.id!
													);
												};
												const handleClickMealEdit = () => {
													this.props.setCurrentMeals({
														...this.props.currentMeals,
														mealType: MealTypeEnum.Lunch,
														meals: [meal]
													});
													this.handleCustomMealModalClicked(true);
												};
												const handleClickDelete = async () => {
													const options: any =
														useConfirmationBoxOptions();
													const result = await confirm(
														'Czy na pewno chcesz usunąć posiłek?',
														options
													);
													if (result) {
														this.props.deleteRestaurantMeal(meal.id!);
													}
												};
												return (
													<div
														key={meal.id}
														className="PartnershipPanel_MealsContainer_Item"
													>
														<img
															src={image}
															onClick={handleClickMealEdit}
														/>
														<div className="PartnershipPanel_MealsContainer_Item_Info">
															<span>
																Nazwa:{' '}
																<span className="PartnershipPanel_MealsContainer_Item_Info_status">
																	{meal.name}
																</span>
															</span>
															<span>
																Status:{' '}
																<span className="PartnershipPanel_MealsContainer_Item_Info_status">
																	{getMealStatusName(
																		meal.status!
																	)}
																</span>
															</span>

															<div className="PartnershipPanel_MealsContainer_Item_Info_Buttons">
																<ActionLink
																	text="Edytuj posiłek"
																	onClick={handleClickMealEdit}
																/>
																{(meal.status ===
																	MealStatusFilter.New.status ||
																	meal.status ===
																		MealStatusFilter.Rejected
																			.status) && (
																	<ActionLink
																		text="Poproś o weryfikację"
																		onClick={
																			changeMealPublicStatus
																		}
																	/>
																)}
																{(meal.status ===
																	MealStatusFilter
																		.FullWorkingNotPublic
																		.status ||
																	meal.status ===
																		MealStatusFilter
																			.FullWorkingPublic
																			.status) && (
																	<ActionLink
																		text="Przełącz status publiczności"
																		onClick={
																			changeMealPublicStatus
																		}
																	/>
																)}
															</div>
														</div>
														<div className="PartnershipPanel_MealsContainer_Item_RemoveIcon">
															<RemoveIcon
																onClick={handleClickDelete}
															/>
														</div>
													</div>
												);
											})}
										</FlipMove>
									</div>
									<ActionButton
										text="Dodaj posiłek"
										onClick={this.handleAddMealClicked}
									/>
								</div>
							)}
							{this.props.restaurant && (
								<div className="PartnershipPanel__section">
									<div className="PartnershipPanel__section-title">
										Lokalizacja
									</div>
									<div className="PartnershipPanel_MapContainer">
										<div className="PartnershipPanel_MapContainer_Buttons">
											<ActionLink
												text="Pokaż moją restauracje na mapie"
												onClick={this.handleSetRestaurantPosition}
											/>
											<div className="PartnershipPanel_MapContainer_Buttons_Switch">
												Zmień położenie restauracji (kliknij na mapie)
												<Switch
													onChange={this.handleClickLocationSwitch}
													checked={this.state.newLocationSwitch}
													height={24}
													width={48}
													onColor="#BB6BD9"
													checkedIcon={false}
													uncheckedIcon={false}
												/>
											</div>
										</div>

										<SearchBox
											disableFilteringData={this.state.searchAddressesItems}
											activateOnFocus
											placeholder="Adres"
											value={this.state.currentPositionName}
											onSelect={this.handleOnSelectPosition}
											onSearchChanged={this.handleSearchChangedPosition}
											className="PartnershipPanel_MapContainer_SearchBox"
										/>
										<RestaurantsMapWrapperComponent
											restaurants={[this.props.restaurant]}
											currentPosition={this.state.currentPosition}
											onIdle={this.handleOnIdleMap}
											handleSetCurrentPosition={this.handleSetCurrentPosition}
											onClickMap={this.handleClickOnMap}
											geolocationButtonText="Znajdź mnie"
										/>
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
				{this.state.showCustomMealModal && (
					<Modal
						show={this.state.showCustomMealModal}
						handleStateChange={() => this.handleCustomMealModalClicked(false)}
					>
						<CustomMeal
							headerText="Edytuj posiłek w restauracji"
							submitCustomMeals={this.handleSubmitCustomMeals}
							handleCustomProductModalClicked={this.handleCustomProductModalClicked}
						/>
					</Modal>
				)}
				{this.state.showCustomProductModal && (
					<Modal
						show={this.state.showCustomProductModal}
						handleStateChange={() => this.handleCustomProductModalClicked(false)}
					>
						<CustomProduct />
					</Modal>
				)}
			</>
		);
	}

	private handleSubmitCustomMeals = (meals: Meal[]) => {
		this.props.addOrUpdateRestaurantMeal(meals[0]);
	};

	private handleClickOnMap = (lat: number, lng: number) => {
		if (this.state.newLocationSwitch) {
			this.props.updateRestaurantLocation(lat, lng);
			this.setState({ newLocationSwitch: false });
		}
	};

	private handleClickLocationSwitch = (value: boolean): void => {
		this.setState({ newLocationSwitch: value });
	};

	private handleSetRestaurantPosition = () => {
		this.handleSetCurrentPosition(this.props.restaurant.lat!, this.props.restaurant.lng!);
	};

	private handleOnIdleMap = (lat: number, lng: number) => {
		if (this.rgeocodeTimer) clearTimeout(this.rgeocodeTimer);
		this.rgeocodeTimer = setTimeout(() => {
			HereAPI.rgeocode(lat, lng).then((x: string) =>
				this.setState({
					currentPositionName: x
				})
			);
		}, 3000);
	};

	private handleSearchChangedPosition = (text: string) => {
		this.setState({ ...this.state, currentPositionName: text });
		if (this.geocodeTimer) clearTimeout(this.geocodeTimer);
		this.geocodeTimer = setTimeout(() => {
			HereAPI.geocode(text).then((results: HereApiItem[]) => {
				const items = results.map((x: HereApiItem) => {
					return {
						display: x.title,
						value: x
					} as ISearchItem;
				});
				this.setState({ ...this.state, searchAddressesItems: items });
			});
		}, 500);
	};

	private handleOnSelectPosition = (item: ISearchItem) => {
		this.handleSetCurrentPosition(+item.value.y, +item.value.x);
	};

	private handleSetCurrentPosition = (lat: number, lng: number) => {
		this.setState({
			currentPosition: { lat: lat, lng: lng }
		});
	};

	private handleAddMealClicked = (): void => {
		this.props.setCurrentMeals({
			...this.props.currentMeals,
			mealType: MealTypeEnum.Lunch,
			meals: []
		});
		this.handleCustomMealModalClicked(true);
	};

	private handleCustomProductModalClicked = (value: boolean): void => {
		this.setState({
			showCustomProductModal: value,
			showCustomMealModal: !value
		});
	};

	private handleCustomMealModalClicked = (value: boolean) => {
		this.setState({
			showCustomMealModal: value
		});
	};

	private onPreviousClicked = (): void => {
		this.props.navigateTo(routes.home.path);
	};

	private getInitialState = (): IPartnershipPanelComponentState => {
		return {
			showCustomProductModal: false,
			showCustomMealModal: false,
			currentPositionName: '',
			searchAddressesItems: [],
			currentPosition: { lat: this.props.restaurant.lat, lng: this.props.restaurant.lng },
			newLocationSwitch: false
		} as IPartnershipPanelComponentState;
	};
}
