import './DietPlanPrice.scss';

import { DietPlanPrice } from '../../molecules/staticData/staticDataSelectors';

export interface IDietPlanPriceProps {
	dietPlanPrice: DietPlanPrice;
	className?: string;
}

export const DietPlanPriceAtom: React.FC<IDietPlanPriceProps> = (props) => {
	return (
		<div className={props.className ? 'DietPlanPrice ' + props.className : 'DietPlanPrice'}>
			<span className="DietPlanPrice_from">od</span>{' '}
			{props.dietPlanPrice.discountCodePricePerMonth !== undefined &&
			props.dietPlanPrice.discountCodePricePerMonth <
				(props.dietPlanPrice.regularPricePerMonth ?? 0) ? (
				<>
					<span className="DietPlanPrice_regular">
						<span className="DietPlanPrice_regular_span">
							{props.dietPlanPrice.regularPricePerMonth.toFixed(2)}
						</span>
					</span>{' '}
					{props.dietPlanPrice.discountCodePricePerMonth.toFixed(2)}
				</>
			) : (
				<>{props.dietPlanPrice.regularPricePerMonth.toFixed(2)}</>
			)}{' '}
			<span className="DietPlanPrice_span">zł/mc</span>
		</div>
	);
};
