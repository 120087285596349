import './landing-page-join-us.css';
import { Headline } from '../../../atoms/headline/headline';
import React from 'react';
import SocialIcon from '../../../atoms/social-icon';

export const LandingPageJoinUs: React.FC = (props) => {
	return (
		<div className="landing-page-join-us">
			<Headline text="Dołącz do nas: " />
			<div className="landing-page-join-us__socials">
				{/*<img src="\img\dietdesigner.svg" width="70%;" alt="logo" />*/}
				{/*<br />*/}
				<SocialIcon
					type="FB"
					url="https://www.facebook.com/Diet-Designer-110137753701143"
				/>
				<SocialIcon type="Insta" url="https://instagram.com/diet.designer" />
				<SocialIcon type="LI" />
				<SocialIcon type="Tweeter" />
			</div>
		</div>
	);
};
