import { IMealFoodProductMealPair } from '../CustomMeal/CustomMealInterfaces';
import { List } from 'immutable';
import { Meal } from '../../../../client';
import { MealExchange } from '../../../../client';

export const NewMeal_Swapped = '@@Diet/Meal/SWAPPED';
export const NewMealSwapped_Saved = '@@Diet/Meal/Swapped/SAVED';
export const NewMeal_Confirmed = '@@Diet/Meal/Swapped/CONFIRMED';
export const SelectedMeals_Fetched = '@@Diet/Meal/Selected/FETCHED';
export const MealProposals_Fetched = '@@Diet/Meal/Proposals/FETCHED';
export const NewMealSwappedLeavePage = '@@Diet/Meal/Swapped/CLEARED';

export interface IMealProposalsFetchedAction {
	type: typeof MealProposals_Fetched;
	payload: { mealProposals: List<MealExchange> };
}

export const mealProposalsFetched = (
	payload: IMealProposalsFetchedAction['payload']
): IMealProposalsFetchedAction => ({
	type: MealProposals_Fetched,
	payload
});

export interface IMealsExchangedAction {
	type: typeof NewMeal_Swapped;
	payload: {};
}

export const mealExchanged = (): IMealsExchangedAction => ({
	type: NewMeal_Swapped,
	payload: {}
});

export interface IMealsFetchedAction {
	type: typeof SelectedMeals_Fetched;
	payload: { meals: List<Meal> };
}

export const mealsFetched = (payload: IMealsFetchedAction['payload']): IMealsFetchedAction => ({
	type: SelectedMeals_Fetched,
	payload
});

export interface IMealSwappedAction {
	type: typeof NewMeal_Confirmed;
	pair: IMealFoodProductMealPair;
}

export const confirmMealServings = (pair: IMealFoodProductMealPair): IMealSwappedAction => ({
	type: NewMeal_Confirmed,
	pair
});

export interface INewSwappedMealSavedAction {
	type: typeof NewMealSwapped_Saved;
	payload: {};
}

export const newSwappedMealSaved = (): INewSwappedMealSavedAction => ({
	type: NewMealSwapped_Saved,
	payload: {}
});

export interface IMealsSwappedClearAction {
	type: typeof NewMealSwappedLeavePage;
	payload: {};
}

export const newSwappedMealCleared = (): IMealsSwappedClearAction => ({
	type: NewMealSwappedLeavePage,
	payload: {}
});
