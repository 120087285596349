import { OrderReducerNamespace } from './interfaces';

import { OrderAction, orderActionName } from './order.actions';
import {
	checkAreCashTransferFormValuesAreValid,
	checkRequiredCashTranferFormAgreements
} from './order.misc';

const cashTransferFormValues = {
	firstName: '',
	surname: '',
	email: '',
	city: '',
	street: '',
	house: '',
	flat: '',
	postCode: ''
};

const cashTransferFormAgreements = {
	dataProcessingAgrement: false,
	cashTransferStatuteAgreement: false
};

export const orderReducerInitialState: OrderReducerNamespace.OrderReducerState = {
	activeDietType: { id: 1, name: 'Standard' },
	activeDietPlanPrice: { dietPlanDefinition: { id: 1 }, regularPricePerMonth: 0 },
	paymentChannels: [],
	activeChannel: {},
	cashTransferFormAgreements,
	cashTransferFormValues,
	isCashTransferFormValid: false,
	paymentResponse: {},
	isUserRegisteredWhileOrdering: false,
	userId: -1
};

export const orderReducer = (
	state = orderReducerInitialState,
	action: OrderAction
): OrderReducerNamespace.OrderReducerState => {
	switch (action.type) {
		case orderActionName.ON_GET_PAYMENT_CHANNELS_RESPONSE: {
			return { ...state, paymentChannels: action.payload?.paymentChannels! };
		}
		case orderActionName.ON_GET_MAKE_PAYMENTS_RESPONSE: {
			return { ...state, paymentResponse: action.payload };
		}
		case orderActionName.ON_SET_ACTIVE_DIET_TYPE: {
			return { ...state, activeDietType: action.payload.dietType };
		}
		case orderActionName.ON_SET_ACTIVE_DIET_PLAN: {
			return { ...state, activeDietPlanPrice: action.payload.dietPlan! };
		}
		case orderActionName.ON_SET_PAYMENT_FORM: {
			return { ...state, cashTransferFormValues: action.payload.formValues };
		}
		case orderActionName.ON_SET_ACTIVE_CHANNEL: {
			const areAllAgreementsChecked = checkRequiredCashTranferFormAgreements(
				state.cashTransferFormAgreements
			);
			const isCashTransferFormValuesValid = checkAreCashTransferFormValuesAreValid(
				state.cashTransferFormValues
			);

			const isCashTransferFormValid =
				areAllAgreementsChecked &&
				isCashTransferFormValuesValid &&
				!!action.payload.activeChannel.id;

			return {
				...state,
				isCashTransferFormValid: isCashTransferFormValid,
				activeChannel: action.payload.activeChannel
			};
		}
		case orderActionName.ON_CASH_TRANSFER_FORM_AGREEMENT_CHANGE: {
			const { agreementKey, value } = action.payload;

			const areAllAgreementsChecked = checkRequiredCashTranferFormAgreements({
				...state.cashTransferFormAgreements,
				[agreementKey]: value
			});
			const isCashTransferFormValuesValid = checkAreCashTransferFormValuesAreValid(
				state.cashTransferFormValues
			);

			const isCashTransferFormValid =
				areAllAgreementsChecked &&
				isCashTransferFormValuesValid &&
				!!state.activeChannel.id;

			return {
				...state,
				isCashTransferFormValid,
				cashTransferFormAgreements: {
					...state.cashTransferFormAgreements,
					[agreementKey]: value
				}
			};
		}
		case orderActionName.ON_CASH_TRANSFER_FIELD_VALUE_CHANGE: {
			const { fieldKey, value } = action.payload;

			const areAllAgreementsChecked = checkRequiredCashTranferFormAgreements(
				state.cashTransferFormAgreements
			);
			const isCashTransferFormValuesValid = checkAreCashTransferFormValuesAreValid({
				...state.cashTransferFormValues,
				[fieldKey]: value
			});

			const isCashTransferFormValid =
				areAllAgreementsChecked &&
				isCashTransferFormValuesValid &&
				!!state.activeChannel.id;

			return {
				...state,
				isCashTransferFormValid,
				cashTransferFormValues: { ...state.cashTransferFormValues, [fieldKey]: value }
			};
		}
		case orderActionName.ON_SET_INITIAL_STATE: {
			return {
				...orderReducerInitialState,
				activeDietPlanPrice: state.activeDietPlanPrice,
				paymentChannels: state.paymentChannels
			};
		}
		case orderActionName.ON_USER_LOGIN_WHILE_ORDERING: {
			return {
				...state,
				cashTransferFormValues: {
					...state.cashTransferFormValues,
					email: action.payload.username
				}
			};
		}
		case orderActionName.ON_USER_REGISTRATION_WHILE_ORDERING: {
			const { username, userId } = action.payload;
			return {
				...state,
				isUserRegisteredWhileOrdering: true,
				cashTransferFormValues: { ...state.cashTransferFormValues, email: username },
				userId: userId
			};
		}
		default:
			return state;
	}
};
