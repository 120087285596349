import './MeasurementBar.scss';
import IndicatorBar from '../IndicatorBar/IndicatorBar';

export interface IMeasurementBarProps {
	label: string;
	currentValue: number;
	expectedValue: number;
	unit: string;
}

const MeasurementBar = ({ label, currentValue, expectedValue, unit }: IMeasurementBarProps) => {
	return (
		<div className="MeasurementBar">
			<div className="MeasurementBar__label">{label}</div>
			<div className="MeasurementBar__content">
				<div className="MeasurementBar__indicator-wrapper">
					<IndicatorBar value={currentValue} valueMax={expectedValue} size="large" />
				</div>
				<div className="MeasurementBar__values">
					<span>{currentValue?.toFixed(1) ?? 0}</span> / {expectedValue?.toFixed(1) ?? 0}
					{unit}
				</div>
			</div>
		</div>
	);
};

export default MeasurementBar;
