import './DietBalance.scss';

import { DietBallanceItem } from '../../../../client';
import MeasurementBar from '../../../atoms/MeasurementBar/MeasurementBar';
import { Range } from 'react-date-range';
import { RangePicker } from '../../../molecules/RangePicker';
import Switch from 'react-switch';
import { getDayBallance } from '../../../../business/durinalBalance/durinalBalance.selectors';
import { getDietBallance } from '../../../../business/durinalBalance/durinalBalance.actions';
import { getNutritionFriendlyFormBallance } from '../../../../shared/helpers';
import moment from 'moment';

import {
	getCustomerDietPlan,
	getCustomerDietPlanDayDateLocal
} from '../../../../business/diet/diet-selector';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

export const DietBalance = () => {
	const dispatch = useDispatch();
	const ballanceItems = useSelector(getDayBallance);
	const currentDpdDateLocal = useSelector(getCustomerDietPlanDayDateLocal);
	const customerDietPlan = useSelector(getCustomerDietPlan);
	const minDateLocal = moment.utc(customerDietPlan?.startDate).toDate();
	const maxDateLocal = moment.utc(customerDietPlan?.endDate).toDate();
	const [isRangeForWeek, setIsRangeForWeek] = useState(false);
	const [range, setRange] = useState<Range>({
		startDate: currentDpdDateLocal,
		endDate: currentDpdDateLocal,
		key: 'ballanceRange'
	} as Range);

	useEffect(() => {
		dispatch(getDietBallance(currentDpdDateLocal, currentDpdDateLocal));
	}, []);

	const changeSwitch = (checked: boolean) => {
		let rangeToChange = range;
		if (checked) {
			if (
				moment(rangeToChange.endDate)
					.subtract(6 * 24, 'hour')
					.isSameOrAfter(moment(minDateLocal))
			) {
				rangeToChange.startDate = moment(rangeToChange.endDate)
					.subtract(6 * 24, 'hour')
					.toDate();
			} else {
				rangeToChange.endDate = moment(rangeToChange.startDate)
					.add(6 * 24, 'hour')
					.toDate();
			}
		} else {
			rangeToChange.startDate = rangeToChange.endDate;
		}
		dispatch(getDietBallance(rangeToChange.startDate!, rangeToChange.endDate!));
		setRange(rangeToChange);
		setIsRangeForWeek(checked);
	};

	const onDateSelected = (startDateLocal: Date, endDateLocal: Date) => {
		dispatch(getDietBallance(startDateLocal, endDateLocal));
	};

	return (
		<div className="DietBalance">
			<div className="DietBalance__header">
				<RangePicker
					className="DietBalance__header_rangePicker"
					onDateSelected={onDateSelected}
					minDateLocal={minDateLocal}
					maxDateLocal={maxDateLocal}
					rangeLocal={range}
					setRange={setRange}
					disableCalendar={isRangeForWeek}
					disableLeftArrow={
						isRangeForWeek &&
						!moment(range.startDate)
							.subtract(7 * 24, 'hour')
							.isSameOrAfter(moment(minDateLocal))
					}
					disableRigthArrow={
						isRangeForWeek &&
						!moment(range.endDate)
							.add(7 * 24, 'hour')
							.isSameOrBefore(moment(maxDateLocal))
					}
				/>
				<div className="switch-wrapper">
					<div className={!isRangeForWeek ? 'highlighted' : ''}>Bilans dniowy</div>
					<Switch
						onChange={changeSwitch}
						checked={isRangeForWeek}
						height={24}
						width={48}
						onColor="#BB6BD9"
						offColor="#BB6BD9"
						checkedIcon={false}
						uncheckedIcon={false}
					/>
					<div className={isRangeForWeek ? 'highlighted' : ''}>Bilans tygodniowy</div>
				</div>
			</div>

			<div className="DietBalance__content">
				{ballanceItems.map((ballanceItem: DietBallanceItem) => (
					<div className="product__detail-wrapper" key={ballanceItem.nutritionName}>
						<MeasurementBar
							label={getNutritionFriendlyFormBallance(ballanceItem.nutritionName!)}
							currentValue={ballanceItem.currentAmount!}
							expectedValue={ballanceItem.avgExpectedAmount!}
							unit={ballanceItem.uoMName!}
						/>
						{ballanceItem.additionalInformation && (
							<div className="product__additional-info">
								<div>
									{ballanceItem.additionalInformation}{' '}
									{ballanceItem.articleUrl && (
										<a href={ballanceItem.articleUrl} target={'_blank'}>
											Dowiedz się więcej
										</a>
									)}
								</div>
							</div>
						)}
					</div>
				))}
			</div>
		</div>
	);
};
