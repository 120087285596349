import './CustomMeal.scss';

import { CustomMealForm } from './CustomMealForm/CustomMealForm';
import React from 'react';

import { ICustomMealComponentProps, ICustomMealComponentState } from './CustomMealInterfaces';

export class CustomMealComponent extends React.Component<
	ICustomMealComponentProps,
	ICustomMealComponentState
> {
	constructor(props: ICustomMealComponentProps, state: ICustomMealComponentState) {
		super(props);
		this.state = this.getInitialState(props);
	}

	public render(): JSX.Element {
		return (
			<div className="page CustomMeal">
				<div className="CustomMeal_Header">
					<p className="header">{this.props.headerText}</p>
				</div>
				<CustomMealForm {...this.props} />
			</div>
		);
	}

	private getInitialState = (props: ICustomMealComponentProps): ICustomMealComponentState => {
		return {} as ICustomMealComponentState;
	};
}
