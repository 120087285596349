import { DietApi } from '../../../../../client';
import { RootState } from '../../../../../business/root-store';
import { ThunkAction } from 'redux-thunk';
import { getBase64 } from '../../../../../shared/helpers';

import { FileApi, FileTypeEnum } from '../../../../../files-client';
import { GetApiConfig, GetFileConfig } from '../../../../../business/api-client-config';
import { dietActions, loadDietPlanDay } from '../../../../../business/diet/diet-action';

export const postMealComment =
	(comment: string, mealId: number): ThunkAction<any, RootState, any, any> =>
	(dispatch, getState) => {
		const state = getState();
		const customerId = state.administration.customerSettings.customerId!;
		const customerName = state.auth.username;
		const api = new DietApi(GetApiConfig(state));
		api.postMealComment({
			mealComment: {
				mealId,
				comments: comment,
				customerId,
				customerName,
				imageOrg: state.diet.commentFiles?.fileInfos.find(
					(z) => z.fileType == FileTypeEnum.OriginalImage
				)?.url,
				imageThumbnail: state.diet.commentFiles?.fileInfos.find(
					(z) => z.fileType == FileTypeEnum.ThumbnailImage
				)?.url,
				imageResized: state.diet.commentFiles?.fileInfos.find(
					(z) => z.fileType == FileTypeEnum.ModifiedImage
				)?.url
			}
		})
			.then((z) => {
				dispatch(dietActions.clearCommentsImages());
			})
			.then(() => {
				const dietDay = state.diet.dietPlanDay!.dietDayId;
				dispatch(loadDietPlanDay(dietDay!));
			});
	};

export const postMealImageComment =
	(file: File, mealId: number): ThunkAction<any, RootState, any, any> =>
	(dispatch, getState) => {
		const state = getState();
		const api = new FileApi(GetFileConfig(state));

		getBase64(file, (base64: string) => {
			var convert = base64.substr(base64.indexOf(',') + 1);
			{
				api.postFileCatalog({ name: 'Images Comment to' + mealId }).then((x) => {
					api.postImageFile({
						imageData: convert,
						name: file.name,
						catalogId: x.data.catalogId,
						resize: true,
						generateThumbminal: true,
						width: 1280,
						height: 720,
						keepResizeRatio: true,
						thumbminalHeight: 225,
						thumbminalWidth: 400
					}).then((z) => {
						dispatch(
							dietActions.loadCommentsImages(x.data.catalogId!, z.data.fileInfos!)
						);
					});
				});
			}
		});
	};
