import * as React from 'react';
import * as Redux from 'redux';

import { CustomMealComponent } from './CustomMealComponent';
import { ICurrentMeals } from '../Meal/meal-interfaces';
import { IImageFile } from '../../../../shared/interfaces/IImageFile';
import { ISearchItem } from '../../../atoms/search-box/search-box';
import { Meal } from '../../../../client';
import { RootState } from '../../../../business/root-store';
import { connect } from 'react-redux';
import { getCurrentMealsSelector } from '../Meal/meal-selectors';
import { getProductsSelector } from './CustomMealSelectors';
import { getRestaurantImagesSelector } from '../../../../business/Restaurants/RestaurantsSelectors';
import { push } from 'connected-react-router';
import { setCurrentMeals } from '../Meal/meal';

import { AddToastPayload, actions } from 'react-redux-toastr';
import { AnyAction, Dispatch } from 'redux';
import { addCustomMealImage, getProductRefAndNames } from './CustomMealEpics';

interface ICustomMealContainerReduxMergedState {
	allIngredients: ISearchItem[];
	mealsImages: IImageFile[];
	currentMeals: ICurrentMeals;
}

interface ICustomMealContainerOwnProps {
	headerText: string;
	disableCustomMacros?: boolean;
	handleCustomProductModalClicked: (value: boolean) => void;
	submitCustomMeals(meals: Meal[]): void;
}

interface ICustomMealContainerDispatch {
	navigateTo: (path: string) => void;
	getProductRefAndNames: () => void;
	setCurrentMeals: (currentMeals: ICurrentMeals) => void;
	addMealImage(file: File, mealIndex: number): void;
	toast: (message: AddToastPayload) => void;
}
interface ICustomMealContainerProps
	extends ICustomMealContainerOwnProps,
		ICustomMealContainerDispatch,
		ICustomMealContainerReduxMergedState {}

class CustomMealContainer extends React.Component<ICustomMealContainerProps> {
	constructor(props: ICustomMealContainerProps) {
		super(props);
		if (props.allIngredients.length == 0) {
			props.getProductRefAndNames();
		}
	}

	public render(): JSX.Element {
		return (
			<CustomMealComponent
				handleCustomProductModalClicked={this.props.handleCustomProductModalClicked}
				addMealImage={this.props.addMealImage}
				setCurrentMeals={this.props.setCurrentMeals}
				allIngredients={this.props.allIngredients}
				currentMeals={this.props.currentMeals}
				toast={this.props.toast}
				navigateTo={this.props.navigateTo}
				mealsImages={this.props.mealsImages}
				disableCustomMacros={this.props.disableCustomMacros}
				headerText={this.props.headerText}
				submitCustomMeals={this.props.submitCustomMeals}
				key={
					this.props.currentMeals.meals.reduce(
						(partialSum, meal) => partialSum + (meal.mealFoodProducts?.length ?? 0),
						0
					) + this.props.mealsImages.length
				}
			/>
		);
	}

	public static mapStateToProps = (
		state: RootState,
		ownProps: ICustomMealContainerOwnProps
	): ICustomMealContainerReduxMergedState => {
		return {
			allIngredients: getProductsSelector(state.static.products),
			mealsImages: getRestaurantImagesSelector(state),
			currentMeals: getCurrentMealsSelector(state)
		};
	};

	public static mapDispatchToProps = (
		dispatch: Dispatch<AnyAction>
	): ICustomMealContainerDispatch => {
		return {
			...Redux.bindActionCreators(
				{
					navigateTo: push,
					getProductRefAndNames: getProductRefAndNames,
					toast: actions.add,
					addMealImage: addCustomMealImage,
					setCurrentMeals: setCurrentMeals
				},
				dispatch
			)
		};
	};
}
export const CustomMeal = connect(
	CustomMealContainer.mapStateToProps,
	CustomMealContainer.mapDispatchToProps
)(CustomMealContainer);
