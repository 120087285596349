import './day-box.scss';

import { useDefaultId } from '../../../hooks/use-default-id';

import React, { InputHTMLAttributes } from 'react';

export interface IDayBoxProps {
	setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
	displayName?: string;
}

export const DayBox = ({
	displayName,
	setFieldValue,
	...props
}: IDayBoxProps & InputHTMLAttributes<HTMLInputElement>) => {
	const id = useDefaultId(props.id || props.name);
	const count = props.value as number;
	const click = () => {
		let newCnt: number | undefined = count || 0;
		if (newCnt < 2) {
			newCnt++;
		} else {
			newCnt = undefined;
		}
		setFieldValue(props.name!, newCnt);
	};
	return (
		<div id={id} onClick={click} className={`day-box ${count > 0 ? 'day-box--checked' : ''}`}>
			{count > 0 && <div className="day-box__number">{count}</div>}
			<span>{displayName}</span>
		</div>
	);
};
