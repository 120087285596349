import './SideBar.scss';

import { ActionButton } from '../../atoms/ActionButton/ActionButton';
import { CloseIcon } from '../../../shared/assets/close-icon';
import { Link } from 'react-router-dom';
import { LogOutIcon } from '../../../shared/assets/log-out-icon';
import { authenticationActions } from '../../../business/auth/auth-actions';
import { documentsUrls } from '../../../shared/shared';
import { isSignedIn } from '../../../business/auth/auth-selector';
import { useOutsideClick } from '../../../hooks/use-outside-click';

import React, { RefObject, useRef } from 'react';
import {
	createUrlWithParams,
	navigationAction,
	routes
} from '../../../business/router/path-definition';
import { useDispatch, useSelector } from 'react-redux';

export interface ISideBarProps {
	onCloseMenu(): void;
}

export const SideBar: React.FC<ISideBarProps> = (props) => {
	const dispatch = useDispatch();
	const isLoggedIn = useSelector(isSignedIn);
	const wrapperRef: RefObject<any> = useRef();

	useOutsideClick(wrapperRef, () => {
		props.onCloseMenu();
	});

	const logout = () => {
		dispatch(authenticationActions.logOutAction());
		dispatch(navigationAction.open(routes.home.path));
		props.onCloseMenu();
	};

	const main = () => {
		dispatch(navigationAction.open(routes.home.path));
		props.onCloseMenu();
	};

	const login = () => {
		dispatch(navigationAction.open(routes.auth.login.path));
		props.onCloseMenu();
	};

	const contact = () => {
		dispatch(navigationAction.open(routes.home.path, { search: 'contact-form' }));
		props.onCloseMenu();
	};

	const register = () => {
		dispatch(navigationAction.open(routes.auth.register.path));
		props.onCloseMenu();
	};

	const handleClickAccountSettings = () => {
		dispatch(navigationAction.open(routes.customer.settings.account.path));
		props.onCloseMenu();
	};

	const handleClickDietSettings = () => {
		dispatch(navigationAction.open(routes.customer.settings.diet.path));
		props.onCloseMenu();
	};

	return (
		<div className="SideBar" ref={wrapperRef}>
			<div className="SideBar__close-icon">
				<CloseIcon onClick={props.onCloseMenu} />
			</div>
			<br />
			<ul>
				<li onClick={main}>Strona Główna</li>
				<li>
					<Link to={routes.static.blog.path}>Blog</Link>
				</li>
				<li onClick={contact}>Kontakt</li>
				{/* <li>
					<Link to={routes.customer.meal.analyze}>Analiza Posiłku</Link>
				</li> */}
				<li>
					<Link to={'/restaurants'}>Zjadłem na mieście</Link>
				</li>
				<li>
					<Link
						to={createUrlWithParams(routes.static.document.path, {
							documentUrl: documentsUrls.getstarted
						})}
					>
						Jak to działa
					</Link>
				</li>
			</ul>
			{isLoggedIn ? (
				<>
					<ul className="SideBar__menu-bottom">
						<li onClick={handleClickAccountSettings}>Ustawienia konta</li>
						<li onClick={handleClickDietSettings}>Ustawienia diety</li>
					</ul>
					<div className="SideBar__action-buttons">
						<ActionButton text="Panel Klienta" />
						<ActionButton
							styleType="secondary"
							text="Wyloguj"
							onClick={logout}
							icon={<LogOutIcon />}
						/>
					</div>
				</>
			) : (
				<div className="SideBar__action-buttons">
					<ActionButton text="Zaloguj" onClick={login} />
					<ActionButton text="Zarejestruj" styleType="secondary" onClick={register} />
				</div>
			)}
		</div>
	);
};
