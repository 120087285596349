import { IImageFile } from '../../shared/interfaces/IImageFile';
import { Reducer } from 'redux';
import { Restaurant } from '../../client';

import {
	IMarkersPlaceIdsSet,
	IRestaurantFetchedAction,
	IRestaurantImagesFetchedAction,
	IRestaurantsFetchedAction,
	MarkersPlaceIds_Set,
	RestaurantImages_Fetched,
	Restaurant_Fetched,
	Restaurants_Fetched
} from './RestaurantsActions';

type ReducerActions =
	| IMarkersPlaceIdsSet
	| IRestaurantsFetchedAction
	| IRestaurantFetchedAction
	| IRestaurantImagesFetchedAction;

interface IRestaurantsDataReduxState {
	markersPlaceIds: string[];
	restaurants: Restaurant[];
	restaurant: Restaurant;
	restaurantImages: IImageFile[];
}

const INITIAL_STATE: IRestaurantsDataReduxState = {
	markersPlaceIds: [],
	restaurants: [],
	restaurant: {},
	restaurantImages: []
};
export const restaurantsReducer: Reducer<IRestaurantsDataReduxState, ReducerActions> = (
	state = INITIAL_STATE,
	action
) => {
	switch (action.type) {
		case MarkersPlaceIds_Set:
			return handleMarkersIdsSet(state, action);
		case Restaurants_Fetched:
			return handleRestaurantsFetched(state, action);
		case Restaurant_Fetched:
			return handleRestaurantFetched(state, action);
		case RestaurantImages_Fetched:
			return handleRestaurantImagesFetched(state, action);
		default:
			return state;
	}
};

const handleMarkersIdsSet = (
	state: IRestaurantsDataReduxState,
	action: IMarkersPlaceIdsSet
): IRestaurantsDataReduxState => {
	return {
		...state,
		markersPlaceIds: action.markersPlaceIds
	};
};

const handleRestaurantsFetched = (
	state: IRestaurantsDataReduxState,
	action: IRestaurantsFetchedAction
): IRestaurantsDataReduxState => {
	return {
		...state,
		restaurants: action.restaurants
	};
};

const handleRestaurantFetched = (
	state: IRestaurantsDataReduxState,
	action: IRestaurantFetchedAction
): IRestaurantsDataReduxState => {
	return {
		...state,
		restaurant: action.restaurant
	};
};

const handleRestaurantImagesFetched = (
	state: IRestaurantsDataReduxState,
	action: IRestaurantImagesFetchedAction
): IRestaurantsDataReduxState => {
	return {
		...state,
		restaurantImages: [...state.restaurantImages, action.images]
	};
};
