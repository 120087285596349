import { GetApiConfig } from '../../../business/api-client-config';
import { RootState } from '../../../business/root-store';
import { ThunkAction } from 'redux-thunk';
import { loadDietPlanDay } from '../../../business/diet/diet-action';

import { DietApi, MealTypeEnum } from '../../../client';

export const processEatenMeal =
	(mealType: MealTypeEnum): ThunkAction<any, RootState, any, any> =>
	(dispatch, getState) => {
		const state: RootState = getState();
		const api = new DietApi(GetApiConfig(state));
		const dietPlanId = state.diet.dietPlanDay!.dietPlanId;
		const dietDay = state.diet.dietPlanDay!.dietDayId;
		api.postProcessEatenMeal({ dayId: dietDay, dietPlanId: dietPlanId, mealType: mealType })
			.then(() => {
				dispatch(loadDietPlanDay(dietDay!));
			})
			.catch((c) => {});
	};
