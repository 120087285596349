import { Header } from '../../atoms/header/HeaderComponent';
import { HeaderComponentProps } from './interfaces';

export const HeaderComponent = ({
	isTrial,
	dietDescritpion,
	onPreferencesClicked,
	userName
}: HeaderComponentProps) => {
	return isTrial ? (
		<Header subtitle="Okres Testowy"> {dietDescritpion}</Header>
	) : (
		<Header showDietPreferences onDietPreferencesClick={onPreferencesClicked}>
			Cześć {userName}
		</Header>
	);
};
