import * as React from 'react';
import * as Redux from 'redux';

import { BeveragesEditorComponent } from './BeveragesEditorComponent';
import { ICurrentMeals } from '../../MealCard/Meal/meal-interfaces';
import { List } from 'immutable';
import { RootState } from '../../../../business/root-store';
import { addOrUpdateCustomerMeals } from '../../MealCard/CustomMeal/CustomMealEpics';
import { connect } from 'react-redux';
import { getBeveragesSelector } from './BeveragesEditorSelectors';
import { getCurrentMealsSelector } from '../../MealCard/Meal/meal-selectors';
import { navigationAction } from '../../../../business/router/path-definition';

import { AddToastPayload, actions } from 'react-redux-toastr';
import { AnyAction, Dispatch } from 'redux';
import { Gender, Meal, MealTypeEnum } from '../../../../client';
import { deleteDietPlanDayMeal, getBeverages } from '../beverages-epics';

interface IBeveragesEditorContainerReduxMergedProps {
	gender: Gender;
	allBeverages: List<Meal>;
	currentMeals: ICurrentMeals;
}

export interface IBeveragesEditorContainerOwnProps {}

interface IBeveragesEditorContainerDispatchProps {
	onCloseClicked: () => void;
	fetchBeverages: () => void;
	addOrUpdateMeals: (meals: Meal[]) => void;
	toast: (message: AddToastPayload) => void;
	deleteDpdm: (mealId: number, mealType: MealTypeEnum) => void;
}

interface IBeveragesEditorContainerProps
	extends IBeveragesEditorContainerOwnProps,
		IBeveragesEditorContainerDispatchProps,
		IBeveragesEditorContainerReduxMergedProps {}

interface IBeveragesEditorComponentState {}

class BeveragesEditorContainer extends React.Component<
	IBeveragesEditorContainerProps,
	IBeveragesEditorComponentState
> {
	constructor(props: IBeveragesEditorContainerProps, state: IBeveragesEditorComponentState) {
		super(props, state);

		this.state = this.getInitialState();
		if (props.allBeverages.count() === 0) props.fetchBeverages();
	}

	public render(): JSX.Element {
		return (
			<BeveragesEditorComponent
				currentMeals={this.props.currentMeals}
				gender={this.props.gender}
				onCloseClicked={this.props.onCloseClicked}
				allBeverages={this.props.allBeverages}
				addOrUpdateMeals={this.props.addOrUpdateMeals}
				toast={this.props.toast}
				deleteDpdm={this.props.deleteDpdm}
			/>
		);
	}

	public componentDidMount(): void {}

	public static mapStateToProps = (
		state: RootState,
		ownProps: IBeveragesEditorContainerOwnProps
	): IBeveragesEditorContainerReduxMergedProps => {
		return {
			gender: Gender.Female, //todo
			allBeverages: getBeveragesSelector(state),
			currentMeals: getCurrentMealsSelector(state)
		};
	};

	public static mapDispatchToProps = (
		dispatch: Dispatch<AnyAction>
	): IBeveragesEditorContainerDispatchProps => {
		return {
			...Redux.bindActionCreators(
				{
					onCloseClicked: navigationAction.goBack,
					fetchBeverages: getBeverages,
					addOrUpdateMeals: addOrUpdateCustomerMeals,
					toast: actions.add,
					deleteDpdm: deleteDietPlanDayMeal
				},
				dispatch
			)
		};
	};

	private getInitialState = (): IBeveragesEditorComponentState => {
		return {} as IBeveragesEditorComponentState;
	};
}
export const BeveragesEditor = connect(
	BeveragesEditorContainer.mapStateToProps,
	BeveragesEditorContainer.mapDispatchToProps
)(BeveragesEditorContainer);
