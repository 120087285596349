import './shopping-list.scss';
import 'moment/locale/pl';

import { ActionButton } from '../../../atoms/ActionButton/ActionButton';
import { CheckBox } from '../../../atoms/CheckBox/CheckBox';
import PoppinsMedium from '../../../../shared/assets/fonts/Poppins/Poppins-Medium.ttf';
import PoppinsRegular from '../../../../shared/assets/fonts/Poppins/Poppins-Regular.ttf';
import React from 'react';
import { ShoppingDateRange } from './shopping-list-date-range/shopping-list-date-range-container';
import _ from 'lodash';
import { logoDdapp } from './logoDdapp';
import moment from 'moment';
import { primaryOrangeColor } from '../../../../shared/shared';

import {
	Document,
	Font,
	Link,
	PDFDownloadLink,
	Page,
	StyleSheet,
	Text,
	View
} from '@react-pdf/renderer';
import {
	GetDatesRangeString,
	capitalizeOnlyFirstLetter,
	getFriendlyFractionsString
} from '../../../../shared/helpers';
import { ShoppingItem, ShoppingList } from '../../../../client';

interface IShoppingListComponentProps {
	shoppingList?: ShoppingList;
	onItemBought: (id: number) => void;
}

interface IShoppingListComponentState {
	groupedItems: _.Dictionary<ShoppingItem[]>;
}

export class ShoppingListComponent extends React.Component<
	IShoppingListComponentProps,
	IShoppingListComponentState
> {
	constructor(props: IShoppingListComponentProps, state: IShoppingListComponentState) {
		super(props, state);

		this.state = this.getInitialState();
	}
	private startDateUtc = moment.utc(this.props.shoppingList?.startDate!).toDate();
	private endDateUtc = moment.utc(this.props.shoppingList?.endDate!).toDate();
	private datesStringLocal = GetDatesRangeString(this.startDateUtc, this.endDateUtc);

	public render(): JSX.Element {
		return (
			<div id="shopping-list">
				<ShoppingDateRange
					pdfDownloadLink={
						<PDFDownloadLink
							document={<this.ShoppingListPdf />}
							fileName={'DietDesigner ' + this.datesStringLocal + '.pdf'}
						>
							{({ blob, url, loading, error }) =>
								loading ? (
									'Generujemy listę zakupów do wydrukowania...'
								) : (
									<ActionButton
										className="ActionButton--secondary"
										text="Wydrukuj listę"
									/>
								)
							}
						</PDFDownloadLink>
					}
					startDateUtc={this.startDateUtc}
					endDateUtc={this.endDateUtc}
				/>
				<div className="shopping-list__wrapper">
					{Object.entries(this.state.groupedItems).map(([key, value]) =>
						this.renderListSection(key, value)
					)}
				</div>
			</div>
		);
	}
	ShoppingListPdf = () => {
		Font.register({
			family: 'Poppins Regular',
			format: 'truetype',
			src: PoppinsRegular
		});
		Font.register({ family: 'Poppins Medium', format: 'truetype', src: PoppinsMedium });
		const styles = StyleSheet.create({
			page: {
				fontFamily: 'Poppins Regular',
				paddingTop: 35,
				paddingBottom: 65,
				paddingHorizontal: 35
			},
			title: {
				fontSize: 18,
				textAlign: 'center',
				fontFamily: 'Poppins Medium'
			},
			sectionTitle: {
				fontSize: 14,
				fontFamily: 'Poppins Medium'
			},
			date: {
				fontSize: 10,
				textAlign: 'center',
				marginBottom: 10
			},
			text: {
				marginLeft: 10,
				fontSize: 10,
				textAlign: 'justify'
			},
			lineThrough: {
				textDecoration: 'line-through'
			},
			logo: {
				alignItems: 'center'
			},
			footer: {
				position: 'absolute',
				fontSize: 12,
				bottom: 30,
				left: 0,
				right: 0,
				textAlign: 'center',
				color: primaryOrangeColor,
				textDecoration: 'none'
			},
			section: {
				marginBottom: 10
			}
		});
		const pdfList = (groupName: string, items: ShoppingItem[]): JSX.Element => {
			return (
				<View style={styles.section}>
					<Text style={styles.sectionTitle}>{capitalizeOnlyFirstLetter(groupName)}</Text>
					{items.map((item: ShoppingItem) => {
						return (
							<Text
								style={
									item.isBought ? [styles.text, styles.lineThrough] : styles.text
								}
							>
								{item.productName! +
									' ' +
									(item.qtyInHomeMeasurement
										? getFriendlyFractionsString(item.qtyInHomeMeasurement) +
										  ' ' +
										  item.qtyInText +
										  ' (' +
										  item.qtyInGrams?.toFixed(0) +
										  'g)'
										: item.qtyInGrams?.toFixed(0) + 'g')}
							</Text>
						);
					})}
				</View>
			);
		};

		return (
			<Document>
				<Page size="A4" style={styles.page}>
					<View style={styles.logo}>{logoDdapp()}</View>
					<Text style={styles.title}>Lista zakupów</Text>
					<Text style={styles.date}>{this.datesStringLocal}</Text>
					<View>
						{Object.entries(this.state.groupedItems).map(([key, value]) =>
							pdfList(key, value)
						)}
					</View>
					<Link style={styles.footer} fixed src="https://dietdesigner.pl/">
						dietdesigner.pl
					</Link>
				</Page>
			</Document>
		);
	};

	private renderListSection = (groupName: string, items: ShoppingItem[]): JSX.Element => {
		return (
			<div className="shopping-list__section">
				<div className="shopping-list__section-header">
					{capitalizeOnlyFirstLetter(groupName)}
				</div>
				<div className="shopping-list__section-content">
					{items.map((item: ShoppingItem) => {
						const handleItemClicked = (): void => {
							this.props.onItemBought(item.id!);
						};
						return (
							<CheckBox
								key={item.id}
								checked={item.isBought ?? false}
								displayName={
									item.productName! +
									' ' +
									(item.qtyInHomeMeasurement
										? getFriendlyFractionsString(item.qtyInHomeMeasurement) +
										  ' ' +
										  item.qtyInText +
										  ' (' +
										  item.qtyInGrams?.toFixed(0) +
										  'g)'
										: item.qtyInGrams?.toFixed(0) + 'g')
								}
								onChange={handleItemClicked}
							/>
						);
					})}
				</div>
			</div>
		);
	};
	private getInitialState = (): IShoppingListComponentState => {
		var groupedItems = _.groupBy(
			this.props.shoppingList?.shoppingItems,
			(x) => x.productGroupName ?? 'Inne'
		);
		return {
			groupedItems: groupedItems
		} as IShoppingListComponentState;
	};
}
