import './meal-card.scss';

import ActionLink from '../../atoms/action-link/action-link';
import { CheckBox } from '../../atoms/CheckBox/CheckBox';
import { DropdownMenu } from '../../atoms/DropdownMenu/DropdownMenu';
import { ICurrentMeals } from './Meal/meal-interfaces';
import { IDpdmWithImage } from './meal-card-interfaces';
import { INutritionFactsSummary } from '../../../shared/helpers';
import { Meal } from './Meal/meal-container';
import { MealCardHeader } from './meal-card-header/meal-card-header-container';
import { MealTypeEnum } from '../../../client';
import { NutritionFactSummary } from '../nutrition-fact-summary/nutrition-fact-summary';
import React from 'react';
import imagePlaceholderIcon from '../../../shared/assets/svg/image-placeholder.svg';
import { primaryOrangeColor } from '../../../shared/shared';
import { routes } from '../../../business/router/path-definition';

interface IMealCardComponentProps {
	title: string;
	mealType: MealTypeEnum;
	meals: IDpdmWithImage[];
	summary: INutritionFactsSummary;
	currentMeals: ICurrentMeals;
	navigateTo(path: string, state?: any): void;
	setCurrentMeals: (currentMeals: ICurrentMeals) => void;
	onProcessEatenMeal: (mealType: MealTypeEnum) => void;
	onCopyClicked: (value: boolean) => void;
	onExistingMealModalClicked: (value: boolean) => void;
	onCustomMealModalClicked: (value: boolean) => void;
}

interface IMealCardComponentState {
	isInnerMealOptionsVisible: boolean;
}

export class MealCardComponent extends React.Component<
	IMealCardComponentProps,
	IMealCardComponentState
> {
	constructor(props: IMealCardComponentProps) {
		super(props);

		this.state = this.getInitialState();
	}

	componentDidMount() {
		const firstCard = document.getElementById('slide_Breakfast');
		if (firstCard != null) {
			firstCard.scrollIntoView();
		}
	}

	private handleMealClicked = (e: any): void => {
		e.preventDefault();
		this.props.setCurrentMeals({
			...this.props.currentMeals,
			mealType: this.props.meals[0].dietPlanDayMeal.mealType!,
			dpdmWithImage: this.props.meals[0]
		});
		this.props.navigateTo(routes.customer.meal.details.path);
	};

	public render(): JSX.Element {
		const isEaten = this.props.meals.every(
			(meal: IDpdmWithImage) => meal.dietPlanDayMeal.isMealEaten === true
		);

		return (
			<div className="meal-card-wrapper">
				<div className="meal-card">
					{this.props.meals.length > 0 && (
						<div className="meal-card__header">
							{this.renderHeader(this.props.meals[0])}
						</div>
					)}

					<div className="meal-card__main">
						<div
							className="meal-card__image"
							style={{
								backgroundImage: `url(${this.props.meals[0].imageUrl})`
							}}
							onClick={
								this.props.meals.length === 1 ? this.handleMealClicked : undefined
							}
						>
							{!this.props.meals[0].imageUrl && (
								<img src={imagePlaceholderIcon} alt="Brak zdjęcia" />
							)}
						</div>

						<div className="style-mobile">
							<NutritionFactSummary summary={this.props.summary} />
						</div>

						<div className="meal-card__content">
							{this.props.meals.map((meal: IDpdmWithImage) => {
								return (
									<Meal
										key={meal.dietPlanDayMeal.id}
										mealWithImage={meal}
										isFavouriteVisible={this.props.meals.length > 1}
										onEditMealModalClicked={this.props.onCustomMealModalClicked}
										onCopyClicked={this.props.onCopyClicked}
									/>
								);
							})}
						</div>
					</div>
					<div className="meal-card__footer">
						<CheckBox
							key={'key_' + this.props.title}
							displayName="Zjedzony"
							checked={isEaten}
							onChange={this.handleEatMealClicked}
						/>
						{this.renderInnerMealEaten()}
					</div>
				</div>
			</div>
		);
	}

	private renderInnerMealEaten = (): JSX.Element => {
		return (
			<div className="meal-card__meal-eaten">
				<DropdownMenu
					triggerItem={
						<ActionLink text="Zjadłem inny posiłek" textColor={primaryOrangeColor} />
					}
					menuItems={[
						// {
						// 	label: 'Przeanalizuj zdjęcie',
						// 	onClick: this.handleAnalysePhoto
						// },
						{
							label: 'Wybierz z bazy',
							onClick: this.handleExistingMealClicked
						},
						{
							label: 'Zjadłem na mieście',
							onClick: this.handleMealFromRestaurant
						},
						{
							label: 'Dodaj własny posiłek',
							onClick: this.handleCustomMealClicked
						}
					]}
					position="upLeft"
				/>
			</div>
		);
	};

	private handleAnalysePhoto = (): void => {
		this.props.navigateTo(routes.customer.meal.analyze.path);
	};

	private handleCustomMealClicked = (): void => {
		this.props.setCurrentMeals({
			...this.props.currentMeals,
			mealType: this.props.mealType,
			meals: []
		});
		this.props.onCustomMealModalClicked(true);
	};

	private handleExistingMealClicked = (): void => {
		this.props.setCurrentMeals({
			...this.props.currentMeals,
			mealType: this.props.mealType
		});
		this.props.onExistingMealModalClicked(true);
	};
	private handleMealFromRestaurant = (): void => {
		this.props.setCurrentMeals({
			...this.props.currentMeals,
			mealType: this.props.mealType
		});
		this.props.navigateTo(routes.restaurants.restaurantsList.path);
	};

	private handleCopyClicked = (): void => {
		this.props.setCurrentMeals({
			...this.props.currentMeals,
			mealType: this.props.mealType,
			meals: this.props.meals.map((x) => x.dietPlanDayMeal.meal!)
		});
		this.props.onCopyClicked(true);
	};

	private handleOnInnerMealEaten = (): void => {
		this.setState({ isInnerMealOptionsVisible: !this.state.isInnerMealOptionsVisible });
	};

	private renderHeader(meal: IDpdmWithImage): JSX.Element {
		return (
			<MealCardHeader
				header={this.props.title}
				mealId={meal.dietPlanDayMeal.mealId!}
				isFavouriteMeal={meal.dietPlanDayMeal.isFavourite!}
				showFavourite={this.props.meals.length === 1}
				onCopy={this.handleCopyClicked}
				summary={this.props.summary}
			/>
		);
	}

	private handleEatMealClicked = () => {
		this.props.onProcessEatenMeal(this.props.mealType);
	};

	private getInitialState = (): IMealCardComponentState => {
		return {
			isInnerMealOptionsVisible: false,
			showCopyMealModal: false,
			showCustomMealModal: false,
			showExistingMealModal: false,
			showAddMealModal: false
		} as IMealCardComponentState;
	};
}
