import { FileMetadata } from '../../files-client';
import { RootState } from '../root-store';
import { createSelector } from 'reselect';

import { Document, Meal, Restaurant } from '../../client';

const rootStateSelector = (state: RootState) => state;

export const getPartnershipRestaurantSelector = createSelector(
	rootStateSelector,
	(state: RootState): Restaurant => state.administration.partnership.restaurant
);

export const getPartnershipRestaurantIdSelector = createSelector(
	rootStateSelector,
	(state: RootState): number | undefined => state.administration.partnership.restaurant.id
);

export const getPartnershipRestaurantDocumentsSelector = createSelector(
	rootStateSelector,
	(state: RootState): FileMetadata[] => state.administration.partnership.restaurantDocuments
);

export const getPartnershipRestaurantImagesSelector = createSelector(
	rootStateSelector,
	(state: RootState): FileMetadata[] => state.administration.partnership.restaurantImages
);

export const getPartnershipDocumentSelector = createSelector(
	rootStateSelector,
	(state: RootState): Document => state.administration.partnership.document
);

export const getPartnershipEditRestaurantMealSelector = createSelector(
	rootStateSelector,
	(state: RootState): Meal => state.administration.partnership.editRestaurantMeal
);
