import * as React from 'react';
import * as Redux from 'redux';

import { FileMetadata } from '../../../../files-client';
import { PartnershipInitialComponent } from './PartnershipInitialComponent';
import { RootState } from '../../../../business/root-store';
import { connect } from 'react-redux';
import { documentsUrls } from '../../../../shared/shared';
import { getDocument } from '../../../molecules/staticData/staticDataSelectors';
import { getDocumentByUrl } from '../../Document/DocumentEpics';
import { partnershipRestaurantFetched } from '../../../../business/Partnership/PartnershipActions';
import { removePartnershipRestaurantImage } from './PartnershipInitialEpics';

import { AnyAction, Dispatch } from 'redux';
import { Document, Restaurant } from '../../../../client';
import {
	getPartnershipRestaurantImagesSelector,
	getPartnershipRestaurantSelector
} from '../../../../business/Partnership/PartnershipSelectors';

interface IPartnershipInitialContainerReduxMergedProps {
	document: Document;
	restaurant: Restaurant;
	restaurantImages: FileMetadata[];
}

interface IPartnershipInitialContainerOwnProps {
	disabled?: boolean;
}

interface IPartnershipInitialContainerDispatchProps {
	getDocumentByUrl: (documentUrl: string) => void;
	removePartnershipRestaurantImage: (fileGuid: string) => void;
	partnershipRestaurantFetched: (restaurant: Restaurant) => void;
}

interface IPartnershipInitialContainerProps
	extends IPartnershipInitialContainerOwnProps,
		IPartnershipInitialContainerDispatchProps,
		IPartnershipInitialContainerReduxMergedProps {}

interface IPartnershipInitialContainerState {}

class PartnershipInitialContainer extends React.Component<
	IPartnershipInitialContainerProps,
	IPartnershipInitialContainerState
> {
	constructor(
		props: IPartnershipInitialContainerProps,
		state: IPartnershipInitialContainerState
	) {
		super(props, state);

		this.state = this.getInitialState();
		if (props.restaurant.id === undefined) {
			this.props.getDocumentByUrl(documentsUrls.promo.partnershippromo);
		}
	}

	public render(): JSX.Element {
		return (
			<PartnershipInitialComponent
				document={this.props.document}
				restaurant={this.props.restaurant}
				restaurantImages={this.props.restaurantImages}
				disabled={this.props.disabled}
				removePartnershipRestaurantImage={this.props.removePartnershipRestaurantImage}
				partnershipRestaurantFetched={this.props.partnershipRestaurantFetched}
			/>
		);
	}

	public componentDidMount(): void {}

	public static mapStateToProps = (
		state: RootState,
		ownProps: IPartnershipInitialContainerOwnProps
	): IPartnershipInitialContainerReduxMergedProps => {
		return {
			document: getDocument(state),
			restaurantImages: getPartnershipRestaurantImagesSelector(state),
			restaurant: getPartnershipRestaurantSelector(state)
		};
	};

	public static mapDispatchToProps = (
		dispatch: Dispatch<AnyAction>
	): IPartnershipInitialContainerDispatchProps => {
		return {
			...Redux.bindActionCreators(
				{
					getDocumentByUrl: getDocumentByUrl,
					removePartnershipRestaurantImage: removePartnershipRestaurantImage,
					partnershipRestaurantFetched: partnershipRestaurantFetched
				},
				dispatch
			)
		};
	};

	private getInitialState = (): IPartnershipInitialContainerState => {
		return {} as IPartnershipInitialContainerState;
	};
}
export const PartnershipInitial = connect(
	PartnershipInitialContainer.mapStateToProps,
	PartnershipInitialContainer.mapDispatchToProps
)(PartnershipInitialContainer);
