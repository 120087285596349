import * as React from 'react';
import * as Redux from 'redux';

import { RootState } from '../../../business/root-store';
import { WeekViewComponent } from './WeekViewComponent';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { routes } from '../../../business/router/path-definition';

import { AnyAction, Dispatch } from 'redux';

interface IWeekViewContainerReduxMergedProps {}

interface IWeekViewContainerOwnProps {}

interface IWeekViewContainerDispatchProps {
	navigateTo: (path: string, state?: any) => void;
}

interface IWeekViewContainerProps
	extends IWeekViewContainerOwnProps,
		IWeekViewContainerDispatchProps,
		IWeekViewContainerReduxMergedProps {}

interface IWeekViewComponentState {}

class WeekViewContainer extends React.Component<IWeekViewContainerProps, IWeekViewComponentState> {
	constructor(props: IWeekViewContainerProps, state: IWeekViewComponentState) {
		super(props, state);

		this.state = this.getInitialState();
	}

	public render(): JSX.Element {
		return <WeekViewComponent onPreviousClicked={this.handleOnPreviousClicked} />;
	}

	public static mapStateToProps = (
		state: RootState,
		ownProps: IWeekViewContainerOwnProps
	): IWeekViewContainerReduxMergedProps => {
		return {};
	};

	public static mapDispatchToProps = (
		dispatch: Dispatch<AnyAction>
	): IWeekViewContainerDispatchProps => {
		return {
			...Redux.bindActionCreators(
				{
					navigateTo: push
				},
				dispatch
			)
		};
	};

	private handleOnPreviousClicked = (): void => {
		this.props.navigateTo(routes.home.path);
	};

	private getInitialState = (): IWeekViewComponentState => {
		return {} as IWeekViewComponentState;
	};
}
export const WeekView = connect(
	WeekViewContainer.mapStateToProps,
	WeekViewContainer.mapDispatchToProps
)(WeekViewContainer);
