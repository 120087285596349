import { IImageFile } from '../../../shared/interfaces/IImageFile';

export const getMealImage = (dietFiles: IImageFile[], catalogId?: number): string => {
	const element = dietFiles.find((x) => x.catalogId === catalogId);
	if (
		!(element == null || element == undefined) &&
		!(element.metadatas == null || element.metadatas == undefined)
	) {
		const file = element.metadatas.find((x) => (x.fileName || '').indexOf('thumb') < 0);
		if (
			!(file == null || file == undefined) &&
			!(file.lastVersion == null || file.lastVersion == undefined) &&
			!(file.lastVersion.url == null || file.lastVersion.url == undefined)
		) {
			return process.env.REACT_APP_FILES_URL + '/api/' + file.lastVersion.url;
		}
	}
	return '';
};
