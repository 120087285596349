import './IndicatorBar.scss';
import React from 'react';

export interface IIndicatorBarProps {
	size?: 'small' | 'large';
	isCaloricType?: boolean;
	value: number;
	valueMax: number;
}

const IndicatorBar: React.FC<IIndicatorBarProps> = ({
	value = 0,
	valueMax = 0,
	isCaloricType = false,
	size = 'small'
}) => {
	const getFinalValue = (): number => {
		return Math.round((value / valueMax) * 100);
	};

	const getDeviation = (value: number): number => {
		return Math.abs(100 - value);
	};

	const getCaloricColor = (deviation: number): string => {
		if (deviation > 22) return 'red';
		if (deviation > 17) return 'orange';
		if (deviation > 12) return 'yellow';
		if (deviation > 8) return 'green-light';
		if (deviation > 5) return 'green';
		return 'green-dark';
	};

	const getNormalColor = (deviation: number): string => {
		if (deviation > 30) return 'red';
		if (deviation > 22) return 'orange';
		if (deviation > 17) return 'yellow';
		if (deviation > 12) return 'green-light';
		if (deviation > 7) return 'green';
		return 'green-dark';
	};

	const getAccentColor = (): string => {
		const deviation = getDeviation(getFinalValue());

		if (isCaloricType) {
			return getCaloricColor(deviation);
		} else {
			return getNormalColor(deviation);
		}
	};

	return (
		<div className={`IndicatorBar IndicatorBar--${size}`}>
			<div className="IndicatorBar__outer">
				<div
					className={`IndicatorBar__inner IndicatorBar__inner--${getAccentColor()}`}
					style={{ width: `${Math.min(getFinalValue(), 100)}%` }}
				/>
			</div>
		</div>
	);
};

export default IndicatorBar;
