import { Configuration as AuthConfig } from '../auth-client';
import { Configuration } from '../client';
import { Configuration as FileConfig } from '../files-client';
import { RootState } from './root-store';

export const GetApiConfig = (state: RootState) => {
	const token = state.auth.token || '';

	if (!(token == null || token == undefined)) {
		return new Configuration({
			basePath: process.env.REACT_APP_API_URL,
			apiKey: 'Bearer ' + token
		});
	} else {
		return new Configuration({
			basePath: process.env.REACT_APP_API_URL
		});
	}
};

export const GetAuthConfig = (state: RootState) => {
	const token = state.auth.token || '';
	if (!(token == null || token == undefined)) {
		return new AuthConfig({
			basePath: process.env.REACT_APP_AUTH_URL,
			apiKey: 'Bearer ' + token
		});
	} else {
		return new AuthConfig({
			basePath: process.env.REACT_APP_AUTH_URL
		});
	}
};

export const GetFileConfig = (state: RootState) => {
	const token = state.auth.token || '';
	if (!(token == null || token == undefined)) {
		return new FileConfig({
			basePath: process.env.REACT_APP_FILES_URL,
			apiKey: 'Bearer ' + token
		});
	} else {
		return new FileConfig({
			basePath: process.env.REACT_APP_FILES_URL
		});
	}
};
