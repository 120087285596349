import '../RestaurantDetails.scss';

import * as Yup from 'yup';

import { ActionButton } from '../../../../atoms/ActionButton/ActionButton';
import ActionLink from '../../../../atoms/action-link/action-link';
import { CheckBox } from '../../../../atoms/CheckBox/CheckBox';
import { FileMetadata } from '../../../../../files-client';
import { FileUploader } from '../../../../atoms/FileUploader/FileUploader';
import { HereAPI } from '../../../../../shared/HereApi';
import { Restaurant } from '../../../../../client';
import { TextArea } from '../../../../atoms/TextArea/TextArea';
import { TextBox } from '../../../../atoms/TextBox/TextBox';
import { getImagesSliderElement } from '../../../../organisms/Partnership/PartnershipInitial/PartnershipInitialForm/PartnershipInitialForm';
import { useHistory } from 'react-router-dom';

import { Field, Form, Formik, FormikHelpers } from 'formik';
import {
	IRestaurantDetailsForm,
	IRestaurantDetailsFormValues
} from './RestaurantDetailsFormInterfaces';
import React, { useEffect } from 'react';
import {
	addOrRemovePartnershipRestaurantImages,
	postRestaurantDetails
} from '../RestaurantDetailsEpics';
import { createUrlWithParams, routes } from '../../../../../business/router/path-definition';
import { documentsUrls, primaryOrangeColor } from '../../../../../shared/shared';
import {
	getPartnershipRestaurantImagesSelector,
	getPartnershipRestaurantSelector
} from '../../../../../business/Partnership/PartnershipSelectors';
import { useDispatch, useSelector } from 'react-redux';

export const RestaurantDetailsForm = (props: IRestaurantDetailsForm) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const restaurantImages = useSelector(getPartnershipRestaurantImagesSelector);
	const restaurant = useSelector(getPartnershipRestaurantSelector);

	const handleClickTerms = (): void => {
		history.push(
			createUrlWithParams(routes.static.document.path, {
				documentUrl: documentsUrls.tos
			})
		);
	};
	const onSubmit = async (values: IRestaurantDetailsFormValues, helpers: FormikHelpers<any>) => {
		let newRestaurant = values as Restaurant;

		let results = await HereAPI.geocode(
			newRestaurant.city +
				', ' +
				newRestaurant.postcode +
				', ' +
				newRestaurant.street +
				' ' +
				newRestaurant.homeNumber
		);
		if (results.length === 0) {
			results = await HereAPI.geocode(
				newRestaurant.city + ', ' + newRestaurant.postcode + ', ' + newRestaurant.street
			);
		}
		if (results.length === 0) {
			results = await HereAPI.geocode(newRestaurant.city + ', ' + newRestaurant.postcode);
		}
		if (results.length === 0) {
			results = await HereAPI.geocode(newRestaurant.city!);
		}
		if (results.length === 0) {
			results = await HereAPI.geocode(newRestaurant.postcode!);
		}
		if (results.length > 0) {
			newRestaurant.newLat = +results[0].y;
			newRestaurant.newLng = +results[0].x;
		}
		dispatch(postRestaurantDetails(newRestaurant));
		setTimeout(() => helpers.setSubmitting(false), 2000);
	};
	useEffect(() => {
		if (!props.dontRewriteToNewProperties) {
			restaurant.newImagesFileCatalogId = restaurant.imagesFileCatalogId;
			restaurant.newLogoUrl = restaurant.logoUrl ?? '';
			restaurant.newAdditionalInfo = restaurant.additionalInfo ?? '';
			restaurant.newApartmentNumber = restaurant.apartmentNumber ?? '';
			restaurant.newCity = restaurant.city ?? '';
			restaurant.newDescription = restaurant.description ?? '';
			restaurant.newDescriptionTitle = restaurant.descriptionTitle ?? '';
			restaurant.newHomeNumber = restaurant.homeNumber ?? '';
			restaurant.newName = restaurant.name ?? '';
			restaurant.newOpenHours = restaurant.openHours ?? '';
			restaurant.newPostcode = restaurant.postcode ?? '';
			restaurant.newRepresentativeApartmentNumber =
				restaurant.representativeApartmentNumber ?? '';
			restaurant.newRepresentativeCity = restaurant.representativeCity ?? '';
			restaurant.newRepresentativeHomeNumber = restaurant.representativeHomeNumber ?? '';
			restaurant.newRepresentativeName = restaurant.representativeName ?? '';
			restaurant.newRepresentativePostcode = restaurant.representativePostcode ?? '';
			restaurant.newRepresentativeStreet = restaurant.representativeStreet ?? '';
			restaurant.newRepresentativeTelephone = restaurant.representativeTelephone ?? '';
			restaurant.newShortDescription = restaurant.shortDescription ?? '';
			restaurant.newStreet = restaurant.street ?? '';
		}
	}, []);

	const imagesChangeHandler = (
		e: React.ChangeEvent<HTMLInputElement>,
		restaurant: Restaurant
	) => {
		const fileList = e.target.files;
		if (fileList) {
			const files = Array.from(fileList);
			dispatch(addOrRemovePartnershipRestaurantImages(restaurant, files));
		}
	};

	const removeImage = (fileName: string, restaurant: Restaurant) => {
		dispatch(addOrRemovePartnershipRestaurantImages(restaurant, undefined, fileName));
	};
	return (
		<Formik
			enableReinitialize
			initialValues={{
				acceptedTerms: false,
				...restaurant
			}}
			validationSchema={Yup.object<
				Partial<Record<keyof IRestaurantDetailsFormValues, Yup.AnySchema>>
			>({
				newName: Yup.string().min(3, 'Co najmniej 3 znaki').required('Wymagane'),
				newCity: Yup.string().min(3, 'Co najmniej 3 znaki').required('Wymagane'),
				newStreet: Yup.string().min(3, 'Co najmniej 3 znaki').required('Wymagane'),
				newHomeNumber: Yup.string().required('Wymagane'),
				newPostcode: Yup.string()
					.matches(new RegExp('[0-9]{2}-[0-9]{3}'), 'Niepoprawny kod pocztowy (00-000)')
					.min(5, 'Co najmniej 6 znaków')
					.required('Wymagane'),
				newOpenHours: Yup.string().min(3, 'Co najmniej 3 znaki').required('Wymagane'),
				newDescriptionTitle: Yup.string()
					.min(3, 'Co najmniej 3 znaki')
					.required('Wymagane'),
				newShortDescription: Yup.string()
					.min(3, 'Co najmniej 3 znaki')
					.required('Wymagane'),
				newDescription: Yup.string().min(3, 'Co najmniej 3 znaki').required('Wymagane'),
				newRepresentativeCity: Yup.string()
					.min(3, 'Co najmniej 3 znaki')
					.required('Wymagane'),
				newRepresentativeStreet: Yup.string()
					.min(3, 'Co najmniej 3 znaki')
					.required('Wymagane'),
				newRepresentativeHomeNumber: Yup.string().required('Wymagane'),
				newRepresentativePostcode: Yup.string()
					.matches(new RegExp('[0-9]{2}-[0-9]{3}'), 'Niepoprawny kod pocztowy (00-000)')
					.min(5, 'Co najmniej 6 znaków')
					.required('Wymagane'),
				newRepresentativeName: Yup.string()
					.min(3, 'Co najmniej 3 znaki')
					.required('Wymagane'),
				newRepresentativeTelephone: Yup.string()
					.min(9, 'Co najmniej 9 znaków')
					.required('Wymagane'),
				acceptedTerms: Yup.boolean().oneOf([true], 'Wymagane')
			})}
			onSubmit={onSubmit}
		>
			{(formik) => (
				<Form>
					<fieldset
						className="RestaurantDetails_form_content_row"
						disabled={props.disabled}
					>
						{!props.disabled && (
							<FileUploader
								multiple
								onChange={(e) =>
									imagesChangeHandler(e, formik.values as Restaurant)
								}
								text="Dodaj zdjęcia restauracji"
								accept="image/*"
							/>
						)}
						{getImagesSliderElement(
							formik,
							restaurantImages,
							(image: FileMetadata) =>
								removeImage(image.fileName!, formik.values as Restaurant),
							'newLogoUrl',
							props.disabled
						)}
						<Field
							name="newName"
							label="Nazwa restauracji"
							placeholder="Jak nazywa się Twoja restauracja?"
							className="RestaurantDetails_form_content_row_item"
							errorString={formik.touched.newName && formik.errors.newName}
							as={TextBox}
						/>

						<Field
							name="newStreet"
							label="Ulica"
							placeholder="np. Kwiatowa"
							className="RestaurantDetails_form_content_row_item"
							errorString={formik.touched.newStreet && formik.errors.newStreet}
							as={TextBox}
						/>
						<Field
							maxLength={10}
							name="newHomeNumber"
							label="Numer posesji"
							placeholder="np. 15"
							className="RestaurantDetails_form_content_row_item"
							errorString={
								formik.touched.newHomeNumber && formik.errors.newHomeNumber
							}
							as={TextBox}
						/>
						<Field
							maxLength={10}
							name="newApartmentNumber"
							label="Numer lokalu"
							placeholder="np. 1"
							className="RestaurantDetails_form_content_row_item"
							errorString={
								formik.touched.newApartmentNumber &&
								formik.errors.newApartmentNumber
							}
							as={TextBox}
						/>

						<Field
							name="newCity"
							label="Miasto"
							placeholder="np. Warszawa"
							className="RestaurantDetails_form_content_row_item"
							errorString={formik.touched.newCity && formik.errors.newCity}
							as={TextBox}
						/>

						<Field
							maxLength={6}
							mask="99-999"
							name="newPostcode"
							label="Kod pocztowy"
							placeholder="np. 91-371"
							className="RestaurantDetails_form_content_row_item"
							errorString={formik.touched.newPostcode && formik.errors.newPostcode}
							as={TextBox}
						/>

						<Field
							name="newOpenHours"
							label="Godziny otwarcia"
							placeholder="np. pon-pt 8-16"
							className="RestaurantDetails_form_content_row_item"
							errorString={formik.touched.newOpenHours && formik.errors.newOpenHours}
							as={TextBox}
						/>

						<Field
							name="newDescriptionTitle"
							label="Podtytuł"
							placeholder="np. Restauracja tajska"
							className="RestaurantDetails_form_content_row_item"
							errorString={
								formik.touched.newDescriptionTitle &&
								formik.errors.newDescriptionTitle
							}
							as={TextBox}
						/>
						<Field
							name="newShortDescription"
							label="Krótki opis restauracji"
							placeholder="np. Najlepsze burgery w Warszawie!"
							className="RestaurantDetails_form_content_row_item RestaurantDetails_form_content_row_item_full-width"
							errorString={
								formik.touched.newShortDescription &&
								formik.errors.newShortDescription
							}
							as={TextBox}
						/>
						<Field
							name="newDescription"
							label="Opis restauracji"
							placeholder="Dłuższy opis"
							className="RestaurantDetails_form_content_row_item RestaurantDetails_form_content_row_item_full-width"
							errorString={
								formik.touched.newDescription && formik.errors.newDescription
							}
							as={TextArea}
						/>

						<Field
							name="newRepresentativeName"
							label="Imię i nazwisko reprezentata"
							placeholder="np. Dyrektor Jan Kowalski"
							className="RestaurantDetails_form_content_row_item"
							errorString={
								formik.touched.newRepresentativeName &&
								formik.errors.newRepresentativeName
							}
							as={TextBox}
						/>

						<Field
							name="newRepresentativeTelephone"
							label="Numer telefonu do reprezentata"
							placeholder="np. 111-222-333"
							className="RestaurantDetails_form_content_row_item"
							errorString={
								formik.touched.newRepresentativeTelephone &&
								formik.errors.newRepresentativeTelephone
							}
							as={TextBox}
						/>

						<Field
							name="newRepresentativeStreet"
							label="Ulica (reprezentant)"
							placeholder="np. Kwiatowa"
							className="RestaurantDetails_form_content_row_item"
							errorString={
								formik.touched.newRepresentativeStreet &&
								formik.errors.newRepresentativeStreet
							}
							as={TextBox}
						/>
						<Field
							maxLength={10}
							name="newRepresentativeHomeNumber"
							label="Numer posesji (reprezentant)"
							placeholder="np. 15"
							className="RestaurantDetails_form_content_row_item"
							errorString={
								formik.touched.newRepresentativeHomeNumber &&
								formik.errors.newRepresentativeHomeNumber
							}
							as={TextBox}
						/>
						<Field
							maxLength={10}
							name="newRepresentativeApartmentNumber"
							label="Numer lokalu (reprezentant)"
							placeholder="np. 1"
							className="RestaurantDetails_form_content_row_item"
							errorString={
								formik.touched.newRepresentativeApartmentNumber &&
								formik.errors.newRepresentativeApartmentNumber
							}
							as={TextBox}
						/>

						<Field
							name="newRepresentativeCity"
							label="Miasto (reprezentant)"
							placeholder="np. Warszawa"
							className="RestaurantDetails_form_content_row_item"
							errorString={
								formik.touched.newRepresentativeCity &&
								formik.errors.newRepresentativeCity
							}
							as={TextBox}
						/>

						<Field
							maxLength={6}
							mask="99-999"
							name="newRepresentativePostcode"
							label="Kod pocztowy (reprezentant)"
							placeholder="np. 91-371"
							className="RestaurantDetails_form_content_row_item"
							errorString={
								formik.touched.newRepresentativePostcode &&
								formik.errors.newRepresentativePostcode
							}
							as={TextBox}
						/>

						<Field
							name="newAdditionalInfo"
							label="Inne ważne informacje"
							placeholder="Wpisz treść swojej wiadomości"
							className="RestaurantDetails_form_content_row_item RestaurantDetails_form_content_row_item_full-width"
							errorString={
								formik.touched.newAdditionalInfo && formik.errors.newAdditionalInfo
							}
							as={TextArea}
						/>
						{!props.disabled && (
							<>
								<div className="RestaurantDetails_form_terms">
									<Field
										name="acceptedTerms"
										type="checkbox"
										as={CheckBox}
										errorString={
											formik.touched.acceptedTerms &&
											formik.errors.acceptedTerms
										}
										displayName={
											<span>
												Podane dane osobowe będą przetwarzane w celu
												kontaktu i obsługi Państwa zgłoszenia. Więcej
												informacji o przetwarzaniu danych osobowych:
												<ActionLink
													textColor={primaryOrangeColor}
													text="Polityka Prywatności"
													onClick={handleClickTerms}
												/>
												.
											</span>
										}
										disableDisplayNameChangeStyle
									/>
								</div>

								<ActionButton
									disabled={!formik.isValid}
									errorString={
										formik.isValid ? undefined : 'Sprawdź błędy w formularzu'
									}
									type="submit"
									text="Wyślij zgłoszenie zmiany danych"
									className="RestaurantDetails_form_action-button"
								/>
							</>
						)}
					</fieldset>
				</Form>
			)}
		</Formik>
	);
};
