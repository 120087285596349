import './DropdownMenu.scss';

import { useOutsideClick } from '../../../hooks/use-outside-click';

import React, { useRef, useState } from 'react';

export interface IDropdownMenuItem {
	label?: string;
	value?: string;
	element?: JSX.Element;
	onClick?(args?: any): void;
}

interface IDropdownMenuProps {
	triggerItem: JSX.Element;
	menuItems: IDropdownMenuItem[];
	onClick?: () => void;
	position?: 'bottomRight' | 'bottomLeft' | 'upRight' | 'upLeft';
}

export const DropdownMenu = (props: IDropdownMenuProps) => {
	const [showOptions, setShowOptions] = useState(false);
	const wrapperRef: any = useRef();

	useOutsideClick(wrapperRef, () => {
		setShowOptions(false);
	});
	let classWhenVisible: string;
	let classWhenHide: string;
	switch (props.position) {
		case 'upLeft':
			classWhenVisible =
				'DropdownMenu__menu--visible DropdownMenu__menu--bottom100 DropdownMenu__menu--right0';
			classWhenHide = 'DropdownMenu__menu--bottomminus100 DropdownMenu__menu--right0';
			break;
		case 'upRight':
			classWhenVisible = 'DropdownMenu__menu--visible DropdownMenu__menu--bottom100';
			classWhenHide = 'DropdownMenu__menu--bottomminus100';
			break;
		case 'bottomLeft':
			classWhenVisible = 'DropdownMenu__menu--visible DropdownMenu__menu--right0';
			classWhenHide = 'DropdownMenu__menu--right0';
			break;
		case 'bottomRight':
			classWhenVisible = 'DropdownMenu__menu--visible';
			classWhenHide = '';
			break;
		default:
			classWhenVisible = 'DropdownMenu__menu--visible';
			classWhenHide = '';
	}
	const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		e.preventDefault();
		if (props.onClick) {
			props.onClick();
		}
		setShowOptions(!showOptions);
	};
	return (
		<div className="DropdownMenu" ref={wrapperRef}>
			<div className="DropdownMenu__trigger" onClick={handleClick}>
				{props.triggerItem}
			</div>
			<ul className={`DropdownMenu__menu ${showOptions ? classWhenVisible : classWhenHide}`}>
				{props.menuItems.map((menuItem: IDropdownMenuItem) => {
					if (menuItem.element) {
						return menuItem.element;
					}
					const handleClick = (evt: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
						setShowOptions(false);
						if (menuItem.onClick) {
							menuItem.onClick(evt);
						}
					};
					return (
						<li onClick={handleClick} key={menuItem.label}>
							{menuItem.label}
						</li>
					);
				})}
			</ul>
		</div>
	);
};
