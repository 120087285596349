import { IOrderMenuView } from './interfaces';
import { IStepIconProps } from '../../atoms/Stepper/StepIcon/StepIcon';

export const elementIds: IOrderMenuView.IdsOfElements = {
	dietAndPackageTypeCard: 'dietAndPackageTypeCard',
	loginCard: 'loginCard',
	paymentMethodCard: 'paymentMethodCard'
};

const labels: IOrderMenuView.Labels = {
	dietType: 'Typ Diety',
	login: 'Logowanie',
	payment: 'Płatność'
};

export const formatNumber = (numb: number | string) => {
	const formattedNumb = Number(numb);
	return Intl.NumberFormat('pl-PL').format(formattedNumb);
};

export const orderStepList = [
	{
		id: labels.dietType,
		label: labels.dietType,
		navigationElementId: elementIds.dietAndPackageTypeCard,
		isActive: true
	} as IStepIconProps,
	{
		id: labels.login,
		label: labels.login,
		navigationElementId: elementIds.loginCard,
		isActive: false
	} as IStepIconProps,
	{
		id: labels.payment,
		label: labels.payment,
		navigationElementId: elementIds.paymentMethodCard,
		isActive: false
	} as IStepIconProps
];

export const createOrderDietStepsList = (
	isLogged: boolean,
	stepList: IStepIconProps[]
): IStepIconProps[] =>
	stepList
		.filter((item) => {
			if (isLogged) return item.label !== labels.login;
			else return item;
		})
		.map((item, index) => ({ ...item, nr: index + 1 }));

export const updateStep = (
	orderList: IStepIconProps[],
	elementId: IOrderMenuView.elementId,
	isActive: boolean
) =>
	orderList.map((step) => {
		if (step.navigationElementId === elementId) {
			return { ...step, isActive };
		}
		return step;
	});
