import React from 'react';

interface ITrenningIconComponentProps {
	onClick?(event: React.SyntheticEvent<any> | undefined): any;
	className?: string;
}

class TrenningIconComponent extends React.Component<ITrenningIconComponentProps> {
	constructor(props: ITrenningIconComponentProps) {
		super(props);
	}

	public render(): JSX.Element {
		return (
			<React.Fragment>
				<div onClick={this.props.onClick} className={this.props.className}>
					<svg
						width="16"
						height="16"
						viewBox="0 0 16 16"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<circle cx="8" cy="8" r="8" fill="#FD6851" />
						<path
							d="M11.237 4.278V5.785H9.191V12H7.31V5.785H5.264V4.278H11.237Z"
							fill="white"
						/>
					</svg>
				</div>
			</React.Fragment>
		);
	}
}
export const TrenningIcon = TrenningIconComponent;
