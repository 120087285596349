import { GetApiConfig } from '../../../business/api-client-config';
import { RootState } from '../../../business/root-store';
import { ThunkAction } from 'redux-thunk';
import { getNumberOfServingsPerDay } from '../../../business/diet/diet-preferences-selector';

import { DietApi, FoodApi } from '../../../client';
import {
	nutritionFactDefinitionsFetched,
	productGroupsFetched,
	userMealTypesFetched
} from './staticDataActions';

export const GetUserMealTypes =
	(): ThunkAction<any, RootState, any, any> => (dispatch, getState) => {
		const state = getState();
		const api = new DietApi(GetApiConfig(state));
		const numberOfServings = getNumberOfServingsPerDay(state);
		api.getMealTypesByNumberOfServings(numberOfServings ?? 0).then((x) =>
			dispatch(userMealTypesFetched(x.data.mealTypes ?? []))
		);
	};

export const GetNutritionFactDefinitions =
	(): ThunkAction<any, RootState, any, any> => (dispatch, getState) => {
		const state = getState();
		const api = new FoodApi(GetApiConfig(state));
		api.getNutritionFactDefinitions().then((x) =>
			dispatch(nutritionFactDefinitionsFetched(x.data.nutritionFactDefinitions ?? []))
		);
	};

export const GetProductGroups =
	(): ThunkAction<any, RootState, any, any> => (dispatch, getState) => {
		const state = getState();
		const api = new FoodApi(GetApiConfig(state));
		api.getProductGroups().then((x) =>
			dispatch(productGroupsFetched(x.data.productGroups ?? []))
		);
	};
