import './meal.scss';

import { AddToastPayload } from 'react-redux-toastr';
import { DropdownMenu } from '../../../atoms/DropdownMenu/DropdownMenu';
import { FavouriteIcon } from '../../../../shared/assets/favourite-icon';
import { ICurrentMeals } from './meal-interfaces';
import { IDpdmWithImage } from '../meal-card-interfaces';
import { MealTypeEnum } from '../../../../client';
import { MoreIcon } from '../../../../shared/assets/more-icon';
import React from 'react';
import { getFriendlyFractionsString } from '../../../../shared/helpers';
import { routes } from '../../../../business/router/path-definition';

interface IMealComponentProps {
	mealWithImage: IDpdmWithImage;
	isFavouriteVisible: boolean;
	customerId?: number | null;
	currentMeals: ICurrentMeals;
	onFavouriteClicked(mealId: number): void;
	onCopyClicked: (value: boolean) => void;
	onEditMealModalClicked(value: boolean): void;
	deleteMealInDietPlanDayMeal(mealId: number, mealType: MealTypeEnum): void;
	setCurrentMeals: (currentMeals: ICurrentMeals) => void;
	navigateTo(path: string, state?: any): void;
	toast: (message: AddToastPayload) => void;
}

interface IMealComponentState {
	isDropdownMealVisible: boolean;
}

export class MealComponent extends React.Component<IMealComponentProps, IMealComponentState> {
	constructor(props: IMealComponentProps, state: IMealComponentState) {
		super(props, state);
		this.state = this.getInitialState();
	}

	getMenuItems() {
		const menuItems = [
			{
				label: 'Zobacz przepis',
				onClick: this.handleMealClicked
			},
			{
				label: 'Zaproponuj inny posiłek'
			},
			{
				label: 'Kopiuj posiłek',
				onClick: this.handleCopy
			},
			{
				label: 'Edytuj posiłek',
				onClick: this.handleEdit
			}
		];

		if (this.props.isFavouriteVisible) {
			menuItems.push({
				label: 'Usuń posiłek',
				onClick: this.handleRemove
			});
		}

		if (
			!this.props.mealWithImage.dietPlanDayMeal.meal?.isCustomMeal ||
			(this.props.mealWithImage.dietPlanDayMeal.meal?.isCustomMeal &&
				this.props.mealWithImage.dietPlanDayMeal.meal?.customerId !== this.props.customerId)
		) {
			menuItems.push({
				label: 'Utwórz własny na podstawie tego posiłku',
				onClick: this.handleCreateFromExisting
			});
		}

		return menuItems;
	}

	public render(): JSX.Element {
		const dietPlanDayMeal = this.props.mealWithImage.dietPlanDayMeal;
		return (
			<div className="meal">
				{this.props.isFavouriteVisible && (
					<div className="meal__favourite-icon-wrapper">
						<FavouriteIcon
							id="favouriteIcon"
							isLiked={dietPlanDayMeal.isFavourite!}
							onClick={this.handleFavouriteClicked}
						/>
					</div>
				)}
				<div className="meal__content">
					<div className="meal__top-row">
						<div className="meal__headline" onClick={this.handleMealClicked.bind(this)}>
							{dietPlanDayMeal.meal?.name}
						</div>
						<div className="meal__calories-wrapper style-desktop">
							{dietPlanDayMeal.energy} kcal
						</div>
						<div className="meal__refresh-dropdown style-desktop">
							<DropdownMenu
								triggerItem={<MoreIcon />}
								menuItems={this.getMenuItems()}
							/>
						</div>
						<div className="meal__refresh-dropdown style-mobile">
							<DropdownMenu
								position={
									this.props.mealWithImage.dietPlanDayMeal.meal!.name!.length > 10
										? 'upLeft'
										: 'upRight'
								}
								triggerItem={<MoreIcon />}
								menuItems={this.getMenuItems()}
							/>
						</div>
					</div>
					<div>
						<span className="meal__details">{dietPlanDayMeal.qtyInGrams}g</span>
						<span className="meal__details">
							{getFriendlyFractionsString(dietPlanDayMeal.qtyInHomeMeasurement)}{' '}
							{dietPlanDayMeal.qtyInText}
						</span>
						<span className="meal__details style-mobile">
							{dietPlanDayMeal.energy} kcal
						</span>
					</div>
				</div>
			</div>
		);
	}

	private handleMealClicked = (e: any): void => {
		e.preventDefault();
		this.props.setCurrentMeals({
			...this.props.currentMeals,
			mealType: this.props.mealWithImage.dietPlanDayMeal.mealType!,
			dpdmWithImage: this.props.mealWithImage
		});
		this.props.navigateTo(routes.customer.meal.details.path);
	};

	private handleFavouriteClicked = (): void => {
		this.props.onFavouriteClicked(this.props.mealWithImage.dietPlanDayMeal.mealId!);
	};
	private handleClickDropdown = (): void => {
		this.setState({ isDropdownMealVisible: !this.state.isDropdownMealVisible });
	};

	private handleRemove = (e: any): void => {
		e.preventDefault();
		this.props.deleteMealInDietPlanDayMeal(
			this.props.mealWithImage.dietPlanDayMeal.mealId!,
			this.props.mealWithImage.dietPlanDayMeal.mealType!
		);
	};

	private handleEdit = (e: any): void => {
		e.preventDefault();
		const meal = this.props.mealWithImage.dietPlanDayMeal.meal!;
		this.props.setCurrentMeals({
			...this.props.currentMeals,
			meals: [
				{
					...meal,
					mealFoodProducts: meal.mealServings
				}
			],
			mealType: this.props.mealWithImage.dietPlanDayMeal.mealType!
		});
		this.props.onEditMealModalClicked(true);
	};

	private handleCreateFromExisting = (e: any): void => {
		const meal = this.props.mealWithImage.dietPlanDayMeal.meal;
		this.props.setCurrentMeals({
			...this.props.currentMeals,
			meals: [
				{
					...meal,
					id: undefined,
					customerId: undefined,
					isCustomMeal: true,
					mealFoodProducts: this.props.mealWithImage.dietPlanDayMeal.mealServings
				}
			], //todo name z customerName
			mealType: this.props.mealWithImage.dietPlanDayMeal.mealType!
		});
		this.props.onEditMealModalClicked(true);
	};

	private handleCopy = (e: any): void => {
		e.preventDefault();
		this.props.setCurrentMeals({
			...this.props.currentMeals,
			mealType: this.props.mealWithImage.dietPlanDayMeal.mealType!,
			meals: [this.props.mealWithImage.dietPlanDayMeal.meal!]
		});
		this.props.onCopyClicked(true);
	};

	private getInitialState = (): IMealComponentState => {
		return {
			isDropdownMealVisible: false,
			showCopyMealModal: false
		} as IMealComponentState;
	};
}
