import './meal-card-header.scss';

import ActionLink from '../../../atoms/action-link/action-link';
import { CopyIcon } from '../../../../shared/assets/copy-icon';
import { FavouriteIcon } from '../../../../shared/assets/favourite-icon';
import { INutritionFactsSummary } from '../../../../shared/helpers';
import { NutritionFactSummary } from '../../nutrition-fact-summary/nutrition-fact-summary';
import React from 'react';
import { primaryOrangeColor } from '../../../../shared/shared';

export interface IMealHeaderProps {
	header: string;
	isFavouriteMeal: boolean;
	showFavourite: boolean;
	onCopyClicked(): void;
	onRefreshClicked(): void;
	onFavouriteClicked(): void;
	summary: INutritionFactsSummary;
}

export class MealCardHeaderComponent extends React.Component<IMealHeaderProps> {
	public render(): JSX.Element {
		return (
			<div className="meal-header">
				<div className="meal-header__content-left">
					<img
						onClick={this.props.onRefreshClicked}
						src="/img/refresh.svg"
						alt="Refresh icon"
					/>
					<h3 className="meal-header__title">{this.props.header}</h3>
				</div>
				<div className="meal-header__content-right">
					<ActionLink
						text="Kopiuj"
						textColor={primaryOrangeColor}
						onClick={this.props.onCopyClicked}
					>
						<CopyIcon />
					</ActionLink>
					<div className="style-desktop">
						<NutritionFactSummary
							summary={this.props.summary}
							className={
								this.props.showFavourite
									? 'nutrition-fact-summary--with-favourite'
									: ''
							}
						/>
					</div>
					{this.props.showFavourite && (
						<div className="meal-header__favourite-wrapper">
							<FavouriteIcon
								id="favouriteIcon"
								isLiked={this.props.isFavouriteMeal}
								onClick={this.props.onFavouriteClicked}
							/>
						</div>
					)}
				</div>
			</div>
		);
	}
}
