import './CopyDay.scss';

import { ActionButton } from '../../../atoms/ActionButton/ActionButton';
import { AddToastPayload } from 'react-redux-toastr';
import { DatePicker } from '../../../atoms/datePickers/DatePicker/DatePicker';
import { IKeyValuePair } from '../../../../shared/interfaces/IKeyValuePair';
import React from 'react';
import { TextBox } from '../../../atoms/TextBox/TextBox';
import moment from 'moment';

interface ICopyDayComponentProps {
	maxDateUtc: Date;
	minDateUtc: Date;
	currentDietPlanDayUtc: Date;
	copyDay(fromDay: Date, toDay: Date): void;
	onCloseClicked(path: string, state?: any): void;
	toast: (message: AddToastPayload) => void;
}

type ICopyDayComponentState = {
	fromDate: Date;
	toDate: Date;
	showCalendarFrom: boolean;
	showCalendarTo: boolean;
};

type IMealSelect = IKeyValuePair<string, string>;

export class CopyDayComponent extends React.Component<
	ICopyDayComponentProps,
	ICopyDayComponentState
> {
	constructor(props: ICopyDayComponentProps, state: ICopyDayComponentState) {
		super(props, state);

		this.state = this.getInitialState();
	}

	public render(): JSX.Element {
		return (
			<div className="page exchangeMeal_main">
				<div className="exchangeMeal_header">
					<p className="header">Gdzie chcesz skopiować dzień?</p>
				</div>

				<div className="copyDay_row">
					<label className="textBox_label">Kopiuj dzień</label>
					<div style={{ position: 'relative' }}>
						<TextBox
							name="textboxFromDate"
							onClick={this.handleCalendarFromClick}
							value={moment(this.state.fromDate)
								.format('YYYY-MM-DD')
								.toLocaleString()}
						/>
						<DatePicker
							disabledDatesUtc={[this.state.toDate]}
							minDateUtc={this.props.minDateUtc}
							maxDateUtc={this.props.maxDateUtc}
							handleChange={this.handleChangeFrom}
							dateUtc={this.state.fromDate}
							showCalendar={this.state.showCalendarFrom}
							onShowStateChange={this.handleClickOutsideDatePickerFrom}
						/>
					</div>
				</div>

				<div className="copyDay_row">
					<label className="textBox_label">do dnia</label>
					<div style={{ position: 'relative' }}>
						<TextBox
							name="textboxToDate"
							onClick={this.handleCalendarToClick}
							value={moment(this.state.toDate).format('YYYY-MM-DD').toLocaleString()}
						/>
						<DatePicker
							disabledDatesUtc={[this.state.fromDate]}
							minDateUtc={this.props.minDateUtc}
							maxDateUtc={this.props.maxDateUtc}
							handleChange={this.handleChangeTo}
							dateUtc={this.state.toDate}
							showCalendar={this.state.showCalendarTo}
							onShowStateChange={this.handleClickOutsideDatePickerTo}
						/>
					</div>
				</div>

				<ActionButton
					className="copyDay_action-button"
					text="Kopiuj dzień"
					onClick={this.handleCopyClicked}
				/>
			</div>
		);
	}
	private handleClickOutsideDatePickerFrom = () => {
		this.setState({ ...this.state, showCalendarFrom: false });
	};

	private handleClickOutsideDatePickerTo = () => {
		this.setState({ ...this.state, showCalendarTo: false });
	};

	private handleCalendarFromClick = () => {
		this.setState({ ...this.state, showCalendarFrom: !this.state.showCalendarFrom });
	};

	private handleChangeFrom = (date: Date) => {
		this.setState({ ...this.state, fromDate: date, showCalendarFrom: false });
	};

	private handleCalendarToClick = () => {
		this.setState({ ...this.state, showCalendarTo: !this.state.showCalendarTo });
	};

	private handleChangeTo = (date: Date) => {
		this.setState({ ...this.state, toDate: date, showCalendarTo: false });
	};

	private handleCopyClicked = (): void => {
		this.props.copyDay(this.state.fromDate, this.state.toDate);
		document.dispatchEvent(new CustomEvent('closeModal', {}));
	};

	private getInitialState = (): ICopyDayComponentState => {
		return {
			showCalendarFrom: false,
			showCalendarTo: false,
			fromDate: moment(new Date(this.props.currentDietPlanDayUtc)).toDate(),
			toDate: moment(new Date(this.props.currentDietPlanDayUtc)).add(1, 'day').toDate()
		} as ICopyDayComponentState;
	};
}
