import './ChooseDietPlan.scss';

import { ActionButton } from '../../atoms/ActionButton/ActionButton';
import { DietPlanDefinition } from '../../../client';
import { DietPlanPrice } from '../staticData/staticDataSelectors';
import { DietPlanPriceAtom } from '../../atoms/DietPlanPrice/DietPlanPriceComponent';

interface IChooseDietPlan {
	onDietPlanChoice(): void;
	isTrial?: boolean;
	isDayPlanAvailable: boolean;
	availableDietPlans?: DietPlanDefinition[];
	dietPlanPrice: DietPlanPrice;
}

export const ChooseDietPlan: React.FC<IChooseDietPlan> = (props) => {
	if (props.isTrial || !props.isDayPlanAvailable) {
		return (
			<div id="diet-plan-container">
				<div className="leftSide">
					<div className="order-menu">
						Zamów jadłospis <br />
					</div>
					<ul>
						<li>Lista zakupów na każdy dzień</li>
						<li>Możliwość zmiany składników i dań</li>
						<li>Opinie na temat dań</li>
					</ul>
				</div>
				<div className="rightSide">
					<DietPlanPriceAtom dietPlanPrice={props.dietPlanPrice} />
					<ActionButton text="Zamów jadłospis" onClick={props.onDietPlanChoice} />
				</div>
			</div>
		);
	}
	return null;
};
