import './comments-renderer-component.scss';

import { ActionButton } from '../../../../../atoms/ActionButton/ActionButton';
import { CloseIcon } from '../../../../../../shared/assets/close-icon';
import { CommentSectionRendererComponent } from '../CommentSectionRendererComponent';
import { FileUploader } from '../../../../../atoms/FileUploader/FileUploader';
import { IDpdmWithImage } from '../../../meal-card-interfaces';
import { TextArea } from '../../../../../atoms/TextArea/TextArea';
import { dietActions } from '../../../../../../business/diet/diet-action';
import { useDispatch } from 'react-redux';
import { ChangeEvent, useState } from 'react';

import { FileInfo, FileTypeEnum } from '../../../../../../files-client';

export interface ICommentsRendererComponentProps {
	mealWithImage: IDpdmWithImage;
	commentFiles: FileInfo[];
	comment: string;
	handleCommentChanged: React.Dispatch<React.SetStateAction<string>>;
	onFileAdded: (file: File) => void;
	handleCommentAdded: () => void;
}

export const CommentsRendererComponent = (props: ICommentsRendererComponentProps): JSX.Element => {
	const [showCommentAddedInfo, setShowCommentAddedInfo] = useState(false);
	const dispatch = useDispatch();
	const hasComments = props.mealWithImage.dietPlanDayMeal.meal!.mealComments!.length > 0;
	const file = props.commentFiles.find(
		(z: FileInfo) => z.fileType == FileTypeEnum.ThumbnailImage
	)?.url;

	const removePicture = () => {
		dispatch(dietActions.clearCommentsImages());
	};

	const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		props.onFileAdded(e.target.files![0]);
	};

	return (
		<div id="comments-section">
			<form>
				<h3>Skomentuj</h3>
				<TextArea
					rows={7}
					value={props.comment}
					onChange={(value: ChangeEvent<HTMLTextAreaElement>) =>
						props.handleCommentChanged(value.target.value)
					}
					placeholder="Powiedz co myślisz"
				/>
				<div className="comments-section__buttons">
					<FileUploader onChange={onFileChange} text="Dodaj zdjęcie" accept="image/*" />
					<ActionButton
						disabled={props.comment.length < 2}
						type="button"
						text="Dodaj komentarz"
						className="add-comment"
						onClick={() => {
							props.handleCommentAdded();
							setShowCommentAddedInfo(true);
						}}
					/>
				</div>
				{file && (
					<>
						<CloseIcon
							onClick={removePicture}
							className="comments-section__closeIcon"
						/>
						<img
							className="comments-section__attachment"
							src={`${process.env.REACT_APP_FILES_URL}/api/${file}`}
						/>
					</>
				)}
			</form>
			{showCommentAddedInfo && (
				<div className="comments-section__comment-added-info">
					Twój komentarz został dodany.
				</div>
			)}
			{hasComments && <CommentSectionRendererComponent mealWithImage={props.mealWithImage} />}
		</div>
	);
};
