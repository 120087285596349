import './ExclusionOfFoodProduct03StepForm.scss';

import { ChipsItem } from '../../../atoms/chips/chips-item';
import { DietExclusionRule } from '../../../../client';
import { IWizzardStepProps } from '../UserInterviewInterfaces';
import { RadioSelect } from '../../../atoms/RadioSelect/RadioSelect';
import { WizardCard } from '../../WizardCard/WizardCard';
import { loadProductAndRefs } from '../../../../business/diet/diet-action';

import { Field, FieldArray } from 'formik';
import { ISearchItem, SearchBox } from '../../../atoms/search-box/search-box';
import React, { useEffect } from 'react';
import {
	clientJourneyAddExclusionsForDietPlan,
	clientJourneyDeleteExclusionsForDietPlan
} from '../../../../business/diet/diet-preferences-action';
import {
	getProductRefsSelector,
	hasProductRefsLoaded
} from '../../../../business/diet/diet-selector';
import { useDispatch, useSelector } from 'react-redux';

export const ExclusionOfFoodProduct03StepForm: React.FC<IWizzardStepProps> = ({
	formik,
	mode,
	scrollToStep,
	...props
}) => {
	const dispatch = useDispatch();

	const allProductsRefs = useSelector(getProductRefsSelector).map((x) => {
		return { display: x.name, value: x, id: x.id } as ISearchItem;
	});
	const hasProductListsLoaded = useSelector(hasProductRefsLoaded);
	useEffect(() => {
		if (!hasProductListsLoaded) {
			dispatch(loadProductAndRefs());
		}
	}, []);
	const [text, setText] = React.useState('');
	const onSearchChanged = (text: string) => {
		setText(text);
	};

	const handleScroll = () => {
		scrollToStep(3);
	};
	return (
		<WizardCard id="step03" header="3. Wykluczenia" onClick={handleScroll}>
			<h4 className="UserInterview__subheader UserInterview__subheader--first-in-row">
				Czy chcesz wykluczyć z jadłospisu jakieś produkty?
			</h4>
			<div className="UserInterview_row">
				<Field
					label="Tak"
					name="step3.haveExclusions"
					value="Yes"
					checked={formik.values.step3.haveExclusions === 'Yes'}
					as={RadioSelect}
				/>
				<Field
					label="Nie"
					name="step3.haveExclusions"
					value="No"
					checked={formik.values.step3.haveExclusions === 'No'}
					as={RadioSelect}
				/>
			</div>
			<span className="FormikError">
				{formik.touched.step3?.haveExclusions && formik.errors.step3?.haveExclusions}
			</span>

			<FieldArray
				name="step3.exclusions"
				render={(arrayHelpers) => {
					const handleSelectExclusion = (item: ISearchItem) => {
						if (mode === 'new-customer') {
							dispatch(clientJourneyAddExclusionsForDietPlan(item.id));
						}
						arrayHelpers.push({
							name: item.display,
							foodProductId: item.id
						} as DietExclusionRule);
					};

					return (
						<>
							{formik.values.step3.haveExclusions === 'Yes' && (
								<>
									<h4 className="UserInterview__subheader">
										Wybierz produkty, które chcesz wykluczyć:
									</h4>
									<SearchBox
										placeholder="Nazwa produktu"
										activateOnFocus
										data={allProductsRefs.filter(
											(x) =>
												!formik.values.step3.exclusions
													?.map((y) => y.foodProductId)
													?.includes(x.id)
										)}
										value={text}
										onSelect={handleSelectExclusion}
										onSearchChanged={onSearchChanged}
									/>
									<span className="FormikError">
										{(formik.touched.step3?.haveExclusions ||
											mode === 'diet-settings') &&
											formik.errors.step3?.exclusions}
									</span>
									<div className="ExclusionOfFoodProduct03StepForm_chips">
										{formik.values.step3.exclusions?.map(
											(exclusion: DietExclusionRule, index: number) => {
												const handleOnChipsRemoved = (id: number) => {
													if (mode === 'new-customer') {
														dispatch(
															clientJourneyDeleteExclusionsForDietPlan(
																id
															)
														);
													}
													arrayHelpers.remove(index);
												};
												return (
													<ChipsItem
														id={exclusion.foodProductId!}
														text={
															exclusion.name ??
															exclusion.foodProduct?.name!
														}
														key={'Chips_' + exclusion.foodProductId!}
														onRemove={handleOnChipsRemoved}
													/>
												);
											}
										)}
									</div>
								</>
							)}
						</>
					);
				}}
			/>
		</WizardCard>
	);
};
