import * as React from 'react';
import * as Redux from 'redux';

import { DietPlanDay } from '../../../client';
import { RootState } from '../../../business/root-store';
import { TrainingBarComponent } from './TrainingBarComponent';
import { addOrUpdateDietPlanDay } from '../../../business/diet/diet-action';
import { connect } from 'react-redux';
import { getDietDay } from '../../../business/diet/diet-selector';

import { AnyAction, Dispatch } from 'redux';

interface ITrainingBarContainerReduxMergedProps {
	currentDietPlanDay: DietPlanDay;
}

interface ITrainingBarContainerOwnProps {}

interface ITrainingBarContainerDispatchProps {
	addOrUpdateDietPlanDay: (dpd: DietPlanDay) => void;
}

interface ITrainingBarContainerProps
	extends ITrainingBarContainerOwnProps,
		ITrainingBarContainerDispatchProps,
		ITrainingBarContainerReduxMergedProps {}

interface ITrainingBarComponentState {}

class TrainingBarContainer extends React.PureComponent<
	ITrainingBarContainerProps,
	ITrainingBarComponentState
> {
	constructor(props: ITrainingBarContainerProps, state: ITrainingBarComponentState) {
		super(props, state);
		this.state = this.getInitialState();
	}

	public render(): JSX.Element {
		return (
			<TrainingBarComponent
				addOrUpdateDietPlanDay={this.props.addOrUpdateDietPlanDay}
				currentDietPlanDay={this.props.currentDietPlanDay}
			/>
		);
	}

	public componentDidMount(): void {}

	public static mapStateToProps = (
		state: RootState,
		ownProps: ITrainingBarContainerOwnProps
	): ITrainingBarContainerReduxMergedProps => {
		return {
			currentDietPlanDay: getDietDay(state)
		};
	};

	public static mapDispatchToProps = (
		dispatch: Dispatch<AnyAction>
	): ITrainingBarContainerDispatchProps => {
		return {
			...Redux.bindActionCreators(
				{ addOrUpdateDietPlanDay: addOrUpdateDietPlanDay },
				dispatch
			)
		};
	};

	private getInitialState = (): ITrainingBarComponentState => {
		return {} as ITrainingBarComponentState;
	};
}
export const TrainingBar = connect(
	TrainingBarContainer.mapStateToProps,
	TrainingBarContainer.mapDispatchToProps
)(TrainingBarContainer);
