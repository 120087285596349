import { useMemo } from 'react';

import { FormikErrors, yupToFormErrors } from 'formik';

export const useInitialErrorsSync = (
	schema: any,
	initialValues: any
): [boolean, FormikErrors<any>] => {
	return useMemo(() => {
		try {
			schema.validateSync(initialValues, { abortEarly: false });
		} catch (err: any) {
			if (err.name === 'ValidationError') {
				return [false, yupToFormErrors(err)];
			}
		}
		return [true, {}];
	}, []);
};
