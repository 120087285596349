import * as React from 'react';
import * as Redux from 'redux';

import { ExchangeMealComponent } from './ExchangeMealComponent';
import { ISearchItem } from '../../../atoms/search-box/search-box';
import { List } from 'immutable';
import { RootState } from '../../../../business/root-store';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { routes } from '../../../../business/router/path-definition';
import { withRouter } from 'react-router-dom';

import { AnyAction, Dispatch } from 'redux';
import { fetchSelectedMeals, getMealsProposals } from './ExchangeMealEpics';
import { getFavouritesSelector, getMealsSelector } from './ExchangeMealSelectors';

interface IExchangeMealContainerReduxMergedState {
	searchDataItems: List<ISearchItem>;
	favoutitesDataItems: List<ISearchItem>;
}

interface IExchangeMealContainerOwnProps {
	onMealAdd?: (value: boolean) => void;
}

interface IExchangeMealContainerProps
	extends IExchangeMealContainerDispatch,
		IExchangeMealContainerOwnProps,
		IExchangeMealContainerReduxMergedState {}

interface IExchangeMealContainerDispatch {
	navigateTo: (path: string) => void;
	onFetchMeal: (id: List<number>) => void;
	getMealsProposals(): void;
}

class ExchangeMealContainer extends React.Component<IExchangeMealContainerProps> {
	constructor(props: IExchangeMealContainerProps) {
		super(props);
	}

	public componentDidMount(): void {
		this.props.getMealsProposals();
	}

	public render(): JSX.Element {
		return (
			<ExchangeMealComponent
				onSaveClicked={this.props.onFetchMeal}
				searchDataSource={this.props.searchDataItems}
				favouritemsDataSource={this.props.favoutitesDataItems}
				onMealAdd={this.props.onMealAdd}
			/>
		);
	}

	private handleCloseClicked = () => {
		this.props.navigateTo(routes.home.path);
	};

	public static mapStateToProps = (
		state: RootState,
		ownProps: IExchangeMealContainerOwnProps
	): IExchangeMealContainerReduxMergedState => {
		return {
			searchDataItems: getMealsSelector(state.static.mealProposals),
			favoutitesDataItems: getFavouritesSelector(state.static.mealProposals)
		};
	};

	public static mapDispatchToProps = (
		dispatch: Dispatch<AnyAction>
	): IExchangeMealContainerDispatch => {
		return {
			...Redux.bindActionCreators(
				{
					getMealsProposals: getMealsProposals,
					onFetchMeal: fetchSelectedMeals,
					navigateTo: push
				},
				dispatch
			)
		};
	};
}
export const ExchangeMeal = connect(
	ExchangeMealContainer.mapStateToProps,
	ExchangeMealContainer.mapDispatchToProps
)(withRouter(ExchangeMealContainer as any));
