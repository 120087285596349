import { IKeyValuePair } from './interfaces/IKeyValuePair';
import _ from 'lodash';

import { DietObjective, DietPlanDayMeal, MealTypeEnum, OrderTypeEnum } from '../client';
import moment, { Moment } from 'moment';

export interface INutritionFactsSummary {
	energy?: number;
	proteins?: number;
	fats?: number;
	carbs?: number;

	roughage?: number;
	fattyAcid?: number;
	sodium?: number;
	mainSummary?: boolean;
}

export const readUrl = (file: File): Promise<string> => {
	return new Promise((resolve, reject) => {
		var reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = (event) => {
			if (event.target?.result) resolve(event.target.result.toString());
		};
		reader.onerror = reject;
	});
};

export const nameofFactory =
	<T>() =>
	(name: keyof T) =>
		name;

export const isEmptyNullOrUndefined = (
	value: string | number | string[] | number[] | Date | Object | undefined | null
): boolean => {
	return value === null || value === undefined || value === '';
};

export const isEmptyNullOrUndefinedOrDefaultNumber = (
	value: string | number | string[] | number[] | Date | Object | undefined | null
): boolean => {
	return value === null || value === undefined || value === '' || value === 0;
};

export const convertEnumToNumberList = (value: object): IKeyValuePair<number, string>[] => {
	const map: IKeyValuePair<number, string>[] = [];
	Object.entries(value)
		.filter(([key, value]) => !isNumber(value))
		.map(([key, value]) => map.push({ key: +key, value: value }));
	return map;
};

export const convertEnumToStringList = (value: object): IKeyValuePair<string, string>[] => {
	const map: IKeyValuePair<string, string>[] = [];
	Object.entries(value).map(([key, value]) => map.push({ key: key, value: value }));
	return map;
};

export const isNumber = (value: string) => isNaN(Number(value)) === false;

export const getNutritionFactsFromDietPlanDayMeals = (
	meals?: DietPlanDayMeal[] | null,
	eaten?: boolean
): INutritionFactsSummary => {
	if (meals === null || meals === undefined) {
		return {
			energy: 0,
			carbs: 0,
			proteins: 0,
			fats: 0
		} as INutritionFactsSummary;
	}
	meals = meals.filter((z) => eaten === null || eaten === undefined || z.isMealEaten == eaten);
	const energy = meals.reduce((partialSum, a) => partialSum + (a.energy ?? 0), 0);
	const carbs = meals.reduce((partialSum, a) => partialSum + (a.carbs ?? 0), 0);
	const proteins = meals.reduce((partialSum, a) => partialSum + (a.proteins ?? 0), 0);
	const fats = meals.reduce((partialSum, a) => partialSum + (a.fats ?? 0), 0);
	const summary: INutritionFactsSummary = {
		energy: energy,
		carbs: carbs,
		proteins: proteins,
		fats: fats
	};
	return summary;
};

export const getDayName = (dayOfWeek: number) => {
	switch (dayOfWeek) {
		case 0:
			return 'Niedziela';
		case 1:
			return 'Poniedziałek';
		case 2:
			return 'Wtorek';
		case 3:
			return 'Środa';
		case 4:
			return 'Czwartek';
		case 5:
			return 'Piątek';
		case 6:
			return 'Sobota';
		default:
			return '';
	}
};

export const getFriendlyMonthName = (date: Date): string => {
	switch (new Date(date).getMonth()) {
		case 0: {
			return 'Stycznia';
		}
		case 1: {
			return 'Lutego';
		}
		case 2: {
			return 'Marca';
		}
		case 3: {
			return 'Kwietnia';
		}
		case 4: {
			return 'Maja';
		}
		case 5: {
			return 'Czerwca';
		}
		case 6: {
			return 'Lipica';
		}
		case 7: {
			return 'Sierpnia';
		}
		case 8: {
			return 'Września';
		}
		case 9: {
			return 'Października';
		}
		case 10: {
			return 'Listopada';
		}
		case 11: {
			return 'Grudnia';
		}
		default:
			return '';
	}
};

export const getFriendlyMealName = (mealType: MealTypeEnum | number | string): string => {
	if (!_.isNaN(mealType) && !(typeof mealType === 'string')) {
		mealType = MealTypeEnum[mealType as number];
	}
	switch (mealType) {
		//todo || 0
		case 'Breakfast' || MealTypeEnum.Breakfast: {
			return 'Śniadanie';
		}
		case 'Brunch' || MealTypeEnum.Brunch: {
			return 'II Śniadanie';
		}
		case 'Elevenses' || MealTypeEnum.Elevenses: {
			return 'Przekąska';
		}
		case 'Lunch' || MealTypeEnum.Lunch: {
			return 'Obiad';
		}
		case 'Tea' || MealTypeEnum.Tea: {
			return 'Obiad II';
		}
		case 'Supper' || MealTypeEnum.Supper: {
			return 'Podwieczorek';
		}
		case 'Dinner' || MealTypeEnum.Dinner: {
			return 'Kolacja';
		}
		case 'Beverages' || MealTypeEnum.Beverages: {
			return 'Napoje';
		}
		default:
			return '';
	}
};

export const getIdForMealType = (mealTypeValue: string) => {
	switch (mealTypeValue) {
		case 'Breakfast':
			return 0;
		case 'Brunch':
			return 1;
		case 'Elevenses':
			return 2;
		case 'Lunch':
			return 3;
		case 'Tea':
			return 4;
		case 'Supper':
			return 5;
		case 'Dinner':
			return 6;
		case 'Beverages':
			return 7;
		default:
			return null;
	}
};

export const getFriendlyOrderTypeString = (orderType: OrderTypeEnum) => {
	switch (orderType) {
		case OrderTypeEnum.OneTime:
			return 'jednorazowy';
		case OrderTypeEnum.Reccuring:
			return 'abonament';
		default:
			return orderType;
	}
};

export const formatDateMonth = (date: Date, mode: Intl.DateTimeFormatOptions['month']): string => {
	return date.getDate() + ' ' + date.toLocaleDateString('pl-PL', { month: mode });
};

export const mealEtiquetteExpression = /\[[a-zA-Z0-9_.-ąęśćźżółńĄĘŚŻŹĆŃÓ]*:|]/gim;

export const getFriendlyFractionsString = (qty?: number | null): string => {
	const Gcd = (a: number, b: number): number => {
		if (b < 0.0000001) return a;
		return Gcd(b, Math.floor(a % b));
	};
	if (qty === undefined || qty === null) {
		return '';
	}
	qty = Math.abs(qty);
	var qtyFloor = Math.floor(qty);
	var decimal = Math.round((qty - qtyFloor) * 100) / 100;

	if (decimal > 0) {
		var fractionsString = '';
		var len = decimal.toString().length - 2;
		var denominator = Math.pow(10, len);
		var numerator = decimal * denominator;
		var divisor = Gcd(numerator, denominator);
		numerator /= divisor;
		denominator /= divisor;
		if (decimal == 0.66) {
			fractionsString = '2/3';
		} else if (decimal == 0.33) {
			fractionsString = '1/3';
		} else {
			fractionsString = Math.floor(numerator) + '/' + Math.floor(denominator);
		}
		return (Math.floor(qty) !== 0 ? Math.floor(qty) + ' i ' : '') + fractionsString;
	}
	return qty.toString();
};

export const getBase64 = (file: File, cb: any) => {
	let reader = new FileReader();
	reader.readAsDataURL(file);
	reader.onload = function () {
		cb(reader.result);
	};
	reader.onerror = function (error) {
		console.log('Error: ', error);
	};
};

export const toBase64 = (file: File): Promise<string> =>
	new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result!.toString());
		reader.onerror = (error) => reject(error);
	});

export const groupBy = (array: any[], key: any) => {
	// Return the end result
	return array.reduce((result, currentValue) => {
		// If an array already present for key, push it to the array. Else create an array and push the object
		(result[currentValue[key]] = result[currentValue[key]] || []).push(currentValue);
		// Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
		return result;
	}, {}); // empty object is the initial value for result object
};
export const getNutritionFriendlyFormBallance = (nutritionName: string) => {
	switch (nutritionName) {
		case 'Wart. energet':
			return 'Kilokalorie (kcal)';
		case 'Węglowodany strawne':
			return 'Węglowodany';
		case 'Jedno-nienasycone':
			return 'Nasycone kw. tłuszczowe';
		case 'Nasycone':
			return 'Jednonienasycone kw. tłuszczowe';
		case 'Wielo-nienasycone':
			return 'Wielonienasycone kw. tłuszczowe';
		case 'A':
			return 'wit. A';
		case 'B1':
			return 'Tiamina (wit. B1)';
		case 'B12':
			return 'Kobalamina (wit. B12)';
		case 'B2':
			return 'Ryboflawina (wit. B2)';
		case 'B6':
			return 'Pirydoksyna (wit. B6)';
		case 'beta-karoten':
			return 'Beta karoten';
		case 'C':
			return 'wit. C';
		case 'D':
			return 'wit. D';
		case 'E':
			return 'wit. E';
		case 'Folacyna':
			return 'Kwas foliowy (wit. B9)';
		case 'Niacyna':
			return 'Niacyna (wit. B3)';
		default:
			return nutritionName;
	}
};

export const getNutritionFriendlyForm = (nutritionName: string) => {
	switch (nutritionName) {
		case 'Białko':
			return 'białka';
		case 'Błonnik':
			return 'błonnika';
		case 'Cholesterol':
			return 'cholesterolu';
		case 'Tłuszcze':
			return 'tłuszczów';
		case 'Wart. energet':
			return 'kilokalorii (kcal)';
		case 'Węglowodany strawne':
			return 'węglowodanów';
		case 'Jedno-nienasycone':
			return 'nasyconych kw. tłuszczowych';
		case 'Nasycone':
			return 'jednonienasyconych kw. tłuszczowych';
		case 'Wielo-nienasycone':
			return 'wielonienasyconych kw. tłuszczowych';
		case 'Cynk':
			return 'cynku';
		case 'Fosfor':
			return 'fosforu';
		case 'Magnez':
			return 'magnezu';
		case 'Mangan':
			return 'mangangu';
		case 'Miedź':
			return 'miedzi';
		case 'Potas':
			return 'potasu';
		case 'Sód':
			return 'sodu';
		case 'Wapń':
			return 'wapnia';
		case 'Żelazo':
			return 'żelaza';
		case 'A':
			return 'wit. A';
		case 'B1':
			return 'tiaminy (wit. B1)';
		case 'B12':
			return 'kobalaminy (wit. B12)';
		case 'B2':
			return 'ryboflawiny (wit. B2)';
		case 'B6':
			return 'pirydoksyny (wit. B6)';
		case 'beta-karoten':
			return 'beta karotenu';
		case 'C':
			return 'wit. C';
		case 'D':
			return 'wit. D';
		case 'E':
			return 'wit. E';
		case 'Folacyna':
			return 'kwasu foliowego (wit. B9)';
		case 'Niacyna':
			return 'niacyny (wit. B3)';
		default:
			return nutritionName;
	}
};

export function capitalizeOnlyFirstLetter(string: string) {
	return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

export function normalizeWords(string: string) {
	string = string
		.split(' ')
		.map((word) => {
			return word.charAt(0) + word.slice(1).toLowerCase();
		})
		.join(' ');
	return string.charAt(0).toUpperCase() + string.slice(1);
}

export const encodeBase64 = (data: any) => {
	return Buffer.from(data).toString('base64');
};
export const decodeBase64 = (data: any) => {
	return Buffer.from(data, 'base64').toString('ascii');
};

export const nullableMoment = (date?: Date | null): Moment | undefined => {
	if (!date) return undefined;
	return moment(date);
};

export const dietObjectiveStringToEnum = (objective?: string) => {
	switch (objective) {
		case 'R': {
			return DietObjective.REDUCE_MASS_WEIGHT;
		}
		case 'FR': {
			return DietObjective.FAST_REDUCE_MASS_WEIGHT;
		}
		case 'B': {
			return DietObjective.KEEP_BALLANCED_DIET;
		}
		case 'G': {
			return DietObjective.GAIN_MASS_WEIGHT;
		}
		case 'GM': {
			return DietObjective.GAIN_MUSCLE_MASS_WEIGHT;
		}
		default:
			return;
	}
};

export type PossibleNumbersOfMeals = 4 | 5;

export const getMealTypesForNumberOfMeals = (
	numberOfMeals: PossibleNumbersOfMeals
): MealTypeEnum[] => {
	switch (numberOfMeals) {
		case 4: {
			return [
				MealTypeEnum.Breakfast,
				MealTypeEnum.Brunch,
				MealTypeEnum.Lunch,
				MealTypeEnum.Dinner
			];
		}
		case 5: {
			return [
				MealTypeEnum.Breakfast,
				MealTypeEnum.Brunch,
				MealTypeEnum.Lunch,
				MealTypeEnum.Dinner,
				MealTypeEnum.Supper
			];
		}
	}
};

export const dietObjectiveEnumToString = (objective?: DietObjective) => {
	switch (objective) {
		case DietObjective.REDUCE_MASS_WEIGHT: {
			return 'R';
		}
		case DietObjective.FAST_REDUCE_MASS_WEIGHT: {
			return 'FR';
		}
		case DietObjective.KEEP_BALLANCED_DIET: {
			return 'B';
		}
		case DietObjective.GAIN_MASS_WEIGHT: {
			return 'G';
		}
		case DietObjective.GAIN_MUSCLE_MASS_WEIGHT: {
			return 'GM';
		}
		default:
			return undefined;
	}
};

export const GetDatesRangeString = (startDate: string | Date, endDate: string | Date): string => {
	// if (moment(startDate).isSame(moment(endDate), 'date'))
	// 	return formatDateMonth(startDate, 'long');
	// return formatDateMonth(startDate, 'short') + ' - ' + formatDateMonth(endDate, 'short');
	const start = moment(startDate).local();
	const end = moment(endDate).local();
	if (start.format('MM-DD-YYYY') === end.format('MM-DD-YYYY')) {
		return _.startCase(start.format('LL').replace(end.format('YYYY'), ''));
	} else if (start.format('MM-YYYY') === end.format('MM-YYYY')) {
		return (
			start.format('D') +
			' - ' +
			_.startCase(end.format('LL').replace(' ' + end.format('YYYY'), ''))
		);
	}
	return (
		_.startCase(start.format('LL').replace(' ' + start.format('YYYY'), '')) +
		' - ' +
		_.startCase(end.format('LL').replace(' ' + end.format('YYYY'), ''))
	);
};
