import { IStepIconProps } from '../../../atoms/Stepper/StepIcon/StepIcon';
import { IUserInterviewCompletionStatusProps } from './UserInterviewCompletionStatusInterfaces';
import React from 'react';
import { Stepper } from '../../../atoms/Stepper/Stepper';

export const UserInterviewCompletionStatus: React.FC<IUserInterviewCompletionStatusProps> = (
	props: IUserInterviewCompletionStatusProps
) => {
	const step1 = Object.keys(props.formik.errors.step1 ?? []).length === 0;
	const step2 = Object.keys(props.formik.errors.step2 ?? []).length === 0;
	const step3 = Object.keys(props.formik.errors.step3 ?? []).length === 0;
	const step4 = Object.keys(props.formik.errors.step4 ?? []).length === 0;
	const step5 = Object.keys(props.formik.errors.step5 ?? []).length === 0;
	const stepList = [
		{
			label: 'Podstawowe',
			isFinished: step1,
			onStepClick: props.onStepIconClick,
			navigationElementId: 'step01',
			stepNumber: 1
		} as IStepIconProps,
		{
			label: 'Cel Diety',
			isFinished: step1 && step2,
			onStepClick: props.onStepIconClick,
			navigationElementId: 'step02',
			stepNumber: 2
		} as IStepIconProps,
		{
			label: 'Wykluczenia',
			isFinished: step1 && step2 && step3,
			onStepClick: props.onStepIconClick,
			navigationElementId: 'step03',
			stepNumber: 3
		} as IStepIconProps,
		{
			label: 'Plan Diety',
			isFinished: step1 && step2 && step3 && step4,
			onStepClick: props.onStepIconClick,
			navigationElementId: 'step04',
			stepNumber: 4
		} as IStepIconProps,
		{
			label: 'Szczegóły',
			isFinished: step1 && step2 && step3 && step4 && step5,
			onStepClick: props.onStepIconClick,
			navigationElementId: 'step05',
			stepNumber: 5
		} as IStepIconProps
	];

	return <Stepper stepList={stepList} />;
};
