import * as React from 'react';
import * as Redux from 'redux';

import { Document } from '../../../client';
import { DocumentComponent } from './DocumentComponent';
import { RootState } from '../../../business/root-store';
import { connect } from 'react-redux';
import { createMatchSelector } from 'connected-react-router';
import { getDocument } from '../../molecules/staticData/staticDataSelectors';
import { getDocumentByUrl } from './DocumentEpics';
import { match } from 'react-router-dom';
import { routes } from '../../../business/router/path-definition';

import { AnyAction, Dispatch } from 'redux';

interface IDocumentContainerReduxMergedProps {
	document: Document;
	match: match<{ documentUrl?: string }>;
}

interface IDocumentContainerOwnProps {}

interface IDocumentContainerDispatchProps {
	getDocumentByUrl: (documentUrl: string) => void;
}

interface IDocumentContainerProps
	extends IDocumentContainerOwnProps,
		IDocumentContainerDispatchProps,
		IDocumentContainerReduxMergedProps {}

interface IDocumentContainerState {
	documentUrl: string;
}

class DocumentContainer extends React.Component<IDocumentContainerProps, IDocumentContainerState> {
	constructor(props: IDocumentContainerProps, state: IDocumentContainerState) {
		super(props, state);

		this.state = this.getInitialState();
		const documentUrl = this.props.match.params.documentUrl;

		if (documentUrl) {
			this.state = {
				documentUrl: documentUrl
			};
			this.props.getDocumentByUrl(documentUrl);
		}
	}

	static getDerivedStateFromProps(
		nextProps: IDocumentContainerProps,
		prevState: IDocumentContainerState
	) {
		if (
			nextProps?.match?.params?.documentUrl &&
			prevState.documentUrl !== nextProps.match.params.documentUrl
		) {
			nextProps.getDocumentByUrl(nextProps.match.params.documentUrl);
			return { documentUrl: nextProps.match.params.documentUrl } as IDocumentContainerState;
		}
		return null;
	}

	public render(): JSX.Element {
		if (!this.props.document.createdDateUtc) return <></>;
		return <DocumentComponent document={this.props.document} />;
	}

	public static mapStateToProps = (
		state: RootState,
		ownProps: IDocumentContainerOwnProps
	): IDocumentContainerReduxMergedProps => {
		const matchSelector = createMatchSelector(routes.static.document);
		return {
			match: matchSelector(state)!,
			document: getDocument(state)
		};
	};

	public static mapDispatchToProps = (
		dispatch: Dispatch<AnyAction>
	): IDocumentContainerDispatchProps => {
		return {
			...Redux.bindActionCreators({ getDocumentByUrl: getDocumentByUrl }, dispatch)
		};
	};

	private getInitialState = (): IDocumentContainerState => {
		return {} as IDocumentContainerState;
	};
}
export const DocumentPage = connect(
	DocumentContainer.mapStateToProps,
	DocumentContainer.mapDispatchToProps
)(DocumentContainer);
