import { DietApi } from '../../../../../client';
import { GetApiConfig } from '../../../../../business/api-client-config';
import { RootState } from '../../../../../business/root-store';
import { ThunkAction } from 'redux-thunk';
import { actions } from 'react-redux-toastr';
import moment from 'moment';
import { shoppingListSaved } from '../shopping-list-actions';

export const setShoppingList =
	(startDateUtc: Date, endDateUtc: Date): ThunkAction<any, RootState, any, any> =>
	(dispatch, getState) => {
		const store: RootState = getState();
		const api = new DietApi(GetApiConfig(store));

		api.postShoppingListForDietPlan({
			endDateUtc: moment(endDateUtc).toISOString(),
			startDateUtc: moment(startDateUtc).toISOString()
		})
			.then((response) => {
				dispatch(shoppingListSaved(response.data.shoppingList!));
			})
			.catch((c) => {
				dispatch(
					actions.add({
						message: 'Cannot get shopping list.',
						type: 'error'
					})
				);
			});
	};
