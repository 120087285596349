import './slider.scss';

import React, { useEffect, useState } from 'react';

interface ISlidingContainerProps {
	items: JSX.Element[];
	timer?: number;
	className?: string;
	currentSlideId?: number;
	setCurrentSlideId?: (slideId: number) => void;
}

export const Slider: React.FC<ISlidingContainerProps> = ({
	items,
	className,
	timer,
	currentSlideId,
	setCurrentSlideId,
	...props
}) => {
	const [internalCurrentSlideId, setInternalCurrentSlideId] = useState(0);
	const slideId = currentSlideId ?? internalCurrentSlideId;
	const setSlideId = setCurrentSlideId ?? setInternalCurrentSlideId;
	const triggers = [];

	if (items.length > 1) {
		for (let i = 0; i < items.length; i++) {
			const handleClick = () => {
				onSlideSelect(i);
			};
			triggers.push(
				<div
					key={i}
					className={`slider__trigger ${i === slideId ? 'slider__trigger--active' : ''}`}
					onClick={handleClick}
				/>
			);
		}
	}
	if (timer) {
		useEffect(() => {
			const interval = setInterval(() => {
				const nextSlideId = slideId + 1;
				if (nextSlideId > items.length - 1) {
					setSlideId(0);
				} else {
					setSlideId(nextSlideId);
				}
			}, timer);
			return () => clearInterval(interval);
		}, [slideId]);
	}

	const onSlideSelect = (selectedId: number) => {
		setSlideId(selectedId);
	};

	return (
		<div className={className ? 'slider ' + className : 'slider'}>
			<div className="slider__items">{items[slideId]}</div>
			<div className="slider__triggers">{triggers}</div>
		</div>
	);
};
