import { IMealFoodProductMealPair } from '../CustomMeal/CustomMealInterfaces';
import { List } from 'immutable';
import { Meal } from '../../../../client';
import { Reducer } from 'redux';

import {
	IMealSwappedAction,
	IMealsFetchedAction,
	IMealsSwappedClearAction,
	INewSwappedMealSavedAction,
	NewMealSwappedLeavePage,
	NewMealSwapped_Saved,
	NewMeal_Confirmed,
	SelectedMeals_Fetched
} from './ExchangeMealActions';

type ReducerActions =
	| IMealSwappedAction
	| IMealsFetchedAction
	| IMealsSwappedClearAction
	| INewSwappedMealSavedAction;

interface IExchangeMealReduxState {
	swappedMeals: List<Meal>;
	confirmedSwappedMeals: List<IMealFoodProductMealPair>;
}

const INITIAL_STATE = {
	swappedMeals: List<Meal>(),
	confirmedSwappedMeals: List<IMealFoodProductMealPair>()
};

export const exchangeMealReducer: Reducer<IExchangeMealReduxState, ReducerActions> = (
	state = INITIAL_STATE,
	action
) => {
	switch (action.type) {
		case SelectedMeals_Fetched:
			return handleMealFetched(state, action);
		case NewMeal_Confirmed:
			return handleMealSwapped(state, action);
		case NewMealSwapped_Saved:
		case NewMealSwappedLeavePage:
			return setInitialExchangeMealState(state, action);
		default:
			return state;
	}
};

const setInitialExchangeMealState = (
	state: IExchangeMealReduxState,
	action: IMealsSwappedClearAction | INewSwappedMealSavedAction
): IExchangeMealReduxState => {
	return {
		...state,
		swappedMeals: List(),
		confirmedSwappedMeals: List()
	};
};

const handleMealSwapped = (
	state: IExchangeMealReduxState,
	action: IMealSwappedAction
): IExchangeMealReduxState => {
	return {
		...state,
		swappedMeals: state.swappedMeals.filter((meal: Meal) => meal.id !== action.pair.key),
		confirmedSwappedMeals: state.confirmedSwappedMeals.push(action.pair)
	};
};

const handleMealFetched = (
	state: IExchangeMealReduxState,
	action: IMealsFetchedAction
): IExchangeMealReduxState => {
	return {
		...state,
		swappedMeals: List<Meal>(action.payload.meals)
	};
};
