import { MealStatus } from '../../client';

export interface MealStatusFilter {
	name: string;
	status: MealStatus;
}

export const MealStatusFilter = {
	None: { name: 'Brak', status: MealStatus.None } as MealStatusFilter,
	New: {
		name: 'Nowy, niepubliczny',
		status: MealStatus.New
	} as MealStatusFilter,
	Rejected: {
		name: 'Odrzucony, prosimy o poprawę',
		status: MealStatus.Rejected
	} as MealStatusFilter,
	FullWorkingNotPublic: {
		name: 'Zweryfikowany, niepubliczny',
		status: MealStatus.Verified
	} as MealStatusFilter,
	FullWorkingPublic: {
		name: 'Zweryfikowany, publiczny',
		status: MealStatus.Verified | MealStatus.Public
	} as MealStatusFilter,
	ToVerify: {
		name: 'Czeka na weryfikację',
		status: MealStatus.ToVerify
	} as MealStatusFilter
};

export const getMealStatusName = (status: MealStatus) => {
	return Object.values(MealStatusFilter).find((x) => x.status === status)?.name;
};
