import './Modal.scss';

import { CloseIcon } from '../../../shared/assets/close-icon';

import React, { RefObject } from 'react';

export interface IModalProps {
	ref: RefObject<IModalProps>;
	show: boolean;
	handleStateChange(): void;

	showModal(): void;
	closeModal(): void;
}

export class Modal extends React.Component<any, any> {
	private readonly wrapperRef: RefObject<any>;
	private readonly underlayRef: RefObject<any>;
	private readonly handleClickOutsideBound: any;
	private readonly closeModalBound: any;

	constructor(props: IModalProps) {
		super(props);
		this.wrapperRef = React.createRef();
		this.underlayRef = React.createRef();

		this.handleClickOutsideBound = this.handleClickOutside.bind(this);
		this.closeModalBound = this.closeModal.bind(this);

		document.addEventListener('mousedown', this.handleClickOutsideBound);
		document.addEventListener('closeModal', this.closeModalBound);

		document.body.style.overflow = 'hidden';
	}

	private closeModal() {
		document.body.style.overflow = 'auto';
		document.removeEventListener('closeModal', this.closeModalBound);
		document.removeEventListener('mousedown', this.handleClickOutsideBound);
		this.props.handleStateChange(false);
	}

	private handleClickOutside(event: MouseEvent) {
		if (this.underlayRef.current && this.underlayRef.current.contains(event.target)) {
			this.closeModal();
		}
	}

	render() {
		return (
			<div
				ref={this.wrapperRef}
				className={`Modal ${this.props.show ? 'Modal--visible' : ''}`}
			>
				<div className="Modal__underlay" ref={this.underlayRef} />
				<div className="Modal__box">
					<div className="Modal__close-icon">
						<CloseIcon onClick={this.closeModal.bind(this)} />
					</div>
					<div className="Modal__body">{this.props.children}</div>
				</div>
			</div>
		);
	}
}
