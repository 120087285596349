import { GetApiConfig } from '../../../../business/api-client-config';
import { List } from 'immutable';
import { RootState } from '../../../../business/root-store';
import { ThunkAction } from 'redux-thunk';
import { mealsForWeekFetched } from './MealTableActions';

import { DietApi, WeekViewDay } from '../../../../client';

export const fetchMealsForWeek =
	(dietPlanId: number, dietDay: number): ThunkAction<any, RootState, any, any> =>
	(dispatch, getState) => {
		const store = getState();
		const api = new DietApi(GetApiConfig(store));
		api.getMealsForWeek({
			dietPlanId: dietPlanId,
			dietDay: dietDay
		})
			.then((response) => {
				dispatch(
					mealsForWeekFetched({
						mealsForWeek: List<WeekViewDay>(response.data.dietPlans!)
					})
				);
			})
			.catch((c) => {});
	};

export const fetchMealsForWeekWithRouterParams =
	(): ThunkAction<any, RootState, any, any> => (dispatch, getState) => {
		const store = getState();

		var dietDay = parseInt(store.router.location.query['dayId']!, 10);
		var dietPlanId = parseInt(store.router.location.query['dietPlanId']!, 10);

		dispatch(fetchMealsForWeek(dietPlanId, dietDay));
	};

export const saveSwappedMeals =
	(from: number, to: number): ThunkAction<any, RootState, any, any> =>
	(dispatch, getState) => {
		const store = getState();
		const api = new DietApi(GetApiConfig(store));
		api.postSwapDietPlanDayMeals({
			dietPlanId: store.diet.dietPlanDay!.dietPlanId!,
			dayId: store.diet.dietPlanDay!.dietDayId!,
			from: from,
			to: to
		})
			.then((response) => {
				dispatch(
					mealsForWeekFetched({
						mealsForWeek: List<WeekViewDay>(response.data.swappedMeals!)
					})
				);
			})
			.catch((c) => {});
	};
