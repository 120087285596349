import './auth.scss';

import { AuthTypeKey } from '../../../business/router/interfaces';
import { ChangePasswordForm } from './ChangePassswordForm';
import { HandleResetPasswordForm } from './HandleResetPasswordForm/HandleResetPasswordForm';
import { Headline } from '../../atoms/headline/headline';
import { ILogin } from './interfaces';
import { LoginForm } from './LoginForm/LoginForm';
import { RadioSelect } from '../../atoms/RadioSelect/RadioSelect';
import { RegisterForm } from './RegisterForm/RegisterForm';
import { ResetPasswordForm } from './ResetPasswordForm';
import { WizardCard } from '../WizardCard/WizardCard';
import { assingFormTitle } from './AuthComponent.auxiliaries';
import { authTypes } from '../../../business/router/path-definition';
import { elementIds } from '../../organisms/OrderMenuView/OrderMenuView.auxiliaries';

interface IAuthCardComponentProps {
	formDisplayed?: AuthTypeKey;
	isUserRegistered?: boolean;
	guid: string;
	onSetUserHasAccount(): void;
	onSetUserHasNoAccount(): void;
	logError(message: string): void;
	loginUser(data: ILogin): void;
	registerUser(data: ILogin): void;
	resetPasswordHandler(): void;
	resetPassword(email: string): void;
	backToLogin(): void;
	changePassword(oldPassword: string, newPassword: string): void;
	handleResetPassword(newPassword: string, retypeNewPassword: string, guid: string): void;
	handleActivateCreatedAccount(
		newPassword: string,
		retypeNewPassword: string,
		guid: string
	): void;
}

export const AuthCardComponent = ({
	formDisplayed,
	isUserRegistered,
	guid,
	onSetUserHasAccount,
	onSetUserHasNoAccount,
	logError,
	loginUser,
	registerUser,
	resetPasswordHandler,
	resetPassword,
	backToLogin,
	changePassword,
	handleResetPassword,
	handleActivateCreatedAccount
}: IAuthCardComponentProps) => {
	const headerTitle = formDisplayed === undefined ? '...' : assingFormTitle(formDisplayed);

	const areRadioButtonsDisplayed = formDisplayed === 'login' || formDisplayed === 'register';
	const isLoginFormDisplayed = formDisplayed === 'login';
	const isRegistrationFormDisplayed = formDisplayed === 'register';
	const isChangePasswordFormDisplayed = formDisplayed === 'change-password';
	const isResetPasswordFormDisplayed = formDisplayed === 'reset-password';
	const isHandleResetPasswordFormDisplayed = formDisplayed === 'reset';
	const isActivateCreatedAccountFormDisplayed = formDisplayed === 'activateaccount';
	return (
		<div id={elementIds.loginCard} className="animate__animated animate__fadeIn">
			<div className={`${elementIds.loginCard}-wrapper`}>
				<WizardCard id="auth" header={headerTitle} className="auth_wizardcard">
					<div className={`${elementIds.loginCard}-content`}>
						{areRadioButtonsDisplayed && (
							<div className="panel panel-radio">
								<RadioSelect
									label="Nie mam konta w serwisie"
									id={authTypes.register}
									checked={!isUserRegistered}
									onChange={onSetUserHasNoAccount}
								/>
								<RadioSelect
									label="Mam już konto"
									id={authTypes.login}
									checked={isUserRegistered}
									onChange={onSetUserHasAccount}
								/>
							</div>
						)}
						<div className="panel panel-form">
							{isLoginFormDisplayed && (
								<div className="AuthLogin animate__animated animate__fadeIn">
									<Headline text="Zaloguj się" align="left" />
									<LoginForm
										userLoginHandler={loginUser}
										resetPasswordHandler={resetPasswordHandler}
									/>
								</div>
							)}
							{isRegistrationFormDisplayed && (
								<div className="AuthRegister animate__animated animate__fadeIn">
									<Headline text="Stwórz nowe konto" align="left" />
									<RegisterForm
										handleError={logError}
										handleRegisterConfirmed={registerUser}
									/>
								</div>
							)}
							{isChangePasswordFormDisplayed && (
								<div className="AuthChangePassword">
									<Headline text="Zmień hasło" align="left" />
									<ChangePasswordForm
										changePassword={changePassword}
										handleError={logError}
									/>
								</div>
							)}
							{isResetPasswordFormDisplayed && (
								<ResetPasswordForm
									backToLogin={backToLogin}
									resetPassword={resetPassword}
								/>
							)}
							{isHandleResetPasswordFormDisplayed && (
								<div className="AuthHandleResetPassword">
									<Headline text="Zmień hasło" align="left" />
									<HandleResetPasswordForm
										handleError={logError}
										handleResetPassword={handleResetPassword}
										guid={guid}
									/>
								</div>
							)}
							{isActivateCreatedAccountFormDisplayed && (
								<HandleResetPasswordForm
									handleError={logError}
									handleResetPassword={handleActivateCreatedAccount}
									key={guid}
									guid={guid}
								/>
							)}
						</div>
					</div>
				</WizardCard>
			</div>
		</div>
	);
};
