import '../PartnershipInitial.scss';

import * as Yup from 'yup';

import { ActionButton } from '../../../../atoms/ActionButton/ActionButton';
import ActionLink from '../../../../atoms/action-link/action-link';
import { CheckBox } from '../../../../atoms/CheckBox/CheckBox';
import { CloseIcon } from '../../../../../shared/assets/close-icon';
import { FileMetadata } from '../../../../../files-client';
import { FileUploader } from '../../../../atoms/FileUploader/FileUploader';
import { HereAPI } from '../../../../../shared/HereApi';
import React from 'react';
import { Slider } from '../../../../atoms/slider/slider';
import { TextArea } from '../../../../atoms/TextArea/TextArea';
import { TextBox } from '../../../../atoms/TextBox/TextBox';
import { createUrlWithParams } from '../../../../../business/router/path-definition';
import { getPartnershipRestaurantSelector } from '../../../../../business/Partnership/PartnershipSelectors';
import { routes } from '../../../../../business/router/path-definition';
import { useHistory } from 'react-router-dom';

import { Field, Form, Formik, FormikHelpers, FormikProps } from 'formik';
import {
	IPartnerShipInitialForm,
	IPartnerShipInitialFormValues
} from './PartnershipInitialFormInterfaces';
import { Request, Restaurant } from '../../../../../client';
import {
	addPartnershipRestaurantImages,
	postRestaurantRequestForm
} from '../PartnershipInitialEpics';
import { documentsUrls, primaryOrangeColor } from '../../../../../shared/shared';
import { useDispatch, useSelector } from 'react-redux';

export const PartnerShipInitialForm = (props: IPartnerShipInitialForm) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const restaurant = useSelector(getPartnershipRestaurantSelector);
	const handleClickTerms = (): void => {
		history.push(
			createUrlWithParams(routes.static.document.path, {
				documentUrl: documentsUrls.tos
			})
		);
	};
	const onSubmit = async (
		values: IPartnerShipInitialFormValues,
		helpers: FormikHelpers<IPartnerShipInitialFormValues>
	) => {
		let newRestaurant = values as Restaurant;

		let results = await HereAPI.geocode(
			newRestaurant.city +
				', ' +
				newRestaurant.postcode +
				', ' +
				newRestaurant.street +
				' ' +
				newRestaurant.homeNumber
		);
		if (results.length === 0) {
			results = await HereAPI.geocode(
				newRestaurant.city + ', ' + newRestaurant.postcode + ', ' + newRestaurant.street
			);
		}
		if (results.length === 0) {
			results = await HereAPI.geocode(newRestaurant.city + ', ' + newRestaurant.postcode);
		}
		if (results.length === 0) {
			results = await HereAPI.geocode(newRestaurant.city!);
		}
		if (results.length === 0) {
			results = await HereAPI.geocode(newRestaurant.postcode!);
		}
		if (results.length > 0) {
			newRestaurant.lat = +results[0].y;
			newRestaurant.lng = +results[0].x;
		}
		dispatch(
			postRestaurantRequestForm(
				{
					name: values.name,
					mail: values.mail,
					title: values.topic
				} as Request,
				newRestaurant,
				restaurant.id !== undefined
			)
		);
		setTimeout(() => helpers.setSubmitting(false), 2000);
	};

	const imagesChangeHandler = (
		e: React.ChangeEvent<HTMLInputElement>,
		restaurant: Restaurant
	) => {
		const fileList = e.target.files;
		if (fileList) {
			const files = Array.from(fileList);
			dispatch(addPartnershipRestaurantImages(restaurant, files));
		}
	};

	const removeImage = (image: FileMetadata) => {
		dispatch(props.removePartnershipRestaurantImage(image.guid!));
	};

	return (
		<Formik
			enableReinitialize
			initialValues={{
				topic: 'Współpraca z DietDesigner',
				acceptedTerms: false,
				...restaurant
			}}
			validationSchema={Yup.object<
				Partial<Record<keyof IPartnerShipInitialFormValues, Yup.AnySchema>>
			>({
				name: Yup.string().min(3, 'Co najmniej 3 znaki').required('Wymagane'),
				city: Yup.string().min(3, 'Co najmniej 3 znaki').required('Wymagane'),
				street: Yup.string().min(3, 'Co najmniej 3 znaki').required('Wymagane'),
				homeNumber: Yup.string().required('Wymagane'),
				postcode: Yup.string()
					.matches(new RegExp('[0-9]{2}-[0-9]{3}'), 'Niepoprawny kod pocztowy (00-000)')
					.min(5, 'Co najmniej 6 znaków')
					.required('Wymagane'),
				openHours: Yup.string().min(3, 'Co najmniej 3 znaki').required('Wymagane'),
				descriptionTitle: Yup.string().min(3, 'Co najmniej 3 znaki').required('Wymagane'),
				shortDescription: Yup.string().min(3, 'Co najmniej 3 znaki').required('Wymagane'),
				description: Yup.string().min(3, 'Co najmniej 3 znaki').required('Wymagane'),
				representativeCity: Yup.string().min(3, 'Co najmniej 3 znaki').required('Wymagane'),
				representativeStreet: Yup.string()
					.min(3, 'Co najmniej 3 znaki')
					.required('Wymagane'),
				representativeHomeNumber: Yup.string().required('Wymagane'),
				representativePostcode: Yup.string()
					.matches(new RegExp('[0-9]{2}-[0-9]{3}'), 'Niepoprawny kod pocztowy (00-000)')
					.min(5, 'Co najmniej 6 znaków')
					.required('Wymagane'),
				representativeName: Yup.string().min(3, 'Co najmniej 3 znaki').required('Wymagane'),
				representativeTelephone: Yup.string()
					.min(9, 'Co najmniej 9 znaków')
					.required('Wymagane'),
				topic: Yup.string().min(3, 'Co najmniej 3 znaki').required('Wymagane'),
				mail: Yup.string()
					.email('Niepoprawny adres email')
					.matches(
						/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
						'Niepoprawny adres email'
					)
					.required('Wymagane'),
				acceptedTerms: Yup.boolean().oneOf([true], 'Wymagane')
			})}
			onSubmit={onSubmit}
		>
			{(formik) => (
				<Form>
					<fieldset
						disabled={props.disabled}
						className="PartnershipInitial_form_content_row"
					>
						<div className="PartnershipInitial_form_content_row_Images">
							{!props.disabled && (
								<FileUploader
									multiple
									onChange={(e) =>
										imagesChangeHandler(e, formik.values as Restaurant)
									}
									text="Dodaj zdjęcia restauracji"
									accept="image/*"
								/>
							)}
							{getImagesSliderElement(
								formik,
								props.restaurantImages,
								removeImage,
								'logoUrl',
								props.disabled
							)}
						</div>
						<Field
							name="name"
							label="Nazwa restauracji"
							placeholder="Jak nazywa się Twoja restauracja?"
							className="PartnershipInitial_form_content_row_item"
							errorString={formik.touched.name && formik.errors.name}
							as={TextBox}
						/>
						<Field
							disabled={restaurant.id}
							name="mail"
							label="E-Mail"
							placeholder="np. jan.kowalski@kontakt.com"
							className="PartnershipInitial_form_content_row_item"
							errorString={formik.touched.mail && formik.errors.mail}
							as={TextBox}
						/>
						<Field
							disabled
							name="topic"
							label="Temat"
							placeholder="O co pytasz?"
							className="PartnershipInitial_form_content_row_item"
							errorString={formik.touched.topic && formik.errors.topic}
							as={TextBox}
						/>

						<Field
							name="street"
							label="Ulica"
							placeholder="np. Kwiatowa"
							className="PartnershipInitial_form_content_row_item"
							errorString={formik.touched.street && formik.errors.street}
							as={TextBox}
						/>
						<Field
							maxLength={10}
							name="homeNumber"
							label="Numer posesji"
							placeholder="np. 15"
							className="PartnershipInitial_form_content_row_item"
							errorString={formik.touched.homeNumber && formik.errors.homeNumber}
							as={TextBox}
						/>
						<Field
							maxLength={10}
							name="apartmentNumber"
							label="Numer lokalu"
							placeholder="np. 1"
							className="PartnershipInitial_form_content_row_item"
							errorString={
								formik.touched.apartmentNumber && formik.errors.apartmentNumber
							}
							as={TextBox}
						/>

						<Field
							name="city"
							label="Miasto"
							placeholder="np. Warszawa"
							className="PartnershipInitial_form_content_row_item"
							errorString={formik.touched.city && formik.errors.city}
							as={TextBox}
						/>

						<Field
							maxLength={6}
							mask="99-999"
							name="postcode"
							label="Kod pocztowy"
							placeholder="np. 91-371"
							className="PartnershipInitial_form_content_row_item"
							errorString={formik.touched.postcode && formik.errors.postcode}
							as={TextBox}
						/>

						<Field
							name="openHours"
							label="Godziny otwarcia"
							placeholder="np. pon-pt 8-16"
							className="PartnershipInitial_form_content_row_item"
							errorString={formik.touched.openHours && formik.errors.openHours}
							as={TextBox}
						/>

						<Field
							name="descriptionTitle"
							label="Podtytuł"
							placeholder="np. Restauracja tajska"
							className="PartnershipInitial_form_content_row_item"
							errorString={
								formik.touched.descriptionTitle && formik.errors.descriptionTitle
							}
							as={TextBox}
						/>
						<Field
							name="shortDescription"
							label="Krótki opis restauracji"
							placeholder="np. Najlepsze burgery w Warszawie!"
							className="PartnershipInitial_form_content_row_item PartnershipInitial_form_content_row_item_full-width"
							errorString={
								formik.touched.shortDescription && formik.errors.shortDescription
							}
							as={TextBox}
						/>
						<Field
							name="description"
							label="Opis restauracji"
							placeholder="Dłuższy opis"
							className="PartnershipInitial_form_content_row_item PartnershipInitial_form_content_row_item_full-width"
							errorString={formik.touched.description && formik.errors.description}
							as={TextArea}
						/>

						<Field
							name="representativeName"
							label="Imię i nazwisko reprezentata"
							placeholder="np. Dyrektor Jan Kowalski"
							className="PartnershipInitial_form_content_row_item"
							errorString={
								formik.touched.representativeName &&
								formik.errors.representativeName
							}
							as={TextBox}
						/>

						<Field
							name="representativeTelephone"
							label="Numer telefonu do reprezentata"
							placeholder="np. 111-222-333"
							className="PartnershipInitial_form_content_row_item"
							errorString={
								formik.touched.representativeTelephone &&
								formik.errors.representativeTelephone
							}
							as={TextBox}
						/>

						<Field
							name="representativeStreet"
							label="Ulica (reprezentant)"
							placeholder="np. Kwiatowa"
							className="PartnershipInitial_form_content_row_item"
							errorString={
								formik.touched.representativeStreet &&
								formik.errors.representativeStreet
							}
							as={TextBox}
						/>
						<Field
							maxLength={10}
							name="representativeHomeNumber"
							label="Numer posesji (reprezentant)"
							placeholder="np. 15"
							className="PartnershipInitial_form_content_row_item"
							errorString={
								formik.touched.representativeHomeNumber &&
								formik.errors.representativeHomeNumber
							}
							as={TextBox}
						/>
						<Field
							maxLength={10}
							name="representativeApartmentNumber"
							label="Numer lokalu (reprezentant)"
							placeholder="np. 1"
							className="PartnershipInitial_form_content_row_item"
							errorString={
								formik.touched.representativeApartmentNumber &&
								formik.errors.representativeApartmentNumber
							}
							as={TextBox}
						/>

						<Field
							name="representativeCity"
							label="Miasto (reprezentant)"
							placeholder="np. Warszawa"
							className="PartnershipInitial_form_content_row_item"
							errorString={
								formik.touched.representativeCity &&
								formik.errors.representativeCity
							}
							as={TextBox}
						/>

						<Field
							maxLength={6}
							mask="99-999"
							name="representativePostcode"
							label="Kod pocztowy (reprezentant)"
							placeholder="np. 91-371"
							className="PartnershipInitial_form_content_row_item"
							errorString={
								formik.touched.representativePostcode &&
								formik.errors.representativePostcode
							}
							as={TextBox}
						/>

						<Field
							name="additionalInfo"
							label="Inne ważne informacje"
							placeholder="Wpisz treść swojej wiadomości"
							className="PartnershipInitial_form_content_row_item PartnershipInitial_form_content_row_item_full-width"
							errorString={
								formik.touched.additionalInfo && formik.errors.additionalInfo
							}
							as={TextArea}
						/>
						{!props.disabled && (
							<>
								<div className="PartnershipInitial_form_terms">
									<Field
										name="acceptedTerms"
										type="checkbox"
										as={CheckBox}
										errorString={
											formik.touched.acceptedTerms &&
											formik.errors.acceptedTerms
										}
										displayName={
											<span>
												Podane dane osobowe będą przetwarzane w celu
												kontaktu i obsługi Państwa zgłoszenia. Więcej
												informacji o przetwarzaniu danych osobowych:
												<ActionLink
													textColor={primaryOrangeColor}
													text="Polityka Prywatności"
													onClick={handleClickTerms}
												/>
												.
											</span>
										}
										disableDisplayNameChangeStyle
									/>
								</div>

								<ActionButton
									disabled={!formik.isValid}
									errorString={
										formik.isValid ? undefined : 'Sprawdź błędy w formularzu'
									}
									type="submit"
									text="Wyślij zgłoszenie"
									className="PartnershipInitial_form_action-button"
								/>
							</>
						)}
					</fieldset>
				</Form>
			)}
		</Formik>
	);
};
export const getImagesSliderElement = (
	formik: FormikProps<any>,
	files: FileMetadata[],
	removeImage: (file: FileMetadata) => void,
	logoUrlField: string,
	disabled?: boolean
): JSX.Element => {
	const images = files.filter(
		(x) => (x.extension === '.jpg' || x.extension === '.png') && x.fileName?.startsWith('org')
	);
	const imagesElements = images.map((image, idx) => {
		const handleRemoveImage = () => {
			removeImage(image);
			if (formik.values[logoUrlField] === image.lastVersion.url) {
				formik.setFieldValue(logoUrlField, null);
			}
		};
		const setImageAsLogo = () => {
			formik.setFieldValue(logoUrlField, image.lastVersion.url);
		};
		return (
			<div
				key={'image_' + idx}
				className="PartnershipInitial_form_content_row_Images_Slider_Image"
			>
				{!disabled ? (
					<div className="PartnershipInitial_form_content_row_Images_Slider_Image_Buttons">
						<CloseIcon
							onClick={handleRemoveImage}
							className="PartnershipInitial_form_content_row_Images_Slider_Image_Buttons_CloseIcon"
						/>
						{formik.values[logoUrlField] !== image.lastVersion.url ? (
							<ActionLink
								onClick={setImageAsLogo}
								text="Ustaw jako główne zdjęcie restauracji (logo)"
							/>
						) : (
							<span>To zdjęcie jest ustawione jako główne (logo)</span>
						)}
					</div>
				) : formik.values[logoUrlField] === image.lastVersion.url ? (
					<span>To zdjęcie jest ustawione jako główne (logo)</span>
				) : (
					<></>
				)}

				<img src={process.env.REACT_APP_FILES_URL + '/api/' + image.lastVersion.url} />
			</div>
		);
	});
	if (imagesElements) {
		return (
			<Slider
				items={imagesElements}
				className="PartnershipInitial_form_content_row_Images_Slider"
			/>
		);
	} else {
		return <></>;
	}
};
