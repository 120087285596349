import './UserInterview.scss';

import { ActionButton } from '../../atoms/ActionButton/ActionButton';
import ActionLink from '../../atoms/action-link/action-link';
import { BackLink } from '../../atoms/back-link/back-link';
import { BasicSettings01StepForm } from './BasicSettings01StepForm';
import { DietObjective02StepForm } from './DietObjective02StepForm';
import { DietPlanPreferences04StepForm } from './DietPlanPreferences04StepForm';
import { ExclusionOfFoodProduct03StepForm } from './ExclusionOfFoodProduct03StepForm';
import { FoodServingDetails05StepForm } from './FoodServingDetails05StepForm';
import { Headline } from '../../atoms/headline/headline';
import { IBasicSettings01StepFormValues } from './BasicSettings01StepForm/BasicSettings01StepFormInterfaces';
import { IDietObjective02StepFormValues } from './DietObjective02StepForm/DietObjective02StepFormInterfaces';
import { IDietPlanPreferences04StepFormValues } from './DietPlanPreferences04StepForm/DietPlanPreferences04StepFormInterfaces';
import { IExclusionOfFoodProduct03StepFormValues } from './ExclusionOfFoodProduct03StepForm/ExclusionOfFoodProduct03StepFormInterfaces';
import { IFoodServingDetails05StepFormValues } from './FoodServingDetails05StepForm/FoodServingDetails05StepFormInterfaces';
import { UserInterviewCompletionStatus } from './UserInterviewCompletionStatus';
import moment from 'moment';
import { navigationAction } from '../../../business/router/path-definition';
import { primaryOrangeColor } from '../../../shared/shared';
import { schema } from './UserInterviewSchema';
import { useInitialErrorsSync } from '../../../hooks/useInitialErrorSync';
import { useParams } from 'react-router-dom';

import {
	PossibleNumbersOfMeals as AvailableNumbersOfMeals,
	dietObjectiveEnumToString,
	dietObjectiveStringToEnum,
	isEmptyNullOrUndefined
} from '../../../shared/helpers';
import { Form, Formik, FormikHelpers, setNestedObjectValues } from 'formik';
import { Gender, MealTypeEnum, MealTypeTag, TagsMealsForDietServings } from '../../../client';
import {
	IUserInterviewProps,
	IUserInterviewValues,
	UserInterviewStepNumber
} from './UserInterviewInterfaces';
import React, { useEffect, useState } from 'react';
import {
	clientJourneyCreateSettings,
	newclientaction
} from '../../../business/customer/customer-settings-actions';
import {
	clientJourneyPostCreateDietPlanAndRegisterUser,
	clientJourneyPutServingPreferences,
	updateUserInterview
} from '../../../business/diet/diet-preferences-action';
import {
	dietStartDateSelector,
	getCustomerBasicInformation,
	getCustomerTrainingSettings,
	getTagsMealsForCustomer
} from '../../../business/customer/customer-settings-selector';
import {
	getDietDayForTwoDays,
	getExclusions,
	getHaveExclusion,
	getLunchAndDinnerSameMeal,
	getNumberOfServingsPerDay,
	getSettings,
	getmainMealForTwoDays
} from '../../../business/diet/diet-preferences-selector';
import { useDispatch, useSelector } from 'react-redux';

export const UserInterview: React.FC<IUserInterviewProps> = ({ mode }: IUserInterviewProps) => {
	const dispatch = useDispatch();
	const [currentStep, setCurrentStep] = useState<UserInterviewStepNumber>(1);

	const customerBasicSettings = useSelector(getCustomerBasicInformation);
	const dietSettings = useSelector(getSettings);
	const trainingSettings = useSelector(getCustomerTrainingSettings);
	const haveExclusions = useSelector(getHaveExclusion);
	const exclusions = useSelector(getExclusions) ?? [];
	const numberOfServingsPerDay = useSelector(getNumberOfServingsPerDay);
	const tagsMealsForCustomer = useSelector(getTagsMealsForCustomer);
	const lunchAndDinnerSameMeal = useSelector(getLunchAndDinnerSameMeal);
	const mainMealForTwoDays = useSelector(getmainMealForTwoDays);
	const dietDayForTwoDays = useSelector(getDietDayForTwoDays);
	const dietDateStartUtc = useSelector(dietStartDateSelector);

	const { dietId } = useParams<{ dietId?: string }>();

	let currentStepTimer: NodeJS.Timeout;

	const handleClickNext = () => {
		const nextStep = currentStep + 1;
		scrollToStep(nextStep);
	};

	useEffect(() => {
		if (dietId) dispatch(clientJourneyCreateSettings(+dietId));
	}, [dietId]);

	const scrollToStep = (step: number) => {
		setCurrentStep(step as UserInterviewStepNumber);
		const element = document.getElementById(`step0${step}`);
		setTimeout(function () {
			element?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
		}, 1);
	};

	const goPageBack = () => {
		dispatch(navigationAction.goBack());
	};

	const handleClickPrevious = () => {
		const previousStep = currentStep - 1;
		scrollToStep(previousStep);
	};

	const generateDietPlan = async () => {
		await dispatch(clientJourneyPostCreateDietPlanAndRegisterUser());
	};

	const observer = new IntersectionObserver(
		function (entries) {
			if (entries[0].isIntersecting) {
				if (currentStepTimer) clearTimeout(currentStepTimer);
				currentStepTimer = setTimeout(() => {
					const number = entries[0].target.id.replace('step0', '');
					setCurrentStep(+number as UserInterviewStepNumber);
				}, 3000);
			}
		},
		{ threshold: [0.7], root: document.querySelector('.UserInterview__content') }
	);

	document.querySelectorAll('.WizardCard').forEach((element) => {
		if (element) {
			observer.observe(element);
		}
	});

	const initialValues = {
		step1: {
			age: customerBasicSettings.yearOfBirth
				? moment().year() - customerBasicSettings.yearOfBirth
				: undefined,
			gender:
				customerBasicSettings.gender === Gender.Male
					? 'Male'
					: customerBasicSettings.gender === Gender.Female
					? 'Female'
					: undefined,
			height: customerBasicSettings.height ?? undefined,
			weight: customerBasicSettings.weight ?? undefined
		} as IBasicSettings01StepFormValues,
		step2: {
			dietObjective: dietObjectiveEnumToString(dietSettings.dietObjective),
			haveTrainings: isEmptyNullOrUndefined(trainingSettings.sportMode)
				? undefined
				: trainingSettings.sportMode
				? 'Yes'
				: 'No',
			friday: trainingSettings.friday,
			monday: trainingSettings.monday,
			saturday: trainingSettings.saturday,
			sunday: trainingSettings.sunday,
			thursday: trainingSettings.thursday,
			tuesday: trainingSettings.tuesday,
			wednesday: trainingSettings.wednesday
		} as IDietObjective02StepFormValues,
		step3: {
			exclusions: exclusions,
			haveExclusions: isEmptyNullOrUndefined(haveExclusions)
				? undefined
				: haveExclusions
				? 'Yes'
				: 'No'
		} as IExclusionOfFoodProduct03StepFormValues,
		step4: {
			mealsCount: isEmptyNullOrUndefined(numberOfServingsPerDay)
				? undefined
				: numberOfServingsPerDay?.toString(),
			tags: tagsMealsForCustomer
		} as IDietPlanPreferences04StepFormValues,
		step5: {
			dietDayForTwoDays: isEmptyNullOrUndefined(dietDayForTwoDays)
				? undefined
				: dietDayForTwoDays
				? 'Yes'
				: 'No',
			lunchAndDinnerSameMeal: isEmptyNullOrUndefined(lunchAndDinnerSameMeal)
				? undefined
				: lunchAndDinnerSameMeal
				? 'Yes'
				: 'No',
			mainMealForTwoDays: isEmptyNullOrUndefined(mainMealForTwoDays)
				? undefined
				: mainMealForTwoDays
				? 'Yes'
				: 'No',
			dietDateStartUtc: dietDateStartUtc
		} as IFoodServingDetails05StepFormValues
	} as IUserInterviewValues;

	const [isInitialValid, initialErrors] = useInitialErrorsSync(schema, initialValues);

	const onSubmit = async (
		values: IUserInterviewValues,
		helpers: FormikHelpers<IUserInterviewValues>
	) => {
		if (mode === 'new-customer') {
			await dispatch(
				newclientaction(
					{
						gender: values.step1.gender === 'Male' ? Gender.Male : Gender.Female,
						height: values.step1.height,
						weight: values.step1.weight,
						yearOfBirth: values.step1.age
							? moment().year() - values.step1.age
							: undefined
					},
					dietObjectiveStringToEnum(values.step2.dietObjective)!,
					{
						wantUseSportMode: values.step2.haveTrainings === 'Yes',
						monday: values.step2.monday,
						tuesday: values.step2.tuesday,
						wednesday: values.step2.wednesday,
						thursday: values.step2.thursday,
						friday: values.step2.friday,
						saturday: values.step2.saturday,
						sunday: values.step2.sunday
					},
					values.step3.haveExclusions === 'Yes',
					{
						lunchAndDinnerSameMeal:
							values.step5.lunchAndDinnerSameMeal &&
							values.step5.lunchAndDinnerSameMeal === 'Yes',
						mainDishForTwoDays:
							values.step5.mainMealForTwoDays &&
							values.step5.mainMealForTwoDays === 'Yes',
						dietDayForTwoDays:
							values.step5.dietDayForTwoDays &&
							values.step5.dietDayForTwoDays === 'Yes'
					}
				)
			);
		} else {
			dispatch(updateUserInterview(values));
			helpers.resetForm({ values: values });
		}
	};
	return (
		<Formik
			initialValues={initialValues}
			validationSchema={schema}
			isInitialValid={isInitialValid}
			initialErrors={initialErrors}
			onSubmit={onSubmit}
		>
			{(formik) => {
				if (mode === 'diet-settings' && !isInitialValid) {
					useEffect(() => {
						formik.setTouched(setNestedObjectValues(formik.errors, true));
					}, []);
				}

				const mealsCount = formik.values.step4.mealsCount;

				useEffect(() => {
					if (mealsCount) {
						if (mode === 'new-customer') {
							dispatch(
								clientJourneyPutServingPreferences(
									{
										numberOfServingsPerDay: +mealsCount
									},
									formik.setFieldValue
								)
							);
						} else {
							switch (+mealsCount as AvailableNumbersOfMeals) {
								case 4: {
									formik.setFieldValue(
										'step4.tags',
										formik.values.step4.tags?.map(
											(x: TagsMealsForDietServings) => {
												return {
													...x,
													mealTypeTags: x.mealTypeTags?.filter(
														(x) => x.mealTypeId !== MealTypeEnum.Supper
													)
												} as TagsMealsForDietServings;
											}
										)
									);
									break;
								}
								case 5: {
									formik.setFieldValue(
										'step4.tags',
										formik.values.step4.tags?.map(
											(x: TagsMealsForDietServings) => {
												return {
													...x,
													mealTypeTags: x.mealTypeTags?.find(
														(y: MealTypeTag) =>
															y.mealTypeId === MealTypeEnum.Supper
													)
														? x.mealTypeTags
														: [
																...(x.mealTypeTags ?? []),
																{
																	mealTypeId: MealTypeEnum.Supper,
																	mealTypeName:
																		MealTypeEnum[
																			MealTypeEnum.Supper
																		],
																	selected: true,
																	tagsMealsForDietServingsId:
																		x.tagId
																} as MealTypeTag
														  ]
												} as TagsMealsForDietServings;
											}
										)
									);
									break;
								}
							}
						}
					}
				}, [mealsCount]);
				return (
					<Form className="UserInterview">
						<div className="UserInterview__content">
							<div className="UserInterview__content-header">
								<div className="UserInterview__content-header-left">
									<BackLink
										text="Powrót do poprzedniej strony"
										onClick={goPageBack}
									/>
									<Headline
										align="left"
										text={
											mode === 'new-customer'
												? 'Generuj okres testowy'
												: 'Ustawienia diety'
										}
										subHeadline="Uzupełnij dane poniżej"
										// todo: "any" do podmiany po poprawie typów na backendzie
										badge={{
											text: (customerBasicSettings as any)
												.customerDietPrefence?.dietType.name,
											type: 'default'
										}}
									/>
								</div>
								<div className="UserInterview__content-header-right">
									<UserInterviewCompletionStatus
										onStepIconClick={scrollToStep}
										formik={formik}
									/>
									<ActionButton
										type="submit"
										className="style-desktop"
										text={
											mode === 'new-customer'
												? 'Generuj plan diety'
												: 'Zapisz'
										}
										disabled={
											!formik.isValid ||
											(mode === 'diet-settings' && !formik.dirty)
										}
									/>
								</div>
							</div>

							<div className="hs full">
								<BasicSettings01StepForm
									scrollToStep={scrollToStep}
									mode={mode}
									formik={formik}
								/>
								<DietObjective02StepForm
									scrollToStep={scrollToStep}
									mode={mode}
									formik={formik}
								/>
								<ExclusionOfFoodProduct03StepForm
									scrollToStep={scrollToStep}
									mode={mode}
									formik={formik}
								/>
								<DietPlanPreferences04StepForm
									scrollToStep={scrollToStep}
									mode={mode}
									formik={formik}
								/>
								<FoodServingDetails05StepForm
									scrollToStep={scrollToStep}
									mode={mode}
									formik={formik}
								/>
							</div>
						</div>
						<div
							className={`UserInterview__confirmation-box ${
								currentStep !== 1 && mode === 'new-customer'
									? ''
									: 'UserInterview__confirmation-box--align-center'
							} style-mobile`}
						>
							{currentStep !== 1 && mode === 'new-customer' && (
								<ActionLink
									className="style-mobile"
									text="Poprzedni krok"
									textColor={primaryOrangeColor}
									onClick={handleClickPrevious}
								/>
							)}
							<ActionButton
								type={
									mode === 'new-customer'
										? currentStep === 5
											? 'submit'
											: 'button'
										: 'submit'
								}
								text={
									mode === 'new-customer'
										? currentStep === 5
											? 'Generuj plan diety'
											: 'Następny krok'
										: 'Zapisz'
								}
								disabled={
									mode === 'new-customer'
										? currentStep === 5 && !formik.isValid
										: !formik.dirty || !formik.isValid
								}
								onClick={
									mode === 'new-customer' && currentStep !== 5
										? handleClickNext
										: undefined
								}
							/>
						</div>
					</Form>
				);
			}}
		</Formik>
	);
};
