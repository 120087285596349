import './CopyMeal.scss';

import { ActionButton } from '../../../atoms/ActionButton/ActionButton';
import { AddToastPayload } from 'react-redux-toastr';
import { DatePicker } from '../../../atoms/datePickers/DatePicker/DatePicker';
import { DropdownList } from '../../../atoms/DropdownList/DropdownList';
import { ICurrentMeals } from '../Meal/meal-interfaces';
import { ISearchItem } from '../../../atoms/search-box/search-box';
import { List } from 'immutable';
import { MealTypeEnum } from '../../../../client';
import React from 'react';
import { TextBox } from '../../../atoms/TextBox/TextBox';
import moment from 'moment';

import { getFriendlyMealName, isEmptyNullOrUndefined } from '../../../../shared/helpers';

interface ICopyMealComponentProps {
	maxDateUtc: Date;
	minDateUtc: Date;
	currentDietPlanDayUtc: Date;
	userMealTypes: List<MealTypeEnum>;
	currentMeals: ICurrentMeals;
	CopyMeals(mealType: MealTypeEnum, toDay: Date): void;
	onCloseClicked(path: string, state?: any): void;
	toast: (message: AddToastPayload) => void;
}

type ICopyMealComponentState = {
	mealType: MealTypeEnum;
	dateUtc: Date;
	showCalendar: boolean;
};

export class CopyMealComponent extends React.Component<
	ICopyMealComponentProps,
	ICopyMealComponentState
> {
	constructor(props: ICopyMealComponentProps, state: ICopyMealComponentState) {
		super(props, state);

		this.state = this.getInitialState();
	}

	public render(): JSX.Element {
		const isModelValid = this.isModelValid();
		const items = this.props.userMealTypes
			.map((x) => {
				return {
					id: +x,
					display: getFriendlyMealName(x),
					value: x,
					disabled:
						moment(this.state.dateUtc).isSame(
							moment(this.props.currentDietPlanDayUtc),
							'date'
						) && x === this.props.currentMeals.mealType
				} as ISearchItem;
			})
			.toArray();
		return (
			<div className="page exchangeMeal_main">
				<div className="exchangeMeal_header">
					<p className="header">Gdzie chcesz skopiować posiłek?</p>
				</div>
				<div style={{ position: 'relative' }}>
					<label className="textBox_label">Do dnia</label>
					<TextBox
						name="textboxFromDate"
						onClick={this.handleCalendarClick}
						value={moment(this.state.dateUtc).format('YYYY-MM-DD').toLocaleString()}
					/>
					<DatePicker
						minDateUtc={this.props.minDateUtc}
						maxDateUtc={this.props.maxDateUtc}
						handleChange={this.handleChangeDate}
						dateUtc={this.state.dateUtc}
						showCalendar={this.state.showCalendar}
						onShowStateChange={this.handleClickDatePicker}
					/>
				</div>

				<div className="copyMeal_dropdown copyMeal_row">
					<DropdownList
						label="Posiłek"
						value={items.find((x) => x.id === +this.state.mealType)}
						items={items}
						onSelect={this.handleSelect}
					/>
				</div>
				<ActionButton
					text="Kopiuj posiłek"
					disabled={!isModelValid}
					onClick={this.handleCopyClicked}
					className={`${isModelValid ? '' : 'disabled'} copyMeal_action-button`}
				/>
			</div>
		);
	}
	private handleClickDatePicker = () => {
		this.setState({ showCalendar: false });
	};

	private handleCalendarClick = () => {
		this.setState({ ...this.state, showCalendar: !this.state.showCalendar });
	};

	private handleChangeDate = (date: Date) => {
		if (
			moment(date).isSame(moment(this.props.currentDietPlanDayUtc), 'date') &&
			this.state.mealType === this.props.currentMeals.mealType
		) {
			this.props.toast({
				message:
					'Nie możesz skopiować posiłku do tego samego rodzaju i dnia. Zmień rodzaj posiłku lub wybierz inną datę.',
				type: 'warning'
			});
		} else {
			this.setState({ ...this.state, dateUtc: date, showCalendar: false });
		}
	};

	private handleCopyClicked = (): void => {
		this.props.CopyMeals(this.state.mealType, this.state.dateUtc);
		document.dispatchEvent(new CustomEvent('closeModal', {}));
	};

	private handleSelect = (item: ISearchItem): void => {
		this.setState({ ...this.state, mealType: item.value });
	};

	private getInitialState = (): ICopyMealComponentState => {
		return {
			mealType: this.props.currentMeals.mealType,
			showCalendar: false,
			dateUtc: moment(this.props.currentDietPlanDayUtc).add(1, 'day').toDate()
		} as ICopyMealComponentState;
	};

	private isModelValid = (): boolean => {
		return !isEmptyNullOrUndefined(this.state.dateUtc);
	};
}
