import { PutInfoAboutYourself } from '../../client';
import { RootState } from '../root-store';
import { createSelector } from 'reselect';
import moment from 'moment';

import {
	getNewCustomerStep02_2Status,
	getNewCustomerStep03Status,
	getNewCustomerStep04Status,
	getNewCustomerStep05Status
} from '../diet/diet-preferences-selector';

export const getCustomerSettings = (state: RootState) => state.administration.customerSettings;
export const getCustomerBasicInformation = (state: RootState) =>
	state.administration.customerSettings.customerBasicInformation;
export const getCustomerSubscriptions = (state: RootState) =>
	state.administration.customerSettings.subscriptions;
export const getCustomerTrainingSettings = (state: RootState) =>
	state.administration.customerSettings.customerTrainingPreferences;
export const getTagsMealsForCustomer = (state: RootState) =>
	state.administration.customerSettings.tagsMealsForCustomer;

export const getCustomerSettingsId = createSelector(getCustomerSettings, (settings) =>
	(settings!.customerBasicInformation.settingsId || '').trim()
);
export const dietStartDateSelector = createSelector(getCustomerSettings, (settings) =>
	settings.dietStartUtc
		? moment.utc(settings.dietStartUtc).toDate()
		: moment().add(1, 'day').startOf('day').toDate()
);

export const getCustomerId = createSelector(
	getCustomerSettings,
	(settings): number | undefined => settings.customerId
);
export const getCustomerSelector = createSelector(
	getCustomerBasicInformation,
	(settings) => settings.customer
);

export const getCustomerAboutYourself = createSelector(getCustomerBasicInformation, (settings) => {
	return {
		gender: settings.gender,
		height: settings.height!,
		settingsId: settings.settingsId,
		weight: settings.weight!,
		yearOfBirth: settings.yearOfBirth!
	} as PutInfoAboutYourself;
});

export const getNewCustomerStep01Status = createSelector(getCustomerSettings, (settings) => {
	return (
		settings!.customerBasicInformation?.settingsId !== null &&
		settings!.customerBasicInformation?.settingsId !== undefined &&
		settings!.customerBasicInformation?.weight !== null &&
		settings!.customerBasicInformation?.weight !== undefined &&
		settings!.customerBasicInformation?.height !== null &&
		settings!.customerBasicInformation?.height !== undefined &&
		settings!.customerBasicInformation?.yearOfBirth !== null &&
		settings!.customerBasicInformation?.yearOfBirth !== undefined &&
		settings!.customerBasicInformation?.gender !== null &&
		settings!.customerBasicInformation?.gender !== undefined
	);
});

export const getNewCustomerStep02Status = createSelector(
	getCustomerSettings,
	(settings) => settings!.dietObjective !== null && settings!.dietObjective !== undefined
);

export const getHaveTrainingSettingsSelector = createSelector(
	getCustomerTrainingSettings,
	(x) => x.sportMode
);

export const getMondayTrainingSettings = createSelector(getCustomerTrainingSettings, (x) =>
	!(x == null || x == undefined) ? x.monday : 0
);
export const getTuesdayTrainingSettings = createSelector(getCustomerTrainingSettings, (x) =>
	!(x == null || x == undefined) ? x.tuesday : 0
);
export const getWednesdayTrainingSettings = createSelector(getCustomerTrainingSettings, (x) =>
	!(x == null || x == undefined) ? x.wednesday : 0
);
export const getThursdayTrainingSettings = createSelector(getCustomerTrainingSettings, (x) =>
	!(x == null || x == undefined) ? x.thursday : 0
);
export const getFridayTrainingSettings = createSelector(getCustomerTrainingSettings, (x) =>
	!(x == null || x == undefined) ? x.friday : 0
);
export const getSaturdayTrainingSettings = createSelector(getCustomerTrainingSettings, (x) =>
	!(x == null || x == undefined) ? x.saturday : 0
);
export const getSundayTrainingSettings = createSelector(getCustomerTrainingSettings, (x) =>
	!(x == null || x == undefined) ? x.sunday : 0
);

export const lastCompletedStepSelector = createSelector(
	getNewCustomerStep01Status,
	getNewCustomerStep02Status,
	getNewCustomerStep02_2Status,
	getNewCustomerStep03Status,
	getNewCustomerStep04Status,
	getNewCustomerStep05Status,
	(
		getNewCustomerStep01Status,
		getNewCustomerStep02Status,
		getNewCustomerStep02_2Status,
		getNewCustomerStep03Status,
		getNewCustomerStep04Status,
		getNewCustomerStep05Status
	) => {
		if (getNewCustomerStep05Status) {
			return 5;
		} else if (getNewCustomerStep04Status) {
			return 4;
		} else if (getNewCustomerStep03Status) {
			return 3;
		} else if (getNewCustomerStep02Status && getNewCustomerStep02_2Status) {
			return 2;
		} else if (getNewCustomerStep01Status) {
			return 1;
		}
	}
);

export const isAllStepsCompletedSelector = createSelector(
	getNewCustomerStep01Status,
	getNewCustomerStep02Status,
	getNewCustomerStep02_2Status,
	getNewCustomerStep03Status,
	getNewCustomerStep04Status,
	getNewCustomerStep05Status,
	(
		getNewCustomerStep01Status,
		getNewCustomerStep02Status,
		getNewCustomerStep02_2Status,
		getNewCustomerStep03Status,
		getNewCustomerStep04Status,
		getNewCustomerStep05Status
	) =>
		getNewCustomerStep01Status &&
		getNewCustomerStep02Status &&
		getNewCustomerStep02_2Status &&
		getNewCustomerStep03Status &&
		getNewCustomerStep04Status &&
		getNewCustomerStep05Status
);
