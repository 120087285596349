import '../auth.scss';

import * as Yup from 'yup';

import { ActionButton } from '../../../atoms/ActionButton/ActionButton';
import ActionLink from '../../../atoms/action-link/action-link';
import { CheckBox } from '../../../atoms/CheckBox/CheckBox';
import { TextBox } from '../../../atoms/TextBox/TextBox';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';

import { Field, Form, Formik, FormikHelpers } from 'formik';
import { IRegisterFormProps, IRegisterFormValues } from './RegisterFormInterfaces';
import { createUrlWithParams, routes } from '../../../../business/router/path-definition';
import { documentsUrls, primaryOrangeColor } from '../../../../shared/shared';

const initialValues: IRegisterFormValues = {
	mail: '',
	password: '',
	repeatPassword: '',
	acceptedTerms: false
};

export const RegisterForm = ({ handleError, handleRegisterConfirmed }: IRegisterFormProps) => {
	const dispatch = useDispatch();
	const handleClickTerms = (): void => {
		dispatch(
			push(
				createUrlWithParams(routes.static.document.path, {
					documentUrl: documentsUrls.tos
				})
			)
		);
	};
	const onSubmit = (values: IRegisterFormValues, helpers: FormikHelpers<IRegisterFormValues>) => {
		handleRegisterConfirmed({ login: values.mail, password: values.password });
		setTimeout(() => helpers.setSubmitting(false), 2000);
	};
	return (
		<Formik
			initialValues={initialValues}
			validationSchema={Yup.object<Partial<Record<keyof IRegisterFormValues, Yup.AnySchema>>>(
				{
					mail: Yup.string().email('Niepoprawny adres email').required('Wymagane'),
					password: Yup.string()
						.min(3, 'Co najmniej 3 znaki')
						.max(30, 'Nie więcej niż 30 znaków')
						.required('Wymagane'),
					repeatPassword: Yup.string()
						.oneOf([Yup.ref('password'), null], 'Hasła muszą być takie same')
						.required('Wymagane'),
					acceptedTerms: Yup.boolean().oneOf([true], 'Wymagane')
				}
			)}
			onSubmit={onSubmit}
		>
			{(formik) => (
				<Form>
					<Field
						name="mail"
						label="E-Mail"
						placeholder="np. jan.kowalski@kontakt.com"
						className="AuthRegister__form-row"
						errorString={formik.touched.mail && formik.errors.mail}
						as={TextBox}
					/>
					<Field
						name="password"
						label="Hasło"
						type="password"
						placeholder="Ustal silne hasło"
						className="AuthRegister__form-row"
						errorString={formik.touched.password && formik.errors.password}
						as={TextBox}
					/>
					<Field
						name="repeatPassword"
						label="Powtórz hasło"
						type="password"
						placeholder="Hasła muszą być takie same"
						className="AuthRegister__form-row"
						errorString={formik.touched.repeatPassword && formik.errors.repeatPassword}
						as={TextBox}
					/>
					<div className="AuthRegister_terms">
						<Field
							name="acceptedTerms"
							type="checkbox"
							as={CheckBox}
							errorString={
								formik.touched.acceptedTerms && formik.errors.acceptedTerms
							}
							displayName={
								<span>
									Podane dane osobowe będą przetwarzane w celu kontaktu i obsługi
									Państwa zgłoszenia. Więcej informacji o przetwarzaniu danych
									osobowych:
									<ActionLink
										textColor={primaryOrangeColor}
										text="Polityka Prywatności"
										onClick={handleClickTerms}
									/>
									.
								</span>
							}
							disableDisplayNameChangeStyle
						/>
					</div>
					<ActionButton
						disabled={!formik.isValid}
						errorString={formik.isValid ? undefined : 'Sprawdź błędy w formularzu'}
						type="submit"
						className="AuthRegister__button"
						text="Stwórz nowe konto"
					/>
				</Form>
			)}
		</Formik>
	);
};
