import { FileApi } from '../../../../files-client';
import { RootState } from '../../../../business/root-store';
import { ThunkAction } from 'redux-thunk';
import { actions } from 'react-redux-toastr';
import { documentsUrls } from '../../../../shared/shared';
import { getPartnershipRestaurantDocuments } from '../PartnershipInitial/PartnershipInitialEpics';

import { AuxiliaryApi, Meal, Restaurant } from '../../../../client';
import { GetApiConfig, GetFileConfig } from '../../../../business/api-client-config';
import {
	partnershipDocumentFetched,
	partnershipRestaurantFetched,
	partnershipRestaurantMealDeleted,
	partnershipRestaurantMealFetched
} from '../../../../business/Partnership/PartnershipActions';

export const downloadDocuments =
	(documentGuid: string, fileName: string): ThunkAction<any, RootState, any, any> =>
	(dispatch, getState) => {
		const store: RootState = getState();
		const api = new FileApi(GetFileConfig(store));
		api.pullFile(documentGuid, undefined, { responseType: 'blob' }).then((x) => {
			if (x.data) {
				const url = window.URL.createObjectURL(new Blob([x.data]));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', fileName);
				document.body.appendChild(link);
				link.click();
			}
		});
	};

export const sendDocuments =
	(file: File, restaurant: Restaurant): ThunkAction<any, RootState, any, any> =>
	(dispatch, getState) => {
		const store: RootState = getState();
		const auxApi = new AuxiliaryApi(GetApiConfig(store));
		const fileApi = new FileApi(GetFileConfig(store));
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => {
			if (reader.result) {
				let encoded = reader.result.toString().replace(/^data:(.*,)?/, '');
				if (encoded.length % 4 > 0) {
					encoded += '='.repeat(4 - (encoded.length % 4));
				}
				fileApi
					.postFile({
						catalogId: restaurant.documentsFileCatalogId,
						file: encoded,
						name: file.name
					})
					.then((fileReponse) => {
						auxApi
							.postRestaurantDocuments({
								restaurantId: restaurant.id,
								signedDocument: encoded,
								fileName: file.name
							})
							.then((x) => {
								dispatch(partnershipRestaurantFetched(x.data.restaurant!));
								dispatch(
									getPartnershipDocumentByUrl(
										documentsUrls.partnership.documentsWait
									)
								);
								dispatch(getPartnershipRestaurantDocuments());
							});
					});
			}
		};
	};
export const getPartnershipDocumentByUrl =
	(documentName: string): ThunkAction<any, RootState, any, any> =>
	(dispatch, getState) => {
		const state = getState();
		const api = new AuxiliaryApi(GetApiConfig(state));
		api.getDocumentByUrl(documentName).then((x) => {
			dispatch(partnershipDocumentFetched(x.data.document!));
		});
	};

export const enableOrDisableRestaurant =
	(): ThunkAction<any, RootState, any, any> => (dispatch, getState) => {
		const state = getState();
		const api = new AuxiliaryApi(GetApiConfig(state));
		api.enableOrDisableRestaurant(state.administration.partnership.restaurant.id!).then((x) => {
			dispatch(partnershipRestaurantFetched(x.data.restaurant!));
		});
	};

export const enableOrDisableRestaurantMeal =
	(mealId: number): ThunkAction<any, RootState, any, any> =>
	(dispatch, getState) => {
		const state = getState();
		const api = new AuxiliaryApi(GetApiConfig(state));
		api.enableOrDisableRestaurantMeal(mealId).then((x) => {
			dispatch(partnershipRestaurantMealFetched(x.data.meal!));
		});
	};

export const updateRestaurantLocation =
	(lat: number, lng: number): ThunkAction<any, RootState, any, any> =>
	(dispatch, getState) => {
		const state = getState();
		const api = new AuxiliaryApi(GetApiConfig(state));
		api.postRestaurantLocation(lat, lng).then((x) => {
			dispatch(partnershipRestaurantFetched(x.data.restaurant!));
		});
	};
export const deleteRestaurantMeal =
	(id: number): ThunkAction<any, RootState, any, any> =>
	(dispatch, getState) => {
		const state = getState();
		const api = new AuxiliaryApi(GetApiConfig(state));
		api.deleteRestaurantMeal(id).then((x) => {
			dispatch(partnershipRestaurantMealDeleted(id));
		});
	};

export const addOrUpdateRestaurantMeal =
	(meal: Meal): ThunkAction<any, RootState, any, any> =>
	(dispatch, getState) => {
		const state = getState();
		const api = new AuxiliaryApi(GetApiConfig(state));
		meal.dietDefinitionId = 1; //todo
		meal.restaurantId = state.administration.partnership.restaurant.id;
		api.addOrUpdateRestaurantMeal({ meal: meal }).then((x) => {
			dispatch(partnershipRestaurantMealFetched(x.data.meal!));
			dispatch(actions.add({ message: 'Udało się zapisać posiłek', type: 'success' }));
		});
	};
