import './Stepper.scss';

import React from 'react';

import { IStepIconProps, StepIcon } from './StepIcon/StepIcon';

interface INewCustomerCompletionStatusProps {
	stepList: IStepIconProps[];
}

export const Stepper: React.FC<INewCustomerCompletionStatusProps> = (props) => {
	return (
		<div className="stepper-wrapper">
			{props.stepList.map((step) => {
				return (
					<StepIcon key={step.id ?? step.navigationElementId ?? step.label} {...step} />
				);
			})}
		</div>
	);
};
