import './meal-detail-card.scss';

import { BackLink } from '../../../../../atoms/back-link/back-link';
import { CommentsRendererComponent } from '../CommentsRendererComponent/CommentsRendererComponent';
import { DietPlanDayMeal } from '../../../../../../client';
import { FavouriteIcon } from '../../../../../../shared/assets/favourite-icon';
import { IngredientsRendererComponent } from '../IngredientsRendererComponent';
import { NutritionFactSummary } from '../../../../nutrition-fact-summary/nutrition-fact-summary';
import { PreparationRendererComponent } from '../PreparationRendererComponent';
import { getCommentsImage } from '../../../../../../business/diet/diet-selector';
import { getCurrentMealDetailsSelector } from '../../meal-selectors';
import imagePlaceholder from '../../../../../../shared/assets/svg/image-placeholder.svg';
import { routes } from '../../../../../../business/router/path-definition';
import { setFavouriteMeal } from '../../meal-epics';
import { useHistory } from 'react-router-dom';
import {
	getFriendlyMealName,
	getFriendlyMonthName,
	getNutritionFactsFromDietPlanDayMeals
} from '../../../../../../shared/helpers';
import { postMealComment, postMealImageComment } from '../../MealDetails/MealDetailsEpics';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

export const MealDetailCard = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const mealWithImage = useSelector(getCurrentMealDetailsSelector);
	const commentFiles = useSelector(getCommentsImage);
	const [comment, setComment] = useState('');

	const handleOnBackClicked = (): void => {
		history.push(routes.home.path);
	};

	const handleCommentAdded = (): void => {
		dispatch(postMealComment(comment, mealWithImage?.dietPlanDayMeal.mealId!));
	};

	const handleFileAdded = (file: File): void => {
		dispatch(postMealImageComment(file, mealWithImage?.dietPlanDayMeal.mealId!));
	};

	const handleSetFavouriteMeal = () => {
		dispatch(setFavouriteMeal(mealWithImage?.dietPlanDayMeal.mealId!));
	};

	const summary = mealWithImage
		? getNutritionFactsFromDietPlanDayMeals([mealWithImage.dietPlanDayMeal])
		: {};

	const formatMealServingDay = (day: Date, dietPlanDayMeal: DietPlanDayMeal): string => {
		return (
			new Date(day).getDay() +
			' ' +
			getFriendlyMonthName(day) +
			' / ' +
			getFriendlyMealName(dietPlanDayMeal.mealType!) +
			' / ' +
			dietPlanDayMeal.meal?.name
		);
	};

	useEffect(() => {
		if (!mealWithImage) {
			history.push(routes.home.path);
		}
	}, [mealWithImage]);

	if (!mealWithImage) return null;

	return (
		<div id="meal-details-card">
			<div className="meal-details__header">
				<div>
					<BackLink text="Powrót do jadłospisu" onClick={handleOnBackClicked} />
					<h3 className="mealDetails_header">
						{mealWithImage?.dietPlanDayMeal.meal?.name}
					</h3>
					<h5>
						{formatMealServingDay(
							new Date(mealWithImage.dietPlanDayMeal.dietDayId!),
							mealWithImage.dietPlanDayMeal
						)}
					</h5>
				</div>
				<NutritionFactSummary
					summary={summary}
					className="meal-details__nutrition-summary"
				/>
			</div>
			<div className="meal-details__container">
				<div className="meal-details__favourite">
					<FavouriteIcon
						id="favouriteIcon"
						isLiked={mealWithImage.dietPlanDayMeal.isFavourite!}
						onClick={handleSetFavouriteMeal}
					/>
				</div>
				<div className="meal-details__summary-view">
					<div
						className="meal-details__image"
						style={{ backgroundImage: `url(${mealWithImage.imageUrl})` }}
					>
						{!mealWithImage.imageUrl && (
							<img src={imagePlaceholder} alt="Brak zdjęcia" />
						)}
					</div>
					<div className="meal-details__recipe">
						<IngredientsRendererComponent mealWithImage={mealWithImage} />
						<PreparationRendererComponent mealWithImage={mealWithImage} />
					</div>
				</div>
				<CommentsRendererComponent
					mealWithImage={mealWithImage}
					commentFiles={commentFiles}
					comment={comment}
					handleCommentChanged={setComment}
					onFileAdded={handleFileAdded}
					handleCommentAdded={handleCommentAdded}
				/>
			</div>
		</div>
	);
};
