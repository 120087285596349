import { Reducer } from 'redux';
import { SpinnerAction } from './SpinnerActions';

export const INITIAL_STATE = {
	activeRequestsCount: false
} as SpinnerState;

export type SpinnerState = {
	activeRequestsCount: boolean;
};

export const SpinnerReducer: Reducer<SpinnerState, SpinnerAction> = (
	state = INITIAL_STATE,
	action
) => {
	switch (action.type) {
		case 'INCREMENT_ACTIVE_REQUESTS_COUNT': {
			return {
				...state,
				activeRequestsCount: true
			};
		}
		case 'DECREMENT_ACTIVE_REQUESTS_COUNT': {
			return {
				...state,
				activeRequestsCount: false
			};
		}
		default:
			return state;
	}
};
