import { DietAndPackageTypeCardComponent } from './DietAndPackageTypeCardComponent';
import { getDietPlanPrices } from '../../../molecules/staticData/staticDataSelectors';
import { getDietTypes } from '../../../../business/diet/diet-selector';
import { orderActions } from '../../../../business/order/order.actions';

import {
	getActiveDietPlanPrice,
	getActiveDietType
} from '../../../../business/order/order.selectors';
import { useDispatch, useSelector } from 'react-redux';

export const DietAndPackageTypeCardContainer = () => {
	const dispatch = useDispatch();

	const dietTypes = useSelector(getDietTypes);
	const dietPlanPrices = useSelector(getDietPlanPrices);
	const activeDietType = useSelector(getActiveDietType);
	const activeDietPlanPrice = useSelector(getActiveDietPlanPrice);

	const setActiveDietType = (typeId: number) => () => {
		const type = dietTypes.find((item) => item.id === typeId);
		if (type) dispatch(orderActions.onSetActiveDietType(type));
	};

	const setActiveDietPlan = (planId: number) => () => {
		const plan = dietPlanPrices?.find((item) => item.dietPlanDefinition.id === planId);
		if (plan) dispatch(orderActions.onSetActiveDietPlan(plan));
	};

	return (
		<DietAndPackageTypeCardComponent
			activeDietPlanPrice={activeDietPlanPrice}
			activeDietType={activeDietType}
			dietPlanPrices={dietPlanPrices}
			dietTypes={dietTypes}
			setActiveDietPlan={setActiveDietPlan}
			setActiveDietType={setActiveDietType}
		/>
	);
};
