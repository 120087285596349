import './Menu.scss';

import { DayMeals } from '../../../molecules/day-meals/day-meals-container';
import { DayPicker } from '../../../atoms/day-picker/DayPickerContainer';
import { NutritionFactDaySummary } from '../../../molecules/nutrition-fact-summary/nutrition-fact-day-summary/nutrition-fact-day-summary-container';
import { TrainingBar } from '../../../molecules/TrainingBar/TrainingBarContainer';
import { WaterBar } from '../../../molecules/Beverages/water-bar/water-bar-container';

export const MenuComponent = () => {
	return (
		//todo change classnames
		<div className="Menu">
			<div className="Menu_containerWithPadding style-mobile">
				<DayPicker />
			</div>
			<div className="Menu_header">
				<div className="style-desktop">
					<DayPicker />
				</div>
				<NutritionFactDaySummary />
				<div className="style-desktop">
					<TrainingBar />
				</div>
				<WaterBar />
			</div>
			<div className="Menu_containerWithPadding">
				<DayMeals />
				<div className="style-mobile">
					<TrainingBar />
				</div>
			</div>
		</div>
	);
};
