import { Reducer } from 'redux';

import { DietAction, dietActionName } from './diet-action';
import {
	DietDefinition,
	DietPlan,
	DietPlanDay,
	DietPlanDayMeal,
	DietPlanDefinition,
	ProductRefNamePair,
	Tag
} from '../../client';
import { FileInfo, FileMetadata } from '../../files-client';

export type DietState = {
	dietTypes: DietDefinition[];
	availableDietPlans?: DietPlanDefinition[];
	customerDietPlan?: DietPlan;
	dietDefinition?: DietDefinition;
	dietPlanDay: DietPlanDay;
	isDayPlanAvailable: boolean;

	commentFiles?: { catalogId: number; fileInfos: FileInfo[] };
	files: { catalogId: number; metadatas: FileMetadata[] }[];
	productRefs: ProductRefNamePair[];
	tagMealsForDietServings: Tag[];
};

export const INITIAL_STATE: DietState = {
	files: [],
	productRefs: [],
	availableDietPlans: [],
	isDayPlanAvailable: true,
	dietTypes: [
		{ id: 1, name: 'Standard' },
		{ id: 2, name: 'Veggie' },
		{ id: 3, name: 'Veggie + Fish & Seafood' }
	] as DietDefinition[],
	tagMealsForDietServings: [],
	dietPlanDay: {}
};

export const dietReducer: Reducer<DietState, DietAction> = (
	state = INITIAL_STATE,
	action: DietAction
): DietState => {
	switch (action.type) {
		case dietActionName.LOAD_AVAILABLE_DIET_PLANS: {
			return { ...state, availableDietPlans: action.payload.plans };
		}
		case dietActionName.LOAD_CUSTOMER_DIET_PLAN: {
			const { endDate } = action.payload.newValue;
			const isDayPlanAvailable = endDate ? new Date(endDate) > new Date() : false;

			return {
				...state,
				isDayPlanAvailable,
				customerDietPlan: action.payload.newValue
			};
		}
		case dietActionName.LOAD_CUSTOMER_SETTINGS_DIET_DEFINITION: {
			return {
				...state,
				dietDefinition: action.payload.dietDefinition
			};
		}
		case dietActionName.LOAD_CUSTOMER_ACTIVE_DIET_DAY: {
			return {
				...state,
				dietPlanDay: action.payload.newValue
			};
		}
		case dietActionName.LOAD_PREVIOUS_DAY: {
			const previousDay = state.customerDietPlan!.dietPlanDays!.filter(
				(x) => x!.dayDateUtc! < state!.dietPlanDay!.dayDateUtc!
			)[0];
			return {
				...state,
				dietPlanDay: previousDay
			};
		}
		case dietActionName.LOAD_NEXT_DAY: {
			const nextDay = state.customerDietPlan!.dietPlanDays!.filter(
				(x) => x!.dayDateUtc! > state!.dietPlanDay!.dayDateUtc!
			)[0];
			return {
				...state,
				dietPlanDay: nextDay
			};
		}
		case dietActionName.LOAD_PRODUCT_REF_NAME_PAIR: {
			return {
				...state,
				productRefs: action.payload.productsAndRefs
			};
		}
		case dietActionName.LOAD_TAG_MEALS_FOR_DIET_SERVING: {
			return {
				...state,
				tagMealsForDietServings: action.payload.tags
			};
		}
		case dietActionName.LOAD_FILE_METADATA: {
			const element = [
				{ catalogId: action.payload.catalogId, metadatas: action.payload.metadatas }
			];
			state!.files!.forEach((old) => {
				if (element.findIndex((o) => o.catalogId === old.catalogId) < 0) element.push(old);
			});
			return {
				...state,
				files: element
			};
		}
		case dietActionName.LOAD_COMMENTS_FILE: {
			return {
				...state,
				commentFiles: {
					catalogId: action.payload.catalogId,
					fileInfos: action.payload.fileInfos
				}
			};
		}
		case dietActionName.CLEAR_COMMENTS_FILE: {
			return {
				...state,
				commentFiles: undefined
			};
		}
		case dietActionName.PROCESS_MEAL_EATEN: {
			state.dietPlanDay!.dietPlanDayMeals!.filter(
				(s) => s.id === action.payload.dietPlanDayMealId
			)[0].isMealEaten = action.payload.isMealEaten;

			return {
				...state,
				dietPlanDay: state.dietPlanDay!
			};
		}
		case dietActionName.EXCHANGE_DIET_PLAN_DAY_MEAL: {
			const dietPlanDay = state.dietPlanDay!;
			const newDietPlanMeal = action.payload.dietPlanDayMeal;
			const newDietPlanDayMeals: DietPlanDayMeal[] = dietPlanDay.dietPlanDayMeals!.filter(
				(dietPlan: DietPlanDayMeal) => dietPlan.mealType === newDietPlanMeal.mealType
			);
			newDietPlanDayMeals.push(newDietPlanMeal);
			dietPlanDay.dietPlanDayMeals = newDietPlanDayMeals;
			return {
				...state,
				dietPlanDay
			};
		}
		default:
			return state;
	}
};
