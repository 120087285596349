import './PartnershipInitial.scss';

import { FileMetadata } from '../../../../files-client';
import { PartnerShipInitialForm } from './PartnershipInitialForm/PartnershipInitialForm';
import React from 'react';

import { Document, Restaurant } from '../../../../client';

interface IPartnershipInitialComponentProps {
	document: Document;
	restaurant: Restaurant;
	restaurantImages: FileMetadata[];
	disabled?: boolean;
	removePartnershipRestaurantImage: (fileGuid: string) => void;
	partnershipRestaurantFetched: (restaurant: Restaurant) => void;
}

interface IPartnershipInitialComponentState {}

export class PartnershipInitialComponent extends React.Component<
	IPartnershipInitialComponentProps,
	IPartnershipInitialComponentState
> {
	constructor(
		props: IPartnershipInitialComponentProps,
		state: IPartnershipInitialComponentState
	) {
		super(props, state);

		this.state = this.getInitialState();
	}

	public render(): JSX.Element {
		return (
			<div
				className={
					'PartnershipInitial ' +
					(this.props.restaurant.id ? 'PartnershipInitial_padding0' : undefined)
				}
			>
				{this.props.document?.createdDateUtc && !this.props.restaurant.id && (
					<div className="PartnershipInitial_document">
						<div className="line-decor line-decor--left"></div>
						<h1 className="document_topic">{this.props.document.title}</h1>
						<div
							dangerouslySetInnerHTML={{
								__html: this.props.document.documentData ?? ''
							}}
						></div>
					</div>
				)}
				<div className="PartnershipInitial_form">
					<div className="PartnershipInitial_form_content">
						<PartnerShipInitialForm
							// restaurant={this.props.restaurant}
							restaurantImages={this.props.restaurantImages}
							disabled={this.props.disabled}
							removePartnershipRestaurantImage={
								this.props.removePartnershipRestaurantImage
							}
							partnershipRestaurantFetched={this.props.partnershipRestaurantFetched}
						/>
					</div>
				</div>
			</div>
		);
	}

	private getInitialState = (): IPartnershipInitialComponentState => {
		return {} as IPartnershipInitialComponentState;
	};
}
