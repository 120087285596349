import './ProductPromoSliderItem.scss';

import { Headline } from '../../../../atoms/headline/headline';
import { IProductPromoSliderItemProps } from './ProductPromoSliderItemInterfaces';
import React from 'react';

export const ProductPromoSliderItem: React.FC<IProductPromoSliderItemProps> = ({
	description,
	title,
	buttonText = 'Sprawdź za darmo',
	image,
	...props
}) => {
	const navigateToJoinUs = (): void => {
		document.getElementById('join-us')!.scrollIntoView({ behavior: 'smooth' });
	};

	return (
		<div className="ProductPromoSliderItem">
			<div className="ProductPromoSliderItem__image style-desktop">
				<img src={image} alt={title} />
			</div>
			<div className="ProductPromoSliderItem__content-section">
				<div className="style-desktop">
					<Headline text={title} align="left" />
					<div className="line-decor line-decor--left style-desktop" />
				</div>
				<div className="style-mobile">
					<Headline text={title} />
					<div className="line-decor style-mobile" />
				</div>
				<div className="ProductPromoSliderItem_content">
					<p>{description}</p>
					<a className="ProductPromoSliderItem_content_link" onClick={navigateToJoinUs}>
						{buttonText}
					</a>
				</div>
			</div>
			<div className="ProductPromoSliderItem__image style-mobile">
				<img src={image} alt={title} />
			</div>
		</div>
	);
};
