import React from 'react';

export interface ISearchIconProps {
	className?: string;
}

export const SearchIcon: React.FC<ISearchIconProps> = (props) => {
	return (
		<svg
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
			className={props.className}
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M19.4864 18.0159L14.0455 12.5751C15.2331 11.0139 15.7834 9.05997 15.585 7.10851C15.3867 5.15705 14.4545 3.35379 12.977 2.06355C11.4996 0.773314 9.58724 0.0924282 7.62684 0.15865C5.66645 0.224872 3.80439 1.03326 2.41739 2.42026C1.03039 3.80726 0.222004 5.66932 0.155782 7.62971C0.0895596 9.59011 0.770445 11.5025 2.06068 12.9799C3.35092 14.4573 5.15419 15.3895 7.10564 15.5879C9.0571 15.7863 11.0111 15.236 12.5722 14.0484L18.013 19.4892C18.2101 19.6814 18.4744 19.789 18.7497 19.789C19.025 19.789 19.2893 19.6814 19.4864 19.4892C19.6816 19.2938 19.7912 19.0288 19.7912 18.7526C19.7912 18.4763 19.6816 18.2114 19.4864 18.0159ZM2.29136 7.91673C2.29136 6.80421 2.62126 5.71667 3.23934 4.79165C3.85743 3.86662 4.73593 3.14565 5.76377 2.71991C6.7916 2.29416 7.9226 2.18277 9.01374 2.39981C10.1049 2.61685 11.1072 3.15258 11.8938 3.93925C12.6805 4.72592 13.2162 5.7282 13.4333 6.81935C13.6503 7.91049 13.5389 9.04149 13.1132 10.0693C12.6874 11.0972 11.9665 11.9757 11.0414 12.5937C10.1164 13.2118 9.02888 13.5417 7.91636 13.5417C6.42506 13.54 4.99534 12.9468 3.94083 11.8923C2.88632 10.8377 2.29313 9.40803 2.29136 7.91673Z"
				fill="#B0B4C4"
			/>
		</svg>
	);
};
