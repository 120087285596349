import { GetApiConfig } from '../../../../business/api-client-config';
import { List } from 'immutable';
import { RootState } from '../../../../business/root-store';
import { ThunkAction } from 'redux-thunk';
import { getCurrentMealsSelector } from '../Meal/meal-selectors';
import { mealProposalsFetched } from './ExchangeMealActions';
import { setCurrentMeals } from '../Meal/meal';

import { DietApi, MealExchange } from '../../../../client';

export const getMealsProposals =
	(): ThunkAction<any, RootState, any, any> => (dispatch, getState) => {
		const state = getState();
		const api = new DietApi(GetApiConfig(state));
		const dietPlanId = state.diet.dietPlanDay!.dietPlanId!;
		const dietDay = state.diet.dietPlanDay!.dietDayId!;
		api.getMealsExchangeProposals(dietPlanId, dietDay).then((response) => {
			if (response.data.mealProposals) {
				dispatch(
					mealProposalsFetched({
						mealProposals: List<MealExchange>(response.data.mealProposals)
					})
				);
			}
		});
	};

export const fetchSelectedMeals =
	(ids: List<number>): ThunkAction<any, RootState, any, any> =>
	(dispatch, getState) => {
		const store = getState();
		const api = new DietApi(GetApiConfig(store));
		const currentMeals = getCurrentMealsSelector(store);
		api.getMeals(Array.from(ids))
			.then((response) => {
				dispatch(
					setCurrentMeals({
						...currentMeals,
						meals: response.data.meals ?? []
					})
				);
			})
			.catch((c) => {});
	};
