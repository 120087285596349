import React from 'react';

export const RefreshIcon: React.FC = (props) => {
	return (
		<React.Fragment>
			<svg
				width="13"
				height="13"
				viewBox="0 0 13 13"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M0.638556 3.29909C0.638556 3.29909 1.78097 1.53641 3.59021 0.59022C5.39779 -0.355973 7.62442 -0.0832567 9.08612 0.833004C10.5478 1.75093 11.0384 2.52418 11.0384 2.52418L12.2972 1.80747C12.2972 1.80747 12.5583 1.67776 12.5583 1.97875V6.48023C12.5583 6.48023 12.5583 6.88099 12.254 6.73798C11.9979 6.61659 9.23411 5.04514 8.36441 4.5496C7.8855 4.33675 8.30621 4.16214 8.30621 4.16214L9.52013 3.46871C9.52013 3.46871 8.8267 2.60067 7.81399 2.14171C6.72812 1.57632 5.71208 1.50981 4.46823 1.97875C3.65673 2.28472 2.70056 3.06961 2.01378 4.22866L0.638556 3.29909ZM11.9214 9.24897C11.9214 9.24897 10.779 11.0116 8.96971 11.9578C7.16213 12.904 4.93384 12.6313 3.47215 11.7134C2.01212 10.7971 1.5199 10.0222 1.5199 10.0222L0.261076 10.7389C0.261076 10.7389 0 10.8686 0 10.5677V6.06617C0 6.06617 0 5.66541 0.304312 5.80842C0.560399 5.92981 3.32415 7.50126 4.19385 7.9968C4.67276 8.20965 4.25205 8.3826 4.25205 8.3826L3.03813 9.07603C3.03813 9.07603 3.73156 9.94406 4.74427 10.403C5.83015 10.9684 6.84618 11.0349 8.09004 10.566C8.90153 10.26 9.8577 9.47512 10.5445 8.31608L11.9214 9.24897Z"
					fill="#BB6BD9"
				/>
			</svg>
		</React.Fragment>
	);
};
