import './order-menu-view.scss';

import { DietAndPackageTypeCardContainer } from './DietAndPackageTypeCardContainer';
import { DietSummaryContainer } from './DietSummaryContainer';
import { LoginCardContainer } from './LoginCardContainer/LoginCardContainer';
import { PaymentCardContainer } from './PaymentCardContainer';
import { Stepper } from '../../atoms/Stepper/Stepper';
import { orderActions } from '../../../business/order/order.actions';
import { routes } from '../../../business/router/path-definition';
import { useDietPlanSteps } from './useDietPlanSteps';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';

import { Redirect, Route, Switch } from 'react-router-dom';

export const OrderMenuView = () => {
	const dispatch = useDispatch();
	const { orderDietStepsList } = useDietPlanSteps();

	useEffect(() => {
		return () => {
			dispatch(orderActions.onSetInitialState());
		};
	}, []);

	return (
		<div id="order-menu-view">
			<div>
				<h2>Zamów jadłospis</h2>
				<Stepper stepList={orderDietStepsList} />
			</div>
			<div>
				<Switch>
					<Route
						path={routes.customer.order.plan.path}
						component={DietAndPackageTypeCardContainer}
					/>
					<Route
						path={routes.customer.order.payment.main.path}
						component={PaymentCardContainer}
					/>
					<Route path={routes.customer.order.auth.path} component={LoginCardContainer} />
					<Redirect
						from={routes.customer.order.main.path}
						to={routes.customer.order.plan.path}
					/>
				</Switch>
				<DietSummaryContainer />
			</div>
		</div>
	);
};
