import { Reducer } from 'redux';

import {
	AuthenticationAction,
	IAuthLoginAction,
	IAuthLogoutAction,
	authenticationActionNames
} from './auth-actions';
import { getCookie, setCookie } from '../../components/cookie';

export type IAuthReduxState = {
	username: string;
	token?: string;
};

const INITIAL_STATE = { username: getCookie('username'), token: getCookie('sessionId') };

export const authReducer: Reducer<IAuthReduxState, AuthenticationAction> = (
	state = INITIAL_STATE,
	action
) => {
	switch (action.type) {
		case authenticationActionNames.Auth_Login:
			return onLogin(state, action);
		case authenticationActionNames.Auth_Logout:
			return onLogout(state, action);
		default:
			return state;
	}
};

const onLogin = (state: IAuthReduxState, action: IAuthLoginAction) => {
	setCookie('sessionId', action.payload.token || '');
	setCookie('username', action.payload.login || '');
	return {
		...state,
		username: action.payload.login,
		token: action.payload.token
	};
};

const onLogout = (state: IAuthReduxState, action: IAuthLogoutAction) => {
	setCookie('sessionId', '');
	setCookie('username', '');
	return { username: '', token: '' };
};
