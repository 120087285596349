import { FileApi } from '../../../../files-client';
import { RootState } from '../../../../business/root-store';
import { ThunkAction } from 'redux-thunk';
import { documentsUrls } from '../../../../shared/shared';
import { getPartnershipDocumentByUrl } from '../../../organisms/Partnership/PartnershipPanel/PartnershipPanelEpics';
import { partnershipRestaurantFetched } from '../../../../business/Partnership/PartnershipActions';

import { AuxiliaryApi, Restaurant } from '../../../../client';
import { GetApiConfig, GetFileConfig } from '../../../../business/api-client-config';
import {
	getPartnershipRestaurantImages,
	uploadPartnershipRestaurantImages
} from '../../../organisms/Partnership/PartnershipInitial/PartnershipInitialEpics';

export const postRestaurantDetails =
	(restaurant: Restaurant): ThunkAction<any, RootState, any, any> =>
	(dispatch, getState) => {
		const state = getState();
		const api = new AuxiliaryApi(GetApiConfig(state));

		api.postRestaurantDetails({
			restaurant: restaurant
		}).then((x) => {
			if (x.data.restaurant) {
				dispatch(getPartnershipDocumentByUrl(documentsUrls.partnership.detailsWait));
				dispatch(partnershipRestaurantFetched(x.data.restaurant));
			}
		});
	};

export const addOrRemovePartnershipRestaurantImages =
	(
		restaurant: Restaurant,
		imagesToAdd?: File[],
		imageToRemoveName?: string
	): ThunkAction<any, RootState, any, any> =>
	(dispatch, getState) => {
		const state = getState();
		const api = new FileApi(GetFileConfig(state));
		if (restaurant.imagesFileCatalogId && !restaurant.newImagesFileCatalogId) {
			api.postFileCatalog({ name: 'Restaurant' }).then((x) => {
				api.copyFilesToCatalog(restaurant.imagesFileCatalogId!, x.data.catalogId!).then(
					(y) => {
						restaurant.newImagesFileCatalogId = x.data.catalogId!;
						dispatch(partnershipRestaurantFetched(restaurant));
						if (imagesToAdd)
							dispatch(
								uploadPartnershipRestaurantImages(
									imagesToAdd,
									restaurant.newImagesFileCatalogId
								)
							);
						else if (imageToRemoveName)
							dispatch(removePartnershipRestaurantImageByName(imageToRemoveName));
					}
				);
			});
		} else if (imagesToAdd && restaurant.newImagesFileCatalogId)
			dispatch(
				uploadPartnershipRestaurantImages(imagesToAdd, restaurant.newImagesFileCatalogId)
			);
		else if (imagesToAdd) {
			api.postFileCatalog({ name: 'Restaurant' }).then((x) => {
				restaurant.newImagesFileCatalogId = x.data.catalogId!;
				dispatch(partnershipRestaurantFetched(restaurant));
				dispatch(uploadPartnershipRestaurantImages(imagesToAdd, x.data.catalogId!));
			});
		} else if (imageToRemoveName)
			dispatch(removePartnershipRestaurantImageByName(imageToRemoveName));
	};

export const removePartnershipRestaurantImageByName =
	(imageName: string): ThunkAction<any, RootState, any, any> =>
	(dispatch, getState) => {
		const state = getState();
		const api = new FileApi(GetFileConfig(state));
		api.deleteFileByName(
			imageName,
			state.administration.partnership.restaurant.newImagesFileCatalogId ??
				state.administration.partnership.restaurant.imagesFileCatalogId!
		).then((x) => {
			dispatch(getPartnershipRestaurantImages());
		});
	};
