import './PaymentCard.scss';

import { CreditCardPaymentForm } from './CreditCardPaymentForm/CreditCardPaymentForm';
import { PaymentChannelForm } from './PaymentChannelForm/PaymentChannelForm';
import { RadioSelect } from '../../../atoms/RadioSelect/RadioSelect';
import { WizardCard } from '../../../molecules/WizardCard/WizardCard';
import { elementIds } from '../OrderMenuView.auxiliaries';
import { paymentMethods } from './PaymentCardContainer.auxiliaries';
import { routes } from '../../../../business/router/path-definition';

import { Redirect, Route, Switch } from 'react-router-dom';

export const PaymentCardContainer = () => {
	// payment method list is hidden til time we wont support more than one type of payment method
	const isPaymentMethodListDisplayed = false;

	return (
		<div id={elementIds.paymentMethodCard} className="animate__animated animate__fadeIn">
			<div className={`${elementIds.paymentMethodCard}-wrapper`}>
				<WizardCard id="payment" header="Metoda płatności" className="paymentWizardCard">
					<div className={`${elementIds.paymentMethodCard}-content`}>
						{isPaymentMethodListDisplayed && (
							<div className="payment-methods-wrapper">
								{paymentMethods.map((method) => (
									<div className="payment-method" key={method.key}>
										<RadioSelect
											disabled={method.isDisabled}
											checked={false}
											id={method.key}
										/>
										<span>{method.label}</span>
										<div className="img-wrapper">
											<img src={method.img} alt={method.label} />
										</div>
									</div>
								))}
							</div>
						)}
						<div className="payments-details-wrapper">
							<Switch>
								<Route
									exact
									path={routes.customer.order.payment.cashTransfer.path}
									component={PaymentChannelForm}
								/>
								<Route
									exact
									path={routes.customer.order.payment.cardPayment.path}
									component={CreditCardPaymentForm}
								/>
								<Redirect
									from={routes.customer.order.payment.main.path}
									to={routes.customer.order.payment.cashTransfer.path}
								/>
							</Switch>
						</div>
					</div>
				</WizardCard>
			</div>
		</div>
	);
};
