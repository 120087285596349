import { AuthTypes } from './interfaces';
import { routerActions } from 'connected-react-router';

export const authTypes: AuthTypes = {
	login: 'login',
	register: 'register',
	changePassword: 'change-password',
	resetPassword: 'reset-password',
	handleResetPassword: 'reset',
	activateMail: 'activatemail',
	activateCreatedAccount: 'activateaccount'
};

export const routes = {
	home: { path: '/' },
	auth: {
		main: { path: '/auth/:authType?/:guid?' },
		register: { path: `/auth/${authTypes.register}` },
		login: { path: `/auth/${authTypes.login}` },
		changePassword: { path: `/auth/${authTypes.changePassword}` },
		resetPassword: { path: `/auth/${authTypes.resetPassword}` },
		handleResetPassword: { path: `/auth/${authTypes.handleResetPassword}` },
		activateMail: { path: `/auth/${authTypes.activateMail}` },
		activateCreatedAccount: { path: `/auth/${authTypes.activateCreatedAccount}` }
	},
	customer: {
		new: { path: '/customer/new/:dietId' },
		meal: {
			add: { path: '/customer/meal/add' },
			exchange: { path: '/customer/meal/exchange' },
			details: { path: '/customer/meal/details' },
			copy: { path: '/customer/meal/copyMeal' },
			copyDay: { path: '/customer/meal/copyDay' },
			analyze: { path: '/customer/meal/analyze' },
			preference: { path: '/customer/meal/preference' }
		},
		order: {
			main: { path: '/customer/order' },
			plan: { path: '/customer/order/diet-plan-and-type' },
			auth: { path: '/customer/order/user-auth' },
			payment: {
				main: { path: '/customer/order/payment' },
				cardPayment: { path: '/customer/order/payment/card-payment' },
				cashTransfer: { path: '/customer/order/payment/cash-transfer' }
			},
			paymentStatus: {
				main: { path: '/customer/payment-status' },
				success: { path: '/customer/payment-status/success' },
				negative: { path: '/customer/payment-status/negative' }
			}
		},
		beverages: {
			main: { path: '/customer/beverages/main' },
			add: { path: '/customer/beverages/add' }
		},
		settings: {
			account: { path: '/customer/account-settings' },
			diet: { path: '/customer/diet-settings' }
		}
	},
	static: {
		document: { path: '/document/:documentUrl' },
		blog: { path: '/blog' }
	},
	restaurants: {
		restaurantsList: { path: '/restaurants' },
		restaurantPage: { path: '/restaurant/:restaurantUrl' }
	},
	partnership: {
		initialForm: { path: '/partnership' },
		panel: { path: '/partnership/panel' }
	}
};

export const navigationAction = {
	open: (url: string = routes.home.path, state?: any) => routerActions.push(url, state),
	goBack: () => routerActions.goBack()
};

export const createUrlWithParams = (url: string, params: any): string => {
	const names = Object.getOwnPropertyNames(params);
	names.map((x) => (url = url.replace(':', '').replace(x, params[x])));
	return url;
};
