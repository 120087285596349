import { ActionType } from 'typesafe-actions';

export const SpinnerActions = {
	incrementActiveRequestsCount: () =>
		({
			type: 'INCREMENT_ACTIVE_REQUESTS_COUNT'
		} as const),
	decrementActiveRequestsCount: () =>
		({
			type: 'DECREMENT_ACTIVE_REQUESTS_COUNT'
		} as const)
};

export type SpinnerAction = ActionType<typeof SpinnerActions>;
