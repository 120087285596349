import { List } from 'immutable';
import { MealItemComponent } from './MealItem/MealItemComponent';
import React from 'react';
import { WeekViewDay } from '../../../../client';

import {
	DragDropContext,
	Draggable,
	DropResult,
	Droppable,
	ResponderProvided
} from 'react-beautiful-dnd';

interface IMealTableComponentProps {
	weekViewDays: List<WeekViewDay>;
	postSwappedMeals(from: number, to: number): void;
}

interface IMealTableComponentState {
	weekViewDays: List<WeekViewDay>;
	isModified: boolean;
	daysMapper: Map<number, number>;
}

export class MealTableComponent extends React.Component<
	IMealTableComponentProps,
	IMealTableComponentState
> {
	constructor(props: IMealTableComponentProps, state: IMealTableComponentState) {
		super(props, state);
	}

	public render(): JSX.Element {
		const { weekViewDays } = this.props;
		return (
			<div className="mealTableSubMain">
				<div className="scroll-wizzard" id="regWizzard">
					<DragDropContext onDragEnd={this.handleOnDragEnd}>
						<Droppable direction="horizontal" droppableId="weekView">
							{(provided) => (
								<ul
									className="hs full"
									{...provided.droppableProps}
									ref={provided.innerRef}
								>
									{!(weekViewDays == null || weekViewDays == undefined)
										? weekViewDays!.map(
												(weekViewDay: WeekViewDay, key: number) => (
													<Draggable
														key={key}
														draggableId={key.toString()}
														index={key}
													>
														{(provided) => (
															<li
																className="item"
																{...provided.draggableProps}
																{...provided.dragHandleProps}
																ref={provided.innerRef}
															>
																<MealItemComponent
																	weekViewDay={weekViewDay}
																/>
															</li>
														)}
													</Draggable>
												)
										  )
										: null}
									{provided.placeholder}
								</ul>
							)}
						</Droppable>
					</DragDropContext>
				</div>
			</div>
		);
	}

	private handleOnDragEnd = (result: DropResult, provided: ResponderProvided): void => {
		if (!result.destination || result.source.index == result.destination.index) return;
		const meals = Array.from(this.props.weekViewDays);
		const [reorderedItem] = meals.splice(result.source.index, 1);
		const updated: WeekViewDay = reorderedItem;
		meals.splice(result.destination.index, 0, updated);
		this.props.postSwappedMeals(result.source.index, result.destination.index);
	};
}
