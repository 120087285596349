import './ingredients-renderer-component.scss';

import { IDpdmWithImage } from '../../../meal-card-interfaces';
import { IngredientsIcon } from '../../../../../../shared/assets/ingredients-icon';
import { LiIcon } from '../../../../../../shared/assets/li_icon';
import { getFriendlyFractionsString } from '../../../../../../shared/helpers';
import { getMealServingsProposalsSelector } from '../../meal-selectors';
import { useEffect } from 'react';

import {
	CaloriesWithProductId,
	MealFoodProduct,
	MealServing,
	MealServingsProposalsForProductId
} from '../../../../../../client';
import { DropdownMenu, IDropdownMenuItem } from '../../../../../atoms/DropdownMenu/DropdownMenu';
import { getMealServingsExchangeProposals, updateServingInDietPlanDayMeal } from '../../meal-epics';
import { useDispatch, useSelector } from 'react-redux';

interface IIngredientsRendererComponentProps {
	mealWithImage: IDpdmWithImage;
}

export const IngredientsRendererComponent = (
	props: IIngredientsRendererComponentProps
): JSX.Element => {
	return (
		<div id="ingredinet-component">
			<div className="ingredinet-component__caption">
				<IngredientsIcon />
				<h3>Składniki</h3>
			</div>

			<ul className="ingredinet-component__list">
				{props.mealWithImage.dietPlanDayMeal.mealServings!.map((serving: MealServing) => {
					const menuItems = getDropdownOptions(serving, props.mealWithImage);
					return (
						<li className="ingredinet-component__item" key={serving.foodProduct?.id}>
							<LiIcon />
							<div className="ingredinet-component__ingredients">
								<strong>{serving.foodProduct?.name}</strong>
								{' ' +
									(serving.qtyInHomeMeasurement
										? getFriendlyFractionsString(serving.qtyInHomeMeasurement) +
										  ' ' +
										  serving.qtyInText +
										  ' (' +
										  serving.qtyInGrams?.toFixed(0) +
										  'g)'
										: serving.qtyInGrams?.toFixed(0) + 'g')}
							</div>
							{menuItems.length > 0 && (
								<DropdownMenu
									triggerItem={<img src="\img\change.svg" alt="Wymień produkt" />}
									menuItems={menuItems}
									position="bottomRight"
								/>
							)}
						</li>
					);
				})}
			</ul>
		</div>
	);
};

const getDropdownOptions = (
	serving: MealServing,
	mealWithImage: IDpdmWithImage
): IDropdownMenuItem[] => {
	const dispatch = useDispatch();
	const mealServingProposals = useSelector(getMealServingsProposalsSelector);
	useEffect(() => {
		let caloriesWithProductIds: CaloriesWithProductId[] = [];
		mealWithImage.dietPlanDayMeal.mealServings?.forEach((x) => {
			caloriesWithProductIds.push({ calories: x.energy, productId: x.foodProductId });
		});
		const excludedFoodProductsIds = mealWithImage.dietPlanDayMeal.mealServings!.map(
			(x) => x.foodProductId!
		);
		dispatch(getMealServingsExchangeProposals(caloriesWithProductIds, excludedFoodProductsIds));
	}, [mealWithImage]);

	const handleOnClickChangeServing = (mealServingProposal: MealServing) => {
		dispatch(
			updateServingInDietPlanDayMeal({
				...mealWithImage.dietPlanDayMeal.meal,
				mealFoodProducts: [
					...(mealWithImage.dietPlanDayMeal.mealServings?.filter(
						(x) => x.foodProductId !== serving.foodProductId
					) ?? []),
					mealServingProposal as MealFoodProduct
				]
			})
		);
	};
	const proposals = mealServingProposals.filter((x) => x.productId === serving.foodProductId);
	let toReturn: IDropdownMenuItem[] = [];
	proposals.forEach((y: MealServingsProposalsForProductId) => {
		if (y.mealServings && y.mealServings.length > 0) {
			y.mealServings?.forEach((mealServingProposal) => {
				const handleClick = () => {
					handleOnClickChangeServing(mealServingProposal);
				};
				toReturn.push({
					label:
						mealServingProposal.foodProduct?.name +
						' ' +
						getFriendlyFractionsString(mealServingProposal.qtyInHomeMeasurement) +
						' ' +
						(mealServingProposal.qtyInText ? mealServingProposal.qtyInText + ' ' : '') +
						(mealServingProposal.qtyInHomeMeasurement
							? '(' + mealServingProposal.qtyInGrams?.toFixed(0) + 'g)'
							: mealServingProposal.qtyInGrams?.toFixed(0) + 'g'),
					onClick: handleClick
				});
			});
		}
	});
	return toReturn;
};
