import './RangePicker.scss';

import { ActionButton } from '../../atoms/ActionButton/ActionButton';
import ActionLink from '../../atoms/action-link/action-link';
import { CalendarIcon } from '../../../shared/assets/calendar-icon';
import { ChevronLeftIcon } from '../../../shared/assets/chevron-left-icon';
import { ChevronRightIcon } from '../../../shared/assets/chevron-right-icon';
import { DateRangePicker } from '../../atoms/datePickers/DateRangePicker/DateRangePicker';
import { GetDatesRangeString } from '../../../shared/helpers';
import { IRangePickerProps } from './RangePickerInterfaces';
import moment from 'moment';
import { primaryOrangeColor } from '../../../shared/shared';
import { useState } from 'react';

import { Range, RangeKeyDict } from 'react-date-range';

export const RangePicker = ({
	rangeLocal,
	maxDateLocal,
	minDateLocal,
	setRange,
	onDateSelected,
	className,
	disableCalendar,
	disableLeftArrow,
	disableRigthArrow,
	...props
}: IRangePickerProps) => {
	const [showCalendar, setShowCalendar] = useState(false);
	const [numberOfClicks, setNumberOfClicks] = useState(0);
	const handleLeftArrowClick = () => {
		let startDate = moment(rangeLocal.startDate);
		let endDate = moment(rangeLocal.endDate);
		const diff = endDate.diff(startDate, 'days') + 1;
		startDate.subtract(diff, 'days');
		endDate.subtract(diff, 'days');
		if (startDate.isBefore(moment(minDateLocal).format('YYYY-MM-DD'))) {
			startDate = moment(minDateLocal);
		}
		if (endDate.isBefore(moment(minDateLocal).format('YYYY-MM-DD'))) {
			endDate = moment(rangeLocal.endDate);
		}
		setRange({
			...rangeLocal,
			startDate: startDate.toDate(),
			endDate: endDate.toDate()
		} as Range);
		onDateSelected(startDate.toDate(), endDate.toDate());
	};

	const handleRightArrowClick = () => {
		let startDate = moment(rangeLocal.startDate);
		let endDate = moment(rangeLocal.endDate);
		const diff = endDate.diff(startDate, 'days') + 1;
		startDate.add(diff, 'days');
		endDate.add(diff, 'days');
		if (endDate.isAfter(moment(maxDateLocal).format('YYYY-MM-DD'))) {
			endDate = moment(maxDateLocal);
		}
		if (startDate.isAfter(moment(maxDateLocal).format('YYYY-MM-DD'))) {
			startDate = moment(rangeLocal.startDate);
		}
		setRange({
			...rangeLocal,
			startDate: startDate.toDate(),
			endDate: endDate.toDate()
		} as Range);
		onDateSelected(startDate.toDate(), endDate.toDate());
	};
	const handleCalendarClick = () => {
		if (!showCalendar) setShowCalendar(true);
	};
	const handleChange = (rangesByKey: RangeKeyDict) => {
		let actualNumberOfClicks = numberOfClicks;
		actualNumberOfClicks = actualNumberOfClicks + 1;
		setNumberOfClicks(actualNumberOfClicks);
		setRange(rangesByKey[rangeLocal.key!]);
		if (actualNumberOfClicks == 2) {
			onDateSelected(
				rangesByKey[rangeLocal.key!].startDate!,
				rangesByKey[rangeLocal.key!].endDate!
			);
			setShowCalendar(false);
			setNumberOfClicks(0);
		}
	};
	const handleShowCalendar = (value: boolean) => {
		setShowCalendar(value);
		setNumberOfClicks(0);
	};
	return (
		<div className={className ? 'RangePicker ' + className : 'RangePicker'}>
			<div className="RangePicker_header">
				<ActionButton
					disabled={
						rangeLocal.startDate?.getDate() == minDateLocal.getDate() ||
						disableLeftArrow
					}
					icon={<ChevronLeftIcon />}
					onClick={handleLeftArrowClick}
				/>
				<span className="RangePicker_header_value">
					{GetDatesRangeString(rangeLocal.startDate!, rangeLocal.endDate!)}
				</span>
				<ActionButton
					disabled={
						rangeLocal.endDate?.getDate() == maxDateLocal.getDate() || disableRigthArrow
					}
					icon={<ChevronRightIcon />}
					onClick={handleRightArrowClick}
				/>
			</div>
			<div className="RangePicker_picker">
				<ActionLink
					disabled={disableCalendar}
					text="Inny zakres dat"
					textColor={primaryOrangeColor}
					onClick={handleCalendarClick}
				>
					<CalendarIcon disabled={disableCalendar} />
				</ActionLink>
				{showCalendar && (
					<DateRangePicker
						maxDateLocal={maxDateLocal}
						minDateLocal={minDateLocal}
						rangeLocal={rangeLocal}
						onChange={handleChange}
						showCalendar={handleShowCalendar}
					/>
				)}
			</div>
		</div>
	);
};
