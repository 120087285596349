import '../CustomProduct.scss';

import * as Yup from 'yup';

import { ActionButton } from '../../../../../atoms/ActionButton/ActionButton';
import { RemoveIcon } from '../../../../../../shared/assets/remove-icon';
import { SearchBox } from '../../../../../atoms/search-box/search-box';
import Switch from 'react-switch';
import { TextArea } from '../../../../../atoms/TextArea/TextArea';
import { TextBox } from '../../../../../atoms/TextBox/TextBox';
import { getNutritionFriendlyForm } from '../../../../../../shared/helpers';

import {
	Accordion,
	AccordionItem,
	AccordionItemButton,
	AccordionItemHeading,
	AccordionItemPanel
} from 'react-accessible-accordion';
import { AddOrUpdateCustomProduct, ProductNutritionFact } from '../../../../../../client';
import { Field, Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { ICustomProductForm, ICustomProductFormValues } from './CustomProductFormInterfaces';
import React, { ChangeEvent, useState } from 'react';

export const CustomProductForm = (props: ICustomProductForm) => {
	const [searchText, setSearchText] = useState<string>('');
	const proteinsId = props.nutritionFactDefinitions.find((x) => x.name === 'Białko')?.id!;
	const carbsId = props.nutritionFactDefinitions.find((x) => x.name === 'Węglowodany strawne')
		?.id!;
	const fatsId = props.nutritionFactDefinitions.find((x) => x.name === 'Tłuszcze')?.id!;
	const energyId = props.nutritionFactDefinitions.find((x) => x.name === 'Wart. energet')?.id!;
	const onSubmit = (
		values: ICustomProductFormValues,
		helpers: FormikHelpers<ICustomProductFormValues>
	) => {
		let product = values as AddOrUpdateCustomProduct;
		props.AddCustomProduct(product);

		setTimeout(() => helpers.setSubmitting(false), 2000);
	};
	let pnfs: ProductNutritionFact[] = [];
	props.nutritionFactDefinitions.map((x) => {
		pnfs?.push({
			nutritionFactDefinitionId: x.id,
			uoMId: x.defaultUnitOfMeasureId,
			uoM: x.defaultUnitOfMeasure,
			nutritionFactDefinition: x
		});
	});
	return (
		<Formik
			enableReinitialize
			initialValues={
				{
					isForPackage: false,
					name: '',
					quantity: undefined,
					carbsQuantity: undefined,
					energyQuantity: undefined,
					fatsQuantity: undefined,
					proteinsQuantity: undefined,
					productNutritionFacts: pnfs
				} as ICustomProductFormValues
			}
			validationSchema={Yup.object<
				Partial<Record<keyof ICustomProductFormValues, Yup.AnySchema>>
			>({
				name: Yup.string().min(3, 'Co najmniej 3 znaki').required('Wymagane'),
				quantity: Yup.number()
					.moreThan(0, 'Wpisz wartość większą od 0')
					.required('Wymagane')
				// fatsQuantity: Yup.number()
				// 	.moreThan(0, 'Wpisz wartość większą od 0')
				// 	.required('Wymagane'),
				// carbsQuantity: Yup.number()
				// 	.moreThan(0, 'Wpisz wartość większą od 0')
				// 	.required('Wymagane'),
				// proteinsQuantity: Yup.number()
				// 	.moreThan(0, 'Wpisz wartość większą od 0')
				// 	.required('Wymagane'),
				// energyQuantity: Yup.number()
				// 	.moreThan(0, 'Wpisz wartość większą od 0')
				// 	.required('Wymagane')
				// productNutritionFacts: Yup.array<ProductNutritionFact>().when('id', {
				// 	is: proteinsId | carboId | fatsId | caloriesId,
				// 	then: Yup.array().of(
				// 		Yup.object({
				// 			quantity: Yup.number()
				// 				.moreThan(0, 'Wpisz wartość większą od 0')
				// 				.required('Wymagane')
				// 		})
				// 	)
				// })
			})}
			onSubmit={onSubmit}
		>
			{(formik) => (
				<Form>
					<fieldset
					// disabled={props.disabled}
					// className="PartnershipInitial_form_content_row"
					>
						<div className="customProduct_section">
							<p className="customProduct_section_header">Podstawowe informacje</p>
							<Field
								name="name"
								label="Nazwa produktu"
								placeholder="Jak nazywa się Twój produkt?"
								className="customProduct_textBox"
								errorString={formik.touched.name && formik.errors.name}
								as={TextBox}
							/>
							<Field
								type="number"
								name="quantity"
								placeholder="Ilość"
								rightLabel="g"
								inputClassName="customProduct_input"
								errorString={formik.touched.quantity && formik.errors.quantity}
								as={TextBox}
							/>
						</div>
						<div className="customProduct_section">
							<p className="customProduct_section_header">Makroskładniki</p>
							<div className="customProduct_switch">
								100g
								<Switch
									onChange={(value) =>
										formik.setFieldValue('isForPackage', value)
									}
									checked={formik.values.isForPackage!}
									height={24}
									width={48}
									onColor="#BB6BD9"
									offColor="#BB6BD9"
									checkedIcon={false}
									uncheckedIcon={false}
								/>
								{formik.values.quantity
									? 'opakowanie (' + formik.values.quantity + 'g)'
									: 'opakowanie'}
							</div>
							<Field
								type="number"
								name="proteinsQuantity"
								placeholder="Ilość"
								rightLabel={
									'g ' +
									getNutritionFriendlyForm(
										props.nutritionFactDefinitions?.find(
											(x) => x.id === proteinsId
										)?.name!
									)
								}
								inputClassName="customProduct_input"
								errorString={
									formik.touched.proteinsQuantity &&
									formik.errors.proteinsQuantity
								}
								as={TextBox}
							/>
							<Field
								type="number"
								name="carbsQuantity"
								placeholder="Ilość"
								rightLabel={
									'g ' +
									getNutritionFriendlyForm(
										props.nutritionFactDefinitions?.find(
											(x) => x.id === carbsId
										)?.name!
									)
								}
								inputClassName="customProduct_input"
								errorString={
									formik.touched.carbsQuantity && formik.errors.carbsQuantity
								}
								as={TextBox}
							/>
							<Field
								type="number"
								name="fatsQuantity"
								placeholder="Ilość"
								rightLabel={
									'g ' +
									getNutritionFriendlyForm(
										props.nutritionFactDefinitions?.find((x) => x.id === fatsId)
											?.name!
									)
								}
								inputClassName="customProduct_input"
								errorString={
									formik.touched.fatsQuantity && formik.errors.fatsQuantity
								}
								as={TextBox}
							/>
							<Field
								type="number"
								name="energyQuantity"
								placeholder="Ilość"
								rightLabel={
									'g ' +
									getNutritionFriendlyForm(
										props.nutritionFactDefinitions?.find(
											(x) => x.id === energyId
										)?.name!
									)
								}
								inputClassName="customProduct_input"
								errorString={
									formik.touched.energyQuantity && formik.errors.energyQuantity
								}
								as={TextBox}
							/>
						</div>
						<div className="customProduct_section">
							<Accordion allowZeroExpanded>
								<AccordionItem>
									<AccordionItemHeading>
										<AccordionItemButton>
											<p className="customProduct_section_header">
												Jeśli masz ochotę, uzupełnij też..
											</p>
										</AccordionItemButton>
									</AccordionItemHeading>
									<AccordionItemPanel>
										<Field
											name="manufacturer"
											label="Nazwa producenta"
											placeholder="np. Sokołów"
											className="customProduct_textBox"
											errorString={
												formik.touched.manufacturer &&
												formik.errors.manufacturer
											}
											as={TextBox}
										/>
										<Field
											name="description"
											label="Opis produktu"
											placeholder="Dłuższy opis"
											className="customProduct_textArea"
											errorString={
												formik.touched.description &&
												formik.errors.description
											}
											as={TextArea}
										/>
										{formik.values.productGroupId !== undefined ? (
											<div className="customProduct_selectedGroup">
												{' '}
												{'Wybrana grupa: ' +
													props.productGroups.find(
														(x) => x.id === formik.values.productGroupId
													)?.display}{' '}
												<RemoveIcon
													className="Ingredient_remove_icon"
													onClick={() =>
														formik.setFieldValue(
															'productGroupId',
															undefined
														)
													}
												/>
											</div>
										) : (
											<SearchBox
												data={props.productGroups}
												className="customProduct_searchBox"
												activateOnFocus
												placeholder="Kategoria produktu"
												onSelect={(item) =>
													formik.setFieldValue('productGroupId', item.id)
												}
												onSearchChanged={setSearchText}
												value={searchText}
											/>
										)}
										{formik.values.productNutritionFacts?.map((x) => {
											if (
												x.nutritionFactDefinitionId !== fatsId &&
												x.nutritionFactDefinitionId !== proteinsId &&
												x.nutritionFactDefinitionId !== carbsId &&
												x.nutritionFactDefinitionId !== energyId &&
												x.nutritionFactDefinition?.name !== 'rośl.' &&
												x.nutritionFactDefinition?.name !== 'zwierz.' &&
												x.nutritionFactDefinition?.name !== 'laktoza' &&
												x.nutritionFactDefinition?.name !== 'sacharoza' &&
												x.nutritionFactDefinition?.name !== 'skrobia'
											)
												return (
													<TextBox
														key={
															'customProduct_nutrition_' +
															x.nutritionFactDefinitionId
														}
														type="number"
														placeholder="Ilość"
														rightLabel={
															'g ' +
															getNutritionFriendlyForm(
																x.nutritionFactDefinition?.name!
															)
														}
														inputClassName="customProduct_input"
														className="customProduct_nutrition"
														value={x.quantity ?? ''}
														onChange={(
															y: ChangeEvent<HTMLInputElement>
														) =>
															handleMacroQuantityChanged(
																y,
																x.nutritionFactDefinitionId!,
																formik
															)
														}
													/>
												);
										})}
									</AccordionItemPanel>
								</AccordionItem>
							</Accordion>
						</div>
						<ActionButton
							disabled={!formik.isValid}
							errorString={formik.isValid ? undefined : 'Sprawdź błędy w formularzu'}
							type="submit"
							text="Dodaj produkt"
							className="customProduct_actionButton"
						/>
					</fieldset>
				</Form>
			)}
		</Formik>
	);
};

const handleMacroQuantityChanged = (
	event: ChangeEvent<HTMLInputElement>,
	nfdId: number,
	formik: FormikProps<ICustomProductFormValues>
) => {
	let pnfs = formik.values.productNutritionFacts;
	if (pnfs) {
		const pnfIndex = pnfs.indexOf(pnfs.find((x) => x.nutritionFactDefinitionId === nfdId)!);
		let pnfToChange = pnfs.find((x) => x.nutritionFactDefinitionId === nfdId)!;
		pnfToChange.quantity = +event.target.value;
		pnfs?.splice(pnfIndex, 1, pnfToChange);
	}

	formik.setFieldValue('productNutritionFacts', pnfs);
};
