import './LandingPage.scss';

import { ContactForm } from './ContactForm/ContactForm';
import { Headline } from '../../atoms/headline/headline';
import { InstagramSlider } from '../../molecules/landing-page-molecules/InstagramSlider/InstagramSlider';
import { LandingPageCustomerOpinions } from '../../molecules/landing-page-molecules/landing-page-customer-opinions/landing-page-customer-opinions';
import { LandingPageDietTypes } from '../../molecules/landing-page-molecules/landing-page-diet-types/LandingPageDietTypesContainer';
import { LandingPageHeader } from '../../molecules/landing-page-molecules/landing-page-header/landing-page-header';
import { LandingPageJoinUs } from '../../molecules/landing-page-molecules/landing-page-join-us/landing-page-join-us';
import { LandingPageProductPromo } from '../../molecules/landing-page-molecules/landing-page-product-promo/LandingPageProductPromo';
import React from 'react';

export const LandingPage: React.FC = (props) => {
	return (
		<div className="LandingPage">
			<LandingPageHeader />
			<LandingPageDietTypes />
			<LandingPageProductPromo />
			<LandingPageJoinUs />
			<LandingPageCustomerOpinions />
			<InstagramSlider />
			<div className="LandingPage_contactForm">
				<div className="line-decor" />
				<Headline text="Masz pytania? Napisz do nas" />
				<ContactForm />
			</div>
		</div>
	);
};
