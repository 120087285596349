import { IShoppingListReduxState } from './shopping-list-reducer';
import { RootState } from '../../../../business/root-store';
import { ShoppingList } from '../../../../client';
import { createSelector } from '@reduxjs/toolkit';

export const shoppingListSelector = (state: RootState) => state.shoppingList;

export const getShoppingListSelector = createSelector(
	shoppingListSelector,
	(shoppingState: IShoppingListReduxState): ShoppingList | undefined => {
		return shoppingState.shoppingList;
	}
);
