import { GetFileConfig } from '../api-client-config';
import { RootState } from '../root-store';
import { ThunkAction } from 'redux-thunk';
import {
	AddIngredientError,
	AddIngredientRequest,
	AddIngredientResponse,
	AnalyzeFoodError,
	AnalyzeFoodRequest,
	AnalyzeFoodResponse,
	RemoveAnalyzedFoodImage,
	RemoveIngredientError,
	RemoveIngredientRequest,
	RemoveIngredientResponse,
	RemoveIngredientResponsePayload
} from './Interfaces';
import {
	MealAnalysisApi,
	PostAddMarkerToImage,
	PostImageFileForAnalysisResponse
} from '../../files-client';

export const ANALYZE_FOOD_IMAGE_REQUEST = 'ANALYZE_FOOD_IMAGE_REQUEST';
export const ANALYZE_FOOD_IMAGE_RESPONSE = 'ANALYZE_FOOD_IMAGE_RESPONSE';
export const ANALYZE_FOOD_IMAGE_ERROR = 'ANALYZE_FOOD_IMAGE_ERROR';

export const ANALYZE_FOOD_REOMVE_INGREDIENT_REQUEST = 'ANALYZE_FOOD_REOMVE_INGREDIENT_REQUEST';
export const ANALYZE_FOOD_REOMVE_INGREDIENT_RESPONSE = 'ANALYZE_FOOD_REOMVE_INGREDIENT_RESPONSE';
export const ANALYZE_FOOD_REOMVE_INGREDIENT_ERROR = 'ANALYZE_FOOD_REOMVE_INGREDIENT_ERROR';

export const ANALYZE_FOOD_ADD_MARKER_REQUEST = 'ANALYZE_FOOD_ADD_MARKER_REQUEST';
export const ANALYZE_FOOD_ADD_MARKER_RESPONSE = 'ANALYZE_FOOD_ADD_MARKER_RESPONSE';
export const ANALYZE_FOOD_ADD_MARKER_ERROR = 'ANALYZE_FOOD_ADD_MARKER_ERROR';

export const REOMVE_ANALYZED_FOOD = 'REOMVE_ANALYZED_FOOD';

const analyzeFoodImageRequest = (): AnalyzeFoodRequest => ({
	type: ANALYZE_FOOD_IMAGE_REQUEST
});

const analyzeFoodImageResponse = (
	payload: PostImageFileForAnalysisResponse
): AnalyzeFoodResponse => ({
	type: ANALYZE_FOOD_IMAGE_RESPONSE,
	payload
});

const analyzeFoodImageError = (error: string): AnalyzeFoodError => ({
	type: ANALYZE_FOOD_IMAGE_ERROR,
	payload: { error }
});

export const removeAnalyzedFoodImage = (): RemoveAnalyzedFoodImage => ({
	type: REOMVE_ANALYZED_FOOD
});

const addIngredientRequest = (): AddIngredientRequest => ({
	type: ANALYZE_FOOD_ADD_MARKER_REQUEST
});

const addIngredientResponse = (
	payload: PostImageFileForAnalysisResponse
): AddIngredientResponse => ({
	type: ANALYZE_FOOD_ADD_MARKER_RESPONSE,
	payload
});

const addIngredientError = (error: string): AddIngredientError => ({
	type: ANALYZE_FOOD_ADD_MARKER_ERROR,
	payload: { error }
});

const removeIngredientRequest = (): RemoveIngredientRequest => ({
	type: ANALYZE_FOOD_REOMVE_INGREDIENT_REQUEST
});

const removeIngredientResponse = (
	payload: RemoveIngredientResponsePayload
): RemoveIngredientResponse => ({
	type: ANALYZE_FOOD_REOMVE_INGREDIENT_RESPONSE,
	payload
});

const removeIngredientError = (error: string): RemoveIngredientError => ({
	type: ANALYZE_FOOD_REOMVE_INGREDIENT_ERROR,
	payload: { error }
});

export const analyzeFoodAction = {
	removeIngredientRequest,
	removeIngredientResponse,
	removeIngredientError,
	addIngredientRequest,
	addIngredientResponse,
	addIngredientError,
	analyzeFoodImageRequest,
	analyzeFoodImageResponse,
	analyzeFoodImageError,
	removeAnalyzedFoodImage
};

export const uploadImageForMealAnalysis =
	(imageData: string): ThunkAction<any, RootState, any, any> =>
	(dispatch, getState) => {
		const state = getState();
		const api = new MealAnalysisApi(GetFileConfig(state));

		dispatch(analyzeFoodAction.analyzeFoodImageRequest());

		api.postForAnalysisImageFile({ imageData, imageUrl: '' }).then((resp) => {
			dispatch(analyzeFoodAction.analyzeFoodImageResponse(resp.data));
		});
	};

export const addIngredient =
	(
		fileId: string,
		postAddMarkerToImage: PostAddMarkerToImage
	): ThunkAction<any, RootState, any, any> =>
	(dispatch, getState) => {
		const state = getState();
		const api = new MealAnalysisApi(GetFileConfig(state));

		dispatch(analyzeFoodAction.addIngredientRequest());

		api.postAddMarkerToImage(fileId, postAddMarkerToImage).then((resp) => {
			dispatch(analyzeFoodAction.addIngredientResponse(resp.data));
		});
	};

export const removeIngredient =
	(fileId: string, markerId: number): ThunkAction<any, RootState, any, any> =>
	(dispatch, getState) => {
		const state = getState();
		const api = new MealAnalysisApi(GetFileConfig(state));

		dispatch(analyzeFoodAction.removeIngredientRequest());

		api.deleteMarkerFromImage(fileId, markerId).then((resp) => {
			const payload = { markerId, estimatedCalories: resp.data.estimatedCalories! };
			dispatch(analyzeFoodAction.removeIngredientResponse(payload));
		});
	};

/*
lista potraw
 lista produktow
 FoodItem {
     name: string;
     size: number;
     calories: number
 }
*/
