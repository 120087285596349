import './DayViewTabsSelector.scss';

import { DayViewTabs } from '../../organisms/DayView/DayView';

interface IDayViewTabsSelectorProps {
	currentActiveTab: DayViewTabs;
	setCurrentActiveTab: (value: React.SetStateAction<DayViewTabs>) => void;
}

export const DayViewTabsSelector = (props: IDayViewTabsSelectorProps) => {
	return (
		<div className="DayViewTabsSelector">
			<a
				className={
					'DayViewTabsSelector_tab ' +
					(props.currentActiveTab === 'Menu'
						? ' DayViewTabsSelector_tab_active'
						: undefined)
				}
				onClick={() => props.setCurrentActiveTab('Menu')}
			>
				Jadłospis
			</a>
			<a
				className={
					'DayViewTabsSelector_tab ' +
					(props.currentActiveTab === 'DietBalance'
						? ' DayViewTabsSelector_tab_active'
						: undefined)
				}
				onClick={() => props.setCurrentActiveTab('DietBalance')}
			>
				Bilans diety
			</a>
			<a
				className={
					'DayViewTabsSelector_tab ' +
					(props.currentActiveTab === 'ShoppingList'
						? ' DayViewTabsSelector_tab_active'
						: undefined)
				}
				onClick={() => props.setCurrentActiveTab('ShoppingList')}
			>
				Lista zakupów
			</a>
		</div>
	);
};
