import './Blog.scss';
import 'rc-pagination/assets/index.css';

import { Document } from '../../../client';
import { Link } from 'react-router-dom';
import Pagination from 'rc-pagination';
import React from 'react';

import { createUrlWithParams, routes } from '../../../business/router/path-definition';

interface IBlogComponentProps {
	blogDocuments: Document[];
}

interface IBlogComponentState {
	currentPage: number;
	collection: Document[];
}

const countPerPage: number = 12;

export class BlogComponent extends React.Component<IBlogComponentProps, IBlogComponentState> {
	constructor(props: IBlogComponentProps, state: IBlogComponentState) {
		super(props, state);

		this.state = this.getInitialState();
	}

	public render(): JSX.Element {
		return (
			<div className="blog">
				<section className="blog_cards">
					{this.state.collection.map((doc) => {
						return (
							<Link
								key={'article_' + doc.id}
								to={createUrlWithParams(routes.static.document.path, {
									documentUrl: doc.url
								})}
							>
								<img
									className="blog_article-img"
									src={
										process.env.REACT_APP_FILES_URL +
											'/api/' +
											doc.pictureModified ?? ''
									}
									alt=" "
								/>
								<h1 className="blog_article-title">{doc.title}</h1>
								<p className="blog_article-subtitle">{doc.subTitle}</p>
							</Link>
						);
					})}
				</section>
				{this.props.blogDocuments.length > countPerPage && (
					<Pagination
						pageSize={countPerPage}
						onChange={this.updatePage}
						current={this.state.currentPage}
						total={this.props.blogDocuments.length}
					/>
				)}
			</div>
		);
	}
	updatePage = (p: number) => {
		const to = countPerPage * p;
		const from = to - countPerPage;
		this.setState({
			...this.state,
			collection: this.props.blogDocuments.slice(from, to),
			currentPage: p
		});
		window.scrollTo({ behavior: 'smooth', top: 0 });
	};

	private getInitialState = (): IBlogComponentState => {
		return {
			collection: this.props.blogDocuments.slice(0, countPerPage),
			currentPage: 1
		} as IBlogComponentState;
	};
}
