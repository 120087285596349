import './shopping-list-date-range.scss';

import { Range } from 'react-date-range';
import { RangePicker } from '../../../../molecules/RangePicker';
import React from 'react';

interface IShoppingDateRangeComponentProps {
	minDateUtc: Date;
	maxDateUtc: Date;
	startDateUtc: Date;
	endDateUtc: Date;
	onDateSelected: (startDateUtc: Date, endDateUtc: Date) => void;
	pdfDownloadLink: JSX.Element;
}

interface IShoppingDateRangeComponentState {
	range: Range;
}
export class ShoppingListDateRangeComponent extends React.Component<
	IShoppingDateRangeComponentProps,
	IShoppingDateRangeComponentState
> {
	constructor(props: IShoppingDateRangeComponentProps, state: IShoppingDateRangeComponentState) {
		super(props, state);

		this.state = this.getInitialState();
	}

	public render(): JSX.Element {
		return (
			<>
				<div className="shopping-list-date-range">
					<RangePicker
						minDateLocal={this.props.minDateUtc}
						maxDateLocal={this.props.maxDateUtc}
						rangeLocal={this.state.range}
						setRange={this.handleSetRange}
						onDateSelected={this.props.onDateSelected}
					/>
					<div className="shopping-list-date-range__print-button">
						{this.props.pdfDownloadLink}
					</div>
				</div>
			</>
		);
	}
	private handleSetRange = (range: Range) => {
		this.setState({ ...this.state, range: range });
	};

	private getInitialState = (): IShoppingDateRangeComponentState => {
		return {
			range: {
				startDate: this.props.startDateUtc,
				endDate: this.props.endDateUtc,
				key: 'shoppingListRange'
			}
		} as IShoppingDateRangeComponentState;
	};
}
