import './LandingPageProductPromo.scss';

import { ProductPromoSliderItem } from './ProductPromoSliderItem/ProductPromoSliderItem';
import { Slider } from '../../../atoms/slider/slider';

import React, { useState } from 'react';

const sliderItems = [
	<ProductPromoSliderItem
		title="Elastyczny jadłospis dostępny zawsze i wszędzie."
		description="Dostosuj jadłospis pod własne potrzeby. Jedz 3, 4 lub 5 zbilansowanych posiłków dziennie według swoich potrzeb!"
		image="/img/screenshots/1.png"
	/>,
	<ProductPromoSliderItem
		title="Jadłospis dostosowany pod Twoje preferencje."
		description="Wstajesz rano i chcesz zjeść zdrowe i pełnowartościowe śniadanie, jednak nie masz czasu? Nie ma sprawy — możesz skorzystać z szybkich koktajli. Wolisz jajka lub owsiankę? Dzięki opcjom indywidualizacji jadłospisów możesz jeść jak chcesz!"
		image="/img/screenshots/2.png"
	/>,
	<ProductPromoSliderItem
		title="Rozbuduj jadłospis o swoje ulubione przepisy."
		description="Masz swoje sprawdzone przepisy? Świetnie! Możesz wykorzystać je w swoim codziennym żywieniu, a Diet Designer pomoże zbilansować Ci resztę posiłków."
		image="/img/screenshots/3.png"
	/>,
	<ProductPromoSliderItem
		title="Diet Designer to ogrom możliwości dopasowania posiłków."
		description="Nie pasuje Ci przepis ze względu na poszczególny składnik? Nie ma problemu, możesz wymienić produkty, a aplikacja podpowie Ci, z czego wybrać."
		image="/img/screenshots/4.png"
	/>,
	<ProductPromoSliderItem
		title="Chcesz zjeść na mieście, nie tracąc kontroli nad dietą?"
		description="Nic prostszego! Wybierz posiłek z jednej z naszych partnerskich restauracji i zapomnij o niepotrzebnych ograniczeniach!"
		image="/img/screenshots/5.png"
	/>,
	<ProductPromoSliderItem
		title="Miej pewność, że dobrze jesz!"
		description="Diet Designer DDapp pozwala na przeanalizowanie Twojego menu pod kątem kluczowych dla zdrowia i prawidłowego funkcjonowania organizmu witamin i minerałów. Dodatkowo da Ci cenne wskazówki jak zadbać o dobrze zbilansowane żywienie."
		image="/img/screenshots/6.png"
	/>,
	<ProductPromoSliderItem
		title="DDapp to znacznie więcej niż plan diety!"
		description="Naszym celem było stworzenie aplikacji, która dostarczy Ci nie tylko zdrowe jadłospisy, ale przede wszystkim funkcjonalne i realne do wprowadzenia w codziennym życiu. Ważnym elementem jest również edukacja żywieniowa. Chcemy, abyś wyrobił w sobie zdrowe i trwałe nawyki związane z jedzeniem."
		image="/img/dietdesigner.svg"
	/>,
	<ProductPromoSliderItem
		title="Diet Designer dla osób ćwiczących rekreacyjnie i sportowców."
		description="Diet Designer pozwala dobrać zbilansowany jadłospis, uwzględniając m.in. Twoją aktywność fizyczną. Możesz być pewny, że Twój organizm będzie miał wszystko, czego potrzebuje do prawidłowego funkcjonowania."
		image="/img/screenshots/7.png"
	/>,
	<ProductPromoSliderItem
		title="Chcesz schudnąć lub nabrać masy ciała? A może dobrze czujesz się w swoje wadze, ale chcesz poprawić swoje żywienie?"
		description="Określ swoje cele, a Diet Designer da Ci najlepsze narzędzia do jego osiągnięcia."
		image="/img/screenshots/8.png"
	/>,
	<ProductPromoSliderItem
		title="Dopasuj plan tygodniowy diety pod siebie!"
		description="Kopiuj menu na inny dzień, dubluj posiłki, dodawaj do ulubionych. W Diet Designer to Ty decydujesz!"
		image="/img/screenshots/9.png"
	/>
];

export const LandingPageProductPromo: React.FC = (props) => {
	const [currentSlideId, setCurrentSlideId] = useState(0);

	return (
		<div className="LandingPageProductPromo">
			<Slider
				currentSlideId={currentSlideId}
				setCurrentSlideId={setCurrentSlideId}
				items={sliderItems}
				timer={17000}
			/>
		</div>
	);
};
