import './TextBox.scss';

import React, { InputHTMLAttributes } from 'react';

export interface ITextBoxProps {
	errorString?: string;
	label?: string;
	rightLabel?: string;
	className?: string;
	inputClassName?: string;
	grayColor?: boolean;
	regex?: 'NaturalNumbersAndFirstNotZero';
	suffix?: string;
	toFixed?: number;
}
export const TextBox = ({
	errorString,
	label,
	rightLabel,
	className,
	inputClassName,
	grayColor,
	value,
	regex,
	onChange,
	suffix,
	toFixed,
	...props
}: ITextBoxProps & InputHTMLAttributes<HTMLInputElement>) => {
	const [stateValue, setStateValue] = React.useState(value ?? '');
	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		let reg: RegExp = /(?:)/;
		switch (regex) {
			case 'NaturalNumbersAndFirstNotZero':
				reg = /^[1-9][0-9]*$/;
				break;
		}
		const newValue = event.target.value;
		if (newValue === '' || reg.test(newValue)) {
			setStateValue(newValue);
			onChange && onChange(event);
		} else {
			event.preventDefault();
		}
	};
	return (
		<div className={`${className ?? ''} ${inputClassName ? '' : 'TextBox'}`}>
			{label && (
				<label htmlFor={props.id || props.name} className="TextBox__label">
					{label}
				</label>
			)}
			<div
				className={`TextBox__inputWrapper ${
					grayColor ? 'TextBox__inputWrapper__gray' : ''
				} ${props.disabled ? 'TextBox__inputWrapper__disabled' : ''}`}
			>
				<input
					className={`${
						inputClassName ? inputClassName : 'TextBox__inputWrapper__input'
					} ${grayColor ? 'TextBox__inputWrapper__gray' : ''}`}
					value={regex ? stateValue : toFixed ? (+(value ?? 0)).toFixed(toFixed) : value}
					onChange={regex ? handleChange : onChange}
					{...props}
				/>
				{suffix && <span className="TextBox__inputWrapper__input__suffix">{suffix}</span>}
			</div>

			{rightLabel && (
				<>
					<label
						htmlFor={props.id || props.name}
						className="TextBox__label TextBox__labelRight"
					>
						{rightLabel}
					</label>
					<br />
				</>
			)}
			{errorString && <span className="FormikError">{errorString}</span>}
		</div>
	);
};
