import { IBeveragesEditorContainerOwnProps } from './BeveragesEditorContainer';
import { List } from 'immutable';
import { RootState } from '../../../../business/root-store';
import { createSelector } from '@reduxjs/toolkit';
import { getCustomerDietPlanDayMeals } from '../../../../business/diet/diet-selector';

import { DietPlanDayMeal, Meal } from '../../../../client';

const rootStateSelector = (state: RootState, props: IBeveragesEditorContainerOwnProps) => state;

export const getBeveragesSelector = createSelector(
	rootStateSelector,
	(state: RootState) => state.static.beverages
);

export const getWaterMealSelector = createSelector(
	getBeveragesSelector,
	(beverages: List<Meal>) => beverages.find((x) => x.name === 'Woda')!
);

export const getCurrentWaterSelector = createSelector(
	[getCustomerDietPlanDayMeals, getWaterMealSelector],
	(dpdms?: DietPlanDayMeal[] | null, waterMeal?: Meal) =>
		dpdms?.find((x) => x.mealId === waterMeal?.id)
);
