import { List } from 'immutable';
import { MoreIcon } from '../../../../../shared/assets/more-icon';
import React from 'react';
import { RefreshIcon } from '../../../../../shared/assets/refresh-icon';
import { TrenningIcon } from '../../../../../shared/assets/tranning-icon';
import { getFriendlyMealName } from '../../../../../shared/helpers';
import moment from 'moment';

import { MealTypeEnum, WeekViewDay, WeekViewDayMeal } from '../../../../../client';

interface IMealItemComponentProps {
	weekViewDay: WeekViewDay;
}

interface IMealItemComponentState {}

export class MealItemComponent extends React.PureComponent<
	IMealItemComponentProps,
	IMealItemComponentState
> {
	constructor(props: IMealItemComponentProps, state: IMealItemComponentState) {
		super(props, state);

		this.state = this.getInitialState();
	}

	public render(): JSX.Element {
		const { weekViewDay } = this.props;
		return (
			<div className="mealItem_container">
				<div className="mealItem_header">
					{moment(this.props.weekViewDay.dayDateUtc!).toDate().toLocaleString('pl-PL', {
						weekday: 'short',
						month: 'short',
						day: 'numeric'
					})}
					{weekViewDay.isTranningDay && <TrenningIcon className="mealItem_icons" />}
					<MoreIcon className="mealItem_icons" />
				</div>
				{!(weekViewDay == null || weekViewDay == undefined) &&
					weekViewDay.meals!.map((meal: WeekViewDayMeal, index: number) => (
						<div
							key={index}
							className={
								this.isLast(index)
									? 'mealItem_content mealItem_content_last'
									: 'mealItem_content'
							}
						>
							<div className="mealItem_type mealMargin">
								{getFriendlyMealName(MealTypeEnum[meal.mealType!])}
								<RefreshIcon />
							</div>
							<div className="mealItem_meal mealMargin">{meal.mealName}</div>
						</div>
					))}
			</div>
		);
	}

	private isLast = (currentIndex: number): boolean => {
		const indexOfLastItem: number = List(this.props.weekViewDay.meals!).count() - 1;
		return currentIndex === indexOfLastItem;
	};

	private getInitialState = (): IMealItemComponentState => {
		return {} as IMealItemComponentState;
	};
}
