import './RestaurantsList.scss';

import FlipMove from 'react-flip-move';
import React from 'react';
import { Restaurant } from '../../../../client';

interface IRestaurantsListComponentProps {
	allRestaurantsLength: number;
	restaurants: Restaurant[];
	currentPosition?: { lat: number; lng: number };
	handleOpenMealsModal: (
		isMealsModalVisible: boolean,
		restaurantClicked?: Restaurant,
		placeIdClicked?: number
	) => void;
}

interface IRestaurantsListComponentState {}

export class RestaurantsListComponent extends React.Component<
	IRestaurantsListComponentProps,
	IRestaurantsListComponentState
> {
	constructor(props: IRestaurantsListComponentProps, state: IRestaurantsListComponentState) {
		super(props, state);

		this.state = this.getInitialState();
	}

	public render(): JSX.Element {
		return (
			<div className="RestaurantsList">
				<h3 className="style-desktop">Restauracje współpracujące z DietDesigner</h3>
				<FlipMove className="RestaurantsList_Container">
					{this.props.restaurants.length > 0 ? (
						this.props.restaurants.map((restaurant: Restaurant) => {
							const wordsArray = [
								restaurant.postcode,
								restaurant.city,
								restaurant.street,
								restaurant.homeNumber,
								restaurant.apartmentNumber
							];
							const fullAddress = wordsArray.join(' ');
							const handleRestaurantClick = () => {
								this.props.handleOpenMealsModal(true, restaurant);
							};
							return (
								<div
									key={restaurant.id}
									className="RestaurantsList_Restaurant"
									onClick={handleRestaurantClick}
								>
									<div className="RestaurantsList_Restaurant_BasicInfo">
										<img
											className="RestaurantsList_Restaurant_BasicInfo_Logo"
											src={
												process.env.REACT_APP_FILES_URL +
												'/api/' +
												restaurant.logoUrl!
											}
										/>
										<div className="RestaurantsList_Restaurant_BasicInfo_Info">
											<h3>{restaurant.name}</h3>
											<span>{fullAddress}</span>
										</div>
									</div>
									<div className="RestaurantsList_Restaurant_Description">
										<h3>{restaurant.descriptionTitle}</h3>
										<span>{restaurant.description}</span>
									</div>
								</div>
							);
						})
					) : (
						<h3>
							{this.props.currentPosition
								? this.props.allRestaurantsLength === 0
									? 'W obrębie 30km od podanej lokalizacji nie ma żadnej współpracującej restauracji.'
									: 'Nie ma restauracji z wyszukiwaną frazą'
								: 'Podaj adres aby zobaczyć współpracujące restauracje w pobliżu.'}
						</h3>
					)}
				</FlipMove>
			</div>
		);
	}

	private getInitialState = (): IRestaurantsListComponentState => {
		return {} as IRestaurantsListComponentState;
	};
}
