import { DietApi } from '../../../../client';
import { GetApiConfig } from '../../../../business/api-client-config';
import { RootState } from '../../../../business/root-store';
import { ThunkAction } from 'redux-thunk';
import { loadDietPlanDay } from '../../../../business/diet/diet-action';
import moment from 'moment';
import { push } from 'connected-react-router';
import { routes } from '../../../../business/router/path-definition';

export const CopyDayEpic =
	(fromDay: Date, toDay: Date): ThunkAction<any, RootState, any, any> =>
	(dispatch, getState) => {
		const state = getState();
		const api = new DietApi(GetApiConfig(state));
		const dpd = state.diet.dietPlanDay;

		api.postCopyDietPlanDayMeals({
			fromDay: moment(fromDay).toISOString(),
			toDay: moment(toDay).toISOString()
		}).then(() => {
			if (moment(dpd?.dayDateUtc) === moment(toDay)) {
				dispatch(loadDietPlanDay(dpd?.dietDayId!));
			}
			dispatch(push(routes.home.path));
		});
	};
