import './credit-card-payment-form.scss';

import { ActionButton } from '../../../../atoms/ActionButton/ActionButton';
import { CheckBox } from '../../../../atoms/CheckBox/CheckBox';
import { TextBox } from '../../../../atoms/TextBox/TextBox';

export const CreditCardPaymentForm = () => {
	//empty function - for further refactor, when card payments be available
	const onClick = () => {};

	return (
		<div id="credit-card-form" className="animate__animated animate__fadeIn">
			<div className="title">Dane karty płatniczej</div>
			<form>
				<div>
					<TextBox
						name="credit-card-number"
						label="Numer karty płatniczej"
						placeholder="XXXX XXXX XXXX XXXX"
					/>
				</div>
				<div>
					<TextBox
						name="credit-card-month"
						label="Data ważności karty"
						placeholder="Miesiąc (mm)"
					/>
					<TextBox name="credit-card-year" placeholder="Rok (rrrr)" />
					<TextBox name="credit-card-cvv" label="Kod CVV2 / CVC2" />
				</div>
				<CheckBox checked={true} displayName="Akceptuję warunki płatności" />
				<ActionButton text="Użyj karty" onClick={onClick} />
			</form>
		</div>
	);
};
