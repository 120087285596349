import { DietPlanDayMeal } from '../../../client';
import { IDpdmWithImage } from './meal-card-interfaces';
import { IImageFile } from '../../../shared/interfaces/IImageFile';
import { IMealCardContainerOwnProps } from './meal-card-container';
import { RootState } from '../../../business/root-store';
import { createSelector } from 'reselect';
import { getMealImage } from './meal-card-helper';

const dietFilesSelector = (state: RootState, props: IMealCardContainerOwnProps) =>
	state.diet.files || [];

const getMeals = (state: RootState, props: IMealCardContainerOwnProps) => props.meals;

export const getImagesForDietPlanDayMeals = createSelector(
	dietFilesSelector,
	getMeals,
	(dietFiles: IImageFile[], dietPlanDayMeals: DietPlanDayMeal[]): IDpdmWithImage[] => {
		let meals: IDpdmWithImage[] = [];

		dietPlanDayMeals.map((dayMeal: DietPlanDayMeal) => {
			const currentImage = getMealImage(dietFiles, dayMeal.meal?.imagesCatalog!);
			const curentDietPlanDayMeal = dietPlanDayMeals.find(
				(a: DietPlanDayMeal) => a.id === dayMeal.id
			);

			meals.push({ imageUrl: currentImage, dietPlanDayMeal: curentDietPlanDayMeal! });
		});
		return meals;
	}
);
