import { Reducer } from 'redux';
import { ShoppingList } from '../../../../client';

import {
	IShoppingItemBoughtAction,
	IShoppingListSavedAction,
	ShoppingItem_Bought,
	ShoppingList_Saved
} from './shopping-list-actions';

type ReducerActions = IShoppingListSavedAction | IShoppingItemBoughtAction;

export interface IShoppingListReduxState {
	shoppingList?: ShoppingList;
}

const INITIAL_STATE: IShoppingListReduxState = {
	shoppingList: undefined
};

export const shoppingListReducer: Reducer<IShoppingListReduxState, ReducerActions> = (
	state = INITIAL_STATE,
	action
) => {
	switch (action.type) {
		case ShoppingList_Saved:
			return handleShoppingListSaved(state, action);
		case ShoppingItem_Bought:
			return handleShoppingItemBought(state, action);
		default:
			return state;
	}
};

const handleShoppingListSaved = (
	state: IShoppingListReduxState,
	action: IShoppingListSavedAction
): IShoppingListReduxState => {
	return {
		...state,
		shoppingList: action.shoppingList
	};
};

const handleShoppingItemBought = (
	state: IShoppingListReduxState,
	action: IShoppingItemBoughtAction
): IShoppingListReduxState => {
	let shoppingItems = state.shoppingList?.shoppingItems;
	const index = shoppingItems?.indexOf(
		shoppingItems?.find((x) => x.id === action.shoppingItemId)!
	)!;
	let itemToChange = shoppingItems?.find((x) => x.id === action.shoppingItemId)!;
	itemToChange.isBought = itemToChange?.isBought ? !itemToChange.isBought : true;
	shoppingItems?.splice(index, 1, itemToChange);

	return {
		...state,
		shoppingList: { ...state.shoppingList, shoppingItems: shoppingItems }
	};
};
