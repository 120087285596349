import './InstagramSlider.scss';

import { Headline } from '../../../atoms/headline/headline';
import React from 'react';
import { Slider } from '../../../atoms/slider/slider';

export const InstagramSlider: React.FC = (props) => {
	const { children, ...restProps } = props;
	// todo: remove style-mobile, style-desktop
	return (
		<div className="InstagramSlider">
			<div className="line-decor" />
			<Headline text="Śledź nas na Instagramie!" />
			<div className="InstagramSlider_photosWrapper style-desktop">
				<img className="InstagramSlider_photosWrapper_photo" src="/img/instagram/I.png" />
				<img className="InstagramSlider_photosWrapper_photo" src="/img/instagram/II.png" />
				<img className="InstagramSlider_photosWrapper_photo" src="/img/instagram/III.png" />
				<img className="InstagramSlider_photosWrapper_photo" src="/img/instagram/IV.png" />
			</div>
			<Slider
				className="style-mobile"
				items={[
					<div className="InstagramSlider_photoInSlider">
						<img src="/img/instagram/I.png" />
					</div>,
					<div className="InstagramSlider_photoInSlider">
						<img src="/img/instagram/II.png" />
					</div>,
					<div className="InstagramSlider_photoInSlider">
						<img src="/img/instagram/III.png" />
					</div>,
					<div className="InstagramSlider_photoInSlider">
						<img src="/img/instagram/IV.png" />
					</div>
				]}
				timer={7000}
			/>
		</div>
	);
};
