import './WizardCard.scss';

import React from 'react';

interface UserInterviewCardProps {
	id: string;
	className?: string;
	header?: string;
	onClick?: (event: any) => void;
	onFocus?: (event: any) => void;
	onChange?: (event: any) => void;
	children?: JSX.Element | JSX.Element[];
}

export const WizardCard: React.FC<UserInterviewCardProps> = (props: UserInterviewCardProps) => {
	return (
		<li
			id={props.id}
			className={props.className ? 'WizardCard ' + props.className : 'WizardCard'}
			onFocus={props.onFocus}
			onClick={props.onClick}
			onChange={props.onChange}
		>
			<div className="WizardCard__header">
				<h3 className="WizardCard__headline">{props.header}</h3>
			</div>
			<div className="WizardCard__content">{props.children}</div>
		</li>
	);
};
