import React from 'react';

class ChevronLeftIconComponent extends React.Component {
	public render(): JSX.Element {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="13"
				height="13"
				viewBox="1 0 7 10"
				fill="none"
			>
				<path
					d="M6.10762 1.18872L1.90332 5.39302L6.10762 9.59732"
					stroke="white"
					strokeWidth="1.68172"
				/>
			</svg>
		);
	}
}

export const ChevronLeftIcon = ChevronLeftIconComponent;
