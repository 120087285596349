import { Reducer } from 'redux';

import {
	Customer,
	CustomerDietPreference,
	CustomerMealServingTagPreference,
	CustomerSubs,
	CustomerTrainingPreferences,
	DietObjective,
	Gender,
	TagsMealsForDietServings
} from '../../client';
import { CustomerSettingsAction, customerSettingsActionNames } from './customer-settings-actions';

export const INITIAL_STATE = {
	//customerSettings: {},
	customerBasicInformation: {},
	customerTrainingPreferences: {},
	subscriptions: {},
	tagsMealsForCustomer: [] as TagsMealsForDietServings[]
} as CustomerSettigsState;

export type CustomerBasicInformation = {
	gender?: Gender;
	weight?: number | null;
	height?: number | null;
	waist?: number | null;
	yearOfBirth?: number | null;
	settingsId?: string;
	customer?: Customer;
};

export type CustomerSettigsState = {
	customerBasicInformation: CustomerBasicInformation;
	customerId?: number;
	customerDietPrefence?: CustomerDietPreference;
	dietObjective?: DietObjective;
	dietStartUtc?: Date;
	customerMealServingTagPreferences?: Array<CustomerMealServingTagPreference> | null;
	//customerSettings: CustomerSettings;
	subscriptions: CustomerSubs[];
	customerTrainingPreferences: CustomerTrainingPreferences;
	tagsMealsForCustomer: TagsMealsForDietServings[];
	user: {};
};

export const customerSettingsReducer: Reducer<CustomerSettigsState, CustomerSettingsAction> = (
	state = INITIAL_STATE,
	action
) => {
	switch (action.type) {
		case customerSettingsActionNames.LOAD_CUSTOMER_SETTINGS: {
			return {
				...state,
				dietObjective: action.payload.settings.dietObjective,
				customerBasicInformation: {
					...action.payload.settings,
					settingsId: action.payload.settings.settingsId
				}
			};
		}
		case customerSettingsActionNames.LOAD_CUSTOMER: {
			return {
				...state,
				customerBasicInformation: {
					...state.customerBasicInformation,
					customer: action.payload.customer
				}
			};
		}
		// todo: create actions depending on user settings groups instead of particular
		// todo: and then: dispatch(customerSettingsActions.setCustomerGender(Gender.Female)) or via container;
		// todo: remove "any"
		// todo: divide user settings groups on descent components
		case customerSettingsActionNames.SET_CUSTOMER_SETTINGS_GENDER: {
			return {
				...state,
				customerBasicInformation: {
					...state.customerBasicInformation,
					gender: action.payload.gender
				}
			};
		}
		case customerSettingsActionNames.SET_CUSTOMER_SETTINGS_AGE: {
			return {
				...state,
				customerBasicInformation: {
					...state.customerBasicInformation,
					yearOfBirth: action.payload.yearOfBirth
				}
			};
		}
		case customerSettingsActionNames.SET_CUSTOMER_SETTINGS_HEIGHT: {
			return {
				...state,
				customerBasicInformation: {
					...state.customerBasicInformation,
					height: action.payload.height
				}
			};
		}
		case customerSettingsActionNames.SET_CUSTOMER_SETTINGS_WEIGHT: {
			return {
				...state,
				customerBasicInformation: {
					...state.customerBasicInformation,
					weight: action.payload.weight
				}
			};
		}
		case customerSettingsActionNames.SET_CUSTOMER_SETTINGS_DIET_OBJECTIVE: {
			return {
				...state,
				dietObjective: action.payload.objective
			};
		}
		case customerSettingsActionNames.LOAD_CUSTOMER_SUBSCRIPTIONS: {
			return {
				...state,
				subscriptions: action.payload.subscriptions
			};
		}
		case customerSettingsActionNames.LOAD_CUSTOMER_TRAINING_PREFERENCES: {
			return {
				...state,
				customerTrainingPreferences: action.payload.trainingPreferences
			};
		}
		case customerSettingsActionNames.LOAD_CUSTOMER_TAG_MEALS_SERVING_FOR_DIET_SETTINGS: {
			return {
				...state,
				tagsMealsForCustomer: [...state.tagsMealsForCustomer, action.payload.settings]
			};
		}
		case customerSettingsActionNames.LOAD_CUSTOMER_TAGS_MEALS_SERVING_FOR_DIET_SETTINGS: {
			return {
				...state,
				tagsMealsForCustomer: action.payload.settings
			};
		}
		case customerSettingsActionNames.REMOVE_CUSTOMER_TAG_MEAL_FROM_DIET_SERVING_PREFERENCES: {
			return {
				...state,
				tagsMealsForCustomer: state.tagsMealsForCustomer.filter(
					(x) => x.tagId !== action.payload.tagId
				)
			};
		}
		case customerSettingsActionNames.UPDATE_CUSTOMER_TAG_MEALS_SERVING_FOR_DIET_SETTINGS: {
			if (!(action.payload.settings == null || action.payload.settings == undefined)) {
				return {
					...state,
					tagsMealsForCustomer: [
						...state.tagsMealsForCustomer.slice(
							0,
							state.tagsMealsForCustomer.findIndex(
								(x) => x.tagId === action.payload.tagId
							)
						),
						action.payload.settings,
						...state.tagsMealsForCustomer.slice(
							state.tagsMealsForCustomer.findIndex(
								(x) => x.tagId === action.payload.tagId
							) + 1,
							state.tagsMealsForCustomer.length
						)
					]
				};
			} else {
				return {
					...state,
					tagsMealsForCustomer: [
						...state.tagsMealsForCustomer.slice(
							0,
							state.tagsMealsForCustomer.findIndex(
								(x) => x.tagId === action.payload.tagId
							)
						),
						...state.tagsMealsForCustomer.slice(
							state.tagsMealsForCustomer.findIndex(
								(x) => x.tagId === action.payload.tagId
							) + 1,
							state.tagsMealsForCustomer.length
						)
					]
				};
			}
		}
		case customerSettingsActionNames.SET_CUSTOMER_DIET_START_DATE: {
			return {
				...state,
				dietStartUtc: action.payload.date
			};
		}
		case customerSettingsActionNames.SET_CUSTOMER_ID: {
			return {
				...state,
				customerId: action.payload.customerId
			};
		}
		case customerSettingsActionNames.LOAD_USER_SETTINGS: {
			return {
				...state,
				user: action.payload.user
			};
		}
		default:
			return state;
	}
};
