import './auth-view.scss';

import { AuthCardContainer } from '../../molecules/AuthCardComponent';
import { AuthTypeKey } from '../../../business/router/interfaces';
import { DietSummaryContainer } from '../OrderMenuView/DietSummaryContainer';
import { ILogin } from '../../molecules/AuthCardComponent/interfaces';
import { routes } from '../../../business/router/path-definition';
import { actions as toastrActions } from 'react-redux-toastr';
import { useDispatch } from 'react-redux';

import {
	activateCreatedAccount,
	activateAccount as activateMail,
	changePassword,
	handleResetPassword,
	logIn,
	registerNewUserApi,
	resetPassword
} from '../../../business/auth/auth-actions';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

export const AuthView = () => {
	const [isRegistered, setIsRegistered] = useState<boolean | undefined>(undefined);
	const history = useHistory();
	const dispatch = useDispatch();
	const { authType, guid } = useParams<{ authType?: AuthTypeKey; guid?: string }>();

	const setIsUserRegisteredHandler = (isUserRegistered: boolean) => {
		if (isUserRegistered) {
			history.push(routes.auth.login.path);
		} else {
			history.push(routes.auth.register.path);
		}
	};

	const onLogErrorHandler = (message: string) => {
		dispatch(toastrActions.add({ message, type: 'error' }));
	};

	const onUserLoginHandler = (data: ILogin) => {
		const { login, password } = data;
		dispatch(logIn(login, password));
	};

	const onUserRegistrationHandler = (data: ILogin) => {
		const { login, password } = data;
		dispatch(registerNewUserApi(login, password));
	};

	const onResetPasswordHandler = (email: string) => {
		dispatch(resetPassword(email));
	};

	const onChangePasswordHandler = (oldPassword: string, newPassword: string) => {
		dispatch(changePassword(oldPassword, newPassword));
	};

	const onHandleResetPassword = (
		newPassword: string,
		retypeNewPassword: string,
		guid: string
	) => {
		dispatch(handleResetPassword(newPassword, retypeNewPassword, guid));
	};

	const handleActivateCreatedAccount = (
		newPassword: string,
		retypeNewPassword: string,
		guid: string
	) => {
		dispatch(activateCreatedAccount(newPassword, retypeNewPassword, guid));
	};

	const resetPasswordHandler = () => {
		history.push(routes.auth.resetPassword.path);
	};

	const backToLogin = () => {
		history.push(routes.auth.login.path);
	};

	useEffect(() => {
		if (authType === undefined) {
			history.push(routes.auth.login.path);
		}

		if (authType === 'register') {
			setIsRegistered(false);
		} else {
			setIsRegistered(true);
		}
	}, [authType]);

	useEffect(() => {
		if (authType === 'activatemail' && !!guid) {
			dispatch(activateMail(guid));
		}
	}, [authType, guid]);

	return (
		<div id="auth-view">
			<AuthCardContainer
				formDisplayed={authType}
				isUserRegistered={isRegistered}
				guid={guid ?? ''}
				key={guid}
				logError={onLogErrorHandler}
				loginUser={onUserLoginHandler}
				registerUser={onUserRegistrationHandler}
				setIsUserRegistered={setIsUserRegisteredHandler}
				resetPasswordHandler={resetPasswordHandler}
				backToLogin={backToLogin}
				resetPassword={onResetPasswordHandler}
				changePassword={onChangePasswordHandler}
				handleResetPassword={onHandleResetPassword}
				handleActivateCreatedAccount={handleActivateCreatedAccount}
			/>
			<DietSummaryContainer adBanner />
		</div>
	);
};
