import { GetApiConfig } from '../../../../../business/api-client-config';
import { RootState } from '../../../../../business/root-store';
import { ThunkAction } from 'redux-thunk';
import { isEmptyNullOrUndefinedOrDefaultNumber } from '../../../../../shared/helpers';
import { setCurrentMeals } from '../../Meal/meal';

import { AddOrUpdateCustomProduct, FoodApi } from '../../../../../client';

export const AddCustomProduct =
	(customProduct: AddOrUpdateCustomProduct): ThunkAction<any, RootState, any, any> =>
	(dispatch, getState) => {
		const state = getState();
		const api = new FoodApi(GetApiConfig(state));
		const currentMeals = state.dayView.meal.currentMeals;
		api.addOrUpdateCustomProduct(customProduct).then((x) => {
			const mfp = x.data.mealFoodProduct!;
			document.dispatchEvent(new CustomEvent('closeModal', {}));
			dispatch(
				setCurrentMeals({
					...currentMeals,
					meals: currentMeals.meals.map((stateMeal, stateMealIndex) => {
						if (stateMealIndex !== currentMeals.mealIndex) {
							return stateMeal;
						}
						return {
							...stateMeal,
							name:
								stateMeal.isCustomMeal &&
								stateMeal.mealFoodProducts?.length === 0 &&
								isEmptyNullOrUndefinedOrDefaultNumber(stateMeal.name)
									? mfp.foodProduct?.name
									: stateMeal.name,
							mealFoodProducts: [...(stateMeal.mealFoodProducts ?? []), mfp]
						};
					})
				})
			);
		});
	};
