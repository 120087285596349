import * as React from 'react';

import { AuthCardComponent } from './AuthCardComponent';
import { IAuthContainerProps } from './interfaces';

export const AuthCardContainer = ({
	formDisplayed,
	isUserRegistered,
	guid,
	setIsUserRegistered,
	logError,
	loginUser,
	registerUser,
	resetPasswordHandler,
	resetPassword,
	backToLogin,
	changePassword,
	handleResetPassword,
	handleActivateCreatedAccount
}: IAuthContainerProps) => {
	const onSetUserHasAccount = () => {
		setIsUserRegistered(true);
	};

	const onSetUserHasNoAccount = () => {
		setIsUserRegistered(false);
	};

	return (
		<AuthCardComponent
			formDisplayed={formDisplayed}
			isUserRegistered={isUserRegistered}
			guid={guid}
			key={guid}
			onSetUserHasAccount={onSetUserHasAccount}
			onSetUserHasNoAccount={onSetUserHasNoAccount}
			logError={logError}
			loginUser={loginUser}
			registerUser={registerUser}
			resetPasswordHandler={resetPasswordHandler}
			backToLogin={backToLogin}
			resetPassword={resetPassword}
			changePassword={changePassword}
			handleResetPassword={handleResetPassword}
			handleActivateCreatedAccount={handleActivateCreatedAccount}
		/>
	);
};
