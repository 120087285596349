import './RestaurantsMap.scss';

import { Restaurant } from '../../../../client';
import { RestaurantsMapComponent } from './RestaurantsMapComponent';

import React, { ReactElement } from 'react';
import { Status, Wrapper } from '@googlemaps/react-wrapper';

interface IRestaurantsMapWrapperComponentProps {
	restaurants: Restaurant[];
	geolocationButtonText?: string;
	currentPosition?: { lat: number; lng: number };
	handleOpenMealsModal?: (
		isMealsModalVisible: boolean,
		restaurantClicked?: Restaurant,
		placeIdClicked?: number
	) => void;
	handleSetCurrentPosition?: (lat: number, lng: number) => void;
	onIdle?: (lat: number, lng: number) => void;
	onClickMap?: (lat: number, lng: number) => void;
}

interface IRestaurantsMapWrapperComponentState {}
export class RestaurantsMapWrapperComponent extends React.Component<
	IRestaurantsMapWrapperComponentProps,
	IRestaurantsMapWrapperComponentState
> {
	constructor(
		props: IRestaurantsMapWrapperComponentProps,
		state: IRestaurantsMapWrapperComponentState
	) {
		super(props, state);
		this.state = this.getInitialState();
	}

	public render(): JSX.Element {
		return (
			<div className="RestaurantsMap">
				<Wrapper
					apiKey={process.env.REACT_APP_GOOGLEMAPS_API_KEY ?? ''}
					language="pl"
					render={this.renderStatus}
				>
					<RestaurantsMapComponent
						restaurants={this.props.restaurants}
						currentPosition={this.props.currentPosition}
						// checkIfPlaceIdExistInArray={this.checkIfPlaceIdExistInArray}
						handleOpenMealsModal={this.props.handleOpenMealsModal}
						handleSetCurrentPosition={this.props.handleSetCurrentPosition}
						onIdle={this.props.onIdle}
						onClickMap={this.props.onClickMap}
						geolocationButtonText={this.props.geolocationButtonText}
					/>
				</Wrapper>
			</div>
		);
	}

	private renderStatus = (status: Status): ReactElement => {
		if (status === Status.LOADING) return <h3>Ładujemy mapę..</h3>;
		if (status === Status.FAILURE) return <h3>Nie udało się pobrać mapy</h3>;
		return <></>;
	};

	private getInitialState = (): IRestaurantsMapWrapperComponentState => {
		return {} as IRestaurantsMapWrapperComponentState;
	};
}
