import { GetApiConfig } from '../../../../business/api-client-config';
import { RootState } from '../../../../business/root-store';
import { ThunkAction } from 'redux-thunk';
import { actions } from 'react-redux-toastr';

import { DietApi, PostShoppingItemForDietPlan } from '../../../../client';
import { shoppingItemBought, shoppingListSaved } from './shopping-list-actions';

export const postShoppingItem =
	(shoppingItemId: number): ThunkAction<any, RootState, any, any> =>
	(dispatch, getState) => {
		const store: RootState = getState();
		const api = new DietApi(GetApiConfig(store));
		const body: PostShoppingItemForDietPlan = {
			shoppingItemId: shoppingItemId
		};

		api.postShoppingItemForDietPlan(body)
			.then(() => {
				dispatch(shoppingItemBought(shoppingItemId));
			})
			.catch((c) => {
				console.log(
					actions.add({
						message: 'Cannot save shopping list.',
						type: 'error'
					})
				);
			});
	};

export const fetchShoppingList =
	(): ThunkAction<any, RootState, any, any> => (dispatch, getState) => {
		const store: RootState = getState();
		const api = new DietApi(GetApiConfig(store));

		api.getShoppingItemForDietPlan()
			.then((response) => {
				dispatch(shoppingListSaved(response.data.shoppingList!));
			})
			.catch((c) => {
				console.log(
					actions.add({
						message: 'Cannot fetch shopping list.',
						type: 'error'
					})
				);
			});
	};
