import './day-meals.scss';

import { Beverages } from '../Beverages/beverages-container';
import { BeveragesEditor } from '../Beverages/BeverageEditor/BeveragesEditorContainer';
import { CopyMeal } from '../MealCard/CopyMeal/CopyMealContainer';
import { CustomMeal } from '../MealCard/CustomMeal/CustomMealContainer';
import { CustomProduct } from '../MealCard/CustomMeal/CustomProduct/CustomProductContainer';
import { ExchangeMeal } from '../MealCard/ExchangeMeal/ExchangeMealContainer';
import { ICurrentMeals } from '../MealCard/Meal/meal-interfaces';
import { IGroupedMeals } from '../../../business/diet/diet-selector';
import { MealCard } from '../MealCard/meal-card-container';
import { Modal } from '../../atoms/Modal/Modal';
import React from 'react';
import { Slide } from '../../atoms/slider/slide';

import { DietPlanDayMeal, Meal } from '../../../client';
import SlickSlider, { Settings } from 'react-slick';
import { getFriendlyMealName, isEmptyNullOrUndefined } from '../../../shared/helpers';

const sliderSettings: Settings = {
	arrows: false,
	pauseOnHover: true,
	autoplay: false,
	infinite: false,
	speed: 500,
	slidesToShow: 1,
	slidesToScroll: 1,
	dots: false
};

interface IDayMealsComponentProps {
	meals: IGroupedMeals[];
	beverages: DietPlanDayMeal[];
	currentMeals: ICurrentMeals;
	setCurrentMeals: (currentMeals: ICurrentMeals) => void;
	addOrUpdateCustomerMeals: (meals: Meal[]) => void;
}

interface IDayMealsComponentState {
	showCopyMealModal: boolean;
	showCustomMealModal: boolean;
	showExistingMealModal: boolean;
	showAddMealModal: boolean;
	showEditMealModal: boolean;
	showCustomProductModal: boolean;
	showBeveragesEditorModal: boolean;
}

export class DayMealsComponent extends React.Component<
	IDayMealsComponentProps,
	IDayMealsComponentState
> {
	constructor(props: IDayMealsComponentProps, state: IDayMealsComponentState) {
		super(props, state);
		this.state = this.getInitialState();
	}

	private handleCustomMealModalClicked = (value: boolean) => {
		this.setState({
			...this.state,
			showCustomMealModal: value
		});
	};

	private handleExistingMealModalClicked = (value: boolean) => {
		this.setState({ ...this.state, showExistingMealModal: value });
	};

	private handleCopyClicked = (value: boolean): void => {
		this.setState({ ...this.state, showCopyMealModal: value });
	};

	private handleBeveragesEditorModalClicked = (value: boolean): void => {
		this.setState({ ...this.state, showBeveragesEditorModal: value });
	};

	private handleCustomProductModalClicked = (value: boolean): void => {
		this.setState({
			...this.state,
			showCustomProductModal: value,
			showCustomMealModal: !value
		});
	};

	public render(): JSX.Element {
		return (
			<>
				<div className="style-mobile">
					<SlickSlider {...sliderSettings} ref={(slider) => slider?.slickGoTo(1)}>
						{!isEmptyNullOrUndefined(this.props.beverages) && (
							<Beverages
								currentMeals={this.props.currentMeals}
								beverages={this.props.beverages}
								handleBeveragesEditorModalClicked={
									this.handleBeveragesEditorModalClicked
								}
								handleCustomMealModalClicked={this.handleCustomMealModalClicked}
							/>
						)}
						{!isEmptyNullOrUndefined(this.props.meals) ? (
							this.props.meals.map((groupedMeal: IGroupedMeals) => {
								return (
									<MealCard
										currentMeals={this.props.currentMeals}
										key={'MealCard' + groupedMeal.key}
										title={groupedMeal.dayNameMeal}
										mealType={groupedMeal.key}
										meals={groupedMeal.values}
										onCopyClicked={() => this.handleCopyClicked(true)}
										onExistingMealModalClicked={() =>
											this.handleExistingMealModalClicked(true)
										}
										onCustomMealModalClicked={() =>
											this.handleCustomMealModalClicked(true)
										}
									/>
								);
							})
						) : (
							<></>
						)}
					</SlickSlider>
				</div>
				<div className="style-desktop day-meals__desktop-wrapper">
					{!isEmptyNullOrUndefined(this.props.beverages) && (
						<Beverages
							currentMeals={this.props.currentMeals}
							beverages={this.props.beverages}
							handleBeveragesEditorModalClicked={
								this.handleBeveragesEditorModalClicked
							}
							handleCustomMealModalClicked={this.handleCustomMealModalClicked}
						/>
					)}
					{!isEmptyNullOrUndefined(this.props.meals) &&
						this.props.meals.map((groupedMeal: IGroupedMeals) => {
							return (
								<Slide key={'slide_' + groupedMeal.key}>
									<MealCard
										key={'MealCard' + groupedMeal.key}
										title={groupedMeal.dayNameMeal}
										mealType={groupedMeal.key}
										meals={groupedMeal.values}
										currentMeals={this.props.currentMeals}
										onCopyClicked={this.handleCopyClicked}
										onExistingMealModalClicked={
											this.handleExistingMealModalClicked
										}
										onCustomMealModalClicked={this.handleCustomMealModalClicked}
									/>
								</Slide>
							);
						})}
				</div>
				{this.state.showCopyMealModal && (
					<Modal
						show={this.state.showCopyMealModal}
						handleStateChange={() => this.handleCopyClicked(false)}
					>
						<CopyMeal />
					</Modal>
				)}
				{this.state.showExistingMealModal && (
					<Modal
						show={this.state.showExistingMealModal}
						handleStateChange={() => this.handleExistingMealModalClicked(false)}
					>
						<ExchangeMeal onMealAdd={() => this.handleCustomMealModalClicked(true)} />
					</Modal>
				)}
				{this.state.showCustomMealModal && (
					<Modal
						show={this.state.showCustomMealModal}
						handleStateChange={() => this.handleCustomMealModalClicked(false)}
					>
						<CustomMeal
							disableCustomMacros
							headerText={
								'Edytuj ' + getFriendlyMealName(this.props.currentMeals.mealType)
							}
							submitCustomMeals={this.props.addOrUpdateCustomerMeals}
							handleCustomProductModalClicked={this.handleCustomProductModalClicked}
						/>
					</Modal>
				)}
				{this.state.showCustomProductModal && (
					<Modal
						show={this.state.showCustomProductModal}
						handleStateChange={() => this.handleCustomProductModalClicked(false)}
					>
						<CustomProduct />
					</Modal>
				)}
				{this.state.showBeveragesEditorModal && (
					<Modal
						show={this.state.showBeveragesEditorModal}
						handleStateChange={this.handleBeveragesEditorModalClicked}
					>
						<BeveragesEditor />
					</Modal>
				)}
			</>
		);
	}

	private getInitialState = (): IDayMealsComponentState => {
		return {
			showAddMealModal: false,
			showBeveragesEditorModal: false,
			showCopyMealModal: false,
			showCustomMealModal: false,
			showCustomProductModal: false,
			showEditMealModal: false,
			showExistingMealModal: false
		} as IDayMealsComponentState;
	};
}
