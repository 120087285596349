import * as React from 'react';
import * as Redux from 'redux';

import { RootState } from '../../../../../business/root-store';
import { ShoppingListDateRangeComponent } from './shopping-list-date-range-component';
import { connect } from 'react-redux';
import { fetchShoppingList } from '../shopping-list-epics';
import { getCustomerDietPlan } from '../../../../../business/diet/diet-selector';
import moment from 'moment';
import { setShoppingList } from './shopping-list-date-range-epics';

import { AnyAction, Dispatch } from 'redux';

interface IShoppingDateRangeContainerReduxMergedProps {
	minDateUtc: Date;
	maxDateUtc: Date;
}

interface IShoppingDateRangeContainerOwnProps {
	startDateUtc: Date;
	endDateUtc: Date;
	pdfDownloadLink: JSX.Element;
}

interface IShoppingDateRangeContainerDispatchProps {
	onDateSelected: (startDateUtc: Date, endDateUtc: Date) => void;
	getShoppingList: () => void;
}

interface IShoppingDateRangeContainerProps
	extends IShoppingDateRangeContainerOwnProps,
		IShoppingDateRangeContainerDispatchProps,
		IShoppingDateRangeContainerReduxMergedProps {}

interface IShoppingDateRangeComponentState {}

class ShoppingListDateRangeContainer extends React.Component<
	IShoppingDateRangeContainerProps,
	IShoppingDateRangeComponentState
> {
	constructor(props: IShoppingDateRangeContainerProps, state: IShoppingDateRangeComponentState) {
		super(props, state);

		this.state = this.getInitialState();
	}

	public render(): JSX.Element {
		return (
			<ShoppingListDateRangeComponent
				minDateUtc={this.props.minDateUtc}
				maxDateUtc={this.props.maxDateUtc}
				startDateUtc={this.props.startDateUtc}
				endDateUtc={this.props.endDateUtc}
				onDateSelected={this.props.onDateSelected}
				pdfDownloadLink={this.props.pdfDownloadLink}
			/>
		);
	}

	public componentDidMount(): void {}

	public static mapStateToProps = (
		state: RootState,
		ownProps: IShoppingDateRangeContainerOwnProps
	): IShoppingDateRangeContainerReduxMergedProps => {
		const customerDietPlan = getCustomerDietPlan(state);
		return {
			minDateUtc: moment.utc(customerDietPlan?.startDate).toDate(),
			maxDateUtc: moment.utc(customerDietPlan?.endDate).toDate()
		};
	};

	public static mapDispatchToProps = (
		dispatch: Dispatch<AnyAction>
	): IShoppingDateRangeContainerDispatchProps => {
		return {
			...Redux.bindActionCreators(
				{
					onDateSelected: setShoppingList,
					getShoppingList: fetchShoppingList
				},
				dispatch
			)
		};
	};

	private getInitialState = (): IShoppingDateRangeComponentState => {
		return {} as IShoppingDateRangeComponentState;
	};
}
export const ShoppingDateRange = connect(
	ShoppingListDateRangeContainer.mapStateToProps,
	ShoppingListDateRangeContainer.mapDispatchToProps
)(ShoppingListDateRangeContainer);
