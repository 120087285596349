import * as React from 'react';
import * as Redux from 'redux';

import { BlogComponent } from './BlogComponent';
import { Document } from '../../../client';
import { RootState } from '../../../business/root-store';
import { connect } from 'react-redux';
import { getBlogDocuments } from './BlogEpics';
import { getBlogDocumentsSelector } from '../../molecules/staticData/staticDataSelectors';

import { AnyAction, Dispatch } from 'redux';

interface IBlogContainerReduxMergedProps {
	blogDocuments: Document[];
}

interface IBlogContainerOwnProps {}

interface IBlogContainerDispatchProps {
	getBlogDocuments: () => void;
}

interface IBlogContainerProps
	extends IBlogContainerOwnProps,
		IBlogContainerDispatchProps,
		IBlogContainerReduxMergedProps {}

interface IBlogContainerState {}

class BlogContainer extends React.Component<IBlogContainerProps, IBlogContainerState> {
	constructor(props: IBlogContainerProps, state: IBlogContainerState) {
		super(props, state);

		this.state = this.getInitialState();

		if (this.props.blogDocuments.length === 0) {
			this.props.getBlogDocuments();
		}
	}

	public render(): JSX.Element {
		return (
			<BlogComponent
				blogDocuments={this.props.blogDocuments}
				key={this.props.blogDocuments.length}
			/>
		);
	}

	public componentDidMount(): void {}

	public static mapStateToProps = (
		state: RootState,
		ownProps: IBlogContainerOwnProps
	): IBlogContainerReduxMergedProps => {
		return {
			blogDocuments: getBlogDocumentsSelector(state)
		};
	};

	public static mapDispatchToProps = (
		dispatch: Dispatch<AnyAction>
	): IBlogContainerDispatchProps => {
		return {
			...Redux.bindActionCreators({ getBlogDocuments: getBlogDocuments }, dispatch)
		};
	};

	private getInitialState = (): IBlogContainerState => {
		return {} as IBlogContainerState;
	};
}
export const Blog = connect(
	BlogContainer.mapStateToProps,
	BlogContainer.mapDispatchToProps
)(BlogContainer);
