import './BasicSettings01StepForm.scss';

import { Field } from 'formik';
import { IWizzardStepProps } from '../UserInterviewInterfaces';
import { RadioSelect } from '../../../atoms/RadioSelect/RadioSelect';
import React from 'react';
import { TextBox } from '../../../atoms/TextBox/TextBox';
import { WizardCard } from '../../WizardCard/WizardCard';

export const BasicSettings01StepForm: React.FC<IWizzardStepProps> = ({
	formik,
	mode,
	scrollToStep
}) => {
	const handleScroll = () => {
		scrollToStep(1);
	};
	return (
		<WizardCard id="step01" header="1. Dane podstawowe" onClick={handleScroll}>
			<div className="UserInterview_row UserInterview_row_columnMobile">
				<Field
					name="step1.gender"
					label="Jestem kobietą"
					value="Female"
					checked={formik.values.step1?.gender === 'Female'}
					as={RadioSelect}
				/>
				<Field
					name="step1.gender"
					label="Jestem mężczyzną"
					value="Male"
					checked={formik.values.step1?.gender === 'Male'}
					as={RadioSelect}
				/>
			</div>
			<span className="FormikError">
				{formik.touched.step1?.gender && formik.errors.step1?.gender}
			</span>
			<Field
				name="step1.weight"
				label="Masa Ciała"
				placeholder="Ile ważysz?"
				regex="NaturalNumbersAndFirstNotZero"
				suffix="kg"
				grayColor
				errorString={formik.touched.step1?.weight && formik.errors.step1?.weight}
				as={TextBox}
				className="BasicSettings01StepForm_row"
			/>
			<Field
				name="step1.age"
				label="Wiek"
				placeholder="Ile masz lat?"
				regex="NaturalNumbersAndFirstNotZero"
				grayColor
				errorString={formik.touched.step1?.age && formik.errors.step1?.age}
				as={TextBox}
				className="BasicSettings01StepForm_row"
			/>
			<Field
				name="step1.height"
				label="Wzrost"
				placeholder="Jaki masz wzrost?"
				suffix="cm"
				regex="NaturalNumbersAndFirstNotZero"
				grayColor
				errorString={formik.touched.step1?.height && formik.errors.step1?.height}
				as={TextBox}
				className="BasicSettings01StepForm_row"
			/>
		</WizardCard>
	);
};
