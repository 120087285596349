import { List } from 'immutable';

import { CurrentOrder, Invoice, Order } from '../../../../client';

export const Invoices_Fetched = '@@User/Settings/Invoices/FETCHED';
export const CurrentOrder_Fetched = '@@User/Settings/CurrentOrder/FETCHED';
export const Orders_Fetched = '@@User/Settings/Orders/FETCHED';
export interface IInvoicesFetchedAction {
	type: typeof Invoices_Fetched;
	invoices: List<Invoice>;
}
export const invoicesFetched = (invoices: List<Invoice>): IInvoicesFetchedAction => ({
	type: Invoices_Fetched,
	invoices: invoices
});

export interface ICurrentOrderFetchedAction {
	type: typeof CurrentOrder_Fetched;
	currentOrder: CurrentOrder;
}

export const currentOrderFetched = (currentOrder: CurrentOrder): ICurrentOrderFetchedAction => ({
	type: CurrentOrder_Fetched,
	currentOrder: currentOrder
});

export interface IOrdersFetchedAction {
	type: typeof Orders_Fetched;
	orders: List<Order>;
}
export const ordersFetched = (orders: List<Order>): IOrdersFetchedAction => ({
	type: Orders_Fetched,
	orders: orders
});
