import './payment-status-view.scss';

import { ActionButton } from '../../atoms/ActionButton/ActionButton';
import { routes } from '../../../business/router/path-definition';

import { Redirect, Route, Switch, useHistory } from 'react-router-dom';

const PaymentSuccess = () => {
	const history = useHistory();

	const returnToHomePage = () => {
		history.push(routes.home.path);
	};

	return (
		<>
			<div className="circle success">Udało się!</div>
			<div>Dziękujemy za dokonanie płatności! </div>
			<div>Cieszymy się, że jesteś z nami.</div>
			<ActionButton text="Powrót" onClick={returnToHomePage} style={{ width: '180px' }} />
		</>
	);
};

const PaymentFailed = () => {
	const history = useHistory();

	const tryAgain = () => {
		history.push(routes.customer.order.plan.path);
	};
	return (
		<>
			<div className="circle failed">Ups!</div>
			<div>Przykro nam coś poszło nie tak!</div>
			<ActionButton
				text="Spróbuj jeszcze raz"
				onClick={tryAgain}
				style={{ width: '180px' }}
			/>
		</>
	);
};

export const PaymentStatusView = () => {
	return (
		<div id="payment-status-container">
			<Switch>
				<Route
					exact={false}
					path={routes.customer.order.paymentStatus.success.path}
					component={PaymentSuccess}
				/>
				<Route
					exact={true}
					path={routes.customer.order.paymentStatus.negative.path}
					component={PaymentFailed}
				/>
				<Redirect
					from={routes.customer.order.paymentStatus.main.path}
					to={routes.home.path}
				/>
			</Switch>
		</div>
	);
};
