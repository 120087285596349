import * as React from 'react';
import * as Redux from 'redux';

import { List } from 'immutable';
import { MealTableComponent } from './MealTableComponent';
import { RootState } from '../../../../business/root-store';
import { WeekViewDay } from '../../../../client';
import { connect } from 'react-redux';

import { AnyAction, Dispatch } from 'redux';
import { fetchMealsForWeekWithRouterParams, saveSwappedMeals } from './MealTableEpics';

interface IMealTableContainerReduxMergedProps {
	weekViewDays: List<WeekViewDay>;
}

interface IMealTableContainerOwnProps {}

interface IMealTableContainerDispatchProps {
	postSwappedMeals(from: number, to: number): void;
	fetchMealsForWeek: () => void;
}

interface IMealTableContainerProps
	extends IMealTableContainerOwnProps,
		IMealTableContainerDispatchProps,
		IMealTableContainerReduxMergedProps {}

interface IMealTableComponentState {}

class MealTableContainer extends React.Component<
	IMealTableContainerProps,
	IMealTableComponentState
> {
	constructor(props: IMealTableContainerProps, state: IMealTableComponentState) {
		super(props, state);

		this.state = this.getInitialState();
	}

	public render(): JSX.Element {
		return (
			<div className="mealTableMain">
				<MealTableComponent
					weekViewDays={this.props.weekViewDays}
					postSwappedMeals={this.props.postSwappedMeals}
				/>
			</div>
		);
	}

	public componentDidMount(): void {
		if (this.props.weekViewDays.count() === 0) {
			this.props.fetchMealsForWeek();
		}
	}

	public static mapStateToProps = (
		state: RootState,
		ownProps: IMealTableContainerOwnProps
	): IMealTableContainerReduxMergedProps => {
		return {
			weekViewDays: state.weekView.mealsForWeek
		};
	};

	public static mapDispatchToProps = (
		dispatch: Dispatch<AnyAction>
	): IMealTableContainerDispatchProps => {
		return {
			...Redux.bindActionCreators(
				{
					postSwappedMeals: saveSwappedMeals,
					fetchMealsForWeek: fetchMealsForWeekWithRouterParams
				},
				dispatch
			)
		};
	};

	private getInitialState = (): IMealTableComponentState => {
		return {} as IMealTableComponentState;
	};
}
export const MealTable = connect(
	MealTableContainer.mapStateToProps,
	MealTableContainer.mapDispatchToProps
)(MealTableContainer);
