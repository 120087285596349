import React from 'react';

export const LogOutIcon = () => {
	return (
		<svg
			width="16"
			height="16"
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M6.99774 0.580432C5.34332 0.573874 3.73968 1.14505 2.46953 2.19327C1.19938 3.24148 0.344292 4.69942 0.0550101 6.31006C0.0480905 6.35171 0.0504888 6.39436 0.0620362 6.435C0.0735837 6.47564 0.0939995 6.51328 0.121847 6.54527C0.149694 6.57727 0.184295 6.60284 0.223215 6.62018C0.262135 6.63753 0.304427 6.64623 0.347114 6.64567H5.20773C5.2852 6.64567 5.3595 6.61524 5.41428 6.56108C5.46906 6.50691 5.49983 6.43345 5.49983 6.35685V4.27734C5.49979 4.16849 5.53085 4.06185 5.58944 3.9697C5.64802 3.87755 5.73175 3.80364 5.83097 3.75649C5.93019 3.70934 6.04086 3.69087 6.15024 3.7032C6.25961 3.71553 6.36323 3.75817 6.44917 3.8262L10.5386 7.061C10.6071 7.11511 10.6623 7.18376 10.7003 7.26185C10.7383 7.33994 10.758 7.42548 10.758 7.51213C10.758 7.59878 10.7383 7.68432 10.7003 7.76242C10.6623 7.84051 10.6071 7.90916 10.5386 7.96327L6.44917 11.1981C6.34552 11.28 6.21675 11.3246 6.08404 11.3246C5.92952 11.3232 5.78172 11.2619 5.67246 11.1539C5.56319 11.0458 5.5012 10.8997 5.49983 10.7469V8.66742C5.49983 8.59082 5.46906 8.51735 5.41428 8.46319C5.3595 8.40903 5.2852 8.3786 5.20773 8.3786H0.35062C0.308001 8.3786 0.265889 8.38773 0.227169 8.40533C0.188449 8.42294 0.154038 8.44862 0.126293 8.4806C0.0985483 8.51259 0.0781267 8.55013 0.0664279 8.59065C0.0547292 8.63117 0.05203 8.67371 0.0585154 8.71536C0.374477 10.4399 1.33939 11.9821 2.75905 13.0315C4.1787 14.0809 5.94769 14.5596 7.70996 14.3713C9.47222 14.183 11.0969 13.3417 12.2571 12.0166C13.4172 10.6915 14.0266 8.98113 13.9627 7.22967C13.8735 5.45379 13.1039 3.77809 11.8102 2.54306C10.5165 1.30802 8.79583 0.60628 6.99774 0.580432Z"
				fill="#FD6851"
			/>
		</svg>
	);
};
