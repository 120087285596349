import './TrainingBar.scss';

import { DecrementTrainingsIcon } from '../../../shared/assets/decrementTrainings-icon';
import { DietPlanDay } from '../../../client';
import { IncrementTrainingsIcon } from '../../../shared/assets/incrementTrainings-icon';
import React from 'react';

interface ITrainingBarComponentProps {
	currentDietPlanDay: DietPlanDay;
	addOrUpdateDietPlanDay: (dpd: DietPlanDay) => void;
}

interface ITrainingBarComponentState {}

export class TrainingBarComponent extends React.Component<
	ITrainingBarComponentProps,
	ITrainingBarComponentState
> {
	constructor(props: ITrainingBarComponentProps, state: ITrainingBarComponentState) {
		super(props, state);

		this.state = this.getInitialState();
	}

	public render(): JSX.Element {
		return (
			<div className="TrainingBar">
				<div className="TrainingBar__button" onClick={this.handleDecrement}>
					<DecrementTrainingsIcon />
				</div>
				<div className="TrainingBar__value">
					<div className="TrainingBar__value-number">
						{this.props.currentDietPlanDay?.doneTrainingsCount}
					</div>
					<div className="TrainingBar__value-label">
						{this.getCorrectFromDoneTrainingString(
							this.props.currentDietPlanDay?.doneTrainingsCount!
						) +
							' z ' +
							this.props.currentDietPlanDay.trainingsNumber +
							' ' +
							this.getCorrectFromTrainingString(
								this.props.currentDietPlanDay.trainingsNumber!
							)}
					</div>
				</div>
				<div className="TrainingBar__button" onClick={this.handleIncrement}>
					<IncrementTrainingsIcon />
				</div>
			</div>
		);
	}
	private getCorrectFromDoneTrainingString = (trainings: number) => {
		switch (trainings) {
			case 0:
				return 'treningów';
			case 1:
				return 'trening';
			case 2:
				return 'treningi';
			default:
				return 'treningi';
		}
	};

	private getCorrectFromTrainingString = (trainings: number) => {
		switch (trainings) {
			case 0:
				return 'zaplanowanych';
			case 1:
				return 'zaplanowanego';
			case 2:
				return 'zaplanowanych';
			default:
				return 'zaplanowanych';
		}
	};

	private handleIncrement = (e: any): void => {
		e.preventDefault();
		this.incrementOrDecrementTraining();
	};
	private handleDecrement = (e: any): void => {
		e.preventDefault();
		this.incrementOrDecrementTraining(true);
	};

	private incrementOrDecrementTraining = (decrement?: boolean) => {
		const currentDpd = this.props.currentDietPlanDay;
		if (decrement && currentDpd.doneTrainingsCount != 0) {
			this.props.addOrUpdateDietPlanDay({
				...currentDpd,
				doneTrainingsCount: currentDpd.doneTrainingsCount! - 1
			});
		} else if (!decrement && currentDpd.doneTrainingsCount! <= 1) {
			this.props.addOrUpdateDietPlanDay({
				...currentDpd,
				doneTrainingsCount: currentDpd.doneTrainingsCount! + 1
			});
		} else return;
	};

	private getInitialState = (): ITrainingBarComponentState => {
		return {} as ITrainingBarComponentState;
	};
}
