import './RestaurantPage.scss';

import { ActionButton } from '../../../atoms/ActionButton/ActionButton';
import ActionLink from '../../../atoms/action-link/action-link';
import { BackLink } from '../../../atoms/back-link/back-link';
import FlipMove from 'react-flip-move';
import { IImageFile } from '../../../../shared/interfaces/IImageFile';
import { IngredientsIcon } from '../../../../shared/assets/ingredients-icon';
import { Modal } from '../../../atoms/Modal/Modal';
import React from 'react';
import { RestaurantPartner } from '../RestaurantPartner/RestaurantPartnerContainer';
import { RestaurantsMapWrapperComponent } from '../RestaurantsMap/RestaurantsMapWrapperComponent';
import { Slider } from '../../../atoms/slider/slider';
import { getMealImage } from '../../MealCard/meal-card-helper';
import { primaryOrangeColor } from '../../../../shared/shared';
import { routes } from '../../../../business/router/path-definition';

import { ISearchItem, SearchBox } from '../../../atoms/search-box/search-box';
import { Meal, Restaurant } from '../../../../client';

interface IRestaurantPageComponentProps {
	restaurant: Restaurant;
	navigateTo: (path: string, state?: any) => void;
	restaurantImages: IImageFile[];
	isSignedIn: boolean;
}

interface IRestaurantPageComponentState {
	mealsFiltered: Meal[];
	isMapModalVisible: boolean;
	isPartnerModalVisible: boolean;
	isQrScannerModalVisible: boolean;
	searchText: string;
}

export class RestaurantPageComponent extends React.Component<
	IRestaurantPageComponentProps,
	IRestaurantPageComponentState
> {
	constructor(props: IRestaurantPageComponentProps, state: IRestaurantPageComponentState) {
		super(props, state);

		this.state = this.getInitialState();
	}
	handleOnBackClicked = (): void => {
		this.props.navigateTo(routes.home.path);
	};

	disableMealsModal = () => {
		this.setState({ ...this.state, isMapModalVisible: false });
	};
	handleClickMap = () => {
		this.setState({ ...this.state, isMapModalVisible: true });
	};
	handleClickPartner = () => {
		this.setState({ ...this.state, isPartnerModalVisible: true });
	};

	handleItemSelected = (newItem: ISearchItem) => {
		// const isAdded =
		// 	this.state.mealsFiltered
		// 		.filter((meal: ISearchItem) => meal.id === newItem.id)
		// 		.count() !== 0;
		// if (!isAdded) {
		// 	this.setState({ mealsFiltered: this.state.mealsFiltered.push(newItem) });
		// }
	};

	disablePartnerModal = () => {
		this.setState({ ...this.state, isPartnerModalVisible: false });
	};
	onSearchTextChanged = (text: string) => {
		const regex = new RegExp(text.toLocaleLowerCase());
		const meals = this.props.restaurant.meals?.filter((x) =>
			regex.test(x.name?.toLocaleLowerCase() ?? '')
		);
		this.setState({
			...this.state,
			mealsFiltered: meals ?? [],
			searchText: text
		});
	};

	public render(): JSX.Element {
		const wordsArray = [
			this.props.restaurant.postcode,
			this.props.restaurant.city,
			this.props.restaurant.street,
			this.props.restaurant.homeNumber,
			this.props.restaurant.apartmentNumber
		];
		const fullAddress = wordsArray.join(' ');
		const images = this.props.restaurantImages
			.find((x) => x.catalogId === this.props.restaurant.imagesFileCatalogId)
			?.metadatas.filter((x) => x.fileName?.startsWith('org'))
			.map((x) => (
				<div className="RestaurantPage_Info_Carousel_Image">
					<img src={process.env.REACT_APP_FILES_URL + '/api/' + x.lastVersion.url} />
				</div>
			));
		return (
			<>
				<div className="RestaurantPage">
					<div className="Restaurants_header">
						<BackLink
							text={
								this.props.isSignedIn
									? 'Powrót do jadłospisu'
									: 'Powrót do strony głównej'
							}
							onClick={this.handleOnBackClicked}
						/>
						<h3>{this.props.restaurant.name}</h3>
						<h5>{this.props.restaurant.shortDescription}</h5>
					</div>
					<div className="RestaurantPage_Container">
						<h3>Informacje o nas</h3>
						<div className="RestaurantPage_Info">
							<div className="RestaurantPage_Info_Carousel">
								<Slider items={images ?? []} timer={7000} />
							</div>
							<div className="RestaurantPage_Info_Text">
								<div className="RestaurantPage_Info_Text_Wrapper">
									<h3>{this.props.restaurant.descriptionTitle}</h3>
									<span>{this.props.restaurant.description}</span>
								</div>
								<div className="RestaurantPage_Info_Text_Wrapper">
									<h3>Jesteśmy otwarci</h3>
									<span>{this.props.restaurant.openHours}</span>
								</div>
								<div className="RestaurantPage_Info_Text_Wrapper">
									<h3>Dojazd do nas</h3>
									<span>{fullAddress}</span>
									<ActionLink
										textColor={primaryOrangeColor}
										text="Sprawdź dojazd na mapie"
										disableLeftMargin
										onClick={this.handleClickMap}
									/>
								</div>
								{this.props.restaurant.discountCodeId && (
									<ActionButton
										text="Program lojalnościowy"
										className="RestaurantPage_Info_Text_ActionButton"
										onClick={this.handleClickPartner}
									/>
								)}
							</div>
						</div>
						<div className="RestaurantPage_Meals">
							<div className="RestaurantPage_Meals_Header">
								<IngredientsIcon />
								<h3>Potrawy</h3>
							</div>
							<div className="RestaurantPage_Meals_Search">
								<SearchBox
									activateOnFocus
									disableSuggestions
									placeholder="Wyszukaj potrawy"
									onSelect={this.handleItemSelected}
									onSearchChanged={this.onSearchTextChanged}
									value={this.state.searchText}
									className="RestaurantPage_Meals_Search_SearchBox"
								/>
								<h3>
									Przedstawione kaloryczności oraz makroskładniki mają charakter
									orientacyjny
								</h3>
							</div>
							<div className="RestaurantPage_Meals_Container">
								<FlipMove>
									{this.state.mealsFiltered.map((meal: Meal) => {
										const image = getMealImage(
											this.props.restaurantImages,
											meal.imagesCatalog!
										);
										return (
											<div
												key={meal.id}
												className="RestaurantPage_Meals_Container_Item"
											>
												<div className="RestaurantPage_Meals_Container_Item_Details">
													<img src={image} />
													<span>{meal.name}</span>
												</div>
												<div className="RestaurantPage_Meals_Container_Item_Calories">
													{meal.energy?.toFixed(0)} kcal/porcja
												</div>
											</div>
										);
									})}
								</FlipMove>
							</div>
						</div>
					</div>
				</div>
				{this.state.isMapModalVisible && (
					<Modal
						show={this.state.isMapModalVisible}
						handleStateChange={this.disableMealsModal}
					>
						<>
							<span className="RestaurantPage_MapModalHeader">
								{this.props.restaurant.name}
							</span>
							<RestaurantsMapWrapperComponent
								restaurants={[this.props.restaurant]}
								currentPosition={{
									lat: this.props.restaurant.lat!,
									lng: this.props.restaurant.lng!
								}}
								geolocationButtonText="Znajdź mnie"
							/>
						</>
					</Modal>
				)}
				{this.state.isPartnerModalVisible && (
					<Modal
						show={this.state.isPartnerModalVisible}
						handleStateChange={this.disablePartnerModal}
					>
						<RestaurantPartner restaurant={this.props.restaurant} />
					</Modal>
				)}
			</>
		);
	}

	private getInitialState = (): IRestaurantPageComponentState => {
		return {
			isMapModalVisible: false,
			isPartnerModalVisible: false,
			mealsFiltered: this.props.restaurant.meals ?? [],
			searchText: ''
		} as IRestaurantPageComponentState;
	};
}
