import { FoodApi } from '../../../../../client';
import { GetApiConfig } from '../../../../../business/api-client-config';
import { List } from 'immutable';
import { RootState } from '../../../../../business/root-store';
import { ThunkAction } from 'redux-thunk';
import { hmrsForProductFetched } from '../../../staticData/staticDataActions';

export const GetHomeMeasurementRulesForProduct =
	(productId: number): ThunkAction<any, RootState, any, any> =>
	(dispatch, getState) => {
		const state = getState();
		const api = new FoodApi(GetApiConfig(state));
		api.getHomeMeasurementRulesForProduct(productId).then((x) => {
			dispatch(hmrsForProductFetched(productId, List(x.data.homeMeasurementRules ?? [])));
		});
	};
