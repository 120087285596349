import { ActionButton } from '../../../atoms/ActionButton/ActionButton';
import { CloseIcon } from '../../../../shared/assets/close-icon';
import { FileUploader } from '../../../atoms/FileUploader/FileUploader';
import { Restaurant } from '../../../../client';
import { sendDocuments } from '../../../organisms/Partnership/PartnershipPanel/PartnershipPanelEpics';
import { useDispatch } from 'react-redux';
import { useState } from 'react';

interface IRestaurantDocumentsProps {
	restaurant: Restaurant;
}

export const RestaurantDocuments = (props: IRestaurantDocumentsProps) => {
	const [file, setFile] = useState<File | null>();

	const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setFile(e.target.files![0]);
	};
	const dispatch = useDispatch();

	const handleSendDocuments = () => {
		if (file) {
			dispatch(sendDocuments(file, props.restaurant));
			setFile(null);
		}
	};
	const removeFile = () => {
		setFile(null);
	};
	return (
		<div className="RestaurantDocuments">
			<FileUploader
				text="Dodaj skan podpisanych dokumentów"
				onChange={onFileChange}
				accept="image/*,.pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
			/>
			{file && (
				<>
					<p>{file.name}</p>
					<CloseIcon onClick={removeFile} className="RestaurantDocuments_closeIcon" />
				</>
			)}
			<ActionButton
				onClick={handleSendDocuments}
				text="Prześlij podpisane dokumenty"
				disabled={!file}
			/>
		</div>
	);
};
