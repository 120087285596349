import { SpinnerReducer } from '../components/atoms/spinner/SpinnerReducer';
import { StateType } from 'typesafe-actions';
import appHistory from '../app-history';
import { authReducer } from './auth/auth-reducer';
import { authenticationActionNames } from './auth/auth-actions';
import { customerSettingsReducer } from './customer/customer-settings-reducer';
import { dietPreferencesReducer } from './diet/diet-preferences-reducer';
import { dietReducer } from './diet/diet-reducer';
import { durinalBalanceReducer } from './durinalBalance/durinalBalance.reducer';
import { exchangeMealReducer } from '../components/molecules/MealCard/ExchangeMeal/ExchangeMealReducers';
import { invoicesReducer } from '../components/organisms/Settings/UserSettings/UserSettingsReducer';
import { mealAnalysisReducer } from './mealAnalysis/mealAnalysis.reducer';
import { mealReducer } from '../components/molecules/MealCard/Meal/meal-reducers';
import { mealTableReducer } from '../components/organisms/WeekView/MealTable/MealTableReducer';
import { orderReducer } from './order/order.reducer';
import { partnershipReducer } from './Partnership/PartnershipReducer';
import { restaurantsReducer } from './Restaurants/RestaurantsReducer';
import { shoppingListReducer } from '../components/organisms/DayView/ShoppingList/shopping-list-reducer';
import { staticDataReducer } from '../components/molecules/staticData/staticDataReducer';
import { reducer as toastrReducer } from 'react-redux-toastr';

import { AnyAction, combineReducers, configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { connectRouter, routerMiddleware } from 'connected-react-router';

export const dayViewReducer = combineReducers({
	meal: mealReducer,
	exchangeMeal: exchangeMealReducer,
	mealAnalysis: mealAnalysisReducer
});

export const administrationReducer = combineReducers({
	customerSettings: customerSettingsReducer,
	partnership: partnershipReducer,
	invoices: invoicesReducer
});

export const appReducer = combineReducers({
	auth: authReducer,
	administration: administrationReducer,
	dietPreferences: dietPreferencesReducer,
	diet: dietReducer,
	dayView: dayViewReducer,
	weekView: mealTableReducer,
	durinalBalance: durinalBalanceReducer,
	shoppingList: shoppingListReducer,
	static: staticDataReducer,
	restaurants: restaurantsReducer,
	router: connectRouter(appHistory),
	toastr: toastrReducer,
	spinner: SpinnerReducer,
	order: orderReducer
});

const rootReducer = (state: ReturnType<typeof appReducer> | undefined, action: AnyAction) => {
	switch (action.type) {
		case authenticationActionNames.Auth_Logout: {
			return appReducer(
				{
					static: state?.static,
					diet: {
						...state?.diet,
						customerDietPlan: undefined,
						dietPlanDay: {},
						isDayPlanAvailable: false,
						dietDefinition: undefined
					}
				} as ReturnType<typeof appReducer>,
				{
					type: authenticationActionNames.Auth_Logout
				}
			);
		}
		default:
			return appReducer(state, action);
	}
};

export const store = configureStore({
	devTools: true,
	reducer: rootReducer,
	middleware: [
		...getDefaultMiddleware({
			serializableCheck: false
		}),
		routerMiddleware(appHistory)
	]
});

export type RootState = StateType<typeof appReducer>;
