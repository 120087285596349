import { List } from 'immutable';
import { Reducer } from 'redux';

import { CurrentOrder, Invoice, Order } from '../../../../client';
import {
	CurrentOrder_Fetched,
	ICurrentOrderFetchedAction,
	IInvoicesFetchedAction,
	IOrdersFetchedAction,
	Invoices_Fetched,
	Orders_Fetched
} from './UserSettingsActions';

type ReducerActions = IInvoicesFetchedAction | ICurrentOrderFetchedAction | IOrdersFetchedAction;

interface IUserSettingsReduxState {
	invoiceList: List<Invoice>;
	currentOrder: CurrentOrder;
	ordersList: List<Order>;
}

const INITIAL_STATE: IUserSettingsReduxState = {
	ordersList: List<Order>(),
	invoiceList: List<Invoice>(),
	currentOrder: {}
};

export const invoicesReducer: Reducer<IUserSettingsReduxState, ReducerActions> = (
	state = INITIAL_STATE,
	action
) => {
	switch (action.type) {
		case Invoices_Fetched:
			return handleInvoicesFetched(state, action);
		case Orders_Fetched:
			return handleOrdersFetched(state, action);
		case CurrentOrder_Fetched:
			return handlePaymentsFetched(state, action);
		default:
			return state;
	}
};

const handleOrdersFetched = (
	state: IUserSettingsReduxState,
	action: IOrdersFetchedAction
): IUserSettingsReduxState => {
	return {
		...state,
		ordersList: action.orders
	};
};

const handleInvoicesFetched = (
	state: IUserSettingsReduxState,
	action: IInvoicesFetchedAction
): IUserSettingsReduxState => {
	return {
		...state,
		invoiceList: action.invoices
	};
};

const handlePaymentsFetched = (
	state: IUserSettingsReduxState,
	action: ICurrentOrderFetchedAction
): IUserSettingsReduxState => {
	return {
		...state,
		currentOrder: action.currentOrder
	};
};
