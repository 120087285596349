import './UserSettings.scss';

import ActionLink from '../../../atoms/action-link/action-link';
import { BackLink } from '../../../atoms/back-link/back-link';
import { Headline } from '../../../atoms/headline/headline';
import { IUserInvoiceDataFormValues } from './UserInvoiceDataForm/UserInvoiceDataFormInterfaces';
import { List } from 'immutable';
import React from 'react';
import { UserInvoiceDataForm } from './UserInvoiceDataForm';
import { getFriendlyOrderTypeString } from '../../../../shared/helpers';
import moment from 'moment';
import { primaryOrangeColor } from '../../../../shared/shared';
import { routes } from '../../../../business/router/path-definition';

import { CurrentOrder, Customer, Invoice, Order } from '../../../../client';

interface IUserSettingsComponentProps {
	email: string;
	orders: List<Order>;
	invoices: List<Invoice>;
	currentOrder: CurrentOrder;
	navigateTo: (path: string, state?: any) => void;
	downloadInvoice: (int: number, fileName: string) => void;
	customer?: Customer;
	saveUserSettings(settings: Customer): void;
}

interface IUserSettingsComponentState {}

export class UserSettingsComponent extends React.Component<
	IUserSettingsComponentProps,
	IUserSettingsComponentState
> {
	constructor(props: IUserSettingsComponentProps, state: IUserSettingsComponentState) {
		super(props, state);
		this.state = this.getInitialState();
	}
	private static getUserPaymentImage() {
		// todo: Add logic depends on user payment method
		return 'img/payments/visa.svg';
	}

	public render(): JSX.Element {
		const handleChangePassword = () => {
			this.props.navigateTo(routes.auth.changePassword.path);
		};
		const currentOrder = this.props.currentOrder;
		return (
			<div className="UserSettings">
				<BackLink text="Powrót do jadłospisu" onClick={this.onPreviousClicked} />
				<Headline align="left" text="Ustawienia konta" />
				<div className="UserSettings__main">
					<div className="UserSettings__panel-left">
						<div className="UserSettings__section">
							<div className="UserSettings__section-title">Dane do faktury</div>
							<UserInvoiceDataForm
								initialValues={
									{
										mail: this.props.email,
										apartmentNumber: this.props.customer?.apartmentNumber,
										city: this.props.customer?.city,
										homeNumber: this.props.customer?.homeNumber,
										name: this.props.customer?.name,
										postcode: this.props.customer?.postcode,
										street: this.props.customer?.street,
										surname: this.props.customer?.surname
									} as IUserInvoiceDataFormValues
								}
								saveUserSettings={this.props.saveUserSettings}
							/>
						</div>
					</div>
					<div className="UserSettings__panel-right">
						<div className="UserSettings__section">
							<div className="UserSettings__section-title">Moje konto</div>
							<div className="UserSettings__section-item">
								<div className="UserSettings__section-item-title">Typ diety</div>
								<div className="UserSettings__order-title UserSettings__order-title--active">
									{currentOrder ? (
										<>
											{currentOrder.dietPlanDefinitionName}{' '}
											{getFriendlyOrderTypeString(currentOrder.orderType!)}{' '}
											{currentOrder.orderBoughtDays
												? currentOrder.orderBoughtDays + ' dni'
												: ''}{' '}
											<br />
											<span>
												{currentOrder.orderBoughtDays
													? 'Aktywny do: ' +
													  moment(currentOrder.orderStartDate)
															.add(
																currentOrder.orderBoughtDays,
																'days'
															)
															.format('YYYY-MM-DD')
															.toLocaleString()
													: 'bezterminowo'}
											</span>
										</>
									) : (
										'Dieta testowa 7 dni'
									)}
								</div>
							</div>
							<div className="UserSettings__section-item">
								<div className="UserSettings__section-item-title">
									Metoda płatności
								</div>
								<div className="userSettingsSectionPaymentButton">
									<div className="UserSettings__section-item-content UserSettings__section-item-content--payment">
										<img
											src={UserSettingsComponent.getUserPaymentImage()}
											alt="Payment image"
										/>
										{/* {currentOrder.payments
											? currentOrder.payments![0].cardNumber
											: null} */}
										<button
											className="UserSettings__button"
											onClick={this.handleOnPaymentChange}
										>
											Zmień
										</button>
									</div>
								</div>
							</div>
							<div className="UserSettings__section-item">
								<div className="UserSettings__section-item-title">Operacje</div>
								<ActionLink
									text="Zmień hasło"
									textColor={primaryOrangeColor}
									onClick={handleChangePassword}
								/>
							</div>
						</div>
						{this.props.invoices.size > 0 && (
							<div className="UserSettings__section">
								<div className="UserSettings__section-title">
									Faktury i rachunki
								</div>
								{this.props.invoices.map((invoice: Invoice, key: number) => (
									<div key={key} className="UserSettings__section-item-title">
										{new Date(invoice.date!).toLocaleDateString()}{' '}
										<span style={{ fontSize: 'small' }}>({invoice.name})</span>
										<ActionLink
											text="Pobierz"
											textColor={primaryOrangeColor}
											onClick={() => this.handleOnInvoiceDownload(invoice)}
										/>
									</div>
								))}
							</div>
						)}
						<div className="UserSettings__section">
							<div className="UserSettings__section-title">Moje zamówienia</div>
							{this.props.orders.map((x: Order) => (
								<div className="UserSettings__section-item">
									<div className="UserSettings__section-item-title">
										{moment(x.startDate).format('YYYY-MM-DD').toLocaleString()}
									</div>
									<div className="UserSettings__section-item-content UserSettings__section-item-content--order">
										<div className="UserSettings__order-title UserSettings__order-title--active">
											{x.dietPlanDefinition?.name}{' '}
											{getFriendlyOrderTypeString(x.orderType!)}{' '}
											{x.boughtDays ? x.boughtDays + ' dni' : ''} <br />
											<span>
												{x.boughtDays
													? 'Aktywny do: ' +
													  moment(x.startDate)
															.add(x.boughtDays, 'days')
															.format('YYYY-MM-DD')
															.toLocaleString()
													: 'bezterminowo'}
											</span>
										</div>
										<div className="UserSettings__order-price">
											{x.priceToPay} zł
										</div>
									</div>
								</div>
							))}
						</div>
					</div>
				</div>
			</div>
		);
	}

	private handleOnInvoiceDownload = (invoice: Invoice) => {
		this.props.downloadInvoice(invoice.id!, invoice.name?.replaceAll('/', '_')! + '.pdf');
	};

	private onPreviousClicked = (): void => {
		this.props.navigateTo(routes.home.path);
	};

	private handleOnPaymentChange = (): void => {
		//todo
		this.props.navigateTo(routes.auth.login.path);
	};

	private getInitialState = (): IUserSettingsComponentState => {
		return {};
	};
}
