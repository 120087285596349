import React from 'react';

export interface IPreviousIconProps {
	onClick?(): void;
}

export const PreviousIcon: React.FC<IPreviousIconProps> = (props) => {
	return (
		<React.Fragment>
			<svg
				width="23"
				height="24"
				viewBox="0 0 23 24"
				fill="none"
				onClick={props.onClick}
				xmlns="http://www.w3.org/2000/svg"
			>
				<circle cx="11.5" cy="11.6887" r="11.5" fill="url(#paint0_linear)" />
				<path
					d="M13.1076 7.18872L8.90332 11.393L13.1076 15.5973"
					stroke="white"
					strokeWidth="1.68172"
				/>
				<defs>
					<linearGradient
						id="paint0_linear"
						x1="25.2055"
						y1="0.188709"
						x2="-4.21339"
						y2="1.6395"
						gradientUnits="userSpaceOnUse"
					>
						<stop stopColor="#FF7E36" />
						<stop offset="1" stopColor="#FA3A68" stopOpacity="0.91" />
					</linearGradient>
				</defs>
			</svg>
		</React.Fragment>
	);
};
