/* tslint:disable */
/* eslint-disable */
/**
 * TailoredApps.File.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface CopyFilesToCatalogResponse
 */
export interface CopyFilesToCatalogResponse {
    /**
     * 
     * @type {Array<FileMetadata>}
     * @memberof CopyFilesToCatalogResponse
     */
    'fileMetadatas'?: Array<FileMetadata> | null;
}
/**
 * 
 * @export
 * @interface DeleteMarkerFromImageResponse
 */
export interface DeleteMarkerFromImageResponse {
    /**
     * 
     * @type {number}
     * @memberof DeleteMarkerFromImageResponse
     */
    'estimatedCalories'?: number;
}
/**
 * 
 * @export
 * @interface ExceptionHandlingResultModel
 */
export interface ExceptionHandlingResultModel {
    /**
     * 
     * @type {string}
     * @memberof ExceptionHandlingResultModel
     */
    'message'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ExceptionHandlingResultModel
     */
    'errorCode'?: number;
    /**
     * 
     * @type {Array<ExceptionOrValidationError>}
     * @memberof ExceptionHandlingResultModel
     */
    'errors'?: Array<ExceptionOrValidationError> | null;
}
/**
 * 
 * @export
 * @interface ExceptionOrValidationError
 */
export interface ExceptionOrValidationError {
    /**
     * 
     * @type {string}
     * @memberof ExceptionOrValidationError
     */
    'field'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExceptionOrValidationError
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @interface FileClassification
 */
export interface FileClassification {
    /**
     * 
     * @type {number}
     * @memberof FileClassification
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FileClassification
     */
    'fileId'?: string;
    /**
     * 
     * @type {string}
     * @memberof FileClassification
     */
    'modelName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FileClassification
     */
    'modelVersion'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FileClassification
     */
    'modelChecksum'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FileClassification
     */
    'label'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FileClassification
     */
    'score'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FileClassification
     */
    'createdDateUtc'?: string;
    /**
     * 
     * @type {string}
     * @memberof FileClassification
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FileClassification
     */
    'modifiedDateUtc'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FileClassification
     */
    'modifiedBy'?: string | null;
    /**
     * 
     * @type {any}
     * @memberof FileClassification
     */
    'file'?: any;
}
/**
 * 
 * @export
 * @interface FileInfo
 */
export interface FileInfo {
    /**
     * 
     * @type {string}
     * @memberof FileInfo
     */
    'metadaId'?: string;
    /**
     * 
     * @type {string}
     * @memberof FileInfo
     */
    'fileGroupId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FileInfo
     */
    'url'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FileInfo
     */
    'name'?: string | null;
    /**
     * 
     * @type {FileTypeEnum}
     * @memberof FileInfo
     */
    'fileType'?: FileTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof FileInfo
     */
    'score'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FileInfo
     */
    'source'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FileInfo
     */
    'label'?: string | null;
    /**
     * 
     * @type {Array<FileClassification>}
     * @memberof FileInfo
     */
    'classification'?: Array<FileClassification> | null;
}
/**
 * 
 * @export
 * @interface FileMetadata
 */
export interface FileMetadata {
    /**
     * 
     * @type {number}
     * @memberof FileMetadata
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FileMetadata
     */
    'fileName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FileMetadata
     */
    'guid'?: string;
    /**
     * 
     * @type {string}
     * @memberof FileMetadata
     */
    'extension'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FileMetadata
     */
    'size'?: number;
    /**
     * 
     * @type {string}
     * @memberof FileMetadata
     */
    'createdDateUtc'?: string;
    /**
     * 
     * @type {string}
     * @memberof FileMetadata
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FileMetadata
     */
    'modifiedDateUtc'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FileMetadata
     */
    'modifiedBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FileMetadata
     */
    'lastVersionId'?: string;
    /**
     * 
     * @type {any}
     * @memberof FileMetadata
     */
    'lastVersion'?: any;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum FileTypeEnum {
    OriginalImage = 'OriginalImage',
    ThumbnailImage = 'ThumbnailImage',
    ModifiedImage = 'ModifiedImage',
    OriginalOther = 'OriginalOther',
    Zip = 'Zip'
}

/**
 * 
 * @export
 * @interface GetFilesFromCatalogResponse
 */
export interface GetFilesFromCatalogResponse {
    /**
     * 
     * @type {Array<FileMetadata>}
     * @memberof GetFilesFromCatalogResponse
     */
    'fileMetadatas'?: Array<FileMetadata> | null;
}
/**
 * 
 * @export
 * @interface ImageObject
 */
export interface ImageObject {
    /**
     * 
     * @type {number}
     * @memberof ImageObject
     */
    'markerId'?: number;
    /**
     * 
     * @type {XYCords}
     * @memberof ImageObject
     */
    'position1'?: XYCords;
    /**
     * 
     * @type {XYCords}
     * @memberof ImageObject
     */
    'position2'?: XYCords;
    /**
     * 
     * @type {string}
     * @memberof ImageObject
     */
    'productRef'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ImageObject
     */
    'qty'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ImageObject
     */
    'measurementUnit'?: string | null;
}
/**
 * 
 * @export
 * @interface ModelFile
 */
export interface ModelFile {
    /**
     * 
     * @type {string}
     * @memberof ModelFile
     */
    'fileName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ModelFile
     */
    'extension'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ModelFile
     */
    'exist'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ModelFile
     */
    'size'?: number;
    /**
     * 
     * @type {string}
     * @memberof ModelFile
     */
    'path'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ModelFile
     */
    'fileId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelFile
     */
    'fileGroupId'?: string | null;
    /**
     * 
     * @type {FileTypeEnum}
     * @memberof ModelFile
     */
    'fileType'?: FileTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof ModelFile
     */
    'version'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ModelFile
     */
    'isDeleted'?: boolean;
    /**
     * 
     * @type {FileMetadata}
     * @memberof ModelFile
     */
    'fileMetadata'?: FileMetadata;
    /**
     * 
     * @type {string}
     * @memberof ModelFile
     */
    'storagePath'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ModelFile
     */
    'label'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ModelFile
     */
    'score'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ModelFile
     */
    'source'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ModelFile
     */
    'url'?: string | null;
}
/**
 * 
 * @export
 * @interface PostAddMarkerToImage
 */
export interface PostAddMarkerToImage {
    /**
     * 
     * @type {string}
     * @memberof PostAddMarkerToImage
     */
    'fileId'?: string | null;
    /**
     * 
     * @type {XYCords}
     * @memberof PostAddMarkerToImage
     */
    'position1'?: XYCords;
    /**
     * 
     * @type {XYCords}
     * @memberof PostAddMarkerToImage
     */
    'position2'?: XYCords;
}
/**
 * 
 * @export
 * @interface PostFile
 */
export interface PostFile {
    /**
     * 
     * @type {string}
     * @memberof PostFile
     */
    'file'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PostFile
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PostFile
     */
    'catalogId'?: number | null;
}
/**
 * 
 * @export
 * @interface PostFileCatalog
 */
export interface PostFileCatalog {
    /**
     * 
     * @type {string}
     * @memberof PostFileCatalog
     */
    'name'?: string | null;
}
/**
 * 
 * @export
 * @interface PostFileCatalogResponse
 */
export interface PostFileCatalogResponse {
    /**
     * 
     * @type {string}
     * @memberof PostFileCatalogResponse
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PostFileCatalogResponse
     */
    'catalogId'?: number;
}
/**
 * 
 * @export
 * @interface PostFileFromUrl
 */
export interface PostFileFromUrl {
    /**
     * 
     * @type {string}
     * @memberof PostFileFromUrl
     */
    'url'?: string | null;
}
/**
 * 
 * @export
 * @interface PostFileResponse
 */
export interface PostFileResponse {
    /**
     * 
     * @type {string}
     * @memberof PostFileResponse
     */
    'fileId'?: string;
    /**
     * 
     * @type {string}
     * @memberof PostFileResponse
     */
    'fileUrl'?: string | null;
}
/**
 * 
 * @export
 * @interface PostImageFile
 */
export interface PostImageFile {
    /**
     * 
     * @type {string}
     * @memberof PostImageFile
     */
    'imageData'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PostImageFile
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PostImageFile
     */
    'source'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PostImageFile
     */
    'resize'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof PostImageFile
     */
    'keepResizeRatio'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof PostImageFile
     */
    'width'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PostImageFile
     */
    'height'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof PostImageFile
     */
    'generateThumbminal'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof PostImageFile
     */
    'thumbminalWidth'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PostImageFile
     */
    'thumbminalHeight'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PostImageFile
     */
    'catalogId'?: number | null;
}
/**
 * 
 * @export
 * @interface PostImageFileForAnalysis
 */
export interface PostImageFileForAnalysis {
    /**
     * 
     * @type {string}
     * @memberof PostImageFileForAnalysis
     */
    'imageUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PostImageFileForAnalysis
     */
    'imageData'?: string | null;
}
/**
 * 
 * @export
 * @interface PostImageFileForAnalysisResponse
 */
export interface PostImageFileForAnalysisResponse {
    /**
     * 
     * @type {Array<ImageObject>}
     * @memberof PostImageFileForAnalysisResponse
     */
    'imageObject'?: Array<ImageObject> | null;
    /**
     * 
     * @type {number}
     * @memberof PostImageFileForAnalysisResponse
     */
    'caloriesEstimated'?: number;
    /**
     * 
     * @type {string}
     * @memberof PostImageFileForAnalysisResponse
     */
    'fileId'?: string;
    /**
     * 
     * @type {string}
     * @memberof PostImageFileForAnalysisResponse
     */
    'fileUrl'?: string | null;
}
/**
 * 
 * @export
 * @interface PostImageFileResponse
 */
export interface PostImageFileResponse {
    /**
     * 
     * @type {Array<FileInfo>}
     * @memberof PostImageFileResponse
     */
    'fileInfos'?: Array<FileInfo> | null;
}
/**
 * 
 * @export
 * @interface PutEditMarkerOnImage
 */
export interface PutEditMarkerOnImage {
    /**
     * 
     * @type {string}
     * @memberof PutEditMarkerOnImage
     */
    'fileId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PutEditMarkerOnImage
     */
    'markerId'?: number;
    /**
     * 
     * @type {XYCords}
     * @memberof PutEditMarkerOnImage
     */
    'position1'?: XYCords;
    /**
     * 
     * @type {XYCords}
     * @memberof PutEditMarkerOnImage
     */
    'position2'?: XYCords;
    /**
     * 
     * @type {string}
     * @memberof PutEditMarkerOnImage
     */
    'productRef'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PutEditMarkerOnImage
     */
    'qty'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PutEditMarkerOnImage
     */
    'measurementUnit'?: string | null;
}
/**
 * 
 * @export
 * @interface PutEditMarkerOnImageResponse
 */
export interface PutEditMarkerOnImageResponse {
    /**
     * 
     * @type {string}
     * @memberof PutEditMarkerOnImageResponse
     */
    'fileId'?: string | null;
    /**
     * 
     * @type {ImageObject}
     * @memberof PutEditMarkerOnImageResponse
     */
    'imageObject'?: ImageObject;
    /**
     * 
     * @type {number}
     * @memberof PutEditMarkerOnImageResponse
     */
    'estimatedCalories'?: number;
}
/**
 * 
 * @export
 * @interface PutFile
 */
export interface PutFile {
    /**
     * 
     * @type {string}
     * @memberof PutFile
     */
    'file'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PutFile
     */
    'name'?: number;
    /**
     * 
     * @type {string}
     * @memberof PutFile
     */
    'fileId'?: string;
    /**
     * 
     * @type {string}
     * @memberof PutFile
     */
    'groupGuid'?: string | null;
}
/**
 * 
 * @export
 * @interface PutFileCatalog
 */
export interface PutFileCatalog {
    /**
     * 
     * @type {number}
     * @memberof PutFileCatalog
     */
    'catalogId'?: number;
    /**
     * 
     * @type {string}
     * @memberof PutFileCatalog
     */
    'newName'?: string | null;
}
/**
 * 
 * @export
 * @interface PutFileCatalogResponse
 */
export interface PutFileCatalogResponse {
    /**
     * 
     * @type {string}
     * @memberof PutFileCatalogResponse
     */
    'name'?: string | null;
}
/**
 * 
 * @export
 * @interface PutFileResponse
 */
export interface PutFileResponse {
    /**
     * 
     * @type {string}
     * @memberof PutFileResponse
     */
    'fileId'?: string;
    /**
     * 
     * @type {number}
     * @memberof PutFileResponse
     */
    'version'?: number;
    /**
     * 
     * @type {string}
     * @memberof PutFileResponse
     */
    'fileUrl'?: string | null;
}
/**
 * 
 * @export
 * @interface XYCords
 */
export interface XYCords {
    /**
     * 
     * @type {number}
     * @memberof XYCords
     */
    'x'?: number;
    /**
     * 
     * @type {number}
     * @memberof XYCords
     */
    'y'?: number;
}

/**
 * FileApi - axios parameter creator
 * @export
 */
export const FileApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} fromCatalogId 
         * @param {number} toCatalogId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copyFilesToCatalog: async (fromCatalogId: number, toCatalogId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fromCatalogId' is not null or undefined
            assertParamExists('copyFilesToCatalog', 'fromCatalogId', fromCatalogId)
            // verify required parameter 'toCatalogId' is not null or undefined
            assertParamExists('copyFilesToCatalog', 'toCatalogId', toCatalogId)
            const localVarPath = `/api/File/Catalog/{FromCatalogId}/{ToCatalogId}`
                .replace(`{${"FromCatalogId"}}`, encodeURIComponent(String(fromCatalogId)))
                .replace(`{${"ToCatalogId"}}`, encodeURIComponent(String(toCatalogId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFile: async (fileId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('deleteFile', 'fileId', fileId)
            const localVarPath = `/api/File/Delete/{FileId}`
                .replace(`{${"FileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} groupingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFileByGroupingId: async (groupingId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'groupingId' is not null or undefined
            assertParamExists('deleteFileByGroupingId', 'groupingId', groupingId)
            const localVarPath = `/api/File/Delete/{GroupingId}/Group`
                .replace(`{${"GroupingId"}}`, encodeURIComponent(String(groupingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} fileName 
         * @param {number} fileCatalogId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFileByName: async (fileName: string, fileCatalogId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fileName' is not null or undefined
            assertParamExists('deleteFileByName', 'fileName', fileName)
            // verify required parameter 'fileCatalogId' is not null or undefined
            assertParamExists('deleteFileByName', 'fileCatalogId', fileCatalogId)
            const localVarPath = `/api/File/Delete/{FileName}/{FileCatalogId}`
                .replace(`{${"FileName"}}`, encodeURIComponent(String(fileName)))
                .replace(`{${"FileCatalogId"}}`, encodeURIComponent(String(fileCatalogId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} catalogId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFilesFromCatalog: async (catalogId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'catalogId' is not null or undefined
            assertParamExists('getFilesFromCatalog', 'catalogId', catalogId)
            const localVarPath = `/api/File/Catalog/{CatalogId}`
                .replace(`{${"CatalogId"}}`, encodeURIComponent(String(catalogId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PostFile} [postFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postFile: async (postFile?: PostFile, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/File/Upload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postFile, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PostFileCatalog} [postFileCatalog] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postFileCatalog: async (postFileCatalog?: PostFileCatalog, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/File/Catalog`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postFileCatalog, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PostFileFromUrl} [postFileFromUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postFileFromUrl: async (postFileFromUrl?: PostFileFromUrl, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/File/Upload/FromUrl`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postFileFromUrl, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PostImageFile} [postImageFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postImageFile: async (postImageFile?: PostImageFile, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/File/Upload/Image`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postImageFile, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [fileId] 
         * @param {number} [version] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pullFile: async (fileId?: string, version?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/File/Pull`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fileId !== undefined) {
                localVarQueryParameter['fileId'] = fileId;
            }

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} fileId 
         * @param {PutFile} [putFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putFile: async (fileId: string, putFile?: PutFile, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('putFile', 'fileId', fileId)
            const localVarPath = `/api/File/Edit/{FileId}`
                .replace(`{${"FileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(putFile, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} catalogId 
         * @param {PutFileCatalog} [putFileCatalog] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putFileCatalog: async (catalogId: number, putFileCatalog?: PutFileCatalog, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'catalogId' is not null or undefined
            assertParamExists('putFileCatalog', 'catalogId', catalogId)
            const localVarPath = `/api/File/Catalog/{CatalogId}`
                .replace(`{${"CatalogId"}}`, encodeURIComponent(String(catalogId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(putFileCatalog, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FileApi - functional programming interface
 * @export
 */
export const FileApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FileApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} fromCatalogId 
         * @param {number} toCatalogId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async copyFilesToCatalog(fromCatalogId: number, toCatalogId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CopyFilesToCatalogResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.copyFilesToCatalog(fromCatalogId, toCatalogId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteFile(fileId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFile(fileId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} groupingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteFileByGroupingId(groupingId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFileByGroupingId(groupingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} fileName 
         * @param {number} fileCatalogId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteFileByName(fileName: string, fileCatalogId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFileByName(fileName, fileCatalogId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} catalogId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFilesFromCatalog(catalogId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetFilesFromCatalogResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFilesFromCatalog(catalogId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PostFile} [postFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postFile(postFile?: PostFile, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostFileResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postFile(postFile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PostFileCatalog} [postFileCatalog] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postFileCatalog(postFileCatalog?: PostFileCatalog, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostFileCatalogResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postFileCatalog(postFileCatalog, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PostFileFromUrl} [postFileFromUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postFileFromUrl(postFileFromUrl?: PostFileFromUrl, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostImageFileResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postFileFromUrl(postFileFromUrl, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PostImageFile} [postImageFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postImageFile(postImageFile?: PostImageFile, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostImageFileResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postImageFile(postImageFile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [fileId] 
         * @param {number} [version] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pullFile(fileId?: string, version?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pullFile(fileId, version, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} fileId 
         * @param {PutFile} [putFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putFile(fileId: string, putFile?: PutFile, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PutFileResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putFile(fileId, putFile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} catalogId 
         * @param {PutFileCatalog} [putFileCatalog] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putFileCatalog(catalogId: number, putFileCatalog?: PutFileCatalog, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PutFileCatalogResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putFileCatalog(catalogId, putFileCatalog, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FileApi - factory interface
 * @export
 */
export const FileApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FileApiFp(configuration)
    return {
        /**
         * 
         * @param {number} fromCatalogId 
         * @param {number} toCatalogId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copyFilesToCatalog(fromCatalogId: number, toCatalogId: number, options?: any): AxiosPromise<CopyFilesToCatalogResponse> {
            return localVarFp.copyFilesToCatalog(fromCatalogId, toCatalogId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} fileId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFile(fileId: string, options?: any): AxiosPromise<object> {
            return localVarFp.deleteFile(fileId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} groupingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFileByGroupingId(groupingId: string, options?: any): AxiosPromise<object> {
            return localVarFp.deleteFileByGroupingId(groupingId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} fileName 
         * @param {number} fileCatalogId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFileByName(fileName: string, fileCatalogId: number, options?: any): AxiosPromise<object> {
            return localVarFp.deleteFileByName(fileName, fileCatalogId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} catalogId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFilesFromCatalog(catalogId: number, options?: any): AxiosPromise<GetFilesFromCatalogResponse> {
            return localVarFp.getFilesFromCatalog(catalogId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PostFile} [postFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postFile(postFile?: PostFile, options?: any): AxiosPromise<PostFileResponse> {
            return localVarFp.postFile(postFile, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PostFileCatalog} [postFileCatalog] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postFileCatalog(postFileCatalog?: PostFileCatalog, options?: any): AxiosPromise<PostFileCatalogResponse> {
            return localVarFp.postFileCatalog(postFileCatalog, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PostFileFromUrl} [postFileFromUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postFileFromUrl(postFileFromUrl?: PostFileFromUrl, options?: any): AxiosPromise<PostImageFileResponse> {
            return localVarFp.postFileFromUrl(postFileFromUrl, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PostImageFile} [postImageFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postImageFile(postImageFile?: PostImageFile, options?: any): AxiosPromise<PostImageFileResponse> {
            return localVarFp.postImageFile(postImageFile, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [fileId] 
         * @param {number} [version] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pullFile(fileId?: string, version?: number, options?: any): AxiosPromise<any> {
            return localVarFp.pullFile(fileId, version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} fileId 
         * @param {PutFile} [putFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putFile(fileId: string, putFile?: PutFile, options?: any): AxiosPromise<PutFileResponse> {
            return localVarFp.putFile(fileId, putFile, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} catalogId 
         * @param {PutFileCatalog} [putFileCatalog] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putFileCatalog(catalogId: number, putFileCatalog?: PutFileCatalog, options?: any): AxiosPromise<PutFileCatalogResponse> {
            return localVarFp.putFileCatalog(catalogId, putFileCatalog, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FileApi - object-oriented interface
 * @export
 * @class FileApi
 * @extends {BaseAPI}
 */
export class FileApi extends BaseAPI {
    /**
     * 
     * @param {number} fromCatalogId 
     * @param {number} toCatalogId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public copyFilesToCatalog(fromCatalogId: number, toCatalogId: number, options?: AxiosRequestConfig) {
        return FileApiFp(this.configuration).copyFilesToCatalog(fromCatalogId, toCatalogId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} fileId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public deleteFile(fileId: string, options?: AxiosRequestConfig) {
        return FileApiFp(this.configuration).deleteFile(fileId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} groupingId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public deleteFileByGroupingId(groupingId: string, options?: AxiosRequestConfig) {
        return FileApiFp(this.configuration).deleteFileByGroupingId(groupingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} fileName 
     * @param {number} fileCatalogId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public deleteFileByName(fileName: string, fileCatalogId: number, options?: AxiosRequestConfig) {
        return FileApiFp(this.configuration).deleteFileByName(fileName, fileCatalogId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} catalogId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public getFilesFromCatalog(catalogId: number, options?: AxiosRequestConfig) {
        return FileApiFp(this.configuration).getFilesFromCatalog(catalogId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PostFile} [postFile] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public postFile(postFile?: PostFile, options?: AxiosRequestConfig) {
        return FileApiFp(this.configuration).postFile(postFile, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PostFileCatalog} [postFileCatalog] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public postFileCatalog(postFileCatalog?: PostFileCatalog, options?: AxiosRequestConfig) {
        return FileApiFp(this.configuration).postFileCatalog(postFileCatalog, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PostFileFromUrl} [postFileFromUrl] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public postFileFromUrl(postFileFromUrl?: PostFileFromUrl, options?: AxiosRequestConfig) {
        return FileApiFp(this.configuration).postFileFromUrl(postFileFromUrl, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PostImageFile} [postImageFile] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public postImageFile(postImageFile?: PostImageFile, options?: AxiosRequestConfig) {
        return FileApiFp(this.configuration).postImageFile(postImageFile, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [fileId] 
     * @param {number} [version] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public pullFile(fileId?: string, version?: number, options?: AxiosRequestConfig) {
        return FileApiFp(this.configuration).pullFile(fileId, version, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} fileId 
     * @param {PutFile} [putFile] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public putFile(fileId: string, putFile?: PutFile, options?: AxiosRequestConfig) {
        return FileApiFp(this.configuration).putFile(fileId, putFile, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} catalogId 
     * @param {PutFileCatalog} [putFileCatalog] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public putFileCatalog(catalogId: number, putFileCatalog?: PutFileCatalog, options?: AxiosRequestConfig) {
        return FileApiFp(this.configuration).putFileCatalog(catalogId, putFileCatalog, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MealAnalysisApi - axios parameter creator
 * @export
 */
export const MealAnalysisApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} fileId 
         * @param {number} markerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMarkerFromImage: async (fileId: string, markerId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('deleteMarkerFromImage', 'fileId', fileId)
            // verify required parameter 'markerId' is not null or undefined
            assertParamExists('deleteMarkerFromImage', 'markerId', markerId)
            const localVarPath = `/api/MealAnalysis/{FileId}/Remove/Marker/{MarkerId}`
                .replace(`{${"FileId"}}`, encodeURIComponent(String(fileId)))
                .replace(`{${"MarkerId"}}`, encodeURIComponent(String(markerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} fileId 
         * @param {PostAddMarkerToImage} [postAddMarkerToImage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAddMarkerToImage: async (fileId: string, postAddMarkerToImage?: PostAddMarkerToImage, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('postAddMarkerToImage', 'fileId', fileId)
            const localVarPath = `/api/MealAnalysis/{FileId}/Add/Marker`
                .replace(`{${"FileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postAddMarkerToImage, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PostImageFileForAnalysis} [postImageFileForAnalysis] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postForAnalysisImageFile: async (postImageFileForAnalysis?: PostImageFileForAnalysis, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/MealAnalysis/Upload/Image`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postImageFileForAnalysis, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} fileId 
         * @param {number} markerId 
         * @param {PutEditMarkerOnImage} [putEditMarkerOnImage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putEditMarkerOnImage: async (fileId: string, markerId: number, putEditMarkerOnImage?: PutEditMarkerOnImage, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('putEditMarkerOnImage', 'fileId', fileId)
            // verify required parameter 'markerId' is not null or undefined
            assertParamExists('putEditMarkerOnImage', 'markerId', markerId)
            const localVarPath = `/api/MealAnalysis/{FileId}/Edit/Marker/{MarkerId}`
                .replace(`{${"FileId"}}`, encodeURIComponent(String(fileId)))
                .replace(`{${"MarkerId"}}`, encodeURIComponent(String(markerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(putEditMarkerOnImage, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MealAnalysisApi - functional programming interface
 * @export
 */
export const MealAnalysisApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MealAnalysisApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} fileId 
         * @param {number} markerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteMarkerFromImage(fileId: string, markerId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteMarkerFromImageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteMarkerFromImage(fileId, markerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} fileId 
         * @param {PostAddMarkerToImage} [postAddMarkerToImage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAddMarkerToImage(fileId: string, postAddMarkerToImage?: PostAddMarkerToImage, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostImageFileForAnalysisResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAddMarkerToImage(fileId, postAddMarkerToImage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PostImageFileForAnalysis} [postImageFileForAnalysis] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postForAnalysisImageFile(postImageFileForAnalysis?: PostImageFileForAnalysis, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostImageFileForAnalysisResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postForAnalysisImageFile(postImageFileForAnalysis, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} fileId 
         * @param {number} markerId 
         * @param {PutEditMarkerOnImage} [putEditMarkerOnImage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putEditMarkerOnImage(fileId: string, markerId: number, putEditMarkerOnImage?: PutEditMarkerOnImage, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PutEditMarkerOnImageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putEditMarkerOnImage(fileId, markerId, putEditMarkerOnImage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MealAnalysisApi - factory interface
 * @export
 */
export const MealAnalysisApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MealAnalysisApiFp(configuration)
    return {
        /**
         * 
         * @param {string} fileId 
         * @param {number} markerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMarkerFromImage(fileId: string, markerId: number, options?: any): AxiosPromise<DeleteMarkerFromImageResponse> {
            return localVarFp.deleteMarkerFromImage(fileId, markerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} fileId 
         * @param {PostAddMarkerToImage} [postAddMarkerToImage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAddMarkerToImage(fileId: string, postAddMarkerToImage?: PostAddMarkerToImage, options?: any): AxiosPromise<PostImageFileForAnalysisResponse> {
            return localVarFp.postAddMarkerToImage(fileId, postAddMarkerToImage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PostImageFileForAnalysis} [postImageFileForAnalysis] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postForAnalysisImageFile(postImageFileForAnalysis?: PostImageFileForAnalysis, options?: any): AxiosPromise<PostImageFileForAnalysisResponse> {
            return localVarFp.postForAnalysisImageFile(postImageFileForAnalysis, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} fileId 
         * @param {number} markerId 
         * @param {PutEditMarkerOnImage} [putEditMarkerOnImage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putEditMarkerOnImage(fileId: string, markerId: number, putEditMarkerOnImage?: PutEditMarkerOnImage, options?: any): AxiosPromise<PutEditMarkerOnImageResponse> {
            return localVarFp.putEditMarkerOnImage(fileId, markerId, putEditMarkerOnImage, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MealAnalysisApi - object-oriented interface
 * @export
 * @class MealAnalysisApi
 * @extends {BaseAPI}
 */
export class MealAnalysisApi extends BaseAPI {
    /**
     * 
     * @param {string} fileId 
     * @param {number} markerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MealAnalysisApi
     */
    public deleteMarkerFromImage(fileId: string, markerId: number, options?: AxiosRequestConfig) {
        return MealAnalysisApiFp(this.configuration).deleteMarkerFromImage(fileId, markerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} fileId 
     * @param {PostAddMarkerToImage} [postAddMarkerToImage] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MealAnalysisApi
     */
    public postAddMarkerToImage(fileId: string, postAddMarkerToImage?: PostAddMarkerToImage, options?: AxiosRequestConfig) {
        return MealAnalysisApiFp(this.configuration).postAddMarkerToImage(fileId, postAddMarkerToImage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PostImageFileForAnalysis} [postImageFileForAnalysis] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MealAnalysisApi
     */
    public postForAnalysisImageFile(postImageFileForAnalysis?: PostImageFileForAnalysis, options?: AxiosRequestConfig) {
        return MealAnalysisApiFp(this.configuration).postForAnalysisImageFile(postImageFileForAnalysis, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} fileId 
     * @param {number} markerId 
     * @param {PutEditMarkerOnImage} [putEditMarkerOnImage] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MealAnalysisApi
     */
    public putEditMarkerOnImage(fileId: string, markerId: number, putEditMarkerOnImage?: PutEditMarkerOnImage, options?: AxiosRequestConfig) {
        return MealAnalysisApiFp(this.configuration).putEditMarkerOnImage(fileId, markerId, putEditMarkerOnImage, options).then((request) => request(this.axios, this.basePath));
    }
}


