import './change-password-form-component.scss';

import * as Yup from 'yup';

import { ActionButton } from '../../../atoms/ActionButton/ActionButton';
import React from 'react';
import { TextBox } from '../../../atoms/TextBox/TextBox';

import { Field, Form, Formik, FormikHelpers } from 'formik';
import {
	IChangePasswordFormProps,
	IChangePasswordFormValues
} from './ChangePasswordFormInterfaces';

const initialValues: IChangePasswordFormValues = {
	oldPassword: '',
	newPassword: '',
	repeatNewPassword: ''
};

export const ChangePasswordForm = ({ changePassword, handleError }: IChangePasswordFormProps) => {
	const onSubmit = (
		values: IChangePasswordFormValues,
		helpers: FormikHelpers<IChangePasswordFormValues>
	) => {
		changePassword(values.oldPassword, values.newPassword);
		setTimeout(() => helpers.setSubmitting(false), 2000);
	};
	return (
		<Formik
			initialValues={initialValues}
			validationSchema={Yup.object<
				Partial<Record<keyof IChangePasswordFormValues, Yup.AnySchema>>
			>({
				oldPassword: Yup.string()
					.min(3, 'Co najmniej 3 znaki')
					.max(30, 'Nie więcej niż 30 znaków')
					.required('Wymagane'),
				newPassword: Yup.string()
					.min(3, 'Co najmniej 3 znaki')
					.max(30, 'Nie więcej niż 30 znaków')
					.required('Wymagane'),
				repeatNewPassword: Yup.string()
					.oneOf([Yup.ref('newPassword'), null], 'Hasła muszą być takie same')
					.required('Wymagane')
			})}
			onSubmit={onSubmit}
		>
			{(formik) => (
				<Form>
					<Field
						name="oldPassword"
						label="Obecne hasło"
						type="password"
						placeholder="Dotychczasowe hasło"
						className="AuthChangePassword__form-row"
						errorString={formik.touched.oldPassword && formik.errors.oldPassword}
						as={TextBox}
					/>
					<Field
						name="oldPassword"
						label="Nowe hasło"
						type="password"
						placeholder="Ustal silne nowe hasło"
						className="AuthChangePassword__form-row"
						errorString={formik.touched.newPassword && formik.errors.newPassword}
						as={TextBox}
					/>
					<Field
						name="oldPassword"
						label="Powtórz nowe hasło"
						type="password"
						placeholder="Powtórz nowe hasło"
						className="AuthChangePassword__form-row"
						errorString={
							formik.touched.repeatNewPassword && formik.errors.repeatNewPassword
						}
						as={TextBox}
					/>
					<ActionButton
						disabled={!formik.isValid}
						errorString={formik.isValid ? undefined : 'Sprawdź błędy w formularzu'}
						type="submit"
						className="AuthChangePassword__button"
						text="Zmień hasło"
					/>
				</Form>
			)}
		</Formik>
	);
};
