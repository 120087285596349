import * as React from 'react';
import * as Redux from 'redux';

import { GetUserMealTypes } from '../../staticData/staticDataEpics';
import { ICurrentMeals } from '../../MealCard/Meal/meal-interfaces';
import { IImageFile } from '../../../../shared/interfaces/IImageFile';
import { List } from 'immutable';
import { RestaurantMealsComponent } from './RestaurantMealsComponent';
import { RootState } from '../../../../business/root-store';
import { addOrUpdateCustomerMeals } from '../../MealCard/CustomMeal/CustomMealEpics';
import { connect } from 'react-redux';
import { getCurrentMealsSelector } from '../../MealCard/Meal/meal-selectors';
import { getRestaurantImages } from '../../../../business/Restaurants/RestaurantsEpics';
import { getRestaurantImagesSelector } from '../../../../business/Restaurants/RestaurantsSelectors';
import { getUserMealTypesWithoutBeverages } from '../../staticData/staticDataSelectors';
import { getiSAnyActiveDiet } from '../../../../business/diet/diet-selector';
import { isSignedIn } from '../../../../business/auth/auth-selector';
import { push } from 'connected-react-router';

import { AnyAction, Dispatch } from 'redux';
import { Meal, MealTypeEnum, Restaurant } from '../../../../client';

interface IRestaurantMealsContainerReduxMergedProps {
	currentMeals: ICurrentMeals;
	userMealTypes: List<MealTypeEnum>;
	isSignedIn: boolean;
	anyActiveDiet: boolean;
	restaurantImages: IImageFile[];
}

interface IRestaurantMealsContainerOwnProps {
	restaurant?: Restaurant;
	placeId?: number;
}

interface IRestaurantMealsContainerDispatchProps {
	addOrUpdateCustomerMeals: (meals: Meal[], mealType?: MealTypeEnum) => void;
	GetUserMealTypes: () => void;
	navigateTo: (path: string, state?: any) => void;
	getRestaurantImages: (restaurant: Restaurant) => void;
}

interface IRestaurantMealsContainerProps
	extends IRestaurantMealsContainerOwnProps,
		IRestaurantMealsContainerDispatchProps,
		IRestaurantMealsContainerReduxMergedProps {}

interface IRestaurantMealsContainerState {}

class RestaurantMealsContainer extends React.Component<
	IRestaurantMealsContainerProps,
	IRestaurantMealsContainerState
> {
	constructor(props: IRestaurantMealsContainerProps, state: IRestaurantMealsContainerState) {
		super(props, state);
		if (
			this.props.userMealTypes.size === 0 &&
			this.props.isSignedIn &&
			this.props.anyActiveDiet
		) {
			this.props.GetUserMealTypes();
		}
		if (this.props.restaurant?.imagesFileCatalogId) {
			this.props.getRestaurantImages(this.props.restaurant);
		}
		this.state = this.getInitialState();
	}

	public render(): JSX.Element {
		return (
			<RestaurantMealsComponent
				addOrUpdateCustomerMeals={this.props.addOrUpdateCustomerMeals}
				navigateTo={this.props.navigateTo}
				restaurant={this.props.restaurant}
				restaurantImages={this.props.restaurantImages}
				currentMeals={this.props.currentMeals}
				userMealTypes={this.props.userMealTypes}
				isSignedIn={this.props.isSignedIn}
				placeId={this.props.placeId}
				key={this.props.userMealTypes.size}
			/>
		);
	}

	public componentDidMount(): void {}

	public static mapStateToProps = (
		state: RootState,
		ownProps: IRestaurantMealsContainerOwnProps
	): IRestaurantMealsContainerReduxMergedProps => {
		return {
			currentMeals: getCurrentMealsSelector(state),
			userMealTypes: getUserMealTypesWithoutBeverages(state),
			isSignedIn: isSignedIn(state),
			restaurantImages: getRestaurantImagesSelector(state),
			anyActiveDiet: getiSAnyActiveDiet(state)
		};
	};

	public static mapDispatchToProps = (
		dispatch: Dispatch<AnyAction>
	): IRestaurantMealsContainerDispatchProps => {
		return {
			...Redux.bindActionCreators(
				{
					GetUserMealTypes: GetUserMealTypes,
					navigateTo: push,
					getRestaurantImages: getRestaurantImages,
					addOrUpdateCustomerMeals: addOrUpdateCustomerMeals
				},
				dispatch
			)
		};
	};

	private getInitialState = (): IRestaurantMealsContainerState => {
		return {} as IRestaurantMealsContainerState;
	};
}
export const RestaurantMeals = connect(
	RestaurantMealsContainer.mapStateToProps,
	RestaurantMealsContainer.mapDispatchToProps
)(RestaurantMealsContainer);
