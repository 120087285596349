import { RootState } from '../root-store';
import { createSelector } from 'reselect';
import { getTagsMealsForCustomer } from '../customer/customer-settings-selector';
import moment from 'moment';

import { DietPlanDayMeal, MealTypeEnum, TagsMealsForDietServings } from '../../client';
import { getFriendlyMealName, getNutritionFactsFromDietPlanDayMeals } from '../../shared/helpers';

export const getCustomerDietDefinition = (state: RootState) => state.diet.dietDefinition;
export const getCustomerDietPlan = (state: RootState) => state.diet.customerDietPlan;
export const getDietTypes = (state: RootState) => state.diet.dietTypes;
export const getDietDay = (state: RootState) => state.diet.dietPlanDay;
export const getDiestate = (state: RootState) => state.diet;
export const getProductRefsSelector = (state: RootState) => state.diet.productRefs;
// export const getDietPlanId = (state: RootState) => state.diet.dietPlanDay?.dietDayId;
export const allTagMealsForDietServings = (state: RootState) => state.diet.tagMealsForDietServings;
export const tagMealsForDietServings = createSelector(
	[allTagMealsForDietServings, getTagsMealsForCustomer],
	(tagMeals, getTagMeal) => {
		return tagMeals
			.filter(
				(x) => getTagMeal.findIndex((z: TagsMealsForDietServings) => z.tagId == x.id) < 0
			)
			.map((x) => x);
	}
);
export const getCommentsImage = createSelector(getDiestate, (dietState) => {
	return dietState.commentFiles?.fileInfos ?? [];
});
export const getStandardDietId = createSelector(getDietTypes, (dietTypes) => {
	const res = dietTypes.find((x) => x.name === 'Standard');
	return res?.id;
});
export const getVeggieDietId = createSelector(getDietTypes, (dietTypes) => {
	const res = dietTypes.find((x) => x.name === 'Veggie');
	return res?.id;
});
export const getVeggieFishDietId = createSelector(getDietTypes, (dietTypes) => {
	const res = dietTypes.find((x) => x.name === 'Veggie + Fish & Seafood');
	return res?.id;
});

export const getCustomerDietPlanName = createSelector(getCustomerDietDefinition, (cdp) => {
	if (!(cdp == null || cdp == undefined)) {
		return '' + cdp.name || '';
	} else return '';
});

export const getiSAnyActiveDiet = createSelector(getCustomerDietPlan, (cdp) => cdp != null);

export const getCustomerDietPlanDay = createSelector(
	(state: RootState) => state.diet,
	(diet) => diet.dietPlanDay
);

export const getCustomerDietPlanDayMeals = createSelector(
	getCustomerDietPlanDay,
	(cdpd) => cdpd?.dietPlanDayMeals
);
export const getGroupedCustomerDietPlanDayMeals = createSelector(getCustomerDietPlanDay, (cdpm) =>
	groupedByMealType(cdpm?.dietPlanDayMeals ?? [])
);

export const getSummaryForDayView = createSelector(getCustomerDietPlanDay, (dpd) =>
	getNutritionFactsFromDietPlanDayMeals(dpd?.dietPlanDayMeals)
);
export const getSummaryForDayViewEaten = createSelector(getCustomerDietPlanDay, (dpd) =>
	getNutritionFactsFromDietPlanDayMeals(dpd?.dietPlanDayMeals, true)
);

export const getDayPlanBeveragesFromCustomerDietPlanDayMeals = createSelector(
	getCustomerDietPlanDayMeals,
	(cdpdm) =>
		!(cdpdm == null || cdpdm == undefined)
			? cdpdm.filter((x) => x.mealType === MealTypeEnum.Beverages)
			: []
);

export const getCustomerDietPlanMeal = (mealId: number) =>
	createSelector(getCustomerDietPlanDayMeals, (meals) =>
		!(meals == null || meals == undefined) ? meals!.find((x) => x.id === mealId) : null
	);

export const getCustomerDietPlanDaySummary = createSelector(
	getDiestate,
	(rootstate) => rootstate.dietPlanDay?.dietPlanDayMeals
);

export const getCustomerDietPlanDayDateLocal = createSelector(getCustomerDietPlanDay, (dpd) => {
	return moment.utc(dpd?.dayDateUtc).toDate();
});

export interface IMealContainer {
	dietPlanDayMeal: DietPlanDayMeal;
}

export const getImageMetadata = (state: RootState) => state.diet.files;
export const getavailableDietPlans = (state: RootState) => state.diet.availableDietPlans;
export const getIsDayPlanAvailable = (state: RootState) => state.diet.isDayPlanAvailable;

export const getMealImage = (catalogId: number) =>
	createSelector(getImageMetadata, (meals) => {
		let element = meals.find((x) => x.catalogId === catalogId);
		if (
			!(element == null || element == undefined) &&
			!(element.metadatas == null || element.metadatas == undefined)
		) {
			let file = element.metadatas.find((x) => (x.fileName || '').indexOf('thumb') < 0);
			if (!(file?.lastVersion?.url == null || file?.lastVersion?.url == undefined)) {
				return process.env.REACT_APP_API_URL + '/' + file.lastVersion.url;
			}
		}
		return '';
	});

export const getMealProducts = (mealId: number) =>
	createSelector(getCustomerDietPlanMeal(mealId), (res) => res!.meal?.mealServings);
export const getTopTreeMealProducts = (mealId: number) =>
	createSelector(getMealProducts(mealId), (res) =>
		res!.sort((a, b) => b.qtyInGrams! - a.qtyInGrams!).slice(0, 3)
	);
export const getIsTrial = createSelector(getCustomerDietPlan, (dp) =>
	!(dp == null || dp == undefined) ? dp.trial : false
);
export const hasProductRefsLoaded = createSelector(
	getProductRefsSelector,
	(dp) => !(dp == null || dp == undefined) && dp.length > 0
);
export const hasTagMealsForDietServings = createSelector(
	tagMealsForDietServings,
	(dp) => !(dp == null || dp == undefined) && dp.length > 0
);

export const groupedByMealType = (daymeals: DietPlanDayMeal[]): IGroupedMeals[] => {
	const grouppedResults = [] as IGroupedMeals[];
	daymeals
		.filter((x) => x.mealType != MealTypeEnum.Beverages)
		.forEach((x) => {
			const arrayIndex = grouppedResults.findIndex((g) => g.key === x.mealType);
			if (arrayIndex < 0) {
				grouppedResults.push({
					key: x.mealType!,
					values: [x],
					dayNameMeal: getFriendlyMealName(x.mealType!)
				});
			} else {
				grouppedResults[arrayIndex].values.push(x);
			}
		});
	return grouppedResults;
};

export const hasNextDay = createSelector([getDietDay, getCustomerDietPlan], (day, plan) => {
	if (
		!(plan?.dietPlanDays == null || plan?.dietPlanDays == undefined) &&
		!(day == null || day == undefined)
	)
		return plan.dietPlanDays.findIndex((x) => x.dietDayId! > day.dietDayId!) > -1;
	else return false;
});
export const hasPreviousDay = createSelector([getDietDay, getCustomerDietPlan], (day, plan) => {
	if (
		!(plan?.dietPlanDays == null || plan?.dietPlanDays == undefined) &&
		!(day == null || day == undefined)
	)
		return plan.dietPlanDays.findIndex((x) => x.dietDayId! < day.dietDayId!) > -1;
	else return false;
});

export interface IGroupedMeals {
	dayNameMeal: string;
	values: DietPlanDayMeal[];
	key: MealTypeEnum;
}
