/* tslint:disable */
/* eslint-disable */
/**
 * TailoredApps.Auth.WebApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface ActivateCreatedAccount
 */
export interface ActivateCreatedAccount {
    /**
     * 
     * @type {string}
     * @memberof ActivateCreatedAccount
     */
    'guid'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivateCreatedAccount
     */
    'systemUniqueId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActivateCreatedAccount
     */
    'password'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActivateCreatedAccount
     */
    'reTypePassword'?: string | null;
}
/**
 * 
 * @export
 * @interface ActivateCreatedAccountResponse
 */
export interface ActivateCreatedAccountResponse {
    /**
     * 
     * @type {AuthData}
     * @memberof ActivateCreatedAccountResponse
     */
    'authData'?: AuthData;
}
/**
 * 
 * @export
 * @interface ActivateMailResponse
 */
export interface ActivateMailResponse {
    /**
     * 
     * @type {boolean}
     * @memberof ActivateMailResponse
     */
    'status'?: boolean;
}
/**
 * 
 * @export
 * @interface AddApplicationRole
 */
export interface AddApplicationRole {
    /**
     * 
     * @type {Role}
     * @memberof AddApplicationRole
     */
    'role'?: Role;
}
/**
 * 
 * @export
 * @interface AddApplicationRoleResponse
 */
export interface AddApplicationRoleResponse {
    /**
     * 
     * @type {Role}
     * @memberof AddApplicationRoleResponse
     */
    'role'?: Role;
}
/**
 * 
 * @export
 * @interface AddSecurity
 */
export interface AddSecurity {
    /**
     * 
     * @type {Security}
     * @memberof AddSecurity
     */
    'security'?: Security;
}
/**
 * 
 * @export
 * @interface AddSecurityResponse
 */
export interface AddSecurityResponse {
    /**
     * 
     * @type {Security}
     * @memberof AddSecurityResponse
     */
    'security'?: Security;
}
/**
 * 
 * @export
 * @interface AddUserRole
 */
export interface AddUserRole {
    /**
     * 
     * @type {number}
     * @memberof AddUserRole
     */
    'userId'?: number;
    /**
     * 
     * @type {number}
     * @memberof AddUserRole
     */
    'roleId'?: number;
}
/**
 * 
 * @export
 * @interface Application
 */
export interface Application {
    /**
     * 
     * @type {number}
     * @memberof Application
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Application
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Application
     */
    'systemUniqueId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Application
     */
    'url'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Application
     */
    'notificationEndpoint'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Application
     */
    'secret'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Application
     */
    'disableMailSending'?: boolean;
}
/**
 * 
 * @export
 * @interface AuthData
 */
export interface AuthData {
    /**
     * 
     * @type {string}
     * @memberof AuthData
     */
    'userId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AuthData
     */
    'userName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AuthData
     */
    'token'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AuthData
     */
    'mailConfirmed'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AuthData
     */
    'entryCreationDateTimeUtc'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthData
     */
    'lastCheckDateTimeUtc'?: string;
}
/**
 * 
 * @export
 * @interface ChangePassword
 */
export interface ChangePassword {
    /**
     * 
     * @type {string}
     * @memberof ChangePassword
     */
    'mail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChangePassword
     */
    'oldPassword'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChangePassword
     */
    'retypeNewPassword'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChangePassword
     */
    'newPassword'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChangePassword
     */
    'resetGuid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChangePassword
     */
    'systemUniqueId'?: string | null;
}
/**
 * 
 * @export
 * @interface ChangePasswordResponse
 */
export interface ChangePasswordResponse {
    /**
     * 
     * @type {AuthData}
     * @memberof ChangePasswordResponse
     */
    'authData'?: AuthData;
}
/**
 * 
 * @export
 * @interface CheckAuthToken
 */
export interface CheckAuthToken {
    /**
     * 
     * @type {string}
     * @memberof CheckAuthToken
     */
    'authToken'?: string | null;
}
/**
 * 
 * @export
 * @interface CheckAuthTokenResponse
 */
export interface CheckAuthTokenResponse {
    /**
     * 
     * @type {boolean}
     * @memberof CheckAuthTokenResponse
     */
    'valid'?: boolean;
    /**
     * 
     * @type {AuthData}
     * @memberof CheckAuthTokenResponse
     */
    'authData'?: AuthData;
}
/**
 * 
 * @export
 * @interface Create
 */
export interface Create {
    /**
     * 
     * @type {string}
     * @memberof Create
     */
    'username'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Create
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Create
     */
    'initialRole'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Create
     */
    'systemUniqueId'?: string | null;
}
/**
 * 
 * @export
 * @interface CreateResponse
 */
export interface CreateResponse {
    /**
     * 
     * @type {AuthData}
     * @memberof CreateResponse
     */
    'authData'?: AuthData;
}
/**
 * 
 * @export
 * @interface ExceptionHandlingResultModel
 */
export interface ExceptionHandlingResultModel {
    /**
     * 
     * @type {string}
     * @memberof ExceptionHandlingResultModel
     */
    'message'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ExceptionHandlingResultModel
     */
    'errorCode'?: number;
    /**
     * 
     * @type {Array<ExceptionOrValidationError>}
     * @memberof ExceptionHandlingResultModel
     */
    'errors'?: Array<ExceptionOrValidationError> | null;
}
/**
 * 
 * @export
 * @interface ExceptionOrValidationError
 */
export interface ExceptionOrValidationError {
    /**
     * 
     * @type {string}
     * @memberof ExceptionOrValidationError
     */
    'field'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExceptionOrValidationError
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @interface GetAllApplicationSecuritiesResponse
 */
export interface GetAllApplicationSecuritiesResponse {
    /**
     * 
     * @type {Array<Security>}
     * @memberof GetAllApplicationSecuritiesResponse
     */
    'securities'?: Array<Security> | null;
}
/**
 * 
 * @export
 * @interface GetApplicationRolesResponse
 */
export interface GetApplicationRolesResponse {
    /**
     * 
     * @type {Array<Role>}
     * @memberof GetApplicationRolesResponse
     */
    'roles'?: Array<Role> | null;
}
/**
 * 
 * @export
 * @interface GetApplicationsResponse
 */
export interface GetApplicationsResponse {
    /**
     * 
     * @type {Array<Application>}
     * @memberof GetApplicationsResponse
     */
    'applications'?: Array<Application> | null;
}
/**
 * 
 * @export
 * @interface GetLoggedInUserDataResponse
 */
export interface GetLoggedInUserDataResponse {
    /**
     * 
     * @type {string}
     * @memberof GetLoggedInUserDataResponse
     */
    'gravatar'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetLoggedInUserDataResponse
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetLoggedInUserDataResponse
     */
    'userId'?: number;
}
/**
 * 
 * @export
 * @interface GetRolesResponse
 */
export interface GetRolesResponse {
    /**
     * 
     * @type {Array<Role>}
     * @memberof GetRolesResponse
     */
    'roles'?: Array<Role> | null;
}
/**
 * 
 * @export
 * @interface GetUserByIdResponse
 */
export interface GetUserByIdResponse {
    /**
     * 
     * @type {User}
     * @memberof GetUserByIdResponse
     */
    'user'?: User;
}
/**
 * 
 * @export
 * @interface GetUserStatisticsResponse
 */
export interface GetUserStatisticsResponse {
    /**
     * 
     * @type {number}
     * @memberof GetUserStatisticsResponse
     */
    'growth'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetUserStatisticsResponse
     */
    'newUsers'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetUserStatisticsResponse
     */
    'users'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetUserStatisticsResponse
     */
    'lockedUsers'?: number;
}
/**
 * 
 * @export
 * @interface GetUsersResponse
 */
export interface GetUsersResponse {
    /**
     * 
     * @type {Array<User>}
     * @memberof GetUsersResponse
     */
    'users'?: Array<User> | null;
}
/**
 * 
 * @export
 * @interface Login
 */
export interface Login {
    /**
     * 
     * @type {string}
     * @memberof Login
     */
    'username'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Login
     */
    'password'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Login
     */
    'systemUniqueId'?: string | null;
}
/**
 * 
 * @export
 * @interface LoginResponse
 */
export interface LoginResponse {
    /**
     * 
     * @type {AuthData}
     * @memberof LoginResponse
     */
    'authData'?: AuthData;
}
/**
 * 
 * @export
 * @interface ProblemDetails
 */
export interface ProblemDetails {
    [key: string]: any | any;

    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'title'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProblemDetails
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'detail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'instance'?: string | null;
}
/**
 * 
 * @export
 * @interface Register
 */
export interface Register {
    /**
     * 
     * @type {string}
     * @memberof Register
     */
    'username'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Register
     */
    'password'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Register
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Register
     */
    'reTypePassword'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Register
     */
    'systemUniqueId'?: string | null;
}
/**
 * 
 * @export
 * @interface RegisterResponse
 */
export interface RegisterResponse {
    /**
     * 
     * @type {AuthData}
     * @memberof RegisterResponse
     */
    'authData'?: AuthData;
}
/**
 * 
 * @export
 * @interface ResetPassword
 */
export interface ResetPassword {
    /**
     * 
     * @type {string}
     * @memberof ResetPassword
     */
    'mail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ResetPassword
     */
    'systemUniqueId'?: string | null;
}
/**
 * 
 * @export
 * @interface Role
 */
export interface Role {
    /**
     * 
     * @type {number}
     * @memberof Role
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Role
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Role
     */
    'description'?: string | null;
    /**
     * 
     * @type {Application}
     * @memberof Role
     */
    'application'?: Application;
    /**
     * 
     * @type {number}
     * @memberof Role
     */
    'applicationId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Role
     */
    'allowInitial'?: boolean | null;
    /**
     * 
     * @type {Array<Security>}
     * @memberof Role
     */
    'securities'?: Array<Security> | null;
}
/**
 * 
 * @export
 * @interface SaveApplication
 */
export interface SaveApplication {
    /**
     * 
     * @type {Application}
     * @memberof SaveApplication
     */
    'application'?: Application;
}
/**
 * 
 * @export
 * @interface SaveApplicationResponse
 */
export interface SaveApplicationResponse {
    /**
     * 
     * @type {Application}
     * @memberof SaveApplicationResponse
     */
    'application'?: Application;
}
/**
 * 
 * @export
 * @interface Security
 */
export interface Security {
    /**
     * 
     * @type {number}
     * @memberof Security
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Security
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Security
     */
    'description'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Security
     */
    'applicationId'?: number;
    /**
     * 
     * @type {Application}
     * @memberof Security
     */
    'application'?: Application;
}
/**
 * 
 * @export
 * @interface UpdateApplicationRole
 */
export interface UpdateApplicationRole {
    /**
     * 
     * @type {number}
     * @memberof UpdateApplicationRole
     */
    'roleId'?: number;
    /**
     * 
     * @type {Role}
     * @memberof UpdateApplicationRole
     */
    'role'?: Role;
}
/**
 * 
 * @export
 * @interface UpdateApplicationRoleResponse
 */
export interface UpdateApplicationRoleResponse {
    /**
     * 
     * @type {Role}
     * @memberof UpdateApplicationRoleResponse
     */
    'role'?: Role;
}
/**
 * 
 * @export
 * @interface UpdateSelfUserData
 */
export interface UpdateSelfUserData {
    /**
     * 
     * @type {number}
     * @memberof UpdateSelfUserData
     */
    'userId'?: number;
    /**
     * 
     * @type {User}
     * @memberof UpdateSelfUserData
     */
    'user'?: User;
}
/**
 * 
 * @export
 * @interface UpdateSelfUserDataResponse
 */
export interface UpdateSelfUserDataResponse {
    /**
     * 
     * @type {User}
     * @memberof UpdateSelfUserDataResponse
     */
    'user'?: User;
}
/**
 * 
 * @export
 * @interface UpdateUserData
 */
export interface UpdateUserData {
    /**
     * 
     * @type {number}
     * @memberof UpdateUserData
     */
    'userId'?: number;
    /**
     * 
     * @type {User}
     * @memberof UpdateUserData
     */
    'user'?: User;
}
/**
 * 
 * @export
 * @interface UpdateUserDataResponse
 */
export interface UpdateUserDataResponse {
    /**
     * 
     * @type {User}
     * @memberof UpdateUserDataResponse
     */
    'user'?: User;
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {number}
     * @memberof User
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'mail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'dateOfBirth'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'sex'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'registerDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'userName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'createdDateUtc'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'confirmedMail'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'reset'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'lastInvalidLoginDateUtc'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'isLocked'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'unlockDateTimeUtc'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    'invalidLoginCount'?: number;
    /**
     * 
     * @type {Array<Role>}
     * @memberof User
     */
    'roles'?: Array<Role> | null;
}

/**
 * AuthenticationApi - axios parameter creator
 * @export
 */
export const AuthenticationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ActivateCreatedAccount} [activateCreatedAccount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateCreatedAccount: async (activateCreatedAccount?: ActivateCreatedAccount, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Authentication/Activate/Account`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(activateCreatedAccount, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ChangePassword} [changePassword] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePassword: async (changePassword?: ChangePassword, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Authentication/ChangePassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(changePassword, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CheckAuthToken} [checkAuthToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkAuthToken: async (checkAuthToken?: CheckAuthToken, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Authentication/CheckAuthToken`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(checkAuthToken, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Create} [create] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: async (create?: Create, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Authentication/Create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(create, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivateMail: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getActivateMail', 'id', id)
            const localVarPath = `/Authentication/Activate/Mail/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLoggedInUserData: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Authentication/GetLoggedInUserData`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Login} [login] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login: async (login?: Login, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Authentication/Login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(login, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postActivateMail: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('postActivateMail', 'id', id)
            const localVarPath = `/Authentication/Activate/Mail/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Register} [register] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        register: async (register?: Register, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Authentication/Register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(register, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ResetPassword} [resetPassword] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword: async (resetPassword?: ResetPassword, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Authentication/ResetPassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resetPassword, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthenticationApi - functional programming interface
 * @export
 */
export const AuthenticationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthenticationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ActivateCreatedAccount} [activateCreatedAccount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activateCreatedAccount(activateCreatedAccount?: ActivateCreatedAccount, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivateCreatedAccountResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.activateCreatedAccount(activateCreatedAccount, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ChangePassword} [changePassword] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changePassword(changePassword?: ChangePassword, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChangePasswordResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changePassword(changePassword, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CheckAuthToken} [checkAuthToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkAuthToken(checkAuthToken?: CheckAuthToken, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CheckAuthTokenResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkAuthToken(checkAuthToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Create} [create] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(create?: Create, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create(create, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getActivateMail(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivateMailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getActivateMail(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLoggedInUserData(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetLoggedInUserDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLoggedInUserData(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Login} [login] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async login(login?: Login, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.login(login, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postActivateMail(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivateMailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postActivateMail(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Register} [register] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async register(register?: Register, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RegisterResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.register(register, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ResetPassword} [resetPassword] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetPassword(resetPassword?: ResetPassword, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetPassword(resetPassword, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthenticationApi - factory interface
 * @export
 */
export const AuthenticationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthenticationApiFp(configuration)
    return {
        /**
         * 
         * @param {ActivateCreatedAccount} [activateCreatedAccount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activateCreatedAccount(activateCreatedAccount?: ActivateCreatedAccount, options?: any): AxiosPromise<ActivateCreatedAccountResponse> {
            return localVarFp.activateCreatedAccount(activateCreatedAccount, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ChangePassword} [changePassword] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePassword(changePassword?: ChangePassword, options?: any): AxiosPromise<ChangePasswordResponse> {
            return localVarFp.changePassword(changePassword, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CheckAuthToken} [checkAuthToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkAuthToken(checkAuthToken?: CheckAuthToken, options?: any): AxiosPromise<CheckAuthTokenResponse> {
            return localVarFp.checkAuthToken(checkAuthToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Create} [create] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(create?: Create, options?: any): AxiosPromise<CreateResponse> {
            return localVarFp.create(create, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivateMail(id: string, options?: any): AxiosPromise<ActivateMailResponse> {
            return localVarFp.getActivateMail(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLoggedInUserData(options?: any): AxiosPromise<GetLoggedInUserDataResponse> {
            return localVarFp.getLoggedInUserData(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Login} [login] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(login?: Login, options?: any): AxiosPromise<LoginResponse> {
            return localVarFp.login(login, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postActivateMail(id: string, options?: any): AxiosPromise<ActivateMailResponse> {
            return localVarFp.postActivateMail(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Register} [register] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        register(register?: Register, options?: any): AxiosPromise<RegisterResponse> {
            return localVarFp.register(register, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ResetPassword} [resetPassword] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword(resetPassword?: ResetPassword, options?: any): AxiosPromise<object> {
            return localVarFp.resetPassword(resetPassword, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthenticationApi - object-oriented interface
 * @export
 * @class AuthenticationApi
 * @extends {BaseAPI}
 */
export class AuthenticationApi extends BaseAPI {
    /**
     * 
     * @param {ActivateCreatedAccount} [activateCreatedAccount] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public activateCreatedAccount(activateCreatedAccount?: ActivateCreatedAccount, options?: AxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).activateCreatedAccount(activateCreatedAccount, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ChangePassword} [changePassword] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public changePassword(changePassword?: ChangePassword, options?: AxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).changePassword(changePassword, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CheckAuthToken} [checkAuthToken] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public checkAuthToken(checkAuthToken?: CheckAuthToken, options?: AxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).checkAuthToken(checkAuthToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Create} [create] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public create(create?: Create, options?: AxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).create(create, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public getActivateMail(id: string, options?: AxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).getActivateMail(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public getLoggedInUserData(options?: AxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).getLoggedInUserData(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Login} [login] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public login(login?: Login, options?: AxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).login(login, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public postActivateMail(id: string, options?: AxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).postActivateMail(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Register} [register] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public register(register?: Register, options?: AxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).register(register, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ResetPassword} [resetPassword] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public resetPassword(resetPassword?: ResetPassword, options?: AxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).resetPassword(resetPassword, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {SaveApplication} [saveApplication] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addApplication: async (saveApplication?: SaveApplication, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/User/Application`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveApplication, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AddApplicationRole} [addApplicationRole] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addApplicationRole: async (addApplicationRole?: AddApplicationRole, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/User/Application/Role`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addApplicationRole, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} roleId 
         * @param {number} securityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addRoleSecurity: async (roleId: number, securityId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'roleId' is not null or undefined
            assertParamExists('addRoleSecurity', 'roleId', roleId)
            // verify required parameter 'securityId' is not null or undefined
            assertParamExists('addRoleSecurity', 'securityId', securityId)
            const localVarPath = `/User/Application/Role/{roleId}/RoleSecurity/{securityId}`
                .replace(`{${"roleId"}}`, encodeURIComponent(String(roleId)))
                .replace(`{${"securityId"}}`, encodeURIComponent(String(securityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AddSecurity} [addSecurity] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSecurity: async (addSecurity?: AddSecurity, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/User/Application/Role/RoleSecurity`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addSecurity, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AddUserRole} [addUserRole] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUserRole: async (addUserRole?: AddUserRole, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/User/Role`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addUserRole, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApplication: async (applicationId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationId' is not null or undefined
            assertParamExists('deleteApplication', 'applicationId', applicationId)
            const localVarPath = `/User/Application/{applicationId}`
                .replace(`{${"applicationId"}}`, encodeURIComponent(String(applicationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} roleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApplicationRole: async (roleId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'roleId' is not null or undefined
            assertParamExists('deleteApplicationRole', 'roleId', roleId)
            const localVarPath = `/User/Application/Role/{roleId}`
                .replace(`{${"roleId"}}`, encodeURIComponent(String(roleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} roleId 
         * @param {number} securityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRoleSecurity: async (roleId: number, securityId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'roleId' is not null or undefined
            assertParamExists('deleteRoleSecurity', 'roleId', roleId)
            // verify required parameter 'securityId' is not null or undefined
            assertParamExists('deleteRoleSecurity', 'securityId', securityId)
            const localVarPath = `/User/Application/Role/{roleId}/RoleSecurity/{securityId}`
                .replace(`{${"roleId"}}`, encodeURIComponent(String(roleId)))
                .replace(`{${"securityId"}}`, encodeURIComponent(String(securityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} securityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSecurity: async (securityId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'securityId' is not null or undefined
            assertParamExists('deleteSecurity', 'securityId', securityId)
            const localVarPath = `/User/Application/Role/Security/{securityId}`
                .replace(`{${"securityId"}}`, encodeURIComponent(String(securityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} roleId 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserRole: async (roleId: number, userId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'roleId' is not null or undefined
            assertParamExists('deleteUserRole', 'roleId', roleId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('deleteUserRole', 'userId', userId)
            const localVarPath = `/User/Role/{RoleId}/User/{UserId}`
                .replace(`{${"RoleId"}}`, encodeURIComponent(String(roleId)))
                .replace(`{${"UserId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllApplicationSecurities: async (applicationId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationId' is not null or undefined
            assertParamExists('getAllApplicationSecurities', 'applicationId', applicationId)
            const localVarPath = `/User/Application/Securities/{applicationId}`
                .replace(`{${"applicationId"}}`, encodeURIComponent(String(applicationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApplicationRoles: async (applicationId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationId' is not null or undefined
            assertParamExists('getApplicationRoles', 'applicationId', applicationId)
            const localVarPath = `/User/Application/Role/{applicationId}`
                .replace(`{${"applicationId"}}`, encodeURIComponent(String(applicationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApplications: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/User/Application`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoles: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/User/Roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser: async (userId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getUser', 'userId', userId)
            const localVarPath = `/User/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserStatistics: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/User/Statistics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsers: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/User`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} applicationId 
         * @param {SaveApplication} [saveApplication] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateApplication: async (applicationId: number, saveApplication?: SaveApplication, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationId' is not null or undefined
            assertParamExists('updateApplication', 'applicationId', applicationId)
            const localVarPath = `/User/Application/{applicationId}`
                .replace(`{${"applicationId"}}`, encodeURIComponent(String(applicationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveApplication, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} roleId 
         * @param {UpdateApplicationRole} [updateApplicationRole] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateApplicationRole: async (roleId: number, updateApplicationRole?: UpdateApplicationRole, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'roleId' is not null or undefined
            assertParamExists('updateApplicationRole', 'roleId', roleId)
            const localVarPath = `/User/Application/Role/{roleId}`
                .replace(`{${"roleId"}}`, encodeURIComponent(String(roleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateApplicationRole, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateSelfUserData} [updateSelfUserData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSelfUserData: async (updateSelfUserData?: UpdateSelfUserData, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/User/SelfService/UserData`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateSelfUserData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {UpdateUserData} [updateUserData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserData: async (userId: number, updateUserData?: UpdateUserData, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateUserData', 'userId', userId)
            const localVarPath = `/User/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUserData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {SaveApplication} [saveApplication] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addApplication(saveApplication?: SaveApplication, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SaveApplicationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addApplication(saveApplication, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AddApplicationRole} [addApplicationRole] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addApplicationRole(addApplicationRole?: AddApplicationRole, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddApplicationRoleResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addApplicationRole(addApplicationRole, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} roleId 
         * @param {number} securityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addRoleSecurity(roleId: number, securityId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addRoleSecurity(roleId, securityId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AddSecurity} [addSecurity] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addSecurity(addSecurity?: AddSecurity, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddSecurityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addSecurity(addSecurity, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AddUserRole} [addUserRole] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addUserRole(addUserRole?: AddUserRole, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addUserRole(addUserRole, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteApplication(applicationId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteApplication(applicationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} roleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteApplicationRole(roleId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteApplicationRole(roleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} roleId 
         * @param {number} securityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteRoleSecurity(roleId: number, securityId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteRoleSecurity(roleId, securityId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} securityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSecurity(securityId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSecurity(securityId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} roleId 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUserRole(roleId: number, userId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUserRole(roleId, userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllApplicationSecurities(applicationId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAllApplicationSecuritiesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllApplicationSecurities(applicationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApplicationRoles(applicationId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetApplicationRolesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApplicationRoles(applicationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApplications(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetApplicationsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApplications(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRoles(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetRolesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRoles(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUser(userId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUserByIdResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUser(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserStatistics(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUserStatisticsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserStatistics(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsers(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUsersResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUsers(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} applicationId 
         * @param {SaveApplication} [saveApplication] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateApplication(applicationId: number, saveApplication?: SaveApplication, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SaveApplicationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateApplication(applicationId, saveApplication, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} roleId 
         * @param {UpdateApplicationRole} [updateApplicationRole] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateApplicationRole(roleId: number, updateApplicationRole?: UpdateApplicationRole, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateApplicationRoleResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateApplicationRole(roleId, updateApplicationRole, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateSelfUserData} [updateSelfUserData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSelfUserData(updateSelfUserData?: UpdateSelfUserData, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateSelfUserDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSelfUserData(updateSelfUserData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} userId 
         * @param {UpdateUserData} [updateUserData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserData(userId: number, updateUserData?: UpdateUserData, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateUserDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserData(userId, updateUserData, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * 
         * @param {SaveApplication} [saveApplication] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addApplication(saveApplication?: SaveApplication, options?: any): AxiosPromise<SaveApplicationResponse> {
            return localVarFp.addApplication(saveApplication, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AddApplicationRole} [addApplicationRole] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addApplicationRole(addApplicationRole?: AddApplicationRole, options?: any): AxiosPromise<AddApplicationRoleResponse> {
            return localVarFp.addApplicationRole(addApplicationRole, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} roleId 
         * @param {number} securityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addRoleSecurity(roleId: number, securityId: number, options?: any): AxiosPromise<object> {
            return localVarFp.addRoleSecurity(roleId, securityId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AddSecurity} [addSecurity] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSecurity(addSecurity?: AddSecurity, options?: any): AxiosPromise<AddSecurityResponse> {
            return localVarFp.addSecurity(addSecurity, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AddUserRole} [addUserRole] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUserRole(addUserRole?: AddUserRole, options?: any): AxiosPromise<object> {
            return localVarFp.addUserRole(addUserRole, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApplication(applicationId: number, options?: any): AxiosPromise<object> {
            return localVarFp.deleteApplication(applicationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} roleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteApplicationRole(roleId: number, options?: any): AxiosPromise<object> {
            return localVarFp.deleteApplicationRole(roleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} roleId 
         * @param {number} securityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRoleSecurity(roleId: number, securityId: number, options?: any): AxiosPromise<object> {
            return localVarFp.deleteRoleSecurity(roleId, securityId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} securityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSecurity(securityId: number, options?: any): AxiosPromise<object> {
            return localVarFp.deleteSecurity(securityId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} roleId 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserRole(roleId: number, userId: number, options?: any): AxiosPromise<object> {
            return localVarFp.deleteUserRole(roleId, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllApplicationSecurities(applicationId: number, options?: any): AxiosPromise<GetAllApplicationSecuritiesResponse> {
            return localVarFp.getAllApplicationSecurities(applicationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApplicationRoles(applicationId: number, options?: any): AxiosPromise<GetApplicationRolesResponse> {
            return localVarFp.getApplicationRoles(applicationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApplications(options?: any): AxiosPromise<GetApplicationsResponse> {
            return localVarFp.getApplications(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoles(options?: any): AxiosPromise<GetRolesResponse> {
            return localVarFp.getRoles(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser(userId: number, options?: any): AxiosPromise<GetUserByIdResponse> {
            return localVarFp.getUser(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserStatistics(options?: any): AxiosPromise<GetUserStatisticsResponse> {
            return localVarFp.getUserStatistics(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsers(options?: any): AxiosPromise<GetUsersResponse> {
            return localVarFp.getUsers(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} applicationId 
         * @param {SaveApplication} [saveApplication] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateApplication(applicationId: number, saveApplication?: SaveApplication, options?: any): AxiosPromise<SaveApplicationResponse> {
            return localVarFp.updateApplication(applicationId, saveApplication, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} roleId 
         * @param {UpdateApplicationRole} [updateApplicationRole] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateApplicationRole(roleId: number, updateApplicationRole?: UpdateApplicationRole, options?: any): AxiosPromise<UpdateApplicationRoleResponse> {
            return localVarFp.updateApplicationRole(roleId, updateApplicationRole, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateSelfUserData} [updateSelfUserData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSelfUserData(updateSelfUserData?: UpdateSelfUserData, options?: any): AxiosPromise<UpdateSelfUserDataResponse> {
            return localVarFp.updateSelfUserData(updateSelfUserData, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {UpdateUserData} [updateUserData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserData(userId: number, updateUserData?: UpdateUserData, options?: any): AxiosPromise<UpdateUserDataResponse> {
            return localVarFp.updateUserData(userId, updateUserData, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 
     * @param {SaveApplication} [saveApplication] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public addApplication(saveApplication?: SaveApplication, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).addApplication(saveApplication, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AddApplicationRole} [addApplicationRole] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public addApplicationRole(addApplicationRole?: AddApplicationRole, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).addApplicationRole(addApplicationRole, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} roleId 
     * @param {number} securityId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public addRoleSecurity(roleId: number, securityId: number, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).addRoleSecurity(roleId, securityId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AddSecurity} [addSecurity] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public addSecurity(addSecurity?: AddSecurity, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).addSecurity(addSecurity, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AddUserRole} [addUserRole] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public addUserRole(addUserRole?: AddUserRole, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).addUserRole(addUserRole, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} applicationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public deleteApplication(applicationId: number, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).deleteApplication(applicationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} roleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public deleteApplicationRole(roleId: number, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).deleteApplicationRole(roleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} roleId 
     * @param {number} securityId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public deleteRoleSecurity(roleId: number, securityId: number, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).deleteRoleSecurity(roleId, securityId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} securityId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public deleteSecurity(securityId: number, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).deleteSecurity(securityId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} roleId 
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public deleteUserRole(roleId: number, userId: number, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).deleteUserRole(roleId, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} applicationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getAllApplicationSecurities(applicationId: number, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).getAllApplicationSecurities(applicationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} applicationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getApplicationRoles(applicationId: number, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).getApplicationRoles(applicationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getApplications(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).getApplications(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getRoles(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).getRoles(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUser(userId: number, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).getUser(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUserStatistics(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).getUserStatistics(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUsers(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).getUsers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} applicationId 
     * @param {SaveApplication} [saveApplication] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public updateApplication(applicationId: number, saveApplication?: SaveApplication, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).updateApplication(applicationId, saveApplication, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} roleId 
     * @param {UpdateApplicationRole} [updateApplicationRole] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public updateApplicationRole(roleId: number, updateApplicationRole?: UpdateApplicationRole, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).updateApplicationRole(roleId, updateApplicationRole, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateSelfUserData} [updateSelfUserData] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public updateSelfUserData(updateSelfUserData?: UpdateSelfUserData, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).updateSelfUserData(updateSelfUserData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {UpdateUserData} [updateUserData] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public updateUserData(userId: number, updateUserData?: UpdateUserData, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).updateUserData(userId, updateUserData, options).then((request) => request(this.axios, this.basePath));
    }
}


