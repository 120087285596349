import * as React from 'react';
import * as Redux from 'redux';

import { RestaurantPartnerComponent } from './RestaurantPartnerComponent';
import { RootState } from '../../../../business/root-store';
import { connect } from 'react-redux';
import { getDocument } from '../../staticData/staticDataSelectors';
import { getDocumentByUrl } from '../../../organisms/Document/DocumentEpics';

import { AnyAction, Dispatch } from 'redux';
import { Document, Restaurant } from '../../../../client';

interface IRestaurantPartnerContainerReduxMergedProps {
	document: Document;
}

interface IRestaurantPartnerContainerOwnProps {
	restaurant: Restaurant;
}

interface IRestaurantPartnerContainerDispatchProps {
	getDocumentByUrl: (documentUrl: string) => void;
}

interface IRestaurantPartnerContainerProps
	extends IRestaurantPartnerContainerOwnProps,
		IRestaurantPartnerContainerDispatchProps,
		IRestaurantPartnerContainerReduxMergedProps {}

interface IRestaurantPartnerContainerState {}

class RestaurantPartnerContainer extends React.Component<
	IRestaurantPartnerContainerProps,
	IRestaurantPartnerContainerState
> {
	constructor(props: IRestaurantPartnerContainerProps, state: IRestaurantPartnerContainerState) {
		super(props, state);

		this.state = this.getInitialState();
		this.props.getDocumentByUrl('restaurantpromo');
	}

	public render(): JSX.Element {
		return (
			<RestaurantPartnerComponent
				document={this.props.document}
				restaurant={this.props.restaurant}
			/>
		);
	}

	public componentDidMount(): void {}

	public static mapStateToProps = (
		state: RootState,
		ownProps: IRestaurantPartnerContainerOwnProps
	): IRestaurantPartnerContainerReduxMergedProps => {
		return { document: getDocument(state) };
	};

	public static mapDispatchToProps = (
		dispatch: Dispatch<AnyAction>
	): IRestaurantPartnerContainerDispatchProps => {
		return {
			...Redux.bindActionCreators({ getDocumentByUrl: getDocumentByUrl }, dispatch)
		};
	};

	private getInitialState = (): IRestaurantPartnerContainerState => {
		return {} as IRestaurantPartnerContainerState;
	};
}
export const RestaurantPartner = connect(
	RestaurantPartnerContainer.mapStateToProps,
	RestaurantPartnerContainer.mapDispatchToProps
)(RestaurantPartnerContainer);
