import { ActionType } from 'typesafe-actions';
import { RootState } from '../root-store';
import { ThunkAction } from 'redux-thunk';
import moment from 'moment';
import { push } from 'connected-react-router';
import { retrieveCustomerSettings } from '../customer/customer-settings-actions';
import { routes } from '../router/path-definition';
import { actions as toastrActions } from 'react-redux-toastr';

import {
	DietApi,
	DietDefinition,
	DietPlan,
	DietPlanDay,
	DietPlanDayMeal,
	DietPlanDefinition,
	FoodApi,
	ProductRefNamePair,
	Tag
} from '../../client';
import { FileApi, FileInfo, FileMetadata } from '../../files-client';
import { GetApiConfig, GetFileConfig } from '../api-client-config';

export const dietActionName = {
	LOAD_CUSTOMER_DIET_PLAN: 'LOAD_CUSTOMER_DIET_PLAN',
	LOAD_CUSTOMER_SETTINGS_DIET_DEFINITION: 'LOAD_CUSTOMER_SETTINGS_DIET_DEFINITION',
	LOAD_CUSTOMER_ACTIVE_DIET_DAY: 'LOAD_CUSTOMER_ACTIVE_DIET_DAY',
	LOAD_FILE_METADATA: 'LOAD_FILE_METADATA',
	LOAD_PREVIOUS_DAY: 'LOAD_PREVIOUS_DAY',
	LOAD_NEXT_DAY: 'LOAD_NEXT_DAY',
	LOAD_PRODUCT_REF_NAME_PAIR: 'LOAD_PRODUCT_REF_NAME_PAIR',
	LOAD_TAG_MEALS_FOR_DIET_SERVING: 'LOAD_TAG_MEALS_FOR_DIET_SERVING',
	EXCHANGE_DIET_PLAN_DAY_MEAL: 'EXCHANGE_DIET_PLAN_DAY_MEAL',
	PROCESS_MEAL_EATEN: 'PROCESS_MEAL_EATEN',
	LOAD_AVAILABLE_DIET_PLANS: 'LOAD_AVAILABLE_DIET_PLANS',
	LOAD_COMMENTS_FILE: 'LOAD_COMMENTS_FILE',
	CLEAR_COMMENTS_FILE: 'CLEAR_COMMENTS_FILE'
} as const;

export const dietActions = {
	loadCustomerDietPlan: (newValue: DietPlan) =>
		({ type: dietActionName.LOAD_CUSTOMER_DIET_PLAN, payload: { newValue } } as const),
	loadCustomerDietType: (dietDefinition: DietDefinition) =>
		({
			type: dietActionName.LOAD_CUSTOMER_SETTINGS_DIET_DEFINITION,
			payload: { dietDefinition }
		} as const),
	loadActiveDay: (newValue: DietPlanDay) =>
		({ type: dietActionName.LOAD_CUSTOMER_ACTIVE_DIET_DAY, payload: { newValue } } as const),
	loadImages: (catalogId: number, metadatas: FileMetadata[]) =>
		({ type: dietActionName.LOAD_FILE_METADATA, payload: { catalogId, metadatas } } as const),
	loadCommentsImages: (catalogId: number, fileInfos: FileInfo[]) =>
		({ type: dietActionName.LOAD_COMMENTS_FILE, payload: { catalogId, fileInfos } } as const),

	clearCommentsImages: () => ({ type: dietActionName.CLEAR_COMMENTS_FILE } as const),
	loadPreviousDay: () => ({ type: dietActionName.LOAD_PREVIOUS_DAY } as const),
	loadNextDay: () => ({ type: dietActionName.LOAD_NEXT_DAY } as const),
	loadProductsAndRefs: (productsAndRefs: ProductRefNamePair[]) =>
		({
			type: dietActionName.LOAD_PRODUCT_REF_NAME_PAIR,
			payload: { productsAndRefs }
		} as const),
	loadTagMealsForDietServings: (tags: Tag[]) =>
		({ type: dietActionName.LOAD_TAG_MEALS_FOR_DIET_SERVING, payload: { tags } } as const),
	exchangeMeal: (dietPlanDayMeal: DietPlanDayMeal) =>
		({
			type: dietActionName.EXCHANGE_DIET_PLAN_DAY_MEAL,
			payload: { dietPlanDayMeal }
		} as const),
	processMealEaten: (dietPlanDayMealId: number, isMealEaten: boolean) =>
		({
			type: dietActionName.PROCESS_MEAL_EATEN,
			payload: { dietPlanDayMealId, isMealEaten }
		} as const),
	loadAvailableDietPlans: (plans: DietPlanDefinition[]) =>
		({ type: dietActionName.LOAD_AVAILABLE_DIET_PLANS, payload: { plans } } as const)
};

export type DietAction = ActionType<typeof dietActions>;

export const loadAvailableDietPlans =
	(): ThunkAction<any, RootState, any, any> => (dispatch, getState) => {
		const state = getState();
		const api = new DietApi(GetApiConfig(state));
		api.getAvailableDietPlans().then((response) => {
			dispatch(dietActions.loadAvailableDietPlans(response.data.plans!));
		});
	};

export const addOrUpdateDietPlanDay =
	(dpd: DietPlanDay): ThunkAction<any, RootState, any, any> =>
	(dispatch, getState) => {
		const state = getState();
		const api = new DietApi(GetApiConfig(state));
		api.addOrUpdateDietPlanDay({ dietPlanDay: dpd }).then((response) => {
			dispatch(dietActions.loadActiveDay(response.data.dietPlanDay!));
			dispatch(push(routes.home.path));
		});
	};

export const loadCustomerDietPlan =
	(customerId: number, dietPlanId: number): ThunkAction<any, RootState, any, any> =>
	(dispatch, getState) => {
		const state = getState();
		const api = new DietApi(GetApiConfig(state));
		api.getDietPlan(customerId, dietPlanId).then((resp) => {
			if (!(resp.data.dietPlan == null || resp.data.dietPlan == undefined)) {
				dispatch(dietActions.loadCustomerDietPlan(resp.data.dietPlan));
				if (!!resp?.data?.dietPlan?.generatedFrom)
					dispatch(retrieveCustomerSettings(resp.data.dietPlan.generatedFrom));
				if (!!resp.data.dietPlan.customerId && !!resp.data.dietPlan.dietPlanDays) {
					const day = resp.data.dietPlan.dietPlanDays.find(
						(x) =>
							x!.dayDateUtc!.split('T')[0] === new Date().toISOString().split('T')[0] //todo
					);
					if (
						!(day == null || day == undefined) &&
						!(day.dietDayId == null || day.dietDayId == undefined)
					) {
						dispatch(loadDietPlanDay(day.dietDayId));
					} else {
						const lastDay =
							resp.data.dietPlan.dietPlanDays[
								resp.data.dietPlan.dietPlanDays.length - 1
							];
						const firstDay = resp.data.dietPlan.dietPlanDays[0];
						if (new Date() < moment(firstDay!.dayDateUtc!).toDate()) {
							dispatch(loadDietPlanDay(firstDay!.dietDayId!));
						} else {
							dispatch(loadDietPlanDay(lastDay!.dietDayId!));

							dispatch(
								toastrActions.add({
									type: 'warning',
									message: 'Twój plan nie posiada diety na dziś',
									title: 'Dokonaj subskrypcji',
									position: 'bottom-center',
									id: 'no-day',
									options: { timeOut: 10000 }
								})
							);
						}
					}
				}
			}
		});
	};

export const loadDietPlanDay =
	(dietDay: number): ThunkAction<any, RootState, any, any> =>
	(dispatch, getState) => {
		const state = getState();
		const fileApi = new FileApi(GetFileConfig(state));
		const dietApi = new DietApi(GetApiConfig(state));
		const customerId = state.administration.customerSettings.customerId!;
		const dietPlandId = state.diet.customerDietPlan?.id!;
		dietApi
			.getDietPlanDay(customerId, dietPlandId, dietDay)
			.then((x) => {
				if (!(x.data.dietPlan == null || x.data.dietPlan == undefined)) {
					dispatch(dietActions.loadActiveDay(x.data.dietPlan));
				}
			})
			.then(() => {
				const state = getState();
				if (!!state?.diet?.dietPlanDay?.dietPlanDayMeals) {
					state.diet.dietPlanDay.dietPlanDayMeals.forEach((element) => {
						if (!!element?.meal?.imagesCatalog) {
							fileApi.getFilesFromCatalog(element.meal.imagesCatalog).then((x) => {
								dispatch(
									dietActions.loadImages(
										element.meal?.imagesCatalog!,
										x.data.fileMetadatas || []
									)
								);
							});
						}
					});
				}
			});
	};

export const loadProductAndRefs =
	(): ThunkAction<any, RootState, any, any> => (dispatch, getState) => {
		const state = getState();
		const api = new FoodApi(GetApiConfig(state));
		api.getProductRefAndNames().then((products) => {
			dispatch(dietActions.loadProductsAndRefs(products.data.refNamePair || []));
		});
	};

export const loadTagMealsForDietServings =
	(dietTypeId: number): ThunkAction<any, RootState, any, any> =>
	(dispatch, getState) => {
		const state = getState();
		const api = new DietApi(GetApiConfig(state));
		api.getTagMealsForDietServings(dietTypeId).then((resp) => {
			dispatch(dietActions.loadTagMealsForDietServings(resp.data.tags || []));
		});
	};
