import './BeveragesEditor.scss';

import { ActionButton } from '../../../atoms/ActionButton/ActionButton';
import { AddToastPayload } from 'react-redux-toastr';
import { ICurrentMeals } from '../../MealCard/Meal/meal-interfaces';
import { Ingredient } from '../../MealCard/CustomMeal/Ingredient/IngredientContainer';
import { List } from 'immutable';
import React from 'react';
import { RemoveIcon } from '../../../../shared/assets/remove-icon';
import { confirm } from 'react-confirm-box';
import { useConfirmationBoxOptions } from '../../../../hooks/useConfirmationBoxOptions';

import { Gender, Meal, MealFoodProduct, MealTypeEnum } from '../../../../client';
import { ISearchItem, SearchBox } from '../../../atoms/search-box/search-box';

interface IBeveragesEditorComponentProps {
	gender: Gender;
	allBeverages: List<Meal>;
	currentMeals: ICurrentMeals;
	addOrUpdateMeals: (meals: Meal[]) => void;
	toast: (message: AddToastPayload) => void;
	onCloseClicked: () => void;
	deleteDpdm: (mealId: number, mealType: MealTypeEnum) => void;
}

interface IBeveragesEditorComponentState {
	meals: MealWithTempAddedFlag[];
	searchText: string;
}

interface MealWithTempAddedFlag extends Meal {
	tempAdded: boolean;
}

export class BeveragesEditorComponent extends React.Component<
	IBeveragesEditorComponentProps,
	IBeveragesEditorComponentState
> {
	constructor(props: IBeveragesEditorComponentProps, state: IBeveragesEditorComponentState) {
		super(props, state);
		this.state = this.getInitialState();
	}

	public render(): JSX.Element {
		let allBeveragesList: ISearchItem[] = [];
		this.props.allBeverages.map((beverage: Meal) => {
			allBeveragesList.push({
				id: beverage.id!,
				value: beverage,
				display: beverage.name!
			});
		});

		return (
			<div className="page">
				<div className="Beverages_header">
					<p className="header">
						Co dzisiaj {this.props.gender === Gender.Female ? 'wypiłaś?' : 'wypiłeś?'}
					</p>
				</div>
				<SearchBox
					data={allBeveragesList}
					activateOnFocus
					placeholder="Wyszukaj napój"
					onSelect={this.handleBeverageSelected}
					onSearchChanged={this.onSearchTextChanged}
					value={this.state.searchText}
					// className="Beverages_searchBox"
				/>
				<br />
				{this.state.meals.map((meal: MealWithTempAddedFlag, mealIndex: number) => {
					const handleRemove = async (): Promise<void> => {
						const options: any = useConfirmationBoxOptions();
						const result = await confirm(
							'Czy na pewno chcesz usunąć ten napój?',
							options
						);
						if (result) {
							this.setState({
								meals: this.state.meals.filter(
									(x, mealStateIndex) => mealStateIndex !== mealIndex
								)
							});
							if (!meal.tempAdded) {
								this.props.deleteDpdm(meal.id!, this.props.currentMeals.mealType);
							}
						}
					};
					return (
						<div key={'meal_beverage_' + meal.id} className="Beverages_main">
							<div className="Beverages_header">
								<span>
									<strong>{meal.name}</strong>
								</span>
								<RemoveIcon
									className="Beverages_removeIcon"
									onClick={handleRemove}
								/>
							</div>
							<div>
								{meal.mealFoodProducts?.map(
									(mfp: MealFoodProduct, mfpIndex: number) => {
										const handleIngredientUpdated = (
											mfp: MealFoodProduct
										): void => {
											this.setState((prevState) => {
												return {
													...prevState,
													meals: prevState.meals.map(
														(stateMeal, stateMealIndex) => {
															if (stateMealIndex !== mealIndex) {
																return stateMeal;
															}
															return {
																...stateMeal,
																mealFoodProducts:
																	stateMeal.mealFoodProducts?.map(
																		(
																			stateMfp,
																			stateMfpIndex
																		) => {
																			if (
																				stateMfpIndex !==
																				mfpIndex
																			) {
																				return stateMfp;
																			}
																			return mfp;
																		}
																	)
															};
														}
													)
												};
											});
										};
										return (
											<Ingredient
												mfp={mfp}
												key={mfp.foodProductId}
												handleIngredientUpdated={handleIngredientUpdated}
												className={
													(meal.mealFoodProducts?.length ?? 0) > 1
														? 'Beverages_padding25'
														: ''
												}
												disableRemoveIcon
												disableBorder
											/>
										);
									}
								)}
							</div>
						</div>
					);
				})}
				<ActionButton
					text={this.state.meals.length === 1 ? 'Zapisz napój' : 'Zapisz napoje'}
					className={`buttonClassName Beverages_actionButton`}
					onClick={this.handleOnSaveClicked}
				/>
			</div>
		);
	}

	private handleOnSaveClicked = () => {
		this.props.addOrUpdateMeals(this.state.meals);
		document.dispatchEvent(new CustomEvent('closeModal', {}));
	};

	private handleBeverageSelected = (beverageItem: ISearchItem): void => {
		if (!this.state.meals.some((x) => x.id === beverageItem.id)) {
			this.setState((prevState) => {
				return { meals: [...prevState.meals, { ...beverageItem.value, tempAdded: true }] };
			});
		} else {
			this.props.toast({
				message: 'Napój jest już wybrany',
				type: 'warning',
				title: beverageItem.display
			});
		}
	};

	private onSearchTextChanged = (text: string) => {
		this.setState({ ...this.state, searchText: text });
	};

	private getInitialState = (): IBeveragesEditorComponentState => {
		return {
			meals: this.props.currentMeals.meals,
			searchText: ''
		} as any;
	};
}
