import { AuxiliaryApi } from '../../../client';
import { GetApiConfig } from '../../../business/api-client-config';
import { RootState } from '../../../business/root-store';
import { ThunkAction } from 'redux-thunk';
import { blogDocumentsFetched } from '../../molecules/staticData/staticDataActions';

export const getBlogDocuments =
	(): ThunkAction<any, RootState, any, any> => (dispatch, getState) => {
		const state = getState();
		const api = new AuxiliaryApi(GetApiConfig(state));
		api.getDocuments({ documentType: 'Blog' }).then((x) => {
			dispatch(blogDocumentsFetched(x.data.documents ?? []));
		});
	};
