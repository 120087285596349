import './QrBarCodeScanner.scss';

import * as zbarWasm from '@undecaf/zbar-wasm';

import { ActionButton } from '../../atoms/ActionButton/ActionButton';

import { useEffect, useRef, useState } from 'react';

export const QrBarCodeScanner = () => {
	let requestId: number | undefined;
	const [facingMode, setFacingMode] = useState(false);
	const videoElement = useRef<HTMLVideoElement>(null);
	const canvasElement = new OffscreenCanvas(1000, 1000);
	const [stream, setStream] = useState<MediaStream | null>(null);
	useEffect(() => {
		getVideo();
	}, []);
	useEffect(() => {
		if (stream) {
			return () => {
				stream.getTracks().forEach((track) => {
					if (track.readyState === 'live') {
						track.stop();
					}
				});
			};
		}
	}, [stream]);
	const getVideo = () => {
		navigator.mediaDevices
			.getUserMedia({
				audio: false,
				video: { facingMode: facingMode ? 'user' : 'environment' }
			})
			.then((stream) => {
				setStream(stream);
				if (videoElement.current) {
					videoElement.current.srcObject = stream;
					detectVideo(true);
				}
			});
	};
	const detect = () => {
		const source = videoElement.current;
		const ctx = canvasElement.getContext('2d');
		function isOffscreenCanvasWorking() {
			try {
				return Boolean(new OffscreenCanvas(1, 1).getContext('2d'));
			} catch {
				return false;
			}
		}

		function getOffCtx2d(width: any, height: any) {
			if (isOffscreenCanvasWorking()) {
				// Only resizing the canvas caused Chromium to become progressively slower
				var offCanvas = new OffscreenCanvas(width, height);
				return offCanvas.getContext('2d');
			}
		}

		canvasElement.width = source?.videoWidth || source?.width || 800;
		canvasElement.height = source?.videoHeight || source?.height || 600;
		const offCtx = getOffCtx2d(canvasElement.width, canvasElement.height) || ctx;
		if (source) {
			offCtx?.drawImage(source, 0, 0);
			ctx?.drawImage(source, 0, 0);
		}
		const imageData = offCtx?.getImageData(0, 0, canvasElement.width, canvasElement.height);
		if (canvasElement.height && canvasElement.width && imageData && ctx) {
			return zbarWasm.scanImageData(imageData).then((symbols: zbarWasm.ZBarSymbol[]) => {
				symbols.forEach((symbol) => {
					const lastPoint = symbol.points[symbol.points.length - 1];
					ctx.moveTo(lastPoint.x, lastPoint.y);
					symbol.points.forEach((point) => ctx.lineTo(point.x, point.y));

					ctx.lineWidth = Math.max(
						Math.min(canvasElement.height, canvasElement.width) / 100,
						1
					);
					ctx.strokeStyle = '#00e00060';
					ctx.stroke();
				});

				symbols.forEach((s: zbarWasm.ZBarSymbol) => {
					console.log(s.typeName + ' ' + s.decode());
				});
			});
		} else {
			return Promise.resolve();
		}
	};

	const detectVideo = (active: boolean) => {
		if (active) {
			detect().then(() => (requestId = requestAnimationFrame(() => detectVideo(true))));
		} else {
			cancelAnimationFrame(requestId!);
			requestId = undefined;
		}
	};
	const handleSetFacingMode = () => {
		setFacingMode(!facingMode);
		getVideo();
	};
	return (
		<div className="QrBarCodeScanner">
			<video
				className="QrBarCodeScanner_video"
				ref={videoElement}
				muted
				playsInline
				autoPlay
			/>
			<ActionButton text="Odwróć kamerę" onClick={handleSetFacingMode} />
		</div>
	);
};
