import { GetApiConfig } from '../../../business/api-client-config';
import { List } from 'immutable';
import { RootState } from '../../../business/root-store';
import { ThunkAction } from 'redux-thunk';
import { beveragesFetched } from './beverages-actions';
import { loadDietPlanDay } from '../../../business/diet/diet-action';

import { CustomerApi, DietApi, Meal, MealTypeEnum } from '../../../client';

export const getBeverages = (): ThunkAction<any, RootState, any, any> => (dispatch, getState) => {
	const state = getState();
	const dietDefinitionId = state.diet.dietDefinition?.id!;
	const api = new DietApi(GetApiConfig(state));
	api.getBeverages(dietDefinitionId).then((response) => {
		if (response.data.beverages) {
			dispatch(beveragesFetched(List<Meal>(response.data.beverages)));
		}
	});
};

export const deleteDietPlanDayMeal =
	(mealId: number, mealType: MealTypeEnum): ThunkAction<any, RootState, any, any> =>
	(dispatch, getState) => {
		const state = getState();
		const api = new CustomerApi(GetApiConfig(state));
		api.deleteMealInDayPlan(
			mealId,
			mealType,
			state.diet.dietPlanDay?.dietDayId!,
			state.diet.customerDietPlan?.customerId!,
			state.administration.customerSettings.subscriptions[0].id!
		).then(() => {
			dispatch(loadDietPlanDay(state.diet.dietPlanDay?.dietDayId!));
		});
	};
