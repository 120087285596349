import './ConfirmationBox.scss';

import { ActionButton } from '../ActionButton/ActionButton';

export interface IConfirmationBoxProps {
	message: string;
	onConfirm(): void;
	onCancel(): void;
}

export const ConfirmationBox = (props: IConfirmationBoxProps) => {
	return (
		<div className="ConfirmationBox">
			{props.message}
			<div className="ConfirmationBox__action-buttons">
				<ActionButton text="Tak" onClick={props.onConfirm} />
				<ActionButton styleType="secondary" text="Anuluj" onClick={props.onCancel} />
			</div>
		</div>
	);
};
