import React from 'react';

interface IMoreIconComponentProps {
	onClick?(event: React.SyntheticEvent<any> | undefined): any;
	className?: string;
}

class MoreIconComponent extends React.Component<IMoreIconComponentProps> {
	constructor(props: IMoreIconComponentProps) {
		super(props);
	}

	public render(): JSX.Element {
		return (
			<div onClick={this.props.onClick} className={this.props.className}>
				<svg
					width="18"
					height="4"
					viewBox="0 0 18 4"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<circle cx="2" cy="2" r="2" fill="#B0B4C4" />
					<circle cx="9" cy="2" r="2" fill="#B0B4C4" />
					<circle cx="16" cy="2" r="2" fill="#B0B4C4" />
				</svg>
			</div>
		);
	}
}
export const MoreIcon = MoreIconComponent;
