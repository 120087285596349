import React from 'react';

export const BreakfastIcon: React.FC = (props) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 22 22"
			width="20px"
			height="20px"
			style={{ fill: 'rgb(187, 107, 217)' }}
		>
			<path d="M0 0h24v24H0V0z" fill="none"></path>
			<path d="M20 3H4v10c0 2.21 1.79 4 4 4h6c2.21 0 4-1.79 4-4v-3h2c1.11 0 2-.9 2-2V5c0-1.11-.89-2-2-2zm0 5h-2V5h2v3zM4 19h16v2H4z"></path>
		</svg>
	);
};
