import './beverages.scss';

import ActionLink from '../../atoms/action-link/action-link';
import { BeverageItem } from './beverage-item/beverage-item-container';
import { BeveragesIcon } from '../../../shared/assets/beverages-icon';
import { ICurrentMeals } from '../MealCard/Meal/meal-interfaces';
import { NutritionFactSummary } from '../nutrition-fact-summary/nutrition-fact-summary';
import React from 'react';
import { getNutritionFactsFromDietPlanDayMeals } from '../../../shared/helpers';
import { primaryOrangeColor } from '../../../shared/shared';

import { DietPlanDayMeal, MealTypeEnum } from '../../../client';

interface IBeveragesComponentProps {
	currentMeals: ICurrentMeals;
	beverages: DietPlanDayMeal[];
	navigateTo: (path: string, state?: any) => void;
	setCurrentMeals(currentMeals: ICurrentMeals): void;
	handleCustomMealModalClicked: (value: boolean) => void;
	handleBeveragesEditorModalClicked: (value: boolean) => void;
}

interface IBeveragesComponentState {}

export class BeveragesComponent extends React.Component<
	IBeveragesComponentProps,
	IBeveragesComponentState
> {
	constructor(props: IBeveragesComponentProps, state: IBeveragesComponentState) {
		super(props, state);
		this.state = this.getInitialState();
	}
	public render(): JSX.Element {
		const summary = getNutritionFactsFromDietPlanDayMeals(this.props.beverages ?? []);

		return (
			<div className="beverages">
				<div className="beverages__header">
					<div className="beverages__header-title">Napoje</div>
					<div className="beverages__header-right">
						<div className="beverages__manage-menu">
							<ActionLink
								text={
									this.props.beverages?.length ?? 0 > 0
										? 'Edytuj lub dodaj'
										: 'Dodaj'
								}
								onClick={this.handleAddOrEditBeverages}
								textColor={primaryOrangeColor}
							>
								<BeveragesIcon />
							</ActionLink>
							<ActionLink
								text="Własny napój"
								textColor={primaryOrangeColor}
								onClick={this.handleAddCustomBeverage}
							>
								<i className="fas fa-edit fa-lg beverages__edit-icon" />
							</ActionLink>
						</div>
						<div className="style-desktop">
							<NutritionFactSummary summary={summary} />
						</div>
					</div>
				</div>
				<div className="style-mobile">
					<NutritionFactSummary summary={summary} />
				</div>
				<div className="beverages__content">
					<div className="beverages__items">
						{!this.props.beverages ||
							(this.props.beverages.length === 0 && (
								<div className="beverages__items-placeholder">
									Brak napojów do wyświetlenia.
								</div>
							))}
						{this.props.beverages?.map((dietPlanDayMeal: DietPlanDayMeal) => {
							return (
								<BeverageItem
									key={'BeverageItem_' + dietPlanDayMeal.id}
									dietPlanDayMeal={dietPlanDayMeal}
								/>
							);
						})}
					</div>
				</div>
			</div>
		);
	}

	private handleAddOrEditBeverages = (): void => {
		this.props.setCurrentMeals({
			...this.props.currentMeals,
			mealType: MealTypeEnum.Beverages,
			meals: this.props.beverages.map((x) => {
				return { ...x.meal, mealFoodProducts: x.mealServings };
			})
		});
		this.props.handleBeveragesEditorModalClicked(true);
	};

	private handleAddCustomBeverage = (): void => {
		this.props.setCurrentMeals({
			...this.props.currentMeals,
			meals: [],
			mealType: MealTypeEnum.Beverages
		});
		this.props.handleCustomMealModalClicked(true);
	};

	private getInitialState = (): IBeveragesComponentState => {
		return {} as IBeveragesComponentState;
	};
}
