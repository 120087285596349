import './landing-page-customer-opinions.css';

import { CustomerOpinionsSliderItem } from './customer-opinions-slider-item/customer-opinions-slider-item';
import React from 'react';
import { Slider } from '../../../atoms/slider/slider';

const sliderItems = [
	<CustomerOpinionsSliderItem
		title="Genialna dieta z łatwym dostępem!"
		quote="Z aplikacji skorzystałem, bo chciałem schudnąć. Nie chciałem jednak rezygnować ze wszystkiego w tym spotkań ze znajomymi na mieście. Super opcja z tymi restauracjami. Poza tym appka intuicyjna i daje dużo możliwości, polecam!"
		author="Kacper, 32 lata"
	/>,
	<CustomerOpinionsSliderItem
		title="Zostanę tu na dłużej."
		quote="Próbowałam wielu diet, z różnym skutkiem. Ze względu na liczne obowiązki wiedziałam, że potrzebuję diety, którą mogę dostosować do swoich potrzeb. I w końcu znalazłam! Wiele propozycji posiłków przypadło mi do gustu, ale co ważniejsze, mogłam dodać swoje sprawdzone przepisy. Z Diet Designer zostanę na dłużej!"
		author="Ania, 24 lata"
	/>,
	<CustomerOpinionsSliderItem
		title="Polecam :)"
		quote="Od zeszłego roku biegam amatorsko. Do tej pory miałam problem z dobraniem ilości kalorii w diecie. Nie wiedziałam, czy jem za dużo, czy za mało. Przez co moje rezultaty były średnie... Nie mogłam schudnąć, a brakowało mi też siły do ćwiczeń. Dzięki wypróbowaniu Diet Designer udało mi się to wszystko poukładać. Teraz czuję się o wiele lepiej, poprawiam czasu i w końcu schudłam!"
		author="Emilia, 23 lata"
	/>,
	<CustomerOpinionsSliderItem
		title="Super pomocnik przy chodzeniu na siłownię."
		quote="Od 3 lat ćwiczę na siłowni. Nie mogłem przytyć, a jestem wysoki. Miałem już dość jedzenia ryżu z kurczakiem na każdy posiłek, dlatego spróbowałem Diet Designer. Po tym jak wybrałem cel, zobaczyłem swój jadłospis. Po pierwsze był urozmaicony, ale tak optymalnie. Nie muszę szukać składników po 5 sklepach. Poza tym często korzysta z opcji wymiany produktów w danym posiłku. Po drugie okazało się, że jem za mało... stąd był mój problem z nabraniem masy mięśniowej. Teraz w końcu widzę efekty!"
		author="Bartek, 27 lat"
	/>,
	<CustomerOpinionsSliderItem
		title="Jem to co lubię."
		quote="Dla mnie HIT. Mogę jeść chleb, makaron oraz owoce na kolacje i chudnę! Do tej pory myślałam, że nie jedząc tych produktów zrzucę kilka kilogramów. Kończy łosię na braku pomysłów, frustracji i braku efektów... Lubię węgle, na szczęście okazało się, że mogę je jeść codziennie. Praktycznie w każdym posiłku! Koniec z niepotrzebnymi ograniczeniami!"
		author="Basia, 53 lata"
	/>,
	<CustomerOpinionsSliderItem
		title="Polecam wszystkim."
		quote="Chciałem diety, gdzie mogłem sobie wybrać co jem na dany posiłek. Jak zobaczyłem kreator diety wiedziałem, że coś może z tego być. W końcu jem to co chcę na dany posiłek i trzymam się planu. Wcześniej zbyt często zdarzało mi się odpuszczać... Na szczęście to już za mną."
		author="Krystian, 29 lat"
	/>
];

export const LandingPageCustomerOpinions: React.FC = () => {
	return (
		<div className="landing-page-customer-opinions">
			<div className="landing-page-customer-opinions__content-box">
				<div className="line-decor" />
				<Slider items={sliderItems} timer={14000} />
			</div>
		</div>
	);
};
