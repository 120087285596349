import { DayBox } from '../../../atoms/day-box/day-box';
import { Field } from 'formik';
import { IWizzardStepProps } from '../UserInterviewInterfaces';
import { RadioSelect } from '../../../atoms/RadioSelect/RadioSelect';
import { WizardCard } from '../../WizardCard/WizardCard';

import React, { useEffect, useRef } from 'react';

export const DietObjective02StepForm: React.FC<IWizzardStepProps> = ({
	formik,
	mode,
	scrollToStep,
	...props
}) => {
	const haveTrainingsList = useRef<HTMLHeadingElement>(null);

	useEffect(() => {
		if (formik.values.step2.haveTrainings === 'Yes') {
			haveTrainingsList?.current?.scrollIntoView();
		}
	}, [formik.values.step2.haveTrainings]);

	const handleScroll = () => {
		scrollToStep(2);
	};

	return (
		<WizardCard id="step02" header="2. Cel Diety" onClick={handleScroll}>
			<Field
				label="Chcę zredukować masę ciała"
				name="step2.dietObjective"
				value="R"
				checked={formik.values.step2.dietObjective === 'R'}
				as={RadioSelect}
			/>
			<Field
				label="Chcę szybko zredukować masę ciała"
				name="step2.dietObjective"
				value="FR"
				checked={formik.values.step2.dietObjective === 'FR'}
				as={RadioSelect}
			/>
			<Field
				label="Chcę utrzymać masę ciała"
				name="step2.dietObjective"
				value="B"
				checked={formik.values.step2.dietObjective === 'B'}
				as={RadioSelect}
			/>
			<Field
				label="Chcę zwiększyć masę ciała"
				name="step2.dietObjective"
				value="G"
				checked={formik.values.step2.dietObjective === 'G'}
				as={RadioSelect}
			/>
			<Field
				label="Chcę zwiększyć masę mięśniową"
				name="step2.dietObjective"
				value="GM"
				checked={formik.values.step2.dietObjective === 'GM'}
				as={RadioSelect}
			/>
			<span className="FormikError">
				{formik.touched.step2?.dietObjective && formik.errors.step2?.dietObjective}
			</span>
			<h4 className="UserInterview__subheader">Czy trenujesz?</h4>
			<div className="UserInterview_row UserInterview_row_columnMobile">
				<Field
					label="Tak, będę trenować"
					name="step2.haveTrainings"
					value="Yes"
					checked={formik.values.step2.haveTrainings === 'Yes'}
					as={RadioSelect}
				/>
				<Field
					label="Nie, nie będę trenować"
					name="step2.haveTrainings"
					value="No"
					checked={formik.values.step2.haveTrainings === 'No'}
					as={RadioSelect}
				/>
			</div>
			<span className="FormikError">
				{formik.touched.step2?.haveTrainings && formik.errors.step2?.haveTrainings}
			</span>
			{formik.values.step2.haveTrainings === 'Yes' ? (
				<>
					<h4 className="UserInterview__subheader" ref={haveTrainingsList}>
						W które dni zazwyczaj trenujesz?
					</h4>
					<Field
						displayName="Pon"
						name="step2.monday"
						setFieldValue={formik.setFieldValue}
						as={DayBox}
					/>
					<Field
						displayName="Wt"
						name="step2.tuesday"
						setFieldValue={formik.setFieldValue}
						as={DayBox}
					/>
					<Field
						displayName="Śr"
						name="step2.wednesday"
						setFieldValue={formik.setFieldValue}
						as={DayBox}
					/>
					<Field
						displayName="Czw"
						name="step2.thursday"
						setFieldValue={formik.setFieldValue}
						as={DayBox}
					/>
					<Field
						displayName="Pt"
						name="step2.friday"
						setFieldValue={formik.setFieldValue}
						as={DayBox}
					/>
					<Field
						displayName="Sob"
						name="step2.saturday"
						setFieldValue={formik.setFieldValue}
						as={DayBox}
					/>
					<Field
						displayName="Nd"
						name="step2.sunday"
						setFieldValue={formik.setFieldValue}
						as={DayBox}
					/>
					<span className="FormikError">{formik.errors.step2?.sunday}</span>
				</>
			) : (
				<></>
			)}
		</WizardCard>
	);
};
