import { IImageFile } from '../../shared/interfaces/IImageFile';
import { Restaurant } from '../../client';
import { RootState } from '../root-store';
import { createSelector } from 'reselect';

const rootStateSelector = (state: RootState) => state;

const getMarkersPlaceIdsSelector = createSelector(
	rootStateSelector,
	(state: RootState) => state.restaurants.markersPlaceIds
);

export const getRestaurantsSelector = createSelector(
	rootStateSelector,
	(state: RootState): Restaurant[] => state.restaurants.restaurants
);
export const getRestaurantSelector = createSelector(
	rootStateSelector,
	(state: RootState): Restaurant => state.restaurants.restaurant
);

export const getRestaurantImagesSelector = createSelector(
	rootStateSelector,
	(state: RootState): IImageFile[] => state.restaurants.restaurantImages
);
