import { ActionType } from 'typesafe-actions';
import { FileApi } from '../../files-client';
import { INewSubscriptionCreatedNavigationState } from '../router/navigation-states/new-subscription-created';
import { RestaurantStatusFilter } from '../../shared/interfaces/RestaurantStatusFilter';
import { RootState } from '../root-store';
import { ThunkAction } from 'redux-thunk';
import { User } from '../../auth-client';
import { customerPreferencesActions } from '../diet/diet-preferences-action';
import { documentsUrls } from '../../shared/shared';
import { getPartnershipDocumentByUrl } from '../../components/organisms/Partnership/PartnershipPanel/PartnershipPanelEpics';
import moment from 'moment-timezone';
import { partnershipRestaurantFetched } from '../Partnership/PartnershipActions';

import {
	AuxiliaryApi,
	Customer,
	CustomerApi,
	CustomerSettings,
	CustomerSubs,
	CustomerTrainingPreferences,
	DietObjective,
	Gender,
	PostCreateCustomerTrainingSettings,
	PutInfoAboutYourself,
	PutServingPreferences,
	TagsMealsForDietServings
} from '../../client';
import { GetApiConfig, GetFileConfig } from '../api-client-config';
import { dietActions, loadCustomerDietPlan } from '../diet/diet-action';
import {
	getPartnershipRestaurantDocuments,
	getPartnershipRestaurantImages,
	getPartnershipRestaurantMealsImages
} from '../../components/organisms/Partnership/PartnershipInitial/PartnershipInitialEpics';
import { navigationAction, routes } from '../router/path-definition';

export const customerSettingsActionNames = {
	LOAD_CUSTOMER_TRAINING_PREFERENCES: 'LOAD_CUSTOMER_TRAINING_PREFERENCES',
	LOAD_CUSTOMER_SUBSCRIPTIONS: 'LOAD_CUSTOMER_SUBSCRIPTIONS',
	LOAD_CUSTOMER: 'LOAD_CUSTOMER',
	LOAD_CUSTOMER_SETTINGS: 'LOAD_CUSTOMER_SETTINGS',
	LOAD_CUSTOMER_TAG_MEALS_SERVING_FOR_DIET_SETTINGS:
		'LOAD_CUSTOMER_TAG_MEALS_SERVING_FOR_DIET_SETTINGS',
	LOAD_CUSTOMER_TAGS_MEALS_SERVING_FOR_DIET_SETTINGS:
		'LOAD_CUSTOMER_TAGS_MEALS_SERVING_FOR_DIET_SETTINGS',
	UPDATE_CUSTOMER_TAG_MEALS_SERVING_FOR_DIET_SETTINGS:
		'UPDATE_CUSTOMER_TAG_MEALS_SERVING_FOR_DIET_SETTINGS',
	REMOVE_CUSTOMER_TAG_MEAL_FROM_DIET_SERVING_PREFERENCES:
		'REMOVE_CUSTOMER_TAG_MEAL_FROM_DIET_SERVING_PREFERENCES',
	SET_CUSTOMER_SETTINGS_GENDER: 'SET_CUSTOMER_SETTINGS_GENDER',
	SET_CUSTOMER_SETTINGS_AGE: 'SET_CUSTOMER_SETTINGS_AGE',
	SET_CUSTOMER_SETTINGS_WEIGHT: 'SET_CUSTOMER_SETTINGS_WEIGHT',
	SET_CUSTOMER_DIET_START_DATE: 'SET_CUSTOMER_DIET_START_DATE',
	SET_CUSTOMER_SETTINGS_HEIGHT: 'SET_CUSTOMER_SETTINGS_HEIGHT',
	SET_CUSTOMER_SETTINGS_DIET_OBJECTIVE: 'SET_CUSTOMER_SETTINGS_DIET_OBJECTIVE',
	SET_CUSTOMER_ID: 'SET_CUSTOMER_ID',
	LOAD_USER_SETTINGS: 'LOAD_USER_SETTINGS'
} as const;

export const customerSettingsActions = {
	loadCustomerTrainingPreferences: (trainingPreferences: CustomerTrainingPreferences) =>
		({
			type: customerSettingsActionNames.LOAD_CUSTOMER_TRAINING_PREFERENCES,
			payload: { trainingPreferences }
		} as const),
	loadSubscriptionData: (subscriptions: CustomerSubs[]) =>
		({
			type: customerSettingsActionNames.LOAD_CUSTOMER_SUBSCRIPTIONS,
			payload: { subscriptions }
		} as const),
	loadCustomerSettings: (settings: CustomerSettings) =>
		({
			type: customerSettingsActionNames.LOAD_CUSTOMER_SETTINGS,
			payload: { settings }
		} as const),
	loadCustomer: (customer: Customer) =>
		({
			type: customerSettingsActionNames.LOAD_CUSTOMER,
			payload: { customer }
		} as const),
	loadTagMealsForDietServings: (settings: TagsMealsForDietServings) =>
		({
			type: customerSettingsActionNames.LOAD_CUSTOMER_TAG_MEALS_SERVING_FOR_DIET_SETTINGS,
			payload: { settings }
		} as const),
	loadTagsMealsForDietServings: (settings: TagsMealsForDietServings[]) =>
		({
			type: customerSettingsActionNames.LOAD_CUSTOMER_TAGS_MEALS_SERVING_FOR_DIET_SETTINGS,
			payload: { settings }
		} as const),
	updateTagsMealsForDietServings: (tagId: number, settings?: TagsMealsForDietServings) =>
		({
			type: customerSettingsActionNames.UPDATE_CUSTOMER_TAG_MEALS_SERVING_FOR_DIET_SETTINGS,
			payload: { tagId, settings }
		} as const),
	removeTagMealsFromDietServingPreferrences: (tagId: number) =>
		({
			type: customerSettingsActionNames.REMOVE_CUSTOMER_TAG_MEAL_FROM_DIET_SERVING_PREFERENCES,
			payload: { tagId }
		} as const),
	setCustomerGender: (gender: Gender) =>
		({
			type: customerSettingsActionNames.SET_CUSTOMER_SETTINGS_GENDER,
			payload: { gender }
		} as const),
	setCustomerAge: (yearOfBirth: number) =>
		({
			type: customerSettingsActionNames.SET_CUSTOMER_SETTINGS_AGE,
			payload: { yearOfBirth }
		} as const),
	setCustomerWeight: (weight: number) =>
		({
			type: customerSettingsActionNames.SET_CUSTOMER_SETTINGS_WEIGHT,
			payload: { weight }
		} as const),
	setCustomerDietStartDay: (date: Date) =>
		({
			type: customerSettingsActionNames.SET_CUSTOMER_DIET_START_DATE,
			payload: { date }
		} as const),
	setCustomerHeight: (height: number) =>
		({
			type: customerSettingsActionNames.SET_CUSTOMER_SETTINGS_HEIGHT,
			payload: { height }
		} as const),
	setCustomerDietObjective: (objective: DietObjective) =>
		({
			type: customerSettingsActionNames.SET_CUSTOMER_SETTINGS_DIET_OBJECTIVE,
			payload: { objective }
		} as const),
	setCustomerId: (customerId: number) =>
		({
			type: customerSettingsActionNames.SET_CUSTOMER_ID,
			payload: { customerId }
		} as const),
	userFetched: (user: User) =>
		({
			type: customerSettingsActionNames.LOAD_USER_SETTINGS,
			payload: { user }
		} as const)
};

export type CustomerSettingsAction = ActionType<typeof customerSettingsActions>;

export const loadCustomerOrPartnership =
	(username: string): ThunkAction<any, RootState, any, any> =>
	(dispatch, getState) => {
		const api = new CustomerApi(GetApiConfig(getState()));
		const fileApi = new FileApi(GetFileConfig(getState()));
		const auxApi = new AuxiliaryApi(GetApiConfig(getState()));
		api.getCustomerId(username).then((response) => {
			if (response.data.id) {
				dispatch(customerSettingsActions.setCustomerId(response.data.id));
				api.getCustomerSubs(response.data.id).then((data) => {
					dispatch(
						customerSettingsActions.loadSubscriptionData(data.data.customerSubs || [])
					);
					dispatch(
						loadCustomerDietPlan(
							response!.data.id || 0,
							data.data.customerSubs![0].id || 0 //todo
						)
					);
				});
			} else {
				auxApi.getRestaurantByContext().then((x) => {
					//todo when will there be more types of partnership not only restaurant, do getPartnershipsByContext
					const restaurant = x.data.restaurant;
					if (restaurant) {
						if (restaurant.status === RestaurantStatusFilter.FormToVerify.status) {
							dispatch(getPartnershipDocumentByUrl(documentsUrls.partnership.wait));
						} else if (
							restaurant.status === RestaurantStatusFilter.DocumentsRejected.status
						) {
							dispatch(
								getPartnershipDocumentByUrl(
									documentsUrls.partnership.documentsRejected
								)
							);
						} else if (
							restaurant.status ===
								RestaurantStatusFilter.ChangesRejectedPublic.status ||
							restaurant.status ===
								RestaurantStatusFilter.ChangesRejectedUnpublic.status
						) {
							dispatch(
								getPartnershipDocumentByUrl(
									documentsUrls.partnership.detailsRejected
								)
							);
						} else if (
							restaurant.status === RestaurantStatusFilter.FormRejected.status
						) {
							dispatch(
								getPartnershipDocumentByUrl(documentsUrls.partnership.rejected)
							);
						} else if (
							restaurant.status === RestaurantStatusFilter.DocumentsToSign.status
						) {
							dispatch(
								getPartnershipDocumentByUrl(
									documentsUrls.partnership.documentsVerify
								)
							);
						} else if (
							restaurant.status === RestaurantStatusFilter.DocumentsToVerify.status
						) {
							dispatch(
								getPartnershipDocumentByUrl(documentsUrls.partnership.documentsWait)
							);
						} else if (
							restaurant.status ===
								RestaurantStatusFilter.ChangesToVerifyPublic.status ||
							restaurant.status ===
								RestaurantStatusFilter.ChangesToVerifyUnpublic.status
						) {
							dispatch(
								getPartnershipDocumentByUrl(documentsUrls.partnership.detailsWait)
							);
						}
						dispatch(partnershipRestaurantFetched(restaurant));
						dispatch(getPartnershipRestaurantDocuments());
						dispatch(getPartnershipRestaurantImages());
						dispatch(getPartnershipRestaurantMealsImages());
						dispatch(navigationAction.open(routes.partnership.panel.path));
					}
				});
			}
		});
	};

export const retrieveCustomerSettings =
	(settingsId: string): ThunkAction<any, RootState, any, any> =>
	(dispatch, getState) => {
		const state = getState();
		const api = new CustomerApi(GetApiConfig(state));
		api.getCustomerSettingsAndPreferences(settingsId).then((x) => {
			if (x.data.customerSettings)
				dispatch(customerSettingsActions.loadCustomerSettings(x.data.customerSettings));
			if (
				!(x.data.customerSettings == null || x.data.customerSettings == undefined) &&
				!(
					x.data.customerSettings.customerDietPrefence == null ||
					x.data.customerSettings.customerDietPrefence == undefined
				)
			) {
				const pref = x.data.customerSettings.customerDietPrefence;
				dispatch(customerPreferencesActions.loadPreferences(pref));
				const dietType = state.diet.dietTypes.find((x) => x.id === pref.dietTypeId);
				if (!(dietType == null || dietType == undefined)) {
					dispatch(dietActions.loadCustomerDietType(dietType));
				}
				if (
					!(
						x.data.customerSettings.customerTrainingPreferences == null ||
						x.data.customerSettings.customerTrainingPreferences == undefined
					)
				)
					dispatch(
						customerSettingsActions.loadCustomerTrainingPreferences(
							x.data.customerSettings.customerTrainingPreferences
						)
					);
			}
		});

		api.getTagsMealsForCustomer(settingsId).then((x) => {
			if (
				!(
					x.data.tagsMealsForDietServings == null ||
					x.data.tagsMealsForDietServings == undefined
				)
			)
				dispatch(
					customerSettingsActions.loadTagsMealsForDietServings(
						x.data.tagsMealsForDietServings
					)
				);
		});
	};

export const clientJourneyCreateSettings =
	(dietTypeId: number): ThunkAction<any, RootState, any, any> =>
	(dispatch, getState) => {
		const state = getState();
		const api = new CustomerApi(GetApiConfig(state));
		api.postCreateCustomerSettings({
			dietTypeId: dietTypeId,
			dietStartUtc: moment().add(1, 'day').startOf('day').toISOString(),
			timezone: moment.tz.guess()
		})
			.then((x) => {
				if (!(x.data.customerSettings === null || x.data.customerSettings === undefined)) {
					dispatch(customerSettingsActions.loadCustomerSettings(x.data.customerSettings));
				}

				if (
					!(x.data.customerSettings === null || x.data.customerSettings === undefined) &&
					!(
						x.data.customerSettings.customerDietPrefence === null ||
						x.data.customerSettings.customerDietPrefence === undefined
					)
				) {
					dispatch(
						customerPreferencesActions.loadPreferences(
							x.data.customerSettings.customerDietPrefence
						)
					);
				}
				if (
					!(x.data.customerSettings == null || x.data.customerSettings == undefined) &&
					!(
						x.data.customerSettings.customerDietPrefence == null ||
						x.data.customerSettings.customerDietPrefence == undefined
					) &&
					!(
						x.data.customerSettings.customerDietPrefence.dietType == null ||
						x.data.customerSettings.customerDietPrefence.dietType == undefined
					)
				)
					dispatch(
						dietActions.loadCustomerDietType(
							x.data.customerSettings.customerDietPrefence.dietType
						)
					);
				// dispatch(navigationAction.open(routes.customer.new.path));
			})
			.catch((c) => {});
	};

export const clientJourneyPutInfoAboutYourself =
	(info: PutInfoAboutYourself): ThunkAction<any, RootState, any, any> =>
	(dispatch, getState) => {
		const state = getState();
		const api = new CustomerApi(GetApiConfig(state));
		api.putInfoAboutYourself(
			state.administration.customerSettings.customerBasicInformation.settingsId || '',
			info
		)
			.then((x) => {
				// if (x.data.customerSettings)
				// 	dispatch(customerSettingsActions.loadCustomerSettings(x.data.customerSettings));
			})
			.catch((c) => {});
	};
export const clientJourneyPutDietObjective =
	(objective: DietObjective): ThunkAction<any, RootState, any, any> =>
	(dispatch, getState) => {
		const state = getState();
		const api = new CustomerApi(GetApiConfig(state));
		api.putDietObjective(
			state.administration.customerSettings.customerBasicInformation.settingsId || '',
			{
				objective
			}
		)
			.then((x) => {
				if (
					!(x.data == null || x.data == undefined) &&
					!(x.data.customerSettings == null || x.data.customerSettings == undefined) &&
					!(
						x.data.customerSettings.dietObjective == null ||
						x.data.customerSettings.dietObjective == undefined
					)
				)
					dispatch(
						customerSettingsActions.setCustomerDietObjective(
							x.data.customerSettings.dietObjective
						)
					);
			})
			.catch((c) => {});
	};

export const clientJourneyPutDietStartDayUtc =
	(date: Date): ThunkAction<any, RootState, any, any> =>
	(dispatch, getState) => {
		const state = getState();
		const api = new CustomerApi(GetApiConfig(state));
		api.putDietStartDayUtc(
			state.administration.customerSettings.customerBasicInformation.settingsId || '',
			{
				dietStartUtc: moment(date).toISOString()
			}
		).then((x) => {
			if (
				!(x.data == null || x.data == undefined) &&
				!(x.data.customerSettings == null || x.data.customerSettings == undefined) &&
				!(
					x.data.customerSettings.dietStartUtc == null ||
					x.data.customerSettings.dietStartUtc == undefined
				)
			)
				dispatch(
					customerSettingsActions.setCustomerDietStartDay(
						moment(x.data.customerSettings.dietStartUtc).toDate()
					)
				);
		});
	};

export const clientJourneyPutDietTypePreferences =
	(dietTypeId: number): ThunkAction<any, RootState, any, any> =>
	(dispatch, getState) => {
		const state = getState();
		const api = new CustomerApi(GetApiConfig(state));
		api.putSelectDietType(
			state.administration.customerSettings.customerBasicInformation.settingsId || '',
			{
				dietTypeId
			}
		)
			.then((x) => {
				if (!!x.data.customerDietPreference && !!x.data.customerDietPreference.dietType)
					dispatch(
						dietActions.loadCustomerDietType(x.data.customerDietPreference.dietType)
					);
				if (!!x.data.customerDietPreference)
					dispatch(
						customerPreferencesActions.loadPreferences(x.data.customerDietPreference)
					);
				// if (!!x.customerDietPreference) dispatch(loadDietDay(x.customerDietPreference.));
			})
			.catch((c) => {});
	};

export const clientJourneyDeleteTagsMealsFrDietServings =
	(tagId: number): ThunkAction<any, RootState, any, any> =>
	(dispatch, getState) => {
		const state = getState();
		const api = new CustomerApi(GetApiConfig(state));
		api.deleteRemoveTagsFromMealForCustomer(
			state.administration.customerSettings.customerBasicInformation.settingsId || '',
			tagId
		)
			.then((x) => {
				dispatch(customerSettingsActions.removeTagMealsFromDietServingPreferrences(tagId));
			})
			.catch((c) => {});
	};

export const clientJourneyPostUpdateTagMealTypesForDietServings =
	(
		mealTypeId: number,
		tagId: number,
		setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void
	): ThunkAction<any, RootState, any, any> =>
	(dispatch, getState) => {
		const state = getState();
		const api = new CustomerApi(GetApiConfig(state));
		api.postTagsMealsForDietServings(
			state.administration.customerSettings.customerBasicInformation.settingsId || '',
			{
				settingsId:
					state.administration.customerSettings.customerBasicInformation.settingsId,
				tagsIds: [tagId],
				mealTypeId: mealTypeId
			}
		)
			.then((x) => {
				dispatch(
					customerSettingsActions.updateTagsMealsForDietServings(
						tagId,
						x.data.tagsMealsForDietServings![0]
					)
				);
				setFieldValue('step4.tags', x.data.tagsMealsForDietServings ?? []);
			})
			.catch((c) => {});
	};
export const clientJourneyPostTagsMealsForDietServings =
	(
		tags: TagsMealsForDietServings[],
		setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void
	): ThunkAction<any, RootState, any, any> =>
	(dispatch, getState) => {
		const state = getState();
		const api = new CustomerApi(GetApiConfig(state));
		api.postTagsMealsForDietServings(
			state.administration.customerSettings.customerBasicInformation.settingsId || '',
			{
				settingsId:
					state.administration.customerSettings.customerBasicInformation.settingsId,
				tagsIds: tags.map((x) => x.tagId!)
			}
		)
			.then((x) => {
				if (!!x.data.tagsMealsForDietServings) {
					dispatch(
						customerSettingsActions.loadTagsMealsForDietServings(
							x.data.tagsMealsForDietServings
						)
					);
					setFieldValue('step4.tags', x.data.tagsMealsForDietServings ?? []);
				}
			})
			.catch((c) => {});
	};

export const newclientaction =
	(
		info: PutInfoAboutYourself,
		objective: DietObjective,
		pref: PostCreateCustomerTrainingSettings,
		wantExcludeProducts: boolean,
		servingPreferences: PutServingPreferences,
		setFieldValue?: (field: string, value: any, shouldValidate?: boolean | undefined) => void
	): ThunkAction<any, RootState, any, any> =>
	(dispatch, getState) => {
		const state = getState();
		const api = new CustomerApi(GetApiConfig(state));
		api.putInfoAboutYourself(
			state.administration.customerSettings.customerBasicInformation.settingsId || '',
			info
		)
			.then((x) => {
				api.putDietObjective(
					state.administration.customerSettings.customerBasicInformation.settingsId || '',
					{
						objective
					}
				)
					.then((x) => {
						if (
							!(x.data == null || x.data == undefined) &&
							!(
								x.data.customerSettings == null ||
								x.data.customerSettings == undefined
							) &&
							!(
								x.data.customerSettings.dietObjective == null ||
								x.data.customerSettings.dietObjective == undefined
							)
						)
							dispatch(
								customerSettingsActions.setCustomerDietObjective(
									x.data.customerSettings.dietObjective
								)
							);
					})
					.then((z) => {
						api.postCreateCustomerTrainingSettings(
							state.administration.customerSettings.customerBasicInformation
								.settingsId || '',
							pref
						)
							.then((x) => {
								if (
									!(
										x.data.customerTrainingPreferences == null ||
										x.data.customerTrainingPreferences == undefined
									)
								)
									dispatch(
										customerSettingsActions.loadCustomerTrainingPreferences(
											x.data.customerTrainingPreferences
										)
									);
							})
							.then((y) => {
								api.putExclusionsForDietPlan(
									state.administration.customerSettings.customerBasicInformation
										.settingsId || '',
									{
										wantExcludeProducts
									}
								)
									.then((x) => {
										if (wantExcludeProducts) {
											dispatch(
												customerPreferencesActions.confirmExclusion(
													wantExcludeProducts
												)
											);
										} else {
											dispatch(
												customerPreferencesActions.denyExclusion(
													wantExcludeProducts
												)
											);
										}
									})
									.then((zz) => {
										api.putServingPreferences(
											state.administration.customerSettings
												.customerBasicInformation.settingsId || '',
											servingPreferences
										)
											.then((x) => {
												if (
													!(
														x?.data?.customerDietPreference == null ||
														x?.data?.customerDietPreference == undefined
													)
												) {
													dispatch(
														customerPreferencesActions.loadPreferences(
															x.data.customerDietPreference
														)
													);
												}
												dispatch(
													customerSettingsActions.loadTagsMealsForDietServings(
														x.data.tagsMealsForDietServings || []
													)
												);
												if (setFieldValue)
													setFieldValue(
														'step4.tags',
														x.data.tagsMealsForDietServings ?? []
													);
											})
											.then((crete) => {
												api.postCreateDietPlan(
													state.administration.customerSettings
														.customerBasicInformation.settingsId || '',
													{
														settingsId:
															state.administration.customerSettings
																.customerBasicInformation
																.settingsId || ''
													}
												).then((x) => {
													dispatch(
														customerSettingsActions.setCustomerId(
															x.data.customerId!
														)
													);
													dispatch(
														navigationAction.open(
															routes.auth.register.path,
															{
																next: routes.home.path,
																settingsId:
																	state.administration
																		.customerSettings
																		.customerBasicInformation
																		.settingsId,
																newClient: true,
																customerId: x.data.customerId,
																customerDietPlanId:
																	x.data.dietPlanId
															} as INewSubscriptionCreatedNavigationState
														)
													);
												});
											});
									});
							});
					})
					.catch((c) => {});
			})
			.catch((c) => {});
	};
