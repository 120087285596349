import { ShoppingList } from '../../../../client';

export const ShoppingList_Saved = '@@Diet/ShoppingLists/SAVED';
export const ShoppingItem_Bought = '@@Diet/ShoppingLists/Product/BOUGHT';

export interface IShoppingListSavedAction {
	type: typeof ShoppingList_Saved;
	shoppingList: ShoppingList;
}

export const shoppingListSaved = (shopping: ShoppingList): IShoppingListSavedAction => ({
	type: ShoppingList_Saved,
	shoppingList: shopping
});

export interface IShoppingItemBoughtAction {
	type: typeof ShoppingItem_Bought;
	shoppingItemId: number;
}

export const shoppingItemBought = (shoppingItemId: number): IShoppingItemBoughtAction => ({
	type: ShoppingItem_Bought,
	shoppingItemId
});
