import * as React from 'react';
import * as Redux from 'redux';

import { List } from 'immutable';
import { RootState } from '../../../../business/root-store';
import { User } from '../../../../auth-client';
import { UserSettingsComponent } from './UserSettingsComponent';
import { connect } from 'react-redux';
import { loadCustomerOrPartnership } from '../../../../business/customer/customer-settings-actions';
import { push } from 'connected-react-router';

import { AnyAction, Dispatch } from 'redux';
import { CurrentOrder, Customer, CustomerSubs, Invoice, Order } from '../../../../client';
import {
	downloadInvoice,
	fetchCurrentOrder,
	fetchUserInvoices,
	fetchUserOrders,
	updateUserSettings
} from './UserSettingsEpics';
import {
	getCustomerId,
	getCustomerSelector
} from '../../../../business/customer/customer-settings-selector';

interface IUserSettingsContainerReduxMergedProps {
	userName: string;
	email: string;
	orders: List<Order>;
	invoices: List<Invoice>;
	currentOrder: CurrentOrder;
	customer?: Customer;
	customerId?: number | null;
}

interface IUserSettingsContainerOwnProps {}

interface IUserSettingsContainerDispatchProps {
	fetchInvoices: () => void;
	fetchCurrentOrder: () => void;
	fetchOrders: () => void;
	navigateTo: (path: string, state?: any) => void;
	onUpdateUserSettings: (settings: Customer) => void;
	onDownloadInvoice: (invoice: number, fileName: string) => void;
	loadCustomerIdAndActiveDietPlan: (username: string) => void;
}

interface IUserSettingsContainerProps
	extends IUserSettingsContainerOwnProps,
		IUserSettingsContainerDispatchProps,
		IUserSettingsContainerReduxMergedProps {}

interface IUserSettingsComponentState {
	customerId?: number | null;
}

class UserSettingsContainer extends React.Component<
	IUserSettingsContainerProps,
	IUserSettingsComponentState
> {
	constructor(props: IUserSettingsContainerProps, state: IUserSettingsComponentState) {
		super(props, state);

		this.state = this.getInitialState();
	}
	static getDerivedStateFromProps(
		nextProps: IUserSettingsContainerProps,
		prevState: IUserSettingsComponentState
	) {
		if (prevState.customerId === undefined && nextProps.customerId !== undefined) {
			nextProps.fetchInvoices();
			nextProps.fetchCurrentOrder();
			nextProps.fetchOrders();
			return { customerId: nextProps.customerId };
		}
		return null;
	}

	public componentDidMount(): void {
		this.props.fetchInvoices();
		this.props.fetchCurrentOrder();
		this.props.fetchOrders();
	}

	public render(): JSX.Element {
		return (
			<UserSettingsComponent
				email={this.props.email}
				invoices={this.props.invoices}
				currentOrder={this.props.currentOrder}
				orders={this.props.orders}
				navigateTo={this.props.navigateTo}
				customer={this.props.customer}
				saveUserSettings={this.props.onUpdateUserSettings}
				downloadInvoice={this.props.onDownloadInvoice}
				// key={this.props.customer.settingsId}
			/>
		);
	}

	public static mapStateToProps = (
		state: RootState,
		ownProps: IUserSettingsContainerOwnProps
	): IUserSettingsContainerReduxMergedProps => {
		const customer: CustomerSubs = UserSettingsContainer.getCustomer(state);
		const user: User = state.administration.customerSettings.user;

		return {
			userName: user ? user.name! : '',
			email: customer !== undefined ? customer.mail! : state.auth.username,
			invoices: state.administration.invoices.invoiceList,
			currentOrder: state.administration.invoices.currentOrder,
			orders: state.administration.invoices.ordersList,
			customer: getCustomerSelector(state),
			customerId: getCustomerId(state)
		};
	};

	public static mapDispatchToProps = (
		dispatch: Dispatch<AnyAction>
	): IUserSettingsContainerDispatchProps => {
		return {
			...Redux.bindActionCreators(
				{
					navigateTo: push,
					fetchInvoices: fetchUserInvoices,
					fetchCurrentOrder: fetchCurrentOrder,
					fetchOrders: fetchUserOrders,
					onUpdateUserSettings: updateUserSettings,
					onDownloadInvoice: downloadInvoice,
					loadCustomerIdAndActiveDietPlan: loadCustomerOrPartnership
				},
				dispatch
			)
		};
	};

	private getInitialState = (): IUserSettingsComponentState => {
		return {
			customerId: this.props.customerId
		} as IUserSettingsComponentState;
	};

	static getCustomer(state: RootState): CustomerSubs {
		if (!Array.isArray(state.administration.customerSettings.subscriptions)) return {};

		return state.administration.customerSettings.subscriptions!.filter(
			(s: CustomerSubs) =>
				new Date(s.startDate!).getTime() <= Date.now() &&
				new Date(s.endDate!).getTime() >= Date.now()
		)[0];
	}
}
export const UserSettings = connect(
	UserSettingsContainer.mapStateToProps,
	UserSettingsContainer.mapDispatchToProps
)(UserSettingsContainer);
