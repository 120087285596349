import '../nutrition-fact-summary.scss';

import ActionLink from '../../../atoms/action-link/action-link';
import { ChevronDownIcon } from '../../../../shared/assets/chevron-down-icon';
import { ChevronUpIcon } from '../../../../shared/assets/chevron-up-icon';
import { INutritionFactsSummary } from '../../../../shared/helpers';
import { MealTypeEnum } from '../../../../client';
import { NutritionFactRealSummary } from '../nutrition-fact-real-summary/nutrition-fact-real-summary';
import { primaryOrangeColor } from '../../../../shared/shared';
import { useOutsideClick } from '../../../../hooks/use-outside-click';
import { useSelector } from 'react-redux';

import React, { useRef, useState } from 'react';
import {
	getCustomerDietPlanDaySummary,
	getSummaryForDayViewEaten
} from '../../../../business/diet/diet-selector';

export interface INutritionFactDaySummaryComponentProps {
	summary: INutritionFactsSummary;
}

export const NutritionFactDaySummaryComponent: React.FC<INutritionFactDaySummaryComponentProps> = (
	props
) => {
	const [showAdditionalInfo, setShowAdditionalInfo] = useState(false);
	const summaryForDayViewEaten = useSelector(getSummaryForDayViewEaten);
	const customerDietPlanDaySummary = useSelector(getCustomerDietPlanDaySummary);
	const nutritionFactRealSummaryRef: any = useRef();

	useOutsideClick(nutritionFactRealSummaryRef, () => setShowAdditionalInfo(false));

	const getRoughage = (): React.ReactNode => {
		if (props.summary.roughage === null || props.summary.roughage === undefined) {
			return null;
		}
		return (
			<div className="summaryElementStyle">
				<h5 className="headerStyle">{props.summary.roughage} g</h5>
				<span className="typeStyle">Błonnik</span>
			</div>
		);
	};

	const getFattyAccid = (): React.ReactNode => {
		if (props.summary.fattyAcid === null || props.summary.fattyAcid === undefined) {
			return null;
		}
		return (
			<div className="summaryElementStyle">
				<h5 className="headerStyle">{props.summary.fattyAcid} g</h5>
				<span className="typeStyle">Błonnik</span>
			</div>
		);
	};

	const getSodium = (): React.ReactNode => {
		if (props.summary.sodium === null || props.summary.sodium === undefined) {
			return null;
		}
		return (
			<div className="summaryElementStyle">
				<h5 className="headerStyle">{props.summary.sodium} g</h5>
				<span className="typeStyle">Błonnik</span>
			</div>
		);
	};

	const changeAdditionalInfoState = () => {
		setShowAdditionalInfo(!showAdditionalInfo);
	};

	const isNutritionSummaryOverload = (nutritionItem: string): boolean => {
		// @ts-ignore
		return props.summary[nutritionItem] < summaryForDayViewEaten[nutritionItem];
	};

	const isNutritionSummaryMissing = (nutritionItem: string): boolean => {
		const mealsNotEaten = customerDietPlanDaySummary?.filter((item) => {
			return item.isMealEaten === false && item.mealType !== MealTypeEnum.Beverages;
		});
		return (
			mealsNotEaten?.length === 0 &&
			// @ts-ignore
			props!.summary[nutritionItem] > summaryForDayViewEaten[nutritionItem]
		);
	};

	const getDangerClass = (nutritionItem: string): string => {
		if (isNutritionSummaryOverload(nutritionItem) || isNutritionSummaryMissing(nutritionItem)) {
			return 'nutrition-fact-summary__item--danger';
		} else {
			return '';
		}
	};

	return (
		<div style={{ position: 'relative' }}>
			<div className="nutrition-fact-summary nutrition-fact-summary--rounded">
				<div
					className={`nutrition-fact-summary__item nutrition-fact-summary__item--active ${getDangerClass(
						'energy'
					)}`}
				>
					<div className="nutrition-fact-summary__item-value">
						{Math.floor(props?.summary.energy ?? 0)} kcal
					</div>
					<span className="nutrition-fact-summary__item-label">Energia</span>
				</div>
				<div className={`nutrition-fact-summary__item ${getDangerClass('proteins')}`}>
					<div className="nutrition-fact-summary__item-value">
						{Math.floor(props?.summary.proteins ?? 0)} g
					</div>
					<span className="nutrition-fact-summary__item-label">Białko</span>
				</div>
				<div className={`nutrition-fact-summary__item ${getDangerClass('fats')}`}>
					<div className="nutrition-fact-summary__item-value">
						{Math.floor(props?.summary.fats ?? 0)} g
					</div>
					<span className="nutrition-fact-summary__item-label">Tłuszcze</span>
				</div>
				<div className={`nutrition-fact-summary__item ${getDangerClass('carbs')}`}>
					<div className="nutrition-fact-summary__item-value">
						{Math.floor(props?.summary.carbs ?? 0)} g
					</div>
					<span className="nutrition-fact-summary__item-label">Węgle</span>
				</div>
				{getRoughage()}
				{getFattyAccid()}
				{getSodium()}
			</div>
			<div
				className={`nutrition-fact-summary__additional-info 
					${showAdditionalInfo ? 'nutrition-fact-summary__additional-info--visible' : ''}
				`}
				ref={nutritionFactRealSummaryRef}
			>
				<NutritionFactRealSummary
					daySummary={props.summary}
					realSummary={summaryForDayViewEaten}
				/>
			</div>
			<div className={`nutrition-fact-summary__expand-button`}>
				<ActionLink
					onClick={changeAdditionalInfoState}
					text="Zjedzone"
					textColor={primaryOrangeColor}
				>
					{!showAdditionalInfo && <ChevronDownIcon fillColor={primaryOrangeColor} />}
					{showAdditionalInfo && <ChevronUpIcon />}
				</ActionLink>
			</div>
		</div>
	);
};
