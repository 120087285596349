import './DropdownList.scss';

import { ISearchItem } from '../search-box/search-box';
import React from 'react';

export interface IDropdownListProps {
	label: string;
	className?: string;
	isDisabled?: boolean;
	isMultiSelect?: boolean;
	value?: ISearchItem;
	items?: ISearchItem[];
	onSelect?(item: ISearchItem): void;
}

export const DropdownList: React.FC<IDropdownListProps> = (props) => {
	const onChange = (event: React.FormEvent<HTMLSelectElement>) => {
		props.onSelect &&
			props.onSelect(props.items?.find((x) => x.display === event.currentTarget.value)!);
	};
	return (
		<div className={props.className ? 'DropdownList ' + props.className : 'DropdownList'}>
			<label className="textBox_label" htmlFor="dropdown">
				{props.label}
			</label>

			<select
				name="dropdown"
				id="dropdown"
				disabled={props.isDisabled}
				multiple={props.isMultiSelect}
				value={props.value?.display}
				onChange={onChange}
			>
				{props.items &&
					props.items.map((x: ISearchItem) => {
						return (
							<option key={x.id} value={x.display} disabled={x.disabled}>
								{x.display}
							</option>
						);
					})}
			</select>
		</div>
	);
};
