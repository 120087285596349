import { ConfirmationBox } from '../components/atoms/ConfirmationBox/ConfirmationBox';
import React from 'react';

export const useConfirmationBoxOptions = () => {
	return {
		render: (message: string, onConfirm: () => void, onCancel: () => void) => {
			return <ConfirmationBox message={message} onConfirm={onConfirm} onCancel={onCancel} />;
		}
	};
};
