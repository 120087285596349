import './ActionButton.scss';

import React, { ButtonHTMLAttributes } from 'react';

export interface IActionButtonProps {
	styleType?: 'primary' | 'secondary';
	text?: string;
	icon?: HTMLImageElement | JSX.Element;
	className?: string;
	errorString?: string;
}

export const ActionButton: React.FC<
	IActionButtonProps & ButtonHTMLAttributes<HTMLButtonElement>
> = ({ errorString, styleType, text, icon, className, ...props }) => {
	return (
		<div className={className ? 'ActionButton ' + className : 'ActionButton'}>
			<button
				{...props}
				className={
					'ActionButton_button ' +
					(className ? className + ' ' : '') +
					(props.disabled ? 'ActionButton_button_disabled ' : '') +
					(styleType ? 'ActionButton_button--' + styleType : '') +
					(icon && !text ? 'ActionButton_button--icon-only' : '') //todo remove
				}
			>
				{icon} {text}
			</button>
			{errorString && <span className="FormikError">{errorString}</span>}
		</div>
	);
};
