import React from 'react';

interface ISpinnerComponentProps {
	visible: boolean;
}

interface ISpinnerComponentState {}

export class SpinnerComponent extends React.Component<
	ISpinnerComponentProps,
	ISpinnerComponentState
> {
	constructor(props: ISpinnerComponentProps, state: ISpinnerComponentState) {
		super(props, state);

		this.state = this.getInitialState();
	}

	public render(): JSX.Element {
		if (this.props.visible) {
			return (
				<>
					<div className="spinner-container">
						<div className="spinner"></div>
					</div>
				</>
			);
		} else {
			return <></>;
		}
	}

	private getInitialState = (): ISpinnerComponentState => {
		return {} as ISpinnerComponentState;
	};
}
