import React from 'react';

interface ICalendarIconComponentProps {
	onClick?(event: React.SyntheticEvent<any> | undefined): any;
	className?: string;
	disabled?: boolean;
}

class CalendarIconComponent extends React.Component<ICalendarIconComponentProps> {
	constructor(props: ICalendarIconComponentProps) {
		super(props);
	}

	public render(): JSX.Element {
		return (
			<React.Fragment>
				<svg
					width="16"
					height="16"
					viewBox="0 0 16 16"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
					onClick={this.props.onClick}
					style={{ cursor: this.props.disabled ? 'auto' : 'pointer' }}
				>
					<path
						d="M14.333 2H12.4997C12.4555 2 12.4131 1.98244 12.3818 1.95118C12.3506 1.91993 12.333 1.87754 12.333 1.83333V0.666667C12.333 0.489856 12.2628 0.320286 12.1377 0.195262C12.0127 0.0702379 11.8432 0 11.6663 0C11.4895 0 11.32 0.0702379 11.1949 0.195262C11.0699 0.320286 10.9997 0.489856 10.9997 0.666667V3.83333C10.9997 3.96594 10.947 4.09312 10.8532 4.18689C10.7595 4.28065 10.6323 4.33333 10.4997 4.33333C10.3671 4.33333 10.2399 4.28065 10.1461 4.18689C10.0524 4.09312 9.99967 3.96594 9.99967 3.83333V2.33333C9.99967 2.24493 9.96456 2.16014 9.90204 2.09763C9.83953 2.03512 9.75475 2 9.66634 2H5.49967C5.45559 2 5.4133 1.98253 5.38206 1.95142C5.35082 1.92031 5.33318 1.87809 5.33301 1.834V0.666667C5.33301 0.489856 5.26277 0.320286 5.13775 0.195262C5.01272 0.0702379 4.84315 0 4.66634 0C4.48953 0 4.31996 0.0702379 4.19494 0.195262C4.06991 0.320286 3.99967 0.489856 3.99967 0.666667V3.83333C3.99967 3.96594 3.947 4.09312 3.85323 4.18689C3.75946 4.28065 3.63228 4.33333 3.49967 4.33333C3.36707 4.33333 3.23989 4.28065 3.14612 4.18689C3.05235 4.09312 2.99967 3.96594 2.99967 3.83333V2.33333C2.99967 2.24493 2.96456 2.16014 2.90204 2.09763C2.83953 2.03512 2.75475 2 2.66634 2H1.66634C1.31272 2 0.973581 2.14048 0.723532 2.39052C0.473484 2.64057 0.333008 2.97971 0.333008 3.33333V14.6667C0.333008 15.0203 0.473484 15.3594 0.723532 15.6095C0.973581 15.8595 1.31272 16 1.66634 16H14.333C14.6866 16 15.0258 15.8595 15.2758 15.6095C15.5259 15.3594 15.6663 15.0203 15.6663 14.6667V3.33333C15.6663 2.97971 15.5259 2.64057 15.2758 2.39052C15.0258 2.14048 14.6866 2 14.333 2ZM13.9997 14.6667H1.99967C1.91127 14.6667 1.82648 14.6315 1.76397 14.569C1.70146 14.5065 1.66634 14.4217 1.66634 14.3333V6.33333C1.66634 6.24493 1.70146 6.16014 1.76397 6.09763C1.82648 6.03512 1.91127 6 1.99967 6H13.9997C14.0881 6 14.1729 6.03512 14.2354 6.09763C14.2979 6.16014 14.333 6.24493 14.333 6.33333V14.3333C14.333 14.4217 14.2979 14.5065 14.2354 14.569C14.1729 14.6315 14.0881 14.6667 13.9997 14.6667Z"
						fill={this.props.disabled ? '#B0B4C4' : '#FD6851'}
					/>
				</svg>
			</React.Fragment>
		);
	}
}
export const CalendarIcon = CalendarIconComponent;
