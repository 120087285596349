import './RestaurantDetails.scss';

import React from 'react';
import { RestaurantDetailsForm } from './RestaurantDetailsForm/RestaurantDetailsForm';

interface IRestaurantDetailsComponentProps {
	dontRewriteToNewProperties?: boolean;
	disabled?: boolean;
}

interface IRestaurantDetailsComponentState {}

export class RestaurantDetailsComponent extends React.Component<
	IRestaurantDetailsComponentProps,
	IRestaurantDetailsComponentState
> {
	constructor(props: IRestaurantDetailsComponentProps, state: IRestaurantDetailsComponentState) {
		super(props, state);

		this.state = this.getInitialState();
	}

	public render(): JSX.Element {
		return (
			<div className="RestaurantDetails">
				<div className="RestaurantDetails_form">
					<div className="RestaurantDetails_form_content">
						<RestaurantDetailsForm
							disabled={this.props.disabled}
							dontRewriteToNewProperties={this.props.dontRewriteToNewProperties}
						/>
					</div>
				</div>
			</div>
		);
	}

	private getInitialState = (): IRestaurantDetailsComponentState => {
		return {} as IRestaurantDetailsComponentState;
	};
}
