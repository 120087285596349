import React from 'react';

interface ICheckIconComponentProps {
	onClick?(event: React.SyntheticEvent<any> | undefined): any;
	className?: string;
}

class CheckIconComponent extends React.Component<ICheckIconComponentProps> {
	constructor(props: ICheckIconComponentProps) {
		super(props);
	}
	public render(): JSX.Element {
		return (
			<svg
				className={this.props.className}
				onClick={this.props.onClick}
				xmlns="http://www.w3.org/2000/svg"
				width="16"
				height="15"
				viewBox="0 0 16 15"
				fill="none"
			>
				<path
					d="M15.5446 2.68001L14.0555 1.18668C14.0307 1.16185 14.0013 1.14214 13.9689 1.1287C13.9365 1.11526 13.9018 1.10834 13.8667 1.10834C13.8316 1.10834 13.7969 1.11526 13.7645 1.1287C13.7321 1.14214 13.7027 1.16185 13.6779 1.18668L5.78885 9.88001C5.76408 9.90485 5.73465 9.92455 5.70226 9.93799C5.66986 9.95144 5.63513 9.95836 5.60005 9.95836C5.56498 9.95836 5.53024 9.95144 5.49785 9.93799C5.46545 9.92455 5.43602 9.90485 5.41125 9.88001L2.32218 6.78668C2.29741 6.76185 2.26799 6.74214 2.23559 6.7287C2.20319 6.71526 2.16846 6.70834 2.13338 6.70834C2.09831 6.70834 2.06358 6.71526 2.03118 6.7287C1.99878 6.74214 1.96936 6.76185 1.94458 6.78668L0.455518 8.28001C0.405525 8.33002 0.377441 8.39784 0.377441 8.46855C0.377441 8.53926 0.405525 8.60707 0.455518 8.65708L5.41125 13.6133C5.43602 13.6382 5.46545 13.6579 5.49785 13.6713C5.53024 13.6848 5.56498 13.6917 5.60005 13.6917C5.63513 13.6917 5.66986 13.6848 5.70226 13.6713C5.73465 13.6579 5.76408 13.6382 5.78885 13.6133L15.5446 3.05335C15.5934 3.0035 15.6208 2.93648 15.6208 2.86668C15.6208 2.79688 15.5934 2.72986 15.5446 2.68001Z"
					fill="white"
				/>
			</svg>
		);
	}
}

export const CheckIcon = CheckIconComponent;
