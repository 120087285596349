import * as React from 'react';
import * as Redux from 'redux';

import { ICurrentMeals } from '../../MealCard/Meal/meal-interfaces';
import { RootState } from '../../../../business/root-store';
import { WaterBarComponent } from './water-bar-component';
import { addOrUpdateCustomerMeals } from '../../MealCard/CustomMeal/CustomMealEpics';
import { connect } from 'react-redux';
import { getCurrentMealsSelector } from '../../MealCard/Meal/meal-selectors';
import { push } from 'connected-react-router';
import { setCurrentMeals } from '../../MealCard/Meal/meal';

import { AnyAction, Dispatch } from 'redux';
import { DietPlanDayMeal, Meal, MealTypeEnum } from '../../../../client';
import { deleteDietPlanDayMeal, getBeverages } from '../beverages-epics';
import {
	getCurrentWaterSelector,
	getWaterMealSelector
} from '../BeverageEditor/BeveragesEditorSelectors';

interface IWaterBarContainerReduxMergedProps {
	waterMeal: Meal;
	currentWater?: DietPlanDayMeal;
	currentMeals: ICurrentMeals;
}

interface IWaterBarContainerOwnProps {}

interface IWaterBarContainerDispatchProps {
	addOrUpdateMeals: (meals: Meal[]) => void;
	fetchBeverages: () => void;
	setCurrentMeals(currentMeals: ICurrentMeals): void;
	deleteDpdm: (mealId: number, mealType: MealTypeEnum) => void;
}

interface IWaterBarContainerProps
	extends IWaterBarContainerOwnProps,
		IWaterBarContainerDispatchProps,
		IWaterBarContainerReduxMergedProps {}

interface IBeveragesComponentState {}

class WaterBarContainer extends React.PureComponent<
	IWaterBarContainerProps,
	IBeveragesComponentState
> {
	constructor(props: IWaterBarContainerProps, state: IBeveragesComponentState) {
		super(props, state);
		this.state = this.getInitialState();
		if (props.waterMeal === undefined) {
			props.fetchBeverages();
		}
	}

	public render(): JSX.Element {
		return (
			<WaterBarComponent
				currentWater={this.props.currentWater}
				currentMeals={this.props.currentMeals}
				addOrUpdateMeals={this.props.addOrUpdateMeals}
				waterMeal={this.props.waterMeal}
				setCurrentMeals={this.props.setCurrentMeals}
				deleteDpdm={this.props.deleteDpdm}
			/>
		);
	}

	public componentDidMount(): void {}

	public static mapStateToProps = (
		state: RootState,
		ownProps: IWaterBarContainerOwnProps
	): IWaterBarContainerReduxMergedProps => {
		return {
			waterMeal: getWaterMealSelector(state),
			currentWater: getCurrentWaterSelector(state),
			currentMeals: getCurrentMealsSelector(state)
		};
	};

	public static mapDispatchToProps = (
		dispatch: Dispatch<AnyAction>
	): IWaterBarContainerDispatchProps => {
		return {
			...Redux.bindActionCreators(
				{
					navigateTo: push,
					addOrUpdateMeals: addOrUpdateCustomerMeals,
					fetchBeverages: getBeverages,
					setCurrentMeals: setCurrentMeals,
					deleteDpdm: deleteDietPlanDayMeal
				},
				dispatch
			)
		};
	};

	private getInitialState = (): IBeveragesComponentState => {
		return {} as IBeveragesComponentState;
	};
}
export const WaterBar = connect(
	WaterBarContainer.mapStateToProps,
	WaterBarContainer.mapDispatchToProps
)(WaterBarContainer);
