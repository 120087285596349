import { List } from 'immutable';
import { Meal } from '../../../client';

export const Beverages_Fetched = '@@Diet/Beverages/FETCHED';

export interface IBeveragesFetchedAction {
	type: typeof Beverages_Fetched;
	beverages: List<Meal>;
}

export const beveragesFetched = (beverages: List<Meal>): IBeveragesFetchedAction => ({
	type: Beverages_Fetched,
	beverages: beverages
});
