import { ISearchItem } from '../../atoms/search-box/search-box';
import { RootState } from '../../../business/root-store';
import { createSelector } from 'reselect';
import { getavailableDietPlans as getAvailableDietPlansSelector } from '../../../business/diet/diet-selector';

import { DietPlanDefinition, DiscountCode, MealTypeEnum, ProductGroup } from '../../../client';

const rootStateSelector = (state: RootState) => state;

export const getUserMealTypesWithoutBeverages = createSelector(
	rootStateSelector,
	(state: RootState) => state.static.userMealTypes.filter((x) => x != MealTypeEnum.Beverages)
);

export const selectProductId = (state: RootState, productId: number) => productId;

export const getHmrsForProduct = createSelector(
	[rootStateSelector, selectProductId],
	(state: RootState, productId: number) =>
		state.static.hmrsPerProduct.find((x) => x.productId === productId)?.hmrs
);

export const getDocument = createSelector(
	rootStateSelector,
	(state: RootState) => state.static.document
);

export const getBlogDocumentsSelector = createSelector(
	rootStateSelector,
	(state: RootState) => state.static.blogDocuments
);

export const getDefaultDiscountCodesSelector = createSelector(
	rootStateSelector,
	(state: RootState) => state.static.defaultDiscountCodes
);

export const getNutritionFactDefinitionsSelector = createSelector(
	rootStateSelector,
	(state: RootState) => state.static.nutritionFactDefinitions
);

export const getProductGroupsSelector = createSelector(rootStateSelector, (state: RootState) => {
	const items = state.static.productGroups
		.filter((x) => x.parentId)
		.map((group: ProductGroup) => {
			return { id: group.id, display: group.name, value: group } as ISearchItem;
		});
	return items;
});

export interface DietPlanPrice {
	dietPlanDefinition: DietPlanDefinition;
	regularPricePerMonth: number;
	discountCode?: DiscountCode;
	discountCodePricePerMonth?: number;
	description?: string;
}

export const getDietPlanPrices = createSelector(
	[getDefaultDiscountCodesSelector, getAvailableDietPlansSelector],
	(defaultDiscountCodes: DiscountCode[], dietPlanDefinitions?: DietPlanDefinition[]) => {
		let dietPlanPrices: DietPlanPrice[] = [];
		if (dietPlanDefinitions?.length !== undefined && dietPlanDefinitions.length > 0) {
			dietPlanDefinitions?.map((dpd) => {
				let dietPlanPrice: DietPlanPrice = {
					regularPricePerMonth: (dpd.priceNet! / dpd.dietDays!) * 30,
					dietPlanDefinition: dpd
				};
				const discountCodes = defaultDiscountCodes.filter(
					(x) => x.dietPlanDefinitionId == dpd.id
				);
				if (discountCodes !== undefined && discountCodes.length > 0) {
					dietPlanPrice.discountCode = discountCodes.reduce(
						(r: DiscountCode, e: DiscountCode) => {
							return (r.priceAfterDiscount! /
								dietPlanPrice.dietPlanDefinition?.dietDays!) *
								30 <
								(e.priceAfterDiscount! /
									dietPlanPrice.dietPlanDefinition?.dietDays!) *
									30
								? r
								: e;
						}
					);
					dietPlanPrice.discountCodePricePerMonth =
						(dietPlanPrice.discountCode.priceAfterDiscount! /
							dietPlanPrice.dietPlanDefinition?.dietDays!) *
						30;
				}
				dietPlanPrices.push(dietPlanPrice);
			});
		}
		return dietPlanPrices.sort((a, b) => b.dietPlanDefinition.id! - a.dietPlanDefinition.id!);
	}
);

export const getCheapestDietPlanPrice = createSelector(
	getDietPlanPrices,
	(dietPlanPrices: DietPlanPrice[]): DietPlanPrice => {
		if (dietPlanPrices.length > 0) {
			return dietPlanPrices.reduce((prev: DietPlanPrice, curr: DietPlanPrice) => {
				const prevCheaper =
					prev.discountCodePricePerMonth !== undefined
						? prev.discountCodePricePerMonth < prev.regularPricePerMonth
							? prev.discountCodePricePerMonth
							: prev.regularPricePerMonth
						: prev.regularPricePerMonth;
				const currCheaper =
					curr.discountCodePricePerMonth !== undefined
						? curr.discountCodePricePerMonth < curr.regularPricePerMonth
							? curr.discountCodePricePerMonth
							: curr.regularPricePerMonth
						: curr.regularPricePerMonth;
				return prevCheaper < currCheaper ? prev : curr;
			});
		}
		return { dietPlanDefinition: {}, regularPricePerMonth: 0 } as DietPlanPrice;
	}
);
