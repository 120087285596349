import { List } from 'immutable';
import { Reducer } from 'redux';
import { WeekViewDay } from '../../../../client';
import { IMealsForWeekFetchedAction, mealsForWeek_Fetched } from './MealTableActions';

type ReducerActions = IMealsForWeekFetchedAction;

interface IMealTableReduxState {
	mealsForWeek: List<WeekViewDay>;
}

const INITIAL_STATE: IMealTableReduxState = {
	mealsForWeek: List<WeekViewDay>()
};

export const mealTableReducer: Reducer<IMealTableReduxState, ReducerActions> = (
	state = INITIAL_STATE,
	action
) => {
	switch (action.type) {
		case mealsForWeek_Fetched:
			return handleMealsForWeekFetched(state, action);
		default:
			return state;
	}
};

const handleMealsForWeekFetched = (
	state: IMealTableReduxState,
	action: IMealsForWeekFetchedAction
): IMealTableReduxState => {
	return {
		...state,
		mealsForWeek: List<WeekViewDay>(action.payload.mealsForWeek)
	};
};
