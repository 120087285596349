import * as React from 'react';
import * as Redux from 'redux';

import { GetHomeMeasurementRulesForProduct } from './IngredientEpics';
import { IngredientComponent } from './IngredientComponent';
import { List } from 'immutable';
import { RootState } from '../../../../../business/root-store';
import { connect } from 'react-redux';
import { getHmrsForProduct } from '../../../staticData/staticDataSelectors';

import { AnyAction, Dispatch } from 'redux';
import { HomeMeasurementRule, MealFoodProduct } from '../../../../../client';

interface IIngredientContainerReduxMergedProps {
	hmrs?: List<HomeMeasurementRule>;
}

interface IIngredientContainerOwnProps {
	mfp: MealFoodProduct;
	disableRemoveIcon?: boolean;
	disableBorder?: boolean;
	className?: string;
	handleIngredientUpdated(mfp: MealFoodProduct): void;
	handleIngredientDeleted?: () => void;
}

interface IIngredientContainerDispatchProps {
	GetHomeMeasurementRulesForProduct: (productId: number) => void;
}

interface IIngredientContainerProps
	extends IIngredientContainerOwnProps,
		IIngredientContainerDispatchProps,
		IIngredientContainerReduxMergedProps {}

interface IIngredientContainerState {}

class IngredientContainer extends React.Component<
	IIngredientContainerProps,
	IIngredientContainerState
> {
	constructor(props: IIngredientContainerProps, state: IIngredientContainerState) {
		super(props, state);

		this.state = this.getInitialState();
		if (!props.hmrs) {
			this.props.GetHomeMeasurementRulesForProduct(this.props.mfp.foodProductId!);
		}
	}

	public render(): JSX.Element {
		return (
			<IngredientComponent
				mfp={this.props.mfp}
				className={this.props.className}
				disableBorder={this.props.disableBorder}
				disableRemoveIcon={this.props.disableRemoveIcon}
				handleIngredientDeleted={this.props.handleIngredientDeleted}
				handleIngredientUpdated={this.props.handleIngredientUpdated}
				hmrs={this.props.hmrs}
				key={this.props.hmrs?.count()}
			/>
		);
	}

	public componentDidMount(): void {}

	public static mapStateToProps = (
		state: RootState,
		ownProps: IIngredientContainerOwnProps
	): IIngredientContainerReduxMergedProps => {
		return {
			hmrs: getHmrsForProduct(state, ownProps.mfp.foodProductId!)
		};
	};

	public static mapDispatchToProps = (
		dispatch: Dispatch<AnyAction>
	): IIngredientContainerDispatchProps => {
		return {
			...Redux.bindActionCreators(
				{ GetHomeMeasurementRulesForProduct: GetHomeMeasurementRulesForProduct },
				dispatch
			)
		};
	};

	private getInitialState = (): IIngredientContainerState => {
		return {} as IIngredientContainerState;
	};
}
export const Ingredient = connect(
	IngredientContainer.mapStateToProps,
	IngredientContainer.mapDispatchToProps
)(IngredientContainer);
