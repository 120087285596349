import React from 'react';

export const LiIcon: React.FC = (props) => {
	return (
		<React.Fragment>
			<svg
				width="6"
				height="6"
				viewBox="0 0 6 6"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<circle cx="3" cy="3" r="3" fill="url(#paint0_linear)" />
				<defs>
					<linearGradient
						id="paint0_linear"
						x1="6.57534"
						y1="-2.9473e-06"
						x2="-1.09914"
						y2="0.378465"
						gradientUnits="userSpaceOnUse"
					>
						<stop stopColor="#FF7E36" />
						<stop offset="1" stopColor="#FA3A68" stopOpacity="0.91" />
					</linearGradient>
				</defs>
			</svg>
		</React.Fragment>
	);
};
