import './diet-and-package-type.scss';

import { DietAndPackageTypeCardCopmponentProps } from './interfaces';
import { DietDefinition } from '../../../../client';
import { DietPlanPrice } from '../../../molecules/staticData/staticDataSelectors';
import { DietPlanPriceAtom } from '../../../atoms/DietPlanPrice/DietPlanPriceComponent';
import { OutlinedTale } from '../../../atoms/outlained-tale';
import { WizardCard } from '../../../molecules/WizardCard/WizardCard';
import { elementIds } from '../OrderMenuView.auxiliaries';
import { getDietPlanDescription } from './DietAndPackageTypeCard.auxiliaries';

export const DietAndPackageTypeCardComponent = ({
	dietTypes,
	dietPlanPrices,
	activeDietType,
	activeDietPlanPrice,
	setActiveDietType,
	setActiveDietPlan
}: DietAndPackageTypeCardCopmponentProps) => {
	return (
		<div id={elementIds.dietAndPackageTypeCard} className="animate__animated animate__fadeIn">
			<div className={`${elementIds.dietAndPackageTypeCard}-wrapper`}>
				<WizardCard id="dietype" header="Wybierz rodzaj diety i typ pakietu">
					<div className={`${elementIds.dietAndPackageTypeCard}-content`}>
						<div className="diet-types">
							{dietTypes.map((type: DietDefinition) => (
								<OutlinedTale
									onClick={setActiveDietType(type.id!)}
									isActive={type.id === activeDietType.id}
									key={type.id!}
								>
									<div
										className={`${elementIds.dietAndPackageTypeCard}__diet-type-wrapper`}
									>
										{type.name}
									</div>
								</OutlinedTale>
							))}
						</div>
						<div className="diet-plans">
							{dietPlanPrices?.map((dietPlanPrice: DietPlanPrice) => (
								<OutlinedTale
									onClick={setActiveDietPlan(
										dietPlanPrice.dietPlanDefinition.id!
									)}
									isActive={
										dietPlanPrice.dietPlanDefinition.id ===
										activeDietPlanPrice.dietPlanDefinition.id
									}
									key={'OutlineTable ' + dietPlanPrice.dietPlanDefinition.id!}
								>
									<div
										className={`${elementIds.dietAndPackageTypeCard}__diet-plan-wrapper`}
									>
										<DietPlanPriceAtom
											dietPlanPrice={dietPlanPrice}
											className="monthly-payment"
										/>
										<div className="plan-description">
											{getDietPlanDescription(dietPlanPrice)}
										</div>
									</div>
								</OutlinedTale>
							))}
						</div>
					</div>
				</WizardCard>
			</div>
		</div>
	);
};
