import { IStepIconProps } from '../../atoms/Stepper/StepIcon/StepIcon';
import { getIsUserRegisteredWhileOrdering } from '../../../business/order/order.selectors';
import { getUsername } from '../../../business/auth/auth-selector';
import { routes } from '../../../business/router/path-definition';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import {
	createOrderDietStepsList,
	elementIds,
	orderStepList,
	updateStep
} from './OrderMenuView.auxiliaries';
import { useEffect, useState } from 'react';

export const useDietPlanSteps = () => {
	const isUserLogged = useSelector(getUsername).length > 0;
	const isUserRegistered = useSelector(getIsUserRegisteredWhileOrdering);
	const location = useLocation();
	const [orderDietStepsList, setOrderDietStepsList] = useState<IStepIconProps[]>([]);

	useEffect(() => {
		const orderList = createOrderDietStepsList(isUserLogged, orderStepList);
		setOrderDietStepsList(orderList);
	}, []);

	useEffect(() => {
		if (orderDietStepsList.length) {
			let orderList = orderDietStepsList.slice();
			if (
				isUserLogged ||
				isUserRegistered ||
				location.pathname.includes(routes.customer.order.auth.path)
			) {
				orderList = updateStep(orderList, elementIds.loginCard, true);
			}
			if (
				!isUserLogged &&
				!isUserRegistered &&
				!location.pathname.includes(routes.customer.order.auth.path)
			) {
				orderList = updateStep(orderList, elementIds.loginCard, false);
			}

			if (location.pathname.includes(routes.customer.order.payment.main.path)) {
				orderList = updateStep(orderList, elementIds.paymentMethodCard, true);
			} else {
				orderList = updateStep(orderList, elementIds.paymentMethodCard, false);
			}
			setOrderDietStepsList(orderList);
		}
	}, [location.pathname, isUserLogged, isUserRegistered]);

	return {
		orderDietStepsList
	};
};
