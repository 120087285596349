import { INutritionFactsSummary } from '../../../shared/helpers';
import React from 'react';

export interface INutritionFactSummaryProps {
	summary: INutritionFactsSummary;
	className?: string;
}

export const NutritionFactSummary: React.FC<INutritionFactSummaryProps> = (props) => {
	const getRoughage = (): React.ReactNode => {
		if (props.summary.roughage === null || props.summary.roughage === undefined) {
			return null;
		}
		return (
			<div className="nutrition-fact-summary__item">
				<div className="nutrition-fact-summary__item-value">{props.summary.roughage} g</div>
				<span className="nutrition-fact-summary__item-label">Błonnik</span>
			</div>
		);
	};

	const getFattyAccid = (): React.ReactNode => {
		if (props.summary.fattyAcid === null || props.summary.fattyAcid === undefined) {
			return null;
		}
		return (
			<div className="nutrition-fact-summary__item">
				<div className="nutrition-fact-summary__item-value">
					{props.summary.fattyAcid} g
				</div>
				<span className="nutrition-fact-summary__item-label">Błonnik</span>
			</div>
		);
	};

	const getSodium = (): React.ReactNode => {
		if (props.summary.sodium === null || props.summary.sodium === undefined) {
			return null;
		}
		return (
			<div className="nutrition-fact-summary__item">
				<div className="nutrition-fact-summary__item-value">{props.summary.sodium} g</div>
				<span className="nutrition-fact-summary__item-label">Błonnik</span>
			</div>
		);
	};

	return (
		<div
			className={`nutrition-fact-summary nutrition-fact-summary--no-background ${props.className}`}
		>
			<div className="nutrition-fact-summary__item">
				<div className="nutrition-fact-summary__item-value">
					{Math.floor(props?.summary.proteins ?? 0)} g
				</div>
				<span className="nutrition-fact-summary__item-label">Białko</span>
			</div>
			<div className="nutrition-fact-summary__item">
				<div className="nutrition-fact-summary__item-value">
					{Math.floor(props?.summary.fats ?? 0)} g
				</div>
				<span className="nutrition-fact-summary__item-label">Tłuszcze</span>
			</div>
			<div className="nutrition-fact-summary__item">
				<div className="nutrition-fact-summary__item-value">
					{Math.floor(props?.summary.carbs ?? 0)} g
				</div>
				<span className="nutrition-fact-summary__item-label">Węgle</span>
			</div>
			{getRoughage()}
			{getFattyAccid()}
			{getSodium()}
			<div
				className={`nutrition-fact-summary__item nutrition-fact-summary__item--active-grey`}
			>
				<div className="nutrition-fact-summary__item-value">
					{Math.floor(props?.summary.energy ?? 0)} kcal
				</div>
				<span className="nutrition-fact-summary__item-label">Energia</span>
			</div>
		</div>
	);
};
