import React from 'react';
import { useDefaultId } from '../../../hooks/use-default-id';

export interface ISlideProps {
	Title?: string;
	Active?: boolean;
	id?: string;
}

export const Slide: React.FC<ISlideProps> = (props) => {
	const { children } = props;

	return <div id={useDefaultId(props.id)}>{children}</div>;
};
