import { IImageFile } from '../../shared/interfaces/IImageFile';
import { Restaurant } from '../../client';

export const MarkersPlaceIds_Set = '@@Diet/Restaurants/MarkersPlaceIds/SET';
export const Restaurants_Fetched = '@@Diet/Restaurants/Restaurants/FETCHED';
export const Restaurant_Fetched = '@@Diet/Restaurants/Restaurant/FETCHED';
export const RestaurantImages_Fetched = '@@Diet/Restaurants/RestaurantImages/FETCHED';
export interface IMarkersPlaceIdsSet {
	type: typeof MarkersPlaceIds_Set;
	markersPlaceIds: string[];
}

export const setMarkersPlaceIds = (markersPlaceIds: string[]): IMarkersPlaceIdsSet => ({
	type: MarkersPlaceIds_Set,
	markersPlaceIds: markersPlaceIds
});

export interface IRestaurantsFetchedAction {
	type: typeof Restaurants_Fetched;
	restaurants: Restaurant[];
}

export const restaurantsFetched = (restaurants: Restaurant[]): IRestaurantsFetchedAction => ({
	type: Restaurants_Fetched,
	restaurants: restaurants
});

export interface IRestaurantFetchedAction {
	type: typeof Restaurant_Fetched;
	restaurant: Restaurant;
}

export const restaurantFetched = (restaurant: Restaurant): IRestaurantFetchedAction => ({
	type: Restaurant_Fetched,
	restaurant: restaurant
});

export interface IRestaurantImagesFetchedAction {
	type: typeof RestaurantImages_Fetched;
	images: IImageFile;
}

export const restaurantImagesFetched = (images: IImageFile): IRestaurantImagesFetchedAction => ({
	type: RestaurantImages_Fetched,
	images: images
});
