import * as React from 'react';
import * as Redux from 'redux';

import { RootState } from '../../../../business/root-store';
import { ShoppingList } from '../../../../client';
import { ShoppingListComponent } from './shopping-list-component';
import { connect } from 'react-redux';
import { getShoppingListSelector } from './shopping-list-selectors';
import moment from 'moment';

import { AnyAction, Dispatch } from 'redux';
import { fetchShoppingList, postShoppingItem as shoppingItemBought } from './shopping-list-epics';

interface IShoppingListContainerReduxMergedProps {
	shoppingList?: ShoppingList;
}

interface IShoppingListContainerOwnProps {}

interface IShoppingListContainerDispatchProps {
	onItemBought: (id: number) => void;
	getShoppingList: () => void;
}

interface IShoppingListContainerProps
	extends IShoppingListContainerOwnProps,
		IShoppingListContainerDispatchProps,
		IShoppingListContainerReduxMergedProps {}

interface IShoppingListComponentState {}

class ShoppingListContainer extends React.Component<
	IShoppingListContainerProps,
	IShoppingListComponentState
> {
	constructor(props: IShoppingListContainerProps, state: IShoppingListComponentState) {
		super(props, state);

		this.state = this.getInitialState();
	}

	componentDidMount() {
		if (this.props.shoppingList === undefined) {
			this.props.getShoppingList();
		}
	}

	public render(): JSX.Element {
		return (
			<ShoppingListComponent
				shoppingList={this.props.shoppingList}
				onItemBought={this.props.onItemBought}
				key={
					moment(this.props.shoppingList?.startDate).unix() +
					moment(this.props.shoppingList?.endDate).unix()
				}
			/>
		);
	}

	public static mapStateToProps = (
		state: RootState,
		ownProps: IShoppingListContainerOwnProps
	): IShoppingListContainerReduxMergedProps => {
		return {
			shoppingList: getShoppingListSelector(state)
		};
	};

	public static mapDispatchToProps = (
		dispatch: Dispatch<AnyAction>
	): IShoppingListContainerDispatchProps => {
		return {
			...Redux.bindActionCreators(
				{
					onItemBought: shoppingItemBought,
					getShoppingList: fetchShoppingList
				},
				dispatch
			)
		};
	};

	private getInitialState = (): IShoppingListComponentState => {
		return {} as IShoppingListComponentState;
	};
}
export const ShoppingListView = connect(
	ShoppingListContainer.mapStateToProps,
	ShoppingListContainer.mapDispatchToProps
)(ShoppingListContainer);
