import './beverage-item.scss';

import React from 'react';
import { RemoveIcon } from '../../../../shared/assets/remove-icon';
import { confirm } from 'react-confirm-box';
import { useConfirmationBoxOptions } from '../../../../hooks/useConfirmationBoxOptions';

import { DietPlanDayMeal, MealTypeEnum } from '../../../../client';

interface IBeverageItemComponentProps {
	dietPlanDayMeal: DietPlanDayMeal;
	deleteBeverage: (mealId: number, mealType: MealTypeEnum) => void;
}

interface IBeverageItemComponentState {}

export class BeverageItemComponent extends React.Component<
	IBeverageItemComponentProps,
	IBeverageItemComponentState
> {
	constructor(props: IBeverageItemComponentProps, state: IBeverageItemComponentState) {
		super(props, state);

		this.state = this.getInitialState();
	}

	public render(): JSX.Element {
		return (
			<div className="beverage-item">
				<div className="detailsStyle">
					<span className="beverage-item__header">
						{this.props.dietPlanDayMeal.meal?.name}
					</span>
					<span>{this.props.dietPlanDayMeal.qtyInGrams}g</span>
					<span className="beverage-item__calories-wrapper">
						{this.props.dietPlanDayMeal.energy}kcal
					</span>
				</div>
				<div className="beverage-item__icon-wrapper">
					<RemoveIcon className="beverage-item__icon" onClick={this.handleRemove} />
				</div>
			</div>
		);
	}

	private handleRemove = async (): Promise<void> => {
		const options: any = useConfirmationBoxOptions();
		const result = await confirm('Czy na pewno chcesz usunąć ten napój?', options);
		if (result) {
			this.props.deleteBeverage(
				this.props.dietPlanDayMeal.mealId!,
				this.props.dietPlanDayMeal.mealType!
			);
		}
	};

	private getInitialState = (): IBeverageItemComponentState => {
		return {} as IBeverageItemComponentState;
	};
}
