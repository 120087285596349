import { GetApiConfig } from '../../../../business/api-client-config';
import { PaymentApi } from '../../../../client';
import { RootState } from '../../../../business/root-store';
import { ThunkAction } from 'redux-thunk';
import { defaultDiscountCodesFetched } from '../../staticData/staticDataActions';

export const fetchDefaultDiscountCodes =
	(): ThunkAction<any, RootState, any, any> => (dispatch, getState) => {
		const state = getState();
		const api = new PaymentApi(GetApiConfig(state));
		api.getDefaultDiscountCodes().then((x) => {
			if (x.data.discountCodes) {
				dispatch(defaultDiscountCodesFetched(x.data.discountCodes));
			}
		});
	};
