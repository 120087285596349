import './RestaurantPartner.scss';

import { ActionButton } from '../../../atoms/ActionButton/ActionButton';
import React from 'react';

import { Document, Restaurant } from '../../../../client';

interface IRestaurantPartnerComponentProps {
	restaurant: Restaurant;
	document: Document;
}

interface IRestaurantPartnerComponentState {}

export class RestaurantPartnerComponent extends React.Component<
	IRestaurantPartnerComponentProps,
	IRestaurantPartnerComponentState
> {
	constructor(props: IRestaurantPartnerComponentProps, state: IRestaurantPartnerComponentState) {
		super(props, state);

		this.state = this.getInitialState();
	}

	public render(): JSX.Element {
		return (
			<div className="RestaurantPartner">
				<div
					dangerouslySetInnerHTML={{ __html: this.props.document.documentData ?? '' }}
				></div>
				<div className="RestaurantPartner_ActionButton">
					<ActionButton text="Odbieram kupon rabatowy" />
				</div>
			</div>
		);
	}

	private getInitialState = (): IRestaurantPartnerComponentState => {
		return {} as IRestaurantPartnerComponentState;
	};
}
