import './RestaurantMeals.scss';

import { ActionButton } from '../../../atoms/ActionButton/ActionButton';
import ActionLink from '../../../atoms/action-link/action-link';
import { CheckIcon } from '../../../../shared/assets/check-icon';
import { DropdownList } from '../../../atoms/DropdownList/DropdownList';
import FlipMove from 'react-flip-move';
import { ICurrentMeals } from '../../MealCard/Meal/meal-interfaces';
import { IImageFile } from '../../../../shared/interfaces/IImageFile';
import { Link } from 'react-router-dom';
import { List } from 'immutable';
import React from 'react';
import { getFriendlyMealName } from '../../../../shared/helpers';
import { getMealImage } from '../../MealCard/meal-card-helper';
import { primaryOrangeColor } from '../../../../shared/shared';

import { ISearchItem, SearchBox } from '../../../atoms/search-box/search-box';
import { Meal, MealTypeEnum, Restaurant } from '../../../../client';

interface IRestaurantMealsComponentProps {
	restaurant?: Restaurant;
	placeId?: number;
	userMealTypes: List<MealTypeEnum>;
	currentMeals: ICurrentMeals;
	addOrUpdateCustomerMeals: (meals: Meal[], mealType?: MealTypeEnum) => void;
	navigateTo: (path: string, state?: any) => void;
	isSignedIn: boolean;
	restaurantImages: IImageFile[];
}

interface IRestaurantMealsComponentState {
	mealList: MealInList[];
	mealType: MealTypeEnum;
	searchText: string;
}

interface MealInList extends Meal {
	isSelected?: boolean;
}

export class RestaurantMealsComponent extends React.Component<
	IRestaurantMealsComponentProps,
	IRestaurantMealsComponentState
> {
	constructor(props: IRestaurantMealsComponentProps) {
		super(props);
		this.state = this.getInitialState();
	}

	handleMailClick = (e: any) => {
		// if (navigator.userAgent.match(/Android/i)) {
		// 	window.open(
		// 		"sms://1900/?body=encodeURIComponent('Cześć! Korzystam z aplikacji DietDesigner i ucieszyłbym się gdyby Wasze posiłki były dostępne w aplikacji. Więcej szczegółów tutaj: www.dietdesigner.app/wspolpraca'),'_blank"
		// 	);
		// }
		// if (navigator.userAgent.match(/iPhone/i)) {
		// 	window.open(
		// 		"sms://1900/&body=encodeURIComponent('Cześć! Korzystam z aplikacji DietDesigner i ucieszyłbym się gdyby Wasze posiłki były dostępne w aplikacji. Więcej szczegółów tutaj: www.dietdesigner.app/wspolpraca'),'_blank"
		// 	);
		// }
		// e.preventDefault();
	};

	handleMoreInfoClick = () => {
		document.dispatchEvent(new CustomEvent('closeModal', {}));
		this.props.navigateTo('restaurant/' + this.props.restaurant?.url);
	};

	public render(): JSX.Element {
		const items = this.props.userMealTypes
			.map((x) => {
				return { id: +x, display: getFriendlyMealName(x), value: x } as ISearchItem;
			})
			.toArray();
		let meals;
		if (this.props.restaurant?.meals && this.props.restaurant?.meals.length > 0) {
			meals = this.props.restaurant?.meals.map((x) => {
				return { id: x.id, display: x.name, value: x.name } as ISearchItem;
			});
		}
		const wordsArray = [
			this.props.restaurant?.postcode,
			this.props.restaurant?.city,
			this.props.restaurant?.street,
			this.props.restaurant?.homeNumber,
			this.props.restaurant?.apartmentNumber
		];
		const fullAddress = wordsArray.join(' ');
		return (
			<div className="page RestaurantMeals_main">
				<div className="RestaurantMeals_header">
					<p className="header">Posiłki w restauracji {this.props.restaurant?.name}</p>
				</div>
				{this.props.restaurant ? (
					<>
						<div className="RestaurantMeals_section">
							<img
								className="RestaurantMeals_logo"
								src={
									process.env.REACT_APP_FILES_URL +
									'/api/' +
									this.props.restaurant.logoUrl!
								}
							/>
							<div className="RestaurantMeals_info">
								<span className="RestaurantMeals_info_name">
									{this.props.restaurant?.name}
								</span>
								<span>{fullAddress}</span>
								{this.props.restaurant.url && (
									<ActionLink
										textColor={primaryOrangeColor}
										text="Więcej informacji o restauracji"
										onClick={this.handleMoreInfoClick}
										disableLeftMargin
									/>
								)}
							</div>
						</div>
						{this.props.userMealTypes.size > 0 && (
							<DropdownList
								className="RestaurantMeals_dropdown"
								label="Dodaj do.."
								value={items.find((x) => x.id === +this.state.mealType)}
								items={items}
								onSelect={this.handleSelect}
							/>
						)}
						<SearchBox
							data={meals}
							activateOnFocus
							placeholder="Wyszukaj posiłek"
							disableSuggestions
							onSearchChanged={this.onSearchTextChanged}
							value={this.state.searchText}
						/>
						<div className="RestaurantMeals_MealsContainer">
							<FlipMove>
								{this.state.mealList
									.filter((x) =>
										x.isSelected
											? true
											: new RegExp(
													this.state.searchText.toLocaleLowerCase()
											  ).test(x.name!.toLocaleLowerCase())
									)
									.sort(function (x, y) {
										return x.isSelected === y.isSelected
											? 0
											: x.isSelected
											? -1
											: 1;
									})
									.map((meal: MealInList) => {
										const image = getMealImage(
											this.props.restaurantImages,
											meal.imagesCatalog!
										);
										return (
											<div
												key={meal.id}
												className="RestaurantMeals_MealsContainer_Item"
												onClick={() => this.handleMealSelected(meal.id!)}
											>
												<div className="RestaurantMeals_MealsContainer_Item_Left">
													<img src={image} />
													<span>{meal.name}</span>
												</div>

												<div className="RestaurantMeals_MealsContainer_Item_Right">
													{meal.energy?.toFixed(0)} kcal/porcja
													{this.props.userMealTypes.size > 0 && (
														<div
															className={
																meal.isSelected
																	? 'RestaurantMeals_MealsContainer_Item_Right_selectedIcon RestaurantMeals_MealsContainer_Item_Right_selectedIcon_selected'
																	: 'RestaurantMeals_MealsContainer_Item_Right_selectedIcon'
															}
														>
															<CheckIcon />
														</div>
													)}
												</div>
											</div>
										);
									})}
							</FlipMove>
						</div>
						{this.props.userMealTypes.size > 0 && (
							<ActionButton
								text={
									this.state.mealList.map((x) => x.isSelected).length === 1
										? 'Dodaj posiłek'
										: 'Dodaj posiłki'
								}
								disabled={!this.state.mealList.some((meal) => meal.isSelected)}
								className={'RestaurantMeals_action-button'}
								onClick={this.handleOnSaveClicked}
							/>
						)}
					</>
				) : (
					<div className="RestaurantMeals_invite">
						<span>Niestety ta restauracja z nami nie współpracuje</span>
						{(navigator.userAgent.match(/Android/i) ||
							navigator.userAgent.match(/iPhone/i)) && (
							<Link className="ActionButton" to="#" onClick={this.handleMailClick}>
								Zaproś restaurację :)
							</Link>
						)}
					</div>
				)}
			</div>
		);
	}

	private handleSelect = (item: ISearchItem): void => {
		this.setState({ ...this.state, mealType: item.value });
	};

	private handleMealSelected = (mealId: number): void => {
		this.setState({
			...this.state,
			mealList: this.state.mealList.map((meal) => {
				if (meal.id !== mealId) return meal;
				meal.isSelected = !meal.isSelected;
				return meal;
			})
		});
	};

	private onSearchTextChanged = (text: string) => {
		this.setState({ ...this.state, searchText: text });
	};

	private handleOnSaveClicked = () => {
		this.props.addOrUpdateCustomerMeals(
			this.state.mealList.filter((x) => x.isSelected),
			this.state.mealType
		);
		document.dispatchEvent(new CustomEvent('closeModal', {}));
	};

	private getInitialState = (): IRestaurantMealsComponentState => {
		// let mealType = MealTypeEnum.Lunch;
		// if (this.props.currentMeals.mealType !== MealTypeEnum.Beverages) {
		// 	mealType = this.props.currentMeals.mealType;
		// }
		return {
			mealList: this.props.restaurant?.meals ?? [],
			mealType: this.props.currentMeals.mealType,
			searchText: ''
		} as IRestaurantMealsComponentState;
	};
}
