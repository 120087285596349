import { AuthTypeKey } from '../../../business/router/interfaces';

const title: Record<AuthTypeKey, string> = {
	'change-password': 'Zmiana hasła',
	reset: 'Reset hasła',
	'reset-password': 'Reset hasła',
	activatemail: 'Aktywacja',
	login: 'Logowanie',
	register: 'Rejestracja',
	activateaccount: 'Ustal swoje hasło'
};

export const assingFormTitle = (authType: AuthTypeKey): string => {
	return title[authType];
};
