import './water-bar.scss';

import { DecrementWaterIcon } from '../../../../shared/assets/decrementWater-icon';
import { ICurrentMeals } from '../../MealCard/Meal/meal-interfaces';
import { IncrementWaterIcon } from '../../../../shared/assets/incrementWater-icon';
import React from 'react';

import { DietPlanDayMeal, Meal, MealTypeEnum } from '../../../../client';

interface IWaterBarComponentProps {
	currentWater?: DietPlanDayMeal;
	waterMeal: Meal;
	currentMeals: ICurrentMeals;

	addOrUpdateMeals: (meals: Meal[]) => void;
	setCurrentMeals(currentMeals: ICurrentMeals): void;
	deleteDpdm: (mealId: number, mealType: MealTypeEnum) => void;
}

interface IWaterBarComponentState {}

export class WaterBarComponent extends React.Component<
	IWaterBarComponentProps,
	IWaterBarComponentState
> {
	constructor(props: IWaterBarComponentProps, state: IWaterBarComponentState) {
		super(props, state);

		this.state = this.getInitialState();
	}

	public render(): JSX.Element {
		return (
			<div className="water-bar">
				<div
					className="water-bar__button"
					onClick={() => this.incrementOrDecrementWater(true)}
				>
					<DecrementWaterIcon />
				</div>
				<div className="water-bar__value">
					<div className="water-bar__value-number">
						{Math.round((this.props.currentWater?.qtyInGrams ?? 0) / 250)}
					</div>
					<div className="water-bar__value-label">
						{this.props.currentWater?.qtyInText
							? this.props.currentWater?.qtyInText
							: 'szklanek'}{' '}
						{' wody z minimum 8'}
					</div>
				</div>
				<div
					className="water-bar__button"
					onClick={() => this.incrementOrDecrementWater(false)}
				>
					<IncrementWaterIcon />
				</div>
			</div>
		);
	}

	private incrementOrDecrementWater = (decrement?: boolean) => {
		const currentWater = this.props.currentWater;
		let waterMeal = this.props.waterMeal;
		if (currentWater !== undefined && (currentWater?.qtyInGrams ?? 0) > 250 && decrement) {
			waterMeal = {
				...waterMeal,
				mealFoodProducts: [
					{
						...waterMeal.mealFoodProducts![0],
						qtyInGrams: currentWater.qtyInGrams! - 250
					}
				]
			};
		} else if (currentWater !== undefined && decrement) {
			this.props.deleteDpdm(currentWater.mealId!, this.props.currentMeals.mealType);
			return;
		} else if (currentWater !== undefined && !decrement) {
			waterMeal = {
				...waterMeal,
				mealFoodProducts: [
					{
						...waterMeal.mealFoodProducts![0],
						qtyInGrams: (currentWater.qtyInGrams ?? 0) + 250
					}
				]
			};
		} else if (currentWater === undefined && decrement) {
			return;
		}

		this.props.setCurrentMeals({
			...this.props.currentMeals,
			mealType: MealTypeEnum.Beverages
		});

		this.props.addOrUpdateMeals(waterMeal ? [waterMeal] : []);
	};

	private getInitialState = (): IWaterBarComponentState => {
		return {} as IWaterBarComponentState;
	};
}
