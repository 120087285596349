import './Document.scss';

import { Document } from '../../../client';
import React from 'react';
import { ScrollProgressBar } from '../../atoms/ScrollProgressBar/ScrollProgressBar';

interface IDocumentComponentProps {
	document: Document;
}

interface IDocumentComponentState {
	showScrollBar: boolean;
}

export class DocumentComponent extends React.Component<
	IDocumentComponentProps,
	IDocumentComponentState
> {
	constructor(props: IDocumentComponentProps, state: IDocumentComponentState) {
		super(props, state);

		this.state = this.getInitialState();
	}
	componentDidMount() {
		// function onNextFrame(callback: any) {
		// 	setTimeout(function () {
		// 		requestAnimationFrame(callback);
		// 	});
		// }
		// const dupa = () => {
		// 	this.setState({
		// 		showScrollBar:
		// 			document.documentElement.scrollHeight / document.documentElement.clientHeight >=
		// 			3
		// 	});
		// };
		// onNextFrame(dupa);
		this.setState({
			showScrollBar:
				document.documentElement.scrollHeight / document.documentElement.clientHeight >= 3
		});
	}
	public render(): JSX.Element {
		return (
			<div className="document">
				{this.state.showScrollBar && <ScrollProgressBar />}
				<div className="line-decor line-decor--left"></div>
				{this.props.document.pictureModified && (
					<div className="document_thumbimg">
						{' '}
						<img
							src={
								process.env.REACT_APP_FILES_URL +
								'/api/' +
								this.props.document.pictureModified
							}
						></img>
					</div>
				)}
				<h1 className="document_topic">{this.props.document.title}</h1>
				<div
					className="document_content"
					dangerouslySetInnerHTML={{ __html: this.props.document.documentData ?? '' }}
				></div>
			</div>
		);
	}

	private getInitialState = (): IDocumentComponentState => {
		return {
			showScrollBar: false
		} as IDocumentComponentState;
	};
}
