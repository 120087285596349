import './footer.css';

import { ICONS } from './icons-temp';
import { Link } from 'react-router-dom';
import React from 'react';
import { documentsUrls } from '../../../shared/shared';
import { isNewUserCreatorActive } from '../../../business/router/router-selector';
import { useSelector } from 'react-redux';

import { createUrlWithParams, routes } from '../../../business/router/path-definition';

export const Footer: React.FC = (props) => {
	const isNewUserCreatorActivated = useSelector(isNewUserCreatorActive);
	if (!isNewUserCreatorActivated)
		return (
			<>
				<div className="style-desktop">
					<div className="footer">
						<div className="footer__item footer__item--left">
							<div className="footer__item__row">
								<img src="/img/footerlogo.svg" alt="logo footer" />
							</div>
							<div className="footer__item__row">
								<a href="mailto:kontakt@dietdesigner.app" className="footer_mail">
									kontakt@dietdesigner.app
								</a>
							</div>
						</div>
						<div className="footer__item footer__item--right">
							<div className="footer__item__row">
								<a href="#">Home</a>
								<a href="#/document/regulamin">Regulamin</a>
								<Link to={routes.static.blog.path}>Blog</Link>
								<Link
									to={createUrlWithParams(routes.static.document.path, {
										documentUrl: documentsUrls.getstarted
									})}
								>
									Jak to działa
								</Link>
								<Link to={routes.partnership.initialForm.path}>Współpraca</Link>
							</div>
							<div className="footer__item__row">{ICONS}</div>
						</div>
					</div>
					<div className="footerVersion">
						<p className="versionNumber">{process.env.REACT_APP_VERSION}</p>
					</div>
				</div>
				<div className="style-mobile">
					<div className="footer">
						<div className="footer__item footer__item--left">
							<div className="footer__item__row">
								<img src="/img/footerlogo.svg" alt="logo footer" />
							</div>
							<div className="footer__item__row">
								<a href="mailto:kontakt@dietdesigner.app" className="footer_mail">
									kontakt@dietdesigner.app
								</a>
							</div>
						</div>
						<div className="footer__item footer__item--left">
							<div className="footer__item__row">
								<a href="#">Homae</a>
								<a href="#/document/regulamin">Regulamin</a>
								<Link to={routes.static.blog.path}>Blog</Link>
								<Link
									to={createUrlWithParams(routes.static.document.path, {
										documentUrl: documentsUrls.getstarted
									})}
								>
									Jak to działa
								</Link>
								<Link to={routes.partnership.initialForm.path}>Współpraca</Link>
							</div>
							<div className="footer__item__row">{ICONS}</div>
						</div>
					</div>

					<div className="footerVersion">
						<p className="versionNumber">{process.env.REACT_APP_VERSION}</p>
					</div>
				</div>
			</>
		);
	else return null;
};
