import './DatePicker.scss';

import { Calendar } from 'react-date-range';
import locale from 'date-fns/locale/pl';
import moment from 'moment';
import { nullableMoment } from '../../../../shared/helpers';

import React, { RefObject } from 'react';

interface IDatePickerProps {
	minDateUtc?: Date;
	maxDateUtc?: Date;
	disabledDatesUtc?: Date[];
	dateUtc: Date;
	showCalendar: boolean;
	handleChange: (date: Date) => void;
	align?: 'top' | 'bottom';
	onShowStateChange: () => void;
}

interface IDatePickerState {}

export class DatePicker extends React.Component<IDatePickerProps, IDatePickerState> {
	private wrapperRef: RefObject<HTMLDivElement>;
	private readonly handleClickOutsideBound: (event: MouseEvent) => void;

	constructor(props: IDatePickerProps, state: IDatePickerState) {
		super(props, state);

		this.state = this.getInitialState();
		this.wrapperRef = React.createRef();
		this.handleClickOutsideBound = this.handleClickOutside.bind(this);

		document.addEventListener('mousedown', this.handleClickOutsideBound);
	}

	private handleClickOutside(event: MouseEvent) {
		if (
			this.wrapperRef.current &&
			!this.wrapperRef.current.parentNode?.contains(event.target as Node)
		) {
			this.props.onShowStateChange();
		}
	}

	public render(): JSX.Element {
		return (
			<div
				className={`
				datePickerPopoverWrapper 
				${this.props.align === 'top' ? 'datePickerPopoverWrapper--top' : ''}
			`}
				ref={this.wrapperRef}
			>
				{this.props.showCalendar && (
					<div className="datePickerPopoverContent">
						<Calendar
							disabledDates={this.props.disabledDatesUtc?.map((x) =>
								moment(x).local().toDate()
							)}
							onChange={this.props.handleChange}
							date={moment(this.props.dateUtc).local().toDate()}
							minDate={nullableMoment(this.props.minDateUtc)?.local().toDate()}
							maxDate={nullableMoment(this.props.maxDateUtc)?.local().toDate()}
							color="#FF7E36"
							showDateDisplay={false}
							locale={locale}
						/>
					</div>
				)}
			</div>
		);
	}

	private getInitialState = (): IDatePickerState => {
		return {} as IDatePickerState;
	};
}
