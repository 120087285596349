import { ActionType } from 'typesafe-actions';
import { AuthenticationApi } from '../../auth-client';
import { RootState } from '../root-store';
import { ThunkAction } from 'redux-thunk';
import { getUsername } from './auth-selector';
import { loadCustomerOrPartnership } from '../customer/customer-settings-actions';
import moment from 'moment';
import { actions as toastrActions } from 'react-redux-toastr';

import { CustomerApi, DietApi } from '../../client';
import { GetApiConfig, GetAuthConfig } from '../api-client-config';
import { navigationAction, routes } from '../router/path-definition';

export const authenticationActionNames = {
	Auth_Login: '@@Diet/Auth/auth-login',
	Auth_Logout: '@@Diet/Auth/Logout',
	Auth_LoadCustomerId: '@@Diet/Auth/CustomerId/Load',
	Auth_LoadTokenStatus: '@@Diet/Auth/TokenStatus/Load'
} as const;
export interface IAuthLoginAction {
	type: typeof authenticationActionNames.Auth_Login;
	payload: { login: string; token?: string };
}

export interface IAuthLogoutAction {
	type: typeof authenticationActionNames.Auth_Logout;
	payload: {};
}

export interface ICustomerIdAction {
	type: typeof authenticationActionNames.Auth_LoadCustomerId;
	payload: { customerId: number };
}

export interface ILoadTokenAction {
	type: typeof authenticationActionNames.Auth_LoadTokenStatus;
	payload: { status?: string };
}

export const authenticationActions = {
	logInAction: (payload: IAuthLoginAction['payload']): IAuthLoginAction => ({
		type: authenticationActionNames.Auth_Login,
		payload
	}),
	logOutAction: (): IAuthLogoutAction => ({
		type: authenticationActionNames.Auth_Logout,
		payload: {}
	}),
	loadCustomerIdAction: (payload: ICustomerIdAction['payload']): ICustomerIdAction => ({
		type: authenticationActionNames.Auth_LoadCustomerId,
		payload
	}),
	loadTokenStatusAction: (payload: ILoadTokenAction['payload']): ILoadTokenAction => ({
		type: authenticationActionNames.Auth_LoadTokenStatus,
		payload
	})
} as const;

export type AuthenticationAction = ActionType<typeof authenticationActions>;

export const logIn =
	(username: string, password: string): ThunkAction<any, RootState, any, any> =>
	(dispatch, getState) => {
		const api = new AuthenticationApi(GetAuthConfig(getState()));
		api.login({ username, password, systemUniqueId: 'DIET' })
			.then((data) => {
				if (data.data.authData?.token) {
					dispatch(
						authenticationActions.logInAction({
							login: username,
							token: data.data.authData.token || ''
						})
					);
					dispatch(loadCustomerOrPartnership(username));
					dispatch(toastrActions.add({ message: 'Zalogowano', type: 'success' }));
					dispatch(navigationAction.open(routes.home.path));
				}
			})
			.catch((c) => {
				dispatch(
					authenticationActions.logInAction({
						login: '',
						token: ''
					})
				);
				dispatch(navigationAction.open(routes.auth.login.path));
			});
	};

export const registerNewUserApi =
	(username: string, password: string): ThunkAction<any, RootState, any, any> =>
	(dispatch, getState) => {
		const state = getState();
		const dietApi = new DietApi(GetApiConfig(state));
		const authApi = new AuthenticationApi(GetAuthConfig(getState()));
		const customerApi = new CustomerApi(GetApiConfig(state));
		const customerId = state.administration.customerSettings.customerId!;
		authApi
			.register({
				email: username,
				password: password,
				username: username,
				systemUniqueId: 'DIET'
			})
			.then((data) => {
				customerApi
					.postCorelateUserWithCustomer(
						state.administration.customerSettings.customerBasicInformation.settingsId ||
							'',
						customerId,
						{
							customerId: customerId,
							userId: Number(data.data.authData!.userId),
							email: data.data.authData!.userName!,
							settingsId:
								state.administration.customerSettings.customerBasicInformation
									.settingsId || ''
						}
					)
					.then((x) => {
						dietApi
							.getMatches(
								state.administration.customerSettings.customerBasicInformation
									.settingsId || '',
								{
									startDate: state.administration.customerSettings.dietStartUtc
										? moment
												.utc(
													state.administration.customerSettings
														.dietStartUtc
												)
												.toISOString()
										: moment().add(1, 'day').startOf('day').toISOString()
								}
							)
							.finally(() => {
								dispatch(
									toastrActions.add({
										message:
											'Na Twój adres mailowy wysłaliśmy link aktywujący konto',
										type: 'success'
									})
								);
								dispatch(navigationAction.open(routes.auth.login.path));
							});
					});
			})
			.catch((c) => {});
	};

export const changePassword =
	(oldPassword: string, newPassword: string): ThunkAction<any, RootState, any, any> =>
	(dispatch, getState) => {
		const state = getState();
		const api = new AuthenticationApi(GetAuthConfig(getState()));
		var mail = getUsername(state);
		api.changePassword({
			mail: mail,
			oldPassword: oldPassword,
			newPassword: newPassword,
			systemUniqueId: 'DIET'
		}).then(() => {
			dispatch(
				toastrActions.add({
					message: 'Pomyślnie zmieniono hasło, możesz się teraz zalogować',
					type: 'success'
				})
			);
			dispatch(navigationAction.open(routes.auth.login.path));
		});
	};

export const resetPassword =
	(mail: string): ThunkAction<any, RootState, any, any> =>
	(dispatch, getState) => {
		const state = getState();
		const api = new AuthenticationApi(GetAuthConfig(getState()));
		api.resetPassword({
			mail: mail,
			systemUniqueId: 'DIET'
		}).then(() => {
			dispatch(
				toastrActions.add({
					message:
						'Jeśli masz u nas konto powinieneś otrzymać na maila link resetujący hasło',
					type: 'success'
				})
			);
			dispatch(navigationAction.open(routes.auth.login.path));
		});
	};

export const handleResetPassword =
	(
		newPassword: string,
		retypeNewPassword: string,
		guid: string
	): ThunkAction<any, RootState, any, any> =>
	(dispatch, getState) => {
		const state = getState();
		const api = new AuthenticationApi(GetAuthConfig(getState()));
		api.changePassword({
			newPassword: newPassword,
			retypeNewPassword: retypeNewPassword,
			resetGuid: guid,
			systemUniqueId: 'DIET'
		}).then(() => {
			dispatch(
				toastrActions.add({
					message: 'Pomyślnie zmieniono hasło, możesz się teraz zalogować',
					type: 'success'
				})
			);
			dispatch(navigationAction.open(routes.auth.login.path));
		});
	};

export const activateCreatedAccount =
	(
		newPassword: string,
		retypeNewPassword: string,
		guid: string
	): ThunkAction<any, RootState, any, any> =>
	(dispatch, getState) => {
		const state = getState();
		const api = new AuthenticationApi(GetAuthConfig(state));
		api.activateCreatedAccount({
			password: newPassword,
			reTypePassword: retypeNewPassword,
			guid: guid,
			systemUniqueId: 'DIET'
		}).then(() => {
			dispatch(
				toastrActions.add({
					message:
						'Pomyślnie ustawiono hasło oraz aktywowano konto, możesz się teraz zalogować',
					type: 'success'
				})
			);
			dispatch(navigationAction.open(routes.auth.login.path));
		});
	};

export const activateAccount =
	(guid: string): ThunkAction<any, RootState, any, any> =>
	(dispatch, getState) => {
		const state = getState();
		const api = new AuthenticationApi(GetAuthConfig(state));
		api.postActivateMail(guid).then((x) => {
			if (x.data.status) {
				dispatch(
					toastrActions.add({
						message: 'Konto zostało pomyślnie aktywowane, możesz się teraz zalogować',
						type: 'success'
					})
				);
				dispatch(navigationAction.open(routes.auth.login.path));
			} else if (!x.data.status) {
				dispatch(
					toastrActions.add({
						message: 'Link aktywacyjny wygasł lub konto jest już aktywne',
						type: 'warning'
					})
				);
				dispatch(navigationAction.open(routes.auth.login.path));
			}
		});
	};
