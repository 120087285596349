import * as React from 'react';
import * as Redux from 'redux';

import { INutritionFactsSummary } from '../../../../shared/helpers';
import { NutritionFactDaySummaryComponent } from './nutrition-fact-day-summary-component';
import { RootState } from '../../../../business/root-store';
import { connect } from 'react-redux';
import { getSummaryForDayView } from '../../../../business/diet/diet-selector';

import { AnyAction, Dispatch } from 'redux';

interface INutritionFactDaySummaryContainerContainerReduxMergedProps {
	summary: INutritionFactsSummary;
}

interface INutritionFactDaySummaryContainerContainerOwnProps {}

interface INutritionFactDaySummaryContainerContainerDispatchProps {}

interface INutritionFactDaySummaryContainerContainerProps
	extends INutritionFactDaySummaryContainerContainerOwnProps,
		INutritionFactDaySummaryContainerContainerDispatchProps,
		INutritionFactDaySummaryContainerContainerReduxMergedProps {}

interface INutritionFactDaySummaryContainerComponentState {}

class NutritionFactDaySummaryContainerContainer extends React.Component<
	INutritionFactDaySummaryContainerContainerProps,
	INutritionFactDaySummaryContainerComponentState
> {
	constructor(
		props: INutritionFactDaySummaryContainerContainerProps,
		state: INutritionFactDaySummaryContainerComponentState
	) {
		super(props, state);

		this.state = this.getInitialState();
	}

	public render(): JSX.Element {
		return <NutritionFactDaySummaryComponent summary={this.props.summary} />;
	}

	public componentDidMount(): void {}

	public static mapStateToProps = (
		state: RootState,
		ownProps: INutritionFactDaySummaryContainerContainerOwnProps
	): INutritionFactDaySummaryContainerContainerReduxMergedProps => {
		return {
			summary: getSummaryForDayView(state)
		};
	};

	public static mapDispatchToProps = (
		dispatch: Dispatch<AnyAction>
	): INutritionFactDaySummaryContainerContainerDispatchProps => {
		return {
			...Redux.bindActionCreators({}, dispatch)
		};
	};

	private getInitialState = (): INutritionFactDaySummaryContainerComponentState => {
		return {} as INutritionFactDaySummaryContainerComponentState;
	};
}
export const NutritionFactDaySummary = connect(
	NutritionFactDaySummaryContainerContainer.mapStateToProps,
	NutritionFactDaySummaryContainerContainer.mapDispatchToProps
)(NutritionFactDaySummaryContainerContainer);
