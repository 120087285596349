import './App.css';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';

import * as React from 'react';
import * as Redux from 'redux';

import { AppComponent } from './AppComponent';
import { ExceptionHandlingResultModel } from './client';
import { RootState } from './business/root-store';
import { RouterLocation } from 'connected-react-router';
import { SpinnerActions } from './components/atoms/spinner/SpinnerActions';
import { connect } from 'react-redux';
import { fetchDefaultDiscountCodes } from './components/molecules/landing-page-molecules/landing-page-diet-types/LandingPageDietTypesEpics';
import { getRouterLocationState } from './business/router/router-selector';
import { getiSAnyActiveDiet } from './business/diet/diet-selector';
import { loadAvailableDietPlans } from './business/diet/diet-action';
import { loadCustomerOrPartnership } from './business/customer/customer-settings-actions';

import { Action, AnyAction, Dispatch } from 'redux';
import { AddToastPayload, actions as toastrActions } from 'react-redux-toastr';
import axios, { AxiosError } from 'axios';
import { getToken, getUsername, isSignedIn } from './business/auth/auth-selector';
import { navigationAction, routes } from './business/router/path-definition';

export interface IAppState {
	sideBarStatus: boolean;
}

interface IAppContainerReduxMergedProps {
	token?: string;
	username?: string;
	isSignedIn: boolean;
	getIsAnyActiveDiet?: boolean;
	router: RouterLocation<unknown>;
}

interface IAppContainerOwnProps {}

interface IAppContainerDispatchProps {
	incrementActiveRequestsCount: () => void;
	decrementActiveRequestsCount: () => void;
	loadAvailableDietPlans: () => void;
	loadCustomerIdAndActiveDietPlan: (username: string) => void;
	fetchDefaultDiscountCodes: () => void;
	add: (toastr: AddToastPayload) => Action;
	open: (route: string) => void;
}

interface IAppContainerProps
	extends IAppContainerOwnProps,
		IAppContainerDispatchProps,
		IAppContainerReduxMergedProps {}

interface IAppComponentState {
	sidebarStatus: boolean;
}

class AppContainer extends React.Component<IAppContainerProps, IAppComponentState> {
	constructor(props: IAppContainerProps, state: IAppComponentState) {
		super(props, state);

		this.state = this.getInitialState();
	}

	private toggleSlideBar = () => {
		this.setState({ ...this.state, sidebarStatus: !this.state.sidebarStatus });
	};

	public componentDidUpdate = (prevProps: IAppContainerProps) => {
		if (prevProps.router.pathname !== this.props.router.pathname) {
			window.scroll({ top: 0 });
		}
	};
	public componentDidMount(): void {
		const {
			incrementActiveRequestsCount,
			decrementActiveRequestsCount,
			add,
			open,
			loadCustomerIdAndActiveDietPlan,
			username
		} = this.props;
		this.props.loadAvailableDietPlans();
		this.props.fetchDefaultDiscountCodes();

		if (!!username && username.length > 0) {
			loadCustomerIdAndActiveDietPlan(username);
		}

		var numberOfAjaxCAllPending = 0;
		axios.interceptors.request.use(
			function (config) {
				numberOfAjaxCAllPending++;
				incrementActiveRequestsCount();
				return config;
			},
			function (error) {
				return Promise.reject(error);
			}
		);

		axios.interceptors.response.use(
			function (response) {
				numberOfAjaxCAllPending--;
				decrementActiveRequestsCount();

				return response;
			},
			function (reason: AxiosError<{ additionalInfo: string }>) {
				numberOfAjaxCAllPending--;
				decrementActiveRequestsCount();
				if (reason.isAxiosError && reason.response == undefined) {
					add({
						type: 'error',
						message: 'Wystąpił problem, brak połączenia z serwisem'
					} as AddToastPayload);
				}
				if ((reason.request.responseURL as string).includes('api.mapy.cz')) {
					return;
				}
				if (reason.response?.status === 401) {
					// console.log('401 unauthorized');
					open(routes.auth.login.path);
				} else {
					var res = reason.response!.data as ExceptionHandlingResultModel;
					if (
						res !== null &&
						res !== undefined &&
						res.errors != null &&
						res.errors != undefined &&
						res.errors.length > 0
					) {
						res.errors.forEach((element) => {
							add({
								type: 'error',
								message: element.message ?? ''
							} as AddToastPayload);
						});
					} else {
						add({
							type: 'error',
							message: 'Wystąpił problem ' + res?.errors
						} as AddToastPayload);
					}
				}

				return Promise.reject(reason);
			}
		);
	}
	public static mapStateToProps = (
		state: RootState,
		ownProps: IAppContainerOwnProps
	): IAppContainerReduxMergedProps => {
		return {
			username: getUsername(state),
			token: getToken(state),
			isSignedIn: isSignedIn(state),
			getIsAnyActiveDiet: getiSAnyActiveDiet(state),
			router: getRouterLocationState(state)
		};
	};
	public static mapDispatchToProps = (
		dispatch: Dispatch<AnyAction>
	): IAppContainerDispatchProps => {
		return {
			...Redux.bindActionCreators(
				{
					loadCustomerIdAndActiveDietPlan: loadCustomerOrPartnership,
					loadAvailableDietPlans: loadAvailableDietPlans,
					add: toastrActions.add,
					incrementActiveRequestsCount: SpinnerActions.incrementActiveRequestsCount,
					decrementActiveRequestsCount: SpinnerActions.decrementActiveRequestsCount,
					fetchDefaultDiscountCodes: fetchDefaultDiscountCodes,
					open: navigationAction.open
				},
				dispatch
			)
		};
	};

	private getInitialState = (): IAppComponentState => {
		return {} as IAppComponentState;
	};

	public render(): JSX.Element {
		return (
			<AppComponent
				key="app"
				isLoggedUser={this.props.isSignedIn}
				sideBarShow={this.state.sidebarStatus}
				toggleSlideBar={this.toggleSlideBar}
			/>
		);
	}
}

export const App = connect(
	AppContainer.mapStateToProps,
	AppContainer.mapDispatchToProps
)(AppContainer);
