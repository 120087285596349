import React from 'react';

export interface IRemoveIconProps {
	id?: number;
	className?: string;
	onClick?: () => void;
}

export const RemoveIcon: React.FC<IRemoveIconProps> = (props) => {
	return (
		<React.Fragment>
			<svg
				viewBox="0 0 18 18"
				fill="none"
				id={'removeIcon_' + props.id}
				className={props.className}
				onClick={props.onClick}
				xmlns="http://www.w3.org/2000/svg"
			>
				<circle cx="9" cy="9" r="9" fill="#FD6851" />
				<path
					d="M11.7363 6.73682L6.73633 11.7368"
					stroke="white"
					strokeWidth="2.13621"
					strokeLinecap="round"
				/>
				<path
					d="M6.73633 6.73682L11.7363 11.7368"
					stroke="white"
					strokeWidth="2.13621"
					strokeLinecap="round"
				/>
			</svg>
		</React.Fragment>
	);
};
