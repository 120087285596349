import { DietBallanceItem } from '../../client';
import { Reducer } from 'redux';

import { DurinalBalanceAction, durinalBalanceActionName } from './durinalBalance.actions';

export type DurinalBalanceState = {
	dietBallanceItems: DietBallanceItem[];
};

export const INITIAL_STATE: DurinalBalanceState = {
	dietBallanceItems: []
};

export const durinalBalanceReducer: Reducer<DurinalBalanceState, DurinalBalanceAction> = (
	state = INITIAL_STATE,
	action: DurinalBalanceAction
): DurinalBalanceState => {
	switch (action.type) {
		case durinalBalanceActionName.ON_GET_DURINAL_BALANCE_RESPONSE: {
			const { ballance } = action.payload;

			const list =
				ballance && ballance.length
					? ballance.sort((itemA, itemB) => itemA.sortOrder! - itemB.sortOrder!)
					: [];
			return { ...state, dietBallanceItems: list };
		}
		default:
			return state;
	}
};
