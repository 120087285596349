import { DietDefinition } from '../../../../client';
import { IOrderMenuView } from '../interfaces';

export const getMonthsConjugation = (monthNo: number) => {
	switch (monthNo) {
		case 1: {
			return 'miesiąc';
		}
		case 2:
		case 3:
		case 4: {
			return 'miesiące';
		}
		case 0:
		case 5:
		case 6:
		case 7:
		case 8:
		case 9:
		case 10:
		case 11:
		case 12: {
			return 'miesięcy';
		}
	}
};

const patterns: IOrderMenuView.Patterns = {
	dietDays: '%dietDays%',
	priceNet: '%priceNet%'
};

export const planDescription: Record<number, string> = {
	1: `przy jednorazowej płatności za ${patterns.dietDays} miesiąc`,
	2: `przy abonamencie na ${patterns.dietDays} miesiące za ${patterns.priceNet} zł`,
	3: `przy abonamencie na ${patterns.dietDays} miesięcy za ${patterns.priceNet} zł`
};

export const dietTypeDescription: Record<number, { imgSrc: string; description: string }> = {
	1: {
		imgSrc: 'img/diet-types/Standard I.png',
		description:
			'Dieta idealna dla osób, które nie lubią nudy na talerzu. Bazuje na dużej gamie produktów, co gwarantuje różnorodność posiłków. Dzięki niej zadbasz o zdrowie i sylwetkę bez większych wyrzeczeń.'
	},
	2: {
		imgSrc: 'img/diet-types/Veggie I.png',
		description:
			'Dieta bezmięsna może być urozmaicona i smaczna, a także da pewność, że dania są odpowiednio zbilansowane i dostarczają wszystkich potrzebnych składników odżywczych.'
	},
	3: {
		imgSrc: 'img/diet-types/Pesco I.png',
		description:
			'Dieta wegetariańska z dodatkiem ryb i owoców morza. Jest doskonałym wyborem dla osób, które chcą wzbogacić swoje posiłki w dary mórz i jezior.'
	}
};

export const matchDietTypeDescription = (
	dietType: DietDefinition,
	dietDescriptions = dietTypeDescription
): IOrderMenuView.DescribedDietType => ({ ...dietType, ...{ ...dietDescriptions[dietType.id!] } });

export const countMonthlyPayment = (monthsNumber: number, priceNet: number) =>
	Number((priceNet / monthsNumber).toFixed(2));
