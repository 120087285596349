import './DateRangePicker.scss';

import { DateRange } from 'react-date-range';
import { IDateRangePickerComponentProps } from './DateRangePickerInterfaces';
import locale from 'date-fns/locale/pl';
import { nullableMoment } from '../../../../shared/helpers';

import React, { useEffect, useRef } from 'react';

export const DateRangePicker = ({
	onChange,
	showCalendar,
	maxDateLocal,
	minDateLocal,
	rangeLocal,
	className,
	...props
}: IDateRangePickerComponentProps) => {
	const ref = useRef<HTMLDivElement>(null);
	useEffect(() => {
		function handleClickOutside(event: any) {
			if (ref.current && !ref.current.contains(event.target)) {
				showCalendar(false);
			}
		}
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [ref]);
	return (
		<div
			ref={ref}
			className={
				className
					? 'dateRangePickerPopoverContent ' + className
					: 'dateRangePickerPopoverContent'
			}
		>
			<DateRange
				onChange={onChange}
				ranges={[
					{
						...rangeLocal,
						startDate: nullableMoment(rangeLocal.startDate)?.toDate(),
						endDate: nullableMoment(rangeLocal.endDate)?.toDate()
					}
				]}
				minDate={minDateLocal}
				maxDate={maxDateLocal}
				rangeColors={['#FF7E36']}
				showDateDisplay={false}
				locale={locale}
				// onPreviewChange={(range) => console.log(range)}
			/>
		</div>
	);
};
