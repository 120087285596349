import React from 'react';

export interface ICopyIconProps {
	size?: number;
	className?: string;
}

export const CopyIcon: React.FC<ICopyIconProps> = (props) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="12"
			height="14"
			viewBox="0 0 12 14"
			fill="none"
		>
			<path
				d="M8.16667 0.583374H1.16667C0.525 0.583374 0 1.10837 0 1.75004V9.91671H1.16667V1.75004H8.16667V0.583374ZM7.58333 2.91671L11.0833 6.41671V12.25C11.0833 12.8917 10.5583 13.4167 9.91667 13.4167H3.49417C2.8525 13.4167 2.33333 12.8917 2.33333 12.25L2.33917 4.08337C2.33917 3.44171 2.85833 2.91671 3.5 2.91671H7.58333ZM7 7.00004H10.2083L7 3.79171V7.00004Z"
				fill="#FD6851"
			/>
		</svg>
	);
};
