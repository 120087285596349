import './CheckBox.scss';

import React, { InputHTMLAttributes } from 'react';

export interface ICheckBoxProps {
	displayName?: string;
	disableDisplayNameChangeStyle?: boolean;
	errorString?: string;
	className?: string;
	classNameContainer?: string;
	icon?: JSX.Element;
}

export const CheckBox = ({
	classNameContainer,
	icon,
	disableDisplayNameChangeStyle,
	displayName,
	errorString,
	className,
	...props
}: ICheckBoxProps & InputHTMLAttributes<HTMLInputElement>) => {
	return (
		<div className={className ? 'CheckBox ' + className : 'CheckBox'}>
			<label
				htmlFor={props.id || props.name}
				className={
					classNameContainer
						? 'CheckBox_container ' + classNameContainer
						: 'CheckBox_container'
				}
			>
				<input {...props} className="CheckBox_container_input" type="checkbox" />
				{displayName && (
					<div className="CheckBox_container_text">
						<div
							className={
								props.checked && !disableDisplayNameChangeStyle
									? ''
									: 'CheckBox_container_text_uncheckedIcon'
							}
						>
							{icon}
						</div>
						<span
							className={
								props.checked && !disableDisplayNameChangeStyle
									? 'CheckBox_container_text_span_checked'
									: 'CheckBox_container_text_span'
							}
						>
							{displayName}
						</span>
					</div>
				)}
			</label>

			{errorString && <span className="FormikError">{errorString}</span>}
		</div>
	);
};
